import React from 'react';
import {View,Text} from 'react-native';
import {  Badge } from 'native-base';
import { FontLetterSpacing } from '../handlers/utils';
import StyledText from "../components/styledText"

const ItemLabel = ({page, design, textMessage, textColor}) => {
    return(
        <Badge style={{
            alignSelf: "flex-start",
            backgroundColor: page=="item_detail"? design.top_buttons_background_color : design.grid_view_button_background_color, //design.grid_view_button_background_color,
            borderRadius: "3px",
            // minHeight: "18px",
            padding: 3,                   
            // color:  textColor, // design.grid_view_button_text_color,
            marginTop: 4,
            marginRight: "5px",
            height:"none"
            // fontSize: props.pillFontSize ?? "9px",
            // letterSpacing: FontLetterSpacing(props.custom_font.primary_letter_space),
            // fontWeight: props.custom_font.pill_is_bold ? "bold": "normal"
          }} ><View>
            <StyledText 
             font_color={page=="item_detail" ? design.top_buttons_text_color : design.grid_view_button_text_color }
             font_weight={page=="item_detail" ? design.top_button_text_font_weight : design.grid_view_button_text_font_weight}
             font_style={page=="item_detail" ? design.top_button_text_font_style : design.grid_view_button_text_font_style }
             text_transform={page=="item_detail" ? design.top_button_text_font_transform : design.grid_view_button_text_font_transform}
             font_family={page=="item_detail" ? design.top_button_text_font_family : design.grid_view_button_text_font_family}
             letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
             font_size={page=="item_detail" ? design.top_button_text_font_size : design.grid_view_button_text_font_size }

            style={{
            alignSelf: 'center',
            textAlign: 'center',
            marginTop: 0,
            marginBottom: "2px",
          }}>
            {textMessage}
          </StyledText></View></Badge>
    )
}

export default ItemLabel