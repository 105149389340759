import React from "react";
import { StyleSheet, Text, View } from "react-native";
import NavigationIndex from "./src/navigation/index";
import { Provider } from "react-redux";
import { store, persistor } from "./src/store/configureStore";
import InitialRender from "./intitialRendering";
import { PersistGate } from "redux-persist/integration/react";
import { AppLoading } from "expo";


// replace console.* for disable log on production
// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }



class App extends React.Component {
  state = {
    isReady: false,
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate
          loading={
            <AppLoading
              // startAsync={this._loadAssetsAsync}
              onFinish={() => this.setState({ isReady: true })}
            />
          }
          persistor={persistor}
        >
          <InitialRender />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
