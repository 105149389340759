'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var TokenList;
var TokenPage;
var TokenInstance;

/* jshint ignore:start */
/**
 * Initialize the TokenList
 *
 * @constructor Twilio.Api.V2010.AccountContext.TokenList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The SID of the Account that created the resource
 */
/* jshint ignore:end */
TokenList = function TokenList(version, accountSid) {
  /* jshint ignore:start */
  /**
   * @function tokens
   * @memberof Twilio.Api.V2010.AccountContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.TokenContext}
   */
  /* jshint ignore:end */
  function TokenListInstance(sid) {
    return TokenListInstance.get(sid);
  }

  TokenListInstance._version = version;
  // Path Solution
  TokenListInstance._solution = {accountSid: accountSid};
  TokenListInstance._uri = `/Accounts/${accountSid}/Tokens.json`;
  /* jshint ignore:start */
  /**
   * create a TokenInstance
   *
   * @function create
   * @memberof Twilio.Api.V2010.AccountContext.TokenList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.ttl] - The duration in seconds the credentials are valid
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TokenInstance
   */
  /* jshint ignore:end */
  TokenListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({'Ttl': _.get(opts, 'ttl')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TokenInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.TokenList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TokenListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TokenListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return TokenListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TokenPage
 *
 * @constructor Twilio.Api.V2010.AccountContext.TokenPage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TokenSolution} solution - Path solution
 *
 * @returns TokenPage
 */
/* jshint ignore:end */
TokenPage = function TokenPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TokenPage.prototype, Page.prototype);
TokenPage.prototype.constructor = TokenPage;

/* jshint ignore:start */
/**
 * Build an instance of TokenInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.TokenPage#
 *
 * @param {TokenPayload} payload - Payload response from the API
 *
 * @returns TokenInstance
 */
/* jshint ignore:end */
TokenPage.prototype.getInstance = function getInstance(payload) {
  return new TokenInstance(this._version, payload, this._solution.accountSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.TokenPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TokenPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TokenPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TokenContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.TokenInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT that the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT that the resource was last updated
 * @property {string} iceServers - An array representing the ephemeral credentials
 * @property {string} password - The temporary password used for authenticating
 * @property {string} ttl - The duration in seconds the credentials are valid
 * @property {string} username -
 *          The temporary username that uniquely identifies a Token
 *
 * @param {V2010} version - Version of the resource
 * @param {TokenPayload} payload - The instance payload
 * @param {sid} accountSid - The SID of the Account that created the resource
 */
/* jshint ignore:end */
TokenInstance = function TokenInstance(version, payload, accountSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.rfc2822DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.rfc2822DateTime(payload.date_updated); // jshint ignore:line
  this.iceServers = payload.ice_servers; // jshint ignore:line
  this.password = payload.password; // jshint ignore:line
  this.ttl = payload.ttl; // jshint ignore:line
  this.username = payload.username; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.TokenInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TokenInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TokenInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TokenList: TokenList,
  TokenPage: TokenPage,
  TokenInstance: TokenInstance
};
