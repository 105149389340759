import React, { useState } from 'react'
import { Touchable } from 'react-native'
import { Dimensions, View, Text, TouchableOpacity, ScrollView } from "react-native";
import i18n from "../i18n";
import { style } from "../../assets/style";
import Icono from "@expo/vector-icons/Ionicons";
import {useSelector} from "react-redux"
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';

const { width, height } = Dimensions.get("window")

const FilterContainer = (props) => {

    const design = useSelector(state => state.config.design)

    const [query, setQuery] = useState('')
    let filters = [
        // "labels.chefs_special",
        // "labels.best_seller",
        // "labels.our_favorite",
        // "labels.must_try",
        // "labels.healthy",
        // "labels.mark_item_as_new",
        // "labels.signature",
        "labels.ic_gluten_free",
        "labels.ic_lacoste_free",
        "labels.ic_shell_fish",
        "labels.ic_vegan"
    ]

    const setFilterHandler = (filter) => {

        props.setFilters(filter)
    }

    const clearAll = () => {
        props.clearAll()
        setQuery("")
        props.closeModal()
    }
    const applyChanges = () => {

        props.searchItem(query, props.filterValues)
        props.closeModal()
        let Nfilters = Object.keys(props.filterValues).filter(item => props.filterValues[item] == true)
        props.selectedFilters(Nfilters)
    }
    
    return (
        <View style={{ alignItems: 'center', backgroundColor: 'transparent', height: height, width: width, 
        // backdropFilter: "blur(5px)"
        backgroundColor: "rgba(0, 0, 0, 0.5)" 
         }}>
            <View style={{ alignItems: 'flex-start', justifyContent: 'center', backgroundColor: 'white', height: height * .4, width: "100%", borderRadius: 8,padding:10 }} >
                {/* <span style={{ fontSize: 20 }} >Filter By : </span> */}
                <div style={{ height: "100%", width: "100%",marginTop:60,marginLeft:25 }}>
                    <View style={{width:"100%",flexDirection:"row",flexWrap:"wrap"}} >
                    {
                        filters.map(item => {
                            return (
                                <TouchableOpacity onPress={() => setFilterHandler(item.split(".")[1])}>
                                    <View style={{
                                        height:35, 
                                        minWidth:100,
                                         backgroundColor: props.filterValues[item.split(".")[1]] ?design.popup_button_color : "transparent",
                                         alignItems:"center", 
                                         justifyContent:"center", 
                                         marginRight:7, 
                                         borderRadius:6,
                                         borderWidth:1,
                                         borderColor: design.popup_button_color,
                                         marginBottom:7
                                         }} >
                                             <StyledText
                                font_color={props.filterValues[item.split(".")[1]] ? design.popup_button_text_color: design.popup_button_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                font_size={design.popup_button_font_size}
                                style={{}}
                            >
                                {i18n.t(item)}
                                </StyledText>
                                    </View>
                                </TouchableOpacity>
                               
                            )
                        })
                    }
                    </View>
                    
                </div>
                <View style={{ flexDirection: "row", width: "100%", marginBottom: 5, justifyContent:"center" }} >
                    <button style={{
                        backgroundColor: design.popup_button_color,
                        minHeight: 35,
                        padding: 0,
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginLeft:5,
                        width:100,
                        marginRight:5
                    }} onClick={applyChanges} class="btn btn-sm" >
                        <StyledText
                                font_color={design.popup_button_text_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                font_size={design.popup_button_font_size}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{fontSize: 13}}
                            >
                                {i18n.t("button_text.apply")}
                                </StyledText>
                        </button>


                    <button onClick={clearAll} style={{
                        backgroundColor: design.popup_button_color,
                        minHeight: 35,
                        padding: 0,

                        paddingLeft: 5,
                        paddingRight: 5,
                        width:100,
                    }} class="btn btn-sm " >
                        <StyledText
                                font_color={design.popup_button_text_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                font_size={design.popup_button_font_size}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{fontSize: 13}}
                            >
                                {i18n.t("button_text.clear")}
                                </StyledText>
                        </button>
                </View>
                <TouchableOpacity
                style={{ position: "absolute", top: 7, right: 5 }}
                onPress={() => props.closeModal()}
              >
                <View style={[style.closeButtonModal, {backgroundColor: design.popup_button_color}]}>
                  <Icono
                    style={{ marginTop: 2 }}
                    name={"ios-close"}
                    color={design.popup_button_text_color}
                    size={40}
                  />
                </View>
              </TouchableOpacity>

            </View>

        </View>
    )
};

export default FilterContainer