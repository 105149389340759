import i18n from "i18n-js";

//import en from "../i18n/en.json";
// import ar from ""../i18n/ar.json"";

import { store } from "./store/configureStore";

import ar from "../i18n/ar.json"
import az from "../i18n/az.json"
import bg from "../i18n/bg.json"
import bn from "../i18n/bn.json"
import de from "../i18n/de.json"
import el from "../i18n/el.json"
import en from "../i18n/en.json"
import es from "../i18n/es.json"
import et from "../i18n/et.json"
import fa from "../i18n/fa.json"
import fi from "../i18n/fi.json"
import fr from "../i18n/fr.json"
import he from "../i18n/he.json"
import hi from "../i18n/hi.json"
import hr from "../i18n/hr.json"
import hy from "../i18n/hy.json"
import it from "../i18n/it.json"
import ja from "../i18n/ja.json"
import ka from "../i18n/ka.json"
import kk from "../i18n/kk.json"
import km from "../i18n/km.json"
import ko from "../i18n/ko.json"
import la from "../i18n/la.json"
import lo from "../i18n/lo.json"
import mn from "../i18n/mn.json"
import nl from "../i18n/nl.json"
import no from "../i18n/no.json"
import pl from "../i18n/pl.json"
import pt from "../i18n/pt.json"
import ro from "../i18n/ro.json"
import ru from "../i18n/ru.json"
import sq from "../i18n/sq.json"
import sv from "../i18n/sv.json"
import ta from "../i18n/ta.json"
import th from "../i18n/th.json"
import tr from "../i18n/tr.json"
import uk from "../i18n/uk.json"
import ur from "../i18n/ur.json"
import uz from "../i18n/uz.json"
import vi from "../i18n/vi.json"
import zh from "../i18n/zh.json"
import mk from "../i18n/mk.json"

const { languages } = store.getState();


i18n.defaultLocale = "en";

i18n.fallbacks = true;
i18n.translations = {
    ar,
    az,
    bg,
    bn,
    de,
    el,
    en,
    es,
    et,
    fa,
    fi,
    fr,
    he,
    hi,
    hr,
    hy,
    it,
    ja,
    ka,
    kk,
    km,
    ko,
    la,
    lo,
    mn,
    nl,
    no,
    pl,
    pt,
    ro,
    ru,
    sq,
    sv,
    ta,
    th,
    tr,
    uk,
    ur,
    uz,
    vi,
    zh,
    mk
};

export default i18n;