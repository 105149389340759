'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var AssistantInitiationActionsList;
var AssistantInitiationActionsPage;
var AssistantInitiationActionsInstance;
var AssistantInitiationActionsContext;

/* jshint ignore:start */
/**
 * Initialize the AssistantInitiationActionsList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsList
 *
 * @param {Twilio.Preview.Understand} version - Version of the resource
 * @param {string} assistantSid - The assistant_sid
 */
/* jshint ignore:end */
AssistantInitiationActionsList = function
    AssistantInitiationActionsList(version, assistantSid) {
  /* jshint ignore:start */
  /**
   * @function assistantInitiationActions
   * @memberof Twilio.Preview.Understand.AssistantContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext}
   */
  /* jshint ignore:end */
  function AssistantInitiationActionsListInstance(sid) {
    return AssistantInitiationActionsListInstance.get(sid);
  }

  AssistantInitiationActionsListInstance._version = version;
  // Path Solution
  AssistantInitiationActionsListInstance._solution = {assistantSid: assistantSid};
  /* jshint ignore:start */
  /**
   * Constructs a assistant_initiation_actions
   *
   * @function get
   * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsList#
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext}
   */
  /* jshint ignore:end */
  AssistantInitiationActionsListInstance.get = function get() {
    return new AssistantInitiationActionsContext(this._version, this._solution.assistantSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  AssistantInitiationActionsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  AssistantInitiationActionsListInstance[util.inspect.custom] = function
      inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return AssistantInitiationActionsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the AssistantInitiationActionsPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsPage
 *
 * @param {Understand} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {AssistantInitiationActionsSolution} solution - Path solution
 *
 * @returns AssistantInitiationActionsPage
 */
/* jshint ignore:end */
AssistantInitiationActionsPage = function
    AssistantInitiationActionsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(AssistantInitiationActionsPage.prototype, Page.prototype);
AssistantInitiationActionsPage.prototype.constructor = AssistantInitiationActionsPage;

/* jshint ignore:start */
/**
 * Build an instance of AssistantInitiationActionsInstance
 *
 * @function getInstance
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsPage#
 *
 * @param {AssistantInitiationActionsPayload} payload -
 *          Payload response from the API
 *
 * @returns AssistantInitiationActionsInstance
 */
/* jshint ignore:end */
AssistantInitiationActionsPage.prototype.getInstance = function
    getInstance(payload) {
  return new AssistantInitiationActionsInstance(this._version, payload, this._solution.assistantSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssistantInitiationActionsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

AssistantInitiationActionsPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the AssistantInitiationActionsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsInstance
 *
 * @property {string} accountSid - The account_sid
 * @property {string} assistantSid - The assistant_sid
 * @property {string} url - The url
 * @property {object} data - The data
 *
 * @param {Understand} version - Version of the resource
 * @param {AssistantInitiationActionsPayload} payload - The instance payload
 * @param {sid} assistantSid - The assistant_sid
 */
/* jshint ignore:end */
AssistantInitiationActionsInstance = function
    AssistantInitiationActionsInstance(version, payload, assistantSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assistantSid = payload.assistant_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.data = payload.data; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {assistantSid: assistantSid, };
};

Object.defineProperty(AssistantInitiationActionsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new AssistantInitiationActionsContext(this._version, this._solution.assistantSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a AssistantInitiationActionsInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssistantInitiationActionsInstance
 */
/* jshint ignore:end */
AssistantInitiationActionsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a AssistantInitiationActionsInstance
 *
 * @function update
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {object} [opts.initiationActions] - The initiation_actions
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssistantInitiationActionsInstance
 */
/* jshint ignore:end */
AssistantInitiationActionsInstance.prototype.update = function update(opts,
    callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssistantInitiationActionsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

AssistantInitiationActionsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the AssistantInitiationActionsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext
 *
 * @param {Understand} version - Version of the resource
 * @param {sid_like} assistantSid - The assistant_sid
 */
/* jshint ignore:end */
AssistantInitiationActionsContext = function
    AssistantInitiationActionsContext(version, assistantSid) {
  this._version = version;

  // Path Solution
  this._solution = {assistantSid: assistantSid, };
  this._uri = `/Assistants/${assistantSid}/InitiationActions`;
};

/* jshint ignore:start */
/**
 * fetch a AssistantInitiationActionsInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssistantInitiationActionsInstance
 */
/* jshint ignore:end */
AssistantInitiationActionsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new AssistantInitiationActionsInstance(
      this._version,
      payload,
      this._solution.assistantSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a AssistantInitiationActionsInstance
 *
 * @function update
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext#
 *
 * @param {object} [opts] - Options for request
 * @param {object} [opts.initiationActions] - The initiation_actions
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed AssistantInitiationActionsInstance
 */
/* jshint ignore:end */
AssistantInitiationActionsContext.prototype.update = function update(opts,
    callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'InitiationActions': serialize.object(_.get(opts, 'initiationActions'))});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new AssistantInitiationActionsInstance(
      this._version,
      payload,
      this._solution.assistantSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.AssistantInitiationActionsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
AssistantInitiationActionsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

AssistantInitiationActionsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  AssistantInitiationActionsList: AssistantInitiationActionsList,
  AssistantInitiationActionsPage: AssistantInitiationActionsPage,
  AssistantInitiationActionsInstance: AssistantInitiationActionsInstance,
  AssistantInitiationActionsContext: AssistantInitiationActionsContext
};
