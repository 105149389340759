/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';

export default function createTabBarItemIOSComponent(IconNamePropType, getImageSource) {
  return class TabBarItemIOS extends PureComponent {
    render() {
      return null;
    }
  };
}
