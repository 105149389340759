'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var ExecutionContextList;
var ExecutionContextPage;
var ExecutionContextInstance;
var ExecutionContextContext;

/* jshint ignore:start */
/**
 * Initialize the ExecutionContextList
 *
 * @constructor Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextList
 *
 * @param {Twilio.Studio.V2} version - Version of the resource
 * @param {string} flowSid - The SID of the Flow
 * @param {string} executionSid - The SID of the Execution
 */
/* jshint ignore:end */
ExecutionContextList = function ExecutionContextList(version, flowSid,
                                                      executionSid) {
  /* jshint ignore:start */
  /**
   * @function executionContext
   * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextContext}
   */
  /* jshint ignore:end */
  function ExecutionContextListInstance(sid) {
    return ExecutionContextListInstance.get(sid);
  }

  ExecutionContextListInstance._version = version;
  // Path Solution
  ExecutionContextListInstance._solution = {flowSid: flowSid, executionSid: executionSid};
  /* jshint ignore:start */
  /**
   * Constructs a execution_context
   *
   * @function get
   * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextList#
   *
   * @returns {Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextContext}
   */
  /* jshint ignore:end */
  ExecutionContextListInstance.get = function get() {
    return new ExecutionContextContext(
      this._version,
      this._solution.flowSid,
      this._solution.executionSid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ExecutionContextListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ExecutionContextListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return ExecutionContextListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ExecutionContextPage
 *
 * @constructor Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ExecutionContextSolution} solution - Path solution
 *
 * @returns ExecutionContextPage
 */
/* jshint ignore:end */
ExecutionContextPage = function ExecutionContextPage(version, response,
                                                      solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ExecutionContextPage.prototype, Page.prototype);
ExecutionContextPage.prototype.constructor = ExecutionContextPage;

/* jshint ignore:start */
/**
 * Build an instance of ExecutionContextInstance
 *
 * @function getInstance
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextPage#
 *
 * @param {ExecutionContextPayload} payload - Payload response from the API
 *
 * @returns ExecutionContextInstance
 */
/* jshint ignore:end */
ExecutionContextPage.prototype.getInstance = function getInstance(payload) {
  return new ExecutionContextInstance(
    this._version,
    payload,
    this._solution.flowSid,
    this._solution.executionSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ExecutionContextPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ExecutionContextPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ExecutionContextContext
 *
 * @constructor Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {object} context - The current state of the flow
 * @property {string} flowSid - The SID of the Flow
 * @property {string} executionSid - The SID of the Execution
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V2} version - Version of the resource
 * @param {ExecutionContextPayload} payload - The instance payload
 * @param {sid} flowSid - The SID of the Flow
 * @param {sid} executionSid - The SID of the Execution
 */
/* jshint ignore:end */
ExecutionContextInstance = function ExecutionContextInstance(version, payload,
    flowSid, executionSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.context = payload.context; // jshint ignore:line
  this.flowSid = payload.flow_sid; // jshint ignore:line
  this.executionSid = payload.execution_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {flowSid: flowSid, executionSid: executionSid, };
};

Object.defineProperty(ExecutionContextInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ExecutionContextContext(
          this._version,
          this._solution.flowSid,
          this._solution.executionSid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ExecutionContextInstance
 *
 * @function fetch
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ExecutionContextInstance
 */
/* jshint ignore:end */
ExecutionContextInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ExecutionContextInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ExecutionContextInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ExecutionContextContext
 *
 * @constructor Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextContext
 *
 * @param {V2} version - Version of the resource
 * @param {sid} flowSid - The SID of the Flow
 * @param {sid} executionSid - The SID of the Execution
 */
/* jshint ignore:end */
ExecutionContextContext = function ExecutionContextContext(version, flowSid,
                                                            executionSid) {
  this._version = version;

  // Path Solution
  this._solution = {flowSid: flowSid, executionSid: executionSid, };
  this._uri = `/Flows/${flowSid}/Executions/${executionSid}/Context`;
};

/* jshint ignore:start */
/**
 * fetch a ExecutionContextInstance
 *
 * @function fetch
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ExecutionContextInstance
 */
/* jshint ignore:end */
ExecutionContextContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ExecutionContextInstance(
      this._version,
      payload,
      this._solution.flowSid,
      this._solution.executionSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.ExecutionContext.ExecutionContextContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ExecutionContextContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ExecutionContextContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ExecutionContextList: ExecutionContextList,
  ExecutionContextPage: ExecutionContextPage,
  ExecutionContextInstance: ExecutionContextInstance,
  ExecutionContextContext: ExecutionContextContext
};
