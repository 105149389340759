import React from 'react';
import {connect, useSelector} from "react-redux"



const LanguageHandlerFn = (data, default_lan, selected_lan, is_truncate, trun_val) => {
    let value = ""
    if(data[selected_lan]){
      value= data[selected_lan]
          }
          else if(data[default_lan]){
            value= data[default_lan]
          }else{
            value= ""
          }
            
        if(is_truncate){
          value = value.slice(0, trun_val)
        }
        return value

}

export default LanguageHandlerFn