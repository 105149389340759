'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var ConferenceParticipantList = require(
    './conference/conferenceParticipant').ConferenceParticipantList;
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ConferenceList;
var ConferencePage;
var ConferenceInstance;
var ConferenceContext;

/* jshint ignore:start */
/**
 * Initialize the ConferenceList
 *
 * @constructor Twilio.Insights.V1.ConferenceList
 *
 * @param {Twilio.Insights.V1} version - Version of the resource
 */
/* jshint ignore:end */
ConferenceList = function ConferenceList(version) {
  /* jshint ignore:start */
  /**
   * @function conferences
   * @memberof Twilio.Insights.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Insights.V1.ConferenceContext}
   */
  /* jshint ignore:end */
  function ConferenceListInstance(sid) {
    return ConferenceListInstance.get(sid);
  }

  ConferenceListInstance._version = version;
  // Path Solution
  ConferenceListInstance._solution = {};
  ConferenceListInstance._uri = `/Conferences`;
  /* jshint ignore:start */
  /**
   * Streams ConferenceInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.conferenceSid] - The SID of the conference.
   * @param {string} [opts.friendlyName] - Custom label for the conference.
   * @param {string} [opts.status] - Conference status.
   * @param {string} [opts.createdAfter] - Conferences created after timestamp.
   * @param {string} [opts.createdBefore] - Conferences created before timestamp.
   * @param {string} [opts.mixerRegion] - Region where the conference was mixed.
   * @param {string} [opts.tags] - Tags applied by Twilio for common issues.
   * @param {string} [opts.subaccount] - Account SID for the subaccount.
   * @param {string} [opts.detectedIssues] -
   *          Potential issues detected during the conference.
   * @param {string} [opts.endReason] - Conference end reason.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ConferenceListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ConferenceInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.conferenceSid] - The SID of the conference.
   * @param {string} [opts.friendlyName] - Custom label for the conference.
   * @param {string} [opts.status] - Conference status.
   * @param {string} [opts.createdAfter] - Conferences created after timestamp.
   * @param {string} [opts.createdBefore] - Conferences created before timestamp.
   * @param {string} [opts.mixerRegion] - Region where the conference was mixed.
   * @param {string} [opts.tags] - Tags applied by Twilio for common issues.
   * @param {string} [opts.subaccount] - Account SID for the subaccount.
   * @param {string} [opts.detectedIssues] -
   *          Potential issues detected during the conference.
   * @param {string} [opts.endReason] - Conference end reason.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConferenceListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ConferenceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.conferenceSid] - The SID of the conference.
   * @param {string} [opts.friendlyName] - Custom label for the conference.
   * @param {string} [opts.status] - Conference status.
   * @param {string} [opts.createdAfter] - Conferences created after timestamp.
   * @param {string} [opts.createdBefore] - Conferences created before timestamp.
   * @param {string} [opts.mixerRegion] - Region where the conference was mixed.
   * @param {string} [opts.tags] - Tags applied by Twilio for common issues.
   * @param {string} [opts.subaccount] - Account SID for the subaccount.
   * @param {string} [opts.detectedIssues] -
   *          Potential issues detected during the conference.
   * @param {string} [opts.endReason] - Conference end reason.
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConferenceListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'ConferenceSid': _.get(opts, 'conferenceSid'),
      'FriendlyName': _.get(opts, 'friendlyName'),
      'Status': _.get(opts, 'status'),
      'CreatedAfter': _.get(opts, 'createdAfter'),
      'CreatedBefore': _.get(opts, 'createdBefore'),
      'MixerRegion': _.get(opts, 'mixerRegion'),
      'Tags': _.get(opts, 'tags'),
      'Subaccount': _.get(opts, 'subaccount'),
      'DetectedIssues': _.get(opts, 'detectedIssues'),
      'EndReason': _.get(opts, 'endReason'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ConferencePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ConferenceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConferenceListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ConferencePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a conference
   *
   * @function get
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @param {string} conferenceSid - Conference SID.
   *
   * @returns {Twilio.Insights.V1.ConferenceContext}
   */
  /* jshint ignore:end */
  ConferenceListInstance.get = function get(conferenceSid) {
    return new ConferenceContext(this._version, conferenceSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Insights.V1.ConferenceList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ConferenceListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ConferenceListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return ConferenceListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ConferencePage
 *
 * @constructor Twilio.Insights.V1.ConferencePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ConferenceSolution} solution - Path solution
 *
 * @returns ConferencePage
 */
/* jshint ignore:end */
ConferencePage = function ConferencePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ConferencePage.prototype, Page.prototype);
ConferencePage.prototype.constructor = ConferencePage;

/* jshint ignore:start */
/**
 * Build an instance of ConferenceInstance
 *
 * @function getInstance
 * @memberof Twilio.Insights.V1.ConferencePage#
 *
 * @param {ConferencePayload} payload - Payload response from the API
 *
 * @returns ConferenceInstance
 */
/* jshint ignore:end */
ConferencePage.prototype.getInstance = function getInstance(payload) {
  return new ConferenceInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.ConferencePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConferencePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConferencePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConferenceContext
 *
 * @constructor Twilio.Insights.V1.ConferenceInstance
 *
 * @property {string} conferenceSid - Conference SID.
 * @property {string} accountSid - Account SID.
 * @property {string} friendlyName - Custom label for the conference.
 * @property {Date} createTime - Conference creation date/time.
 * @property {Date} startTime -
 *          Timestamp in ISO 8601 format when the conference started.
 * @property {Date} endTime - Conference end date/time.
 * @property {number} durationSeconds - Conference duration in seconds.
 * @property {number} connectDurationSeconds -
 *          Duration of the conference in seconds.
 * @property {conference.conference_status} status - Status of conference
 * @property {number} maxParticipants - Max participants specified in config.
 * @property {number} maxConcurrentParticipants -
 *          Actual maximum concurrent participants.
 * @property {number} uniqueParticipants - Unique conference participants.
 * @property {conference.conference_end_reason} endReason - Conference end reason.
 * @property {string} endedBy - Call SID that ended the conference.
 * @property {conference.region} mixerRegion -
 *          Region where the conference was mixed.
 * @property {conference.region} mixerRegionRequested -
 *          Configuration-requested conference mixer region.
 * @property {boolean} recordingEnabled -
 *          Boolean. Indicates whether recording was enabled.
 * @property {object} detectedIssues -
 *          Potential issues detected during the conference.
 * @property {conference.tag} tags -
 *          Tags for detected conference conditions and participant behaviors.
 * @property {object} tagInfo - Object. Contains details about conference tags.
 * @property {conference.processing_state} processingState -
 *          Processing state for the Conference Summary resource.
 * @property {string} url - The URL of this resource.
 * @property {string} links - Nested resource URLs.
 *
 * @param {V1} version - Version of the resource
 * @param {ConferencePayload} payload - The instance payload
 * @param {sid} conferenceSid - Conference SID.
 */
/* jshint ignore:end */
ConferenceInstance = function ConferenceInstance(version, payload,
                                                  conferenceSid) {
  this._version = version;

  // Marshaled Properties
  this.conferenceSid = payload.conference_sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.createTime = deserialize.iso8601DateTime(payload.create_time); // jshint ignore:line
  this.startTime = deserialize.iso8601DateTime(payload.start_time); // jshint ignore:line
  this.endTime = deserialize.iso8601DateTime(payload.end_time); // jshint ignore:line
  this.durationSeconds = deserialize.integer(payload.duration_seconds); // jshint ignore:line
  this.connectDurationSeconds = deserialize.integer(payload.connect_duration_seconds); // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.maxParticipants = deserialize.integer(payload.max_participants); // jshint ignore:line
  this.maxConcurrentParticipants = deserialize.integer(payload.max_concurrent_participants); // jshint ignore:line
  this.uniqueParticipants = deserialize.integer(payload.unique_participants); // jshint ignore:line
  this.endReason = payload.end_reason; // jshint ignore:line
  this.endedBy = payload.ended_by; // jshint ignore:line
  this.mixerRegion = payload.mixer_region; // jshint ignore:line
  this.mixerRegionRequested = payload.mixer_region_requested; // jshint ignore:line
  this.recordingEnabled = payload.recording_enabled; // jshint ignore:line
  this.detectedIssues = payload.detected_issues; // jshint ignore:line
  this.tags = payload.tags; // jshint ignore:line
  this.tagInfo = payload.tag_info; // jshint ignore:line
  this.processingState = payload.processing_state; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {conferenceSid: conferenceSid || this.conferenceSid, };
};

Object.defineProperty(ConferenceInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ConferenceContext(this._version, this._solution.conferenceSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ConferenceInstance
 *
 * @function fetch
 * @memberof Twilio.Insights.V1.ConferenceInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConferenceInstance
 */
/* jshint ignore:end */
ConferenceInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Access the conferenceParticipants
 *
 * @function conferenceParticipants
 * @memberof Twilio.Insights.V1.ConferenceInstance#
 *
 * @returns {Twilio.Insights.V1.ConferenceContext.ConferenceParticipantList}
 */
/* jshint ignore:end */
ConferenceInstance.prototype.conferenceParticipants = function
    conferenceParticipants() {
  return this._proxy.conferenceParticipants;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.ConferenceInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConferenceInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConferenceInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConferenceContext
 *
 * @constructor Twilio.Insights.V1.ConferenceContext
 *
 * @property {Twilio.Insights.V1.ConferenceContext.ConferenceParticipantList} conferenceParticipants -
 *          conferenceParticipants resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} conferenceSid - Conference SID.
 */
/* jshint ignore:end */
ConferenceContext = function ConferenceContext(version, conferenceSid) {
  this._version = version;

  // Path Solution
  this._solution = {conferenceSid: conferenceSid, };
  this._uri = `/Conferences/${conferenceSid}`;

  // Dependents
  this._conferenceParticipants = undefined;
};

/* jshint ignore:start */
/**
 * fetch a ConferenceInstance
 *
 * @function fetch
 * @memberof Twilio.Insights.V1.ConferenceContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConferenceInstance
 */
/* jshint ignore:end */
ConferenceContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConferenceInstance(this._version, payload, this._solution.conferenceSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ConferenceContext.prototype,
  'conferenceParticipants', {
    get: function() {
      if (!this._conferenceParticipants) {
        this._conferenceParticipants = new ConferenceParticipantList(
          this._version,
          this._solution.conferenceSid
        );
      }
      return this._conferenceParticipants;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.ConferenceContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConferenceContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ConferenceContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ConferenceList: ConferenceList,
  ConferencePage: ConferencePage,
  ConferenceInstance: ConferenceInstance,
  ConferenceContext: ConferenceContext
};
