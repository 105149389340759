import React, {Component} from 'react';
import {TouchableOpacity} from "react-native";
import {View} from "react-native";
// import Icono from '@expo/vector-icons/Ionicons';
// import Ant from '@expo/vector-icons/AntDesign';

import Icon from './Warning'

import {style} from "../../assets/style";

// import {BACK} from "../utils/languages";


const backButton = (props) => {
    return(
            <View style={[style.backButton,{backgroundColor:props.backgroundColor,border:props.border_color}]}>
                {/* {
                    props.detail_page ?
                        <Ant name={'close'} style={{fontSize: 30, color: props.color}}/>
                        :
                        <Icono name="ios-arrow-back" style={{fontSize: 30, color: props.color}}/>

                } */}
                 {
                    props.detail_page ?
                        <Icon name={'close'} style={{fontSize: 30, color: props.color}}/>
                        :
                        <i
                            style={{ color: props.color, fontSize: 30, }}
                        class="fas fa-angle-left"
                        ></i>
                        // <Icon name={'back'} style={{fontSize: 30, color: props.color}}/>

                }
                

            </View>
    )
};
export default backButton;