'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var TaskQueueCumulativeStatisticsList = require(
    './taskQueue/taskQueueCumulativeStatistics').TaskQueueCumulativeStatisticsList;
var TaskQueueRealTimeStatisticsList = require(
    './taskQueue/taskQueueRealTimeStatistics').TaskQueueRealTimeStatisticsList;
var TaskQueueStatisticsList = require(
    './taskQueue/taskQueueStatistics').TaskQueueStatisticsList;
var TaskQueuesStatisticsList = require(
    './taskQueue/taskQueuesStatistics').TaskQueuesStatisticsList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var TaskQueueList;
var TaskQueuePage;
var TaskQueueInstance;
var TaskQueueContext;

/* jshint ignore:start */
/**
 * Initialize the TaskQueueList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that contains the TaskQueue
 */
/* jshint ignore:end */
TaskQueueList = function TaskQueueList(version, workspaceSid) {
  /* jshint ignore:start */
  /**
   * @function taskQueues
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext}
   */
  /* jshint ignore:end */
  function TaskQueueListInstance(sid) {
    return TaskQueueListInstance.get(sid);
  }

  TaskQueueListInstance._version = version;
  // Path Solution
  TaskQueueListInstance._solution = {workspaceSid: workspaceSid};
  TaskQueueListInstance._uri = `/Workspaces/${workspaceSid}/TaskQueues`;

  // Components
  TaskQueueListInstance._statistics = undefined;

  /* jshint ignore:start */
  /**
   * Streams TaskQueueInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the TaskQueue resources to read
   * @param {string} [opts.evaluateWorkerAttributes] -
   *          The attributes of the Workers to read
   * @param {string} [opts.workerSid] -
   *          The SID of the Worker with the TaskQueue resources to read
   * @param {string} [opts.ordering] - Sorting parameter for TaskQueues
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  TaskQueueListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists TaskQueueInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the TaskQueue resources to read
   * @param {string} [opts.evaluateWorkerAttributes] -
   *          The attributes of the Workers to read
   * @param {string} [opts.workerSid] -
   *          The SID of the Worker with the TaskQueue resources to read
   * @param {string} [opts.ordering] - Sorting parameter for TaskQueues
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskQueueListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of TaskQueueInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the TaskQueue resources to read
   * @param {string} [opts.evaluateWorkerAttributes] -
   *          The attributes of the Workers to read
   * @param {string} [opts.workerSid] -
   *          The SID of the Worker with the TaskQueue resources to read
   * @param {string} [opts.ordering] - Sorting parameter for TaskQueues
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskQueueListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'EvaluateWorkerAttributes': _.get(opts, 'evaluateWorkerAttributes'),
      'WorkerSid': _.get(opts, 'workerSid'),
      'Ordering': _.get(opts, 'ordering'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskQueuePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of TaskQueueInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskQueueListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskQueuePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a TaskQueueInstance
   *
   * @function create
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.friendlyName - A string to describe the resource
   * @param {string} [opts.targetWorkers] -
   *          A string describing the Worker selection criteria for any Tasks that enter the TaskQueue
   * @param {number} [opts.maxReservedWorkers] -
   *          The maximum number of Workers to reserve
   * @param {task_queue.task_order} [opts.taskOrder] -
   *          How Tasks will be assigned to Workers
   * @param {string} [opts.reservationActivitySid] -
   *          The SID of the Activity to assign Workers when a task is reserved for them
   * @param {string} [opts.assignmentActivitySid] -
   *          The SID of the Activity to assign Workers once a task is assigned to them
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TaskQueueInstance
   */
  /* jshint ignore:end */
  TaskQueueListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['friendlyName'])) {
      throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'TargetWorkers': _.get(opts, 'targetWorkers'),
      'MaxReservedWorkers': _.get(opts, 'maxReservedWorkers'),
      'TaskOrder': _.get(opts, 'taskOrder'),
      'ReservationActivitySid': _.get(opts, 'reservationActivitySid'),
      'AssignmentActivitySid': _.get(opts, 'assignmentActivitySid')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskQueueInstance(
        this._version,
        payload,
        this._solution.workspaceSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a task_queue
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @param {string} sid - The SID of the resource to
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext}
   */
  /* jshint ignore:end */
  TaskQueueListInstance.get = function get(sid) {
    return new TaskQueueContext(this._version, this._solution.workspaceSid, sid);
  };

  Object.defineProperty(TaskQueueListInstance,
    'statistics', {
      get: function statistics() {
        if (!this._statistics) {
          this._statistics = new TaskQueuesStatisticsList(this._version, this._solution.workspaceSid);
        }

        return this._statistics;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TaskQueueListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TaskQueueListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return TaskQueueListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TaskQueuePage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskQueuePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TaskQueueSolution} solution - Path solution
 *
 * @returns TaskQueuePage
 */
/* jshint ignore:end */
TaskQueuePage = function TaskQueuePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TaskQueuePage.prototype, Page.prototype);
TaskQueuePage.prototype.constructor = TaskQueuePage;

/* jshint ignore:start */
/**
 * Build an instance of TaskQueueInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueuePage#
 *
 * @param {TaskQueuePayload} payload - Payload response from the API
 *
 * @returns TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueuePage.prototype.getInstance = function getInstance(payload) {
  return new TaskQueueInstance(this._version, payload, this._solution.workspaceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueuePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskQueuePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskQueuePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskQueueContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} assignmentActivitySid -
 *          The SID of the Activity to assign Workers when a task is assigned for them
 * @property {string} assignmentActivityName -
 *          The name of the Activity to assign Workers when a task is assigned for them
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT when the resource was last updated
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {number} maxReservedWorkers - The maximum number of Workers to reserve
 * @property {string} reservationActivitySid -
 *          The SID of the Activity to assign Workers once a task is reserved for them
 * @property {string} reservationActivityName -
 *          The name of the Activity to assign Workers once a task is reserved for them
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} targetWorkers -
 *          A string describing the Worker selection criteria for any Tasks that enter the TaskQueue
 * @property {task_queue.task_order} taskOrder -
 *          How Tasks will be assigned to Workers
 * @property {string} url - The absolute URL of the TaskQueue resource
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the TaskQueue
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {TaskQueuePayload} payload - The instance payload
 * @param {sid} workspaceSid - The SID of the Workspace that contains the TaskQueue
 * @param {sid} sid - The SID of the resource to
 */
/* jshint ignore:end */
TaskQueueInstance = function TaskQueueInstance(version, payload, workspaceSid,
                                                sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assignmentActivitySid = payload.assignment_activity_sid; // jshint ignore:line
  this.assignmentActivityName = payload.assignment_activity_name; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.maxReservedWorkers = deserialize.integer(payload.max_reserved_workers); // jshint ignore:line
  this.reservationActivitySid = payload.reservation_activity_sid; // jshint ignore:line
  this.reservationActivityName = payload.reservation_activity_name; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.targetWorkers = payload.target_workers; // jshint ignore:line
  this.taskOrder = payload.task_order; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, sid: sid || this.sid, };
};

Object.defineProperty(TaskQueueInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TaskQueueContext(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TaskQueueInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a TaskQueueInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.targetWorkers] -
 *          A string describing the Worker selection criteria for any Tasks that enter the TaskQueue
 * @param {string} [opts.reservationActivitySid] -
 *          The SID of the Activity to assign Workers when a task is reserved for them
 * @param {string} [opts.assignmentActivitySid] -
 *          The SID of the Activity to assign Workers when a task is assigned for them
 * @param {number} [opts.maxReservedWorkers] -
 *          The maximum number of Workers to create reservations for the assignment of a task while in the queue
 * @param {task_queue.task_order} [opts.taskOrder] -
 *          How Tasks will be assigned to Workers
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a TaskQueueInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Access the statistics
 *
 * @function statistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueStatisticsList}
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.statistics = function statistics() {
  return this._proxy.statistics;
};

/* jshint ignore:start */
/**
 * Access the realTimeStatistics
 *
 * @function realTimeStatistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueRealTimeStatisticsList}
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.realTimeStatistics = function realTimeStatistics() {
  return this._proxy.realTimeStatistics;
};

/* jshint ignore:start */
/**
 * Access the cumulativeStatistics
 *
 * @function cumulativeStatistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueCumulativeStatisticsList}
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.cumulativeStatistics = function
    cumulativeStatistics() {
  return this._proxy.cumulativeStatistics;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskQueueInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskQueueInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskQueueContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext
 *
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueStatisticsList} statistics -
 *          statistics resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueRealTimeStatisticsList} realTimeStatistics -
 *          realTimeStatistics resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext.TaskQueueCumulativeStatisticsList} cumulativeStatistics -
 *          cumulativeStatistics resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid - The SID of the Workspace with the TaskQueue to fetch
 * @param {sid} sid - The SID of the resource to
 */
/* jshint ignore:end */
TaskQueueContext = function TaskQueueContext(version, workspaceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, sid: sid, };
  this._uri = `/Workspaces/${workspaceSid}/TaskQueues/${sid}`;

  // Dependents
  this._statistics = undefined;
  this._realTimeStatistics = undefined;
  this._cumulativeStatistics = undefined;
};

/* jshint ignore:start */
/**
 * fetch a TaskQueueInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskQueueInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a TaskQueueInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.targetWorkers] -
 *          A string describing the Worker selection criteria for any Tasks that enter the TaskQueue
 * @param {string} [opts.reservationActivitySid] -
 *          The SID of the Activity to assign Workers when a task is reserved for them
 * @param {string} [opts.assignmentActivitySid] -
 *          The SID of the Activity to assign Workers when a task is assigned for them
 * @param {number} [opts.maxReservedWorkers] -
 *          The maximum number of Workers to create reservations for the assignment of a task while in the queue
 * @param {task_queue.task_order} [opts.taskOrder] -
 *          How Tasks will be assigned to Workers
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'TargetWorkers': _.get(opts, 'targetWorkers'),
    'ReservationActivitySid': _.get(opts, 'reservationActivitySid'),
    'AssignmentActivitySid': _.get(opts, 'assignmentActivitySid'),
    'MaxReservedWorkers': _.get(opts, 'maxReservedWorkers'),
    'TaskOrder': _.get(opts, 'taskOrder')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskQueueInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a TaskQueueInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskQueueInstance
 */
/* jshint ignore:end */
TaskQueueContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(TaskQueueContext.prototype,
  'statistics', {
    get: function() {
      if (!this._statistics) {
        this._statistics = new TaskQueueStatisticsList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._statistics;
    }
});

Object.defineProperty(TaskQueueContext.prototype,
  'realTimeStatistics', {
    get: function() {
      if (!this._realTimeStatistics) {
        this._realTimeStatistics = new TaskQueueRealTimeStatisticsList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._realTimeStatistics;
    }
});

Object.defineProperty(TaskQueueContext.prototype,
  'cumulativeStatistics', {
    get: function() {
      if (!this._cumulativeStatistics) {
        this._cumulativeStatistics = new TaskQueueCumulativeStatisticsList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._cumulativeStatistics;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskQueueContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskQueueContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TaskQueueContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TaskQueueList: TaskQueueList,
  TaskQueuePage: TaskQueuePage,
  TaskQueueInstance: TaskQueueInstance,
  TaskQueueContext: TaskQueueContext
};
