'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./trusthub/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize trusthub domain
 *
 * @constructor Twilio.Trusthub
 *
 * @property {Twilio.Trusthub.V1} v1 - v1 version
 * @property {Twilio.Trusthub.V1.CustomerProfilesList} customerProfiles -
 *          customerProfiles resource
 * @property {Twilio.Trusthub.V1.EndUserList} endUsers - endUsers resource
 * @property {Twilio.Trusthub.V1.EndUserTypeList} endUserTypes -
 *          endUserTypes resource
 * @property {Twilio.Trusthub.V1.PoliciesList} policies - policies resource
 * @property {Twilio.Trusthub.V1.SupportingDocumentList} supportingDocuments -
 *          supportingDocuments resource
 * @property {Twilio.Trusthub.V1.SupportingDocumentTypeList} supportingDocumentTypes -
 *          supportingDocumentTypes resource
 * @property {Twilio.Trusthub.V1.TrustProductsList} trustProducts -
 *          trustProducts resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Trusthub(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://trusthub.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(Trusthub.prototype, Domain.prototype);
Trusthub.prototype.constructor = Trusthub;

Object.defineProperty(Trusthub.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(Trusthub.prototype,
  'customerProfiles', {
    get: function() {
      return this.v1.customerProfiles;
    }
});

Object.defineProperty(Trusthub.prototype,
  'endUsers', {
    get: function() {
      return this.v1.endUsers;
    }
});

Object.defineProperty(Trusthub.prototype,
  'endUserTypes', {
    get: function() {
      return this.v1.endUserTypes;
    }
});

Object.defineProperty(Trusthub.prototype,
  'policies', {
    get: function() {
      return this.v1.policies;
    }
});

Object.defineProperty(Trusthub.prototype,
  'supportingDocuments', {
    get: function() {
      return this.v1.supportingDocuments;
    }
});

Object.defineProperty(Trusthub.prototype,
  'supportingDocumentTypes', {
    get: function() {
      return this.v1.supportingDocumentTypes;
    }
});

Object.defineProperty(Trusthub.prototype,
  'trustProducts', {
    get: function() {
      return this.v1.trustProducts;
    }
});

module.exports = Trusthub;
