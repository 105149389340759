"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _waitForVar = _interopRequireDefault(require("../utils/waitForVar"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var APPLE_SCRIPT_SRC = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
/**
 * Performs an apple ID signIn operation
 */

var signIn = function signIn(_ref) {
  var authOptions = _ref.authOptions,
      onSuccess = _ref.onSuccess,
      onError = _ref.onError;
  return (
    /** wait for apple sript to load */
    (0, _waitForVar.default)('AppleID').then(function () {
      /** Handle if appleID script was not loaded -- log + throw error to be caught below */
      if (!window.AppleID) {
        console.error(new Error('Error loading apple script'));
      }
      /** Init apple auth */


      window.AppleID.auth.init(authOptions);
      /** Signin to appleID */

      return window.AppleID.auth.signIn().then(function (response) {
        /** This is only called in case usePopup is true */
        if (onSuccess) {
          onSuccess(response);
        }
        /** resolve with the reponse */


        return response;
      }).catch(function (err) {
        if (onError) {
          /** Call onError catching the error */
          onError(err);
        } else {
          /** Log the error to help debug */
          console.error(err);
        }

        return null;
      });
    }).catch(function (err) {
      if (onError) {
        /** Call onError catching the error */
        onError(err);
      } else {
        /** Log the error to help debug */
        console.error(err);
      }

      return null;
    })
  );
};

var _default = {
  APPLE_SCRIPT_SRC: APPLE_SCRIPT_SRC,
  signIn: signIn
};
exports.default = _default;