'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ConfigurationList;
var ConfigurationPage;
var ConfigurationInstance;
var ConfigurationContext;

/* jshint ignore:start */
/**
 * Initialize the ConfigurationList
 *
 * @constructor Twilio.FlexApi.V1.ConfigurationList
 *
 * @param {Twilio.FlexApi.V1} version - Version of the resource
 */
/* jshint ignore:end */
ConfigurationList = function ConfigurationList(version) {
  /* jshint ignore:start */
  /**
   * @function configuration
   * @memberof Twilio.FlexApi.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.FlexApi.V1.ConfigurationContext}
   */
  /* jshint ignore:end */
  function ConfigurationListInstance(sid) {
    return ConfigurationListInstance.get(sid);
  }

  ConfigurationListInstance._version = version;
  // Path Solution
  ConfigurationListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a configuration
   *
   * @function get
   * @memberof Twilio.FlexApi.V1.ConfigurationList#
   *
   * @returns {Twilio.FlexApi.V1.ConfigurationContext}
   */
  /* jshint ignore:end */
  ConfigurationListInstance.get = function get() {
    return new ConfigurationContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.FlexApi.V1.ConfigurationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ConfigurationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ConfigurationListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return ConfigurationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationPage
 *
 * @constructor Twilio.FlexApi.V1.ConfigurationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ConfigurationSolution} solution - Path solution
 *
 * @returns ConfigurationPage
 */
/* jshint ignore:end */
ConfigurationPage = function ConfigurationPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ConfigurationPage.prototype, Page.prototype);
ConfigurationPage.prototype.constructor = ConfigurationPage;

/* jshint ignore:start */
/**
 * Build an instance of ConfigurationInstance
 *
 * @function getInstance
 * @memberof Twilio.FlexApi.V1.ConfigurationPage#
 *
 * @param {ConfigurationPayload} payload - Payload response from the API
 *
 * @returns ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationPage.prototype.getInstance = function getInstance(payload) {
  return new ConfigurationInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ConfigurationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConfigurationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationContext
 *
 * @constructor Twilio.FlexApi.V1.ConfigurationInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the Configuration resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the Configuration resource was last updated
 * @property {object} attributes -
 *          An object that contains application-specific data
 * @property {configuration.status} status - The status of the Flex onboarding
 * @property {string} taskrouterWorkspaceSid - The SID of the TaskRouter Workspace
 * @property {string} taskrouterTargetWorkflowSid -
 *          The SID of the TaskRouter target Workflow
 * @property {string} taskrouterTargetTaskqueueSid -
 *          The SID of the TaskRouter Target TaskQueue
 * @property {object} taskrouterTaskqueues - The list of TaskRouter TaskQueues
 * @property {object} taskrouterSkills -
 *          The Skill description for TaskRouter workers
 * @property {object} taskrouterWorkerChannels -
 *          The TaskRouter default channel capacities and availability for workers
 * @property {object} taskrouterWorkerAttributes - The TaskRouter Worker attributes
 * @property {string} taskrouterOfflineActivitySid -
 *          The TaskRouter SID of the offline activity
 * @property {string} runtimeDomain - The URL where the Flex instance is hosted
 * @property {string} messagingServiceInstanceSid -
 *          The SID of the Messaging service instance
 * @property {string} chatServiceInstanceSid -
 *          The SID of the chat service this user belongs to
 * @property {string} flexServiceInstanceSid - The SID of the Flex service instance
 * @property {string} uiLanguage - The primary language of the Flex UI
 * @property {object} uiAttributes -
 *          The object that describes Flex UI characteristics and settings
 * @property {object} uiDependencies -
 *          The object that defines the NPM packages and versions to be used in Hosted Flex
 * @property {string} uiVersion - The Pinned UI version
 * @property {string} serviceVersion - The Flex Service version
 * @property {boolean} callRecordingEnabled - Whether call recording is enabled
 * @property {string} callRecordingWebhookUrl - The call recording webhook URL
 * @property {boolean} crmEnabled - Whether CRM is present for Flex
 * @property {string} crmType - The CRM Type
 * @property {string} crmCallbackUrl - The CRM Callback URL
 * @property {string} crmFallbackUrl - The CRM Fallback URL
 * @property {object} crmAttributes - An object that contains the CRM attributes
 * @property {object} publicAttributes - The list of public attributes
 * @property {boolean} pluginServiceEnabled - Whether the plugin service enabled
 * @property {object} pluginServiceAttributes - The plugin service attributes
 * @property {object} integrations -
 *          A list of objects that contain the configurations for the Integrations supported in this configuration
 * @property {object} outboundCallFlows - The list of outbound call flows
 * @property {string} serverlessServiceSids - The list of serverless service SIDs
 * @property {object} queueStatsConfiguration -
 *          Configurable parameters for Queues Statistics
 * @property {object} notifications - Configurable parameters for Notifications
 * @property {object} markdown - Configurable parameters for Markdown
 * @property {string} url - The absolute URL of the Configuration resource
 * @property {object} flexInsightsHr - Object that controls workspace reporting
 * @property {boolean} flexInsightsDrilldown -
 *          Setting to enable Flex UI redirection
 * @property {string} flexUrl - URL to redirect to in case drilldown is enabled.
 * @property {object} channelConfigs -
 *          Flex Conversations channels' attachments configurations
 * @property {object} debuggerIntegration -
 *          Configurable parameters for Debugger Integration
 * @property {object} flexUiStatusReport -
 *          Configurable parameters for Flex UI Status report
 *
 * @param {V1} version - Version of the resource
 * @param {ConfigurationPayload} payload - The instance payload
 */
/* jshint ignore:end */
ConfigurationInstance = function ConfigurationInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.attributes = payload.attributes; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.taskrouterWorkspaceSid = payload.taskrouter_workspace_sid; // jshint ignore:line
  this.taskrouterTargetWorkflowSid = payload.taskrouter_target_workflow_sid; // jshint ignore:line
  this.taskrouterTargetTaskqueueSid = payload.taskrouter_target_taskqueue_sid; // jshint ignore:line
  this.taskrouterTaskqueues = payload.taskrouter_taskqueues; // jshint ignore:line
  this.taskrouterSkills = payload.taskrouter_skills; // jshint ignore:line
  this.taskrouterWorkerChannels = payload.taskrouter_worker_channels; // jshint ignore:line
  this.taskrouterWorkerAttributes = payload.taskrouter_worker_attributes; // jshint ignore:line
  this.taskrouterOfflineActivitySid = payload.taskrouter_offline_activity_sid; // jshint ignore:line
  this.runtimeDomain = payload.runtime_domain; // jshint ignore:line
  this.messagingServiceInstanceSid = payload.messaging_service_instance_sid; // jshint ignore:line
  this.chatServiceInstanceSid = payload.chat_service_instance_sid; // jshint ignore:line
  this.flexServiceInstanceSid = payload.flex_service_instance_sid; // jshint ignore:line
  this.uiLanguage = payload.ui_language; // jshint ignore:line
  this.uiAttributes = payload.ui_attributes; // jshint ignore:line
  this.uiDependencies = payload.ui_dependencies; // jshint ignore:line
  this.uiVersion = payload.ui_version; // jshint ignore:line
  this.serviceVersion = payload.service_version; // jshint ignore:line
  this.callRecordingEnabled = payload.call_recording_enabled; // jshint ignore:line
  this.callRecordingWebhookUrl = payload.call_recording_webhook_url; // jshint ignore:line
  this.crmEnabled = payload.crm_enabled; // jshint ignore:line
  this.crmType = payload.crm_type; // jshint ignore:line
  this.crmCallbackUrl = payload.crm_callback_url; // jshint ignore:line
  this.crmFallbackUrl = payload.crm_fallback_url; // jshint ignore:line
  this.crmAttributes = payload.crm_attributes; // jshint ignore:line
  this.publicAttributes = payload.public_attributes; // jshint ignore:line
  this.pluginServiceEnabled = payload.plugin_service_enabled; // jshint ignore:line
  this.pluginServiceAttributes = payload.plugin_service_attributes; // jshint ignore:line
  this.integrations = payload.integrations; // jshint ignore:line
  this.outboundCallFlows = payload.outbound_call_flows; // jshint ignore:line
  this.serverlessServiceSids = payload.serverless_service_sids; // jshint ignore:line
  this.queueStatsConfiguration = payload.queue_stats_configuration; // jshint ignore:line
  this.notifications = payload.notifications; // jshint ignore:line
  this.markdown = payload.markdown; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.flexInsightsHr = payload.flex_insights_hr; // jshint ignore:line
  this.flexInsightsDrilldown = payload.flex_insights_drilldown; // jshint ignore:line
  this.flexUrl = payload.flex_url; // jshint ignore:line
  this.channelConfigs = payload.channel_configs; // jshint ignore:line
  this.debuggerIntegration = payload.debugger_integration; // jshint ignore:line
  this.flexUiStatusReport = payload.flex_ui_status_report; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(ConfigurationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ConfigurationContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ConfigurationInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.ConfigurationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.uiVersion] -
 *          The Pinned UI version of the Configuration resource to fetch
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.fetch = function fetch(opts, callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * create a ConfigurationInstance
 *
 * @function create
 * @memberof Twilio.FlexApi.V1.ConfigurationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.create = function create(callback) {
  return this._proxy.create(callback);
};

/* jshint ignore:start */
/**
 * update a ConfigurationInstance
 *
 * @function update
 * @memberof Twilio.FlexApi.V1.ConfigurationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.update = function update(callback) {
  return this._proxy.update(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ConfigurationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConfigurationInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationContext
 *
 * @constructor Twilio.FlexApi.V1.ConfigurationContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
ConfigurationContext = function ConfigurationContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/Configuration`;
};

/* jshint ignore:start */
/**
 * fetch a ConfigurationInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.ConfigurationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.uiVersion] -
 *          The Pinned UI version of the Configuration resource to fetch
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationContext.prototype.fetch = function fetch(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'UiVersion': _.get(opts, 'uiVersion')});

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConfigurationInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * create a ConfigurationInstance
 *
 * @function create
 * @memberof Twilio.FlexApi.V1.ConfigurationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationContext.prototype.create = function create(callback) {
  var deferred = Q.defer();
  var promise = this._version.create({uri: this._uri, method: 'POST'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConfigurationInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ConfigurationInstance
 *
 * @function update
 * @memberof Twilio.FlexApi.V1.ConfigurationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationContext.prototype.update = function update(callback) {
  var deferred = Q.defer();
  var promise = this._version.update({uri: this._uri, method: 'POST'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConfigurationInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ConfigurationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ConfigurationContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ConfigurationList: ConfigurationList,
  ConfigurationPage: ConfigurationPage,
  ConfigurationInstance: ConfigurationInstance,
  ConfigurationContext: ConfigurationContext
};
