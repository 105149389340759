'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var MessageList = require('./conversation/message').MessageList;
var Page = require('../../../base/Page');  /* jshint ignore:line */
var ParticipantList = require('./conversation/participant').ParticipantList;
var WebhookList = require('./conversation/webhook').WebhookList;
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ConversationList;
var ConversationPage;
var ConversationInstance;
var ConversationContext;

/* jshint ignore:start */
/**
 * Initialize the ConversationList
 *
 * @constructor Twilio.Conversations.V1.ConversationList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 */
/* jshint ignore:end */
ConversationList = function ConversationList(version) {
  /* jshint ignore:start */
  /**
   * @function conversations
   * @memberof Twilio.Conversations.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ConversationContext}
   */
  /* jshint ignore:end */
  function ConversationListInstance(sid) {
    return ConversationListInstance.get(sid);
  }

  ConversationListInstance._version = version;
  // Path Solution
  ConversationListInstance._solution = {};
  ConversationListInstance._uri = `/Conversations`;
  /* jshint ignore:start */
  /**
   * create a ConversationInstance
   *
   * @function create
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.friendlyName] -
   *          The human-readable name of this conversation.
   * @param {string} [opts.uniqueName] -
   *          An application-defined string that uniquely identifies the resource
   * @param {Date} [opts.dateCreated] - The date that this resource was created.
   * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
   * @param {string} [opts.messagingServiceSid] -
   *          The unique ID of the Messaging Service this conversation belongs to.
   * @param {string} [opts.attributes] -
   *          An optional string metadata field you can use to store any data you wish.
   * @param {conversation.state} [opts.state] - Current state of this conversation.
   * @param {string} [opts.timers.inactive] -
   *          ISO8601 duration when conversation will be switched to `inactive` state.
   * @param {string} [opts.timers.closed] -
   *          ISO8601 duration when conversation will be switched to `closed` state.
   * @param {conversation.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
   *          The X-Twilio-Webhook-Enabled HTTP request header
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ConversationInstance
   */
  /* jshint ignore:end */
  ConversationListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'UniqueName': _.get(opts, 'uniqueName'),
      'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
      'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
      'MessagingServiceSid': _.get(opts, 'messagingServiceSid'),
      'Attributes': _.get(opts, 'attributes'),
      'State': _.get(opts, 'state'),
      'Timers.Inactive': _.get(opts, 'timers.inactive'),
      'Timers.Closed': _.get(opts, 'timers.closed')
    });
    var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data, headers: headers});

    promise = promise.then(function(payload) {
      deferred.resolve(new ConversationInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams ConversationInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ConversationListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ConversationInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConversationListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ConversationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConversationListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ConversationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ConversationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ConversationListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ConversationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a conversation
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @param {string} sid -
   *          A 34 character string that uniquely identifies this resource.
   *
   * @returns {Twilio.Conversations.V1.ConversationContext}
   */
  /* jshint ignore:end */
  ConversationListInstance.get = function get(sid) {
    return new ConversationContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ConversationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ConversationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ConversationListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ConversationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ConversationPage
 *
 * @constructor Twilio.Conversations.V1.ConversationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ConversationSolution} solution - Path solution
 *
 * @returns ConversationPage
 */
/* jshint ignore:end */
ConversationPage = function ConversationPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ConversationPage.prototype, Page.prototype);
ConversationPage.prototype.constructor = ConversationPage;

/* jshint ignore:start */
/**
 * Build an instance of ConversationInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ConversationPage#
 *
 * @param {ConversationPayload} payload - Payload response from the API
 *
 * @returns ConversationInstance
 */
/* jshint ignore:end */
ConversationPage.prototype.getInstance = function getInstance(payload) {
  return new ConversationInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConversationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConversationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConversationContext
 *
 * @constructor Twilio.Conversations.V1.ConversationInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account responsible for this conversation.
 * @property {string} chatServiceSid -
 *          The unique ID of the Conversation Service this conversation belongs to.
 * @property {string} messagingServiceSid -
 *          The unique ID of the Messaging Service this conversation belongs to.
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {string} friendlyName - The human-readable name of this conversation.
 * @property {string} uniqueName -
 *          An application-defined string that uniquely identifies the resource
 * @property {string} attributes -
 *          An optional string metadata field you can use to store any data you wish.
 * @property {conversation.state} state - Current state of this conversation.
 * @property {Date} dateCreated - The date that this resource was created.
 * @property {Date} dateUpdated - The date that this resource was last updated.
 * @property {object} timers - Timer date values for this conversation.
 * @property {string} url - An absolute URL for this conversation.
 * @property {string} links -
 *          Absolute URLs to access the participants, messages and webhooks of this conversation.
 * @property {object} bindings - The bindings
 *
 * @param {V1} version - Version of the resource
 * @param {ConversationPayload} payload - The instance payload
 * @param {sid_like} sid -
 *          A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ConversationInstance = function ConversationInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.chatServiceSid = payload.chat_service_sid; // jshint ignore:line
  this.messagingServiceSid = payload.messaging_service_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.attributes = payload.attributes; // jshint ignore:line
  this.state = payload.state; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.timers = payload.timers; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line
  this.bindings = payload.bindings; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(ConversationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ConversationContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a ConversationInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          The human-readable name of this conversation.
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.attributes] -
 *          An optional string metadata field you can use to store any data you wish.
 * @param {string} [opts.messagingServiceSid] -
 *          The unique ID of the Messaging Service this conversation belongs to.
 * @param {conversation.state} [opts.state] - Current state of this conversation.
 * @param {string} [opts.timers.inactive] -
 *          ISO8601 duration when conversation will be switched to `inactive` state.
 * @param {string} [opts.timers.closed] -
 *          ISO8601 duration when conversation will be switched to `closed` state.
 * @param {string} [opts.uniqueName] -
 *          An application-defined string that uniquely identifies the resource
 * @param {conversation.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a ConversationInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {conversation.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationInstance.prototype.remove = function remove(opts, callback) {
  return this._proxy.remove(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a ConversationInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Access the participants
 *
 * @function participants
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @returns {Twilio.Conversations.V1.ConversationContext.ParticipantList}
 */
/* jshint ignore:end */
ConversationInstance.prototype.participants = function participants() {
  return this._proxy.participants;
};

/* jshint ignore:start */
/**
 * Access the messages
 *
 * @function messages
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @returns {Twilio.Conversations.V1.ConversationContext.MessageList}
 */
/* jshint ignore:end */
ConversationInstance.prototype.messages = function messages() {
  return this._proxy.messages;
};

/* jshint ignore:start */
/**
 * Access the webhooks
 *
 * @function webhooks
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @returns {Twilio.Conversations.V1.ConversationContext.WebhookList}
 */
/* jshint ignore:end */
ConversationInstance.prototype.webhooks = function webhooks() {
  return this._proxy.webhooks;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConversationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConversationInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConversationContext
 *
 * @constructor Twilio.Conversations.V1.ConversationContext
 *
 * @property {Twilio.Conversations.V1.ConversationContext.ParticipantList} participants -
 *          participants resource
 * @property {Twilio.Conversations.V1.ConversationContext.MessageList} messages -
 *          messages resource
 * @property {Twilio.Conversations.V1.ConversationContext.WebhookList} webhooks -
 *          webhooks resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} sid -
 *          A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ConversationContext = function ConversationContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Conversations/${sid}`;

  // Dependents
  this._participants = undefined;
  this._messages = undefined;
  this._webhooks = undefined;
};

/* jshint ignore:start */
/**
 * update a ConversationInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ConversationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          The human-readable name of this conversation.
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.attributes] -
 *          An optional string metadata field you can use to store any data you wish.
 * @param {string} [opts.messagingServiceSid] -
 *          The unique ID of the Messaging Service this conversation belongs to.
 * @param {conversation.state} [opts.state] - Current state of this conversation.
 * @param {string} [opts.timers.inactive] -
 *          ISO8601 duration when conversation will be switched to `inactive` state.
 * @param {string} [opts.timers.closed] -
 *          ISO8601 duration when conversation will be switched to `closed` state.
 * @param {string} [opts.uniqueName] -
 *          An application-defined string that uniquely identifies the resource
 * @param {conversation.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
    'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
    'Attributes': _.get(opts, 'attributes'),
    'MessagingServiceSid': _.get(opts, 'messagingServiceSid'),
    'State': _.get(opts, 'state'),
    'Timers.Inactive': _.get(opts, 'timers.inactive'),
    'Timers.Closed': _.get(opts, 'timers.closed'),
    'UniqueName': _.get(opts, 'uniqueName')
  });
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data, headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConversationInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a ConversationInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ConversationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {conversation.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationContext.prototype.remove = function remove(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.remove({uri: this._uri, method: 'DELETE', headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a ConversationInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ConversationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConversationInstance
 */
/* jshint ignore:end */
ConversationContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConversationInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ConversationContext.prototype,
  'participants', {
    get: function() {
      if (!this._participants) {
        this._participants = new ParticipantList(this._version, this._solution.sid);
      }
      return this._participants;
    }
});

Object.defineProperty(ConversationContext.prototype,
  'messages', {
    get: function() {
      if (!this._messages) {
        this._messages = new MessageList(this._version, this._solution.sid);
      }
      return this._messages;
    }
});

Object.defineProperty(ConversationContext.prototype,
  'webhooks', {
    get: function() {
      if (!this._webhooks) {
        this._webhooks = new WebhookList(this._version, this._solution.sid);
      }
      return this._webhooks;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ConversationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConversationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ConversationContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ConversationList: ConversationList,
  ConversationPage: ConversationPage,
  ConversationInstance: ConversationInstance,
  ConversationContext: ConversationContext
};
