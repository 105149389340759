import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
  FlatList,
  Dimensions,
  CheckBox,
  Animated,
  PanResponder,
  AsyncStorage,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import BackButton from "./../components/backButton";
import Bell from "./../components/fontSize";
import HistoryButton from "./../components/OrderHistoryButton";
import ProfileButton from "./../components/profileButton";

import { style } from "../../assets/style";
import { connect } from "react-redux";
import ListItem from "./../components/listItem";
import DefaultLanguage from "./../components/DefaultLanguage";
import Recipes from "./../components/recipes";
import Modal from "modal-react-native-web";
// import Icono from '@expo/vector-icons/Ionicons';
import {
  updateChildren,
  addToCart,
  removeItemFromCart,
  addCount,
  subtractCount,
  totalPrice,
  addDefaultLanguage,
  allCart,
  addChildren,
  selectMenu,
  isLoading as setIsLoading,
  selectMenuData,
  searchItem,
  resetCart
} from "./../store/actions/index";
import HeaderSection from "./../components/headerSection";
import MenuCampaign from "./../components/MenuCampaign";

import CartTotal from "./../components/cartTotal";
import CartButton from "./../components/cartButton";
import Toast from "./../components/toast";
import LanguageContainer from "./../components/languageContainer";
import FilterContainer from "./../components/filterContainer";

import Ant from "@expo/vector-icons/AntDesign";
import Icon from "./../components/Warning";
import ShimmerPlaceHolder from "./../components/placeHolder";
// import Campaign from './../components/campaignScreen'
import CampaignModal from "../components/campaignModal";
import { NavigationActions } from "react-navigation";
import FallbackScreen from "./fallbackScreen";
import { THREE_FRACTION_CURRENCY } from "./../constants";
import PdfViewer from "../components/pdfViewer";
import { PushData } from "./../constants/tagViewpush";
import ProgressiveImage from "./../components/progressiveImage";

import { image_api, API_URL } from "./../constants/index";
import i18n from "../i18n";
import HeartButton from '../components/heartButton';
import FilterButton from '../components/filterButton'
import SearchButton from '../components/searchItem'
import MenuButton from '../components/menuButton'

import IntlLabel from "./../handlers/languageHandler";
import { PriceLoader } from "./../handlers/utils"
import axios from "axios";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';
import { performRequest } from "../services/apiHandler";

const { width, height } = Dimensions.get("window")
const initialFilters = {
  // chefs_special: false,
  // best_seller: false,
  // our_favorite: false,
  // must_try: false,
  // healthy: false,
  // mark_item_as_new: false,
  // signature: false
  ic_gluten_free: false,
  ic_gluten_free: false,
  ic_shell_fish: false,
  ic_vegan: false
};
class HomeScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      selectedItemModal: false,
      selectedItem: null,
      selectedPriceIndex: null,
      selectedPrice: null,
      removeItemModal: false,
      itemsToRemove: [],
      error_message: "",
      message_status: "",
      message_display: "none",
      languageModal: false,
      uuid: "",
      isHomeActive: true,
      campaignModal: false,
      activeHeaderIndex: 0,
      pageNumber: 1,
      totalPages: null,
      campaignSelected: "",
      scrollPosition: 0,
      changeMenuLoader: false,
      filterModal: false,
      showSearchInput: false,
      selectedFilters: [],
      filterValues: { ...initialFilters },
      searchQuery: "",
      // isSpecialNoteVisible: this.props.config.special_notes ? true : false,
      showTopButtons: false
    };


  }

  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };
  static path = "feed";

  closeCampaignModal = () => {
    this.setState({ campaignModal: false, campaignSelected: "" });
  };

  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  onClickProfile = () => {
    if (this.props.user) {
      clearTimeout(this.campaign_time);
      this.setState({ isHomeActive: false }, () => {
        this.props.navigation.navigate("AccountScreen");
      });
    } else {
      clearTimeout(this.campaign_time);
      this.setState({ isHomeActive: false }, () => {
        AsyncStorage.setItem('login_trigger', "HomeScreen")
        this.props.navigation.navigate("MobileForm");
      });
    }
  };
  scrollToIndex = (index) => {
    this.setState({ activeHeaderIndex: index });
    this.flatListRef.scrollToIndex({ animated: true, index: index });
  };

  renderContent = (parentItems) =>
    parentItems.map((item, index) => {
      return (
        <ListItem
          onClickCampaign={this.onClickCampaign}
          section_campaigns={this.campaignList(item)}
          default_language={this.props.default_language}
          config={this.props.config}
          key={index}
          item={item}
          itemContent={(content, Parentsection) => this.itemContent(content, Parentsection)}
          renderContent={(content) => this.renderContent(content)}
          allChildren={this.props.allChildren}
          design={this.props.design}
        />
      );
    });

  itemContent = (items, section) =>{
    return items.map((item, index) => (
      <Recipes
        section={section}
        navigateToDetail={(itm) => {
          this.setState({ isHomeActive: false }, () => {
            clearTimeout(this.campaign_time);
            const navigateAction = NavigationActions.navigate({
              routeName: "RecipeDetail",
              params: {
                item: itm,
                index: index,
                setScreenActive: () => this.setScreenActive(),
                section
              },
            });
            this.props.navigation.dispatch(navigateAction);
          });
        }}
        cartLoading={this.state.cartLoading}
        key={index}
        editCartItem={(item, condition)=>this.editCartItem(item, condition, index)}
        onRemoveItem={(item) => this.onRemoveItem(item)}
        _addToCart={(item) => this.addToCartFn(item)}
        onCartClick={(item, index) => this.handleCartClick(item, index)}
        item_page_description_color={"black"}
        item={item}
        key={item.id}
        default_language={this.props.default_language}
        currency={this.props.config.currency}
        config={this.props.config}
        isRTL={this.props.isRTL}
        platform={this.props.platform}
        design={this.props.design}
        primaryFontFace={this.props.primaryFontFace}
        cartButtonLoading={this.state.cartButtonLoading}
      />
    ))};

  handleCartClick = (item, index) => {
    this.setState({ isHomeActive: false }, () => {
      clearTimeout(this.campaign_time);
      let section = this.props.allChildren.find((Sitem) => {
        return item.section == Sitem.id && Sitem.type == "section";
      });

      const navigateAction = NavigationActions.navigate({
        routeName: "RecipeDetail",
        params: {
          item,
          index: index,
          setScreenActive: () => this.setScreenActive(),
          section
        },
      });
      this.props.navigation.dispatch(navigateAction);
    });


    // if (item.count > 0) {
    //   let cart = [...this.props.cart];
    //   let items_to_remove = cart.filter((cart_item) => cart_item.id == item.id);
    //   this.setState({
    //     removeItemModal: true,
    //     itemsToRemove: items_to_remove,
    //     selectedItem: item,
    //   });
    // } else {
    //   this.setState({
    //     selectedItemModal: !this.state.selectedItemModal,
    //     selectedItem: item,
    //   });
    // }
  };
  addNewCustomization = () => {
    this.setState({
      selectedItemModal: !this.state.selectedItemModal,
      removeItemModal: false,
      itemsToRemove: [],
    });
  };
  onClickPrice = (item, index) => {
    this.setState({ selectedPriceIndex: index, selectedPrice: item });
  };

  onRemoveItem = (item) => {
    let all_cart = [...this.props.cart];
    let item_selected_from_cart = all_cart.find((itm) => itm.id == item.id);

    this.props.subtractCount();
    let price = this.props.total_price;
    this.props.totalPrice(price - item_selected_from_cart.selected_price.price);

    if (item_selected_from_cart.count > 1) {
      item_selected_from_cart.count -= 1;
      this.props.allCart(all_cart);
    } else {
      let new_cart = all_cart.filter((itm) => itm.id != item.id);
      this.props.allCart(new_cart);
    }

    item.count -= 1;
    let all_items = [...this.props.allChildren];
    this.props.updateChildren(all_items);
  };
  // editItemFromModal = (item, condition, index) => {
    
  //   console.log(item, "itemmmmmmmmm")
  //   this.setState({cartButtonLoading: true})
  //   let playload = {
  //     item: item.id,
  //     mid: Constants.deviceId,
  //     quantity: 1,
  //     uuid: this.state.uuid,
  //     condition,
  //     platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform
  //   }
  //   performRequest("patch", `api/pwa/cart/${item.cart_item_id}`, playload)
  //   .then(response => {
  //     console.log(response.data, 'responseeeeeeeeee')
  //     // ====================================== logic to remove multi option items

  //     this.props.totalPrice(response.data.total  || 0.00);
      
  //     this.props.allCart(response.data.items || []);
  //     let items_to_remove = []
  //     console.log(this.state.selectedItem, response.data?.items,item, "oooooooo")
  //      items_to_remove = response.data?.items?.filter((cart_item) => cart_item.item.id == item.id);


  //     if(condition == "minus"){
  //       item["count"] == 1 ? delete item['count'] : item.count-=1;
  //       this.props.subtractCount();
  //     }else{
  //       this.props.addCount();
  //       item["count"] += 1
  //     }
  //     let all_children = [...this.props.allChildren];
  //     this.props.updateChildren(all_children);


  //   this.setState({cartButtonLoading: false, itemsToRemove: items_to_remove.length? items_to_remove : []})
  //   })
  //   .catch(error => {

  //     console.log(error, "errorrr")    
  //     this.setState({cartButtonLoading: false})

  //   })
  // };

  editCartItem = (item, condition, index) => {
    
    if((item.price.length>1 || item.modifiers.length>1) && condition == "minus"){
      let cart = [...this.props.cart];
      console.log(cart, "carttttttttttttttttttttt")
      let items_to_remove = cart.filter((cart_item) => cart_item.item.id == item.id);
      console.log(items_to_remove, "removeeeee")

      this.setState({
        removeItemModal: true,
        itemsToRemove: items_to_remove,
        selectedItem: item,
      });
      return
    }else if((item.price.length>1 || item.modifiers.length>1) && condition == "add"){
      this.handleCartClick(item, index)
      return
    }
    this.setState({cartButtonLoading: true})
    let playload = {
      item: item.id,
      mid: Constants.deviceId,
      quantity: 1,
      uuid: this.state.uuid,
      condition,
      platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform
    }
    performRequest("patch", `api/pwa/cart/${item.cart_item_id}`, playload)
    .then(response => {
      console.log(response.data, 'responseeeeeeeeee')
      // ====================================== logic to remove multi option items

      this.props.totalPrice(response.data.total  || 0.00);
      
      this.props.allCart(response.data.items || []);
      let items_to_remove = []
      if(this.state.selectedItem) items_to_remove = response.data?.items?.filter((cart_item) => cart_item.item.id == this.state.selectedItem.id);


      if(condition == "minus"){
        item["count"] == 1 ? delete item['count'] : item.count-=1;
        this.props.subtractCount();
      }else{
        this.props.addCount();
        item["count"] += 1
      }
      let all_children = [...this.props.allChildren];
      if(this.state.removeItemModal){
        let selected  = all_children.find(item=>item.id == this.state.selectedItem.id &&  item.type=="item")
        selected["count"] == 1 ? delete selected['count'] : selected.count-=1;
      }
      if(items_to_remove.length == 0) this.setState({removeItemModal: false})

      this.props.updateChildren(all_children);


    this.setState({cartButtonLoading: false, itemsToRemove: items_to_remove.length? items_to_remove : []})
    })
    .catch(error => {

      console.log(error, "errorrr")    
      this.setState({cartButtonLoading: false})

    })
    // let cart = [...this.props.cart];
    // // cart.filter(cart_item => )
    // if (item.count > 1) {
    //   let items_to_remove = cart.filter((cart_item) => cart_item.id == item.id);
    //   this.setState({
    //     removeItemModal: true,
    //     itemsToRemove: items_to_remove,
    //   });
    // } else {
    //   // console.log('hereeeeeeeeeeeeeee')
    //   let item_in_cart = cart.find((cart_item) => cart_item.id === item.id);

    //   let price = this.props.total_price;
    //   let temp_price = item_in_cart.selected_price.price;
    //   item_in_cart.modifier.map(
    //     (mod_item) => {
    //       if (!mod_item.is_nested) {
    //         temp_price += mod_item.price ? (mod_item.price * mod_item.count) : 0
    //       }
    //       if (mod_item.is_item_modifier) {
    //         temp_price += mod_item.nested_modifier_total
    //       }
    //     }
    //   );

    //   this.props.totalPrice(price - temp_price);
    //   // this.props.totalPrice(price - item_in_cart.selected_price.price);

    //   this.props.subtractCount();
    //   delete item["count"];

    //   let all_children = [...this.props.allChildren];
    //   this.props.updateChildren(all_children);

    //   let existing_cart_items = [...this.props.cart];
    //   let new_cart_items = existing_cart_items.filter(
    //     (cart_item) => cart_item.id !== item.id
    //   );
    //   this.props.removeItemFromCart(new_cart_items);
    // }
  };
  updateChildrenFn = (item) => {
    // console.log('herer problemmmm')
    this.props.addCount();
    if (item["count"]) {
      item.count += 1;
    } else {
      item["count"] = 1;
    }

    // item['count'] = item.count ? item.count + 1 : 1;
    let allChildren = [...this.props.allChildren];
    this.props.updateChildren(allChildren);
  };

  addToCartFn = (item) => {
    this.setState({cartLoading: true})
    let payload = {
      item: item.id,
      mid: Constants.deviceId,
      price: item.price.length > 0 ? item.price[0].id : 0,
      modifiers : [],
      quantity: 1,
      uuid: this.state.uuid,
      platform: this.props.platform!=="qr"? this.props.menu_type : this.props.platform,
      delivery_charge: this.props.config.delivery_charge
    }
    performRequest("post", "api/pwa/cart", payload)
    .then(response => {
      
      console.log(response.data, "responseeee")
      let cart_items = [...this.props.cart, response.data.cart_item];
      this.props.allCart(cart_items);

      item.count ? (item.count += 1) : (item.count = 1);
      item.cart_item_id = response.data.item.cart_item_id
      let allChildren = [...this.props.allChildren];
      this.props.updateChildren(allChildren);


      this.props.addCount();
    this.props.totalPrice(response.data.total_price);

    this.setState({cartLoading: false})
    })
    .catch(error => {
      console.log(error.response, "errorrr")    
      this.setState({cartLoading: false})

    })
    // let cart_items = [...this.props.cart];
    // let item_to_cart = { ...item };

    // let prev_item_exist = cart_items.find(
    //   (cart_item) => cart_item.id === item.id
    // );
    // if (prev_item_exist) {
    //   prev_item_exist.count = prev_item_exist.count + 1;
    //   this.props.allCart(cart_items);
    // } else {
    //   item_to_cart["count"] = item.count ? item.count + 1 : 1;
    //   item_to_cart["selected_price"] =
    //     item.price.length > 0 ? item.price[0] : { price: 0, description: null };
    //   item_to_cart["modifier"] = [];
    //   this.props.addToCart(item_to_cart);
    // }

    // item.count ? (item.count += 1) : (item.count = 1);
    // let allChildren = [...this.props.allChildren];
    // this.props.updateChildren(allChildren);

    // this.props.addCount();
    // let price = this.props.total_price;
    // this.props.totalPrice(
    //   item.price.length > 0 ? price + item.price[0].price : price
    // );
  };

  makeModifiersFalse = (item) => {
    for (let i = 0; i < item.modifiers.length; i++) {
      delete item.modifiers[i].checked_count;
      for (
        let j = 0;
        j < item.modifiers[i].modifier_modifier_group.length;
        j++
      ) {
        item.modifiers[i].modifier_modifier_group[j].checked = false;
      }
    }
  };

  onOptionSelect = () => {
    let item = { ...this.state.selectedItem };
    if (!item) return;
    let failed = false;
    Promise.all(
      item.modifiers.map((item) => {
        if (item.min > 0) {
          if (item.checked_count < item.min || !item.checked_count) {
            failed = true;
            item["required_indicator"] = true;
          } else {
          }
        } else {
          // failed = true;
          // item['required_indicator'] = true;
        }
      })
    ).then(() => {
      // console.log(failed, 'failedddd????');
      if (!failed) {
        // if everything okay
        let modifier = [];

        for (let i = 0; i < item.modifiers.length; i++) {
          for (
            let j = 0;
            j < item.modifiers[i].modifier_modifier_group.length;
            j++
          ) {
            if (item.modifiers[i].modifier_modifier_group[j].checked === true) {
              modifier.push(item.modifiers[i].modifier_modifier_group[j]);
            }
          }
        }
        item["modifier"] = modifier;

        if (this.state.selectedItem.price.length > 1) {
          if (this.state.selectedPrice) {
            let item_selected_from_children = this.props.allChildren.find(
              (itm) =>
                itm.id === this.state.selectedItem.id && itm.type === "item"
            );
            this.makeModifiersFalse(item_selected_from_children);
            let if_item_exist = this.props.cart.find(
              (cartItem) =>
                cartItem.selected_price.id === this.state.selectedPrice.id &&
                JSON.stringify(item.modifier) ==
                JSON.stringify(cartItem.modifier)
            );

            if (if_item_exist) {
              if_item_exist.count += 1; // updating cart count

              item_selected_from_children.count
                ? (item_selected_from_children.count =
                  item_selected_from_children.count + 1)
                : (item_selected_from_children.count = 1); // updating all children count

              let price = this.props.total_price;
              let temp_selected_price = this.state.selectedPrice.price;
              item.modifier.map((mod_item) => {
                temp_selected_price += mod_item.price ? mod_item.price : 0;
              });
              this.props.totalPrice(price + temp_selected_price);

              // this.props.totalPrice(price + this.state.selectedPrice.price);
              this.props.addCount();

              let allChildren = [...this.props.allChildren];
              this.props.updateChildren(allChildren);

              this.setState({
                selectedItemModal: false,
                selectedItem: null,
                selectedPrice: null,
                selectedPriceIndex: null,
              });
            } else {
              item["selected_price"] = this.state.selectedPrice;
              item["count"] = 1;
              item_selected_from_children.count
                ? (item_selected_from_children.count =
                  item_selected_from_children.count + 1)
                : (item_selected_from_children.count = 1);

              let price = this.props.total_price;
              let temp_selected_price = this.state.selectedPrice.price;
              item.modifier.map((mod_item) => {
                temp_selected_price += mod_item.price ? mod_item.price : 0;
              });
              this.props.totalPrice(price + temp_selected_price);
              // this.props.totalPrice(price + this.state.selectedPrice.price);

              this.props.addToCart(item);
              this.props.addCount();

              let allChildren = [...this.props.allChildren];
              this.props.updateChildren(allChildren);

              this.setState({
                selectedItemModal: false,
                selectedItem: null,
                selectedPrice: null,
                selectedPriceIndex: null,
              });
            }
          } else {
            this.throwMessageFn(
              i18n.t("labels.please_select_at_least_one_option"),
              "red"
            );
          }
        } else {
          this.setState(
            {
              selectedPrice: this.state.selectedItem.price[0]
                ? this.state.selectedItem.price[0]
                : {
                  id: JSON.stringify(this.state.selectedItem),
                  price: 0,
                  description: { [this.props.default_language]: "" },
                  order: 0,
                },
            },
            () => {
              let if_item_exist = this.props.cart.find(
                (cartItem) =>
                  cartItem.selected_price.id === this.state.selectedPrice.id &&
                  JSON.stringify(item.modifier) ==
                  JSON.stringify(cartItem.modifier)
              );
              let item_selected_from_children = this.props.allChildren.find(
                (itm) =>
                  itm.id === this.state.selectedItem.id && itm.type === "item"
              );
              this.makeModifiersFalse(item_selected_from_children);

              if (if_item_exist) {
                if_item_exist.count += 1; // updating cart count

                item_selected_from_children.count
                  ? (item_selected_from_children.count =
                    item_selected_from_children.count + 1)
                  : (item_selected_from_children.count = 1); // updating all children count

                let price = this.props.total_price;
                let temp_selected_price = this.state.selectedPrice.price;
                item.modifier.map((mod_item) => {
                  temp_selected_price += mod_item.price ? mod_item.price : 0;
                });
                this.props.totalPrice(price + temp_selected_price);

                // this.props.totalPrice(price + this.state.selectedPrice.price);
                this.props.addCount();

                let allChildren = [...this.props.allChildren];
                this.props.updateChildren(allChildren);

                this.setState({
                  selectedItemModal: false,
                  selectedItem: null,
                  selectedPrice: null,
                  selectedPriceIndex: null,
                });
              } else {
                item["selected_price"] = this.state.selectedPrice
                  ? this.state.selectedPrice
                  : {
                    id: JSON.stringify(item),
                    price: 0,
                    description: { [this.props.default_language]: "" },
                    order: 0,
                  };
                item["count"] = 1;
                item_selected_from_children.count
                  ? (item_selected_from_children.count =
                    item_selected_from_children.count + 1)
                  : (item_selected_from_children.count = 1);
                let price = this.props.total_price;
                let temp_selected_price = this.state.selectedPrice
                  ? this.state.selectedPrice.price
                  : 0;
                item.modifier.map((mod_item) => {
                  temp_selected_price += mod_item.price ? mod_item.price : 0;
                });
                this.props.totalPrice(price + temp_selected_price);
                // this.props.totalPrice(price + this.state.selectedPrice.price);

                this.props.addToCart(item);
                this.props.addCount();

                let allChildren = [...this.props.allChildren];
                this.props.updateChildren(allChildren);

                this.setState({
                  selectedItemModal: false,
                  selectedItem: null,
                  selectedPrice: null,
                  selectedPriceIndex: null,
                });
              }
            }
          );
        }
      } else {
        this.setState({ selectedItem: item });
      }
    });
  };

  onModifierClick = (parentIndex, childIndex, childPrice) => {
    let item = this.state.selectedItem;
    item.modifiers[parentIndex]["required_indicator"] = false;
    if (
      item.modifiers[parentIndex].modifier_modifier_group[childIndex]["checked"]
    ) {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = false;
      if (item.modifiers[parentIndex]["checked_count"] == 1) {
        delete item.modifiers[parentIndex]["checked_count"];
      } else {
        item.modifiers[parentIndex]["checked_count"] -= 1;
      }
      item.modifier_total_price -= childPrice;
    } else {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = true;
      if (!item.modifier_total_price) {
        item["modifier_total_price"] = childPrice;
      } else {
        item.modifier_total_price += childPrice;
      }
      if (item.modifiers[parentIndex]["checked_count"]) {
        item.modifiers[parentIndex]["checked_count"] += 1;
      } else {
        item.modifiers[parentIndex]["checked_count"] = 1;
      }
    }
    // console.log(item, 'itemmmmmmmmmmm')
    this.setState({ selectedItem: item });
  };

  // _renderModalContent = () => (
  //   <View
  //     style={{
  //       flex: 1,
  //       alignItems: "flex-end",
  //       justifyContent: "flex-end",
  //       backgroundColor: "transparent",
  //       backdropFilter: "blur(5px)" 
  //     }}
  //   >
  //     <TouchableOpacity
  //       onPress={() => this.setState({ selectedItemModal: false })}
  //     >
  //       <View
  //         style={{
  //           height: height * 0.5,
  //           backgroundColor: "transparent",
  //           width: width,
  //           // backgroundColor:'rgba(0, 0, 0, 0.4)'
  //         }}
  //       />
  //     </TouchableOpacity>

  //     <View style={style.optionMainContainer}>
  //       <View style={style.optionsContainer}>
  //         <View
  //           style={{
  //             height: 40,
  //             width: "100%",
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //             borderBottomWidth: 1,
  //             borderBottomColor: "rgba(12, 10, 10, 0.2)",
  //             flexDirection: "row",
  //           }}
  //         >
  //           <StyledText
  //             numberOfLines={1}
  //             style={{ marginLeft: 10, fontWeight: "500", fontSize: this.props.design.custom_font.heading_size ?? 20, fontFamily: 'SecondaryFont' }}
  //           >
  //             {this.state.selectedItem
  //               ? <IntlLabel data={this.state.selectedItem.item_names} />
  //               : null}
  //           </StyledText>
  //           <TouchableOpacity
  //             onPress={() => this.setState({ selectedItemModal: false })}
  //           >
  //             <View style={style.closeButton}>
  //               <Icon name={"close"} color={"#F44336"} size={24} />

  //               {/* <Icono name={'ios-close'} color={'#F44336'} size={24}/> */}
  //             </View>
  //           </TouchableOpacity>
  //         </View>
  //         <ScrollView bounces={false}>
  //           {this.state.selectedItem &&
  //             this.state.selectedItem.price.length > 1 ? (
  //             <View
  //               style={{ margin: 10, direction: this.props.isRTL && "rtl" }}
  //             >
  //               <StyledText style={{ marginBottom: 10, marginTop: 8, fontFamily: 'SecondaryFont' }}>
  //                 {i18n.t("labels.options")}
  //               </StyledText>
  //               {this.state.selectedItem.price.map((item, index) => (
  //                 <TouchableOpacity
  //                   onPress={() => this.onClickPrice(item, index)}
  //                 >
  //                   <View
  //                     style={{
  //                       width: "100%",
  //                       height: 20,
  //                       marginBottom: 4,
  //                       flexDirection: "row",
  //                       justifyContent: "space-between",
  //                     }}
  //                   >
  //                     <View style={{ flexDirection: "row", width: "64%" }}>
  //                       {/* <CheckBox value={this.state.selectedPriceIndex == index}/> */}
  //                       {this.state.selectedPriceIndex == index ? (
  //                         <Ant
  //                           name={"checkcircle"}
  //                           color={"red"}
  //                           size={19}
  //                           style={{ marginTop: 1, marginHorizontal: 5 }}
  //                         />
  //                       ) : (
  //                         <View
  //                           style={{
  //                             borderRadius: 20,
  //                             border: `1px solid black`,
  //                             padding: 1,
  //                             width: 20,
  //                             height: 20,
  //                             alignItems: "center",
  //                             justifyContent: "center",
  //                             marginHorizontal: 5,
  //                           }}
  //                         />
  //                       )}
  //                       <StyledText
  //                         numberOfLines={1}
  //                         style={{
  //                           color:
  //                             this.state.selectedPriceIndex === index
  //                               ? "red"
  //                               : "black",
  //                           fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                           width: "80%",
  //                           marginTop: 1,
  //                           fontFamily: 'SecondaryFont'
  //                         }}
  //                       >
  //                         {" "}
  //                         <IntlLabel data={item.description} />
  //                       </StyledText>
  //                     </View>
  //                     <View style={{ width: "15%" }}>
  //                       {item.calorie ? (
  //                         <StyledText
  //                           numberOfLines={1}
  //                           style={{
  //                             color:
  //                               this.state.selectedPriceIndex === index
  //                                 ? "red"
  //                                 : "black",
  //                             marginRight: 10,
  //                             fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                             marginTop: 1,
  //                             fontFamily: 'SecondaryFont'
  //                           }}
  //                         >
  //                           {item.calorie} cal
  //                         </StyledText>
  //                       ) : null}
  //                     </View>
  //                     <View style={{ width: "40%" }}>
  //                       <StyledText
  //                         style={{
  //                           color:
  //                             this.state.selectedPriceIndex === index
  //                               ? "red"
  //                               : "black",
  //                           marginRight: 10,
  //                           fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                           marginTop: 1,
  //                           fontFamily: 'SecondaryFont'
  //                         }}
  //                       >
  //                         {this.props.config.display_currency &&
  //                           this.props.config.currency}{" "}
  //                         {this.props.config.display_price &&
  //                           PriceLoader(item.price, this.props.config.currency, this.props.config.display_fraction)}
  //                       </StyledText>
  //                     </View>
  //                   </View>
  //                 </TouchableOpacity>
  //               ))}
  //             </View>
  //           ) : null}

  //           {this.state.selectedItem &&
  //             this.state.selectedItem.modifiers.length > 0 && (
  //               <StyledText
  //                 style={{ marginBottom: 10, marginTop: 8, marginLeft: 10, fontFamily: 'SecondaryFont' }}
  //               >
  //                 {i18n.t("checkout.extras")}
  //               </StyledText>
  //             )}

  //           {this.state.selectedItem
  //             ? this.state.selectedItem.modifiers.length > 0
  //               ? this.state.selectedItem.modifiers.map(
  //                 (parentItem, parentIndex) => (
  //                   <View
  //                     style={{
  //                       marginHorizontal: 10,
  //                       direction: this.props.isRTL && "rtl",
  //                     }}
  //                   >
  //                     <View
  //                       disabled
  //                       block
  //                       style={{
  //                         borderRadius: 0,
  //                         width: "100%",
  //                         flex: 1,
  //                         flexDirection: "row",
  //                         // backgroundColor:
  //                         //   this.props.design.popup_button_color,
  //                         height: 45,
  //                         justifyContent: "space-between",
  //                         alignItems: "center",
  //                       }}
  //                     >
  //                       <View
  //                         style={{
  //                           height: "100%",
  //                           justifyContent: "center",
  //                           alignItems: "center",

  //                           width: "80%",
  //                         }}
  //                       >
  //                         <StyledText
  //                           numberOfLines={1}
  //                           style={{
  //                             color: this.props.design.popup_text_color,
  //                             fontWeight: this.props.design.custom_font.sub_heading_is_bold == true ? "bold" : "normal",
  //                             fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                             textTransform: "uppercase",
  //                             fontFamily: "PrimaryFont"
  //                           }}
  //                         >
  //                           {/* {JSON.parse(parentItem.names)
  //                               [this.props.default_language].toUpperCase()
  //                               .trim()} */}
  //                           <IntlLabel data={JSON.parse(parentItem.names)} />
  //                         </StyledText>
  //                       </View>
  //                       {parentItem.required ? (
  //                         <View
  //                           style={{
  //                             height: "70%",
  //                             justifyContent: "center",
  //                             alignItems: "center",
  //                             marginRight: 10,
  //                             backgroundColor: parentItem.required_indicator
  //                               ? "red"
  //                               : this.props.design.grid_view_text_color,
  //                             borderRadius: 3,
  //                             paddingHorizontal: 8,
  //                             paddingVertical: 8,
  //                           }}
  //                         >
  //                           <StyledText style={{ color: "white", fontSize: this.props.design.custom_font.body_size ?? 10, fontFamily: 'SecondaryFont' }}>
  //                             {i18n.t("labels.required")}
  //                           </StyledText>
  //                         </View>
  //                       ) : null}
  //                     </View>

  //                     {parentItem.modifier_modifier_group.map(
  //                       (childItem, childIndex) => (
  //                         // if(parentItem.max === ){}
  //                         <View
  //                           style={{
  //                             // marginLeft: 10,
  //                             flexDirection: "row",
  //                             marginVertical: 5,
  //                             marginRight: 10,
  //                           }}
  //                         >
  //                           <View
  //                             style={{
  //                               alignItems: "flex-start",
  //                               flex: 3,
  //                               flexDirection: "row",
  //                             }}
  //                           >
  //                             <TouchableOpacity
  //                               onPress={() =>
  //                                 this.onModifierClick(
  //                                   parentIndex,
  //                                   childIndex,
  //                                   childItem.price
  //                                 )
  //                               }
  //                               disabled={
  //                                 parentItem.max ===
  //                                 parentItem.checked_count &&
  //                                 !childItem.checked
  //                               }
  //                             >
  //                               {childItem.checked ? (
  //                                 <Ant
  //                                   name={"checkcircle"}
  //                                   color={"red"}
  //                                   size={19}
  //                                   style={{
  //                                     marginTop: 1,
  //                                     marginHorizontal: 5,
  //                                   }}
  //                                 />
  //                               ) : (
  //                                 <View
  //                                   style={{
  //                                     borderRadius: 20,
  //                                     border: `1px solid black`,
  //                                     padding: 1,
  //                                     width: 20,
  //                                     height: 20,
  //                                     alignItems: "center",
  //                                     justifyContent: "center",
  //                                     marginHorizontal: 5,
  //                                   }}
  //                                 />
  //                               )}
  //                               {/* <CheckBox disabled={parentItem.max === parentItem.checked_count && !childItem.checked}  style={{borderRadius:5, borderColor:this.props.popup_text_color}} value={childItem.checked}/> */}
  //                             </TouchableOpacity>
  //                             <StyledText
  //                               numberOfLines={1}
  //                               style={{
  //                                 color:
  //                                   parentItem.max ===
  //                                     parentItem.checked_count &&
  //                                     !childItem.checked
  //                                     ? "grey"
  //                                     : this.props.design.popup_text_color,
  //                                 marginRight: 8,
  //                                 fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                                 fontFamily: 'SecondaryFont'
  //                               }}
  //                             >

  //                               <IntlLabel data={JSON.parse(childItem.names)} />
  //                             </StyledText>
  //                           </View>
  //                           <View style={{ alignItems: "flex-end" }}>
  //                             <StyledText
  //                               numberOfLines={1}
  //                               style={{
  //                                 color:
  //                                   parentItem.max ===
  //                                     parentItem.checked_count &&
  //                                     !childItem.checked
  //                                     ? "grey"
  //                                     : this.props.design.popup_text_color,
  //                                 fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
  //                                 fontFamily: 'SecondaryFont'
  //                               }}
  //                             >
  //                               {childItem.price > 0 ? "+" : ""}{" "}
  //                               {this.props.config.currency}{" "}
  //                               {PriceLoader(childItem.price, this.props.config.currency, this.props.config.display_fraction)}
  //                             </StyledText>
  //                           </View>
  //                         </View>
  //                       )
  //                     )}
  //                   </View>
  //                 )
  //               )
  //               : null
  //             : null}
  //         </ScrollView>
  //         <View style={{ width: "100%", height: 40 }}>
  //           <TouchableOpacity onPress={() => this.onOptionSelect()}>
  //             <View style={style.addToCartStyle}>
  //               <StyledText style={{ color: "#F44336", fontSize: this.props.design.custom_font.sub_heading_size ?? 16, fontFamily: 'SecondaryFont' }}>
  //                 {i18n.t("checkout.add_to_cart")}
  //               </StyledText>
  //             </View>
  //           </TouchableOpacity>
  //         </View>
  //       </View>
  //     </View>
  //   </View>
  // );
  removeItemCount = (item) => {
    // console.log(item.modifier, 'scrnnn')
    this.props.subtractCount();

    // substract total price
    let price = this.props.total_price;

    let temp_selected_price = item.selected_price.price;
    // item.modifier.map((mod_item) => {
    //   temp_selected_price += mod_item.price ? (mod_item.price * mod_item.count) : 0;
    // });
    item.modifier.map(
      (mod_item) => {
        if (!mod_item.is_nested) {
          temp_selected_price += mod_item.price ? (mod_item.price * mod_item.count) : 0
        }
        if (mod_item.is_item_modifier) {
          temp_selected_price += mod_item.nested_modifier_total
        }
      }
    );
    this.props.totalPrice(price - temp_selected_price);

    // this.props.totalPrice(price - item.selected_price.price);
    // ======================

    item.count = item.count - 1;
    let all_childrens = [...this.props.allChildren];

    let find_item = all_childrens.find(
      (itm) => itm.id === item.id && itm.type == "item"
    );
    find_item["count"] =
      find_item["count"] > 1 ? find_item.count - 1 : delete find_item["count"];

    this.props.updateChildren(all_childrens);
    // ========================
  };
  removeFromCart = (item) => {
    console.log(item, "lllllll")
    this.props.subtractCount();

    // substract total price
    let price = this.props.total_price;
    let temp_selected_price = item.selected_price.price;

    // item.modifier.map((mod_item) => {
    //   temp_selected_price += mod_item.price ? (mod_item.price * mod_item.count) : 0;
    // });
    item.modifier.map(
      (mod_item) => {
        if (!mod_item.is_nested) {
          temp_selected_price += mod_item.price ? (mod_item.price * mod_item.count) : 0
        }
        if (mod_item.is_item_modifier) {
          temp_selected_price += mod_item.nested_modifier_total
        }
      }
    );

    this.props.totalPrice(price - temp_selected_price);

    // this.props.totalPrice(price - item.selected_price.price);
    // ===========

    // removing item from main cart
    let cart_items = [...this.props.cart];
    let sorted_cart = cart_items.filter(
      (cart_item) =>
        cart_item.selected_price.id !== item.selected_price.id ||
        JSON.stringify(cart_item.modifier) != JSON.stringify(item.modifier)
    );
    this.props.removeItemFromCart(sorted_cart);
    // =====================

    // reducing item count from main counter
    let all_children = [...this.props.allChildren];
    let find_item = all_children.find(
      (itm) => itm.id === item.id && itm.type == "item"
    );
    // delete find_item.count;
    if (find_item.count > 1) {
      find_item.count = find_item.count - 1;
    } else {
      delete find_item.count;
    }
    this.props.updateChildren(all_children);
    // find_item['count'] = find_item['count'] > 1 ? find_item.count - 1 : delete find_item['count'];
    // console.log(find_item, 'itemmmmmmmmmmmmmm')
    // ========================

    // removing item from selected items array
    // let sorded_selected_item = this.state.itemsToRemove.filter(selected_item => selected_item.selected_price.id !== item.selected_price.id && JSON.stringify(selected_item.modifier) != JSON.stringify(item.modifier));
    // console.log(sorded_selected_item, 'itemmmmmmmmmmm')
    let sorded_selected_item = this.state.itemsToRemove.filter(
      (selected_item) => {
        return (
          JSON.stringify(selected_item.modifier) !=
          JSON.stringify(item.modifier) ||
          selected_item.selected_price.id !== item.selected_price.id
        );
        // console.log(JSON.stringify(selected_item.modifier) != JSON.stringify(item.modifier) , 'mmmmmmmmmm')
      }
    );
    // console.log(sorded_selected_item, 'testtttttttt')

    this.setState({ itemsToRemove: sorded_selected_item });
    if (sorded_selected_item.length == 0) {
      this.setState({ removeItemModal: false });
    }
    // ========================
  };
  increaseItemCount = (item) => {
    // console.log(item.modifier, 'ssssssssssss')
    item.count = item.count + 1;
    let all_cart = [...this.props.cart];
    this.props.removeItemFromCart(all_cart);

    // updating cart count in all children
    let all_children = [...this.props.allChildren];
    let selected_item = all_children.find(
      (itm) => itm.id == item.id && itm.type === "item"
    );
    selected_item.count += 1;
    this.props.updateChildren(all_children);

    // add total price
    let price = this.props.total_price;
    let temp_selected_price = item.selected_price.price;

    let final_amount = price + temp_selected_price
    if (item.modifier_total_price) {
      let modifier_total = item.modifier_total_price
      final_amount = final_amount + modifier_total
    }

    this.props.totalPrice(final_amount);
    // item.modifier.map((mod_item) => {
    //   temp_selected_price += mod_item.price ? mod_item.price : 0;
    // });
    // this.props.totalPrice(price + temp_selected_price);

    this.props.addCount();
  };

  _renderRemoveSelectedItems = () => (
    <View
      style={{
        flex: 1,
        alignItems: "flex-end",
        justifyContent: "flex-end",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)" 
      }}
    >
      <TouchableOpacity
        onPress={() => this.setState({ removeItemModal: false })}
      >
        <View
          style={{
            height: height * 0.5,

            backgroundColor: "transparent",
            width: width,
            //
          }}
        />
      </TouchableOpacity>

      <View
        style={{
          width: width,
          // height:Dimensions.get('window').height*.5,
          backgroundColor: this.props.design.popup_background_color,
          borderWidth: 1,
          // padding:  22,
          justifyContent: "center",

          // alignItems: 'center',
          // borderRadius: 10,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={{
            height: 40,
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomWidth: 1,
            borderBottomColor: "rgba(12, 10, 10, 0.2)",
            flexDirection: "row",
          }}
        >
          <StyledText
            numberOfLines={1}
            font_color={this.props.design.popup_heading_text_color}
            font_weight={this.props.design.popup_heading_font_weight}
            font_style={this.props.design.popup_heading_font_style}
            text_transform={this.props.design.popup_heading_font_transform}
            font_family={this.props.design.popup_heading_font_family}
            font_size={this.props.design.popup_heading_font_size?? 20}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            style={{ fontWeight: "500", marginLeft: 8 }}
          >
            {this.state.selectedItem
              ? <IntlLabel data={this.state.selectedItem.item_names} />
              : null}

          </StyledText>
          <TouchableOpacity
            onPress={() => this.setState({ removeItemModal: false })}
          >
            <View style={style.closeButton}>
              <Icon name={"close"} color={"#F44336"} size={30} />

              {/* <Icono name={'ios-close'} color={'#F44336'} size={30}/> */}
            </View>
          </TouchableOpacity>
        </View>
        <ScrollView bounces={false}>
          {this.state.itemsToRemove.map((item) => (
            <View
              style={{
                width: "100%",
                height: 70,
                marginBottom: 5,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginVertical: 10,
              }}
            >
              <View style={{ marginLeft: 8, width: "70%" }}>
                <StyledText 
                numberOfLines={1} 
                font_color={this.props.design.popup_text_color}
                font_weight={this.props.design.popup_text_font_weight}
                font_style={this.props.design.popup_text_font_style}
                text_transform={this.props.design.popup_text_font_transform}
                font_family={this.props.design.popup_text_font_family}
                font_size={this.props.design.popup_text_font_size ?? 16}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                style={{}}
                >
                  <IntlLabel data={JSON.parse(item.item.item_names)} />
                </StyledText>
                {this.props.config.display_price && (
                  <StyledText 
                  font_color={this.props.design.popup_text_color}
                  font_weight={this.props.design.popup_text_font_weight}
                  font_style={this.props.design.popup_text_font_style}
                  text_transform={this.props.design.popup_text_font_transform}
                  font_family={this.props.design.popup_text_font_family}
                  font_size={this.props.design.popup_text_font_size ?? 16}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                  style={{ fontWeight: "400"}}
                  >
                    {this.props.config.display_currency &&
                      this.props.config.currency}{" "}
                    {item.total && item.total}
                  </StyledText>
                )}
                {/*{*/}
                {/*item.selected_price.description[this.props.default_language] &&*/}
                <StyledText 
                  font_color={this.props.design.popup_text_color}
                  font_weight={this.props.design.popup_text_font_weight}
                  font_style={this.props.design.popup_text_font_style}
                  text_transform={this.props.design.popup_text_font_transform}
                  font_family={this.props.design.popup_text_font_family}
                  font_size={this.props.design.popup_text_font_size ?? 16}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                  style={{  }}
                >
                  {" "}
                  {item.item.price &&
                    <IntlLabel data={JSON.parse(item.item.price.description)} />}

                </StyledText>

                {/*}*/}
                <View style={{ flexDirection: "row" }}>
                  {/*<Text style={{fontSize:10}}>Extra : </Text>*/}

                  {item.modifier !== undefined
                    ? item.modifier.map((item) => (
                      <StyledText
                        font_color={this.props.design.popup_text_color}
                        font_weight={this.props.design.popup_text_font_weight}
                        font_style={this.props.design.popup_text_font_style}
                        text_transform={this.props.design.popup_text_font_transform}
                        font_family={this.props.design.popup_text_font_family}
                        font_size={this.props.design.popup_text_font_size ?? 14}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        style={{
                          writingDirection: this.props.isRTL && "rtl",
                        }}
                      >
                        <IntlLabel data={JSON.parse(item.names)} />,
                      </StyledText>
                    ))
                    : null}
                </View>
              </View>
              <View style={{ marginRight: 8 }}>
                <CartButton
                  config={this.props.config}
                  item={item}
                  editCartItem={this.editCartItem}
                  onRemoveItem={this.removeFromCart}
                  removeItemModal={this.state.removeItemModal}
                  increaseItemCount={() => this.increaseItemCount(item)}
                  design={this.props.design}
                />
              </View>
            </View>
          ))}

          {/* <TouchableOpacity onPress={() => this.addNewCustomization()}>
            <Text style={{ fontSize: 18, margin: 8, color: "black" }}>
              + {i18n.t("labels.add_new_customization")}
            </Text>
          </TouchableOpacity> */}
        </ScrollView>
      </View>
    </View>
  );
  setFilters = (filter) => {
    let filters = {
      ...this.state.filterValues,
      [filter]: !this.state.filterValues[filter]
    }

    this.setState({ filterValues: filters })
  }
  clearAll = () => {
    this.setState({ filterValues: { ...initialFilters }, selectedFilters: [] },()=>{
      this.props.searchItem(this.state.searchQuery, this.state.filterValues)
    })

  }
  filterView = () => (
    <FilterContainer
    clearAll={this.clearAll}
      filterValues={this.state.filterValues}
      setFilters={this.setFilters}
      design={this.props.design}
      closeModal={() => this.setState({ filterModal: !this.state.filterModal })}
      searchItem={this.props.searchItem}
      selectedFilters={(filters) => this.setState({ selectedFilters: filters })}
    />
  )

  defaultLangView = () => (
    <LanguageContainer
      background_color={this.props.design.popup_background_color}
      closeModal={() => this.setState({ languageModal: false })}
    >
      {this.props.languages.map((item) => (
        <TouchableOpacity
          onPress={() => {
            i18n.locale = item.value;

            this.props.addDefaultLanguage(item.value);
            this.setState({
              languageModal: false,
              default_language: item.value,
            });
            this.props.addDefaultLanguage(item.value);
          }}
        >
          <View
            style={{
              paddingLeft: 3,
              paddingVertical: 7,
              borderBottom: "1px solid black",
              justifyContent: "center",
            }}
          >
            <StyledText 
            font_color={this.props.design.popup_text_color}
            font_weight={this.props.design.popup_text_font_weight}
            font_style={this.props.design.popup_text_font_style}
            text_transform={this.props.design.popup_text_font_transform}
            font_family={this.props.design.popup_text_font_family}
            font_size={this.props.design.popup_text_font_size ?? 16}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

            style={{
              
            }}>{item.label}</StyledText>
          </View>
        </TouchableOpacity>
      ))}
    </LanguageContainer>
  );

  componentDidMount() {
    // console.log(this.props.platform, "kkkkkkkkkkkkkkkkkkk")
    // if(this.props.platform === "online" && this.props.cart.length>0){
    //   let old_menu = this.props.cart[0].menu 
    //   if(this.props.menu_id !=old_menu ){
    //     this.props.resetCart()
    //   }
    // }
    let page = "home";
    this.props.campaigns.forEach((campaign) => {
      if (
        campaign.campaign_starts_after == "4" &&
        campaign.content_type != "4" &&
        campaign.content_type != "3" &&
        campaign.starts_after_time_menu.value == this.props.selectedMenu.id
      ) {
        
        this.campaign_time = setTimeout(() => {
          if (this.state.isHomeActive) {
            this.setState({ campaignSelected: campaign, campaignModal: true });
          }
        }, parseInt(campaign.starts_after_time) * 1000);
      }
    });

    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.setState({ uuid: uuid });
        this.runPlaceHolder();
        this.runColPlaceHolder();
        PushData(
          this.props.navigation.state.routeName,
          this.props.config.venueName ? this.props.config.venueName : "Ent",
          uuid ? uuid : "Ent",
          {}
        );
      }
    });
  }
  runPlaceHolder() {
    if (
      Array.isArray(this.loadingAnimated) &&
      this.loadingAnimated.length > 0
    ) {
      Animated.parallel(
        this.loadingAnimated.map((animate) => {
          if (animate && animate.getAnimated) {
            return animate.getAnimated();
          }
          return null;
        }),
        {
          stopTogether: false,
        }
      ).start(() => {
        this.runPlaceHolder();
      });
    }
  }
  runColPlaceHolder() {
    if (
      Array.isArray(this.loadingColAnimated) &&
      this.loadingColAnimated.length > 0
    ) {
      Animated.parallel(
        this.loadingColAnimated.map((animate) => {
          if (animate && animate.getAnimated) {
            return animate.getAnimated();
          }
          return null;
        }),
        {
          stopTogether: false,
        }
      ).start(() => {
        this.runColPlaceHolder();
      });
    }
  }
  navigateToOrder = () => {
    clearTimeout(this.campaign_time);
    this.setState({ isHomeActive: false }, () => {
      this.props.navigation.navigate("RecentOrdersScreen");
    });
  };

  _renderRows(loadingAnimated, numberRow, uniqueKey) {
    let shimmerRows = [];
    for (let index = 0; index < numberRow; index++) {
      shimmerRows.push(
        <ShimmerPlaceHolder
          colorShimmer={["#ececec", "#e4e4e4", "#e4e4e4"]}
          width={120}
          height={this.props.design.show_section_image ? 80 : 20}
          key={`loading-${index}-${uniqueKey}`}
          ref={(ref) => loadingAnimated.push(ref)}
          style={{ marginBottom: 7, margin: 5, borderRadius: 7 }}
        />
      );
    }
    return (
      <View style={{ flexDirection: "row", marginTop: 10 }}>{shimmerRows}</View>
    );
  }

  _renderCols(loadingColAnimated, numberRow, uniqueKey) {
    let shimmerRows = [];
    for (let index = 0; index < numberRow; index++) {
      shimmerRows.push(
        <ShimmerPlaceHolder
          colorShimmer={["#ececec", "#e4e4e4", "#e4e4e4"]}
          height={110}
          width={width * 0.95}
          key={`loading-${index}-${uniqueKey}`}
          ref={(ref) => loadingColAnimated.push(ref)}
          style={{
            marginBottom: 13,
            borderRadius: 6,
          }}
        />
      );
    }
    return (
      <View style={{ marginTop: 30, alignItems: "center" }}>{shimmerRows}</View>
    );
  }
  priceLoader = (price) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.config.currency)) {
      return price ? price.toFixed(3) : "0.000";
    } else {
      if (this.props.config.currency == "¥") {
        return price ? parseInt(price) : "0";
      } else {
        return price ? price.toFixed(2) : "0.00";
      }
    }
  };

  onClickCampaign = (item) => {
    console.log(item, "itemmmm");

    if (item.content_type == "3") {
      window.open(item.content, "blank");
    } else if (item.content_type == "1" || item.content_type == "2") {
      console.log("hereeeeeeeee", item);
      this.setState({ campaignSelected: item }, () => {
        this.setState({ campaignModal: true });
      });
    } else if (item.content_type == "4") {
      let selectedItem = this.props.allChildren.find((Ritem) => {
        return item.content.id == Ritem.id && Ritem.type == "item";
      });
      if (selectedItem) {
        this.setState({ isHomeActive: false }, () => {
          clearTimeout(this.campaign_time);
          let section = this.props.allChildren.find((Sitem) => {
            return selectedItem.section == Sitem.id && Sitem.type == "section";
          });

          const navigateAction = NavigationActions.navigate({
            routeName: "RecipeDetail",
            params: {
              item: selectedItem,
              index: 0,
              setScreenActive: () => this.setScreenActive(),
              section
            },
          });
          this.props.navigation.dispatch(navigateAction);
        });
      } else {
        this.throwMessageFn("Item not available", "red");
      }
    }
  };
  campaignList = (item) => {
    let section_campaign = this.props.campaigns.filter((campaign) => {
      return (
        campaign.campaign_starts_after == "2" &&
        campaign.start_when.id == item.id
      );
    });
    return section_campaign;
  };

  removeFilterItem = (item) => {

    let filterValues = {
      ...this.state.filterValues,
      [item]: !this.state.filterValues[item]
    }
    let selectedFilters = [...this.state.selectedFilters].filter(Fitem => Fitem !== item)
    this.setState({ filterValues, selectedFilters })
    this.props.searchItem(this.state.searchQuery, filterValues)


  }

  asyncChildrenCall = async (uuid, menuid) => {
    this.props.selectMenu(menuid);
    // this.props.setIsLoading();

    axios
      .get(
        API_URL +
        "api/qr-menu-children/" +
        uuid +
        "/?menu=" +
        menuid +
        "&platform=" +
        this.props.platform +
        "&limit=1500&page=1"
      )
      .then((response) => {
        let datum = JSON.parse(JSON.stringify(response.data.results));

        datum.forEach((element) => {
          let recoms = [];
          let comb = []
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
          if (element.combos) {
            element.combos.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) comb.push(data);
            });
          }
          element.combos = comb;
        });

        this.props.addChildren(datum);
        this.setState({ changeMenuLoader: false })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeMenu = (item) => {
    console.log(item, "kkkk")
    let menuid = item.id;
    if (menuid !== this.props.menu_id) {
      // this.props.addChildren([]);
    } else {
      return;
    }


    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {

        this.setState({ changeMenuLoader: true }, () => {
          this.runPlaceHolder();
          this.runColPlaceHolder();
          this.asyncChildrenCall(uuid, menuid);
          // set menu data in redux
          this.props.selectMenuData(item);
        })



      }
    });


  }

  onSearchHandler = (e) => {
    // console.log(this.state.filterValues, "hereeeeeeee")
    this.props.searchItem(e.target.value, this.state.filterValues)

    this.setState({ searchQuery: e.target.value })
  }

  toggleSearch = () => {
    this.setState({ showSearchInput: !this.state.showSearchInput, searchQuery: "" }, () => {
      if (!this.state.showSearchInput) {
        this.props.searchItem(this.state.searchQuery, this.state.filterValues)
      }
    })
  }
  renderListItem = (item, index) => {
    let section = this.props.initialAllChildren.find((Sitem) => item.section == Sitem.id && Sitem.type == "section");
    if (!section) {
      section = {
        type: "menu",
        id: item.menu,
        show_item_image: true
      }
    }

    return <Recipes
      navigateToDetail={(itm) => {
        this.setState({ isHomeActive: false }, () => {
          clearTimeout(this.campaign_time);

          const navigateAction = NavigationActions.navigate({
            routeName: "RecipeDetail",
            params: {
              item: itm,
              index: index,
              setScreenActive: () => this.setScreenActive(),
              section
            },
          });
          this.props.navigation.dispatch(navigateAction);

          // this.props.navigation.navigate('RecipeDetail',{
          //     item: itm,
          //     index: index
          // })
        });
      }}
      cartLoading={this.state.cartLoading}
      section={section}
      key={index}
      editCartItem={(item, condition)=>this.editCartItem(item, condition, index)}
      onRemoveItem={(item) => this.onRemoveItem(item)}
      _addToCart={(item) => this.addToCartFn(item)}
      onCartClick={(item, index) => this.handleCartClick(item, index)}
      item_page_description_color={"black"}
      item={item}
      key={item.id}
      default_language={this.props.default_language}
      currency={this.props.config.currency}
      config={this.props.config}
      isRTL={this.props.isRTL}
      platform={this.props.platform}
      design={this.props.design}
      cartButtonLoading={this.state.cartButtonLoading}
    />
  }

  toggleTopButtons = ()=> {
    this.setState({showTopButtons:!this.state.showTopButtons})
  }

  render() {
    this.loadingAnimated = [];
    this.loadingColAnimated = [];
    const { isLoading, immediateChildren, selectedMenu } = this.props;
    if (isLoading) {
      return (
        <View style={{ flex: 1 }}>
          {this._renderRows(this.loadingAnimated, 4, "3rows")}
          {this._renderCols(this.loadingColAnimated, 5, "5cols")}
        </View>
      );
    }
    // if (
    //   !isLoading &&
    //   !immediateChildren.length &&
    //   selectedMenu.pdf_menu &&
    //   selectedMenu.pdf_file
    // ) {
    //   return (
    //     <PdfViewer
    //       menu={selectedMenu}
    //       border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
    //       backgroundColor={this.props.design.top_buttons_background_color}
    //       borderColor={"transparent"}
    //       color={this.props.design.top_buttons_text_color}
    //       default_language={this.props.default_language}
    //       navigateBack={() => {
    //         this.setState({ isHomeActive: false }, () => {
    //           this.props.navigation.goBack();
    //         });
    //       }}
    //     />
    //   );
    // }
    // if (!isLoading && !immediateChildren.length) {
    //   return <FallbackScreen errorStatus={{ status: 7 }} />;
    // }

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: this.props.design.list_view_background_color,
        }}
      >


        {this.props.design.display_image_for_menu_page_background && (
          <ProgressiveImage
            position={"absolute"}
            thumbnailSource={{
              uri:
                image_api +
                this.props.design.background_image_menu
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=250&h=250",
            }}
            source={{
              uri:
                image_api +
                this.props.design.background_image_menu
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=800&h=800",
            }}
            style={{ width: "100%", height: "100%", position: "absolute" }}
            resizeMode="cover"
          />
        )}

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {this.props.menu_type == "pickup" || this.props.platform == "qr" ? (
            <View
              style={{
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  const { params } = this.props.navigation.state;
                  if (params) {
                    params.setScreenActive();
                  }
                  if (this.state.menu_type == "pickup") {
                    clearTimeout(this.campaign_time);
                    this.setState({ isHomeActive: false }, () => {
                      this.props.navigation.navigate("IndexScreen");
                    });
                    return;
                  } else if (this.props.platform == "qr") {
                    clearTimeout(this.campaign_time);
                    this.setState({ isHomeActive: false }, () => {
                      this.props.navigation.navigate("MenuHome");
                    });
                    return;
                  } else {
                    this.setState({ isHomeActive: false }, () => {
                      this.props.navigation.goBack();
                    });
                  }
                }}
              >
                <BackButton
                  border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                  backgroundColor={
                    this.props.design.top_buttons_background_color
                  }
                  borderColor={"transparent"}
                  color={this.props.design.top_buttons_text_color}
                  default_language={this.props.default_language}
                  font_size={this.props.design.custom_font.heading_size}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <View
              style={{
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <TouchableOpacity
                style={{ marginTop: 5 }}
                onPress={() => {
                  this.setState({ isHomeActive: false }, () => {
                    this.props.navigation.goBack();
                  });
                }}
              >
                <BackButton
                  border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                  backgroundColor={
                    this.props.design.top_buttons_background_color
                  }
                  borderColor={"transparent"}
                  color={this.props.design.top_buttons_text_color}
                  default_language={this.props.default_language}
                  font_size={this.props.design.custom_font.heading_size}
                />
              </TouchableOpacity>
              <View style={{ margin: 12 }}>
                <StyledText
                  font_color={this.props.design.list_view_header_color}
                  font_weight={this.props.design.list_view_header_font_weight}
                  font_style={this.props.design.list_view_header_font_style}
                  text_transform={this.props.design.popup_text_font_transform}
                  font_family={this.props.design.list_view_header_font_family}
                  font_size={this.props.design.list_view_header_font_size}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                  
                  style={{  }}
                >
                  {this.props.config.venueName}
                </StyledText>
                <StyledText
                 font_color={this.props.design.list_view_header_color}
                 font_weight={this.props.design.list_view_header_font_weight}
                 font_style={this.props.design.list_view_header_font_style}
                 text_transform={this.props.design.popup_text_font_transform}
                 font_family={this.props.design.list_view_header_font_family}
                 font_size={this.props.design.list_view_header_font_size}
                 letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                  style={{  }}
                >
                  {i18n.t("labels.min_order")} : {this.props.config.currency}{" "}
                  {this.props.config.minimum_order_amount
                    ? this.props.config.minimum_order_amount
                    : 0.0}
                </StyledText>
                {this.state.open_time && this.state.close_time ? (
                  <StyledText
                  font_color={this.props.design.list_view_header_color}
                  font_weight={this.props.design.list_view_header_font_weight}
                  font_style={this.props.design.list_view_header_font_style}
                  text_transform={this.props.design.popup_text_font_transform}
                  font_family={this.props.design.list_view_header_font_family}
                  font_size={this.props.design.list_view_header_font_size}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                    style={{  }}
                  >
                    {" "}
                    {i18n.t("labels.open_from")} {this.state.open_time}{" "}
                    {i18n.t("labels.to")} {this.state.close_time}
                  </StyledText>
                ) : null}
              </View>
            </View>
          )}

          <View
            style={{
              alignItems: "flex-end",
            }}
          >
            <View style={{ flexDirection: "row" }}>

              {
                this.props.config.show_favourite ?
                  <TouchableOpacity onPress={() => this.props.navigation.navigate("LikedScreen")}>
                    <HeartButton
                      border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                      backgroundColor={
                        this.props.design.top_buttons_background_color
                      }
                      borderColor={"black"}
                      color={this.props.design.top_buttons_text_color}
                      default_language={this.props.default_language}
                    />
                  </TouchableOpacity>
                  :
                  <View />
              }


              {this.props.platform == "qr" || this.props.platform == 'enterprise' ? (
                this.props.config.capture_profile ||
                  this.props.config.login_required ? (
                  <TouchableOpacity onPress={() => this.onClickProfile()}>
                    <ProfileButton
                      border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                      backgroundColor={
                        this.props.design.top_buttons_background_color
                      }
                      borderColor={"black"}
                      color={this.props.design.top_buttons_text_color}
                      default_language={this.props.default_language}
                    />
                  </TouchableOpacity>
                ) : (
                  <View />
                )
              ) : (
                <TouchableOpacity onPress={() => this.onClickProfile()}>
                  <ProfileButton
                    border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                    backgroundColor={
                      this.props.design.top_buttons_background_color
                    }
                    borderColor={"black"}
                    color={this.props.design.top_buttons_text_color}
                    default_language={this.props.default_language}
                  />
                </TouchableOpacity>
              )}

              <TouchableOpacity>
                {this.props.platform == "qr" || this.props.platform == 'enterprise' &&
                  this.props.config.show_service_button &&
                  this.props.config.general_qr.qr_id != this.state.uuid && (
                    <Bell
                      border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                      throwMessageFn={() =>
                        this.throwMessageFn(
                          i18n.t("labels.a_call_has_been_send_to_waiter"),
                          "green"
                        )
                      }
                      backgroundColor={
                        this.props.design.top_buttons_background_color
                      }
                      borderColor={"black"}
                      color={this.props.design.top_buttons_text_color}
                      default_language={this.props.default_language}
                    />
                  )}
              </TouchableOpacity>

              {this.props.config.multi_language ? (
                <TouchableOpacity>
                  <DefaultLanguage
                    border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                    changeLanguage={() =>
                      this.setState({ languageModal: true })
                    }
                    backgroundColor={
                      this.props.design.top_buttons_background_color
                    }
                    borderColor={"black"}
                    color={this.props.design.top_buttons_text_color}
                    default_language={this.props.default_language}
                    heading_size={this.props.design.custom_font.heading_size}
                  />
                </TouchableOpacity>
              ) : null}
              {
                this.props.config.show_filter && 
              <TouchableOpacity onPress={() => this.setState({ filterModal: !this.state.filterModal })}>
                <FilterButton
                design={this.props.design}
                  border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                  backgroundColor={
                    this.props.design.top_buttons_background_color
                  }
                  borderColor={"black"}
                  color={this.props.design.top_buttons_text_color}
                  default_language={this.props.default_language}
                />
              </TouchableOpacity>
  }
              {
                this.props.config.show_search && 
              <div className="row" style={{ marginLeft: 0, marginRight: 0 }} >

                <input

                  style={{ transition: "width .37s ease-in-out .37s", width: !this.state.showSearchInput ? 0 : width * .86, height: 33, marginTop: 6, position: "absolute", right: 8 }}
                  onChange={this.onSearchHandler}
                  placeholder={i18n.t("labels.search_item")}
                  type="text"
                  class="form-control"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
                <TouchableWithoutFeedback onPress={this.toggleSearch} >
                  <SearchButton
                    border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                    backgroundColor={
                      this.props.design.top_buttons_background_color
                    }
                    borderColor={"black"}
                    color={this.props.design.top_buttons_text_color}
                    default_language={this.props.default_language}
                  />
                </TouchableWithoutFeedback>
              </div>
              }
            </View>
          </View>
        </View>

        <View style={{ flex: 8 }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap", width: width, margin: 5 }}>
            {
              this.state.selectedFilters.map(item => {
                return (
                  <View style={{
                    height: 25,
                    minWidth: 50,
                    backgroundColor: this.props.design.top_buttons_background_color,
                    borderRadius: 6,
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                    marginRight: 6,
                    marginBottom: 3
                  }} >
                    <StyledText 
                      font_color={this.props.design.top_buttons_text_color}
                      font_weight={this.props.design.top_button_text_font_weight}
                      font_style={this.props.design.top_button_text_font_style}
                      text_transform={this.props.design.top_button_text_font_transform}
                      font_family={this.props.design.top_button_text_font_family}
                      font_size={this.props.design.top_button_text_font_size}
                      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                    style={{ 
                      margin: 4 
                      }} >{i18n.t(`labels.${item}`)}</StyledText>
                    <TouchableOpacity onPress={() => this.removeFilterItem(item)} >
                      <View style={{ height: "100%", width: 25, alignItems: "center", justifyContent: "center" }}>
                        <i
                          style={{ color: this.props.design.top_buttons_text_color }}
                          class="fas fa-times"
                        ></i>
                      </View>
                    </TouchableOpacity>
                  </View>
                )
              })
            }

          </View>

          {
            this.props.menus.length > 1 && this.props.platform === "qr" &&
            <View style={{ flexDirection: "row" }}>
              <ScrollView style={{ flexDirection: this.props.isRTL ? "row-reverse" : "row" }} pagingEnabled horizontal showsHorizontalScrollIndicator={false}>
                {
                  this.props.menus.map(item => {
                    return (
                      <TouchableOpacity onPress={() => this.changeMenu(item)} >
                        <View style={{
                          minWidth: 95,
                          padding: 8,
                          borderRadius: 6,
                          height: 25,
                          backgroundColor: this.props.menu_id != item.id ? "transparent" : this.props.design.top_buttons_background_color,
                          borderColor: this.props.menu_id != item.id ? this.props.design.top_buttons_background_color : "transparent",
                          border: "1px solid",
                          marginHorizontal: 5,
                          marginVertical: 10,
                          alignItems: "center",
                          justifyContent: "center",

                        }} >
                          <StyledText 
                          font_color={this.props.menu_id != item.id ? this.props.design.top_buttons_background_color : this.props.design.top_buttons_text_color}
                          font_weight={this.props.design.top_button_text_font_weight}
                          font_style={this.props.design.top_button_text_font_style}
                          text_transform={this.props.design.top_button_text_font_transform}
                          font_family={this.props.design.top_button_text_font_family}
                          font_size={this.props.design.top_button_text_font_size}
                          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
          
                          style={{ fontSize: 12 }} 
                          >
                            <IntlLabel data={item.menu_titles} />
                          </StyledText>
                        </View>

                      </TouchableOpacity>
                    )
                  })
                }
              </ScrollView>
            </View>
          }


          {
            this.state.changeMenuLoader ?
              <View style={{ flex: 1 }}>
                {this._renderRows(this.loadingAnimated, 4, "3rows")}
                {this._renderCols(this.loadingColAnimated, 5, "5cols")}
              </View>
              :
              (!isLoading &&
                !immediateChildren.length &&
                  selectedMenu.pdf_menu &&
                  selectedMenu.pdf_file) ?
                  <View style={{height: height-100}}>
                    <ScrollView >
                    <PdfViewer
                    menu={selectedMenu}
                    border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                    backgroundColor={this.props.design.top_buttons_background_color}
                    borderColor={"transparent"}
                    color={this.props.design.top_buttons_background_color}
                    default_language={this.props.default_language}
                    navigateBack={() => {
                      this.setState({ isHomeActive: false }, () => {
                        this.props.navigation.goBack();
                      });
                    }}
                  />
                  </ScrollView>
                </View>
                :
              <>
            
                <View style={{ marginBottom: 5, minHeight: this.props.design.show_section_image ? 130 : 50 }}>
                  <View style={{ flexDirection: "row" }}>
                    <FlatList
                      bounces={false}
                      showsHorizontalScrollIndicator={false}
                      horizontal
                      extraData={this.state}
                      // onScroll={(event) => console.log(event.nativeEvent.contentOffset.y, 'y')}
                      data={this.props.immediateChildren}
                      renderItem={({ item, index }) => (
                        <HeaderSection
                          design={this.props.design}
                          menu_id={this.props.menu_id}
                          menus={this.props.menus}
                          item={item}
                          onPressItem={() => this.scrollToIndex(index)}
                          default_language={this.props.default_language}
                          // header_color={
                          //   this.state.activeHeaderIndex == index
                          //     ? this.props.design.list_view_price_text_color
                          //     : this.props.design.list_view_text_color
                          // }
                          header_color={this.props.design.section_heading_color ? this.props.design.section_heading_color  : this.props.design.top_buttons_background_color}
                          selected_header_color={this.props.design.selected_section_heading_color ? this.props.design.selected_section_heading_color  : this.props.design.top_buttons_background_color}
                          is_active={this.state.activeHeaderIndex == index}
                          sub_heading_size={this.props.design.custom_font.sub_heading_size}
                          body_size={this.props.design.custom_font.body_size}
                          custom_font={this.props.design.custom_font}
                        />
                      )}
                      keyExtractor={(item) => item.id}
                    />
                  </View>
                </View>
                {!this.state.searchQuery && this.props.menu_campaign.length && this.state.scrollPosition < 20 ? (
                  <View style={{ marginBottom: 5, height: 145 }}>
                    <View style={{ flexDirection: "row" }}>
                      <FlatList
                        pagingEnabled={true}
                        scrollEventThrottle={60}
                        bounces={false}
                        showsHorizontalScrollIndicator={false}
                        horizontal
                        extraData={this.state}
                        data={this.props.menu_campaign}
                        renderItem={({ item, index }) => (
                          <MenuCampaign
                            item={item}
                            menu_campaign={this.props.menu_campaign}
                            type={"menu"}
                            onPressItem={() => this.onClickCampaign(item)}
                          />
                        )}
                        keyExtractor={(item) => item.id}
                      />
                    </View>
                  </View>
                ) : (
                  <View />
                )}
                <View style={{ flex: 1, height: 200 }} >
                  {
                    !immediateChildren.length &&
                    <FallbackScreen errorStatus={{ status: 7 }} />
                  }

                  {
                    this.state.searchQuery || this.state.selectedFilters.length>0 ?
                      <FlatList
                        bounces={false}
                        style={{  height: 200 }}
                        onScroll={(event) => {
                          this.setState({
                            scrollPosition: event.nativeEvent.contentOffset.y,
                          });
                        }}
                        disableIntervalMomentum={true}
                        // onScrollToIndexFailed={30}

                        ref={(ref) => {
                          this.flatListRef = ref;
                        }}
                        data={this.props.allChildren}
                        initialNumToRender={20}
                        renderItem={({ item, index }) => this.renderListItem(item, index)}
                        keyExtractor={(item) => item.id}
                      />
                      :
                      <FlatList
                        bounces={false}
                        style={{  height: 200 }}
                        onScroll={(event) => {
                          this.setState({
                            scrollPosition: event.nativeEvent.contentOffset.y,
                          });
                        }}
                        disableIntervalMomentum={true}
                        // onScrollToIndexFailed={30}

                        ref={(ref) => {
                          this.flatListRef = ref;
                        }}
                        data={this.props.immediateChildren}
                        initialNumToRender={20}
                        renderItem={({ item, index }) => (
                          <ListItem
                            onClickCampaign={this.onClickCampaign}
                            section_campaigns={this.campaignList(item)}
                            index={index}
                            length={this.props.immediateChildren.length}
                            key={item.id}
                            item={item}
                            itemContent={(content, Parentsection) => this.itemContent(content, Parentsection)}
                            renderContent={(content) => this.renderContent(content)}
                            {...this.props}
                          />
                        )}
                        keyExtractor={(item) => item.id}
                      />
                  }

                </View>

              </>
          }





          {/*customize cart item modal*/}
          {/* <Modal
            transparent
            style={{ backgroundColor: "transparent" }}
            animationType="slide"
            visible={this.state.selectedItemModal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {this._renderModalContent()}
          </Modal> */}
          {/*end customize cart item modal*/}

          {/*remove cart item modal*/}
          <Modal
            transparent
            style={{ backgroundColor: "transparent" }}
            animationType="slide"
            visible={this.state.removeItemModal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {this._renderRemoveSelectedItems()}
          </Modal>
          {/*end remove cart item modal*/}
          {/* <Modal
            transparent
            style={{ backgroundColor: "transparent" }}
            animationType="slide"
            visible={this.state.isSpecialNoteVisible}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {
              this.SpecialNoteModalView()
            }
       
      </Modal> */}



          {/*default lan modal*/}

          <Modal
            transparent
            // animationType="slide"
            visible={this.state.languageModal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {this.defaultLangView()}
          </Modal>

          <Modal
            transparent
            // animationType="slide"
            visible={this.state.filterModal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            {this.filterView()}
          </Modal>

        </View>
        {this.props.cart.length > 0 ? (
          <TouchableOpacity
            style={{ marginTop: 40 }}
            onPress={() => {
              this.setState({ isHomeActive: false }, () => {
                clearTimeout(this.campaign_time);
                const navigateAction = NavigationActions.navigate({
                  routeName: "CartScreen",
                  params: {
                    setScreenActive: () => this.setScreenActive(),
                  },
                });
                this.props.navigation.dispatch(navigateAction);

                // this.props.navigation.navigate('CartScreen')
              });
            }}
          >
            {
              console.log(this.props.total_price, "ss")
            }
            <CartTotal
              design={this.props.design}
              display_currency={this.props.config.display_currency}
              display_fraction={this.props.config.display_fraction}
              display_price={this.props.config.display_price}
              currency={this.props.config.currency}
              total={this.props.cart_count}
              total_price={this.props.total_price}
              navigateToCart={() => {
                this.setState({ isHomeActive: false }, () => {
                  clearTimeout(this.campaign_time);
                  const navigateAction = NavigationActions.navigate({
                    routeName: "CartScreen",
                    params: {
                      setScreenActive: () => this.setScreenActive(),
                    },
                  });
                  this.props.navigation.dispatch(navigateAction);
                  // this.props.navigation.navigate('CartScreen')
                });
              }}
              cartScreen={false}
              custom_font={this.props.design.custom_font}
            />
          </TouchableOpacity>
        ) : null}

        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />

        <CampaignModal
          campaign={this.state.campaignSelected}
          campaignModal={this.state.campaignModal}
          closeCampaignModal={this.closeCampaignModal}
          popup_text_color={this.props.design.popup_text_color}
          popup_button_color={this.props.design.popup_button_color}
          popup_button_text_color={this.props.design.popup_button_text_color}
          popup_button_border_color={this.props.design.popup_button_color}
          popup_background_color={this.props.design.popup_background_color}
          subheading_font_size={this.props.design.custom_font.sub_heading_size}
          body_font_size={this.props.design.custom_font.body_size}
          custom_font={this.props.design.custom_font}
        />
        {
          (this.props.config.general_qr.qr_id != this.state.uuid) && !this.props.config.accepting_orders ?
            <View style={{ position: "absolute", bottom: 0, width: "100%", height: 30, backgroundColor: "grey", justifyContent: "center", alignItems: "center" }} >
              <Text style={{ color: "rgb(56 56 56)", fontFamily: 'SecondaryFont' }} >{i18n.t("labels.not_accepting_order")}</Text>
            </View>
            :
            <View />
        }

      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    menu_id: state.menus.menuID,
    isLoading: state.menus.isLoading,
    platform: state.config.platform,
    user: state.number.user,
    menu_type: state.config.menu_type,
    selectedMenu: state.menus.selectedMenu,
    menu_campaign: state.menus.menu_campaign,
    campaigns: state.menus.campaigns,
    design: state.config.design,
    primaryFontFace: state.fontFace.primaryFontFace,
    menus: state.menus.menus,
    initialAllChildren: state.menus.initialAllChildren,
  };
};

export default connect(mapStateToProps, {
  updateChildren,
  addToCart,
  removeItemFromCart,
  addCount,
  subtractCount,
  totalPrice,
  addDefaultLanguage,
  allCart,
  addChildren,
  selectMenu,
  setIsLoading,
  selectMenuData,
  searchItem,
  resetCart
})(HomeScreen);
