'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var PhoneNumberList = require('./v2/phoneNumber').PhoneNumberList;
var SipDomainList = require('./v2/sipDomain').SipDomainList;
var TrunkList = require('./v2/trunk').TrunkList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V2 version of Routes
 *
 * @constructor Twilio.Routes.V2
 *
 * @property {Twilio.Routes.V2.PhoneNumberList} phoneNumbers -
 *          phoneNumbers resource
 * @property {Twilio.Routes.V2.SipDomainList} sipDomains - sipDomains resource
 * @property {Twilio.Routes.V2.TrunkList} trunks - trunks resource
 *
 * @param {Twilio.Routes} domain - The twilio domain
 */
/* jshint ignore:end */
function V2(domain) {
  Version.prototype.constructor.call(this, domain, 'v2');

  // Resources
  this._phoneNumbers = undefined;
  this._sipDomains = undefined;
  this._trunks = undefined;
}

_.extend(V2.prototype, Version.prototype);
V2.prototype.constructor = V2;

Object.defineProperty(V2.prototype,
  'phoneNumbers', {
    get: function() {
      this._phoneNumbers = this._phoneNumbers || new PhoneNumberList(this);
      return this._phoneNumbers;
    }
});

Object.defineProperty(V2.prototype,
  'sipDomains', {
    get: function() {
      this._sipDomains = this._sipDomains || new SipDomainList(this);
      return this._sipDomains;
    }
});

Object.defineProperty(V2.prototype,
  'trunks', {
    get: function() {
      this._trunks = this._trunks || new TrunkList(this);
      return this._trunks;
    }
});

module.exports = V2;
