import {
    GET_MENUS,
    GET_CHILDRENS,
    UPDATE_CHILDRENS,
    RESET_MENU,
    SELECT_MENU,
    GET_MODIFIERS,
    RESET_CHILDREN,
    IS_LOADING,
    GET_ONLINE_CONTENT,
    SET_SELECTED_MENU,
    SET_CAMPAIGNS,
    SEARCH_ITEM,

} from "../actionTypes";
import _ from 'lodash'
import {AvailabilityHandler} from './../../handlers/utils'

const initialState = {
    menus: [],
    _immediateChildren:[],
    allChildren:[],
    initialAllChildren:[],
    menuID:0,
    modifiers:[],
    isLoading: false,
    selectedMenu: "",
    campaigns: [],
    menu_campaign : [],
    test: "",
    appliedFilters: []

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MENUS:
            return {
                ...state,
                menus: action.menus
            };
        case GET_CHILDRENS:
            let modifiers = [...state.modifiers]
            let immediate_children = []
            let if_immidiate_item = []
            let available_items = AvailabilityHandler([...action.children])
            let result = _.each(available_items,(item, index) => {

               if(item.type == 'item'){
                   if(item.section == null){
                        if_immidiate_item.push(item)
                   }
                    _.each(item.modifiers, (modifier,mod_index) => {

                        let children = _.find(modifiers, item => {
                            return item.id == modifier.modifier
                        })
                        // console.log(children,'chillllll modddd')
                        if(children){
                            var copy_mod = _.cloneDeep(children);
                            modifier.modifier_modifier_group = copy_mod.modifier_modifier_group
                        }
                      

                    })

               }else if(item.section === null && item.type === 'section'){

                    immediate_children.push(item)
               }
            })
            if(if_immidiate_item.length>0){
                immediate_children.unshift({
                    type: 'menu',
                    id: state.menuID,
                    show_item_image:true
                })
            }
            return{
                ...state,
                _immediateChildren:immediate_children,
                allChildren: result,
                isLoading: false,
                initialAllChildren: [...result]
                
            };
        case UPDATE_CHILDRENS:
            return{
                ...state,
                allChildren:action.children,
            };
        case RESET_MENU:
            let allChildren = state.allChildren.map(item =>{
                delete item['count'];
                return item
            });
            return{
                ...state,
                allChildren: allChildren,
            };
        case SELECT_MENU:
                let campaigns = [...state.campaigns].filter(item=> {
                    if(item.campaign_starts_after == "1"){
                        if(item.start_when.id == action.menu){
                            return item
                        }
                    }
                })
            return{
                ...state,
                menuID: action.menu,
                menu_campaign : campaigns
            };
        case GET_MODIFIERS:
            return{
                ...state,
                modifiers:action.modifiers
            }
        case RESET_CHILDREN:
            return{
                ...state,
                _immediateChildren:[],
                allChildren:[],
            }
        case IS_LOADING:
            return{
                ...state,
                isLoading:true,
            }
        case GET_ONLINE_CONTENT:
            let resp_mod = [...action.modifiers]
            let online_immediate_children = []
            let if_online_immidiate_item = []

            let new_online_childrens = _.uniqBy([...action.children.concat(action.children)], item => [item.id, item.type].join())
            let available_item = AvailabilityHandler([...new_online_childrens])

            let online_result = _.each(available_item,(item, index) => {
               if(item.type == 'item'){
                   if(item.section == null){
                    if_online_immidiate_item.push(item)
                   }
                    _.each(item.modifiers, (modifier,mod_index) => {

                        let children = _.find(resp_mod, item => {
                            return item.id == modifier.modifier
                        })
                        var copy_mod = _.cloneDeep(children);
                        modifier.modifier_modifier_group = copy_mod.modifier_modifier_group

                    })
               }else if(item.section === null && item.type === 'section'){
                online_immediate_children.push(item)
               }
            })
            if(if_online_immidiate_item.length>0){
                online_immediate_children.unshift({
                    type: 'menu',
                    id: state.menuID,
                    show_item_image:true
                })
            }
            return{
                ...state,
                allChildren: online_result,
                _immediateChildren: online_immediate_children,
                modifiers: action.modifiers,
                isLoading: false
            };
        case SET_SELECTED_MENU:
            return{
                ...state,
                selectedMenu: action.menu
            }
        case SET_CAMPAIGNS:
            let menu_campaigns = [...action.campaigns].filter(item=> {
                if(item.campaign_starts_after == "1"){
                    console.log(item.start_when)
                    if(item.start_when.id == state.menuID){
                        return item
                    }
                }
            })
            return {
                ...state,
                campaigns: action.campaigns,
                menu_campaign : menu_campaigns

            };
        case SEARCH_ITEM:
            let filteredItems
            if(isNaN(parseInt(action.query))){
                let filters = action.filters;
                let q = action.query.toLowerCase()
                let keys
                if(filters){
                    keys = Object.keys(filters)
                }
                
                filteredItems = [...state.initialAllChildren].filter(
                    item =>  {
                        let show_item_search = false
                        let show_item_filter = true
                        if((JSON.stringify(item.item_names).toLowerCase().includes(q) || 
                        JSON.stringify(item.item_descriptions).toLowerCase().includes(q)) &&
                        item.type == "item" 
                        // keys.map(f_item  => item[f_item] == filters[f_item])
                        ){
                            show_item_search = true
                            // return item
                        }
                        if(filters){
                            keys.map( key => {        
                                if(filters[key]){
                                    console.log(key, filters[key]);
                                    show_item_filter = false
                                    if(JSON.stringify(item.warning).includes(key)){
                                        show_item_filter = true
                                    }
                                    // if(item[key] == filters[key]){
                                    //     show_item_filter = true
                                    // }
                                }
                            });
                        }
                        if(show_item_search && show_item_filter){
                            return item
                        }

                    })
            }else{
                filteredItems = [...state.initialAllChildren].filter(
                    item =>  {
                        // if(item.type == "section"){
                        //     return item
                        // }else{
                            let if_price_matched = false;
                            if(item.price){
                                item.price.map(p_item => {
                                    if(parseInt(p_item.price) == parseInt(q)){
                                        if_price_matched = true
                                    }
                                })
                            }
                            if(if_price_matched){
                                return item
                            }
                        // }
                    })
            }
            return {
                ...state,
                allChildren: filteredItems,
            }
   
        default:
            return state;
    }
};

export default reducer;