import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import { Row, Col, Button } from "native-base";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import axios from "axios";
import { API_URL } from "./../constants/index";
import Toast from "./../components/toast";
import { style } from "../../assets/style";
import Modal from "modal-react-native-web";
import Icono from "@expo/vector-icons/Ionicons";
import { Hr, Br } from "./../components/horizontalLine";
import { performRequest } from "./../services/apiHandler";
import ShimmerPlaceHolder from "./../components/placeHolder";
import Constants from "expo-constants";
import i18n from "../i18n";
import IntlLabel from "./../handlers/languageHandler";
import IntlLabelFn from "./../handlers/languageHandlerFn";
import { FontLetterSpacing } from "./../handlers/utils";
import moment from "moment-timezone";

const STATUS_CHOICES = {
  1: "Pending",
  2: "Accepted",
  3: "Cancelled",
  4: "Rejected",
  5: "In Kichen",
  6: "In Route",
  7: "Delivered",
  8: "Ready to pick",
  9: "Accepted",
};

class Orders extends Component {
  state = {
    error_message: "",
    message_status: "",
    message_display: "none",
    orders: [],
    order_detail: "",
    orderDetailModal: false,
    isLogged: true,
    isLoading: true,
  };
  static navigationOptions = {
    header: null,
    gesturesEnabled: true,
    animationEnabled: true,
  };
  navigateToLogin = () => {
    // this.props.navigation.navigate('MobileForm')
  };
  componentDidMount() {
    // if(!this.props.user){
    //     this.setState({isLogged: false})
    //     // this.props.navigation.navigate('RecentOrdersScreen')
    // }
    performRequest(
      "get",
      `api/list/customer/orders/?user=${
        this.props.user ? this.props.user.id : ""
      }&venue=${this.props.config.venue}&mid=${Constants.deviceId}`,
      null,
      false
    )
      // axios.get(API_URL+`api/list/customer/orders/?user=${this.props.user.id}&venue=${this.props.config.venue}`,)
      .then((response) => {
        this.setState({ orders: response.data.results, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false, orders: [] });

        this.throwMessageFn("Something went wrong. Try reloading page", "red");
      });
  }
  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  bindOrderTime = (time) => {
    console.log(this.props.config.timezone,time," --- ",moment().format("MMM DD, YYYY HH:mm:ss") ,"   ",moment.tz(time, "MMM DD, YYYY HH:mm:ss","Asia/Dubai" ).isValid(),"configggggggg")
    
    if(this.props.config.timezone){
      let dubai_time = moment.tz(time, "MMM DD, YYYY HH:mm:ss","Asia/Dubai" );
      console.log(dubai_time, "dubaiiiii", dubai_time.clone().tz(this.props.config.timezone), "indiaa" )

      var time = dubai_time.clone().tz(this.props.config.timezone).format('MMMM DD, YYYY HH:mm:ss');
      return time
    }
    return time
  }
  _orderDetailModal = () => {
    if (this.state.order_detail) {
      return (
        <View style={{ flex: 1,
        //  backdropFilter: "blur(5px)"  
         backgroundColor: "rgba(0, 0, 0, 0.5)"
         }}>
          <View
            style={{
              height: 40,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderBottomColor: "rgba(12, 10, 10, 0.2)",
              flexDirection: "row",
            }}
          >
            <Text
              numberOfLines={1}
              style={{ fontWeight: "500",letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.sub_heading_size ?? 16, marginLeft: 8, fontFamily:'SecondaryFont' }}
            >
              Order Summary
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={style.ticket}>
              <View style={{ flex: 1, marginLeft: 3 }}>
                <Br />
                <Br />
                <Br />
                {this.state.order_detail.channel == 3 && (
                  <>
                    <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontFamily:'SecondaryFont'}}>Deliver to</Text>
                    <Hr />
                    <Text
                      numberOfLines={1}
                      style={{ color: "grey", letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 11, fontFamily:'SecondaryFont' }}
                    >
                      {
                        this.state.order_detail.delivery_details.address
                          .complete_address
                      }
                      ,{" "}
                      {
                        this.state.order_detail.delivery_details.address
                          .location_str
                      }
                    </Text>
                  </>
                )}

                <Br />
                <Br />
                <Br />
                <Br />

                <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontFamily:'SecondaryFont'}}>Your Order</Text>
                <Hr />
                <View style={{ maxHeight: 200 }}>
                  <ScrollView>
                    {this.state.order_detail.item_detail.items.map((itm, i) => (
                      <View>
                        <Text numberOfLines={1} style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 12, fontFamily:'SecondaryFont' }}>
                          {itm.name} {itm.modifier.length > 0 ? "+" : ""}{" "}
                          {itm.modifier.map(
                            (modifier) =>
                            IntlLabelFn(JSON.parse(modifier.names), this.props.config.default_language.value, this.props.default_language)
                          )}
                          
                        </Text>
                        <Row
                          style={{
                            justifyContent: "space-between",
                            marginVertical: 3,
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{
                                minWidth: 16,
                                minHeight: 16,
                                border: "1px solid grey",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 9, fontFamily:'SecondaryFont' }}>{itm.count}</Text>
                            </View>
                            <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, fontFamily:'SecondaryFont' }}>
                              {" "}
                              x{" "}
                              {
                                this.state.order_detail.item_detail
                                  .currency_used
                              }{" "}
                              {itm.selected_price.price.toFixed(2)}
                            </Text>
                          </View>
                          <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, fontFamily:'SecondaryFont' }}>
                            {this.state.order_detail.item_detail.currency_used}{" "}
                            {parseInt(itm.count) *
                              parseFloat(itm.selected_price.price).toFixed(2)}
                          </Text>
                        </Row>
                        <Hr />
                      </View>
                    ))}
                  </ScrollView>
                </View>
                <View style={{ height: 14, marginBottom: 15 }}>
                  <Row style={{ justifyContent: "space-between" }}>
                    <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey", fontFamily:'SecondaryFont' }}>Total</Text>
                    <Text style={{ fonletterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), tSize: this.props.design.custom_font.body_size ?? 10, fontFamily:'SecondaryFont'}}>
                      {this.state.order_detail.item_detail.currency_used}{" "}
                      {this.state.order_detail.item_detail.total}
                    </Text>
                  </Row>
                </View>

                {/* <Hr/> */}

                <Text style={{fontFamily:'SecondaryFont'}}>Order details</Text>
                <Hr />
                <View style={{ marginBottom: 5 }}>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey", fontFamily:'SecondaryFont' }}>
                    Order Number
                  </Text>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, fontFamily:'SecondaryFont' }}>
                    {this.state.order_detail.ticket}
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey", fontFamily:'SecondaryFont' }}>
                    Delivery Charge
                  </Text>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10,fontFamily:'SecondaryFont' }}>
                    {this.state.order_detail.delivery_charge}
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey", fontFamily:'SecondaryFont' }}>
                    {i18n.t("button_text.payment")}
                  </Text>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10,fontFamily:'SecondaryFont' }}>
                    {this.state.order_detail.payment_mode == 2
                      ? "Paid"
                      : "Pending"}{" "}
                    : {this.paymentMethod()}
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey" , fontFamily:'SecondaryFont'}}>Date</Text>
                  <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),  fontSize: this.props.design.custom_font.body_size ?? 10,fontFamily:'SecondaryFont' }}>
                    {/* {this.state.order_detail.sent_time} */}
                    {
                      this.bindOrderTime(this.state.order_detail.sent_time)
                    }
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey",fontFamily:'SecondaryFont' }}>
                    {i18n.t("checkout.phone_number")}
                  </Text>
                  {this.state.order_detail.delivery_details.mobile_number && (
                    <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, fontFamily:'SecondaryFont'}}>
                      +
                      {this.state.order_detail.delivery_details.mobile_number.slice(
                        0,
                        9
                      )}
                      XXX
                    </Text>
                  )}
                </View>
              </View>

              <TouchableOpacity
                style={{ position: "absolute", top: 5, right: 5 }}
                onPress={() => this.toggleDetailPopup()}
              >
                <View style={[style.closeButtonModal]}>
                  <Icono
                    style={{ marginTop: 2 }}
                    name={"ios-close"}
                    color={"#5d6670"}
                    size={40}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  paymentMethod = () => {
    if (this.state.order_detail.channel == 3) {
      if (this.state.order_detail.payment_mode == 2) {
        return i18n.t("checkout.online_payment");
      } else if (this.state.order_detail.payment_mode == 4) {
        return "Not Applicable";
      } else if (this.state.order_detail.payment_mode == 1) {
        return i18n.t("checkout.cash_on_delivery");
      } else {
        return i18n.t("checkout.card_on_delivery");
      }
    } else {
      if (this.state.order_detail.payment_mode == 2) {
        return i18n.t("checkout.online_payment");
      } else if (this.state.order_detail.payment_mode == 4) {
        return "Not Applicable";
      } else if (this.state.order_detail.payment_mode == 1) {
        return i18n.t("checkout.cash_on_pickup");
      } else {
        return i18n.t("checkout.card_on_pickup");
      }
    }
  };

  toggleDetailPopup = (item) => {
    this.setState({ order_detail: item }, () => {
      this.setState({ orderDetailModal: !this.state.orderDetailModal });
    });
  };
  _renderCols(loadingColAnimated, numberRow, uniqueKey) {
    let shimmerRows = [];
    for (let index = 0; index < numberRow; index++) {
      shimmerRows.push(
        <ShimmerPlaceHolder
          colorShimmer={["#ececec", "#e4e4e4", "#e4e4e4"]}
          height={110}
          width={Dimensions.get("window").width * 0.95}
          key={`loading-${index}-${uniqueKey}`}
          ref={(ref) => loadingColAnimated.push(ref)}
          style={{
            marginBottom: 13,
            borderRadius: 6,
          }}
        />
      );
    }
    return (
      <View style={{ marginTop: 30, alignItems: "center" }}>{shimmerRows}</View>
    );
  }
  runColPlaceHolder() {
    if (
      Array.isArray(this.loadingColAnimated) &&
      this.loadingColAnimated.length > 0
    ) {
      Animated.parallel(
        this.loadingColAnimated.map((animate) => {
          if (animate && animate.getAnimated) {
            return animate.getAnimated();
          }
          return null;
        }),
        {
          stopTogether: false,
        }
      ).start(() => {
        this.runColPlaceHolder();
      });
    }
  }
  goBack = () => {
    //   try{
    const { state, setParams, navigate } = this.props.navigation;
    const params = state.params || {};
    params.returnAccount();
    return;
    //   }catch(err){
    //       console.log('')
    //   }
    //   this.props.navigation.goBack()
  };
  render() {
    this.loadingColAnimated = [];

    if (this.state.isLogged && !this.state.isLoading) {
      if (!this.state.orders.length) {
        return (
          <View
            style={{ flex: 1, alignItems: "center", backgroundColor: "white" }}
          >
            <View
              style={{
                backgroundColor: "red",
                height: 240,
                width: 300,
                marginTop: 50,
              }}
            >
              <Image
                source={require("./../../assets/emptymenu.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </View>
            <View style={{ alignItems: "center" }}>
              <Text style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontWeight: "bold", fontSize: this.props.design.custom_font.sub_heading_size ?? 20,fontFamily:'SecondaryFont' }}>
                No Orders Yet
              </Text>
              <Text style={{ color: "grey",fontFamily:'SecondaryFont' }}>
                You don't have any previous orders with this restaurent
              </Text>
            </View>
            <View style={{ width: "90%", position: "absolute", bottom: 10 }}>
              <Button
                onPress={() => this.props.navigation.navigate("HomeScreen")}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 10,
                  backgroundColor:
                    this.props.design.top_buttons_background_color,
                }}
              >
                <Text
                  style={{
                    color: this.props.design.top_buttons_text_color,
                    fontWeight: "bold",
                    fontFamily:'SecondaryFont'
                  }}
                >
                  Order Now
                </Text>
              </Button>
            </View>

            <TouchableOpacity
              style={{ position: "absolute", top: 0, left: 0 }}
              onPress={() => this.goBack()}
            >
              <BackButton
                border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
                backgroundColor={this.props.design.top_buttons_background_color}
                borderColor={"black"}
                color={this.props.design.top_buttons_text_color}
                default_language={this.props.default_language}
                font_size={this.props.design.custom_font.heading_size}
              />
            </TouchableOpacity>
          </View>
        );
      } else
        return (
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, backgroundColor: "white" }}>
              <Text
                style={{
                  fontSize: this.props.design.custom_font.sub_heading_size ?? 20,
                  letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                  fontWeight: "bold",
                  marginTop: 80,
                  marginVertical: 15,
                  marginLeft: 5,
                  fontFamily:'SecondaryFont'
                }}
              >
                {i18n.t("checkout.your_order")}
              </Text>
              <ScrollView>
                {this.state.orders.map((item, index) => {
                  return (
                    <View style={style.recentOrderCard}>
                      <TouchableOpacity
                        onPress={() => this.toggleDetailPopup(item)}
                      >
                        <View style={{ marginLeft: 8 }}>
                          <Text
                            style={{
                              fontSize: this.props.design.custom_font.body_size ?? 10,
                              letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                              color: "grey",
                              paddingVertical: 5,
                              fontFamily:'SecondaryFont'
                            }}
                          >
                            {item.venue}
                          </Text>

                          {/* <Text style={{fontSize:10,color:'grey',paddingVertical:5}}>{item.delivery_details.address.location_str}</Text> */}
                        </View>
                        <View
                          style={{
                            borderBottomColor: "#eff0f1",
                            borderBottomWidth: 1,
                            marginHorizontal: 6,
                            marginBottom: 5,
                          }}
                        />
                        <View style={{ marginLeft: 8 }}>
                          <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "grey",fontFamily:'SecondaryFont' }}>
                            ITEMS
                          </Text>
                          <Text
                            numberOfLines={1}
                            style={{letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "black", fontFamily:'SecondaryFont' }}
                          >
                            {item.item_detail.items.map(
                              (itm, i) =>
                                `${itm.count} x ${itm.name} ${
                                  itm.modifier.length > 0 ? "+" : ""
                                }  ${itm.modifier.map(
                                  (modifier) =>
                                  IntlLabelFn(JSON.parse(modifier.names), this.props.config.default_language.value, this.props.default_language)
                                    
                                )} ${
                                  item.item_detail.items.length - 1 == i
                                    ? ""
                                    : ","
                                } `
                            )}
                          </Text>

                          <Text
                            style={{
                              fontSize: this.props.design.custom_font.body_size ?? 10,
                              letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                              color: "grey",
                              marginTop: 5,
                              fontFamily:'SecondaryFont'
                            }}
                          >
                            ORDERED ON
                          </Text>
                          <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "black",fontFamily:'SecondaryFont' }}>
                            {/* {item.sent_time} */}
                            {this.bindOrderTime(item.sent_time)}

                          </Text>

                          <Text
                            style={{
                              fontSize: this.props.design.custom_font.body_size ?? 10,
                              letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                              color: "grey",
                              marginTop: 5,
                              fontFamily:'SecondaryFont'
                            }}
                          >
                            TOTAL AMOUNT
                          </Text>
                          <Text style={{ letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize: this.props.design.custom_font.body_size ?? 10, color: "black",fontFamily:'SecondaryFont' }}>
                            {item.item_detail.currency_used}{" "}
                            {item.item_detail.total}
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <View
                        style={{
                          borderBottomColor: "#eff0f1",
                          borderBottomWidth: 1,
                          marginHorizontal: 6,
                          marginTop: 5,
                        }}
                      />
                      <View
                        style={{
                          marginLeft: 8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: this.props.design.custom_font.body_size ?? 10,
                            letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                            color: "grey",
                            paddingVertical: 5,
                            fontFamily:'SecondaryFont'
                          }}
                        >
                          {STATUS_CHOICES[item.delivery_status]}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </ScrollView>
            </View>

            <TouchableOpacity
              style={{ position: "absolute", top: 0 }}
              onPress={() => this.goBack()}
            >
              <BackButton
                border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
                backgroundColor={this.props.design.top_buttons_background_color}
                borderColor={"black"}
                color={this.props.design.top_buttons_text_color}
                default_language={this.props.default_language}
                font_size={this.props.design.custom_font.heading_size}
              />
            </TouchableOpacity>
            <Toast
              error_message={this.state.error_message}
              message_status={this.state.message_status}
              message_display={this.state.message_display}
              custom_font={this.props.design.custom_font}
            />

            <Modal
              transparent
              style={{ backgroundColor: "transparent" }}
              animationType="slide"
              // visible={true}
              visible={this.state.orderDetailModal}
            >
              {this._orderDetailModal()}
            </Modal>
          </View>
        );
    } else {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          {this._renderCols(this.loadingColAnimated, 5, "5cols")}

          {/* <Text>Login <TouchableOpacity onPress={this.navigateToLogin}><Text style={{color:'blue'}}>here</Text></TouchableOpacity> to view orders</Text> */}

          <TouchableOpacity
            style={{ position: "absolute", top: 0, left: 0 }}
            onPress={() => this.goBack()}
          >
            <BackButton
              border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
              backgroundColor={this.props.design.top_buttons_background_color}
              borderColor={"black"}
              color={this.props.design.top_buttons_text_color}
              default_language={this.props.default_language}
              font_size={this.props.design.custom_font.heading_size}
            />
          </TouchableOpacity>
        </View>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.number.user,
    config: state.config.config,
    default_language: state.languages.default_language,
    design: state.config.design,

  };
};
export default connect(mapStateToProps, null)(Orders);
