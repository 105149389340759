import React,{Component} from 'react';
import {View, Text,TouchableOpacity,ImageBackground, Image } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import {style} from "../../assets/style";
import {image_api} from './../constants/index'



export default class MenuCampaign extends React.Component{
   

    render(){
        const {item, menu_campaign} = this.props;
        if(item){
            return(
                <TouchableOpacity style={menu_campaign.length>1 ? style.topCampaign : style.topCampaignSingle } onPress={this.props.onPressItem}>
                    <Image imageStyle={{borderRadius:7}} style={{width: '100%', height: 130,justifyContent:'flex-end',borderRadius:7}} source={item.banner ? {uri: image_api+item.banner.split('/').splice(3).join('/')+"?w=450&h=450"}:require('./../../assets/food.png')}/>
                </TouchableOpacity>
            )
        }
        return <View/>

    }

}
