import {THREE_FRACTION_CURRENCY} from './../constants'
import i18n from "../i18n";
import {connect, useSelector} from "react-redux"

import moment from "moment"

let date_format = 'DD/MM/YYYY'
const d_t_format = "YYYY-MM-DDTHH:mm:ss"

export const LanguageHandler = (data, language) => {
  const default_lan = useSelector(state => state.config.config.default_lanuage.value)
  if(data[language]){
    return data[language]
  }else{
    return data[default_lan]
  }

}


export const dateLoader = (date) => {
    let new_date = date
    let today = moment().format(date_format)
    let tomorrow = moment().add(1, 'days').format(date_format)
    // console.log(today, tomorrow)
    if(today == date){
      // new_date = "Today"
      new_date = i18n.t("labels.today")
    }if(tomorrow == date){
      // new_date = "Tomorrow"
      new_date = i18n.t("labels.tomorrow")
    }
    return new_date
  }


  export const AvailabilityHandler = (data) => {
    let new_data = data.filter(item => {
      if(item.availability){
        let aval = item.availability
        let now = moment()
        let today = now.format(date_format)
        if (aval.availability == "2"){
          // return item
          let start_date = moment(aval.custom_range.startTime.slice(0,-1), d_t_format)
          let end_date = moment(aval.custom_range.endTime.slice(0,-1), d_t_format)
          let start_date_formatted = moment(start_date.format(date_format), date_format)
          let end_date_formatted = moment(end_date.format(date_format), date_format).add(1,"days")
          
          if(now.isBetween(start_date_formatted, end_date_formatted)){
            let start_time_today = moment(`${today} ${start_date.format('HH')}:${start_date.format('mm')}`, `${date_format} HH:mm`)
            let end_time_today = moment(`${today} ${end_date.format('HH')}:${end_date.format('mm')}`, `${date_format} HH:mm`)

            if(now.isBetween(start_time_today, end_time_today)){
              return item
            }else{
                let time_list = aval.custom_range.time
                let available = false
                if(time_list){
                  for(let i=0;i<time_list.length;i++){
                    let start_time = moment(`${today} ${time_list[i].startTime}`, `${date_format} HH:mm`)
                    let end_time = moment(`${today} ${time_list[i].endTime}`, `${date_format} HH:mm`)
  
                    if(now.isBetween(start_time, end_time)){
                      available = true
                      break;
                    }
                  }
                  if (available) return item
                }

            }
          }

        }else if(aval.availability == "3"){
          let is_available = aval.periodic_details.find(item => item.dayOfWeek-1 == now.day())
          // console.log("hereeeeeee",is_available, now.day(), aval.periodic_details )

          if(is_available){
            let time_list = is_available.time
            let available = false
            if(time_list){
              for(let i=0;i<time_list.length;i++){
                let start_time = moment(`${today} ${time_list[i].startTime}`, `${date_format} HH:mm`)
                let end_time = moment(`${today} ${time_list[i].endTime}`, `${date_format} HH:mm`)
                // console.log(now.isBetween(start_time, end_time),  "blaaaa")
  
                if(now.isBetween(start_time, end_time)){
                  available = true
                  break;
                }
              }
              if (available) return item
            }

          }
        }else{
          return item
        }
      }else{
        return item
      }
    })
    return new_data
  }


  export const PriceLoader = (price, currency, display_fraction) => {
    if (THREE_FRACTION_CURRENCY.includes(currency)) {
      if(display_fraction) return price ? price.toFixed(3) : "0.000";
      else return parseInt(price)
    } else {
      if (currency == "¥" || !display_fraction) {
        return price ? parseInt(price) : "0";
      } else {
        return price ? price.toFixed(2) : "0.00";
      }
    }
  };


  export const FontLetterSpacing = (val) => {
    let letterSpaceValue = "0px";
    
    if(val && val == "normal") {
      letterSpaceValue = "0px";
    } else if(val && val == "wide") {
      letterSpaceValue = "3px";
    } else if(val && val == "extrawide" ) {
      letterSpaceValue = "5px";
    }
    return letterSpaceValue;
  }