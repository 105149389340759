'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var BrandedChannelList = require(
    './trusted_comms/brandedChannel').BrandedChannelList;
var BrandsInformationList = require(
    './trusted_comms/brandsInformation').BrandsInformationList;
var CpsList = require('./trusted_comms/cps').CpsList;
var CurrentCallList = require('./trusted_comms/currentCall').CurrentCallList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the TrustedComms version of Preview
 *
 * @constructor Twilio.Preview.TrustedComms
 *
 * @property {Twilio.Preview.TrustedComms.BrandedChannelList} brandedChannels -
 *          brandedChannels resource
 * @property {Twilio.Preview.TrustedComms.BrandsInformationList} brandsInformation -
 *          brandsInformation resource
 * @property {Twilio.Preview.TrustedComms.CpsList} cps - cps resource
 * @property {Twilio.Preview.TrustedComms.CurrentCallList} currentCalls -
 *          currentCalls resource
 *
 * @param {Twilio.Preview} domain - The twilio domain
 */
/* jshint ignore:end */
function TrustedComms(domain) {
  Version.prototype.constructor.call(this, domain, 'TrustedComms');

  // Resources
  this._brandedChannels = undefined;
  this._brandsInformation = undefined;
  this._cps = undefined;
  this._currentCalls = undefined;
}

_.extend(TrustedComms.prototype, Version.prototype);
TrustedComms.prototype.constructor = TrustedComms;

Object.defineProperty(TrustedComms.prototype,
  'brandedChannels', {
    get: function() {
      this._brandedChannels = this._brandedChannels || new BrandedChannelList(this);
      return this._brandedChannels;
    }
});

Object.defineProperty(TrustedComms.prototype,
  'brandsInformation', {
    get: function() {
      this._brandsInformation = this._brandsInformation || new BrandsInformationList(this);
      return this._brandsInformation;
    }
});

Object.defineProperty(TrustedComms.prototype,
  'cps', {
    get: function() {
      this._cps = this._cps || new CpsList(this);
      return this._cps;
    }
});

Object.defineProperty(TrustedComms.prototype,
  'currentCalls', {
    get: function() {
      this._currentCalls = this._currentCalls || new CurrentCallList(this);
      return this._currentCalls;
    }
});

module.exports = TrustedComms;
