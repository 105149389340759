import React, {Component} from 'react';
import {View, TouchableOpacity, ScrollView, Dimensions, Text} from 'react-native';
import { FontLetterSpacing } from './../handlers/utils';
import StyledText from "../components/styledText"

import {style} from "../../assets/style";
// import Icono from '@expo/vector-icons/Ionicons';
import Icon from './Warning'



class SelectorModal extends Component{
    render(){
        return(
            <View style={{flex:1,alignItems:'flex-end',justifyContent:'flex-end',backgroundColor:'transparent', 
            // backdropFilter: "blur(5px)"
            backgroundColor: "rgba(0, 0, 0, 0.5)"
             }} >
            <TouchableOpacity onPress={this.props.toggleModal}>
                <View style={{
                    height:Dimensions.get('window').height*.5,
    
                    backgroundColor:'transparent',
                    width:Dimensions.get('window').width,
                //
                }}
    
                />
            </TouchableOpacity>
    
            <View style={{
                width:Dimensions.get('window').width,
                backgroundColor: this.props.design.popup_background_color,
                borderWidth:1,
                justifyContent: 'center',
                borderColor: 'rgba(0, 0, 0, 0.1)',
            }} >
                <View style={{height:40,width:'100%',alignItems:'center',justifyContent:'space-between',borderBottomWidth:1,borderBottomColor:'rgba(12, 10, 10, 0.2)',flexDirection:'row'}}>
                    <StyledText 
                     numberOfLines={1} 
                     font_color={this.props.design.popup_heading_text_color}
                    font_weight={this.props.design.popup_heading_font_weight}
                    font_style={this.props.design.popup_heading_font_style}
                    text_transform={this.props.design.popup_heading_font_transform}
                    font_family={this.props.design.popup_heading_font_family}
                    font_size={this.props.design.popup_heading_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                      style={{
                           marginLeft:8, 
                           }}>
                          {this.props.title}
                      </StyledText>
                    <TouchableOpacity onPress={this.props.toggleModal}>
                        <View style={style.closeButton}>
                            <Icon name={'close'} color={'#F44336'} size={30}/>

                            {/* <Icono name={'ios-close'} color={'#F44336'} size={30}/> */}
                        </View>
                    </TouchableOpacity>
    
                </View>
                <ScrollView bounces={false} style={{maxHeight:this.props.height}}>
                  {this.props.children}

                </ScrollView>
            </View>
        </View>
        )
    }
}

export default SelectorModal