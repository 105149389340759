'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var TaskChannelList;
var TaskChannelPage;
var TaskChannelInstance;
var TaskChannelContext;

/* jshint ignore:start */
/**
 * Initialize the TaskChannelList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that contains the Task Channel
 */
/* jshint ignore:end */
TaskChannelList = function TaskChannelList(version, workspaceSid) {
  /* jshint ignore:start */
  /**
   * @function taskChannels
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext}
   */
  /* jshint ignore:end */
  function TaskChannelListInstance(sid) {
    return TaskChannelListInstance.get(sid);
  }

  TaskChannelListInstance._version = version;
  // Path Solution
  TaskChannelListInstance._solution = {workspaceSid: workspaceSid};
  TaskChannelListInstance._uri = `/Workspaces/${workspaceSid}/TaskChannels`;
  /* jshint ignore:start */
  /**
   * Streams TaskChannelInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  TaskChannelListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists TaskChannelInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskChannelListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of TaskChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskChannelListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of TaskChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TaskChannelListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a TaskChannelInstance
   *
   * @function create
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.friendlyName -
   *          A string to describe the Task Channel resource
   * @param {string} opts.uniqueName -
   *          An application-defined string that uniquely identifies the Task Channel
   * @param {boolean} [opts.channelOptimizedRouting] -
   *          Whether the Task Channel should prioritize Workers that have been idle
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TaskChannelInstance
   */
  /* jshint ignore:end */
  TaskChannelListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['friendlyName'])) {
      throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
    }
    if (_.isUndefined(opts['uniqueName'])) {
      throw new Error('Required parameter "opts[\'uniqueName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'UniqueName': _.get(opts, 'uniqueName'),
      'ChannelOptimizedRouting': serialize.bool(_.get(opts, 'channelOptimizedRouting'))
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TaskChannelInstance(
        this._version,
        payload,
        this._solution.workspaceSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a task_channel
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @param {string} sid - The SID of the Task Channel resource to fetch
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext}
   */
  /* jshint ignore:end */
  TaskChannelListInstance.get = function get(sid) {
    return new TaskChannelContext(this._version, this._solution.workspaceSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TaskChannelListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TaskChannelListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return TaskChannelListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TaskChannelPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TaskChannelSolution} solution - Path solution
 *
 * @returns TaskChannelPage
 */
/* jshint ignore:end */
TaskChannelPage = function TaskChannelPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TaskChannelPage.prototype, Page.prototype);
TaskChannelPage.prototype.constructor = TaskChannelPage;

/* jshint ignore:start */
/**
 * Build an instance of TaskChannelInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelPage#
 *
 * @param {TaskChannelPayload} payload - Payload response from the API
 *
 * @returns TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelPage.prototype.getInstance = function getInstance(payload) {
  return new TaskChannelInstance(this._version, payload, this._solution.workspaceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskChannelPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskChannelPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskChannelContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} uniqueName -
 *          An application-defined string that uniquely identifies the Task Channel
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the Task Channel
 * @property {boolean} channelOptimizedRouting -
 *          Whether the Task Channel will prioritize Workers that have been idle
 * @property {string} url - The absolute URL of the Task Channel resource
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {TaskChannelPayload} payload - The instance payload
 * @param {sid} workspaceSid -
 *          The SID of the Workspace that contains the Task Channel
 * @param {sid_like} sid - The SID of the Task Channel resource to fetch
 */
/* jshint ignore:end */
TaskChannelInstance = function TaskChannelInstance(version, payload,
                                                    workspaceSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.channelOptimizedRouting = payload.channel_optimized_routing; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, sid: sid || this.sid, };
};

Object.defineProperty(TaskChannelInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TaskChannelContext(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TaskChannelInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a TaskChannelInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          A string to describe the Task Channel resource
 * @param {boolean} [opts.channelOptimizedRouting] -
 *          Whether the TaskChannel should prioritize Workers that have been idle
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a TaskChannelInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskChannelInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskChannelInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskChannelContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid -
 *          The SID of the Workspace with the Task Channel to fetch
 * @param {sid_like} sid - The SID of the Task Channel resource to fetch
 */
/* jshint ignore:end */
TaskChannelContext = function TaskChannelContext(version, workspaceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, sid: sid, };
  this._uri = `/Workspaces/${workspaceSid}/TaskChannels/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a TaskChannelInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskChannelInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a TaskChannelInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          A string to describe the Task Channel resource
 * @param {boolean} [opts.channelOptimizedRouting] -
 *          Whether the TaskChannel should prioritize Workers that have been idle
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'ChannelOptimizedRouting': serialize.bool(_.get(opts, 'channelOptimizedRouting'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskChannelInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a TaskChannelInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskChannelInstance
 */
/* jshint ignore:end */
TaskChannelContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.TaskChannelContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskChannelContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TaskChannelContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TaskChannelList: TaskChannelList,
  TaskChannelPage: TaskChannelPage,
  TaskChannelInstance: TaskChannelInstance,
  TaskChannelContext: TaskChannelContext
};
