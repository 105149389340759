"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

/**
 * useScript hook
 *
 * https://usehooks.com/useScript
 */
function useScript(src) {
  (0, _react.useEffect)(function () {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!src) {
      return;
    } // Fetch existing script element by src
    // It may have been added by another intance of this hook


    var script = document.querySelectorAll("script[src=\"".concat(src, "\"]"))[0];

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true; // Add script to document body

      document.body.appendChild(script);
    }
  }, [src] // Only re-run effect if script src changes
  );
}

var _default = useScript;
exports.default = _default;