import React, { Component } from 'react';
import { View, Text, TouchableOpacity, ImageBackground, Image } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import { style } from "../../assets/style";
import { image_api } from './../constants/index';
import IntlLabel from "./../handlers/languageHandler";
import { FontLetterSpacing } from './../handlers/utils';

import StyledText from "../components/styledText"


export default class HeaderSection extends React.Component {
    state = {
        menu: this.props.menus.find(Mitem => Mitem.id == this.props.menu_id)
    }

    render() {
        // const image_api = "https://d2ceauryvxkzea.cloudfront.net/fit-in/300x300/"
        const { item, header_color, is_active, design } = this.props;
        const { menu } = this.state;
        // let menu = {}
        // if (item.type=='menu'){
        //     menu = this.props.menus.find(Mitem => Mitem.id == this.props.menu_id)
        // }
        if (design.show_section_image) {
            return (
                <TouchableOpacity style={style.topSection} onPress={this.props.onPressItem}>
                    <Image 
                    imageStyle={{ borderRadius: 7 }} 
                    style={{ width: '100%', height: 80, justifyContent: 'flex-end', borderRadius: 7 }} 
                    source={
                        item.type != 'menu' ? item.image ? { uri: image_api + this.props.item.image.split('/').splice(3).join('/') + "?w=250&h=250" } : design.show_default_section_image && design.default_section_image ? { uri: image_api + design.default_section_image.split('/').splice(3).join('/') + "?w=250&h=250" } : require('./../../assets/food.png') : menu ? menu.image ? { uri: image_api + menu.image.split('/').splice(3).join('/') + "?w=250&h=250" } : design.show_default_section_image && design.default_section_image ? { uri: image_api + design.default_section_image.split('/').splice(3).join('/') + "?w=250&h=250" } : require('./../../assets/food.png') : require('./../../assets/food.png')} />
                    <View style={{ width: '100%', minHeight: 50, justifyContent: 'center' }}>
                        {
                            item.type == 'menu' ?
                            <StyledText 
                            numberOfLines={2} 
                            font_color={is_active  ? design.selected_section_heading_color : design.section_heading_color}
                            font_weight={is_active  ? design.selected_section_heading_font_weight : design.section_heading_font_weight}
                            font_style={is_active  ? design.selected_section_heading_font_style: design.section_heading_font_style}
                            text_transform={is_active  ? design.selected_section_heading_font_transform: design.section_heading_font_transform}
                            font_family={is_active  ? design.selected_section_heading_font_family:design.section_heading_font_family}
                            font_size={is_active  ? design.selected_section_heading_font_size:design.section_heading_font_size}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                            style={{
                                 textAlign:'center',
                                 marginHorizontal:10,
                                 }}>
                                {menu ? menu.menu_titles ? <IntlLabel data={menu.menu_titles}  />  : null : ''}
                                </StyledText>
                            :
                            <StyledText 
                            numberOfLines={2} 
                            font_color={is_active  ? design.selected_section_heading_color : design.section_heading_color}
                            font_weight={is_active  ? design.selected_section_heading_font_weight : design.section_heading_font_weight}
                            font_style={is_active  ? design.selected_section_heading_font_style: design.section_heading_font_style}
                            text_transform={is_active  ? design.selected_section_heading_font_transform: design.section_heading_font_transform}
                            font_family={is_active  ? design.selected_section_heading_font_family:design.section_heading_font_family}
                            font_size={is_active  ? design.selected_section_heading_font_size:design.section_heading_font_size}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                            style={{
                                 textAlign:'center',
                                 marginHorizontal:10,
                                 }}>
                                {item.item_names ? <IntlLabel data={item.item_names}  />  : null}
                                </StyledText>
                        }
                    </View>
                </TouchableOpacity>
            )
        } else {
            return (
                <TouchableOpacity onPress={this.props.onPressItem} >
                    <View style={{
                        minWidth: 95,
                        // padding: 8,
                        // borderRadius: 6,
                        height: 25,
                        // backgroundColor: !is_active ? "transparent" : design.top_buttons_background_color,
                        backgroundColor:  "transparent",
                        borderColor: is_active ? this.props.selected_header_color : "transparent",
                        // border: "1px solid",
                        marginHorizontal: 5,
                        marginVertical: 10,
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottomWidth: 2,
                        paddingBottom:10

                    }} >
                        {
                            item.type == 'menu' ?
                                <StyledText 
                                font_color={is_active  ? design.selected_section_heading_color : design.section_heading_color}
                            font_weight={is_active  ? design.selected_section_heading_font_weight : design.section_heading_font_weight}
                            font_style={is_active  ? design.selected_section_heading_font_style: design.section_heading_font_style}
                            text_transform={is_active  ? design.selected_section_heading_font_transform: design.section_heading_font_transform}
                            font_family={is_active  ? design.selected_section_heading_font_family:design.section_heading_font_family}
                            font_size={is_active  ? design.selected_section_heading_font_size:design.section_heading_font_size}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}


                                style={{
                                      textAlign:'center',
                                      marginHorizontal:10,
                                       }} >
                                    {menu ? menu.menu_titles ? <IntlLabel data={menu.menu_titles} /> : null : ''}
                                    </StyledText>
                                :
                                <StyledText 
                                font_color={is_active  ? design.selected_section_heading_color : design.section_heading_color}
                                font_weight={is_active  ? design.selected_section_heading_font_weight : design.section_heading_font_weight}
                                font_style={is_active  ? design.selected_section_heading_font_style: design.section_heading_font_style}
                                text_transform={is_active  ? design.selected_section_heading_font_transform: design.section_heading_font_transform}
                                font_family={is_active  ? design.selected_section_heading_font_family:design.section_heading_font_family}
                                font_size={is_active  ? design.selected_section_heading_font_size:design.section_heading_font_size}
                                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
    
                                style={{
                             
                                      textAlign:'center',
                                      marginHorizontal:10,
                                      
                                      }} >
                                    {item.item_names ? <IntlLabel data={item.item_names} /> : null}
                                    </StyledText>
                        }
                    </View>

                </TouchableOpacity>
            )
        }

    }

}
