'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var UsAppToPersonUsecaseList;
var UsAppToPersonUsecasePage;
var UsAppToPersonUsecaseInstance;

/* jshint ignore:start */
/**
 * Initialize the UsAppToPersonUsecaseList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseList
 *
 * @param {Twilio.Messaging.V1} version - Version of the resource
 * @param {string} messagingServiceSid -
 *          The unique string that identifies the resource
 */
/* jshint ignore:end */
UsAppToPersonUsecaseList = function UsAppToPersonUsecaseList(version,
    messagingServiceSid) {
  /* jshint ignore:start */
  /**
   * @function usAppToPersonUsecases
   * @memberof Twilio.Messaging.V1.ServiceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseContext}
   */
  /* jshint ignore:end */
  function UsAppToPersonUsecaseListInstance(sid) {
    return UsAppToPersonUsecaseListInstance.get(sid);
  }

  UsAppToPersonUsecaseListInstance._version = version;
  // Path Solution
  UsAppToPersonUsecaseListInstance._solution = {messagingServiceSid: messagingServiceSid};
  UsAppToPersonUsecaseListInstance._uri = `/Services/${messagingServiceSid}/Compliance/Usa2p/Usecases`;
  /* jshint ignore:start */
  /**
   * fetch a UsAppToPersonUsecaseInstance
   *
   * @function fetch
   * @memberof Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.brandRegistrationSid] - A2P Brand Registration SID
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed UsAppToPersonUsecaseInstance
   */
  /* jshint ignore:end */
  UsAppToPersonUsecaseListInstance.fetch = function fetch(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({'BrandRegistrationSid': _.get(opts, 'brandRegistrationSid')});

    var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new UsAppToPersonUsecaseInstance(
        this._version,
        payload,
        this._solution.messagingServiceSid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  UsAppToPersonUsecaseListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  UsAppToPersonUsecaseListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return UsAppToPersonUsecaseListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the UsAppToPersonUsecasePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecasePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {UsAppToPersonUsecaseSolution} solution - Path solution
 *
 * @returns UsAppToPersonUsecasePage
 */
/* jshint ignore:end */
UsAppToPersonUsecasePage = function UsAppToPersonUsecasePage(version, response,
    solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(UsAppToPersonUsecasePage.prototype, Page.prototype);
UsAppToPersonUsecasePage.prototype.constructor = UsAppToPersonUsecasePage;

/* jshint ignore:start */
/**
 * Build an instance of UsAppToPersonUsecaseInstance
 *
 * @function getInstance
 * @memberof Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecasePage#
 *
 * @param {UsAppToPersonUsecasePayload} payload - Payload response from the API
 *
 * @returns UsAppToPersonUsecaseInstance
 */
/* jshint ignore:end */
UsAppToPersonUsecasePage.prototype.getInstance = function getInstance(payload) {
  return new UsAppToPersonUsecaseInstance(this._version, payload, this._solution.messagingServiceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecasePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
UsAppToPersonUsecasePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UsAppToPersonUsecasePage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the UsAppToPersonUsecaseContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseInstance
 *
 * @property {object} usAppToPersonUsecases - Human readable A2P Use Case details
 *
 * @param {V1} version - Version of the resource
 * @param {UsAppToPersonUsecasePayload} payload - The instance payload
 * @param {sid} messagingServiceSid -
 *          The unique string that identifies the resource
 */
/* jshint ignore:end */
UsAppToPersonUsecaseInstance = function UsAppToPersonUsecaseInstance(version,
    payload, messagingServiceSid) {
  this._version = version;

  // Marshaled Properties
  this.usAppToPersonUsecases = payload.us_app_to_person_usecases; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {messagingServiceSid: messagingServiceSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.ServiceContext.UsAppToPersonUsecaseInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
UsAppToPersonUsecaseInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UsAppToPersonUsecaseInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  UsAppToPersonUsecaseList: UsAppToPersonUsecaseList,
  UsAppToPersonUsecasePage: UsAppToPersonUsecasePage,
  UsAppToPersonUsecaseInstance: UsAppToPersonUsecaseInstance
};
