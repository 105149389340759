'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var TollfreeVerificationList;
var TollfreeVerificationPage;
var TollfreeVerificationInstance;
var TollfreeVerificationContext;

/* jshint ignore:start */
/**
 * Initialize the TollfreeVerificationList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.TollfreeVerificationList
 *
 * @param {Twilio.Messaging.V1} version - Version of the resource
 */
/* jshint ignore:end */
TollfreeVerificationList = function TollfreeVerificationList(version) {
  /* jshint ignore:start */
  /**
   * @function tollfreeVerifications
   * @memberof Twilio.Messaging.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Messaging.V1.TollfreeVerificationContext}
   */
  /* jshint ignore:end */
  function TollfreeVerificationListInstance(sid) {
    return TollfreeVerificationListInstance.get(sid);
  }

  TollfreeVerificationListInstance._version = version;
  // Path Solution
  TollfreeVerificationListInstance._solution = {};
  TollfreeVerificationListInstance._uri = `/Tollfree/Verifications`;
  /* jshint ignore:start */
  /**
   * Streams TollfreeVerificationInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.tollfreePhoneNumberSid] -
   *          The SID of the Phone Number associated with the Tollfree Verification
   * @param {tollfree_verification.status} [opts.status] -
   *          The compliance status of the Tollfree Verification record.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists TollfreeVerificationInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.tollfreePhoneNumberSid] -
   *          The SID of the Phone Number associated with the Tollfree Verification
   * @param {tollfree_verification.status} [opts.status] -
   *          The compliance status of the Tollfree Verification record.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of TollfreeVerificationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.tollfreePhoneNumberSid] -
   *          The SID of the Phone Number associated with the Tollfree Verification
   * @param {tollfree_verification.status} [opts.status] -
   *          The compliance status of the Tollfree Verification record.
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'TollfreePhoneNumberSid': _.get(opts, 'tollfreePhoneNumberSid'),
      'Status': _.get(opts, 'status'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TollfreeVerificationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of TollfreeVerificationInstance records from the
   * API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.getPage = function getPage(targetUrl, callback)
                                                               {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new TollfreeVerificationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a TollfreeVerificationInstance
   *
   * @function create
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.businessName -
   *          The name of the business or organization using the Tollfree number
   * @param {string} opts.businessWebsite -
   *          The website of the business or organization using the Tollfree number
   * @param {string} opts.notificationEmail -
   *          The email address to receive the notification about the verification result.
   * @param {string|list} opts.useCaseCategories -
   *          The category of the use case for the Tollfree Number. List as many are applicable.
   * @param {string} opts.useCaseSummary -
   *          Further explaination on how messaging is used by the business or organization
   * @param {string} opts.productionMessageSample -
   *          An example of message content, i.e. a sample message
   * @param {string|list} opts.optInImageUrls -
   *          Link to an image that shows the opt-in workflow. Multiple images allowed and must be a publicly hosted URL
   * @param {tollfree_verification.opt_in_type} opts.optInType -
   *          Describe how a user opts-in to text messages
   * @param {string} opts.messageVolume -
   *          Estimate monthly volume of messages from the Tollfree Number
   * @param {string} opts.tollfreePhoneNumberSid -
   *          The SID of the Phone Number associated with the Tollfree Verification
   * @param {string} [opts.customerProfileSid] - Customer's Profile Bundle BundleSid
   * @param {string} [opts.businessStreetAddress] -
   *          The address of the business or organization using the Tollfree number
   * @param {string} [opts.businessStreetAddress2] -
   *          The address of the business or organization using the Tollfree number
   * @param {string} [opts.businessCity] -
   *          The city of the business or organization using the Tollfree number
   * @param {string} [opts.businessStateProvinceRegion] -
   *          The state/province/region of the business or organization using the Tollfree number
   * @param {string} [opts.businessPostalCode] -
   *          The postal code of the business or organization using the Tollfree number
   * @param {string} [opts.businessCountry] -
   *          The country of the business or organization using the Tollfree number
   * @param {string} [opts.additionalInformation] -
   *          Additional information to be provided for verification
   * @param {string} [opts.businessContactFirstName] -
   *          The first name of the contact for the business or organization using the Tollfree number
   * @param {string} [opts.businessContactLastName] -
   *          The last name of the contact for the business or organization using the Tollfree number
   * @param {string} [opts.businessContactEmail] -
   *          The email address of the contact for the business or organization using the Tollfree number
   * @param {string} [opts.businessContactPhone] -
   *          The phone number of the contact for the business or organization using the Tollfree number
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TollfreeVerificationInstance
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['businessName'])) {
      throw new Error('Required parameter "opts[\'businessName\']" missing.');
    }
    if (_.isUndefined(opts['businessWebsite'])) {
      throw new Error('Required parameter "opts[\'businessWebsite\']" missing.');
    }
    if (_.isUndefined(opts['notificationEmail'])) {
      throw new Error('Required parameter "opts[\'notificationEmail\']" missing.');
    }
    if (_.isUndefined(opts['useCaseCategories'])) {
      throw new Error('Required parameter "opts[\'useCaseCategories\']" missing.');
    }
    if (_.isUndefined(opts['useCaseSummary'])) {
      throw new Error('Required parameter "opts[\'useCaseSummary\']" missing.');
    }
    if (_.isUndefined(opts['productionMessageSample'])) {
      throw new Error('Required parameter "opts[\'productionMessageSample\']" missing.');
    }
    if (_.isUndefined(opts['optInImageUrls'])) {
      throw new Error('Required parameter "opts[\'optInImageUrls\']" missing.');
    }
    if (_.isUndefined(opts['optInType'])) {
      throw new Error('Required parameter "opts[\'optInType\']" missing.');
    }
    if (_.isUndefined(opts['messageVolume'])) {
      throw new Error('Required parameter "opts[\'messageVolume\']" missing.');
    }
    if (_.isUndefined(opts['tollfreePhoneNumberSid'])) {
      throw new Error('Required parameter "opts[\'tollfreePhoneNumberSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'BusinessName': _.get(opts, 'businessName'),
      'BusinessWebsite': _.get(opts, 'businessWebsite'),
      'NotificationEmail': _.get(opts, 'notificationEmail'),
      'UseCaseCategories': serialize.map(_.get(opts, 'useCaseCategories'), function(e) { return e; }),
      'UseCaseSummary': _.get(opts, 'useCaseSummary'),
      'ProductionMessageSample': _.get(opts, 'productionMessageSample'),
      'OptInImageUrls': serialize.map(_.get(opts, 'optInImageUrls'), function(e) { return e; }),
      'OptInType': _.get(opts, 'optInType'),
      'MessageVolume': _.get(opts, 'messageVolume'),
      'TollfreePhoneNumberSid': _.get(opts, 'tollfreePhoneNumberSid'),
      'CustomerProfileSid': _.get(opts, 'customerProfileSid'),
      'BusinessStreetAddress': _.get(opts, 'businessStreetAddress'),
      'BusinessStreetAddress2': _.get(opts, 'businessStreetAddress2'),
      'BusinessCity': _.get(opts, 'businessCity'),
      'BusinessStateProvinceRegion': _.get(opts, 'businessStateProvinceRegion'),
      'BusinessPostalCode': _.get(opts, 'businessPostalCode'),
      'BusinessCountry': _.get(opts, 'businessCountry'),
      'AdditionalInformation': _.get(opts, 'additionalInformation'),
      'BusinessContactFirstName': _.get(opts, 'businessContactFirstName'),
      'BusinessContactLastName': _.get(opts, 'businessContactLastName'),
      'BusinessContactEmail': _.get(opts, 'businessContactEmail'),
      'BusinessContactPhone': _.get(opts, 'businessContactPhone')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TollfreeVerificationInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a tollfree_verification
   *
   * @function get
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @param {string} sid - Tollfree Verification Sid
   *
   * @returns {Twilio.Messaging.V1.TollfreeVerificationContext}
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.get = function get(sid) {
    return new TollfreeVerificationContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Messaging.V1.TollfreeVerificationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TollfreeVerificationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TollfreeVerificationListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return TollfreeVerificationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TollfreeVerificationPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.TollfreeVerificationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TollfreeVerificationSolution} solution - Path solution
 *
 * @returns TollfreeVerificationPage
 */
/* jshint ignore:end */
TollfreeVerificationPage = function TollfreeVerificationPage(version, response,
    solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TollfreeVerificationPage.prototype, Page.prototype);
TollfreeVerificationPage.prototype.constructor = TollfreeVerificationPage;

/* jshint ignore:start */
/**
 * Build an instance of TollfreeVerificationInstance
 *
 * @function getInstance
 * @memberof Twilio.Messaging.V1.TollfreeVerificationPage#
 *
 * @param {TollfreeVerificationPayload} payload - Payload response from the API
 *
 * @returns TollfreeVerificationInstance
 */
/* jshint ignore:end */
TollfreeVerificationPage.prototype.getInstance = function getInstance(payload) {
  return new TollfreeVerificationInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.TollfreeVerificationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TollfreeVerificationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TollfreeVerificationPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TollfreeVerificationContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.TollfreeVerificationInstance
 *
 * @property {string} sid - Tollfree Verification Sid
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} customerProfileSid - Customer's Profile Bundle BundleSid
 * @property {string} trustProductSid - Tollfree TrustProduct Bundle BundleSid
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} regulatedItemSid - The SID of the Regulated Item
 * @property {string} businessName -
 *          The name of the business or organization using the Tollfree number
 * @property {string} businessStreetAddress -
 *          The address of the business or organization using the Tollfree number
 * @property {string} businessStreetAddress2 -
 *          The address of the business or organization using the Tollfree number
 * @property {string} businessCity -
 *          The city of the business or organization using the Tollfree number
 * @property {string} businessStateProvinceRegion -
 *          The state/province/region of the business or organization using the Tollfree number
 * @property {string} businessPostalCode -
 *          The postal code of the business or organization using the Tollfree number
 * @property {string} businessCountry -
 *          The country of the business or organization using the Tollfree number
 * @property {string} businessWebsite -
 *          The website of the business or organization using the Tollfree number
 * @property {string} businessContactFirstName -
 *          The first name of the contact for the business or organization using the Tollfree number
 * @property {string} businessContactLastName -
 *          The last name of the contact for the business or organization using the Tollfree number
 * @property {string} businessContactEmail -
 *          The email address of the contact for the business or organization using the Tollfree number
 * @property {string} businessContactPhone -
 *          The phone number of the contact for the business or organization using the Tollfree number
 * @property {string} notificationEmail -
 *          The email address to receive the notification about the verification result.
 * @property {string} useCaseCategories -
 *          The category of the use case for the Tollfree Number. List as many are applicable.
 * @property {string} useCaseSummary -
 *          Further explaination on how messaging is used by the business or organization
 * @property {string} productionMessageSample -
 *          An example of message content, i.e. a sample message
 * @property {string} optInImageUrls -
 *          Link to an image that shows the opt-in workflow. Multiple images allowed and must be a publicly hosted URL
 * @property {tollfree_verification.opt_in_type} optInType -
 *          Describe how a user opts-in to text messages
 * @property {string} messageVolume -
 *          Estimate monthly volume of messages from the Tollfree Number
 * @property {string} additionalInformation -
 *          Additional information to be provided for verification
 * @property {string} tollfreePhoneNumberSid -
 *          The SID of the Phone Number associated with the Tollfree Verification
 * @property {tollfree_verification.status} status -
 *          The compliance status of the Tollfree Verification record.
 * @property {string} url - The absolute URL of the Tollfree Verification
 * @property {object} resourceLinks -
 *          The URLs of the documents associated with the Tollfree Verification resource
 *
 * @param {V1} version - Version of the resource
 * @param {TollfreeVerificationPayload} payload - The instance payload
 * @param {sid} sid - Tollfree Verification Sid
 */
/* jshint ignore:end */
TollfreeVerificationInstance = function TollfreeVerificationInstance(version,
    payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.customerProfileSid = payload.customer_profile_sid; // jshint ignore:line
  this.trustProductSid = payload.trust_product_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.regulatedItemSid = payload.regulated_item_sid; // jshint ignore:line
  this.businessName = payload.business_name; // jshint ignore:line
  this.businessStreetAddress = payload.business_street_address; // jshint ignore:line
  this.businessStreetAddress2 = payload.business_street_address2; // jshint ignore:line
  this.businessCity = payload.business_city; // jshint ignore:line
  this.businessStateProvinceRegion = payload.business_state_province_region; // jshint ignore:line
  this.businessPostalCode = payload.business_postal_code; // jshint ignore:line
  this.businessCountry = payload.business_country; // jshint ignore:line
  this.businessWebsite = payload.business_website; // jshint ignore:line
  this.businessContactFirstName = payload.business_contact_first_name; // jshint ignore:line
  this.businessContactLastName = payload.business_contact_last_name; // jshint ignore:line
  this.businessContactEmail = payload.business_contact_email; // jshint ignore:line
  this.businessContactPhone = payload.business_contact_phone; // jshint ignore:line
  this.notificationEmail = payload.notification_email; // jshint ignore:line
  this.useCaseCategories = payload.use_case_categories; // jshint ignore:line
  this.useCaseSummary = payload.use_case_summary; // jshint ignore:line
  this.productionMessageSample = payload.production_message_sample; // jshint ignore:line
  this.optInImageUrls = payload.opt_in_image_urls; // jshint ignore:line
  this.optInType = payload.opt_in_type; // jshint ignore:line
  this.messageVolume = payload.message_volume; // jshint ignore:line
  this.additionalInformation = payload.additional_information; // jshint ignore:line
  this.tollfreePhoneNumberSid = payload.tollfree_phone_number_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.resourceLinks = payload.resource_links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(TollfreeVerificationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TollfreeVerificationContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TollfreeVerificationInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.TollfreeVerificationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TollfreeVerificationInstance
 */
/* jshint ignore:end */
TollfreeVerificationInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.TollfreeVerificationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TollfreeVerificationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TollfreeVerificationInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TollfreeVerificationContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.TollfreeVerificationContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - Tollfree Verification Sid
 */
/* jshint ignore:end */
TollfreeVerificationContext = function TollfreeVerificationContext(version, sid)
    {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Tollfree/Verifications/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a TollfreeVerificationInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.TollfreeVerificationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TollfreeVerificationInstance
 */
/* jshint ignore:end */
TollfreeVerificationContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TollfreeVerificationInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.TollfreeVerificationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TollfreeVerificationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TollfreeVerificationContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TollfreeVerificationList: TollfreeVerificationList,
  TollfreeVerificationPage: TollfreeVerificationPage,
  TollfreeVerificationInstance: TollfreeVerificationInstance,
  TollfreeVerificationContext: TollfreeVerificationContext
};
