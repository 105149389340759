'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var TwiML = require('./TwiML');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * <Response> TwiML for Faxes
 *
 * @constructor FaxResponse
 */
/* jshint ignore:end */
function FaxResponse() {
  TwiML.call(this);
  this._propertyName = 'response';
}

FaxResponse.prototype = Object.create(TwiML.prototype);
FaxResponse.prototype.constructor = FaxResponse;

/* jshint ignore:start */
/**
 * <Receive> TwiML Verb
 *
 * @function receive
 * @memberof FaxResponse#
 *
 * @param {object} [attributes] - TwiML attributes
 * @param {string} [attributes.action] - Receive action URL
 * @param {string} [attributes.method] - Receive action URL method
 * @param {receive.media_type} [attributes.mediaType] -
 *          The media type used to store media in the fax media store
 * @param {receive.page_size} [attributes.pageSize] -
 *          What size to interpret received pages as
 * @param {boolean} [attributes.storeMedia] -
 *          Whether or not to store received media in the fax media store
 *
 * @returns Receive
 */
/* jshint ignore:end */
FaxResponse.prototype.receive = function receive(attributes) {
  return new Receive(this.response.ele('Receive', attributes));
};


/* jshint ignore:start */
/**
 * <Receive> TwiML Verb
 *
 * @constructor Receive
 *
 * @param {object} receive - <Receive> TwiML Verb
 */
/* jshint ignore:end */
function Receive(receive) {
  this.receive = receive;
  this._propertyName = 'receive';
}

Receive.prototype = Object.create(TwiML.prototype);
Receive.prototype.constructor = Receive;

module.exports = FaxResponse;
