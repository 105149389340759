'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var TaskStatisticsList;
var TaskStatisticsPage;
var TaskStatisticsInstance;
var TaskStatisticsContext;

/* jshint ignore:start */
/**
 * Initialize the TaskStatisticsList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsList
 *
 * @param {Twilio.Preview.Understand} version - Version of the resource
 * @param {string} assistantSid - The unique ID of the parent Assistant.
 * @param {string} taskSid - The unique ID of the Task associated with this Field.
 */
/* jshint ignore:end */
TaskStatisticsList = function TaskStatisticsList(version, assistantSid, taskSid)
                                                  {
  /* jshint ignore:start */
  /**
   * @function statistics
   * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsContext}
   */
  /* jshint ignore:end */
  function TaskStatisticsListInstance(sid) {
    return TaskStatisticsListInstance.get(sid);
  }

  TaskStatisticsListInstance._version = version;
  // Path Solution
  TaskStatisticsListInstance._solution = {assistantSid: assistantSid, taskSid: taskSid};
  /* jshint ignore:start */
  /**
   * Constructs a task_statistics
   *
   * @function get
   * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsList#
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsContext}
   */
  /* jshint ignore:end */
  TaskStatisticsListInstance.get = function get() {
    return new TaskStatisticsContext(
      this._version,
      this._solution.assistantSid,
      this._solution.taskSid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TaskStatisticsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TaskStatisticsListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return TaskStatisticsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TaskStatisticsPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsPage
 *
 * @param {Understand} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TaskStatisticsSolution} solution - Path solution
 *
 * @returns TaskStatisticsPage
 */
/* jshint ignore:end */
TaskStatisticsPage = function TaskStatisticsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TaskStatisticsPage.prototype, Page.prototype);
TaskStatisticsPage.prototype.constructor = TaskStatisticsPage;

/* jshint ignore:start */
/**
 * Build an instance of TaskStatisticsInstance
 *
 * @function getInstance
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsPage#
 *
 * @param {TaskStatisticsPayload} payload - Payload response from the API
 *
 * @returns TaskStatisticsInstance
 */
/* jshint ignore:end */
TaskStatisticsPage.prototype.getInstance = function getInstance(payload) {
  return new TaskStatisticsInstance(
    this._version,
    payload,
    this._solution.assistantSid,
    this._solution.taskSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskStatisticsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskStatisticsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskStatisticsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account that created this Field.
 * @property {string} assistantSid - The unique ID of the parent Assistant.
 * @property {string} taskSid -
 *          The unique ID of the Task associated with this Field.
 * @property {number} samplesCount -
 *          The total number of Samples associated with this Task.
 * @property {number} fieldsCount -
 *          The total number of Fields associated with this Task.
 * @property {string} url - The url
 *
 * @param {Understand} version - Version of the resource
 * @param {TaskStatisticsPayload} payload - The instance payload
 * @param {sid} assistantSid - The unique ID of the parent Assistant.
 * @param {sid} taskSid - The unique ID of the Task associated with this Field.
 */
/* jshint ignore:end */
TaskStatisticsInstance = function TaskStatisticsInstance(version, payload,
                                                          assistantSid, taskSid)
                                                          {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assistantSid = payload.assistant_sid; // jshint ignore:line
  this.taskSid = payload.task_sid; // jshint ignore:line
  this.samplesCount = deserialize.integer(payload.samples_count); // jshint ignore:line
  this.fieldsCount = deserialize.integer(payload.fields_count); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {assistantSid: assistantSid, taskSid: taskSid, };
};

Object.defineProperty(TaskStatisticsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TaskStatisticsContext(
          this._version,
          this._solution.assistantSid,
          this._solution.taskSid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TaskStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskStatisticsInstance
 */
/* jshint ignore:end */
TaskStatisticsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskStatisticsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskStatisticsInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskStatisticsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsContext
 *
 * @param {Understand} version - Version of the resource
 * @param {sid_like} assistantSid - The unique ID of the parent Assistant.
 * @param {sid_like} taskSid -
 *          The unique ID of the Task associated with this Field.
 */
/* jshint ignore:end */
TaskStatisticsContext = function TaskStatisticsContext(version, assistantSid,
                                                        taskSid) {
  this._version = version;

  // Path Solution
  this._solution = {assistantSid: assistantSid, taskSid: taskSid, };
  this._uri = `/Assistants/${assistantSid}/Tasks/${taskSid}/Statistics`;
};

/* jshint ignore:start */
/**
 * fetch a TaskStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskStatisticsInstance
 */
/* jshint ignore:end */
TaskStatisticsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskStatisticsInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.taskSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.TaskContext.TaskStatisticsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskStatisticsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TaskStatisticsContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TaskStatisticsList: TaskStatisticsList,
  TaskStatisticsPage: TaskStatisticsPage,
  TaskStatisticsInstance: TaskStatisticsInstance,
  TaskStatisticsContext: TaskStatisticsContext
};
