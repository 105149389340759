import React from "react"
import { View, Text, TouchableOpacity, Dimensions, CheckBox, TextInput } from "react-native";
import i18n from "../i18n";
import IntlLabel from "./../handlers/languageHandler";
import StarRatings from "react-star-ratings";
import Icon from "./../components/Warning";

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import { FontLetterSpacing } from "../handlers/utils";
import {useSelector} from "react-redux"
import StyledText from "../components/styledText"

const FeedbackForm = (props) => {
    return (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)"  }}>
            <View
                style={{
                    width: Dimensions.get("window").width * 0.8,
                    backgroundColor: props.design.popup_background_color,
                    borderWidth: 1,
                    padding: 22,
                    justifyContent: "center",
                    // alignItems: 'center',
                    borderRadius: 4,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                    height: "95%",
                }}
            >
                <View style={{ flexDirection: "row" }}>

                    <View
                        style={{
                            width: "70%",
                            alignItems: "center",
                            marginBottom: 60,
                            borderRadius: 10,
                            flex: 1,
                            marginTop: 20,
                        }}
                    >
                        <View style={{ alignItems: "center", marginBottom: 22 }}>
                        <StyledText
                            font_color={props.design.popup_heading_text_color}
                            font_weight={props.design.popup_heading_font_weight}
                            font_style={props.design.popup_heading_font_style}
                            text_transform={props.design.popup_heading_font_transform}
                            font_family={props.design.popup_heading_font_family}
                            font_size={props.design.popup_heading_font_size}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                            style={{}}
                        >
                            {props.config.survey.header}
                            </StyledText>
                           
                        </View>
                    </View>
                </View>
                <KeyboardAwareScrollView>
                    {props.config && props.config.survey.question_survey
                        ? props.config.survey.question_survey.length > 0
                            ? props.config.survey.question_survey.map((item, index) => {
                                return (
                                    <View key={index} style={{ padding: 10 }}>
                                        <View style={{ flexDirection: "row" }}>
                                        <StyledText
                                        font_color={props.design.popup_text_color}
                                        font_weight={props.design.popup_text_font_weight}
                                        font_style={props.design.popup_text_font_style}
                                        text_transform={props.design.popup_text_font_transform}
                                        font_family={props.design.popup_text_font_family}
                                        font_size={props.design.popup_text_font_size}
                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                        style={{}}
                                    >
                                        <IntlLabel data={item.questions} /> {" "}
                                        </StyledText>
                                            
                                            {item.mandatory_answer ? (
                                                <StyledText
                                                font_color={props.design.popup_text_color}
                                                font_weight={props.design.popup_text_font_weight}
                                                font_style={props.design.popup_text_font_style}
                                                text_transform={props.design.popup_text_font_transform}
                                                font_family={props.design.popup_text_font_family}
                                                font_size={props.design.popup_text_font_size}
                                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                                style={{}}
                                                >
                                                    *
                                                </StyledText>
                                            ) : null}
                                        </View>
                                        {item.mandatory_answer ? (
                                            <StyledText
                                                font_color={"red"}
                                                font_weight={props.design.popup_text_font_weight}
                                                font_style={props.design.popup_text_font_style}
                                                text_transform={props.design.popup_text_font_transform}
                                                font_family={props.design.popup_text_font_family}
                                                font_size={props.design.popup_text_font_size}
                                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                                style={{display: item.display_warning,}}
                                                
                                            >
                                                {i18n.t("labels.answer_required")}
                                            </StyledText>
                                        ) : null}
                                        {item.type == 1 ? (
                                            <View style={{ marginBottom: 15 }}>
                                                <TextInput
                                                    style={{
                                                        letterSpacing: FontLetterSpacing(props.design.custom_font.secondary_letter_space),
                                                        fontSize: props.design.custom_font.sub_heading_size,
                                                        fontWeight: props.design.popup_text_font_weight,
                                                        borderBottomWidth: 1,
                                                        borderColor: props.design.popup_text_color,
                                                        color: props.design.popup_text_color,
                                                        // fontFamily:props.design.popup_text_font_family,
                                                        textTransform:props.design.popup_text_font_transform,
                                                        fontStyle: props.design.popup_text_font_style

                                                    }}
                                                    onChangeText={(text) =>
                                                        props.onChangeFeedbackText(text, index)
                                                    }
                                                />
                                            </View>
                                        ) : null}
                                        {item.type == 2 ? (
                                            <View
                                                style={{
                                                    marginBottom: 15,
                                                    marginTop: 8,
                                                    alignItems: "flex-start",
                                                }}
                                            >

                                                <StarRatings
                                                    rating={
                                                        props.feedback_q_a.length &&
                                                        props.feedback_q_a[index].answer
                                                    }
                                                    starRatedColor={"#FFD700"}
                                                    starHoverColor={"#FFD700"}
                                                    starEmptyColor={"lightgrey"}
                                                    changeRating={(e) => props.ratingCompleted(e, index)}
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="25px"
                                                />
                                            </View>
                                        ) : null}
                                        {item.type == 3 ? (
                                            <View>
                                                <View
                                                    style={{
                                                        marginBottom: 15,
                                                        marginTop: 15,
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <StyledText
                                                        font_color={props.design.popup_text_color}
                                                        font_weight={props.design.popup_text_font_weight}
                                                        font_style={props.design.popup_text_font_style}
                                                        text_transform={props.design.popup_text_font_transform}
                                                        font_family={props.design.popup_text_font_family}
                                                        font_size={props.design.popup_text_font_size}
                                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                                    >
                                                        {i18n.t("button_text.yes")}{" "}
                                                    </StyledText>
                                                    <TouchableOpacity
                                                        onPress={() => props.onPressYesBox(index)}
                                                    >
                                                        <CheckBox
                                                            value={item.answer === true ? true : false}
                                                            color={props.design.popup_text_color}
                                                        />
                                                    </TouchableOpacity>

                                                    <StyledText
                                                        font_color={props.design.popup_text_color}
                                                        font_weight={props.design.popup_text_font_weight}
                                                        font_style={props.design.popup_text_font_style}
                                                        text_transform={props.design.popup_text_font_transform}
                                                        font_family={props.design.popup_text_font_family}
                                                        font_size={props.design.popup_text_font_size}
                                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                                        style={{marginLeft: 20,}}
                                                    >
                                                        {" "}
                                                        {i18n.t("button_text.no")}{" "}
                                                    </StyledText>
                                                    <TouchableOpacity
                                                        onPress={() => props.onPressNoBox(index)}
                                                    >
                                                        <CheckBox
                                                            value={item.answer === false ? true : false}
                                                            color={props.design.popup_text_color}
                                                        />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        ) : null}
                                    </View>
                                );
                            })
                            : null
                        : null}
                </KeyboardAwareScrollView>
                <View
                    style={{ borderRadius: 10, position: "absolute", right: 5, top: 5 }}
                >
                    <TouchableOpacity
                        onPress={() => props.toggleFeedbackModal()}
                    >
                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: props.design.popup_button_color,
                                width: 50,
                                height: 50,
                                borderRadius: 7,
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: props.design.popup_button_color,
                            }}
                        >
                            <Icon
                                style={{ color: props.design.popup_button_text_color }}
                                size={30}
                                name={"close"}
                            />
                        </View>
                    </TouchableOpacity>
                </View>

                {props.config && props.config.survey.question_survey ? (
                    props.config.survey.question_survey.length > 0 ? (
                        <TouchableOpacity onPress={() => props.onSubmitFeedBack()}>
                            <View
                                style={{
                                    backgroundColor: props.design.popup_button_color,
                                    width: "100%",
                                    marginHorizontal: 3,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: 35,
                                    borderRadius: 7,
                                }}
                            >
                                <StyledText

                                 font_color={props.design.popup_button_text_color}
                                 font_weight={props.design.popup_button_font_weight}
                                 font_style={props.design.popup_button_font_style}
                                 text_transform={props.design.popup_button_font_transform}
                                 font_family={props.design.popup_button_font_family}
                                 font_size={props.design.popup_button_font_size}
                                 letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                 style={{}}
                                >
                                    {i18n.t("labels.submit")}
                                </StyledText>
                            </View>
                        </TouchableOpacity>
                    ) : null
                ) : null}
            </View>
        </View>
    )
}

export default FeedbackForm;