'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var WorkerChannelList;
var WorkerChannelPage;
var WorkerChannelInstance;
var WorkerChannelContext;

/* jshint ignore:start */
/**
 * Initialize the WorkerChannelList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @param {string} workerSid -
 *          The SID of the Worker that contains the WorkerChannel
 */
/* jshint ignore:end */
WorkerChannelList = function WorkerChannelList(version, workspaceSid, workerSid)
                                                {
  /* jshint ignore:start */
  /**
   * @function workerChannels
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext}
   */
  /* jshint ignore:end */
  function WorkerChannelListInstance(sid) {
    return WorkerChannelListInstance.get(sid);
  }

  WorkerChannelListInstance._version = version;
  // Path Solution
  WorkerChannelListInstance._solution = {workspaceSid: workspaceSid, workerSid: workerSid};
  WorkerChannelListInstance._uri = `/Workspaces/${workspaceSid}/Workers/${workerSid}/Channels`;
  /* jshint ignore:start */
  /**
   * Streams WorkerChannelInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists WorkerChannelInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of WorkerChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new WorkerChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of WorkerChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new WorkerChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a worker_channel
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @param {string} sid - The SID of the to fetch
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext}
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.get = function get(sid) {
    return new WorkerChannelContext(
      this._version,
      this._solution.workspaceSid,
      this._solution.workerSid,
      sid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WorkerChannelListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WorkerChannelListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return WorkerChannelListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WorkerChannelPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WorkerChannelSolution} solution - Path solution
 *
 * @returns WorkerChannelPage
 */
/* jshint ignore:end */
WorkerChannelPage = function WorkerChannelPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WorkerChannelPage.prototype, Page.prototype);
WorkerChannelPage.prototype.constructor = WorkerChannelPage;

/* jshint ignore:start */
/**
 * Build an instance of WorkerChannelInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelPage#
 *
 * @param {WorkerChannelPayload} payload - Payload response from the API
 *
 * @returns WorkerChannelInstance
 */
/* jshint ignore:end */
WorkerChannelPage.prototype.getInstance = function getInstance(payload) {
  return new WorkerChannelInstance(
    this._version,
    payload,
    this._solution.workspaceSid,
    this._solution.workerSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerChannelPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerChannelPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerChannelContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {number} assignedTasks -
 *          The total number of Tasks assigned to Worker for the TaskChannel type
 * @property {boolean} available -
 *          Whether the Worker should receive Tasks of the TaskChannel type
 * @property {number} availableCapacityPercentage -
 *          The current available capacity between 0 to 100 for the TaskChannel
 * @property {number} configuredCapacity -
 *          The current configured capacity for the WorkerChannel
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT when the resource was last updated
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} taskChannelSid - The SID of the TaskChannel
 * @property {string} taskChannelUniqueName -
 *          The unique name of the TaskChannel, such as 'voice' or 'sms'
 * @property {string} workerSid -
 *          The SID of the Worker that contains the WorkerChannel
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @property {string} url - The absolute URL of the WorkerChannel resource
 *
 * @param {V1} version - Version of the resource
 * @param {WorkerChannelPayload} payload - The instance payload
 * @param {sid} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @param {sid} workerSid - The SID of the Worker that contains the WorkerChannel
 * @param {sid_like} sid - The SID of the to fetch
 */
/* jshint ignore:end */
WorkerChannelInstance = function WorkerChannelInstance(version, payload,
                                                        workspaceSid, workerSid,
                                                        sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assignedTasks = deserialize.integer(payload.assigned_tasks); // jshint ignore:line
  this.available = payload.available; // jshint ignore:line
  this.availableCapacityPercentage = deserialize.integer(payload.available_capacity_percentage); // jshint ignore:line
  this.configuredCapacity = deserialize.integer(payload.configured_capacity); // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.taskChannelSid = payload.task_channel_sid; // jshint ignore:line
  this.taskChannelUniqueName = payload.task_channel_unique_name; // jshint ignore:line
  this.workerSid = payload.worker_sid; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, sid: sid || this.sid, };
};

Object.defineProperty(WorkerChannelInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new WorkerChannelContext(
          this._version,
          this._solution.workspaceSid,
          this._solution.workerSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a WorkerChannelInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerChannelInstance
 */
/* jshint ignore:end */
WorkerChannelInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a WorkerChannelInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.capacity] -
 *          The total number of Tasks that the Worker should handle for the TaskChannel type
 * @param {boolean} [opts.available] - Whether the WorkerChannel is available
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerChannelInstance
 */
/* jshint ignore:end */
WorkerChannelInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerChannelInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerChannelInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerChannelContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid -
 *          The SID of the Workspace with the WorkerChannel to fetch
 * @param {sid} workerSid - The SID of the Worker with the WorkerChannel to fetch
 * @param {sid_like} sid - The SID of the to fetch
 */
/* jshint ignore:end */
WorkerChannelContext = function WorkerChannelContext(version, workspaceSid,
                                                      workerSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, sid: sid, };
  this._uri = `/Workspaces/${workspaceSid}/Workers/${workerSid}/Channels/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a WorkerChannelInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerChannelInstance
 */
/* jshint ignore:end */
WorkerChannelContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkerChannelInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.workerSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a WorkerChannelInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.capacity] -
 *          The total number of Tasks that the Worker should handle for the TaskChannel type
 * @param {boolean} [opts.available] - Whether the WorkerChannel is available
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerChannelInstance
 */
/* jshint ignore:end */
WorkerChannelContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'Capacity': _.get(opts, 'capacity'),
    'Available': serialize.bool(_.get(opts, 'available'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkerChannelInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.workerSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerChannelContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

WorkerChannelContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WorkerChannelList: WorkerChannelList,
  WorkerChannelPage: WorkerChannelPage,
  WorkerChannelInstance: WorkerChannelInstance,
  WorkerChannelContext: WorkerChannelContext
};
