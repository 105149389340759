'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var RecordList = require('./usage/record').RecordList;
var TriggerList = require('./usage/trigger').TriggerList;

var UsageList;

/* jshint ignore:start */
/**
 * Initialize the UsageList
 *
 * @constructor Twilio.Api.V2010.AccountContext.UsageList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid -
 *          A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
UsageList = function UsageList(version, accountSid) {
  /* jshint ignore:start */
  /**
   * @function usage
   * @memberof Twilio.Api.V2010.AccountContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.UsageContext}
   */
  /* jshint ignore:end */
  function UsageListInstance(sid) {
    return UsageListInstance.get(sid);
  }

  UsageListInstance._version = version;
  // Path Solution
  UsageListInstance._solution = {accountSid: accountSid};

  // Components
  UsageListInstance._records = undefined;
  UsageListInstance._triggers = undefined;

  Object.defineProperty(UsageListInstance,
    'records', {
      get: function records() {
        if (!this._records) {
          this._records = new RecordList(this._version, this._solution.accountSid);
        }

        return this._records;
      }
  });

  Object.defineProperty(UsageListInstance,
    'triggers', {
      get: function triggers() {
        if (!this._triggers) {
          this._triggers = new TriggerList(this._version, this._solution.accountSid);
        }

        return this._triggers;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.UsageList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  UsageListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  UsageListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return UsageListInstance;
};

module.exports = {
  UsageList: UsageList
};
