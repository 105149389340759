'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var AppList = require('./v1/app').AppList;
var DeviceList = require('./v1/device').DeviceList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V1 version of Microvisor
 *
 * @constructor Twilio.Microvisor.V1
 *
 * @property {Twilio.Microvisor.V1.AppList} apps - apps resource
 * @property {Twilio.Microvisor.V1.DeviceList} devices - devices resource
 *
 * @param {Twilio.Microvisor} domain - The twilio domain
 */
/* jshint ignore:end */
function V1(domain) {
  Version.prototype.constructor.call(this, domain, 'v1');

  // Resources
  this._apps = undefined;
  this._devices = undefined;
}

_.extend(V1.prototype, Version.prototype);
V1.prototype.constructor = V1;

Object.defineProperty(V1.prototype,
  'apps', {
    get: function() {
      this._apps = this._apps || new AppList(this);
      return this._apps;
    }
});

Object.defineProperty(V1.prototype,
  'devices', {
    get: function() {
      this._devices = this._devices || new DeviceList(this);
      return this._devices;
    }
});

module.exports = V1;
