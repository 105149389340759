'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var TrustProductsEvaluationsList;
var TrustProductsEvaluationsPage;
var TrustProductsEvaluationsInstance;
var TrustProductsEvaluationsContext;

/* jshint ignore:start */
/**
 * Initialize the TrustProductsEvaluationsList
 *
 * @constructor Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList
 *
 * @param {Twilio.Trusthub.V1} version - Version of the resource
 * @param {string} trustProductSid - The unique string that identifies the resource
 */
/* jshint ignore:end */
TrustProductsEvaluationsList = function TrustProductsEvaluationsList(version,
    trustProductSid) {
  /* jshint ignore:start */
  /**
   * @function trustProductsEvaluations
   * @memberof Twilio.Trusthub.V1.TrustProductsContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsContext}
   */
  /* jshint ignore:end */
  function TrustProductsEvaluationsListInstance(sid) {
    return TrustProductsEvaluationsListInstance.get(sid);
  }

  TrustProductsEvaluationsListInstance._version = version;
  // Path Solution
  TrustProductsEvaluationsListInstance._solution = {trustProductSid: trustProductSid};
  TrustProductsEvaluationsListInstance._uri = `/TrustProducts/${trustProductSid}/Evaluations`;
  /* jshint ignore:start */
  /**
   * create a TrustProductsEvaluationsInstance
   *
   * @function create
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.policySid - The unique string of a policy
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TrustProductsEvaluationsInstance
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['policySid'])) {
      throw new Error('Required parameter "opts[\'policySid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'PolicySid': _.get(opts, 'policySid')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrustProductsEvaluationsInstance(
        this._version,
        payload,
        this._solution.trustProductSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams TrustProductsEvaluationsInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists TrustProductsEvaluationsInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of TrustProductsEvaluationsInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrustProductsEvaluationsPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of TrustProductsEvaluationsInstance records from
   * the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.getPage = function getPage(targetUrl,
      callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrustProductsEvaluationsPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a trust_products_evaluations
   *
   * @function get
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @param {string} sid - The unique string that identifies the Evaluation resource
   *
   * @returns {Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsContext}
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.get = function get(sid) {
    return new TrustProductsEvaluationsContext(this._version, this._solution.trustProductSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TrustProductsEvaluationsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TrustProductsEvaluationsListInstance[util.inspect.custom] = function
      inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return TrustProductsEvaluationsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TrustProductsEvaluationsPage
 *
 * @constructor Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TrustProductsEvaluationsSolution} solution - Path solution
 *
 * @returns TrustProductsEvaluationsPage
 */
/* jshint ignore:end */
TrustProductsEvaluationsPage = function TrustProductsEvaluationsPage(version,
    response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TrustProductsEvaluationsPage.prototype, Page.prototype);
TrustProductsEvaluationsPage.prototype.constructor = TrustProductsEvaluationsPage;

/* jshint ignore:start */
/**
 * Build an instance of TrustProductsEvaluationsInstance
 *
 * @function getInstance
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsPage#
 *
 * @param {TrustProductsEvaluationsPayload} payload - Payload response from the API
 *
 * @returns TrustProductsEvaluationsInstance
 */
/* jshint ignore:end */
TrustProductsEvaluationsPage.prototype.getInstance = function
    getInstance(payload) {
  return new TrustProductsEvaluationsInstance(this._version, payload, this._solution.trustProductSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrustProductsEvaluationsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrustProductsEvaluationsPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrustProductsEvaluationsContext
 *
 * @constructor Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsInstance
 *
 * @property {string} sid -
 *          The unique string that identifies the Evaluation resource
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} policySid - The unique string of a policy
 * @property {string} trustProductSid -
 *          The unique string that identifies the resource
 * @property {trust_products_evaluations.status} status -
 *          The compliance status of the Evaluation resource
 * @property {object} results - The results of the Evaluation resource
 * @property {Date} dateCreated - The date_created
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {TrustProductsEvaluationsPayload} payload - The instance payload
 * @param {sid} trustProductSid - The unique string that identifies the resource
 * @param {sid} sid - The unique string that identifies the Evaluation resource
 */
/* jshint ignore:end */
TrustProductsEvaluationsInstance = function
    TrustProductsEvaluationsInstance(version, payload, trustProductSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.policySid = payload.policy_sid; // jshint ignore:line
  this.trustProductSid = payload.trust_product_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.results = payload.results; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {trustProductSid: trustProductSid, sid: sid || this.sid, };
};

Object.defineProperty(TrustProductsEvaluationsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TrustProductsEvaluationsContext(
          this._version,
          this._solution.trustProductSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TrustProductsEvaluationsInstance
 *
 * @function fetch
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrustProductsEvaluationsInstance
 */
/* jshint ignore:end */
TrustProductsEvaluationsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrustProductsEvaluationsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrustProductsEvaluationsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrustProductsEvaluationsContext
 *
 * @constructor Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} trustProductSid - The unique string that identifies the resource
 * @param {sid} sid - The unique string that identifies the Evaluation resource
 */
/* jshint ignore:end */
TrustProductsEvaluationsContext = function
    TrustProductsEvaluationsContext(version, trustProductSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {trustProductSid: trustProductSid, sid: sid, };
  this._uri = `/TrustProducts/${trustProductSid}/Evaluations/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a TrustProductsEvaluationsInstance
 *
 * @function fetch
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrustProductsEvaluationsInstance
 */
/* jshint ignore:end */
TrustProductsEvaluationsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TrustProductsEvaluationsInstance(
      this._version,
      payload,
      this._solution.trustProductSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.TrustProductsContext.TrustProductsEvaluationsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrustProductsEvaluationsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TrustProductsEvaluationsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TrustProductsEvaluationsList: TrustProductsEvaluationsList,
  TrustProductsEvaluationsPage: TrustProductsEvaluationsPage,
  TrustProductsEvaluationsInstance: TrustProductsEvaluationsInstance,
  TrustProductsEvaluationsContext: TrustProductsEvaluationsContext
};
