import React, { useState, useEffect } from 'react'
import { View, Text, TextInput, TouchableOpacity, Image, AsyncStorage } from 'react-native'
import { Button, Spinner,Input } from 'native-base'
import { style } from './../../assets/style'
import CountryPicker from 'react-native-country-picker-modal'
// import { CountryCode, Country } from './src/types'
import BackButton from './../components/backButton'
import { connect } from 'react-redux'
import Toast from './../components/toast'
import { accountSid, authToken, serviceID, API_URL, GOOGLECLIENTID, FB_CLIENT, APPLEID, REDIRECT_URL, WHATSAPP_COUNTRIES, ReCAPTCHA_KEY } from './../constants/index'
import axios from 'axios'

import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { ResponseType } from 'expo-auth-session';
import { performRequest } from './../services/apiHandler'
import { adduser, addToken } from './../store/actions'
import { NavigationActions, } from 'react-navigation';
import * as Google from 'expo-auth-session/providers/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import * as Localization from "expo-localization";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import "./style.css"
// import AppleLogin from 'react-apple-login'
import AppleSignin from 'react-apple-signin-auth';
import TwitterLogin from "react-twitter-login";
import i18n from "../i18n";
import AppleLogin from 'react-apple-login'
import StyledText from "../components/styledText"
import {FontLetterSpacing} from "./../handlers/utils"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleButton } from '../components/googleAuth'
import ReCAPTCHA from "react-google-recaptcha";
import { ScrollView } from 'react-native'

const client = require('twilio')(accountSid, authToken);

WebBrowser.maybeCompleteAuthSession();

const MobileForm = (props) => {

    const [channel, setChannel] = useState("")

    const [error_message, setErrorMessage] = useState('')
    const [message_status, setMessageStatus] = useState('')
    const [message_display, setMessageDisplay] = useState('none')
    const [countryCode, setcountryCode] = useState(props.config.country.cca2)
    const [country, setCountry] = useState(props.config.country)
    const [mobile_number, setMobileNumber] = useState('')
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")
    const [tabIndex, setTabIndex] = useState(0)
    const [google_token, setGoogleToken] = useState("")

    const [request, response, promptAsync] = Facebook.useAuthRequest({
        clientId: FB_CLIENT,
        responseType: ResponseType.Token,
    });
    const [req, res, prompt] = Google.useAuthRequest({
        webClientId: GOOGLECLIENTID,
        // iosClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
        // androidClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
        // webClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    });
    const setScreenActive = () => {
        // setState({
        //     isLandingPageActive:true
        // })
    }

    const onChangeCaptcha = (googleCaptchaToken) => {
        setGoogleToken(googleCaptchaToken)
      }

    useEffect(() => {
        let country = props.config.country.cca2
        if (WHATSAPP_COUNTRIES.includes(country.toUpperCase())) {
            setChannel("WHATSAPP")
        } else {
            setChannel("SMS")

        }

    }, [])
    useEffect(() => {
        console.log(response, res, "resssssssssssss")
        if (res?.type === 'success') {

            const { accessToken } = res.authentication;
            performRequest('post', 'api/v2/pwa/google-auth/', { access_token: accessToken, mobile_number: mobile_number ? country.callingCode[0] + mobile_number : "" }, false)
                .then(response => {
                    postAuthentication(response)
                })
                .catch(error => {
                    throwMessageFn('Error authenticating with google. Try again', 'red')
                })
        }
    }, [response, res]);

    const googleAuthentication = (payload) => {
        performRequest('post', 'api/v2/pwa/google-auth/', { access_token: payload.access_token, mobile_number: mobile_number ? country.callingCode[0] + mobile_number : "" }, false)
            .then(response => {
                postAuthentication(response)
            })
            .catch(error => {
                throwMessageFn('Error authenticating with google. Try again', 'red')
            })
    }

    const postAuthentication = async (response) => {
        await AsyncStorage.setItem('token', response.data.token);
        props.addToken(response.data.token)
        props.adduser(response.data.user)
        if (props.platform == 'qr') {
            try{
                const screen = await AsyncStorage.getItem('login_trigger')
                console.log(screen, "screeeeen")
    
                props.navigation.navigate(screen)
                await AsyncStorage.removeItem("login_trigger")
    
                return
            }catch(e){
                    const navigateAction = NavigationActions.navigate({
                        routeName: 'MenuHome',
                        params: {
                        },
                    });
                    props.navigation.dispatch(navigateAction);
            }
            return;


            if (props.config.login_required && props.config.login_to_see_menu) {
                const navigateAction = NavigationActions.navigate({
                    routeName: 'HomeScreen',
                    params: {
                        setScreenActive: () => setScreenActive()
                    },
                });
                props.navigation.dispatch(navigateAction);
            } else if (props.config.login_required) {
                props.navigation.navigate('CheckoutScreen')
            } else {
                const navigateAction = NavigationActions.navigate({
                    routeName: 'MenuHome',
                });
                props.navigation.dispatch(navigateAction);
            }
        } else {
            try{
                const screen = await AsyncStorage.getItem('login_trigger')
                console.log(screen, "screeeeen")
    
                props.navigation.navigate(screen)
                await AsyncStorage.removeItem("login_trigger")
    
                return
            }catch(e){
                    const navigateAction = NavigationActions.navigate({
                        routeName: 'IndexScreen',
                        params: {
                        },
                    });
                    props.navigation.dispatch(navigateAction);
            }
            return;
            
            performRequest('get', `api/v2/pwa/address/`)
                .then(response => {
                    if (response.data.length > 0) {
                        props.navigation.navigate('CheckoutScreen')
                    } else {
                        if (props.menu_type == 'delivery') {
                            props.navigation.navigate('AddressFormScreen')
                        } else {
                            props.navigation.navigate('CheckoutScreen')

                        }
                    }
                })
                .catch(error => {
                    props.navigation.navigate('AddressFormScreen')

                })
        }
    }
    const selectCountry = (country) => {
        setcountryCode(country.cca2)
        setCountry(country)

    }
    const throwMessageFn = (message, status) => {
        setMessageStatus(status)
        setErrorMessage(message)
        setMessageDisplay('inherit')
        setTimeout(
            function () {
                setMessageStatus('')
                setErrorMessage('')
                setMessageDisplay('none')
            },
            3000
        );
    };
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const continueHandler = () => {
          if(mobile_number){
              verifyNumber()
          }else if(email){
            verifyEmail()
          }else{
            throwMessageFn('Enter an email address or mobile number', 'red')
          }
      }
    const verifyEmail = () => {
        if (!email) {
            throwMessageFn('Enter an email address', 'red')
            return
        }
        if(!validateEmail(email)){
            throwMessageFn('Enter a valid email address', 'red')
            return
        }
        setLoader(true)

        performRequest("post", "api/v2/account/email/verify", {
            email,
            venue: props.config.venue
        }, false)
        .then(response => {
            setLoader(false);

            props.navigation.navigate('OtpScreen', { channel: "email", service: "", email })

            console.log(response.data, "responseeee")
        })
        .catch(err => {
            setLoader(false);

            console.log(err.response, "err")
            throwMessageFn('Error sending verification email', 'red')
        })

    }

    const verifyNumber = () => {
        if (!mobile_number) {
            throwMessageFn('Enter a mobile number', 'red')
            return
        }

        setLoader(true)
        let locale = Localization.locale.split("-")[0]
        // let num_with_code = country.callingCode[0] + mobile_number;
        let num_with_code = mobile_number;

        axios.get(API_URL + `api/v2/pwa/number/verify/?number=${num_with_code}&locale=${locale}&channel=${channel}&venue_name=${props.config.displayName ? props.config.displayName : props.config.venueName}&venue_id=${props.config.venue}&token=${google_token}`)
            .then((response) => {
                setLoader(false);
                    props.navigation.navigate('OtpScreen', { 'country_code': country.callingCode[0], 'number': mobile_number, country: countryCode, channel, service: response.data.service })
   
            })
            .catch(() => {
                setLoader(false);
                throwMessageFn('Error sending otp. Try again.', 'red')
            })
    }

        // const GoogleAuthHandler = useGoogleLogin({
        //     onSuccess: tokenResponse => console.log(tokenResponse),
        //     onError: error => console.log(error, "errorrr")
        //   });
          
    

    return (
        <ScrollView style={{ backgroundColor: props.design.list_view_background_color,flex:1}} >

            <StyledText 
            font_color={props.design.list_view_header_color}
            font_weight={props.design.list_view_header_font_weight}
            font_style={props.design.list_view_header_font_style}
            text_transform={props.design.list_view_header_font_transform}
            font_family={props.design.list_view_header_font_family}
            font_size={props.design.list_view_header_font_size?? 20}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

            style={{ 
                marginTop: 70,marginBottom: 20, marginLeft: 10, }}>

                Login Using</StyledText>
{/* 
                {
                    props.config.is_otp_login_enabled &&
                    <View style={{
                        // height: 50,
                        width: '95%',
                        alignSelf: 'center',
                        flexDirection:"row"
                    }}>
                        <Button
                            onPress={() => setTabIndex(0)}
                            style={{ 
                                marginTop: 10, 
                                backgroundColor: "transparent", 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                width: '50%', 
                                borderWidth:1,
                                borderColor:props.design.top_buttons_background_color ,
                                borderBottomWidth:tabIndex == 0?0:1,
                                borderTopLeftRadius:5,
                                borderBottomLeftRadius:tabIndex == 0?0:5,
                                borderRightWidth: tabIndex == 0?0:1,
                                borderBottomRightRadius:tabIndex == 0?0:5,
                            }}
                        >
                            <Text style={{ color: props.design.top_buttons_background_color, fontFamily: 'SecondaryFont' }}> {i18n.t("labels.mobile_num")} </Text>
                        </Button>
                        <Button
                            onPress={() => setTabIndex(1)}
                            style={{ 
                                marginTop: 10, 
                                backgroundColor: "transparent", 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                width: '50%', 
                                 borderWidth:1,
                                 borderColor:props.design.top_buttons_background_color ,
                                 borderBottomWidth:tabIndex == 1?0:1,
                                 borderTopRightRadius:5,
                                 borderBottomRightRadius:tabIndex == 1?0:5,
                                 borderLeftWidth: tabIndex == 1?0:1,
                                 borderBottomLeftRadius:tabIndex == 1?0:5,
                                }}
                        >
                            <Text style={{ color: props.design.top_buttons_background_color, fontFamily: 'SecondaryFont' }}> {i18n.t("labels.email")} </Text>
                        </Button>
                      
                    </View>
                } */}

            {
           props.config.is_otp_login_enabled &&
                <>
                    <StyledText 
                        font_color={props.design.list_view_sub_text_color}
                        font_weight={props.design.list_view_sub_text_font_weight}
                        font_style={props.design.list_view_sub_text_font_style}
                        text_transform={props.design.list_view_sub_text_font_transform}
                        font_family={props.design.list_view_sub_text_font_family}
                        font_size={props.design.list_view_sub_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                        
                    style={{  marginBottom: channel.toLowerCase() == "whatsapp" ? 5 : 20, marginLeft: 10, marginTop: !props.config.is_social_login_enabled ? 100 : 20  }}>
                        Enter your {channel.toLowerCase() == "whatsapp" ? "WhatsApp" : "phone"} number
                    </StyledText>
                    {
                        channel.toLowerCase() == "whatsapp" ?
                            <StyledText 
                            font_color={props.design.list_view_text_color}
                            font_weight={props.design.list_view_text_font_weight}
                            font_style={props.design.list_view_text_font_style}
                            text_transform={props.design.list_view_text_font_transform}
                            font_family={props.design.list_view_text_font_family}
                            font_size={props.design.list_view_text_font_size?? 12}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                            style={{ fontSize: props.design.custom_font.body_size ?? 10, marginBottom: 20, marginLeft: 10,  }}>
                                {i18n.t("labels.whatsapp_desc")}

                            </StyledText>
                            :
                            <View />
                    }

                    <View style={{ flexDirection: 'row', height: 50, width: '95%', alignSelf: 'center' }}>
                        <PhoneInput
                            style={{
                                fontSize: props.design.custom_font.heading_size ?? 40,
                                height: '100%',
                                width: '96%',


                            }}
                            defaultCountry={countryCode}
                            value={mobile_number}
                            onChange={(text) => setMobileNumber(text)} />

                    </View>

                    {
                        channel.toLowerCase() == "whatsapp" ?
                            <View style={{ flexDirection: "row" }} >
                                <StyledText 
                                 font_color={props.design.list_view_text_color}
                                 font_weight={props.design.list_view_text_font_weight}
                                 font_style={props.design.list_view_text_font_style}
                                 text_transform={props.design.list_view_text_font_transform}
                                 font_family={props.design.list_view_text_font_family}
                                 font_size={props.design.list_view_text_font_size?? 14}
                                 letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                                style={{ marginBottom: 20, marginLeft: 10, marginTop: 20 }}>

                                    {i18n.t("labels.whatsapp_desc_1")}

                                </StyledText>
                                <TouchableOpacity onPress={() => setChannel("SMS")} >
                                    <StyledText 
                                    // font_color={props.design.list_view_text_color}
                                    font_weight={props.design.list_view_text_font_weight}
                                    font_style={props.design.list_view_text_font_style}
                                    text_transform={props.design.list_view_text_font_transform}
                                    font_family={props.design.list_view_text_font_family}
                                    font_size={props.design.list_view_text_font_size?? 14}
                                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                                    style={{ color: "blue", marginTop: 20,  }} >{" "}{i18n.t("labels.click_here")}</StyledText>
                                </TouchableOpacity>
                            </View>
                            :
                            <View style={{ flexDirection: "row" }} >
                                <StyledText 
                                font_color={props.design.list_view_text_color}
                                font_weight={props.design.list_view_text_font_weight}
                                font_style={props.design.list_view_text_font_style}
                                text_transform={props.design.list_view_text_font_transform}
                                font_family={props.design.list_view_text_font_family}
                                font_size={props.design.list_view_text_font_size?? 14}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                                style={{ fontSize: props.design.custom_font.sub_heading_size ?? 14, marginVertical: 20, marginLeft: 10}}>

                                    {i18n.t("labels.whatsapp_desc_2")}
                                </StyledText>
                                <TouchableOpacity onPress={() => setChannel("WHATSAPP")} >
                                    <StyledText 
                                    // font_color={props.design.list_view_text_color}
                                    font_weight={props.design.list_view_text_font_weight}
                                    font_style={props.design.list_view_text_font_style}
                                    text_transform={props.design.list_view_text_font_transform}
                                    font_family={props.design.list_view_text_font_family}
                                    font_size={props.design.list_view_text_font_size?? 14}
                                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
   
                                    style={{ color: "blue", marginTop: 20,  }} >{" "} {i18n.t("labels.click_here")} </StyledText>
                                </TouchableOpacity>
                            </View>
                    }


                    {/* <View style={{ height: 40 }} /> */}

                    {
                        channel.toLowerCase() == "whatsapp" ?
                            <StyledText 
                            font_color={props.design.list_view_text_color}
                            font_weight={props.design.list_view_text_font_weight}
                            font_style={props.design.list_view_text_font_style}
                            text_transform={props.design.list_view_text_font_transform}
                            font_family={props.design.list_view_text_font_family}
                            font_size={props.design.list_view_text_font_size?? 12}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                            style={{  marginBottom: 4, marginLeft: 12,  }}>
                                {i18n.t("labels.whatsapp_desc_3")}
                            </StyledText>
                            :
                            <View />
                    }
                    {/* <View style={{
                        // height: 50,
                        width: '95%',
                        alignSelf: 'center',
                    }}>
                        <Button
                            disabled={loader}
                            onPress={() => verifyNumber()}
                            style={{ marginTop: 10, backgroundColor: props.design.top_buttons_background_color, alignItems: 'center', justifyContent: 'center', width: '100%', borderRadius: 5 }}
                        >
                            {
                                loader ?
                                    <Spinner color={props.design.top_buttons_text_color} />
                                    :
                                    <Text style={{ color: props.design.top_buttons_text_color, fontFamily: 'SecondaryFont' }}> {i18n.t("labels.continue")} </Text>
                            }
                        </Button>
                    </View> */}

                </>
}
             
                <>
                {
                    props.config.is_otp_login_enabled && props.config.is_email_login_enabled &&
                    <StyledText 
                    font_color={props.design.list_view_text_color}
                    // font_weight={props.design.list_view_text_font_weight}
                    font_style={props.design.list_view_text_font_style}
                    text_transform={props.design.list_view_text_font_transform}
                    font_family={props.design.list_view_text_font_family}
                    font_size={props.design.list_view_text_font_size?? 18}
                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{  fontWeight: 'bold', marginBottom: 10, marginLeft: 10, marginTop: !props.config.is_otp_login_enabled ? 100 : 15, alignSelf:"center" }}>
                    Or
                </StyledText>
                }
                
                {
                    props.config.is_email_login_enabled &&
                <>
                <StyledText 
                    font_color={props.design.list_view_text_color}
                    font_weight={props.design.list_view_text_font_weight}
                    font_style={props.design.list_view_text_font_style}
                    text_transform={props.design.list_view_text_font_transform}
                    font_family={props.design.list_view_text_font_family}
                    font_size={props.design.list_view_text_font_size?? 15}
                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                style={{  fontWeight: 'bold', marginBottom: 5, marginLeft: 10, }}>
                {i18n.t("labels.enter_email_address")}
                </StyledText>
             

                <View style={{ flexDirection: 'row', height: 50, width: '95%', alignSelf: 'center' }}>
                <View style={{ height: 35, marginTop: 5, marginBottom: 20 , width:"100%"}}>
                    <Input
                    type="email"
                        value={email}
                        onChangeText={(text) => setEmail(text)}
                        style={{
                        color: "grey",
                        border: "1px solid grey",
                        borderRadius: 5, 
                        }}
                    />
                    </View>
                </View>
                </>
                }
                {
                    (props.config.is_otp_login_enabled || props.config.is_email_login_enabled) &&
                    <View style={{
                        // height: 50,
                        width: '95%',
                        alignSelf: 'center',
                    }}>
                        <ReCAPTCHA
                            sitekey={ReCAPTCHA_KEY}
                            onChange={onChangeCaptcha}
                        />
                        <Button
                            disabled={loader}
                            onPress={() => continueHandler()}
                            style={{ marginTop: 10, backgroundColor: props.design.top_buttons_background_color, alignItems: 'center', justifyContent: 'center', width: '100%', borderRadius: 5 }}
                        >
                            {
                                loader ?
                                    <Spinner color={props.design.top_buttons_text_color} />
                                    :
                                    <StyledText 
                                        font_color={props.design.top_buttons_text_color}
                                        font_weight={props.design.top_button_text_font_weight}
                                        font_style={props.design.top_button_text_font_style}
                                        text_transform={props.design.top_button_text_font_transform}
                                        font_family={props.design.top_button_text_font_family}
                                        font_size={props.design.top_button_text_font_size}
                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                                
                            
                                    style={{ }}> {i18n.t("labels.continue")} </StyledText>
                            }
                        </Button>
                    </View>
                }

                </>

{
                props.config.is_social_login_enabled && 
                <>
                {(props.config.is_otp_login_enabled || props.config.is_email_login_enabled)  &&
                                    <View style={{
                                        // height: 50,
                                        width: '95%',
                                        alignSelf: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginVertical: 25,
                
                                    }}>
                                        <StyledText 
                                        font_color={props.design.list_view_text_color}
                                        font_weight={props.design.list_view_text_font_weight}
                                        font_style={props.design.list_view_text_font_style}
                                        text_transform={props.design.list_view_text_font_transform}
                                        font_family={props.design.list_view_text_font_family}
                                        font_size={props.design.list_view_text_font_size?? 18}
                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                    
                                        style={{ fontWeight: 'bold', }}>Or</StyledText>
                                    </View>
                }


                    <View style={{
                        // height: 50,
                        width: '95%',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',


                    }}>
                        <GoogleOAuthProvider clientId={GOOGLECLIENTID}>
                            <GoogleButton googleAuthentication={googleAuthentication} />
                            {/* <TouchableOpacity onPress={() => prompt()}>
                                <View style={style.socialButtons}>
                                    <Image source={require('../../assets/google.png')} style={{ width: 23, height: 23 }} />
                                </View>
                            </TouchableOpacity> */}
                                {/* <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        console.log(credentialResponse);
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                    /> */}

                            </GoogleOAuthProvider>



                        <FacebookLogin
                            redirectUri={REDIRECT_URL}
                            appId={FB_CLIENT}
                            isMobile={false}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={(response) => {
                                if (response && response.email) {
                                    console.log(response, "response")
                                    const { email, name, picture } = response
                                    let formData = new FormData();
                                    formData.append('email', email);
                                    formData.append('name', name);
                                    // formData.append('name', name);
                                    formData.append('mobile_number', mobile_number ? country.callingCode[0] + mobile_number : "");

                                    let picture_url = ''
                                    if (picture) {
                                        picture_url = picture.data.url
                                        formData.append('picture_url', picture_url);
                                    }
                                    performRequest('post', 'api/v2/pwa/facebook-auth', formData, false)
                                        .then(response => {
                                            AsyncStorage.setItem('login_with', 'facebook')
                                            postAuthentication(response)
                                        })
                                        .catch(error => {
                                            throwMessageFn('Error authenticating with Facebook. Try again', 'red')
                                        })
                                } else {
                                    throwMessageFn('Error authenticating with Facebook. Try again', 'red')

                                }

                            }}
                            icon={require('../../assets/facebook.png')}
                            containerStyle={style.socialButtons}
                            buttonStyle={style.socialButtons}
                            render={renderProps =>
                                <TouchableOpacity
                                    onPress={() => renderProps.onClick()}
                                // onPress={() =>  promptAsync()}
                                >
                                    <View style={style.socialButtons}>
                                        <Image source={require('../../assets/facebook.png')} style={{ width: 23, height: 23 }} />
                                    </View>
                                </TouchableOpacity>
                            }
                        />
                        {/* <AppleLogin callback={(response)=>{
                            console.log(response,"rrrrrrrrrrr")
                        }} usePopup={true} responseType="id_token" scope="name email" clientId={APPLEID} redirectURI={REDIRECT_URL} /> */}


                        <AppleSignin
                            /** Auth options passed to AppleID.auth.init() */
                            authOptions={{
                                clientId: APPLEID,
                                /** Requested scopes, seperated by spaces - eg: 'email name' */
                                scope: 'email name',
                                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                                redirectURI: REDIRECT_URL,
                                state: '',
                                nonce: 'nonce',
                                usePopup: true,
                            }}
                            /** General props */
                            uiType="dark"
                            /** className */
                            className="apple-auth-btn"
                            /** Allows to change the button's children, eg: for changing the button text */
                            buttonExtraChildren="Continue with Apple"
                            /** Checkout README.md for further customization props. */
                            /** Spread rest props if needed */
                            onSuccess={(response) => {
                                console.log(response,"rrrrrrrrrrr")
                                const { id_token, code } = response.authorization
                                performRequest('post', 'api/v2/pwa/apple-auth', { token: id_token, code, mobile_number: mobile_number ? country.callingCode[0] + mobile_number : "" }, false)
                                    .then(response => {
                                        postAuthentication(response)
                                    })
                                    .catch(error => {
                                        throwMessageFn('Error authenticating with Apple. Try again', 'red')
                                    })
                                console.log(response, "ssssssss")

                            }} // default = undefined
                            render={(props) => <TouchableOpacity {...props}>
                                <View style={style.socialButtons}>
                                    <Image source={require('../../assets/apple.png')} style={{ width: 23, height: 23 }} />
                                </View>
                            </TouchableOpacity>}
                        />




                    </View>
           
                </>


            }
        




            <TouchableOpacity style={{ position: 'absolute', top: 0 }} onPress={() => props.navigation.goBack()} >
                <BackButton
                    border_color={`1px solid ${props.design.top_buttons_border_color}`}
                    backgroundColor={props.design.top_buttons_background_color}
                    borderColor={'black'}
                    color={props.design.top_buttons_text_color}
                    default_language={props.default_language}

                />
            </TouchableOpacity>



            <Toast
                error_message={error_message}
                message_status={message_status}
                message_display={message_display}
                custom_font={props.design.custom_font}
            />



        </ScrollView>
    )
}
MobileForm['navigationOptions'] = screenProps => ({
    header: null,
    gesturesEnabled: false,
    animationEnabled: true
})

const mapStateToProps = state => {
    return {
        menus: state.menus.menus,
        allChildren: state.menus.allChildren,
        immediateChildren: state.menus._immediateChildren,
        cart: state.cart.cart,
        cart_count: state.cart.cart_count,
        total_price: state.cart.total_price,
        config: state.config.config,
        languages: state.languages.languages,
        default_language: state.languages.default_language,
        isRTL: state.languages.isRTL,
        menu_type: state.config.menu_type,
        platform: state.config.platform,
        design: state.config.design,

    }
};

export default connect(mapStateToProps, { adduser, addToken })(MobileForm)