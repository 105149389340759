import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Text, TouchableOpacity, View, ScrollView} from 'react-native'
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';
import { Spinner} from 'native-base'


class ChatWindow extends Component {
  constructor(props) {
    super(props);
  }

  onUserInputSubmit(message) {
    this.props.onUserInputSubmit(message);
  }

  onFilesSelected(filesList) {
    this.props.onFilesSelected(filesList);
  }

  render() {
    let messageList = this.props.messageList || [];
    let classList = [
      'sc-chat-window',
      (this.props.isOpen ? 'opened' : 'closed')
    ];
    return (
      <div className={classList.join(' ')}>
        <Header
          design={this.props.design}
          teamName={this.props.agentProfile.teamName}
          imageUrl={this.props.agentProfile.imageUrl}
          onClose={this.props.onClose}
        />
        {
          this.props.isMessageLoading ?
            <Spinner color={"black"} />
            :
              <MessageList
              messages={messageList}
              imageUrl={this.props.agentProfile.imageUrl}
            />
        }

                {
          !this.props.isMessageLoading &&
          <ScrollView horizontal style={{margin:5}} >
            {
              this.props.predefined_messages ? this.props.predefined_messages.map(item => (
                  <TouchableOpacity onPress={() => this.onUserInputSubmit({data:{text: item.message}})} style={{minWidth:60,justifyContent:"center",alignItems:"center", border:1,borderRadius: 10,backgroundColor:"lightgrey",padding:8, marginRight:6}}>
                    <View style={{minWidth:60,justifyContent:"center",alignItems:"center", border:1,borderRadius: 10,backgroundColor:"lightgrey",padding:8}} >
                        <Text style={{   fontFamily:'SecondaryFont'}}>{item.message}</Text>
                    </View>
                  </TouchableOpacity>
              ))
              :
              <View/>
            }

          </ScrollView>

        }
        <UserInput
          onSubmit={this.onUserInputSubmit.bind(this)}
          onFilesSelected={this.onFilesSelected.bind(this)}
          showEmoji={this.props.showEmoji}
        />
      </div>
    );
  }
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  onUserInputSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool,
  isMessageLoading: PropTypes.bool,
  design: PropTypes.object,
  predefined_messages: PropTypes.arrayOf(PropTypes.object),
};

export default ChatWindow;
