import {
    ADD_CONFIG,
    ADD_TABLE,
    SET_DELIVERY_MODE,
    SET_PLATFORM,
    SET_VENUES,
    SET_DESIGN,
    SET_FEEDBACK_MODAL
} from "../actionTypes";


const initialState = {
    config: [],
    table:{},
    platform: "qr",
    menu_type: 'delivery',
    venues: [],
    design: {},
    FeedbackModal: false

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CONFIG:
            return {
                ...state,
                config: action.config
            };

        case ADD_TABLE:
            return {
                ...state,
                table: action.table
            };
        case SET_DELIVERY_MODE:
            return{
            ...state,
            menu_type: action.mode
            };
        case SET_PLATFORM:
            return{
            ...state,
            platform: action.platform
            };
        case SET_VENUES:
            return{
                ...state,
                venues: action.venues
                }; 
        case SET_DESIGN:
            return{
                ...state,
                design: action.design
                }; 
        case SET_FEEDBACK_MODAL:
            return{
                ...state,
                FeedbackModal: action.feedback
                }; 
        default:
            return state;
    }
};

export default reducer;