'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var ArchivedCallList = require('./v1/archivedCall').ArchivedCallList;
var ByocTrunkList = require('./v1/byocTrunk').ByocTrunkList;
var ConnectionPolicyList = require(
    './v1/connectionPolicy').ConnectionPolicyList;
var DialingPermissionsList = require(
    './v1/dialingPermissions').DialingPermissionsList;
var IpRecordList = require('./v1/ipRecord').IpRecordList;
var SourceIpMappingList = require('./v1/sourceIpMapping').SourceIpMappingList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V1 version of Voice
 *
 * @constructor Twilio.Voice.V1
 *
 * @property {Twilio.Voice.V1.ArchivedCallList} archivedCalls -
 *          archivedCalls resource
 * @property {Twilio.Voice.V1.ByocTrunkList} byocTrunks - byocTrunks resource
 * @property {Twilio.Voice.V1.ConnectionPolicyList} connectionPolicies -
 *          connectionPolicies resource
 * @property {Twilio.Voice.V1.DialingPermissionsList} dialingPermissions -
 *          dialingPermissions resource
 * @property {Twilio.Voice.V1.IpRecordList} ipRecords - ipRecords resource
 * @property {Twilio.Voice.V1.SourceIpMappingList} sourceIpMappings -
 *          sourceIpMappings resource
 *
 * @param {Twilio.Voice} domain - The twilio domain
 */
/* jshint ignore:end */
function V1(domain) {
  Version.prototype.constructor.call(this, domain, 'v1');

  // Resources
  this._archivedCalls = undefined;
  this._byocTrunks = undefined;
  this._connectionPolicies = undefined;
  this._dialingPermissions = undefined;
  this._ipRecords = undefined;
  this._sourceIpMappings = undefined;
}

_.extend(V1.prototype, Version.prototype);
V1.prototype.constructor = V1;

Object.defineProperty(V1.prototype,
  'archivedCalls', {
    get: function() {
      this._archivedCalls = this._archivedCalls || new ArchivedCallList(this);
      return this._archivedCalls;
    }
});

Object.defineProperty(V1.prototype,
  'byocTrunks', {
    get: function() {
      this._byocTrunks = this._byocTrunks || new ByocTrunkList(this);
      return this._byocTrunks;
    }
});

Object.defineProperty(V1.prototype,
  'connectionPolicies', {
    get: function() {
      this._connectionPolicies = this._connectionPolicies || new ConnectionPolicyList(this);
      return this._connectionPolicies;
    }
});

Object.defineProperty(V1.prototype,
  'dialingPermissions', {
    get: function() {
      this._dialingPermissions = this._dialingPermissions || new DialingPermissionsList(this);
      return this._dialingPermissions;
    }
});

Object.defineProperty(V1.prototype,
  'ipRecords', {
    get: function() {
      this._ipRecords = this._ipRecords || new IpRecordList(this);
      return this._ipRecords;
    }
});

Object.defineProperty(V1.prototype,
  'sourceIpMappings', {
    get: function() {
      this._sourceIpMappings = this._sourceIpMappings || new SourceIpMappingList(this);
      return this._sourceIpMappings;
    }
});

module.exports = V1;
