'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var PhoneNumberList;
var PhoneNumberPage;
var PhoneNumberInstance;
var PhoneNumberContext;

/* jshint ignore:start */
/**
 * Initialize the PhoneNumberList
 *
 * @constructor Twilio.Lookups.V1.PhoneNumberList
 *
 * @param {Twilio.Lookups.V1} version - Version of the resource
 */
/* jshint ignore:end */
PhoneNumberList = function PhoneNumberList(version) {
  /* jshint ignore:start */
  /**
   * @function phoneNumbers
   * @memberof Twilio.Lookups.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Lookups.V1.PhoneNumberContext}
   */
  /* jshint ignore:end */
  function PhoneNumberListInstance(sid) {
    return PhoneNumberListInstance.get(sid);
  }

  PhoneNumberListInstance._version = version;
  // Path Solution
  PhoneNumberListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a phone_number
   *
   * @function get
   * @memberof Twilio.Lookups.V1.PhoneNumberList#
   *
   * @param {string} phoneNumber - The phone number to fetch in E.164 format
   *
   * @returns {Twilio.Lookups.V1.PhoneNumberContext}
   */
  /* jshint ignore:end */
  PhoneNumberListInstance.get = function get(phoneNumber) {
    return new PhoneNumberContext(this._version, phoneNumber);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Lookups.V1.PhoneNumberList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  PhoneNumberListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  PhoneNumberListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return PhoneNumberListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the PhoneNumberPage
 *
 * @constructor Twilio.Lookups.V1.PhoneNumberPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {PhoneNumberSolution} solution - Path solution
 *
 * @returns PhoneNumberPage
 */
/* jshint ignore:end */
PhoneNumberPage = function PhoneNumberPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(PhoneNumberPage.prototype, Page.prototype);
PhoneNumberPage.prototype.constructor = PhoneNumberPage;

/* jshint ignore:start */
/**
 * Build an instance of PhoneNumberInstance
 *
 * @function getInstance
 * @memberof Twilio.Lookups.V1.PhoneNumberPage#
 *
 * @param {PhoneNumberPayload} payload - Payload response from the API
 *
 * @returns PhoneNumberInstance
 */
/* jshint ignore:end */
PhoneNumberPage.prototype.getInstance = function getInstance(payload) {
  return new PhoneNumberInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Lookups.V1.PhoneNumberPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
PhoneNumberPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

PhoneNumberPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the PhoneNumberContext
 *
 * @constructor Twilio.Lookups.V1.PhoneNumberInstance
 *
 * @property {object} callerName - The name of the phone number's owner
 * @property {string} countryCode - The ISO country code for the phone number
 * @property {string} phoneNumber - The phone number in E.164 format
 * @property {string} nationalFormat - The phone number, in national format
 * @property {object} carrier - The telecom company that provides the phone number
 * @property {object} addOns -
 *          A JSON string with the results of the Add-ons you specified
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V1} version - Version of the resource
 * @param {PhoneNumberPayload} payload - The instance payload
 * @param {phone_number} phoneNumber - The phone number to fetch in E.164 format
 */
/* jshint ignore:end */
PhoneNumberInstance = function PhoneNumberInstance(version, payload,
                                                    phoneNumber) {
  this._version = version;

  // Marshaled Properties
  this.callerName = payload.caller_name; // jshint ignore:line
  this.countryCode = payload.country_code; // jshint ignore:line
  this.phoneNumber = payload.phone_number; // jshint ignore:line
  this.nationalFormat = payload.national_format; // jshint ignore:line
  this.carrier = payload.carrier; // jshint ignore:line
  this.addOns = payload.add_ons; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {phoneNumber: phoneNumber || this.phoneNumber, };
};

Object.defineProperty(PhoneNumberInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new PhoneNumberContext(this._version, this._solution.phoneNumber);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a PhoneNumberInstance
 *
 * @function fetch
 * @memberof Twilio.Lookups.V1.PhoneNumberInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.countryCode] - The ISO country code of the phone number
 * @param {string|list} [opts.type] - The type of information to return
 * @param {string|list} [opts.addOns] -
 *          The unique_name of an Add-on you would like to invoke
 * @param {object} [opts.addOnsData] -
 *          Data specific to the add-on you would like to invoke
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed PhoneNumberInstance
 */
/* jshint ignore:end */
PhoneNumberInstance.prototype.fetch = function fetch(opts, callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Lookups.V1.PhoneNumberInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
PhoneNumberInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

PhoneNumberInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the PhoneNumberContext
 *
 * @constructor Twilio.Lookups.V1.PhoneNumberContext
 *
 * @param {V1} version - Version of the resource
 * @param {phone_number} phoneNumber - The phone number to fetch in E.164 format
 */
/* jshint ignore:end */
PhoneNumberContext = function PhoneNumberContext(version, phoneNumber) {
  this._version = version;

  // Path Solution
  this._solution = {phoneNumber: phoneNumber, };
  this._uri = `/PhoneNumbers/${phoneNumber}`;
};

/* jshint ignore:start */
/**
 * fetch a PhoneNumberInstance
 *
 * @function fetch
 * @memberof Twilio.Lookups.V1.PhoneNumberContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.countryCode] - The ISO country code of the phone number
 * @param {string|list} [opts.type] - The type of information to return
 * @param {string|list} [opts.addOns] -
 *          The unique_name of an Add-on you would like to invoke
 * @param {object} [opts.addOnsData] -
 *          Data specific to the add-on you would like to invoke
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed PhoneNumberInstance
 */
/* jshint ignore:end */
PhoneNumberContext.prototype.fetch = function fetch(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'CountryCode': _.get(opts, 'countryCode'),
    'Type': serialize.map(_.get(opts, 'type'), function(e) { return e; }),
    'AddOns': serialize.map(_.get(opts, 'addOns'), function(e) { return e; })
  });
  _.extend(data, serialize.prefixedCollapsibleMap(opts.addOnsData, 'AddOns'));

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new PhoneNumberInstance(this._version, payload, this._solution.phoneNumber));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Lookups.V1.PhoneNumberContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
PhoneNumberContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

PhoneNumberContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  PhoneNumberList: PhoneNumberList,
  PhoneNumberPage: PhoneNumberPage,
  PhoneNumberInstance: PhoneNumberInstance,
  PhoneNumberContext: PhoneNumberContext
};
