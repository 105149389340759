export const GET_MENUS = 'GET_MENUS';
export const GET_CHILDRENS = 'GET_CHILDRENS';
export const UPDATE_CHILDRENS = 'UPDATE_CHILDRENS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const CART_COUNTER = 'CART_COUNTER';

export const CART_REDUCE_COUNT = 'CART_REDUCE_COUNT';

export const ADD_TOTAL_PRICE = 'ADD_TOTAL_PRICE';

export const RESET_CART = 'RESET_CART';

export const RESET_MENU = 'RESET_MENU';

export const ADD_CONFIG = 'ADD_CONFIG';

export const SET_COUNT = 'SET_COUNT';

export const DEFAULT_LANGUAGE = 'DEFAULT_LANGUAGE';
export const LANGUAGES = 'LANGUAGES';

export const ALL_CART = 'ALL_CART';

export const SELECT_MENU = 'SELECT_MENU';

export const ADD_CARD = 'ADD_CARD';

export const ADD_TABLE = 'ADD_TABLE';

export const ADD_LOCATION = 'ADD_LOCATION';


export const GET_MODIFIERS = 'GET_MODIFIERS';

export const RESET_CHILDREN = 'RESET_CHILDREN'

export const IS_LOADING = 'IS_LOADING';


export const ADD_VERIFIED_NUMBER = 'ADD_VERIFIED_NUMBER';

export const ADD_USER = 'ADD_USER';

export const SET_DELIVERY_MODE = 'SET_DELIVERY_MODE';

export const SET_TOKEN = 'SET_TOKEN';

export const SET_PLATFORM = 'SET_PLATFORM';


export const GET_ONLINE_CONTENT = 'GET_ONLINE_CONTENT';

export const BACK_TO_PROFILE = 'BACK_TO_PROFILE';
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';

export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

export const SET_VENUES = 'SET_VENUES';

export const SET_DESIGN= 'SET_DESIGN';

export const SET_MESSAGE = 'SET_MESSAGE';

export const ALLOW_ORDERING = "ALLOW_ORDERING";

export const SET_PRIMARY_FONT_FACE = "SET_PRIMARY_FONT_FACE";

export const SET_SECONDARY_FONT_FACE = "SET_SECONDARY_FONT_FACE";

export const SEARCH_ITEM = "SEARCH_ITEM";

export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT"
export const SET_FEEDBACK_MODAL = "SET_FEEDBACK_MODAL";
