"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "appleAuthHelpers", {
  enumerable: true,
  get: function get() {
    return _appleAuthHelpers.default;
  }
});
Object.defineProperty(exports, "useScript", {
  enumerable: true,
  get: function get() {
    return _useScript.default;
  }
});
exports.default = void 0;

var _AppleSigninButton = _interopRequireDefault(require("./AppleSigninButton/AppleSigninButton"));

var _appleAuthHelpers = _interopRequireDefault(require("./appleAuthHelpers"));

var _useScript = _interopRequireDefault(require("./hooks/useScript"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = _AppleSigninButton.default;
exports.default = _default;