import { Dimensions, Platform, StyleSheet } from "react-native";
let { width, height } = Dimensions.get("window");
if (Platform.OS === "web") {
  // width = 450;
}
let elevationShadowStyle = {
  elevation: 5,
  shadowColor: "black",
  shadowOffset: { width: 0, height: 0.5 * 5 },
  shadowOpacity: 0.3,
  shadowRadius: 0.8 * 5,
};

let elevationShadowStyleCart = {
  elevation: 5,
  shadowColor: "black",
  shadowOffset: { width: 0, height: 0.5 * 5 },
  shadowOpacity: 0.3,
  shadowRadius: 0.8 * 13,
};
export const style = StyleSheet.create({
  logoContainer: {
    position:"absolute",
    top:75 , 
    width:"100%", 
    alignItems:"center",
  },
  logoImgContainer: {
    width: 100, 
    borderRadius:5,
    height: 100, 
    ...elevationShadowStyle,
  },
  listContainer: {
    width: "95%",
    minHeight: 100,
    // backgroundColor: "transparent",
    marginBottom: 13,
    borderRadius: 6,
    shadowColor: "#000",
    // paddingBottom:20,
    // paddingTop:6,
    ...elevationShadowStyle,
  },
  listImageStyle: {
    height: "100%",
    width: "100%",
  },
  listImageContainerStyle: {
    height: "100%",
    width: "30%",
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
  },
  itemAddButton: {
    minWidth: 63,
    minHeight: 23,
    // borderWidth:1,
    // marginRight: 2,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    // marginBottom: 2,
    fontFamily: "SecondaryFont",
    
  },
  itemPrice: {
    position: "absolute",
    bottom: 9,
    fontWeight: 460,
    color: "#F44336",
    fontFamily: "SecondaryFont"
  },
  backButton: {
    flexDirection: "row",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    height: 35,
    margin: 5,
    ...elevationShadowStyle,
  },
  topSection: {
    width: 120,
    minHeight: 120,
    margin: 5,
    borderRadius: 7,
    alignItems: "center",

    // ...elevationShadowStyle,
  },
  topCampaignSingle: {
    width: width * 0.97,
    height: 140,
    margin: 5,
    borderRadius: 7,
    alignItems: "center",

    // ...elevationShadowStyle,
  },
  topCampaign: {
    width: width * 0.9,
    height: 140,
    margin: 5,
    borderRadius: 7,
    alignItems: "center",

    // ...elevationShadowStyle,
  },
  fontButton: {
    flexDirection: "row",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    height: 35,
    margin: 5,
    ...elevationShadowStyle,
  },
  languageButton: {
    flexDirection: "row",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    height: 35,
    margin: 5,
    ...elevationShadowStyle,
  },
  cartButton: {
    flexDirection: "row",
    width: 63,
    height: 23,
    // borderWidth:1,
    marginRight: 2,
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 4,
    marginBottom: 2,
  },
  closeButton: {
    width: 30,
    height: 30,
    backgroundColor: "#e4e6eb",
    marginRight: 6,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    // borderRadius:8,
    // ...elevationShadowStyle
  },
  closeButtonModal: {
    width: 40,
    height: 40,
    // backgroundColor:'#e4e6eb',
    marginRight: 6,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "lightgrey",
  },
  checkoutIconWrap: {
    width: 30,
    height: 30,
    backgroundColor: "#e4e6eb",
    marginRight: 6,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    justifyContent: "center",
  },
  addToCartStyle: {
    backgroundColor: "white",
    width: "100%",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    ...elevationShadowStyle,
  },
  cartItems: {
    // width: Dimensions.get("window").width * 0.96,
    // height:55,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // backgroundColor:'white',
    // borderRadius:7,
    padding: 7,
    // ...elevationShadowStyle,
    alignSelf: "center",
    // marginVertical:13,
    // borderBottomWidth:1,
    // borderColor:'grey',
    paddingBottom: 10,
  },
  cartItem: {
    // height:55,
    flexDirection: "column",
    borderRadius:6
  },
  welcomeContainer: {
    alignItems: "center",
    justifyContent: "center",
    // height: 35,
    // width: 60,
    // paddingTop:5,
    // paddingBottom:5,
    // borderRadius: 10,
    // marginTop: 150,
    ...elevationShadowStyle,
  },
  optionsContainer: {
    height: "95%",
    width: "95%",
    backgroundColor: "white",
    borderRadius: 8,
    ...elevationShadowStyleCart,
  },
  optionMainContainer: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height * 0.5,
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "rgba(0, 0, 0, 0.1)",
  },

  container: {
    flex: 1,
    backgroundColor: "white",
  },
  child: {
    height: height * 0.5,
    width,
    justifyContent: "center",
  },
  text: {
    fontSize: width * 0.5,
    textAlign: "center",
  },
  iconStyle: { width: 14, height: 14, marginRight: 3, marginTop: 1},

  checkoutModal: {
    width: "97%",
    borderRadius: 12,
    justifyContent: "center",
    backgroundColor: "white",
    ...elevationShadowStyle,
  },

  ModalListContainer: {
    flexDirection: "row",
    height: 45,
    margin: 7,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: "lightgrey",
    // ...elevationShadowStyle
  },
  checkoutContainer: {
    flexDirection: "row",
    // height:45,
    margin: 7,
    borderRadius: 8,
    marginVertical: 7,
    // ...elevationShadowStyle,
    padding: 8,
    // alignItems:'center',
    borderWidth: 0.5,
    borderColor: "lightgrey",
    width:'98%'
  },
  paymentContainer: {
    flexDirection: "row",
    // height:45,
    // margin: 7,
    // borderRadius: 8,
    // marginTop: 7,
    // ...elevationShadowStyle,
    padding: 8,
    // alignItems:'center',
    // borderWidth: 0.5,
    // borderColor: "lightgrey",
    width:'90%',
    height: 50,
    marginBottom:0

  },
  summaryPrice: {
    margin: 7,
    borderRadius: 8,
    marginVertical: 10,
    // ...elevationShadowStyle,
    padding: 8,
    // alignItems:'center',
    borderWidth: 0.5,
    borderColor: "lightgrey",
  },
  inputStyle: {
    flex: 1,
    fontSize: 17,
    fontWeight: "bold",
    color: "#5a52a5",
  },

  ticket: {
    position: "absolute",
    bottom: 0,
    boxSizing: "border-box",
    width: "100%",
    height: "88%",
    margin: "150px auto 0",
    padding: 20,
    borderRadius: 10,
    ...elevationShadowStyle,

    // background: '#FBFBFB',
    // boxShadow: '2px 2px 15px 0px #c5c5c5',
    backgroundColor: "white",
  },
  datePickerModal: {
    position: "absolute",
    bottom: 0,
    boxSizing: "border-box",
    width: "100%",
    height: "60%",
    margin: "150px auto 0",
    padding: 20,
    borderRadius: 10,
    ...elevationShadowStyle,

    // background: '#FBFBFB',
    // boxShadow: '2px 2px 15px 0px #c5c5c5',
    backgroundColor: "white",
  },

  recentOrderCard: {
    width: "97%",
    borderRadius: 5,
    minHeight: 100,
    alignSelf: "center",
    background: "#FBFBFB",
    ...elevationShadowStyle,
    marginBottom: 12,
  },

  socialButtons: {
    width: 47,
    height: 47,
    borderRadius: "50%",
    background: "#FBFBFB",
    ...elevationShadowStyle,
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
  },

  tipContainer: {
    alignItems:'center',
    justifyContent:'center', 
    width:50,
    height:35,
    borderRadius:8, 
    marginRight:15, 
    borderWidth:1,
    backgroundColor:'transparent'
  },
  checkoutButtonContainer: {
    position:'absolute', 
    bottom:0,
    width:'100%', 
    flex:1, 
    flexDirection:'row', 
    height:70,
    
    justifyContent:'center',
    alignItems:'center',
    ...elevationShadowStyle,
    },
    modifierItem: {
        borderRadius: 0,
        width: "100%",
        flex: 1,
        flexDirection: "row",
        // height: 45,
        justifyContent: "space-between",
        alignItems: "center",
      },
    modifierContainer: {
        marginVertical: 6,
        marginHorizontal: 25,
        width: "90%",
        alignSelf: "center",

      }
});
