'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var TaskActionsList;
var TaskActionsPage;
var TaskActionsInstance;
var TaskActionsContext;

/* jshint ignore:start */
/**
 * Initialize the TaskActionsList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsList
 *
 * @param {Twilio.Autopilot.V1} version - Version of the resource
 * @param {string} assistantSid -
 *          The SID of the Assistant that is the parent of the Task associated with the resource
 * @param {string} taskSid - The SID of the Task associated with the resource
 */
/* jshint ignore:end */
TaskActionsList = function TaskActionsList(version, assistantSid, taskSid) {
  /* jshint ignore:start */
  /**
   * @function taskActions
   * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext}
   */
  /* jshint ignore:end */
  function TaskActionsListInstance(sid) {
    return TaskActionsListInstance.get(sid);
  }

  TaskActionsListInstance._version = version;
  // Path Solution
  TaskActionsListInstance._solution = {assistantSid: assistantSid, taskSid: taskSid};
  /* jshint ignore:start */
  /**
   * Constructs a task_actions
   *
   * @function get
   * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsList#
   *
   * @returns {Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext}
   */
  /* jshint ignore:end */
  TaskActionsListInstance.get = function get() {
    return new TaskActionsContext(this._version, this._solution.assistantSid, this._solution.taskSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TaskActionsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TaskActionsListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return TaskActionsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TaskActionsPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TaskActionsSolution} solution - Path solution
 *
 * @returns TaskActionsPage
 */
/* jshint ignore:end */
TaskActionsPage = function TaskActionsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TaskActionsPage.prototype, Page.prototype);
TaskActionsPage.prototype.constructor = TaskActionsPage;

/* jshint ignore:start */
/**
 * Build an instance of TaskActionsInstance
 *
 * @function getInstance
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsPage#
 *
 * @param {TaskActionsPayload} payload - Payload response from the API
 *
 * @returns TaskActionsInstance
 */
/* jshint ignore:end */
TaskActionsPage.prototype.getInstance = function getInstance(payload) {
  return new TaskActionsInstance(
    this._version,
    payload,
    this._solution.assistantSid,
    this._solution.taskSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskActionsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskActionsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskActionsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} assistantSid -
 *          The SID of the Assistant that is the parent of the Task associated with the resource
 * @property {string} taskSid - The SID of the Task associated with the resource
 * @property {string} url - The absolute URL of the TaskActions resource
 * @property {object} data -
 *          The JSON string that specifies the actions that instruct the Assistant on how to perform the task
 *
 * @param {V1} version - Version of the resource
 * @param {TaskActionsPayload} payload - The instance payload
 * @param {sid} assistantSid -
 *          The SID of the Assistant that is the parent of the Task associated with the resource
 * @param {sid} taskSid - The SID of the Task associated with the resource
 */
/* jshint ignore:end */
TaskActionsInstance = function TaskActionsInstance(version, payload,
                                                    assistantSid, taskSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assistantSid = payload.assistant_sid; // jshint ignore:line
  this.taskSid = payload.task_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.data = payload.data; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {assistantSid: assistantSid, taskSid: taskSid, };
};

Object.defineProperty(TaskActionsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TaskActionsContext(
          this._version,
          this._solution.assistantSid,
          this._solution.taskSid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TaskActionsInstance
 *
 * @function fetch
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskActionsInstance
 */
/* jshint ignore:end */
TaskActionsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a TaskActionsInstance
 *
 * @function update
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {object} [opts.actions] -
 *          The JSON string that specifies the actions that instruct the Assistant on how to perform the task
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskActionsInstance
 */
/* jshint ignore:end */
TaskActionsInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskActionsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TaskActionsInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TaskActionsContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} assistantSid -
 *          The SID of the Assistant that is the parent of the Task for which the task actions to fetch were defined
 * @param {sid_like} taskSid -
 *          The SID of the Task for which the task actions to fetch were defined
 */
/* jshint ignore:end */
TaskActionsContext = function TaskActionsContext(version, assistantSid, taskSid)
                                                  {
  this._version = version;

  // Path Solution
  this._solution = {assistantSid: assistantSid, taskSid: taskSid, };
  this._uri = `/Assistants/${assistantSid}/Tasks/${taskSid}/Actions`;
};

/* jshint ignore:start */
/**
 * fetch a TaskActionsInstance
 *
 * @function fetch
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskActionsInstance
 */
/* jshint ignore:end */
TaskActionsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskActionsInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.taskSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a TaskActionsInstance
 *
 * @function update
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext#
 *
 * @param {object} [opts] - Options for request
 * @param {object} [opts.actions] -
 *          The JSON string that specifies the actions that instruct the Assistant on how to perform the task
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TaskActionsInstance
 */
/* jshint ignore:end */
TaskActionsContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'Actions': serialize.object(_.get(opts, 'actions'))});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new TaskActionsInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.taskSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.TaskContext.TaskActionsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TaskActionsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TaskActionsContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TaskActionsList: TaskActionsList,
  TaskActionsPage: TaskActionsPage,
  TaskActionsInstance: TaskActionsInstance,
  TaskActionsContext: TaskActionsContext
};
