import React, {useState, useEffect} from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  AsyncStorage,
  Dimensions,
} from "react-native";
import OctI from "@expo/vector-icons/Octicons";
import Material from "@expo/vector-icons/MaterialCommunityIcons";

import SI from "@expo/vector-icons/SimpleLineIcons";
import Modal from "modal-react-native-web";
import Toast from "./toast";
import axios from "axios";
import { style } from "../../assets/style";
import { Button, Icon, Row, Input, Item, Textarea } from "native-base";
import Icono from "@expo/vector-icons/Ionicons";
import moment from "moment";
import Constants from "expo-constants";
import {
  THREE_FRACTION_CURRENCY,
  CHECKOUT_PUB_KEY,
  API_URL,
} from "./../constants/index";
import { percentageHandler } from "./../handlers/percentageHandler";
import i18n from "../i18n";
import _ from "lodash";
import {dateLoader} from "../handlers/utils"
import {useSelector} from "react-redux"
import StyledText from "../components/styledText"
import { FontLetterSpacing } from "./../handlers/utils";


const DateModal = ({
  closeDateModal,
  button_background_color,
  button_text_color,
  dateList,
  onSelectDate,
  timeList,
  selectedDate,
  onSelectTime,
  default_time,
  showWarning,
  block_out_days,
  working_hours,
  working_hour,
  future_date_start_from,
  heading_font_size
}) => {
  const [nonWorkingHours, setNonWorkingHours] = useState(working_hours ?JSON.parse(working_hours).filter(item => !item.checked) : [])
  const design = useSelector(state => state.config.design)

  const isDateDisabled = (item) => {

    if(_.some(nonWorkingHours, ['label', `campaign.${moment(item, 'DD/MM/YYYY').format('dddd').toLowerCase()}`])){
      return true
    }
    let date_from = moment().add(parseInt(future_date_start_from)+1, "days").set({h:0,m:0,s:0,ms:0})
    let today = moment().format("DD/MM/YYYY")
    let item_date = moment(item, 'DD/MM/YYYY')

    if(item_date< date_from && item!=today){
      return true
    }
    if(item==today && !working_hour){
      return true
    }

    return false
  }

  // const dateLoader = (date) => {
  //   let new_date = date
  //   let today = moment().format('DD/MM/YYYY')
  //   let tomorrow = moment().add(1, 'days').format('DD/MM/YYYY')
  //   // console.log(today, tomorrow)
  //   if(today == date){
  //     new_date = "Today"
  //   }if(tomorrow == date){
  //     new_date = "Tomorrow"
  //   }
  //   return new_date
  // }
  const isNotTimePast = (item) => {

    let now = moment()
    let today = now.format('DD/MM/YYYY')
    if(item == "ASAP"){
      console.log("asap")
      if(today==selectedDate){
        console.log("sameeee")
        return true
      }else{
        return false

      }
    }
    // console.log(item,today,selectedDate,  "lllllllll")
    if(today==selectedDate){
      let time = item.split(" - ")[0]
      let moment_time  = moment(time, 'hh:mm a');
      // console.log(time,moment_time,now, "timeeee")
      if(now>moment_time){
        return false
      }else{
        return true
      }


    }
    return true

  }
    return (
      <View style={{ flex: 1, 
      // backdropFilter: "blur(5px)"
      backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}>
        <View
          style={{
            // height: 200,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomColor: "rgba(12, 10, 10, 0.2)",
            flexDirection: "row",
          }}
        ></View>
        <View
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View style={[style.datePickerModal, {backgroundColor:design.popup_background_color}]}>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 3,
                }}
              >
                <StyledText
                                numberOfLines={1}
                                font_color={design.popup_heading_text_color}
                                font_weight={design.popup_heading_font_weight}
                                font_style={design.popup_heading_font_style}
                                text_transform={design.popup_heading_font_transform}
                                font_family={design.popup_heading_font_family}
                                font_size={design.popup_heading_font_size}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{marginLeft: 10}}
                            >
                                {i18n.t("labels.select_date")}
                                </StyledText>

                
              </View>

              <Row style={{marginTop: 20,}} >
                <ScrollView style={{flex:.45}}>
                <View
                  style={{
                    // flexDirection: "row",
                    // width: "100%",
                    // marginTop: 20,
                    // flexWrap: 'wrap',
                    // justifyContent: "center"
                  }}
                >
                 
                    
                    {
                        dateList.map((item, index) => {
                          if(!isDateDisabled(item)){
                            return(
                              <TouchableOpacity  onPress={()=>onSelectDate(item)} style={{
                                  height:50,
                                  width:80, 
                                  backgroundColor: selectedDate == item ? design.popup_button_color:"transparent",
                                  borderWidth: 1,
                                  borderColor: selectedDate == item ? "transparent": design.popup_button_color,
                                  justifyContent:"center",
                                  alignItems: "center",
                                  margin: 5,
                                  borderRadius: 8
                              }}>
                                  <StyledText

                                font_color={selectedDate == item ? design.popup_button_text_color: design.popup_button_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                font_size={design.popup_button_font_size}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{}}
                            >
                                {dateLoader(item)}
                                </StyledText>
                                      
                              </TouchableOpacity>
                            )
                          }

                        })
                    }
                </View>
                </ScrollView>

                <ScrollView style={{flex:1}}>
              <View
                style={{
                  // flexDirection: "row",
                  // width: "100%",
                  // marginTop: 20,
                  // flexWrap: 'wrap',
                  // justifyContent: "center"
                }}
              >
                  
                  {
                      timeList.map((item, index) => {
                        if(isNotTimePast(item)){
                          return(
                            <TouchableOpacity onPress={()=>onSelectTime(item)} style={{
                                height:50,
                                // width:80, 
                                backgroundColor: default_time == item ? design.popup_button_color:"transparent",
                                color: default_time == item ? design.popup_button_text_color: design.popup_button_color,
                                borderColor: default_time == item ? "transparent": design.popup_button_color,
                                borderWidth: 1,
                                justifyContent:"center",
                                alignItems: "center",
                                margin: 5,
                                borderRadius: 8
                            }}>
                              <StyledText
                                font_color={default_time == item ? design.popup_button_text_color: design.popup_button_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                font_size={design.popup_button_font_size}
                                style={{}}
                            >
                                {item}
                                </StyledText>
                                   
                            </TouchableOpacity>
                          )
                        }

                      })
                  }
              </View>
              </ScrollView>
              
              </Row>
              <Button onPress={() => {
                if(!default_time || !selectedDate){
                  return showWarning()
                }else{
                  closeDateModal()
                }
              }} style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      width: "100%",
                      backgroundColor:design.popup_button_color,

                      // position:'absolute',
                      // bottom:0
                    }}>
                      <StyledText
                                font_color={design.popup_button_text_color}
                                font_weight={design.popup_button_font_weight}
                                font_style={design.popup_button_font_style}
                                text_transform={design.popup_button_font_transform}
                                font_family={design.popup_button_font_family}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{}}
                            >
                                {i18n.t("labels.submit")}
                                </StyledText>
              </Button>
            <TouchableOpacity
              style={{ position: "absolute", top: 5, right: 5 }}
              onPress={() => closeDateModal()}
            >
              <View style={[style.closeButtonModal,{backgroundColor:design.popup_button_color}]}>
              <i
                     style={{ color: design.popup_button_text_color, fontSize: 25,marginTop: 2 }}
                  class="fas fa-times"
                 ></i>
              </View>
            </TouchableOpacity>

          </View>

        </View>

      </View>
    );
 
};

export default DateModal;
