'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var UserInfoList;
var UserInfoPage;
var UserInfoInstance;
var UserInfoContext;

/* jshint ignore:start */
/**
 * Initialize the UserInfoList
 *
 * @constructor Twilio.Oauth.V1.UserInfoList
 *
 * @param {Twilio.Oauth.V1} version - Version of the resource
 */
/* jshint ignore:end */
UserInfoList = function UserInfoList(version) {
  /* jshint ignore:start */
  /**
   * @function userInfo
   * @memberof Twilio.Oauth.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Oauth.V1.UserInfoContext}
   */
  /* jshint ignore:end */
  function UserInfoListInstance(sid) {
    return UserInfoListInstance.get(sid);
  }

  UserInfoListInstance._version = version;
  // Path Solution
  UserInfoListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a user_info
   *
   * @function get
   * @memberof Twilio.Oauth.V1.UserInfoList#
   *
   * @returns {Twilio.Oauth.V1.UserInfoContext}
   */
  /* jshint ignore:end */
  UserInfoListInstance.get = function get() {
    return new UserInfoContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Oauth.V1.UserInfoList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  UserInfoListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  UserInfoListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return UserInfoListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the UserInfoPage
 *
 * @constructor Twilio.Oauth.V1.UserInfoPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {UserInfoSolution} solution - Path solution
 *
 * @returns UserInfoPage
 */
/* jshint ignore:end */
UserInfoPage = function UserInfoPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(UserInfoPage.prototype, Page.prototype);
UserInfoPage.prototype.constructor = UserInfoPage;

/* jshint ignore:start */
/**
 * Build an instance of UserInfoInstance
 *
 * @function getInstance
 * @memberof Twilio.Oauth.V1.UserInfoPage#
 *
 * @param {UserInfoPayload} payload - Payload response from the API
 *
 * @returns UserInfoInstance
 */
/* jshint ignore:end */
UserInfoPage.prototype.getInstance = function getInstance(payload) {
  return new UserInfoInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.UserInfoPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
UserInfoPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UserInfoPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the UserInfoContext
 *
 * @constructor Twilio.Oauth.V1.UserInfoInstance
 *
 * @property {string} userSid - The user sid
 * @property {string} firstName - The first name of the end-user
 * @property {string} lastName - The last name of the end-user
 * @property {string} friendlyName - The friendly name of the end-user
 * @property {string} email - The end-user's preferred email address
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {UserInfoPayload} payload - The instance payload
 */
/* jshint ignore:end */
UserInfoInstance = function UserInfoInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.userSid = payload.user_sid; // jshint ignore:line
  this.firstName = payload.first_name; // jshint ignore:line
  this.lastName = payload.last_name; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.email = payload.email; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(UserInfoInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new UserInfoContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a UserInfoInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.UserInfoInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed UserInfoInstance
 */
/* jshint ignore:end */
UserInfoInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.UserInfoInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
UserInfoInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UserInfoInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the UserInfoContext
 *
 * @constructor Twilio.Oauth.V1.UserInfoContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
UserInfoContext = function UserInfoContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/userinfo`;
};

/* jshint ignore:start */
/**
 * fetch a UserInfoInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.UserInfoContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed UserInfoInstance
 */
/* jshint ignore:end */
UserInfoContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new UserInfoInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.UserInfoContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
UserInfoContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

UserInfoContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  UserInfoList: UserInfoList,
  UserInfoPage: UserInfoPage,
  UserInfoInstance: UserInfoInstance,
  UserInfoContext: UserInfoContext
};
