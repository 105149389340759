'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var HighriskSpecialPrefixList = require(
    './country/highriskSpecialPrefix').HighriskSpecialPrefixList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var CountryList;
var CountryPage;
var CountryInstance;
var CountryContext;

/* jshint ignore:start */
/**
 * Initialize the CountryList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.CountryList
 *
 * @param {Twilio.Voice.V1} version - Version of the resource
 */
/* jshint ignore:end */
CountryList = function CountryList(version) {
  /* jshint ignore:start */
  /**
   * @function countries
   * @memberof Twilio.Voice.V1.DialingPermissionsContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Voice.V1.DialingPermissionsContext.CountryContext}
   */
  /* jshint ignore:end */
  function CountryListInstance(sid) {
    return CountryListInstance.get(sid);
  }

  CountryListInstance._version = version;
  // Path Solution
  CountryListInstance._solution = {};
  CountryListInstance._uri = `/DialingPermissions/Countries`;
  /* jshint ignore:start */
  /**
   * Streams CountryInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.isoCode] -
   *          Filter to retrieve the country permissions by specifying the ISO country code
   * @param {string} [opts.continent] -
   *          Filter to retrieve the country permissions by specifying the continent
   * @param {string} [opts.countryCode] - Country code filter
   * @param {boolean} [opts.lowRiskNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to low-risk numbers enabled
   * @param {boolean} [opts.highRiskSpecialNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk special service numbers enabled
   * @param {boolean} [opts.highRiskTollfraudNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk toll fraud numbers enabled
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  CountryListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists CountryInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.isoCode] -
   *          Filter to retrieve the country permissions by specifying the ISO country code
   * @param {string} [opts.continent] -
   *          Filter to retrieve the country permissions by specifying the continent
   * @param {string} [opts.countryCode] - Country code filter
   * @param {boolean} [opts.lowRiskNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to low-risk numbers enabled
   * @param {boolean} [opts.highRiskSpecialNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk special service numbers enabled
   * @param {boolean} [opts.highRiskTollfraudNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk toll fraud numbers enabled
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CountryListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of CountryInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.isoCode] -
   *          Filter to retrieve the country permissions by specifying the ISO country code
   * @param {string} [opts.continent] -
   *          Filter to retrieve the country permissions by specifying the continent
   * @param {string} [opts.countryCode] - Country code filter
   * @param {boolean} [opts.lowRiskNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to low-risk numbers enabled
   * @param {boolean} [opts.highRiskSpecialNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk special service numbers enabled
   * @param {boolean} [opts.highRiskTollfraudNumbersEnabled] -
   *          Filter to retrieve the country permissions with dialing to high-risk toll fraud numbers enabled
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CountryListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'IsoCode': _.get(opts, 'isoCode'),
      'Continent': _.get(opts, 'continent'),
      'CountryCode': _.get(opts, 'countryCode'),
      'LowRiskNumbersEnabled': serialize.bool(_.get(opts, 'lowRiskNumbersEnabled')),
      'HighRiskSpecialNumbersEnabled': serialize.bool(_.get(opts, 'highRiskSpecialNumbersEnabled')),
      'HighRiskTollfraudNumbersEnabled': serialize.bool(_.get(opts, 'highRiskTollfraudNumbersEnabled')),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new CountryPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of CountryInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CountryListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new CountryPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a country
   *
   * @function get
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @param {string} isoCode - The ISO country code
   *
   * @returns {Twilio.Voice.V1.DialingPermissionsContext.CountryContext}
   */
  /* jshint ignore:end */
  CountryListInstance.get = function get(isoCode) {
    return new CountryContext(this._version, isoCode);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  CountryListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  CountryListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return CountryListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the CountryPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.CountryPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {CountrySolution} solution - Path solution
 *
 * @returns CountryPage
 */
/* jshint ignore:end */
CountryPage = function CountryPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(CountryPage.prototype, Page.prototype);
CountryPage.prototype.constructor = CountryPage;

/* jshint ignore:start */
/**
 * Build an instance of CountryInstance
 *
 * @function getInstance
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryPage#
 *
 * @param {CountryPayload} payload - Payload response from the API
 *
 * @returns CountryInstance
 */
/* jshint ignore:end */
CountryPage.prototype.getInstance = function getInstance(payload) {
  return new CountryInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
CountryPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CountryPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CountryContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.CountryInstance
 *
 * @property {string} isoCode - The ISO country code
 * @property {string} name - The name of the country
 * @property {string} continent -
 *          The name of the continent in which the country is located
 * @property {string} countryCodes - The E.164 assigned country codes(s)
 * @property {boolean} lowRiskNumbersEnabled -
 *          Whether dialing to low-risk numbers is enabled
 * @property {boolean} highRiskSpecialNumbersEnabled -
 *          Whether dialing to high-risk special services numbers is enabled
 * @property {boolean} highRiskTollfraudNumbersEnabled -
 *          Whether dialing to high-risk toll fraud numbers is enabled, else `false`
 * @property {string} url - The absolute URL of this resource
 * @property {string} links - A list of URLs related to this resource
 *
 * @param {V1} version - Version of the resource
 * @param {CountryPayload} payload - The instance payload
 * @param {iso_country_code} isoCode - The ISO country code
 */
/* jshint ignore:end */
CountryInstance = function CountryInstance(version, payload, isoCode) {
  this._version = version;

  // Marshaled Properties
  this.isoCode = payload.iso_code; // jshint ignore:line
  this.name = payload.name; // jshint ignore:line
  this.continent = payload.continent; // jshint ignore:line
  this.countryCodes = payload.country_codes; // jshint ignore:line
  this.lowRiskNumbersEnabled = payload.low_risk_numbers_enabled; // jshint ignore:line
  this.highRiskSpecialNumbersEnabled = payload.high_risk_special_numbers_enabled; // jshint ignore:line
  this.highRiskTollfraudNumbersEnabled = payload.high_risk_tollfraud_numbers_enabled; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {isoCode: isoCode || this.isoCode, };
};

Object.defineProperty(CountryInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new CountryContext(this._version, this._solution.isoCode);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a CountryInstance
 *
 * @function fetch
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CountryInstance
 */
/* jshint ignore:end */
CountryInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Access the highriskSpecialPrefixes
 *
 * @function highriskSpecialPrefixes
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryInstance#
 *
 * @returns {Twilio.Voice.V1.DialingPermissionsContext.CountryContext.HighriskSpecialPrefixList}
 */
/* jshint ignore:end */
CountryInstance.prototype.highriskSpecialPrefixes = function
    highriskSpecialPrefixes() {
  return this._proxy.highriskSpecialPrefixes;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
CountryInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CountryInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CountryContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.CountryContext
 *
 * @property {Twilio.Voice.V1.DialingPermissionsContext.CountryContext.HighriskSpecialPrefixList} highriskSpecialPrefixes -
 *          highriskSpecialPrefixes resource
 *
 * @param {V1} version - Version of the resource
 * @param {iso_country_code} isoCode - The ISO country code
 */
/* jshint ignore:end */
CountryContext = function CountryContext(version, isoCode) {
  this._version = version;

  // Path Solution
  this._solution = {isoCode: isoCode, };
  this._uri = `/DialingPermissions/Countries/${isoCode}`;

  // Dependents
  this._highriskSpecialPrefixes = undefined;
};

/* jshint ignore:start */
/**
 * fetch a CountryInstance
 *
 * @function fetch
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CountryInstance
 */
/* jshint ignore:end */
CountryContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new CountryInstance(this._version, payload, this._solution.isoCode));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(CountryContext.prototype,
  'highriskSpecialPrefixes', {
    get: function() {
      if (!this._highriskSpecialPrefixes) {
        this._highriskSpecialPrefixes = new HighriskSpecialPrefixList(
          this._version,
          this._solution.isoCode
        );
      }
      return this._highriskSpecialPrefixes;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.CountryContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
CountryContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

CountryContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  CountryList: CountryList,
  CountryPage: CountryPage,
  CountryInstance: CountryInstance,
  CountryContext: CountryContext
};
