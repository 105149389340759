import {ADD_CONFIG, ADD_TABLE, SET_DELIVERY_MODE, SET_PLATFORM, SET_VENUES, SET_DESIGN, SET_FEEDBACK_MODAL} from "../actionTypes";


export const addConfig = (config) => {
    return{
        type: ADD_CONFIG,
        config: config,

    };
};

export const addTable = (table) => {
    return{
        type: ADD_TABLE,
        table: table,

    };
};

export const setDeliveryMode = (mode) => {
    return{
        type: SET_DELIVERY_MODE,
        mode: mode
    }
}


export const setPlatform = (platform) => {
    return{
        type: SET_PLATFORM,
        platform: platform
    }
}

export const setVenues = (venues) => {
    return{
        type: SET_VENUES,
        venues
    }
}

export const setDesign = (design) => {
    return{
        type: SET_DESIGN,
        design
    }
}


export const feedbackModal = (feedback) => {
    console.log("hereeee 222222222", feedback, "")
    return{
        type: SET_FEEDBACK_MODAL,
        feedback: feedback,

    };
};
