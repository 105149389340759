import { createStore, combineReducers, compose } from "redux";
import MenuReducer from "./reducers/menus";
import CartReducer from "./reducers/cart";
import ConfigReducer from "./reducers/config";
import LanguageReducer from "./reducers/languages";
import PaymentReducer from "./reducers/payment";
import LocationReducer from "./reducers/location";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import NumberReducer from "./reducers/mobile";
import MessageReducer from "./reducers/messages";
import FontFaceReducer from "./reducers/fontFace";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "payment",
    "menus",
    "config",
    "cart",
    "cart_count",
    "total_price",
    "messages"
  ],

  // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const rootReducer = combineReducers({
    cart: CartReducer,
    menus:MenuReducer,
    config:ConfigReducer,
    languages: LanguageReducer,
    payment: PaymentReducer,
    location: LocationReducer,
    number: NumberReducer,
    messages: MessageReducer,
    fontFace:FontFaceReducer

});

let composeEnhancers = compose;

if (__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// const configureStore = () => {
//     return createStore(rootReducer,composeEnhancers());
// };
//
// export default configureStore;

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer);
export const persistor = persistStore(store);
