import React, {Component} from 'react';
import {View, Text} from "react-native";

import {Icon} from 'native-base'
import {style} from "../../assets/style";





class ProfileButton extends React.Component{







    render(){
        return(
                <View style={[style.fontButton,{backgroundColor:this.props.backgroundColor,border:this.props.border_color}]}>
                    {/* <FA name="concierge-bell" style={{fontSize: 30, color: this.props.color}}/> */}
                    {/*<Text style={{fontSize: 30, color: props.color}}>{props.default_language}</Text>*/}
                    {/* <Icon type="FontAwesome5" name="user-alt" style={{fontSize: 24, color: this.props.color}}/> */}
                    <i style={{color: this.props.color, fontSize:25}} class="fas fa-user-alt"></i>
                </View>
        );
    }
};
export default ProfileButton;


