import {
    ADD_VERIFIED_NUMBER,
    ADD_USER,
    SET_TOKEN,
    BACK_TO_PROFILE
} from "../actionTypes";


const initialState = {
       number : '',
       user:'',
       token: '', 
       backToProfile: false
       
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_VERIFIED_NUMBER:
            return {
                ...state,
                number: action.number
            };
        case ADD_USER:
            return{
                ...state,
                user: action.user
            }
        case SET_TOKEN:
            return{
                ...state,
                token: action.token
            }
        case BACK_TO_PROFILE:
            return{
                ...state,
                backToProfile: action.is_profile_page
            }
        default:
            return state;
    }
};

export default reducer;