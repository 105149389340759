'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var SinkValidateList;
var SinkValidatePage;
var SinkValidateInstance;

/* jshint ignore:start */
/**
 * Initialize the SinkValidateList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkValidateList
 *
 * @param {Twilio.Events.V1} version - Version of the resource
 * @param {string} sid - A string that uniquely identifies this Sink.
 */
/* jshint ignore:end */
SinkValidateList = function SinkValidateList(version, sid) {
  /* jshint ignore:start */
  /**
   * @function sinkValidate
   * @memberof Twilio.Events.V1.SinkContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Events.V1.SinkContext.SinkValidateContext}
   */
  /* jshint ignore:end */
  function SinkValidateListInstance(sid) {
    return SinkValidateListInstance.get(sid);
  }

  SinkValidateListInstance._version = version;
  // Path Solution
  SinkValidateListInstance._solution = {sid: sid};
  SinkValidateListInstance._uri = `/Sinks/${sid}/Validate`;
  /* jshint ignore:start */
  /**
   * create a SinkValidateInstance
   *
   * @function create
   * @memberof Twilio.Events.V1.SinkContext.SinkValidateList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.testId -
   *          A string that uniquely identifies the test event for a Sink being validated.
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SinkValidateInstance
   */
  /* jshint ignore:end */
  SinkValidateListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['testId'])) {
      throw new Error('Required parameter "opts[\'testId\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'TestId': _.get(opts, 'testId')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SinkValidateInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Events.V1.SinkContext.SinkValidateList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SinkValidateListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SinkValidateListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return SinkValidateListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SinkValidatePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkValidatePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SinkValidateSolution} solution - Path solution
 *
 * @returns SinkValidatePage
 */
/* jshint ignore:end */
SinkValidatePage = function SinkValidatePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SinkValidatePage.prototype, Page.prototype);
SinkValidatePage.prototype.constructor = SinkValidatePage;

/* jshint ignore:start */
/**
 * Build an instance of SinkValidateInstance
 *
 * @function getInstance
 * @memberof Twilio.Events.V1.SinkContext.SinkValidatePage#
 *
 * @param {SinkValidatePayload} payload - Payload response from the API
 *
 * @returns SinkValidateInstance
 */
/* jshint ignore:end */
SinkValidatePage.prototype.getInstance = function getInstance(payload) {
  return new SinkValidateInstance(this._version, payload, this._solution.sid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.SinkContext.SinkValidatePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SinkValidatePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SinkValidatePage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SinkValidateContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkValidateInstance
 *
 * @property {string} result -
 *          Feedback indicating whether the given Sink was validated.
 *
 * @param {V1} version - Version of the resource
 * @param {SinkValidatePayload} payload - The instance payload
 * @param {sid} sid - A string that uniquely identifies this Sink.
 */
/* jshint ignore:end */
SinkValidateInstance = function SinkValidateInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.result = payload.result; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.SinkContext.SinkValidateInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SinkValidateInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SinkValidateInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SinkValidateList: SinkValidateList,
  SinkValidatePage: SinkValidatePage,
  SinkValidateInstance: SinkValidateInstance
};
