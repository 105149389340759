import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  AsyncStorage,
  Dimensions,
} from "react-native";
import OctI from "@expo/vector-icons/Octicons";
import Material from "@expo/vector-icons/MaterialCommunityIcons";

import SI from "@expo/vector-icons/SimpleLineIcons";
import Modal from "modal-react-native-web";
import Toast from "./toast";
import axios from "axios";
import { style } from "../../assets/style";
import { Button, Icon, Row, Input, Item, Textarea } from "native-base";
import Icono from "@expo/vector-icons/Ionicons";
import moment from "moment";
import Constants from "expo-constants";
import {
  THREE_FRACTION_CURRENCY,
  CHECKOUT_PUB_KEY,
  API_URL,
} from "./../constants/index";
import { percentageHandler } from "./../handlers/percentageHandler";
import i18n from "../i18n";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from "./../handlers/utils";

const OrderSuccessModal = ({
  order_response,
  platform,
  menu_type,
  config,
  orderScreen,
  closeSuccessModal,
  design
}) => {
  if (order_response) {
    return (
      <View style={{ flex: 1,
      //  backdropFilter: "blur(5px)" 
       backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}>
        <View
          style={{
            height: 40,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomColor: "rgba(12, 10, 10, 0.2)",
            flexDirection: "row",
          }}
        ></View>
        <View
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: design.popup_background_color
          }}
        >
          <View style={[style.ticket,{backgroundColor: design.popup_background_color}]}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 35,
                }}

              >
                <StyledText 
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                style={{ 
                   marginLeft: 10, 
                 }}
                >
                  {i18n.t("checkout.your_order_ticket")}
                </StyledText>
                <StyledText
                    font_color={design.popup_text_color}
                    font_weight={"bold"}
                    font_style={design.popup_text_font_style}
                    text_transform={design.popup_text_font_transform}
                    font_family={design.popup_text_font_family}
                    font_size={design.popup_text_font_size}
                    letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                
                 style={{
                 marginRight: 10, 
                  }}>
                  # {order_response.ticket}
                </StyledText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 9,
                }}
              >
                <StyledText 
                  font_color={design.popup_text_color}
                  font_weight={"bold"}
                  font_style={design.popup_text_font_style}
                  text_transform={design.popup_text_font_transform}
                  font_family={design.popup_text_font_family}
                  font_size={design.popup_text_font_size}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
  
                style={{ 
                  fontWeight: "bold", 
                marginLeft: 10, 
              }}
                >
                  {i18n.t("checkout.payment_mode")}
                </StyledText>
                <StyledText 
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                style={{ 
                  marginRight: 10, 
              }}
                >
                  {order_response.payment_mode == 2
                    ? i18n.t("checkout.online_payment")
                    : order_response.payment_mode == 4
                    ? i18n.t("order.not_applicable")
                    : `${
                        order_response.payment_mode == 5
                          ? i18n.t("order.card")
                          : order_response.payment_mode == 6
                          ? i18n.t("order.room_charge")
                          : i18n.t("checkout.cash")
                      }${platform != "qr" ? " on " : ""}${
                        platform != "qr" ? menu_type : ""
                      }`}
                </StyledText>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 9,
                }}
              ></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 9,
                }}
              >
                <StyledText 
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                style={{
                   marginLeft: 10, 
                }}>
                  {i18n.t("amount.discount")}{" "}
                </StyledText>
                <StyledText
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                 style={{ 
                    marginRight: 10, 
                    }}>
                  {config.currency}{" "}
                  {order_response.amount_discount
                    ? order_response.amount_discount
                    : percentageHandler.priceLoader(0, config.currency, config.display_fraction)}
                </StyledText>
              </View>
              {menu_type == "delivery" && platform != "qr" ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 9,
                  }}
                >
                  <StyledText 
                  font_color={design.popup_text_color}
                  font_weight={"bold"}
                  font_style={design.popup_text_font_style}
                  text_transform={design.popup_text_font_transform}
                  font_family={design.popup_text_font_family}
                  font_size={design.popup_text_font_size}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
  
                  style={{ 
                     marginLeft: 10, 
                    }}
                  >
                    {i18n.t("order.delivery_charge")}{" "}
                  </StyledText>
                  <StyledText 
                  font_color={design.popup_text_color}
                  font_weight={"bold"}
                  font_style={design.popup_text_font_style}
                  text_transform={design.popup_text_font_transform}
                  font_family={design.popup_text_font_family}
                  font_size={design.popup_text_font_size}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
  
                  style={{ 
                    marginRight: 10, 
                    }}>
                    {config.currency}{" "}
                    {config.delivery_charge
                      ? percentageHandler.priceLoader(
                          parseFloat(config.delivery_charge),
                          config.currency, config.display_fraction
                          
                        )
                      : percentageHandler.priceLoader(0, config.currency, config.display_fraction)}
                  </StyledText>
                </View>
              ) : null}

              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  position: "absolute",
                  bottom: 10,
                }}
              >
                <StyledText 
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                style={{ 
                  marginLeft: 10, 
                   }}>
                  {i18n.t("amount.total")}
                </StyledText>

                <StyledText
                font_color={design.popup_text_color}
                font_weight={"bold"}
                font_style={design.popup_text_font_style}
                text_transform={design.popup_text_font_transform}
                font_family={design.popup_text_font_family}
                font_size={design.popup_text_font_size}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                 style={{ 
                   marginRight: 10, 
                   }}>
                  {config.currency} {order_response.total}
                </StyledText>
              </Row>
            </View>
            <View style={{ justifyContent: "flex-end", flex: 1 }}>
              <Button
                onPress={() => orderScreen()}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 27,
                  backgroundColor: design.popup_button_color,
                  width:"100%"
                }}
              >
                <StyledText
                 font_color={design.popup_button_text_color}
                 font_weight={design.popup_button_font_weight}
                 font_style={design.popup_button_font_style}
                 text_transform={design.popup_button_font_transform}
                 font_family={design.popup_button_font_family}
                 font_size={design.popup_button_font_size}
                 letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                  style={{}}
                >
                  {i18n.t("checkout.view_order")}
                </StyledText>
              </Button>
            </View>

            <TouchableOpacity
              style={{ position: "absolute", top: 5, right: 5 }}
              onPress={() => closeSuccessModal()}
            >
              <View style={[style.closeButtonModal]}>
              <i
                     style={{ color: "#5d6670", fontSize: 25,marginTop: 2 }}
                  class="fas fa-times"
                 ></i>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default OrderSuccessModal;
