import {LANGUAGES, DEFAULT_LANGUAGE} from './../actionTypes';




export const addDefaultLanguage = (language) => {
    return{
        type: DEFAULT_LANGUAGE,
        language: language
    }
};



export const addLanguage = (languages) => {
    return{
        type: LANGUAGES,
        languages: languages
    }
};