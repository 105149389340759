'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SipDomainList;
var SipDomainPage;
var SipDomainInstance;
var SipDomainContext;

/* jshint ignore:start */
/**
 * Initialize the SipDomainList
 *
 * @constructor Twilio.Routes.V2.SipDomainList
 *
 * @param {Twilio.Routes.V2} version - Version of the resource
 */
/* jshint ignore:end */
SipDomainList = function SipDomainList(version) {
  /* jshint ignore:start */
  /**
   * @function sipDomains
   * @memberof Twilio.Routes.V2#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Routes.V2.SipDomainContext}
   */
  /* jshint ignore:end */
  function SipDomainListInstance(sid) {
    return SipDomainListInstance.get(sid);
  }

  SipDomainListInstance._version = version;
  // Path Solution
  SipDomainListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a sip_domain
   *
   * @function get
   * @memberof Twilio.Routes.V2.SipDomainList#
   *
   * @param {string} sipDomain - The sip_domain
   *
   * @returns {Twilio.Routes.V2.SipDomainContext}
   */
  /* jshint ignore:end */
  SipDomainListInstance.get = function get(sipDomain) {
    return new SipDomainContext(this._version, sipDomain);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Routes.V2.SipDomainList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SipDomainListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SipDomainListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SipDomainListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SipDomainPage
 *
 * @constructor Twilio.Routes.V2.SipDomainPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SipDomainSolution} solution - Path solution
 *
 * @returns SipDomainPage
 */
/* jshint ignore:end */
SipDomainPage = function SipDomainPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SipDomainPage.prototype, Page.prototype);
SipDomainPage.prototype.constructor = SipDomainPage;

/* jshint ignore:start */
/**
 * Build an instance of SipDomainInstance
 *
 * @function getInstance
 * @memberof Twilio.Routes.V2.SipDomainPage#
 *
 * @param {SipDomainPayload} payload - Payload response from the API
 *
 * @returns SipDomainInstance
 */
/* jshint ignore:end */
SipDomainPage.prototype.getInstance = function getInstance(payload) {
  return new SipDomainInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.SipDomainPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SipDomainPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SipDomainPage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SipDomainContext
 *
 * @constructor Twilio.Routes.V2.SipDomainInstance
 *
 * @property {string} sipDomain - The sip_domain
 * @property {string} url - The url
 * @property {string} sid - The sid
 * @property {string} accountSid - The account_sid
 * @property {string} friendlyName - The friendly_name
 * @property {string} voiceRegion - The voice_region
 * @property {Date} dateCreated - The date_created
 * @property {Date} dateUpdated - The date_updated
 *
 * @param {V2} version - Version of the resource
 * @param {SipDomainPayload} payload - The instance payload
 * @param {string} sipDomain - The sip_domain
 */
/* jshint ignore:end */
SipDomainInstance = function SipDomainInstance(version, payload, sipDomain) {
  this._version = version;

  // Marshaled Properties
  this.sipDomain = payload.sip_domain; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.voiceRegion = payload.voice_region; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sipDomain: sipDomain || this.sipDomain, };
};

Object.defineProperty(SipDomainInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SipDomainContext(this._version, this._solution.sipDomain);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a SipDomainInstance
 *
 * @function update
 * @memberof Twilio.Routes.V2.SipDomainInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.voiceRegion] - The voice_region
 * @param {string} [opts.friendlyName] - The friendly_name
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SipDomainInstance
 */
/* jshint ignore:end */
SipDomainInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a SipDomainInstance
 *
 * @function fetch
 * @memberof Twilio.Routes.V2.SipDomainInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SipDomainInstance
 */
/* jshint ignore:end */
SipDomainInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.SipDomainInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SipDomainInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SipDomainInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SipDomainContext
 *
 * @constructor Twilio.Routes.V2.SipDomainContext
 *
 * @param {V2} version - Version of the resource
 * @param {string} sipDomain - The sip_domain
 */
/* jshint ignore:end */
SipDomainContext = function SipDomainContext(version, sipDomain) {
  this._version = version;

  // Path Solution
  this._solution = {sipDomain: sipDomain, };
  this._uri = `/SipDomains/${sipDomain}`;
};

/* jshint ignore:start */
/**
 * update a SipDomainInstance
 *
 * @function update
 * @memberof Twilio.Routes.V2.SipDomainContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.voiceRegion] - The voice_region
 * @param {string} [opts.friendlyName] - The friendly_name
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SipDomainInstance
 */
/* jshint ignore:end */
SipDomainContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'VoiceRegion': _.get(opts, 'voiceRegion'),
    'FriendlyName': _.get(opts, 'friendlyName')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SipDomainInstance(this._version, payload, this._solution.sipDomain));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a SipDomainInstance
 *
 * @function fetch
 * @memberof Twilio.Routes.V2.SipDomainContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SipDomainInstance
 */
/* jshint ignore:end */
SipDomainContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SipDomainInstance(this._version, payload, this._solution.sipDomain));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.SipDomainContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SipDomainContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SipDomainContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SipDomainList: SipDomainList,
  SipDomainPage: SipDomainPage,
  SipDomainInstance: SipDomainInstance,
  SipDomainContext: SipDomainContext
};
