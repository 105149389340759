import React from 'react'
import {View} from 'react-native';




const CardBorder = (props) => {
    return(
        <View
            style={{
                borderBottomColor: '#B2BEB5',
                width: "100%",
                borderBottomWidth: .5,
                marginTop: 10,
                marginBottom: 10
            }}
        />
    )
}

export default CardBorder