'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var BrandsInformationList;
var BrandsInformationPage;
var BrandsInformationInstance;
var BrandsInformationContext;

/* jshint ignore:start */
/**
 * Initialize the BrandsInformationList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.TrustedComms.BrandsInformationList
 *
 * @param {Twilio.Preview.TrustedComms} version - Version of the resource
 */
/* jshint ignore:end */
BrandsInformationList = function BrandsInformationList(version) {
  /* jshint ignore:start */
  /**
   * @function brandsInformation
   * @memberof Twilio.Preview.TrustedComms#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Preview.TrustedComms.BrandsInformationContext}
   */
  /* jshint ignore:end */
  function BrandsInformationListInstance(sid) {
    return BrandsInformationListInstance.get(sid);
  }

  BrandsInformationListInstance._version = version;
  // Path Solution
  BrandsInformationListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a brands_information
   *
   * @function get
   * @memberof Twilio.Preview.TrustedComms.BrandsInformationList#
   *
   * @returns {Twilio.Preview.TrustedComms.BrandsInformationContext}
   */
  /* jshint ignore:end */
  BrandsInformationListInstance.get = function get() {
    return new BrandsInformationContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Preview.TrustedComms.BrandsInformationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  BrandsInformationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  BrandsInformationListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return BrandsInformationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the BrandsInformationPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.TrustedComms.BrandsInformationPage
 *
 * @param {TrustedComms} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {BrandsInformationSolution} solution - Path solution
 *
 * @returns BrandsInformationPage
 */
/* jshint ignore:end */
BrandsInformationPage = function BrandsInformationPage(version, response,
                                                        solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(BrandsInformationPage.prototype, Page.prototype);
BrandsInformationPage.prototype.constructor = BrandsInformationPage;

/* jshint ignore:start */
/**
 * Build an instance of BrandsInformationInstance
 *
 * @function getInstance
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationPage#
 *
 * @param {BrandsInformationPayload} payload - Payload response from the API
 *
 * @returns BrandsInformationInstance
 */
/* jshint ignore:end */
BrandsInformationPage.prototype.getInstance = function getInstance(payload) {
  return new BrandsInformationInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandsInformationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BrandsInformationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BrandsInformationContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.TrustedComms.BrandsInformationInstance
 *
 * @property {Date} updateTime - Creation time of the information retrieved
 * @property {string} fileLink - The URL to the brands information
 * @property {string} fileLinkTtlInSeconds - How long will be the `file_link` valid
 * @property {string} url - The URL of this resource
 *
 * @param {TrustedComms} version - Version of the resource
 * @param {BrandsInformationPayload} payload - The instance payload
 */
/* jshint ignore:end */
BrandsInformationInstance = function BrandsInformationInstance(version, payload)
    {
  this._version = version;

  // Marshaled Properties
  this.updateTime = deserialize.iso8601DateTime(payload.update_time); // jshint ignore:line
  this.fileLink = payload.file_link; // jshint ignore:line
  this.fileLinkTtlInSeconds = payload.file_link_ttl_in_seconds; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(BrandsInformationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new BrandsInformationContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a BrandsInformationInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.ifNoneMatch] - Standard `If-None-Match` HTTP header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandsInformationInstance
 */
/* jshint ignore:end */
BrandsInformationInstance.prototype.fetch = function fetch(opts, callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandsInformationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BrandsInformationInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BrandsInformationContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.TrustedComms.BrandsInformationContext
 *
 * @param {TrustedComms} version - Version of the resource
 */
/* jshint ignore:end */
BrandsInformationContext = function BrandsInformationContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/BrandsInformation`;
};

/* jshint ignore:start */
/**
 * fetch a BrandsInformationInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.ifNoneMatch] - Standard `If-None-Match` HTTP header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandsInformationInstance
 */
/* jshint ignore:end */
BrandsInformationContext.prototype.fetch = function fetch(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var headers = values.of({'If-None-Match': _.get(opts, 'ifNoneMatch')});

  var promise = this._version.fetch({uri: this._uri, method: 'GET', headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new BrandsInformationInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.TrustedComms.BrandsInformationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandsInformationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

BrandsInformationContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  BrandsInformationList: BrandsInformationList,
  BrandsInformationPage: BrandsInformationPage,
  BrandsInformationInstance: BrandsInformationInstance,
  BrandsInformationContext: BrandsInformationContext
};
