import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ChatWindow from './ChatWindow';
import launcherIcon from './../assets/logo-no-bg.svg';
import incomingMessageSound from './../assets/sounds/notification.mp3';
import launcherIconActive from './../assets/close-icon.png';
import Constants from "expo-constants";

class Launcher extends Component {

  constructor() {
    super();
    this.state = {
      launcherIcon,
      isOpen: false
    };
  }

  checkIfNewMessage = () => {
    this.playIncomingMessageSound();
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.mute) { return; }
  //   const nextMessage = nextProps.messageList[nextProps.messageList.length - 1];
  //   console.log(nextMessage,nextProps,this.props, '1111' )
  //   let isIncoming = false
  //   if(nextMessage && nextMessage.sender && nextMessage.sender.device_id !== Constants.deviceId){
  //     isIncoming = true
  //   }
  //   // (nextMessage || {}).sender ? (nextMessage || {}).sender.device_id !== Constants.deviceId : false;

  //   const isNew = nextProps.messageList.length > this.props.messageList.length;
  //   console.log(isIncoming,isNew, 'playyyyyyyy')

  //   if (isIncoming && isNew) {
  //     console.log('playyyyyyyy')
  //     this.playIncomingMessageSound();
  //   }
  // }

  playIncomingMessageSound() {
    var audio = new Audio(incomingMessageSound);
    audio.play();
  }

  handleClick() {
    if (this.props.handleClick !== undefined) {
      this.props.handleClick();
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }
  render() {
    const isOpen = this.props.hasOwnProperty('isOpen') ? this.props.isOpen : this.state.isOpen;
    const launcher_position = this.props.config && 
    this.props.config.chat_position == "bottom_left" ? 
    "sc-launcher-left" : 
    this.props.config.chat_position == "bottom_center" ?
    "sc-launcher-center" : 
    this.props.config.chat_position == "top" ? 
    "":
    "sc-launcher-right"
    const classList = [
      'sc-launcher',
      (isOpen ? 'opened' : ''),
      launcher_position,

    ];

    return (
      <div id={`sc-launcher`}  >
        <div style={{
          backgroundColor: this.props.design.top_buttons_background_color
        }} className={classList.join(' ')} onClick={this.handleClick.bind(this)}>
          <MessageCount count={this.props.newMessagesCount} isOpen={isOpen} />
          {/* <img className={'sc-open-icon'} src={launcherIconActive} />
          <img className={'sc-closed-icon'} src={launcherIcon} /> */}
          <i
                style={{
                  color: this.props.design.top_buttons_text_color,
                  fontSize: 25
                }}
                class="far fa-comment-dots"
              ></i>
        </div>
     
        <ChatWindow
          design={this.props.design}
          isMessageLoading={this.props.isMessageLoading}
          messageList={this.props.messageList}
          onUserInputSubmit={this.props.onMessageWasSent}
          onFilesSelected={this.props.onFilesSelected}
          agentProfile={this.props.agentProfile}
          isOpen={isOpen}
          onClose={this.handleClick.bind(this)}
          showEmoji={this.props.showEmoji}
          predefined_messages= {Array.isArray(this.props.config) ? this.props.config[0].predefined_messages: this.props.config.predefined_messages}
        />
      </div>
    );
  }
}

const MessageCount = (props) => {
  if (props.count === 0 || props.isOpen === true) { return null; }
  return (
    <div className={'sc-new-messages-count'}>
      {props.count}
    </div>
  );
};

Launcher.propTypes = {
  onMessageWasReceived: PropTypes.func,
  onMessageWasSent: PropTypes.func,
  newMessagesCount: PropTypes.number,
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
  messageList: PropTypes.arrayOf(PropTypes.object),
  mute: PropTypes.bool,
  showEmoji: PropTypes.bool,
  checkIfNewMessage: PropTypes.func,
  config: PropTypes.object,
  design: PropTypes.object,
  isMessageLoading: PropTypes.bool,
  predefined_messages: PropTypes.arrayOf(PropTypes.object),

};

Launcher.defaultProps = {
  newMessagesCount: 0,
  showEmoji: true
};

export default Launcher;
