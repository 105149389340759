'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var ReservationList;
var ReservationPage;
var ReservationInstance;
var ReservationContext;

/* jshint ignore:start */
/**
 * Initialize the ReservationList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that this worker is contained within.
 * @param {string} workerSid - The SID of the reserved Worker resource
 */
/* jshint ignore:end */
ReservationList = function ReservationList(version, workspaceSid, workerSid) {
  /* jshint ignore:start */
  /**
   * @function reservations
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext}
   */
  /* jshint ignore:end */
  function ReservationListInstance(sid) {
    return ReservationListInstance.get(sid);
  }

  ReservationListInstance._version = version;
  // Path Solution
  ReservationListInstance._solution = {workspaceSid: workspaceSid, workerSid: workerSid};
  ReservationListInstance._uri = `/Workspaces/${workspaceSid}/Workers/${workerSid}/Reservations`;
  /* jshint ignore:start */
  /**
   * Streams ReservationInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @param {object} [opts] - Options for request
   * @param {reservation.status} [opts.reservationStatus] -
   *          Returns the list of reservations for a worker with a specified ReservationStatus
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ReservationListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ReservationInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @param {object} [opts] - Options for request
   * @param {reservation.status} [opts.reservationStatus] -
   *          Returns the list of reservations for a worker with a specified ReservationStatus
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ReservationListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ReservationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @param {object} [opts] - Options for request
   * @param {reservation.status} [opts.reservationStatus] -
   *          Returns the list of reservations for a worker with a specified ReservationStatus
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ReservationListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'ReservationStatus': _.get(opts, 'reservationStatus'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ReservationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ReservationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ReservationListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ReservationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a reservation
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @param {string} sid - The SID of the WorkerReservation resource to fetch
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext}
   */
  /* jshint ignore:end */
  ReservationListInstance.get = function get(sid) {
    return new ReservationContext(
      this._version,
      this._solution.workspaceSid,
      this._solution.workerSid,
      sid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ReservationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ReservationListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ReservationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ReservationPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ReservationSolution} solution - Path solution
 *
 * @returns ReservationPage
 */
/* jshint ignore:end */
ReservationPage = function ReservationPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ReservationPage.prototype, Page.prototype);
ReservationPage.prototype.constructor = ReservationPage;

/* jshint ignore:start */
/**
 * Build an instance of ReservationInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationPage#
 *
 * @param {ReservationPayload} payload - Payload response from the API
 *
 * @returns ReservationInstance
 */
/* jshint ignore:end */
ReservationPage.prototype.getInstance = function getInstance(payload) {
  return new ReservationInstance(
    this._version,
    payload,
    this._solution.workspaceSid,
    this._solution.workerSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ReservationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ReservationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ReservationContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {reservation.status} reservationStatus -
 *          The current status of the reservation
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} taskSid - The SID of the reserved Task resource
 * @property {string} workerName - The friendly_name of the Worker that is reserved
 * @property {string} workerSid - The SID of the reserved Worker resource
 * @property {string} workspaceSid -
 *          The SID of the Workspace that this worker is contained within.
 * @property {string} url - The absolute URL of the WorkerReservation resource
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {ReservationPayload} payload - The instance payload
 * @param {sid} workspaceSid -
 *          The SID of the Workspace that this worker is contained within.
 * @param {sid} workerSid - The SID of the reserved Worker resource
 * @param {sid} sid - The SID of the WorkerReservation resource to fetch
 */
/* jshint ignore:end */
ReservationInstance = function ReservationInstance(version, payload,
                                                    workspaceSid, workerSid,
                                                    sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.reservationStatus = payload.reservation_status; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.taskSid = payload.task_sid; // jshint ignore:line
  this.workerName = payload.worker_name; // jshint ignore:line
  this.workerSid = payload.worker_sid; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, sid: sid || this.sid, };
};

Object.defineProperty(ReservationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ReservationContext(
          this._version,
          this._solution.workspaceSid,
          this._solution.workerSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ReservationInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ReservationInstance
 */
/* jshint ignore:end */
ReservationInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a ReservationInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {reservation.status} [opts.reservationStatus] -
 *          The new status of the reservation
 * @param {string} [opts.workerActivitySid] -
 *          The new worker activity SID if rejecting a reservation
 * @param {string} [opts.instruction] -
 *          The assignment instruction for the reservation
 * @param {string} [opts.dequeuePostWorkActivitySid] -
 *          The SID of the Activity resource to start after executing a Dequeue instruction
 * @param {string} [opts.dequeueFrom] -
 *          The caller ID of the call to the worker when executing a Dequeue instruction
 * @param {string} [opts.dequeueRecord] -
 *          Whether to record both legs of a call when executing a Dequeue instruction
 * @param {number} [opts.dequeueTimeout] -
 *          The timeout for call when executing a Dequeue instruction
 * @param {string} [opts.dequeueTo] -
 *          The contact URI of the worker when executing a Dequeue instruction
 * @param {string} [opts.dequeueStatusCallbackUrl] -
 *          The callback URL for completed call event when executing a Dequeue instruction
 * @param {string} [opts.callFrom] -
 *          The Caller ID of the outbound call when executing a Call instruction
 * @param {string} [opts.callRecord] -
 *          Whether to record both legs of a call when executing a Call instruction
 * @param {number} [opts.callTimeout] -
 *          The timeout for a call when executing a Call instruction
 * @param {string} [opts.callTo] -
 *          The contact URI of the worker when executing a Call instruction
 * @param {string} [opts.callUrl] -
 *          TwiML URI executed on answering the worker's leg as a result of the Call instruction
 * @param {string} [opts.callStatusCallbackUrl] -
 *          The URL to call for the completed call event when executing a Call instruction
 * @param {boolean} [opts.callAccept] -
 *          Whether to accept a reservation when executing a Call instruction
 * @param {string} [opts.redirectCallSid] -
 *          The Call SID of the call parked in the queue when executing a Redirect instruction
 * @param {boolean} [opts.redirectAccept] -
 *          Whether the reservation should be accepted when executing a Redirect instruction
 * @param {string} [opts.redirectUrl] -
 *          TwiML URI to redirect the call to when executing the Redirect instruction
 * @param {string} [opts.to] -
 *          The Contact URI of the worker when executing a Conference instruction
 * @param {string} [opts.from] -
 *          The caller ID of the call to the worker when executing a Conference instruction
 * @param {string} [opts.statusCallback] -
 *          The URL we should call to send status information to your application
 * @param {string} [opts.statusCallbackMethod] -
 *          The HTTP method we should use to call status_callback
 * @param {reservation.call_status|list} [opts.statusCallbackEvent] -
 *          The call progress events that we will send to status_callback
 * @param {number} [opts.timeout] -
 *          The timeout for a call when executing a Conference instruction
 * @param {boolean} [opts.record] -
 *          Whether to record the participant and their conferences
 * @param {boolean} [opts.muted] - Whether to mute the agent
 * @param {string} [opts.beep] -
 *          Whether to play a notification beep when the participant joins
 * @param {boolean} [opts.startConferenceOnEnter] -
 *          Whether the conference starts when the participant joins the conference
 * @param {boolean} [opts.endConferenceOnExit] -
 *          Whether to end the conference when the agent leaves
 * @param {string} [opts.waitUrl] - URL that hosts pre-conference hold music
 * @param {string} [opts.waitMethod] -
 *          The HTTP method we should use to call `wait_url`
 * @param {boolean} [opts.earlyMedia] -
 *          Whether agents can hear the state of the outbound call
 * @param {number} [opts.maxParticipants] -
 *          The maximum number of agent conference participants
 * @param {string} [opts.conferenceStatusCallback] -
 *          The callback URL for conference events
 * @param {string} [opts.conferenceStatusCallbackMethod] -
 *          HTTP method for requesting `conference_status_callback` URL
 * @param {reservation.conference_event|list} [opts.conferenceStatusCallbackEvent] -
 *          The conference status events that we will send to conference_status_callback
 * @param {string} [opts.conferenceRecord] -
 *          Whether to record the conference the participant is joining
 * @param {string} [opts.conferenceTrim] -
 *          Whether to trim leading and trailing silence from your recorded conference audio files
 * @param {string} [opts.recordingChannels] -
 *          Specify `mono` or `dual` recording channels
 * @param {string} [opts.recordingStatusCallback] -
 *          The URL that we should call using the `recording_status_callback_method` when the recording status changes
 * @param {string} [opts.recordingStatusCallbackMethod] -
 *          The HTTP method we should use when we call `recording_status_callback`
 * @param {string} [opts.conferenceRecordingStatusCallback] -
 *          The URL we should call using the `conference_recording_status_callback_method` when the conference recording is available
 * @param {string} [opts.conferenceRecordingStatusCallbackMethod] -
 *          The HTTP method we should use to call `conference_recording_status_callback`
 * @param {string} [opts.region] -
 *          The region where we should mix the conference audio
 * @param {string} [opts.sipAuthUsername] -
 *          The SIP username used for authentication
 * @param {string} [opts.sipAuthPassword] - The SIP password for authentication
 * @param {string|list} [opts.dequeueStatusCallbackEvent] -
 *          The call progress events sent via webhooks as a result of a Dequeue instruction
 * @param {string} [opts.postWorkActivitySid] -
 *          The new worker activity SID after executing a Conference instruction
 * @param {boolean} [opts.endConferenceOnCustomerExit] -
 *          Whether to end the conference when the customer leaves
 * @param {boolean} [opts.beepOnCustomerEntrance] -
 *          Whether to play a notification beep when the customer joins
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ReservationInstance
 */
/* jshint ignore:end */
ReservationInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ReservationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ReservationInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ReservationContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid -
 *          The SID of the Workspace with the WorkerReservation resource to fetch
 * @param {sid} workerSid -
 *          The SID of the reserved Worker resource with the WorkerReservation resource to fetch
 * @param {sid} sid - The SID of the WorkerReservation resource to fetch
 */
/* jshint ignore:end */
ReservationContext = function ReservationContext(version, workspaceSid,
                                                  workerSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, sid: sid, };
  this._uri = `/Workspaces/${workspaceSid}/Workers/${workerSid}/Reservations/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a ReservationInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ReservationInstance
 */
/* jshint ignore:end */
ReservationContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ReservationInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.workerSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ReservationInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {reservation.status} [opts.reservationStatus] -
 *          The new status of the reservation
 * @param {string} [opts.workerActivitySid] -
 *          The new worker activity SID if rejecting a reservation
 * @param {string} [opts.instruction] -
 *          The assignment instruction for the reservation
 * @param {string} [opts.dequeuePostWorkActivitySid] -
 *          The SID of the Activity resource to start after executing a Dequeue instruction
 * @param {string} [opts.dequeueFrom] -
 *          The caller ID of the call to the worker when executing a Dequeue instruction
 * @param {string} [opts.dequeueRecord] -
 *          Whether to record both legs of a call when executing a Dequeue instruction
 * @param {number} [opts.dequeueTimeout] -
 *          The timeout for call when executing a Dequeue instruction
 * @param {string} [opts.dequeueTo] -
 *          The contact URI of the worker when executing a Dequeue instruction
 * @param {string} [opts.dequeueStatusCallbackUrl] -
 *          The callback URL for completed call event when executing a Dequeue instruction
 * @param {string} [opts.callFrom] -
 *          The Caller ID of the outbound call when executing a Call instruction
 * @param {string} [opts.callRecord] -
 *          Whether to record both legs of a call when executing a Call instruction
 * @param {number} [opts.callTimeout] -
 *          The timeout for a call when executing a Call instruction
 * @param {string} [opts.callTo] -
 *          The contact URI of the worker when executing a Call instruction
 * @param {string} [opts.callUrl] -
 *          TwiML URI executed on answering the worker's leg as a result of the Call instruction
 * @param {string} [opts.callStatusCallbackUrl] -
 *          The URL to call for the completed call event when executing a Call instruction
 * @param {boolean} [opts.callAccept] -
 *          Whether to accept a reservation when executing a Call instruction
 * @param {string} [opts.redirectCallSid] -
 *          The Call SID of the call parked in the queue when executing a Redirect instruction
 * @param {boolean} [opts.redirectAccept] -
 *          Whether the reservation should be accepted when executing a Redirect instruction
 * @param {string} [opts.redirectUrl] -
 *          TwiML URI to redirect the call to when executing the Redirect instruction
 * @param {string} [opts.to] -
 *          The Contact URI of the worker when executing a Conference instruction
 * @param {string} [opts.from] -
 *          The caller ID of the call to the worker when executing a Conference instruction
 * @param {string} [opts.statusCallback] -
 *          The URL we should call to send status information to your application
 * @param {string} [opts.statusCallbackMethod] -
 *          The HTTP method we should use to call status_callback
 * @param {reservation.call_status|list} [opts.statusCallbackEvent] -
 *          The call progress events that we will send to status_callback
 * @param {number} [opts.timeout] -
 *          The timeout for a call when executing a Conference instruction
 * @param {boolean} [opts.record] -
 *          Whether to record the participant and their conferences
 * @param {boolean} [opts.muted] - Whether to mute the agent
 * @param {string} [opts.beep] -
 *          Whether to play a notification beep when the participant joins
 * @param {boolean} [opts.startConferenceOnEnter] -
 *          Whether the conference starts when the participant joins the conference
 * @param {boolean} [opts.endConferenceOnExit] -
 *          Whether to end the conference when the agent leaves
 * @param {string} [opts.waitUrl] - URL that hosts pre-conference hold music
 * @param {string} [opts.waitMethod] -
 *          The HTTP method we should use to call `wait_url`
 * @param {boolean} [opts.earlyMedia] -
 *          Whether agents can hear the state of the outbound call
 * @param {number} [opts.maxParticipants] -
 *          The maximum number of agent conference participants
 * @param {string} [opts.conferenceStatusCallback] -
 *          The callback URL for conference events
 * @param {string} [opts.conferenceStatusCallbackMethod] -
 *          HTTP method for requesting `conference_status_callback` URL
 * @param {reservation.conference_event|list} [opts.conferenceStatusCallbackEvent] -
 *          The conference status events that we will send to conference_status_callback
 * @param {string} [opts.conferenceRecord] -
 *          Whether to record the conference the participant is joining
 * @param {string} [opts.conferenceTrim] -
 *          Whether to trim leading and trailing silence from your recorded conference audio files
 * @param {string} [opts.recordingChannels] -
 *          Specify `mono` or `dual` recording channels
 * @param {string} [opts.recordingStatusCallback] -
 *          The URL that we should call using the `recording_status_callback_method` when the recording status changes
 * @param {string} [opts.recordingStatusCallbackMethod] -
 *          The HTTP method we should use when we call `recording_status_callback`
 * @param {string} [opts.conferenceRecordingStatusCallback] -
 *          The URL we should call using the `conference_recording_status_callback_method` when the conference recording is available
 * @param {string} [opts.conferenceRecordingStatusCallbackMethod] -
 *          The HTTP method we should use to call `conference_recording_status_callback`
 * @param {string} [opts.region] -
 *          The region where we should mix the conference audio
 * @param {string} [opts.sipAuthUsername] -
 *          The SIP username used for authentication
 * @param {string} [opts.sipAuthPassword] - The SIP password for authentication
 * @param {string|list} [opts.dequeueStatusCallbackEvent] -
 *          The call progress events sent via webhooks as a result of a Dequeue instruction
 * @param {string} [opts.postWorkActivitySid] -
 *          The new worker activity SID after executing a Conference instruction
 * @param {boolean} [opts.endConferenceOnCustomerExit] -
 *          Whether to end the conference when the customer leaves
 * @param {boolean} [opts.beepOnCustomerEntrance] -
 *          Whether to play a notification beep when the customer joins
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ReservationInstance
 */
/* jshint ignore:end */
ReservationContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'ReservationStatus': _.get(opts, 'reservationStatus'),
    'WorkerActivitySid': _.get(opts, 'workerActivitySid'),
    'Instruction': _.get(opts, 'instruction'),
    'DequeuePostWorkActivitySid': _.get(opts, 'dequeuePostWorkActivitySid'),
    'DequeueFrom': _.get(opts, 'dequeueFrom'),
    'DequeueRecord': _.get(opts, 'dequeueRecord'),
    'DequeueTimeout': _.get(opts, 'dequeueTimeout'),
    'DequeueTo': _.get(opts, 'dequeueTo'),
    'DequeueStatusCallbackUrl': _.get(opts, 'dequeueStatusCallbackUrl'),
    'CallFrom': _.get(opts, 'callFrom'),
    'CallRecord': _.get(opts, 'callRecord'),
    'CallTimeout': _.get(opts, 'callTimeout'),
    'CallTo': _.get(opts, 'callTo'),
    'CallUrl': _.get(opts, 'callUrl'),
    'CallStatusCallbackUrl': _.get(opts, 'callStatusCallbackUrl'),
    'CallAccept': serialize.bool(_.get(opts, 'callAccept')),
    'RedirectCallSid': _.get(opts, 'redirectCallSid'),
    'RedirectAccept': serialize.bool(_.get(opts, 'redirectAccept')),
    'RedirectUrl': _.get(opts, 'redirectUrl'),
    'To': _.get(opts, 'to'),
    'From': _.get(opts, 'from'),
    'StatusCallback': _.get(opts, 'statusCallback'),
    'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod'),
    'StatusCallbackEvent': serialize.map(_.get(opts, 'statusCallbackEvent'), function(e) { return e; }),
    'Timeout': _.get(opts, 'timeout'),
    'Record': serialize.bool(_.get(opts, 'record')),
    'Muted': serialize.bool(_.get(opts, 'muted')),
    'Beep': _.get(opts, 'beep'),
    'StartConferenceOnEnter': serialize.bool(_.get(opts, 'startConferenceOnEnter')),
    'EndConferenceOnExit': serialize.bool(_.get(opts, 'endConferenceOnExit')),
    'WaitUrl': _.get(opts, 'waitUrl'),
    'WaitMethod': _.get(opts, 'waitMethod'),
    'EarlyMedia': serialize.bool(_.get(opts, 'earlyMedia')),
    'MaxParticipants': _.get(opts, 'maxParticipants'),
    'ConferenceStatusCallback': _.get(opts, 'conferenceStatusCallback'),
    'ConferenceStatusCallbackMethod': _.get(opts, 'conferenceStatusCallbackMethod'),
    'ConferenceStatusCallbackEvent': serialize.map(_.get(opts, 'conferenceStatusCallbackEvent'), function(e) { return e; }),
    'ConferenceRecord': _.get(opts, 'conferenceRecord'),
    'ConferenceTrim': _.get(opts, 'conferenceTrim'),
    'RecordingChannels': _.get(opts, 'recordingChannels'),
    'RecordingStatusCallback': _.get(opts, 'recordingStatusCallback'),
    'RecordingStatusCallbackMethod': _.get(opts, 'recordingStatusCallbackMethod'),
    'ConferenceRecordingStatusCallback': _.get(opts, 'conferenceRecordingStatusCallback'),
    'ConferenceRecordingStatusCallbackMethod': _.get(opts, 'conferenceRecordingStatusCallbackMethod'),
    'Region': _.get(opts, 'region'),
    'SipAuthUsername': _.get(opts, 'sipAuthUsername'),
    'SipAuthPassword': _.get(opts, 'sipAuthPassword'),
    'DequeueStatusCallbackEvent': serialize.map(_.get(opts, 'dequeueStatusCallbackEvent'), function(e) { return e; }),
    'PostWorkActivitySid': _.get(opts, 'postWorkActivitySid'),
    'EndConferenceOnCustomerExit': serialize.bool(_.get(opts, 'endConferenceOnCustomerExit')),
    'BeepOnCustomerEntrance': serialize.bool(_.get(opts, 'beepOnCustomerEntrance'))
  });
  var headers = values.of({'If-Match': _.get(opts, 'ifMatch')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data, headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new ReservationInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.workerSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ReservationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ReservationContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ReservationList: ReservationList,
  ReservationPage: ReservationPage,
  ReservationInstance: ReservationInstance,
  ReservationContext: ReservationContext
};
