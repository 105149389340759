'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var FlowTestUserList;
var FlowTestUserPage;
var FlowTestUserInstance;
var FlowTestUserContext;

/* jshint ignore:start */
/**
 * Initialize the FlowTestUserList
 *
 * @constructor Twilio.Studio.V2.FlowContext.FlowTestUserList
 *
 * @param {Twilio.Studio.V2} version - Version of the resource
 * @param {string} sid - Unique identifier of the flow.
 */
/* jshint ignore:end */
FlowTestUserList = function FlowTestUserList(version, sid) {
  /* jshint ignore:start */
  /**
   * @function testUsers
   * @memberof Twilio.Studio.V2.FlowContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Studio.V2.FlowContext.FlowTestUserContext}
   */
  /* jshint ignore:end */
  function FlowTestUserListInstance(sid) {
    return FlowTestUserListInstance.get(sid);
  }

  FlowTestUserListInstance._version = version;
  // Path Solution
  FlowTestUserListInstance._solution = {sid: sid};
  /* jshint ignore:start */
  /**
   * Constructs a flow_test_user
   *
   * @function get
   * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserList#
   *
   * @returns {Twilio.Studio.V2.FlowContext.FlowTestUserContext}
   */
  /* jshint ignore:end */
  FlowTestUserListInstance.get = function get() {
    return new FlowTestUserContext(this._version, this._solution.sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  FlowTestUserListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  FlowTestUserListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return FlowTestUserListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the FlowTestUserPage
 *
 * @constructor Twilio.Studio.V2.FlowContext.FlowTestUserPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {FlowTestUserSolution} solution - Path solution
 *
 * @returns FlowTestUserPage
 */
/* jshint ignore:end */
FlowTestUserPage = function FlowTestUserPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(FlowTestUserPage.prototype, Page.prototype);
FlowTestUserPage.prototype.constructor = FlowTestUserPage;

/* jshint ignore:start */
/**
 * Build an instance of FlowTestUserInstance
 *
 * @function getInstance
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserPage#
 *
 * @param {FlowTestUserPayload} payload - Payload response from the API
 *
 * @returns FlowTestUserInstance
 */
/* jshint ignore:end */
FlowTestUserPage.prototype.getInstance = function getInstance(payload) {
  return new FlowTestUserInstance(this._version, payload, this._solution.sid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
FlowTestUserPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FlowTestUserPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FlowTestUserContext
 *
 * @constructor Twilio.Studio.V2.FlowContext.FlowTestUserInstance
 *
 * @property {string} sid - Unique identifier of the flow.
 * @property {string} testUsers -
 *          List of test user identities that can test draft versions of the flow.
 * @property {string} url - The URL of this resource.
 *
 * @param {V2} version - Version of the resource
 * @param {FlowTestUserPayload} payload - The instance payload
 * @param {sid} sid - Unique identifier of the flow.
 */
/* jshint ignore:end */
FlowTestUserInstance = function FlowTestUserInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.testUsers = payload.test_users; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid, };
};

Object.defineProperty(FlowTestUserInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new FlowTestUserContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a FlowTestUserInstance
 *
 * @function fetch
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FlowTestUserInstance
 */
/* jshint ignore:end */
FlowTestUserInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a FlowTestUserInstance
 *
 * @function update
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserInstance#
 *
 * @param {object} opts - Options for request
 * @param {string|list} opts.testUsers -
 *          List of test user identities that can test draft versions of the flow.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FlowTestUserInstance
 */
/* jshint ignore:end */
FlowTestUserInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
FlowTestUserInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FlowTestUserInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FlowTestUserContext
 *
 * @constructor Twilio.Studio.V2.FlowContext.FlowTestUserContext
 *
 * @param {V2} version - Version of the resource
 * @param {sid} sid - Unique identifier of the flow.
 */
/* jshint ignore:end */
FlowTestUserContext = function FlowTestUserContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Flows/${sid}/TestUsers`;
};

/* jshint ignore:start */
/**
 * fetch a FlowTestUserInstance
 *
 * @function fetch
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FlowTestUserInstance
 */
/* jshint ignore:end */
FlowTestUserContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new FlowTestUserInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a FlowTestUserInstance
 *
 * @function update
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserContext#
 *
 * @param {object} opts - Options for request
 * @param {string|list} opts.testUsers -
 *          List of test user identities that can test draft versions of the flow.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FlowTestUserInstance
 */
/* jshint ignore:end */
FlowTestUserContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['testUsers'])) {
    throw new Error('Required parameter "opts[\'testUsers\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'TestUsers': serialize.map(_.get(opts, 'testUsers'), function(e) { return e; })
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new FlowTestUserInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Studio.V2.FlowContext.FlowTestUserContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
FlowTestUserContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

FlowTestUserContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  FlowTestUserList: FlowTestUserList,
  FlowTestUserPage: FlowTestUserPage,
  FlowTestUserInstance: FlowTestUserInstance,
  FlowTestUserContext: FlowTestUserContext
};
