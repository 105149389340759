import {
        GET_MENUS,
        GET_CHILDRENS,
        UPDATE_CHILDRENS,
        RESET_MENU,
        SELECT_MENU,
        GET_MODIFIERS,
        RESET_CHILDREN,
        IS_LOADING,
        GET_ONLINE_CONTENT,
        SET_SELECTED_MENU,
        SET_CAMPAIGNS,
        SEARCH_ITEM,
    } from './../actionTypes'


export const addMenu = (menus) => {
    return{
        type: GET_MENUS,
        menus: menus,

    };
};


export const addChildren = (children) => {
    return{
        type: GET_CHILDRENS,
        children: children,

    };
};

export const addOnlineChildren = (children, modifiers) => {
    return{
        type: GET_ONLINE_CONTENT,
        children: children,
        modifiers: modifiers

    };
};


export const updateChildren = (children) => {
    return{
        type: UPDATE_CHILDRENS,
        children: children,

    };
};

export const resetChildren = () => {
    return{
        type: RESET_MENU,

    };
};


export const selectMenu = (menu) => {
    return{
        type: SELECT_MENU,
        menu:menu
    }
}

export const getModifiers = (modifiers) => {
    return{
        type: GET_MODIFIERS,
        modifiers: modifiers
    }
}


export const resetAllRecipies = () => {
    return{
        type: RESET_CHILDREN
    }
}

export const isLoading = () => {
    console.log('here it is')
    return {
        type: IS_LOADING
    }
}

export const selectMenuData = (menu) => {
    return {
        type: SET_SELECTED_MENU,
        menu: menu
    }
}


export const addCampaign = (campaigns) => {
    return{
        type: SET_CAMPAIGNS,
        campaigns: campaigns,

    };
};

export const searchItem = (query, filters) => {
    return{
        type: SEARCH_ITEM,
        query,
        filters

    };
};


