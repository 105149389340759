import React from 'react'
import {View, Text} from 'react-native';
import { FontLetterSpacing } from './../handlers/utils';
import i18n from "../i18n";

import StyledText from "../components/styledText"




const ModifierRequired = (props) => {
    return(
        <View
        style={{
          height: "70%",
          justifyContent: "center",
         
          alignItems: "center",
          // marginRight: 18,
          backgroundColor: props.required_indicator
            ? "red"
            : props.top_buttons_background_color,
          borderRadius: 3,
          paddingHorizontal: 8,
          paddingVertical: 8,
          marginRight: 8,
          marginTop: 12
        }}
      >
        <StyledText
        font_color={props.item_page_background_color}
        font_weight={props.design.top_button_text_font_weight}
        font_style={props.design.top_button_text_font_style}
        text_transform={props.design.top_button_text_font_transform}
        font_family={props.design.top_button_text_font_family}
        font_size={props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
          style={{
            // color: props.item_page_background_color,
            // fontSize: props.body_size ?? 10,
            // fontFamily:'SecondaryFont',
            // fontWeight: props.custom_font.body_is_bold ? "bold": "normal",
            // letterSpacing :FontLetterSpacing(props.custom_font.secondary_letter_space)
          }}
        >
          {props.min ? props.min : ""}{" "}
          {i18n.t("labels.required")}
        </StyledText>
      </View>
    )
}

export default ModifierRequired