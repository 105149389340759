'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var DataSessionList;
var DataSessionPage;
var DataSessionInstance;

/* jshint ignore:start */
/**
 * Initialize the DataSessionList
 *
 * @constructor Twilio.Wireless.V1.SimContext.DataSessionList
 *
 * @param {Twilio.Wireless.V1} version - Version of the resource
 * @param {string} simSid -
 *          The SID of the Sim resource that the Data Session is for
 */
/* jshint ignore:end */
DataSessionList = function DataSessionList(version, simSid) {
  /* jshint ignore:start */
  /**
   * @function dataSessions
   * @memberof Twilio.Wireless.V1.SimContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Wireless.V1.SimContext.DataSessionContext}
   */
  /* jshint ignore:end */
  function DataSessionListInstance(sid) {
    return DataSessionListInstance.get(sid);
  }

  DataSessionListInstance._version = version;
  // Path Solution
  DataSessionListInstance._solution = {simSid: simSid};
  DataSessionListInstance._uri = `/Sims/${simSid}/DataSessions`;
  /* jshint ignore:start */
  /**
   * Streams DataSessionInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Wireless.V1.SimContext.DataSessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  DataSessionListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists DataSessionInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Wireless.V1.SimContext.DataSessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DataSessionListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of DataSessionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Wireless.V1.SimContext.DataSessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DataSessionListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new DataSessionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of DataSessionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Wireless.V1.SimContext.DataSessionList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DataSessionListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new DataSessionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Wireless.V1.SimContext.DataSessionList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  DataSessionListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  DataSessionListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return DataSessionListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the DataSessionPage
 *
 * @constructor Twilio.Wireless.V1.SimContext.DataSessionPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {DataSessionSolution} solution - Path solution
 *
 * @returns DataSessionPage
 */
/* jshint ignore:end */
DataSessionPage = function DataSessionPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(DataSessionPage.prototype, Page.prototype);
DataSessionPage.prototype.constructor = DataSessionPage;

/* jshint ignore:start */
/**
 * Build an instance of DataSessionInstance
 *
 * @function getInstance
 * @memberof Twilio.Wireless.V1.SimContext.DataSessionPage#
 *
 * @param {DataSessionPayload} payload - Payload response from the API
 *
 * @returns DataSessionInstance
 */
/* jshint ignore:end */
DataSessionPage.prototype.getInstance = function getInstance(payload) {
  return new DataSessionInstance(this._version, payload, this._solution.simSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Wireless.V1.SimContext.DataSessionPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
DataSessionPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DataSessionPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DataSessionContext
 *
 * @constructor Twilio.Wireless.V1.SimContext.DataSessionInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} simSid -
 *          The SID of the Sim resource that the Data Session is for
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} radioLink -
 *          The generation of wireless technology that the device was using
 * @property {string} operatorMcc -
 *          The 'mobile country code' is the unique ID of the home country where the Data Session took place
 * @property {string} operatorMnc -
 *          The 'mobile network code' is the unique ID specific to the mobile operator network where the Data Session took place
 * @property {string} operatorCountry -
 *          The three letter country code representing where the device's Data Session took place
 * @property {string} operatorName -
 *          The friendly name of the mobile operator network that the SIM-connected device is attached to
 * @property {string} cellId -
 *          The unique ID of the cellular tower that the device was attached to at the moment when the Data Session was last updated
 * @property {object} cellLocationEstimate -
 *          An object with the estimated location where the device's Data Session took place
 * @property {number} packetsUploaded -
 *          The number of packets uploaded by the device between the start time and when the Data Session was last updated
 * @property {number} packetsDownloaded -
 *          The number of packets downloaded by the device between the start time and when the Data Session was last updated
 * @property {Date} lastUpdated -
 *          The date that the resource was last updated, given as GMT in ISO 8601 format
 * @property {Date} start -
 *          The date that the Data Session started, given as GMT in ISO 8601 format
 * @property {Date} end -
 *          The date that the record ended, given as GMT in ISO 8601 format
 * @property {string} imei - The unique ID of the device using the SIM to connect
 *
 * @param {V1} version - Version of the resource
 * @param {DataSessionPayload} payload - The instance payload
 * @param {sid_like} simSid -
 *          The SID of the Sim resource that the Data Session is for
 */
/* jshint ignore:end */
DataSessionInstance = function DataSessionInstance(version, payload, simSid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.simSid = payload.sim_sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.radioLink = payload.radio_link; // jshint ignore:line
  this.operatorMcc = payload.operator_mcc; // jshint ignore:line
  this.operatorMnc = payload.operator_mnc; // jshint ignore:line
  this.operatorCountry = payload.operator_country; // jshint ignore:line
  this.operatorName = payload.operator_name; // jshint ignore:line
  this.cellId = payload.cell_id; // jshint ignore:line
  this.cellLocationEstimate = payload.cell_location_estimate; // jshint ignore:line
  this.packetsUploaded = deserialize.integer(payload.packets_uploaded); // jshint ignore:line
  this.packetsDownloaded = deserialize.integer(payload.packets_downloaded); // jshint ignore:line
  this.lastUpdated = deserialize.iso8601DateTime(payload.last_updated); // jshint ignore:line
  this.start = deserialize.iso8601DateTime(payload.start); // jshint ignore:line
  this.end = deserialize.iso8601DateTime(payload.end); // jshint ignore:line
  this.imei = payload.imei; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {simSid: simSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Wireless.V1.SimContext.DataSessionInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
DataSessionInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DataSessionInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  DataSessionList: DataSessionList,
  DataSessionPage: DataSessionPage,
  DataSessionInstance: DataSessionInstance
};
