import {
   SET_PRIMARY_FONT_FACE,
   SET_SECONDARY_FONT_FACE
} from "../actionTypes";


export const addPrimaryFontFace = (loadedFontFace) => {

    return{
        type: SET_PRIMARY_FONT_FACE,     
        loadedFontFace:loadedFontFace
    }
};



export const addSecondaryFontFace = (loadedFontFace) => {
    return{
        type: SET_SECONDARY_FONT_FACE,   
        loadedFontFace:loadedFontFace
    }
};
