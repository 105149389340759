'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var BrandVettingList = require(
    './brandRegistration/brandVetting').BrandVettingList;
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var BrandRegistrationList;
var BrandRegistrationPage;
var BrandRegistrationInstance;
var BrandRegistrationContext;

/* jshint ignore:start */
/**
 * Initialize the BrandRegistrationList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.BrandRegistrationList
 *
 * @param {Twilio.Messaging.V1} version - Version of the resource
 */
/* jshint ignore:end */
BrandRegistrationList = function BrandRegistrationList(version) {
  /* jshint ignore:start */
  /**
   * @function brandRegistrations
   * @memberof Twilio.Messaging.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Messaging.V1.BrandRegistrationContext}
   */
  /* jshint ignore:end */
  function BrandRegistrationListInstance(sid) {
    return BrandRegistrationListInstance.get(sid);
  }

  BrandRegistrationListInstance._version = version;
  // Path Solution
  BrandRegistrationListInstance._solution = {};
  BrandRegistrationListInstance._uri = `/a2p/BrandRegistrations`;
  /* jshint ignore:start */
  /**
   * Streams BrandRegistrationInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists BrandRegistrationInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of BrandRegistrationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new BrandRegistrationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of BrandRegistrationInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new BrandRegistrationPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a BrandRegistrationInstance
   *
   * @function create
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.customerProfileBundleSid - Customer Profile Bundle Sid
   * @param {string} opts.a2PProfileBundleSid - A2P Messaging Profile Bundle Sid
   * @param {string} [opts.brandType] -
   *          Type of brand being created. One of: "STANDARD", "STARTER".
   * @param {boolean} [opts.mock] -
   *          A boolean that specifies whether brand should be a mock or not. If true, brand will be registered as a mock brand. Defaults to false if no value is provided.
   * @param {boolean} [opts.skipAutomaticSecVet] - Skip Automatic Secondary Vetting
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed BrandRegistrationInstance
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['customerProfileBundleSid'])) {
      throw new Error('Required parameter "opts[\'customerProfileBundleSid\']" missing.');
    }
    if (_.isUndefined(opts['a2PProfileBundleSid'])) {
      throw new Error('Required parameter "opts[\'a2PProfileBundleSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'CustomerProfileBundleSid': _.get(opts, 'customerProfileBundleSid'),
      'A2PProfileBundleSid': _.get(opts, 'a2PProfileBundleSid'),
      'BrandType': _.get(opts, 'brandType'),
      'Mock': serialize.bool(_.get(opts, 'mock')),
      'SkipAutomaticSecVet': serialize.bool(_.get(opts, 'skipAutomaticSecVet'))
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new BrandRegistrationInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a brand_registration
   *
   * @function get
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @param {string} sid - The SID that identifies the resource to fetch
   *
   * @returns {Twilio.Messaging.V1.BrandRegistrationContext}
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.get = function get(sid) {
    return new BrandRegistrationContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Messaging.V1.BrandRegistrationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  BrandRegistrationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  BrandRegistrationListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return BrandRegistrationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the BrandRegistrationPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.BrandRegistrationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {BrandRegistrationSolution} solution - Path solution
 *
 * @returns BrandRegistrationPage
 */
/* jshint ignore:end */
BrandRegistrationPage = function BrandRegistrationPage(version, response,
                                                        solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(BrandRegistrationPage.prototype, Page.prototype);
BrandRegistrationPage.prototype.constructor = BrandRegistrationPage;

/* jshint ignore:start */
/**
 * Build an instance of BrandRegistrationInstance
 *
 * @function getInstance
 * @memberof Twilio.Messaging.V1.BrandRegistrationPage#
 *
 * @param {BrandRegistrationPayload} payload - Payload response from the API
 *
 * @returns BrandRegistrationInstance
 */
/* jshint ignore:end */
BrandRegistrationPage.prototype.getInstance = function getInstance(payload) {
  return new BrandRegistrationInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.BrandRegistrationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandRegistrationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BrandRegistrationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BrandRegistrationContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.BrandRegistrationInstance
 *
 * @property {string} sid - A2P BrandRegistration Sid
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} customerProfileBundleSid -
 *          A2P Messaging Profile Bundle BundleSid
 * @property {string} a2PProfileBundleSid - A2P Messaging Profile Bundle BundleSid
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} brandType - Type of brand. One of: "STANDARD", "STARTER".
 * @property {brand_registration.status} status - Brand Registration status.
 * @property {string} tcrId - Campaign Registry (TCR) Brand ID
 * @property {string} failureReason - A reason why brand registration has failed
 * @property {string} url - The absolute URL of the Brand Registration
 * @property {number} brandScore - Brand score
 * @property {brand_registration.brand_feedback} brandFeedback - Brand feedback
 * @property {brand_registration.identity_status} identityStatus - Identity Status
 * @property {boolean} russell3000 - Russell 3000
 * @property {boolean} governmentEntity - Government Entity
 * @property {string} taxExemptStatus - Tax Exempt Status
 * @property {boolean} skipAutomaticSecVet - Skip Automatic Secondary Vetting
 * @property {boolean} mock -
 *          A boolean that specifies whether brand should be a mock or not. If true, brand will be registered as a mock brand. Defaults to false if no value is provided.
 * @property {string} links - The links
 *
 * @param {V1} version - Version of the resource
 * @param {BrandRegistrationPayload} payload - The instance payload
 * @param {sid} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
BrandRegistrationInstance = function BrandRegistrationInstance(version, payload,
    sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.customerProfileBundleSid = payload.customer_profile_bundle_sid; // jshint ignore:line
  this.a2PProfileBundleSid = payload.a2p_profile_bundle_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.brandType = payload.brand_type; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.tcrId = payload.tcr_id; // jshint ignore:line
  this.failureReason = payload.failure_reason; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.brandScore = deserialize.integer(payload.brand_score); // jshint ignore:line
  this.brandFeedback = payload.brand_feedback; // jshint ignore:line
  this.identityStatus = payload.identity_status; // jshint ignore:line
  this.russell3000 = payload.russell_3000; // jshint ignore:line
  this.governmentEntity = payload.government_entity; // jshint ignore:line
  this.taxExemptStatus = payload.tax_exempt_status; // jshint ignore:line
  this.skipAutomaticSecVet = payload.skip_automatic_sec_vet; // jshint ignore:line
  this.mock = payload.mock; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(BrandRegistrationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new BrandRegistrationContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a BrandRegistrationInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.BrandRegistrationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandRegistrationInstance
 */
/* jshint ignore:end */
BrandRegistrationInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a BrandRegistrationInstance
 *
 * @function update
 * @memberof Twilio.Messaging.V1.BrandRegistrationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandRegistrationInstance
 */
/* jshint ignore:end */
BrandRegistrationInstance.prototype.update = function update(callback) {
  return this._proxy.update(callback);
};

/* jshint ignore:start */
/**
 * Access the brandVettings
 *
 * @function brandVettings
 * @memberof Twilio.Messaging.V1.BrandRegistrationInstance#
 *
 * @returns {Twilio.Messaging.V1.BrandRegistrationContext.BrandVettingList}
 */
/* jshint ignore:end */
BrandRegistrationInstance.prototype.brandVettings = function brandVettings() {
  return this._proxy.brandVettings;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.BrandRegistrationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandRegistrationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BrandRegistrationInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BrandRegistrationContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.BrandRegistrationContext
 *
 * @property {Twilio.Messaging.V1.BrandRegistrationContext.BrandVettingList} brandVettings -
 *          brandVettings resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
BrandRegistrationContext = function BrandRegistrationContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/a2p/BrandRegistrations/${sid}`;

  // Dependents
  this._brandVettings = undefined;
};

/* jshint ignore:start */
/**
 * fetch a BrandRegistrationInstance
 *
 * @function fetch
 * @memberof Twilio.Messaging.V1.BrandRegistrationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandRegistrationInstance
 */
/* jshint ignore:end */
BrandRegistrationContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new BrandRegistrationInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a BrandRegistrationInstance
 *
 * @function update
 * @memberof Twilio.Messaging.V1.BrandRegistrationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BrandRegistrationInstance
 */
/* jshint ignore:end */
BrandRegistrationContext.prototype.update = function update(callback) {
  var deferred = Q.defer();
  var promise = this._version.update({uri: this._uri, method: 'POST'});

  promise = promise.then(function(payload) {
    deferred.resolve(new BrandRegistrationInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(BrandRegistrationContext.prototype,
  'brandVettings', {
    get: function() {
      if (!this._brandVettings) {
        this._brandVettings = new BrandVettingList(this._version, this._solution.sid);
      }
      return this._brandVettings;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.BrandRegistrationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
BrandRegistrationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

BrandRegistrationContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  BrandRegistrationList: BrandRegistrationList,
  BrandRegistrationPage: BrandRegistrationPage,
  BrandRegistrationInstance: BrandRegistrationInstance,
  BrandRegistrationContext: BrandRegistrationContext
};
