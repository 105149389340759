import React from 'react'
import {TouchableOpacity, View, Text} from 'react-native';



const AddressNicknameButton = (props) => {
    return(
        <TouchableOpacity onPress = {props.pressedNick}>
            <View style={{border:'1px solid grey',height:'100%',width:70,marginRight:10,alignItems:'center',justifyContent:'center',borderRadius:5,backgroundColor:props.isActive && props.activeNickBackgroundColor}}>
                <Text style={{color: props.isActive && props.activeNickTextColor,    fontFamily:'SecondaryFont'}}>{props.text}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default AddressNicknameButton