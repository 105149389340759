import React, { useEffect, useState } from "react";
import { Item, Label, Input, Button, } from "native-base";
import {
  View,
  Text,
  TouchableOpacity,
  AsyncStorage,
  Image,
  ScrollView,
  Dimensions,
  TouchableWithoutFeedback
} from "react-native";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import CountryPicker from "react-native-country-picker-modal";
import Modal from "modal-react-native-web";

import { API_URL, FB_CLIENT, GOOGLECLIENTID, REDIRECT_URL, APPLEID, SPECIAL_VENUE } from "./../constants";
import Toast from "./../components/toast";
import { adduser, addToken, feedbackModal } from "./../store/actions";
import { performRequest } from "./../services/apiHandler";
import { NavigationActions } from "react-navigation";
import { style } from "./../../assets/style";
import * as WebBrowser from "expo-web-browser";

import { Spinner } from 'native-base'

import { ResponseType } from "expo-auth-session";
import Constants from "expo-constants";
import i18n from "../i18n";
import * as Google from 'expo-auth-session/providers/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// import AppleLogin from 'react-apple-login'
import AppleSignin from 'react-apple-signin-auth';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import OtpInput from 'react-otp-input';
import * as Localization from "expo-localization";
import { FontLetterSpacing } from "./../handlers/utils";

import StyledText from "../components/styledText"

let mid = Constants.installationId;
const { height, width } = Dimensions.get("window");

const UserRegistration = (props) => {
  const { country_code,
    number_param,
    country,
    first_name_param,
    last_name_param,
    channel,
    email_param,
    is_phone_verified: phone_verified,
    is_email_verified: email_verified
  } = props.navigation.state ? props.navigation.state.params : {};

  let interval
  const [countryCode, setCountryCode] = useState(is_email_verified && props.config.country.cca2);
  const [email, setEmail] = useState(email_param ? email_param : "");
  const [first_name, setFirstName] = useState(first_name_param ? first_name_param : "");
  const [last_name, setLastName] = useState(last_name_param ? last_name_param : "");
  const [error_message, setErrorMessage] = useState("");
  const [message_status, setMessageStatus] = useState("");
  const [message_display, setMessageDisplay] = useState("none");
  const [email_disabled, setEmailDisabled] = useState(false);
  const [next_disabled, setNextDisabled] = useState(false)
  const [otp, setOtp] = useState("")
  const [number, setMobileNumber] = useState(number_param ? number_param : "")
  const [showEmailOtp, setShowEmailOtp] = useState(false)
  const [showMobileOtp, setShowMobileOtp] = useState(false)
  const [is_phone_verified, isPhoneVerified] = useState(phone_verified)
  const [is_email_verified, isEmailVerified] = useState(email_verified)
  const [resendDisabled, setResendDisabled] = useState(true)
  const [resendLoader, setResendLoader] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30)
  const [service, setService] = useState("")


  // const country_code = ""
  // const number = ""
  // const country  = ""

  // const [request, response, promptAsync] = Facebook.useAuthRequest({
  //   clientId: FB_CLIENT,
  //   responseType: ResponseType.Code,
  // });
  const [req, res, prompt] = Google.useAuthRequest({
    webClientId: GOOGLECLIENTID,
  });

  useEffect(() => {

    if (res?.type === 'success') {
      setNextDisabled(true)
      const { accessToken } = res.authentication;
      performRequest('post', 'api/v2/pwa/google-auth/', { access_token: accessToken, mobile_number: number }, false)
        .then(response => {
          setNextDisabled(false)

          // console.log(response.data.user.email, "lll")
          setEmail(response.data.user.email);
          // setEmailDisabled(true)
          // postAuthentication(response)
        })
        .catch(error => {
          setNextDisabled(false)
          throwMessageFn('Error authenticating with google. Try again', 'red')
        })
    }

  }, [res]);

  const postAuthentication = async (response) => {
    if (response.data.token) {
      await AsyncStorage.setItem("token", response.data.token);
      props.addToken(response.data.token);
    }

    props.adduser(response.data.user);
    if (props.platform == "qr") {
      const screen = await AsyncStorage.getItem('login_trigger')
      if (screen) {
        const is_feedback = localStorage.getItem("is_feedback")
          if(is_feedback){
              localStorage.removeItem("is_feedback")
              props.feedbackModal(true)
          }
        props.navigation.navigate(screen)
        await AsyncStorage.removeItem("login_trigger")
        return
      }

      const navigateAction = NavigationActions.navigate({
        routeName: "HomeScreen",
        params: {
          setScreenActive: () => setScreenActive(),
        },
      });
      props.navigation.dispatch(navigateAction);

    } else {
      const screen = await AsyncStorage.getItem('login_trigger')
      if (screen) {
        const is_feedback = localStorage.getItem("is_feedback")
          if(is_feedback){
              localStorage.removeItem("is_feedback")
              props.feedbackModal(true)
          }
        props.navigation.navigate(screen)
        await AsyncStorage.removeItem("login_trigger")
        return
      }
      if (props.menu_type == "delivery") {
        performRequest("get", `api/v2/pwa/address/`)
          .then((response) => {
            if (response.data.length > 0) {
              props.navigation.navigate("CheckoutScreen");
            } else {
              props.navigation.navigate("AddressFormScreen");
            }
          })
          .catch((error) => {
            props.navigation.navigate("AddressFormScreen");
          });
      } else {
        props.navigation.navigate("CheckoutScreen");
      }

    }
  };
  const throwMessageFn = (message, status) => {
    setErrorMessage(message);
    setMessageStatus(status);
    setMessageDisplay("inherit");

    setTimeout(
      function () {
        setErrorMessage("");
        setMessageStatus("");
        setMessageDisplay("none");
      },
      // .bind(this),
      3000
    );
  };

  const ValidateEmail = () => {
    if (email) {
      // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      if (email.includes('@')) {
        RegisterUser();
      } else {
        throwMessageFn("Enter a valid email address", "red");
        return;
      }
    } else {
      if (SPECIAL_VENUE == window.location.pathname.substring(1)) {
        throwMessageFn("Email address is required", "red");
        return;
      }

      RegisterUser();
    }
  };
  const setScreenActive = () => {
    // setState({
    //     isLandingPageActive:true
    // })
  };
  const RegisterUser = () => {

    if (!first_name) {
      throwMessageFn("First Name is required", "red");
      return
    }

    if (!is_email_verified && email) {
      setNextDisabled(true)
      sendEmailOtp()
      return;
    }
    if (!is_phone_verified && number) {
      setNextDisabled(true)
      sendMobileOtp()
      return;
    }
    setNextDisabled(true)
    updateUserHandler()

  };

  const updateUserHandler = async() => {
    // const uuid = await AsyncStorage.getItem('uuid');

    let data = {
      first_name,
      last_name,
      other_email: email,
      mobile_number: number,
      mid: mid,
      channel,
      email,
      // uuid
    };

    performRequest("post", `api/v2/pwa/account/?mid=${mid}`, data)
      .then(async (response) => {
        // props.adduser(response.data)
        // props.addToken(response.data.token)
        // await AsyncStorage.setItem('token', response.data.token);
        throwMessageFn("User details updated successfully", "green");
        let new_response = {
          data: {
            user: response.data,
            token: response.data.token,
          },
        };
        const { first_name, last_name, email } = response.data;
        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);
        setEmailDisabled(true);
        postAuthentication(new_response);
        setNextDisabled(false)


      })
      .catch((error) => {
        if (error.response.data.email) {
          throwMessageFn("Email already taken", "red");
          return;
        } else {
          throwMessageFn("Error registering user. Please try again", "red");
        }
        setNextDisabled(false)

      });
  }


  const onChangeOTP = async(_otp, type) => {
    setOtp(_otp)
    if (_otp.length == 4) {
      setResendLoader(true)
      setResendDisabled(true)
      if(type=="email"){
        performRequest("post", "api/v2/account/email/otp/verify", {
          email,
          venue: props.config.venue,
          otp:_otp
      }, false)
      .then(response => {
          setShowEmailOtp(false)
          setNextDisabled(true)
          updateUserHandler()
          setResendLoader(false)
      })
      .catch(err => {
          console.log(err.response, "err")
          setResendLoader(false)

          throwMessageFn('Failed to verify OTP', 'red')
      })
      }else{
        const uuid = await AsyncStorage.getItem('uuid');
        performRequest("get", `api/v2/pwa/otp/verify/?number=${number.substring(1)}&otp=${_otp}&mid=${mid}&service=${service}&channel=${"SMS"}&venue_id=${props.config.venue}&uuid=${uuid}`, false, false)
        .then(res=>{
          setShowMobileOtp(false)
          setNextDisabled(true)
          updateUserHandler()
          setResendLoader(false)
        })
        .catch(err=>{
          setResendLoader(false)
          throwMessageFn('Failed to verify OTP', 'red')

        })


      }
    }
  }


  const sendEmailOtp = () => {
    setResendLoader(true)

    performRequest("post", "api/v2/account/email/verify/auth", {
      email,
      venue: props.config.venue,

    })
      .then(() => {
        setShowEmailOtp(true)
        setNextDisabled(false)
        setResendLoader(false)

      })
      .catch(() => {
        setResendLoader(false)
        setNextDisabled(false)
        throwMessageFn('Error sending otp. Try again.', 'red')
      })

    setTimeLeft(30)
    setResendDisabled(true)
    timerId()

  }
  const sendMobileOtp = () => {
    setResendLoader(true)
    let locale = Localization.locale.split("-")[0]

    performRequest("get", API_URL + `api/v2/pwa/number/verify/auth?number=${number}&locale=${locale}&venue_name=${props.config.displayName ? props.config.displayName : props.config.venueName}&channel=SMS&venue_id=${props.config.venue}`, {})
      .then((response) => {
        setService(response.data.service)
        setShowMobileOtp(true)
        setNextDisabled(false)
        setResendLoader(false)
      })
      .catch(() => {
        setResendLoader(false)
        setNextDisabled(false)
        throwMessageFn('Error sending otp. Try again.', 'red')
      })
    setTimeLeft(30)
    setResendDisabled(true)
    timerId()
  }
  const resendCode = (type) => {
    if(type=="email"){
      sendEmailOtp()
    }else{
      sendMobileOtp()
    }
  }
  const timerId = () => {
    interval = setInterval(() => countdown(), 1000)
  };


  const countdown = () => {
    if (timeLeft == -1) {
      clearTimeout(interval);
      setResendDisabled(false)
    } else {
      setTimeLeft(timeLeft - 1)
    }
  }


  return (
    <ScrollView style={{ backgroundColor: props.design.list_view_background_color }}>




      <StyledText
      font_color={props.design.list_view_header_color}
      font_weight={props.design.list_view_header_font_weight}
      font_style={props.design.list_view_header_font_style}
      text_transform={props.design.list_view_header_font_transform}
      font_family={props.design.list_view_header_font_family}
      font_size={props.design.list_view_header_font_size?? 20}
      letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

        style={{
         
          marginTop: 50,
          marginVertical: 20,
          marginLeft: 11,
        
        }}
      >
        {i18n.t("labels.about")}
      </StyledText>
      <View style={{ width: "96%", alignSelf: "center" }}>
        <StyledText 
        font_color={props.design.list_view_text_color}
        font_weight={props.design.list_view_text_font_weight}
        font_style={props.design.list_view_text_font_style}
        text_transform={props.design.list_view_text_font_transform}
        font_family={props.design.list_view_text_font_family}
        font_size={props.design.list_view_text_font_size?? 12}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

        style={{ }}>Your First Name *</StyledText>
        <View style={{ height: 35, marginTop: 5, marginBottom: 20 }}>
          <Input
            value={first_name}
            onChangeText={(text) => setFirstName(text)}
            style={{
              color: "grey",
              border: "1px solid grey",
              borderRadius: 5,
            }}
          />
        </View>

        <StyledText 
        font_color={props.design.list_view_text_color}
        font_weight={props.design.list_view_text_font_weight}
        font_style={props.design.list_view_text_font_style}
        text_transform={props.design.list_view_text_font_transform}
        font_family={props.design.list_view_text_font_family}
        font_size={props.design.list_view_text_font_size?? 12}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

        style={{  }} >Your Last Name </StyledText>
        <View style={{ height: 35, marginTop: 5, marginBottom: 20 }}>
          <Input
            value={last_name}
            onChangeText={(text) => setLastName(text)}
            style={{
              color: "grey",
              border: "1px solid grey",
              borderRadius: 5, 
            }}
          />
        </View>


        <StyledText 
        font_color={props.design.list_view_text_color}
        font_weight={props.design.list_view_text_font_weight}
        font_style={props.design.list_view_text_font_style}
        text_transform={props.design.list_view_text_font_transform}
        font_family={props.design.list_view_text_font_family}
        font_size={props.design.list_view_text_font_size?? 12}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

        style={{  }} >{i18n.t("checkout.phone_number")}</StyledText>
        <View style={{ height: 35, marginTop: 5, marginBottom: 20 }}>
          <View
            style={{
              flexDirection: "row",
              // border: "1px solid grey",
              borderRadius: 5,
            }}
          >

            <PhoneInput
              disabled={is_phone_verified}
              style={{
                fontSize: props.design.custom_font.heading_size ?? 30,
                fontWeight: props.design.custom_font.heading_is_bold ? "bold": "normal",
                letterSpacing: FontLetterSpacing(props.design.custom_font.secondary_letter_space),
                height: '100%',
                width: '100%',
              }}
              defaultCountry={countryCode}
              value={number}
              onChange={(text) => setMobileNumber(text)}

            />


          </View>
        </View>

        <View style={{
          width: '95%',
          marginTop: 50,
          marginBottom: 10,

        }}>

          <StyledText 
          font_color={props.design.list_view_text_color}
          font_weight={props.design.list_view_text_font_weight}
          font_style={props.design.list_view_text_font_style}
          text_transform={props.design.list_view_text_font_transform}
          font_family={props.design.list_view_text_font_family}
          font_size={props.design.list_view_text_font_size?? 12}
          letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

          style={{  }}>{i18n.t("labels.email_notification_info")}</StyledText>
        </View>

        <StyledText 
        font_color={props.design.list_view_text_color}
        font_weight={props.design.list_view_text_font_weight}
        font_style={props.design.list_view_text_font_style}
        text_transform={props.design.list_view_text_font_transform}
        font_family={props.design.list_view_text_font_family}
        font_size={props.design.list_view_text_font_size?? 12}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

        style={{  }} >Email Address </StyledText>
        <View style={{ height: 35, marginTop: 5, marginBottom: 20 }}>
          <Input
            value={email}
            disabled={is_email_verified}
            onChangeText={(text) => setEmail(text)}
            style={{
              color: 'grey',
              border: '1px solid grey',
              borderRadius: 5
            }}
          />


        </View>

        {/* <View style={{
          // height: 50,
          width: '95%',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: 5,
          // marginLeft:10

        }}>
          <Text style={{ fontSize: 12, color: props.design.list_view_text_color, fontFamily: 'SecondaryFont' }}>OR</Text>
        </View> */}




        {/* <>
          <View style={{
            width: '95%',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',

          }}>
            <TouchableOpacity onPress={() => prompt()}>
              <View style={style.socialButtons}>
                <Image source={require('../../assets/google.png')} style={{ width: 23, height: 23 }} />
              </View>
            </TouchableOpacity>


            <FacebookLogin
              redirectUri={REDIRECT_URL}
              appId={FB_CLIENT}
              isMobile={false}
              autoLoad={false}
              fields="name,email,picture"
              callback={(response) => {
                if (response && response.email) {
                  console.log(response, "response")
                  const {email, name, picture} = response
                  let formData = new FormData();
                  formData.append('email', email);
                  formData.append('name', name);
                  // formData.append('name', name);
                  formData.append('mobile_number', mobile_number ?  country.callingCode[0]+mobile_number:"");

                  let picture_url = ''
                  if(picture){
                      picture_url = picture.data.url
                      formData.append('picture_url', picture_url);
                  }
                  performRequest('post', 'api/v2/pwa/facebook-auth', formData, false)
                  .then(response=> {
                      AsyncStorage.setItem('login_with', 'facebook')
                      postAuthentication(response)
                  })
                  .catch(error=>{
                      throwMessageFn('Error authenticating with Facebook. Try again', 'red')
                  })
                } else {
                  throwMessageFn('Error authenticating with Facebook. Try again', 'red')

                }

              }}
              icon={require('../../assets/facebook.png')}
              containerStyle={style.socialButtons}
              buttonStyle={style.socialButtons}
              render={renderProps =>
                <TouchableOpacity
                  onPress={() => renderProps.onClick()}
                // onPress={() =>  promptAsync()}
                >
                  <View style={style.socialButtons}>
                    <Image source={require('../../assets/facebook.png')} style={{ width: 23, height: 23 }} />
                  </View>
                </TouchableOpacity>
              }
            />


            <AppleSignin
              authOptions={{
                clientId: APPLEID,
                scope: 'email name',
                redirectURI: REDIRECT_URL,
                state: '',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType="dark"
              className="apple-auth-btn"
              buttonExtraChildren="Continue with Apple"
             
              onSuccess={(response) => {
                setNextDisabled(true)
                const { id_token, code } = response.authorization
                performRequest('post', 'api/v2/pwa/apple-auth', { token: id_token, code, mobile_number: mobile_number ? mobile_number : "" }, false)
                  .then(response => {
                    setEmail(response.data.email);
                    setNextDisabled(false)

                    postAuthentication(response)
                  })
                  .catch(error => {
                    setNextDisabled(false)

                    throwMessageFn('Error authenticating with Apple. Try again', 'red')
                  })
                console.log(response, "ssssssss")

              }} // default = undefined
              render={(props) => <TouchableOpacity {...props}>
                <View style={style.socialButtons}>
                  <Image source={require('../../assets/apple.png')} style={{ width: 23, height: 23 }} />
                </View>
              </TouchableOpacity>}
            />


          </View>

        </> */}

        <Button
          disabled={next_disabled}
          onPress={() => ValidateEmail()}
          success
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            backgroundColor: props.design.top_buttons_background_color,
            width: '100%', borderRadius: 5
          }}
        >
          {
            next_disabled ?
              <Spinner color={props.design.top_buttons_text_color} />
              :
              <StyledText 
              font_color={props.design.top_buttons_text_color}
                font_weight={props.design.top_button_text_font_weight}
                font_style={props.design.top_button_text_font_style}
                text_transform={props.design.top_button_text_font_transform}
                font_family={props.design.top_button_text_font_family}
                font_size={props.design.top_button_text_font_size}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

              style={{ color: props.design.top_buttons_text_color, fontFamily: 'SecondaryFont' }}> Continue </StyledText>
          }
        </Button>
      </View>


      <Modal
        animationType="slide"
        transparent
        visible={showEmailOtp}
       
      >
        <TouchableOpacity
          style={{ flex: 1, alignItems: "center", justifyContent: "center", 
          // backdropFilter: "blur(5px)" 
          backgroundColor: "rgba(0, 0, 0, 0.5)"
         }}
          onPressOut={() => {
            setShowEmailOtp(false)
            setEmail("")
            setNextDisabled(false)
          }}

        >
          <TouchableWithoutFeedback>
          <View
            style={{
              width: width * 0.8,
              backgroundColor: "white",
   
              padding: 30,
         
              borderRadius: 4,
              alignItems: "center",
              maxHeight: "80%",
              borderWidth:1
            }}
          >
             
            <StyledText 
            font_color={props.design.list_view_text_color}
            font_weight={props.design.list_view_text_font_weight}
            font_style={props.design.list_view_text_font_style}
            text_transform={props.design.list_view_text_font_transform}
            font_family={props.design.list_view_text_font_family}
            font_size={props.design.list_view_text_font_size?? 12}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
    
            style={{ marginTop: 15, fontFamily: 'SecondaryFont', color: props.design.list_view_text_color }}>Enter the code sent to </StyledText>
            <StyledText
            font_color={props.design.list_view_text_color}
            font_weight={props.design.list_view_text_font_weight}
            font_style={props.design.list_view_text_font_style}
            text_transform={props.design.list_view_text_font_transform}
            font_family={props.design.list_view_text_font_family}
            font_size={props.design.list_view_text_font_size?? 12}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
    
            style={{ marginTop: 5,marginBottom: 6 }}>{email}</StyledText>
            <OtpInput
              autocomplete="one-time-code"
              isInputNum={true}
              type={"tel"}
              value={otp}
              onChange={(text)=>onChangeOTP(text, "email")}
              numInputs={4}
              separator={<span>-</span>}

              inputStyle={{
                height: 50,
                width: 50,

                fontSize: 40,

              }}
            />

            <View style={{ height: 30, width: '90%',}}>
              <TouchableOpacity
                style={{ height: 30, }}
                disabled={resendDisabled}
                onPress={()=>resendCode("email")}

              >
                <View style={{
                  width: '100%',
                  height: '100%',
                  marginTop: 20,
                  borderRadius: 8,
                  border: '1px solid grey',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: props.design.top_buttons_background_color
                }}>
                  {
                    resendLoader ?
                      <Spinner color={props.design.top_buttons_text_color} />
                      :
                      <StyledText
                      font_color={props.design.top_buttons_text_color}
                    font_weight={props.design.top_button_text_font_weight}
                    font_style={props.design.top_button_text_font_style}
                    text_transform={props.design.top_button_text_font_transform}
                    font_family={props.design.top_button_text_font_family}
                    font_size={props.design.top_button_text_font_size}
                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                       style={{ fontSize:12 }} >Resend code {timeLeft != -1 && `( ${timeLeft} )`}</StyledText>
                  }


                </View>
              </TouchableOpacity>
            
            </View>

          </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="slide"
        transparent
        visible={showMobileOtp}
        
      >
        <TouchableOpacity
          style={{ flex: 1, alignItems: "center", justifyContent: "center", 
          // backdropFilter: "blur(5px)" 
          backgroundColor: "rgba(0, 0, 0, 0.5)"
         }}
          onPressOut={() => {
            setShowMobileOtp(false)
            setEmail("")
            setNextDisabled(false)
          }}

        >
          <TouchableWithoutFeedback>
          <View
            style={{
              width: width * 0.8,
              backgroundColor: "white",
              padding: 30,
              borderRadius: 4,
              alignItems: "center",
              maxHeight: "80%",
              borderWidth:1

            }}
          >     
              
          <StyledText 
          font_color={props.design.list_view_text_color}
          font_weight={props.design.list_view_text_font_weight}
          font_style={props.design.list_view_text_font_style}
          text_transform={props.design.list_view_text_font_transform}
          font_family={props.design.list_view_text_font_family}
          font_size={props.design.list_view_text_font_size?? 12}
          letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
  
          style={{ marginTop: 15, }}>Enter the code sent to </StyledText>
            <StyledText 
            font_color={props.design.list_view_text_color}
            font_weight={props.design.list_view_text_font_weight}
            font_style={props.design.list_view_text_font_style}
            text_transform={props.design.list_view_text_font_transform}
            font_family={props.design.list_view_text_font_family}
            font_size={props.design.list_view_text_font_size?? 12}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
    
            style={{ marginTop: 5 }}>{number}</StyledText>
            <OtpInput
              autocomplete="one-time-code"
              isInputNum={true}
              type={"tel"}
              value={otp}
              onChange={(text)=>onChangeOTP(text, "mobile")}
              numInputs={4}
              separator={<span>-</span>}

              inputStyle={{
                height: 50,
                width: 50,

                fontSize: 40,

              }}
            />
            <View style={{ height: 30, width: '90%',  }}>
              <TouchableOpacity
                style={{ height: 30, }}
                disabled={resendDisabled}
                onPress={()=>resendCode("mobile")}

              >
                <View style={{
                  width: '100%',
                  height: '100%',
                  marginTop: 20,
                  borderRadius: 8,
                  border: '1px solid grey',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: props.design.top_buttons_background_color
                }}>
                  {
                    resendLoader ?
                      <Spinner color={props.design.top_buttons_text_color} />
                      :
                      <StyledText 
                      font_color={props.design.top_buttons_text_color}
                    font_weight={props.design.top_button_text_font_weight}
                    font_style={props.design.top_button_text_font_style}
                    text_transform={props.design.top_button_text_font_transform}
                    font_family={props.design.top_button_text_font_family}
                    font_size={props.design.top_button_text_font_size}
                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                      style={{fontSize:12}} >Resend code {timeLeft != -1 && `( ${timeLeft} )`}</StyledText>
                  }
                </View>
              </TouchableOpacity>
            </View>

          </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <TouchableOpacity
        style={{ position: "absolute", top: 0 }}
        onPress={() => props.navigation.goBack()}
      >
        <BackButton
          border_color={`1px solid ${props.design.top_buttons_border_color}`}
          backgroundColor={props.design.top_buttons_background_color}
          borderColor={"black"}
          color={props.design.top_buttons_text_color}
          default_language={props.default_language}
          font_size={props.design.custom_font.heading_size}
        />
      </TouchableOpacity>

      <Toast
        error_message={error_message}
        message_status={message_status}
        message_display={message_display}
        custom_font={props.design.custom_font}
      />
    </ScrollView>
  );
};
UserRegistration["navigationOptions"] = (screenProps) => ({
  header: null,
  gesturesEnabled: false,
  animationEnabled: true,
});

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    menu_type: state.config.menu_type,
    platform: state.config.platform,
    design: state.config.design,

  };
};

export default connect(mapStateToProps, { adduser, addToken, feedbackModal })(
  UserRegistration
);
