'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var os = require('os');  /* jshint ignore:line */
var url = require('url');  /* jshint ignore:line */
var moduleInfo = require('../../package.json');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var RestException = require('../base/RestException');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Twilio Client to interact with the Rest API
 *
 * @constructor Twilio
 *
 * @property {Twilio.Accounts} accounts - accounts domain
 * @property {Twilio.Api} api - api domain
 * @property {Twilio.Autopilot} autopilot - autopilot domain
 * @property {Twilio.Chat} chat - chat domain
 * @property {Twilio.Content} content - content domain
 * @property {Twilio.Conversations} conversations - conversations domain
 * @property {Twilio.Events} events - events domain
 * @property {Twilio.FlexApi} flexApi - flex_api domain
 * @property {Twilio.FrontlineApi} frontlineApi - frontline_api domain
 * @property {Twilio.Insights} insights - insights domain
 * @property {Twilio.IpMessaging} ipMessaging - ip_messaging domain
 * @property {Twilio.Lookups} lookups - lookups domain
 * @property {Twilio.Media} media - media domain
 * @property {Twilio.Messaging} messaging - messaging domain
 * @property {Twilio.Monitor} monitor - monitor domain
 * @property {Twilio.Notify} notify - notify domain
 * @property {Twilio.Numbers} numbers - numbers domain
 * @property {Twilio.Oauth} oauth - oauth domain
 * @property {Twilio.Preview} preview - preview domain
 * @property {Twilio.Pricing} pricing - pricing domain
 * @property {Twilio.Proxy} proxy - proxy domain
 * @property {Twilio.Routes} routes - routes domain
 * @property {Twilio.Serverless} serverless - serverless domain
 * @property {Twilio.Studio} studio - studio domain
 * @property {Twilio.Sync} sync - sync domain
 * @property {Twilio.Taskrouter} taskrouter - taskrouter domain
 * @property {Twilio.Trunking} trunking - trunking domain
 * @property {Twilio.Trusthub} trusthub - trusthub domain
 * @property {Twilio.Verify} verify - verify domain
 * @property {Twilio.Video} video - video domain
 * @property {Twilio.Voice} voice - voice domain
 * @property {Twilio.Wireless} wireless - wireless domain
 * @property {Twilio.Supersim} supersim - supersim domain
 * @property {Twilio.Bulkexports} bulkexports - bulkexports domain
 * @property {Twilio.Microvisor} microvisor - microvisor domain
 * @property {Twilio.Api.V2010.AccountContext.AddressList} addresses -
 *          addresses resource
 * @property {Twilio.Api.V2010.AccountContext.ApplicationList} applications -
 *          applications resource
 * @property {Twilio.Api.V2010.AccountContext.AuthorizedConnectAppList} authorizedConnectApps -
 *          authorizedConnectApps resource
 * @property {Twilio.Api.V2010.AccountContext.AvailablePhoneNumberCountryList} availablePhoneNumbers -
 *          availablePhoneNumbers resource
 * @property {Twilio.Api.V2010.AccountContext.BalanceList} balance -
 *          balance resource
 * @property {Twilio.Api.V2010.AccountContext.CallList} calls - calls resource
 * @property {Twilio.Api.V2010.AccountContext.ConferenceList} conferences -
 *          conferences resource
 * @property {Twilio.Api.V2010.AccountContext.ConnectAppList} connectApps -
 *          connectApps resource
 * @property {Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList} incomingPhoneNumbers -
 *          incomingPhoneNumbers resource
 * @property {Twilio.Api.V2010.AccountContext.KeyList} keys - keys resource
 * @property {Twilio.Api.V2010.AccountContext.MessageList} messages -
 *          messages resource
 * @property {Twilio.Api.V2010.AccountContext.NewKeyList} newKeys -
 *          newKeys resource
 * @property {Twilio.Api.V2010.AccountContext.NewSigningKeyList} newSigningKeys -
 *          newSigningKeys resource
 * @property {Twilio.Api.V2010.AccountContext.NotificationList} notifications -
 *          notifications resource
 * @property {Twilio.Api.V2010.AccountContext.OutgoingCallerIdList} outgoingCallerIds -
 *          outgoingCallerIds resource
 * @property {Twilio.Api.V2010.AccountContext.QueueList} queues - queues resource
 * @property {Twilio.Api.V2010.AccountContext.RecordingList} recordings -
 *          recordings resource
 * @property {Twilio.Api.V2010.AccountContext.SigningKeyList} signingKeys -
 *          signingKeys resource
 * @property {Twilio.Api.V2010.AccountContext.SipList} sip - sip resource
 * @property {Twilio.Api.V2010.AccountContext.ShortCodeList} shortCodes -
 *          shortCodes resource
 * @property {Twilio.Api.V2010.AccountContext.TokenList} tokens - tokens resource
 * @property {Twilio.Api.V2010.AccountContext.TranscriptionList} transcriptions -
 *          transcriptions resource
 * @property {Twilio.Api.V2010.AccountContext.UsageList} usage - usage resource
 * @property {Twilio.Api.V2010.AccountContext.ValidationRequestList} validationRequests -
 *          validationRequests resource
 *
 * @param {string} username -
 *          The username used for authentication. This is normally account sid, but if using key/secret auth will be the api key sid.
 * @param {string} password -
 *          The password used for authentication. This is normally auth token, but if using key/secret auth will be the secret.
 * @param {object} [opts] - The options argument
 * @param {RequestClient} [opts.httpClient] -
 *          The client used for http requests. Defaults to RequestClient
 * @param {string} [opts.accountSid] -
 *          The default accountSid. This is set to username if not provided
 * @param {object} [opts.env] - The environment object. Defaults to process.env
 * @param {string} [opts.edge] - Twilio edge to use. Defaults to none
 * @param {string} [opts.region] -
 *          Twilio region to use. Defaults to us1 if edge defined
 * @param {boolean} [opts.lazyLoading] -
 *          Enable lazy loading, loading time will decrease if enabled. Defaults to true
 * @param {string} [opts.logLevel] - Debug logs will be shown. Defaults to none
 * @param {string[]} [opts.userAgentExtensions] -
 *          Additions to the user agent string
 *
 * @returns {Twilio} A new instance of Twilio client
 */
/* jshint ignore:end */
function Twilio(username, password, opts) {
  opts = opts || {};
  var env = opts.env || process.env;

  this.username = username || env.TWILIO_ACCOUNT_SID;
  this.password = password || env.TWILIO_AUTH_TOKEN;
  this.accountSid = opts.accountSid || this.username;
  this._httpClient = opts.httpClient;
  if (opts.lazyLoading == false) {
    this._httpClient = this.httpClient;
  }
  this.edge = opts.edge || env.TWILIO_EDGE;
  this.region = opts.region || env.TWILIO_REGION;
  this.logLevel = opts.logLevel || env.TWILIO_LOG_LEVEL;
  this.userAgentExtensions = opts.userAgentExtensions || [];

  if (!this.username) {
    throw new Error('username is required');
  }

  if (!this.password) {
    throw new Error('password is required');
  }

  if (!this.accountSid.startsWith('AC')) {
    throw new Error('accountSid must start with AC');
  }

  // Domains
  this._accounts = undefined;
  this._api = undefined;
  this._autopilot = undefined;
  this._chat = undefined;
  this._content = undefined;
  this._conversations = undefined;
  this._events = undefined;
  this._flexApi = undefined;
  this._frontlineApi = undefined;
  this._insights = undefined;
  this._ipMessaging = undefined;
  this._lookups = undefined;
  this._media = undefined;
  this._messaging = undefined;
  this._monitor = undefined;
  this._notify = undefined;
  this._numbers = undefined;
  this._oauth = undefined;
  this._preview = undefined;
  this._pricing = undefined;
  this._proxy = undefined;
  this._routes = undefined;
  this._serverless = undefined;
  this._studio = undefined;
  this._sync = undefined;
  this._taskrouter = undefined;
  this._trunking = undefined;
  this._trusthub = undefined;
  this._verify = undefined;
  this._video = undefined;
  this._voice = undefined;
  this._wireless = undefined;
  this._supersim = undefined;
  this._bulkexports = undefined;
  this._microvisor = undefined;

  if (opts.lazyLoading == false) {
    this.accounts;
    this.api;
    this.autopilot;
    this.chat;
    this.content;
    this.conversations;
    this.events;
    this.flexApi;
    this.frontlineApi;
    this.insights;
    this.ipMessaging;
    this.lookups;
    this.media;
    this.messaging;
    this.monitor;
    this.notify;
    this.numbers;
    this.oauth;
    this.preview;
    this.pricing;
    this.proxy;
    this.routes;
    this.serverless;
    this.studio;
    this.sync;
    this.taskrouter;
    this.trunking;
    this.trusthub;
    this.verify;
    this.video;
    this.voice;
    this.wireless;
    this.supersim;
    this.bulkexports;
    this.microvisor;
  }
}

/* jshint ignore:start */
/**
 * Makes a request to the Twilio API using the configured http client.
 * Authentication information is automatically added if none is provided.
 *
 * @function request
 * @memberof Twilio#
 *
 * @param {object} opts - The options argument
 * @param {string} opts.method - The http method
 * @param {string} opts.uri - The request uri
 * @param {string} [opts.username] - The username used for auth
 * @param {string} [opts.password] - The password used for auth
 * @param {object} [opts.headers] - The request headers
 * @param {object} [opts.params] - The request params
 * @param {object} [opts.data] - The request data
 * @param {int} [opts.timeout] - The request timeout in milliseconds
 * @param {boolean} [opts.allowRedirects] - Should the client follow redirects
 * @param {string} [opts.logLevel] - Show debug logs
 */
/* jshint ignore:end */
Twilio.prototype.request = function request(opts) {
  opts = opts || {};

  if (!opts.method) {
    throw new Error('method is required');
  }

  if (!opts.uri) {
    throw new Error('uri is required');
  }

  var username = opts.username || this.username;
  var password = opts.password || this.password;

  var headers = opts.headers || {};

  var pkgVersion = moduleInfo.version;
  var osName = os.platform();
  var osArch = os.arch();
  var nodeVersion = process.version;
  headers['User-Agent'] = util.format(
    'twilio-node/%s (%s %s) node/%s',
    pkgVersion,
    osName,
    osArch,
    nodeVersion
  );
  this.userAgentExtensions.forEach(extension => {
    headers['User-Agent'] += ` ${extension}`;
  });
  headers['Accept-Charset'] = 'utf-8';

  if (opts.method === 'POST' && !headers['Content-Type']) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  if (!headers.Accept) {
    headers.Accept = 'application/json';
  }

  var uri = new url.URL(opts.uri);
  uri.hostname = this.getHostname(uri.hostname, this.edge, this.region);

  return this.httpClient.request({
    method: opts.method,
    uri: uri.href,
    username: username,
    password: password,
    headers: headers,
    params: opts.params,
    data: opts.data,
    timeout: opts.timeout,
    allowRedirects: opts.allowRedirects,
    logLevel: opts.logLevel || this.logLevel
  });
};

/* jshint ignore:start */
/**
 * Adds a region and/or edge to a given hostname
 *
 * @function getHostname
 * @memberof Twilio#
 *
 * @param {string} hostname - A URI hostname (e.g. api.twilio.com)
 * @param {string} targetEdge - The targeted edge location (e.g. sydney)
 * @param {string} targetRegion - The targeted region location (e.g. au1)
 */
/* jshint ignore:end */
Twilio.prototype.getHostname = function getHostname(hostname, targetEdge,
                                                     targetRegion) {
  const defaultRegion = 'us1';

  const domain = hostname.split('.').slice(-2).join('.');
  const prefix = hostname.split('.' + domain)[0];
  let [product, edge, region] = prefix.split('.');
  if (edge && !region) {
    region = edge;
    edge = undefined;
  }

  region = targetRegion || region || (targetEdge && defaultRegion);
  if (!region) {
    return hostname;
  }
  edge = targetEdge || edge;

  return [product, edge, region, domain].filter(part => part).join('.');
};

/* jshint ignore:start */
/**
 * Validates that a request to the new SSL certificate is successful.
 *
 * @throws {RestException} if the request fails
 *
 * @function validateSslCert
 * @memberof Twilio#
 */
/* jshint ignore:end */
Twilio.prototype.validateSslCert = function validateSslCert() {
  return this.httpClient.request({
    method: 'GET',
    uri: 'https://api.twilio.com:8443/2010-04-01/.json',
  }).then((response) => {
    if (response.statusCode < 200 || response.statusCode >= 300) {
      throw new RestException(response);
    }

    return response;
  });
};

Object.defineProperty(Twilio.prototype,
  'httpClient', {
    get: function() {
      if (!this._httpClient) {
        var RequestClient = require('../base/RequestClient');  /* jshint ignore:line */
        this._httpClient = new RequestClient();
      }
      return this._httpClient;
    }
});

Object.defineProperty(Twilio.prototype,
  'accounts', {
    get: function() {
      if (!this._accounts) {
        var Accounts = require('./Accounts');  /* jshint ignore:line */
        this._accounts = new Accounts(this);
      }
      return this._accounts;
    }
});

Object.defineProperty(Twilio.prototype,
  'api', {
    get: function() {
      if (!this._api) {
        var Api = require('./Api');  /* jshint ignore:line */
        this._api = new Api(this);
      }
      return this._api;
    }
});

Object.defineProperty(Twilio.prototype,
  'autopilot', {
    get: function() {
      if (!this._autopilot) {
        var Autopilot = require('./Autopilot');  /* jshint ignore:line */
        this._autopilot = new Autopilot(this);
      }
      return this._autopilot;
    }
});

Object.defineProperty(Twilio.prototype,
  'chat', {
    get: function() {
      if (!this._chat) {
        var Chat = require('./Chat');  /* jshint ignore:line */
        this._chat = new Chat(this);
      }
      return this._chat;
    }
});

Object.defineProperty(Twilio.prototype,
  'content', {
    get: function() {
      if (!this._content) {
        var Content = require('./Content');  /* jshint ignore:line */
        this._content = new Content(this);
      }
      return this._content;
    }
});

Object.defineProperty(Twilio.prototype,
  'conversations', {
    get: function() {
      if (!this._conversations) {
        var Conversations = require('./Conversations');  /* jshint ignore:line */
        this._conversations = new Conversations(this);
      }
      return this._conversations;
    }
});

Object.defineProperty(Twilio.prototype,
  'events', {
    get: function() {
      if (!this._events) {
        var Events = require('./Events');  /* jshint ignore:line */
        this._events = new Events(this);
      }
      return this._events;
    }
});

Object.defineProperty(Twilio.prototype,
  'flexApi', {
    get: function() {
      if (!this._flexApi) {
        var FlexApi = require('./FlexApi');  /* jshint ignore:line */
        this._flexApi = new FlexApi(this);
      }
      return this._flexApi;
    }
});

Object.defineProperty(Twilio.prototype,
  'frontlineApi', {
    get: function() {
      if (!this._frontlineApi) {
        var FrontlineApi = require('./FrontlineApi');  /* jshint ignore:line */
        this._frontlineApi = new FrontlineApi(this);
      }
      return this._frontlineApi;
    }
});

Object.defineProperty(Twilio.prototype,
  'insights', {
    get: function() {
      if (!this._insights) {
        var Insights = require('./Insights');  /* jshint ignore:line */
        this._insights = new Insights(this);
      }
      return this._insights;
    }
});

Object.defineProperty(Twilio.prototype,
  'ipMessaging', {
    get: function() {
      if (!this._ipMessaging) {
        var IpMessaging = require('./IpMessaging');  /* jshint ignore:line */
        this._ipMessaging = new IpMessaging(this);
      }
      return this._ipMessaging;
    }
});

Object.defineProperty(Twilio.prototype,
  'lookups', {
    get: function() {
      if (!this._lookups) {
        var Lookups = require('./Lookups');  /* jshint ignore:line */
        this._lookups = new Lookups(this);
      }
      return this._lookups;
    }
});

Object.defineProperty(Twilio.prototype,
  'media', {
    get: function() {
      if (!this._media) {
        var Media = require('./Media');  /* jshint ignore:line */
        this._media = new Media(this);
      }
      return this._media;
    }
});

Object.defineProperty(Twilio.prototype,
  'messaging', {
    get: function() {
      if (!this._messaging) {
        var Messaging = require('./Messaging');  /* jshint ignore:line */
        this._messaging = new Messaging(this);
      }
      return this._messaging;
    }
});

Object.defineProperty(Twilio.prototype,
  'monitor', {
    get: function() {
      if (!this._monitor) {
        var Monitor = require('./Monitor');  /* jshint ignore:line */
        this._monitor = new Monitor(this);
      }
      return this._monitor;
    }
});

Object.defineProperty(Twilio.prototype,
  'notify', {
    get: function() {
      if (!this._notify) {
        var Notify = require('./Notify');  /* jshint ignore:line */
        this._notify = new Notify(this);
      }
      return this._notify;
    }
});

Object.defineProperty(Twilio.prototype,
  'numbers', {
    get: function() {
      if (!this._numbers) {
        var Numbers = require('./Numbers');  /* jshint ignore:line */
        this._numbers = new Numbers(this);
      }
      return this._numbers;
    }
});

Object.defineProperty(Twilio.prototype,
  'oauth', {
    get: function() {
      if (!this._oauth) {
        var Oauth = require('./Oauth');  /* jshint ignore:line */
        this._oauth = new Oauth(this);
      }
      return this._oauth;
    }
});

Object.defineProperty(Twilio.prototype,
  'preview', {
    get: function() {
      if (!this._preview) {
        var Preview = require('./Preview');  /* jshint ignore:line */
        this._preview = new Preview(this);
      }
      return this._preview;
    }
});

Object.defineProperty(Twilio.prototype,
  'pricing', {
    get: function() {
      if (!this._pricing) {
        var Pricing = require('./Pricing');  /* jshint ignore:line */
        this._pricing = new Pricing(this);
      }
      return this._pricing;
    }
});

Object.defineProperty(Twilio.prototype,
  'proxy', {
    get: function() {
      if (!this._proxy) {
        var Proxy = require('./Proxy');  /* jshint ignore:line */
        this._proxy = new Proxy(this);
      }
      return this._proxy;
    }
});

Object.defineProperty(Twilio.prototype,
  'routes', {
    get: function() {
      if (!this._routes) {
        var Routes = require('./Routes');  /* jshint ignore:line */
        this._routes = new Routes(this);
      }
      return this._routes;
    }
});

Object.defineProperty(Twilio.prototype,
  'serverless', {
    get: function() {
      if (!this._serverless) {
        var Serverless = require('./Serverless');  /* jshint ignore:line */
        this._serverless = new Serverless(this);
      }
      return this._serverless;
    }
});

Object.defineProperty(Twilio.prototype,
  'studio', {
    get: function() {
      if (!this._studio) {
        var Studio = require('./Studio');  /* jshint ignore:line */
        this._studio = new Studio(this);
      }
      return this._studio;
    }
});

Object.defineProperty(Twilio.prototype,
  'sync', {
    get: function() {
      if (!this._sync) {
        var Sync = require('./Sync');  /* jshint ignore:line */
        this._sync = new Sync(this);
      }
      return this._sync;
    }
});

Object.defineProperty(Twilio.prototype,
  'taskrouter', {
    get: function() {
      if (!this._taskrouter) {
        var Taskrouter = require('./Taskrouter');  /* jshint ignore:line */
        this._taskrouter = new Taskrouter(this);
      }
      return this._taskrouter;
    }
});

Object.defineProperty(Twilio.prototype,
  'trunking', {
    get: function() {
      if (!this._trunking) {
        var Trunking = require('./Trunking');  /* jshint ignore:line */
        this._trunking = new Trunking(this);
      }
      return this._trunking;
    }
});

Object.defineProperty(Twilio.prototype,
  'trusthub', {
    get: function() {
      if (!this._trusthub) {
        var Trusthub = require('./Trusthub');  /* jshint ignore:line */
        this._trusthub = new Trusthub(this);
      }
      return this._trusthub;
    }
});

Object.defineProperty(Twilio.prototype,
  'verify', {
    get: function() {
      if (!this._verify) {
        var Verify = require('./Verify');  /* jshint ignore:line */
        this._verify = new Verify(this);
      }
      return this._verify;
    }
});

Object.defineProperty(Twilio.prototype,
  'video', {
    get: function() {
      if (!this._video) {
        var Video = require('./Video');  /* jshint ignore:line */
        this._video = new Video(this);
      }
      return this._video;
    }
});

Object.defineProperty(Twilio.prototype,
  'voice', {
    get: function() {
      if (!this._voice) {
        var Voice = require('./Voice');  /* jshint ignore:line */
        this._voice = new Voice(this);
      }
      return this._voice;
    }
});

Object.defineProperty(Twilio.prototype,
  'wireless', {
    get: function() {
      if (!this._wireless) {
        var Wireless = require('./Wireless');  /* jshint ignore:line */
        this._wireless = new Wireless(this);
      }
      return this._wireless;
    }
});

Object.defineProperty(Twilio.prototype,
  'supersim', {
    get: function() {
      if (!this._supersim) {
        var Supersim = require('./Supersim');  /* jshint ignore:line */
        this._supersim = new Supersim(this);
      }
      return this._supersim;
    }
});

Object.defineProperty(Twilio.prototype,
  'bulkexports', {
    get: function() {
      if (!this._bulkexports) {
        var Bulkexports = require('./Bulkexports');  /* jshint ignore:line */
        this._bulkexports = new Bulkexports(this);
      }
      return this._bulkexports;
    }
});

Object.defineProperty(Twilio.prototype,
  'microvisor', {
    get: function() {
      if (!this._microvisor) {
        var Microvisor = require('./Microvisor');  /* jshint ignore:line */
        this._microvisor = new Microvisor(this);
      }
      return this._microvisor;
    }
});

Object.defineProperty(Twilio.prototype,
  'addresses', {
    get: function() {
      return this.api.account.addresses;
    }
});

Object.defineProperty(Twilio.prototype,
  'applications', {
    get: function() {
      return this.api.account.applications;
    }
});

Object.defineProperty(Twilio.prototype,
  'authorizedConnectApps', {
    get: function() {
      return this.api.account.authorizedConnectApps;
    }
});

Object.defineProperty(Twilio.prototype,
  'availablePhoneNumbers', {
    get: function() {
      return this.api.account.availablePhoneNumbers;
    }
});

Object.defineProperty(Twilio.prototype,
  'balance', {
    get: function() {
      return this.api.account.balance;
    }
});

Object.defineProperty(Twilio.prototype,
  'calls', {
    get: function() {
      return this.api.account.calls;
    }
});

Object.defineProperty(Twilio.prototype,
  'conferences', {
    get: function() {
      return this.api.account.conferences;
    }
});

Object.defineProperty(Twilio.prototype,
  'connectApps', {
    get: function() {
      return this.api.account.connectApps;
    }
});

Object.defineProperty(Twilio.prototype,
  'incomingPhoneNumbers', {
    get: function() {
      return this.api.account.incomingPhoneNumbers;
    }
});

Object.defineProperty(Twilio.prototype,
  'keys', {
    get: function() {
      return this.api.account.keys;
    }
});

Object.defineProperty(Twilio.prototype,
  'messages', {
    get: function() {
      return this.api.account.messages;
    }
});

Object.defineProperty(Twilio.prototype,
  'newKeys', {
    get: function() {
      return this.api.account.newKeys;
    }
});

Object.defineProperty(Twilio.prototype,
  'newSigningKeys', {
    get: function() {
      return this.api.account.newSigningKeys;
    }
});

Object.defineProperty(Twilio.prototype,
  'notifications', {
    get: function() {
      return this.api.account.notifications;
    }
});

Object.defineProperty(Twilio.prototype,
  'outgoingCallerIds', {
    get: function() {
      return this.api.account.outgoingCallerIds;
    }
});

Object.defineProperty(Twilio.prototype,
  'queues', {
    get: function() {
      return this.api.account.queues;
    }
});

Object.defineProperty(Twilio.prototype,
  'recordings', {
    get: function() {
      return this.api.account.recordings;
    }
});

Object.defineProperty(Twilio.prototype,
  'signingKeys', {
    get: function() {
      return this.api.account.signingKeys;
    }
});

Object.defineProperty(Twilio.prototype,
  'sip', {
    get: function() {
      return this.api.account.sip;
    }
});

Object.defineProperty(Twilio.prototype,
  'shortCodes', {
    get: function() {
      return this.api.account.shortCodes;
    }
});

Object.defineProperty(Twilio.prototype,
  'tokens', {
    get: function() {
      return this.api.account.tokens;
    }
});

Object.defineProperty(Twilio.prototype,
  'transcriptions', {
    get: function() {
      return this.api.account.transcriptions;
    }
});

Object.defineProperty(Twilio.prototype,
  'usage', {
    get: function() {
      return this.api.account.usage;
    }
});

Object.defineProperty(Twilio.prototype,
  'validationRequests', {
    get: function() {
      return this.api.account.validationRequests;
    }
});

module.exports = Twilio;
