import React, { Component } from "react";
import {
  View,
  TextInput,
  Text,
  Image,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
  AsyncStorage
} from "react-native";
import { Button, Form } from "native-base";
import BackButton from "./../components/backButton";
import { connect } from "react-redux";
import Toast from "./../components/toast";
import { addCard, adduser, addToken } from "./../store/actions";
import {
  MERCHANT_CODE,
  MERCHANT_CODE_TEST,
  PUBLISHABLE_KEY,
  PUBLISHABLE_KEY_TEST,
} from "./../constants/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, createToken } from "@stripe/stripe-js";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { performRequest } from "./../services/apiHandler";
import { Spinner } from "native-base";
import i18n from "../i18n";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';

class PaymentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      cvv: "",
      month: "",
      year: "",
      error_message: "",
      message_status: "",
      message_display: "none",
      card: "",
      card: "",
      saveCard: true,
      cards: [],
      isLoading: false,
    };
    if (
      this.props.config.payment &&
      this.props.config.payment.service == "stripe"
    ) {
      this.stripePromise = loadStripe(this.props.config.payment.key);
    }
  }

  loadData = () => {
    performRequest("get", `api/v2/pwa/user/cards/?service=${this.props.config.payment?.id}`)
      .then((response) => {
        this.setState({ cards: response.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.props.adduser("");
        this.props.addToken("");
        AsyncStorage.setItem('login_trigger', "CardScreen")
        this.props.navigation.navigate("MobileForm");
        this.setState({ isLoading: false });
      });
  };
  componentDidMount() {
    this.loadData();
  }
  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });
    const resp = (await this.stripePromise)
      .createToken(this.cardref)
      .then((response) => {
        if (response.error) {
          this.throwMessageFn(response.error.message, "red");
          this.setState({ isLoading: false });
          return;
        }
        let data = {
          card: response.token.card,
          token: response.token.id,
          venue: this.props.config.venue,
          pay_id: this.props.config.payment.id,
        };
        performRequest("post", "api/v2/stripe/card/save/", data)
          .then((response) => {
            this.loadData();
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            this.throwMessageFn("Something went wrong", "red");
          });
      });
  };

  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  onChange = (e) => {
    let formattedText = e.split(" ").join("");
    if (formattedText.length > 0) {
      formattedText = formattedText.match(new RegExp(".{1,4}", "g")).join(" ");
    }
    this.setState({ cardNumber: formattedText });
    // this.setState({ cardNumber: e });
  };

  onChangeCvv = (cvv) => {
    this.setState({ cvv: cvv });
  };
  onChangeMonth = (month) => {
    this.setState({ month: month });
  };
  onChangeYear = (year) => {
    this.setState({ year: year });
  };

  submitCard = () => {
    const { cvv, year, month, cardNumber } = this.state;
    if (!cvv || !year || !month || !cardNumber) {
      this.throwMessageFn("Fill all card details", "red");
      return;
    }
    this.props.addCard(cardNumber, year, month, cvv, "2checkout");
    this.props.navigation.navigate("CheckoutScreen");
  };

  twoCheckoutCard = () => (
    <View style={{ width: "90%", alignSelf: "center", marginTop: 80 }}>
      <StyledText 
      font_color={this.props.design.list_view_header_color}
      font_weight={this.props.design.list_view_header_font_weight}
      font_style={this.props.design.list_view_header_font_style}
      text_transform={this.props.design.list_view_header_font_transform}
      font_family={this.props.design.list_view_header_font_family}
      font_size={this.props.design.list_view_header_font_size?? 20}
      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

      style={{  marginBottom: 4,}}>Card Number</StyledText>
      <TextInput
        keyboardType="phone-pad"       
        maxLength={19}
        placeholder={"XXXX-XXXX-XXXX-XXXX"}
        placeholderStyle={{ fontSize: this.props.design.custom_font.body_size }}
        placeholderTextColor={"#b4b7b9"}
        value={this.state.cardNumber}
        onChangeText={(e) => this.onChange(e)}
        style={{
          fontSize: this.props.design.custom_font.heading_size,
          fontWeight: this.props.design.custom_font.heading_is_bold ? "bold": "normal",
          letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
          // backgroundColor:'grey',
          height: 35,
          border: "1px solid grey",
          // fontFamily:'SecondaryFont'
        }}
      />
      {this.state.cardNumber.startsWith("4") ? (
        <Image
          source={require("../../assets/visa.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("5") ? (
        <Image
          source={require("../../assets/master.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("6") ? (
        <Image
          source={require("../../assets/discover.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("2") ? (
        <Image
          source={require("../../assets/jcb.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : null}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <View style={{ flex: 2, flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
            <StyledText 
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

            style={{ marginBottom: 4}}>Exp. Month</StyledText>

            <TextInput
              keyboardType="phone-pad"
              onChangeText={this.onChangeMonth}
              placeholder={"MM"}
              maxLength={2}
              value={this.state.month}
              placeholderStyle={{ fontSize: this.props.design.custom_font.body_size }}
              placeholderTextColor={"#b4b7b9"}
              style={{
                fontSize: this.props.design.custom_font.heading_size,
                fontWeight: this.props.design.custom_font.heading_is_bold ? "bold": "normal", 
                letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                // backgroundColor:'grey',
                height: 35,
                border: "1px solid grey",
                width: "95%",
                alignSelf: "flex-start",
                // fontFamily:'SecondaryFont'
              }}
            />
          </View>

          <View style={{ width: "50%", alignSelf: "flex-end" }}>
            <StyledText 
            font_color={this.props.design.list_view_text_color}
            font_weight={this.props.design.list_view_text_font_weight}
            font_style={this.props.design.list_view_text_font_style}
            text_transform={this.props.design.list_view_text_font_transform}
            font_family={this.props.design.list_view_text_font_family}
            font_size={this.props.design.list_view_text_font_size}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

            style={{  marginBottom: 4}}>Exp. Year</StyledText>

            <TextInput
              keyboardType="phone-pad"
              onChangeText={this.onChangeYear}
              placeholder={"YYYY"}
              maxLength={4}
              value={this.state.year}
              placeholderStyle={{ fontSize: this.props.design.custom_font.body_size, letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space) }}
              placeholderTextColor={"#b4b7b9"}
              style={{
                fontSize: this.props.design.custom_font.heading_size,
                fontWeight: this.props.design.custom_font.heading_is_bold ? "bold": "normal",
                letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                // backgroundColor:'grey',
                height: 35,
                border: "1px solid grey",
                width: "95%",
                // fontFamily:'SecondaryFont'
              }}
            />
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <StyledText 
          font_color={this.props.design.list_view_text_color}
          font_weight={this.props.design.list_view_text_font_weight}
          font_style={this.props.design.list_view_text_font_style}
          text_transform={this.props.design.list_view_text_font_transform}
          font_family={this.props.design.list_view_text_font_family}
          font_size={this.props.design.list_view_text_font_size}
          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

          style={{  marginBottom: 4, marginLeft: 30 }}>
            cvv
          </StyledText>

          <TextInput
            keyboardType="phone-pad"
            maxLength={3}
            value={this.state.cvv}
            onChangeText={this.onChangeCvv}
            placeholder={"CVV"}
            placeholderStyle={{ fontSize: this.props.design.custom_font.body_size }} //13
            placeholderTextColor={"#b4b7b9"}
            style={{
              fontSize: this.props.design.custom_font.heading_size,
              fontWeight: this.props.design.custom_font.heading_is_bold ? "bold": "normal",
              letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
              // backgroundColor:'grey',
              height: 35,
              border: "1px solid grey",
              width: "80%",
              alignSelf: "flex-end",
              // fontFamily:'SecondaryFont'
            }}
          />
        </View>
      </View>
      <View style={{ height: 20 }} />
      <Button
        style={{
          marginTop: 10,
          backgroundColor: this.props.design.top_buttons_background_color,
          alignItems: "center",
          justifyContent: "center",
          width:"100%"
        }}
        onPress={() => this.submitCard()}
      >
        <StyledText 
        font_color={this.props.design.top_buttons_text_color}
        font_weight={this.props.design.top_button_text_font_weight}
        font_style={this.props.design.top_button_text_font_style}
        text_transform={this.props.design.top_button_text_font_transform}
        font_family={this.props.design.top_button_text_font_family}
        font_size={this.props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

        style={{}}>
          {" "}
          {i18n.t("order.add_card")}
        </StyledText>
      </Button>
    </View>
  );

  stripeCard = () => (
    <View style={{ width: "90%", alignSelf: "center", marginTop: 80 }}>
      <View
        style={{
          border: "1px solid grey",
          justifyContent: "center",
          height: 35,
        }}
      >
        <Elements stripe={this.stripePromise}>
          <CardElement
            onReady={(c) => (this.cardref = c)}
            onChangeText={(e) => {
              console.log(e, "llll");
            }}
            // onReady={e=>{
            //     console.log(e, 'llll')

            // }}
            onPress={(e) => {
              console.log(e, "llll");
            }}
            onClick={(e) => {
              console.log(e, "llll");
            }}
            onChange={(e) => {
              console.log(e, "llll");
              this.setState({ card: e });
            }}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </Elements>
      </View>

      <View style={{ height: 20 }} />
      <Button
        style={{
          marginTop: 10,
          backgroundColor: this.props.design.top_buttons_background_color,
          alignItems: "center",
          justifyContent: "center",
          width:"100%"

        }}
        onPress={this.handleSubmit}
      >
        {this.state.isLoading ? (
          <Spinner color={"black"} />
        ) : (
          <StyledText 
          font_color={this.props.design.top_buttons_text_color}
        font_weight={this.props.design.top_button_text_font_weight}
        font_style={this.props.design.top_button_text_font_style}
        text_transform={this.props.design.top_button_text_font_transform}
        font_family={this.props.design.top_button_text_font_family}
        font_size={this.props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

          style={{ }}>
            {" "}
            SAVE CARD{" "}
          </StyledText>
        )}
      </Button>
    </View>
  );
  cardLoader = () => {
    if (this.props.config.payment.service == "stripe") {
      return this.stripeCard();
    } else {
      return this.twoCheckoutCard();
    }
  };
  selectCard = (item) => {
    this.props.addCard(
      item.last_four_digits,
      "dummy",
      "dummy",
      "dummy",
      "stripe",
      item.id,
      "",
      false,
      true
    );
    this.props.navigation.navigate("CheckoutScreen");
  };
  render() {
    const { cardNumber, cvv, month, year } = this.state;
    return (
      <View style={{backgroundColor: this.props.design.list_view_background_color}} >
        {/* {this.props.config.payment ? this.cardLoader() : this.twoCheckoutCard()} */}

          <View
            style={{ width: "90%", alignSelf: "center", marginVertical: 20, backgroundColor: this.props.design.list_view_background_color }}
          >
            <StyledText 
              font_color={this.props.design.list_view_text_color}
              font_weight={this.props.design.list_view_text_font_weight}
              font_style={this.props.design.list_view_text_font_style}
              text_transform={this.props.design.list_view_text_font_transform}
              font_family={this.props.design.list_view_text_font_family}
              font_size={this.props.design.list_view_text_font_size}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
    
            style={{}}>Saved Cards</StyledText>
            <View style={{ marginBottom: 10 }} />
            {this.state.cards.map((item) => (
              <TouchableOpacity onPress={() => this.selectCard(item)}>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "black",
                    borderRadius: 8,
                    width: "100%",
                    height: 50,
                    marginBottom: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <StyledText 
                    font_color={this.props.design.list_view_text_color}
                    font_weight={this.props.design.list_view_text_font_weight}
                    font_style={this.props.design.list_view_text_font_style}
                    text_transform={this.props.design.list_view_text_font_transform}
                    font_family={this.props.design.list_view_text_font_family}
                    font_size={this.props.design.list_view_text_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
          
                  style={{}}>XXXX-XXXX-XXXX-{item.last_four_digits}</StyledText>
                  <StyledText 
                    font_color={this.props.design.list_view_text_color}
                    font_weight={this.props.design.list_view_text_font_weight}
                    font_style={this.props.design.list_view_text_font_style}
                    text_transform={this.props.design.list_view_text_font_transform}
                    font_family={this.props.design.list_view_text_font_family}
                    font_size={this.props.design.list_view_text_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
        
                  style={{fontFamily:'SecondaryFont'}}>{item.card_scheme}</StyledText>
                  <StyledText 
                    font_color={this.props.design.list_view_text_color}
                    font_weight={this.props.design.list_view_text_font_weight}
                    font_style={this.props.design.list_view_text_font_style}
                    text_transform={this.props.design.list_view_text_font_transform}
                    font_family={this.props.design.list_view_text_font_family}
                    font_size={this.props.design.list_view_text_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
        
                  style={{fontFamily:'SecondaryFont'}}>{item.expires_on}</StyledText>
                </View>
              </TouchableOpacity>
            ))}
          </View>

        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => this.props.navigation.goBack()}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>

        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    //   marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
});
const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    platform: state.config.platform,
    design: state.config.design,

  };
};
export default connect(mapStateToProps, { addCard, adduser, addToken })(
  PaymentScreen
);
