'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var BindingList = require('./service/binding').BindingList;
var ConfigurationList = require('./service/configuration').ConfigurationList;
var ConversationList = require('./service/conversation').ConversationList;
var Page = require('../../../base/Page');  /* jshint ignore:line */
var ParticipantConversationList = require(
    './service/participantConversation').ParticipantConversationList;
var RoleList = require('./service/role').RoleList;
var UserList = require('./service/user').UserList;
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ServiceList;
var ServicePage;
var ServiceInstance;
var ServiceContext;

/* jshint ignore:start */
/**
 * Initialize the ServiceList
 *
 * @constructor Twilio.Conversations.V1.ServiceList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 */
/* jshint ignore:end */
ServiceList = function ServiceList(version) {
  /* jshint ignore:start */
  /**
   * @function services
   * @memberof Twilio.Conversations.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ServiceContext}
   */
  /* jshint ignore:end */
  function ServiceListInstance(sid) {
    return ServiceListInstance.get(sid);
  }

  ServiceListInstance._version = version;
  // Path Solution
  ServiceListInstance._solution = {};
  ServiceListInstance._uri = `/Services`;
  /* jshint ignore:start */
  /**
   * create a ServiceInstance
   *
   * @function create
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.friendlyName - The human-readable name of this service.
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ServiceInstance
   */
  /* jshint ignore:end */
  ServiceListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['friendlyName'])) {
      throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'FriendlyName': _.get(opts, 'friendlyName')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServiceInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams ServiceInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ServiceListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ServiceInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ServiceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServicePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ServiceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServicePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a service
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @param {string} sid -
   *          A 34 character string that uniquely identifies this resource.
   *
   * @returns {Twilio.Conversations.V1.ServiceContext}
   */
  /* jshint ignore:end */
  ServiceListInstance.get = function get(sid) {
    return new ServiceContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ServiceList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ServiceListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ServiceListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return ServiceListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ServicePage
 *
 * @constructor Twilio.Conversations.V1.ServicePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ServiceSolution} solution - Path solution
 *
 * @returns ServicePage
 */
/* jshint ignore:end */
ServicePage = function ServicePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ServicePage.prototype, Page.prototype);
ServicePage.prototype.constructor = ServicePage;

/* jshint ignore:start */
/**
 * Build an instance of ServiceInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ServicePage#
 *
 * @param {ServicePayload} payload - Payload response from the API
 *
 * @returns ServiceInstance
 */
/* jshint ignore:end */
ServicePage.prototype.getInstance = function getInstance(payload) {
  return new ServiceInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServicePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServicePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ServicePage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ServiceContext
 *
 * @constructor Twilio.Conversations.V1.ServiceInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account responsible for this service.
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {string} friendlyName - The human-readable name of this service.
 * @property {Date} dateCreated - The date that this resource was created.
 * @property {Date} dateUpdated - The date that this resource was last updated.
 * @property {string} url - An absolute URL for this service.
 * @property {string} links -
 *          Absolute URLs to access the conversations, users, roles, bindings and configuration of this service.
 *
 * @param {V1} version - Version of the resource
 * @param {ServicePayload} payload - The instance payload
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ServiceInstance = function ServiceInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(ServiceInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ServiceContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * remove a ServiceInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * fetch a ServiceInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Access the conversations
 *
 * @function conversations
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.ConversationList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.conversations = function conversations() {
  return this._proxy.conversations;
};

/* jshint ignore:start */
/**
 * Access the bindings
 *
 * @function bindings
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.BindingList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.bindings = function bindings() {
  return this._proxy.bindings;
};

/* jshint ignore:start */
/**
 * Access the users
 *
 * @function users
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.UserList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.users = function users() {
  return this._proxy.users;
};

/* jshint ignore:start */
/**
 * Access the roles
 *
 * @function roles
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.RoleList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.roles = function roles() {
  return this._proxy.roles;
};

/* jshint ignore:start */
/**
 * Access the configuration
 *
 * @function configuration
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.ConfigurationList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.configuration = function configuration() {
  return this._proxy.configuration;
};

/* jshint ignore:start */
/**
 * Access the participantConversations
 *
 * @function participantConversations
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns {Twilio.Conversations.V1.ServiceContext.ParticipantConversationList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.participantConversations = function
    participantConversations() {
  return this._proxy.participantConversations;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServiceInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ServiceInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ServiceContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext
 *
 * @property {Twilio.Conversations.V1.ServiceContext.ConversationList} conversations -
 *          conversations resource
 * @property {Twilio.Conversations.V1.ServiceContext.BindingList} bindings -
 *          bindings resource
 * @property {Twilio.Conversations.V1.ServiceContext.UserList} users -
 *          users resource
 * @property {Twilio.Conversations.V1.ServiceContext.RoleList} roles -
 *          roles resource
 * @property {Twilio.Conversations.V1.ServiceContext.ConfigurationList} configuration -
 *          configuration resource
 * @property {Twilio.Conversations.V1.ServiceContext.ParticipantConversationList} participantConversations -
 *          participantConversations resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ServiceContext = function ServiceContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Services/${sid}`;

  // Dependents
  this._conversations = undefined;
  this._bindings = undefined;
  this._users = undefined;
  this._roles = undefined;
  this._configuration = undefined;
  this._participantConversations = undefined;
};

/* jshint ignore:start */
/**
 * remove a ServiceInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a ServiceInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ServiceInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ServiceContext.prototype,
  'conversations', {
    get: function() {
      if (!this._conversations) {
        this._conversations = new ConversationList(this._version, this._solution.sid);
      }
      return this._conversations;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'bindings', {
    get: function() {
      if (!this._bindings) {
        this._bindings = new BindingList(this._version, this._solution.sid);
      }
      return this._bindings;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'users', {
    get: function() {
      if (!this._users) {
        this._users = new UserList(this._version, this._solution.sid);
      }
      return this._users;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'roles', {
    get: function() {
      if (!this._roles) {
        this._roles = new RoleList(this._version, this._solution.sid);
      }
      return this._roles;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'configuration', {
    get: function() {
      if (!this._configuration) {
        this._configuration = new ConfigurationList(this._version, this._solution.sid);
      }
      return this._configuration;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'participantConversations', {
    get: function() {
      if (!this._participantConversations) {
        this._participantConversations = new ParticipantConversationList(this._version, this._solution.sid);
      }
      return this._participantConversations;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServiceContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ServiceContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ServiceList: ServiceList,
  ServicePage: ServicePage,
  ServiceInstance: ServiceInstance,
  ServiceContext: ServiceContext
};
