'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var JobList;
var JobPage;
var JobInstance;
var JobContext;

/* jshint ignore:start */
/**
 * Initialize the JobList
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.JobList
 *
 * @param {Twilio.Bulkexports.V1} version - Version of the resource
 */
/* jshint ignore:end */
JobList = function JobList(version) {
  /* jshint ignore:start */
  /**
   * @function jobs
   * @memberof Twilio.Bulkexports.V1.ExportContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Bulkexports.V1.ExportContext.JobContext}
   */
  /* jshint ignore:end */
  function JobListInstance(sid) {
    return JobListInstance.get(sid);
  }

  JobListInstance._version = version;
  // Path Solution
  JobListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a job
   *
   * @function get
   * @memberof Twilio.Bulkexports.V1.ExportContext.JobList#
   *
   * @param {string} jobSid -
   *          The unique string that that we created to identify the Bulk Export job
   *
   * @returns {Twilio.Bulkexports.V1.ExportContext.JobContext}
   */
  /* jshint ignore:end */
  JobListInstance.get = function get(jobSid) {
    return new JobContext(this._version, jobSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Bulkexports.V1.ExportContext.JobList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  JobListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  JobListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return JobListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the JobPage
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.JobPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {JobSolution} solution - Path solution
 *
 * @returns JobPage
 */
/* jshint ignore:end */
JobPage = function JobPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(JobPage.prototype, Page.prototype);
JobPage.prototype.constructor = JobPage;

/* jshint ignore:start */
/**
 * Build an instance of JobInstance
 *
 * @function getInstance
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobPage#
 *
 * @param {JobPayload} payload - Payload response from the API
 *
 * @returns JobInstance
 */
/* jshint ignore:end */
JobPage.prototype.getInstance = function getInstance(payload) {
  return new JobInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
JobPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

JobPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the JobContext
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.JobInstance
 *
 * @property {string} resourceType -
 *          The type of communication – Messages, Calls, Conferences, and Participants
 * @property {string} friendlyName -
 *          The friendly name specified when creating the job
 * @property {object} details -
 *          The details of a job state which is an object that contains a `status` string, a day count integer, and list of days in the job
 * @property {string} startDay -
 *          The start time for the export specified when creating the job
 * @property {string} endDay -
 *          The end time for the export specified when creating the job
 * @property {string} jobSid - The job_sid returned when the export was created
 * @property {string} webhookUrl - The optional webhook url called on completion
 * @property {string} webhookMethod - This is the method used to call the webhook
 * @property {string} email -
 *          The optional email to send the completion notification to
 * @property {string} url - The url
 * @property {string} jobQueuePosition -
 *          This is the job position from the 1st in line. Your queue position will never increase. As jobs ahead of yours in the queue are processed, the queue position number will decrease
 * @property {string} estimatedCompletionTime -
 *          this is the time estimated until your job is complete. This is calculated each time you request the job list. The time is calculated based on the current rate of job completion (which may vary) and your job queue position
 *
 * @param {V1} version - Version of the resource
 * @param {JobPayload} payload - The instance payload
 * @param {sid} jobSid -
 *          The unique string that that we created to identify the Bulk Export job
 */
/* jshint ignore:end */
JobInstance = function JobInstance(version, payload, jobSid) {
  this._version = version;

  // Marshaled Properties
  this.resourceType = payload.resource_type; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.details = payload.details; // jshint ignore:line
  this.startDay = payload.start_day; // jshint ignore:line
  this.endDay = payload.end_day; // jshint ignore:line
  this.jobSid = payload.job_sid; // jshint ignore:line
  this.webhookUrl = payload.webhook_url; // jshint ignore:line
  this.webhookMethod = payload.webhook_method; // jshint ignore:line
  this.email = payload.email; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.jobQueuePosition = payload.job_queue_position; // jshint ignore:line
  this.estimatedCompletionTime = payload.estimated_completion_time; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {jobSid: jobSid || this.jobSid, };
};

Object.defineProperty(JobInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new JobContext(this._version, this._solution.jobSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a JobInstance
 *
 * @function fetch
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed JobInstance
 */
/* jshint ignore:end */
JobInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a JobInstance
 *
 * @function remove
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed JobInstance
 */
/* jshint ignore:end */
JobInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
JobInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

JobInstance.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the JobContext
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.JobContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} jobSid -
 *          The unique string that that we created to identify the Bulk Export job
 */
/* jshint ignore:end */
JobContext = function JobContext(version, jobSid) {
  this._version = version;

  // Path Solution
  this._solution = {jobSid: jobSid, };
  this._uri = `/Exports/Jobs/${jobSid}`;
};

/* jshint ignore:start */
/**
 * fetch a JobInstance
 *
 * @function fetch
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed JobInstance
 */
/* jshint ignore:end */
JobContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new JobInstance(this._version, payload, this._solution.jobSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a JobInstance
 *
 * @function remove
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed JobInstance
 */
/* jshint ignore:end */
JobContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.JobContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
JobContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

JobContext.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  JobList: JobList,
  JobPage: JobPage,
  JobInstance: JobInstance,
  JobContext: JobContext
};
