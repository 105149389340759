import React from "react"
import { View, Text, TouchableOpacity } from "react-native";



const FeedbackButton = (props) => {
    return (
        <TouchableOpacity
            onPress={props.feedbackHandler}
            activeOpacity={1}
        >
            <View
                style={[
                    props.style.fontButton,
                    {
                        backgroundColor:
                            props.design.info_button_background_color,
                        border: `1px solid ${props.config.info_button_background_color}`,
                    },
                ]}
            >
                <i
                    style={{
                        color: props.design.info_button_text_color,
                        fontSize: 25
                    }}
                    class="far fa-smile"
                ></i>
            </View>
        </TouchableOpacity>
    )
}

export default FeedbackButton;