'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var BindingList;
var BindingPage;
var BindingInstance;
var BindingContext;

/* jshint ignore:start */
/**
 * Initialize the BindingList
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.BindingList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 * @param {string} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 */
/* jshint ignore:end */
BindingList = function BindingList(version, chatServiceSid) {
  /* jshint ignore:start */
  /**
   * @function bindings
   * @memberof Twilio.Conversations.V1.ServiceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.BindingContext}
   */
  /* jshint ignore:end */
  function BindingListInstance(sid) {
    return BindingListInstance.get(sid);
  }

  BindingListInstance._version = version;
  // Path Solution
  BindingListInstance._solution = {chatServiceSid: chatServiceSid};
  BindingListInstance._uri = `/Services/${chatServiceSid}/Bindings`;
  /* jshint ignore:start */
  /**
   * Streams BindingInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @param {object} [opts] - Options for request
   * @param {binding.binding_type|list} [opts.bindingType] -
   *          The push technology used by the Binding resources to read.
   * @param {string|list} [opts.identity] -
   *          The identity of Conversation User associated with this binding.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  BindingListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists BindingInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @param {object} [opts] - Options for request
   * @param {binding.binding_type|list} [opts.bindingType] -
   *          The push technology used by the Binding resources to read.
   * @param {string|list} [opts.identity] -
   *          The identity of Conversation User associated with this binding.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BindingListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of BindingInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @param {object} [opts] - Options for request
   * @param {binding.binding_type|list} [opts.bindingType] -
   *          The push technology used by the Binding resources to read.
   * @param {string|list} [opts.identity] -
   *          The identity of Conversation User associated with this binding.
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BindingListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'BindingType': serialize.map(_.get(opts, 'bindingType'), function(e) { return e; }),
      'Identity': serialize.map(_.get(opts, 'identity'), function(e) { return e; }),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new BindingPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of BindingInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  BindingListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new BindingPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a binding
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @param {string} sid -
   *          A 34 character string that uniquely identifies this resource.
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.BindingContext}
   */
  /* jshint ignore:end */
  BindingListInstance.get = function get(sid) {
    return new BindingContext(this._version, this._solution.chatServiceSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ServiceContext.BindingList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  BindingListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  BindingListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return BindingListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the BindingPage
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.BindingPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {BindingSolution} solution - Path solution
 *
 * @returns BindingPage
 */
/* jshint ignore:end */
BindingPage = function BindingPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(BindingPage.prototype, Page.prototype);
BindingPage.prototype.constructor = BindingPage;

/* jshint ignore:start */
/**
 * Build an instance of BindingInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingPage#
 *
 * @param {BindingPayload} payload - Payload response from the API
 *
 * @returns BindingInstance
 */
/* jshint ignore:end */
BindingPage.prototype.getInstance = function getInstance(payload) {
  return new BindingInstance(this._version, payload, this._solution.chatServiceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
BindingPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BindingPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BindingContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.BindingInstance
 *
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {string} accountSid -
 *          The unique ID of the Account responsible for this binding.
 * @property {string} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @property {string} credentialSid - The SID of the Credential for the binding.
 * @property {Date} dateCreated - The date that this resource was created.
 * @property {Date} dateUpdated - The date that this resource was last updated.
 * @property {string} endpoint - The unique endpoint identifier for the Binding.
 * @property {string} identity -
 *          The identity of Conversation User associated with this binding.
 * @property {binding.binding_type} bindingType -
 *          The push technology to use for the binding.
 * @property {string} messageTypes -
 *          The Conversation message types the binding is subscribed to.
 * @property {string} url - An absolute URL for this binding.
 *
 * @param {V1} version - Version of the resource
 * @param {BindingPayload} payload - The instance payload
 * @param {sid} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
BindingInstance = function BindingInstance(version, payload, chatServiceSid,
                                            sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.chatServiceSid = payload.chat_service_sid; // jshint ignore:line
  this.credentialSid = payload.credential_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.endpoint = payload.endpoint; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line
  this.bindingType = payload.binding_type; // jshint ignore:line
  this.messageTypes = payload.message_types; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {chatServiceSid: chatServiceSid, sid: sid || this.sid, };
};

Object.defineProperty(BindingInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new BindingContext(
          this._version,
          this._solution.chatServiceSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * remove a BindingInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BindingInstance
 */
/* jshint ignore:end */
BindingInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * fetch a BindingInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BindingInstance
 */
/* jshint ignore:end */
BindingInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
BindingInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BindingInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BindingContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.BindingContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @param {sid} sid - A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
BindingContext = function BindingContext(version, chatServiceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {chatServiceSid: chatServiceSid, sid: sid, };
  this._uri = `/Services/${chatServiceSid}/Bindings/${sid}`;
};

/* jshint ignore:start */
/**
 * remove a BindingInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BindingInstance
 */
/* jshint ignore:end */
BindingContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a BindingInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed BindingInstance
 */
/* jshint ignore:end */
BindingContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new BindingInstance(
      this._version,
      payload,
      this._solution.chatServiceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.BindingContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
BindingContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

BindingContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  BindingList: BindingList,
  BindingPage: BindingPage,
  BindingInstance: BindingInstance,
  BindingContext: BindingContext
};
