import React from 'react'
import {View, Text} from 'react-native';

import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';



const ModifierCounterContainer = (props) => {
    return(
        <View
        style={{
            alignItems: "flex-start",
            flex: 3,
            flexDirection: "row",
            alignItems: 'center'
        }}
        >
        {
            props.qr_id != props.uuid ?
            props.children
            :
            <View />
        }

        <StyledText
            // numberOfLines={3}
            font_color={props.design.item_price_text_color}
            font_weight={props.design.item_price_text_font_weight}
            font_style={props.design.item_price_text_font_style}
            text_transform={props.design.item_price_text_font_transform}
            font_family={props.design.item_price_text_font_family}
            font_size={props.design.item_price_text_font_size}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
            style={{
            marginLeft: 8,
            marginRight: 8,
            }}
        >
            {props.description}
        </StyledText>
        </View>
    )
}

export default ModifierCounterContainer