'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var WorkersCumulativeStatisticsList;
var WorkersCumulativeStatisticsPage;
var WorkersCumulativeStatisticsInstance;
var WorkersCumulativeStatisticsContext;

/* jshint ignore:start */
/**
 * Initialize the WorkersCumulativeStatisticsList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that contains the Workers
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsList = function
    WorkersCumulativeStatisticsList(version, workspaceSid) {
  /* jshint ignore:start */
  /**
   * @function cumulativeStatistics
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsContext}
   */
  /* jshint ignore:end */
  function WorkersCumulativeStatisticsListInstance(sid) {
    return WorkersCumulativeStatisticsListInstance.get(sid);
  }

  WorkersCumulativeStatisticsListInstance._version = version;
  // Path Solution
  WorkersCumulativeStatisticsListInstance._solution = {workspaceSid: workspaceSid};
  /* jshint ignore:start */
  /**
   * Constructs a workers_cumulative_statistics
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsList#
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsContext}
   */
  /* jshint ignore:end */
  WorkersCumulativeStatisticsListInstance.get = function get() {
    return new WorkersCumulativeStatisticsContext(this._version, this._solution.workspaceSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WorkersCumulativeStatisticsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WorkersCumulativeStatisticsListInstance[util.inspect.custom] = function
      inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return WorkersCumulativeStatisticsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WorkersCumulativeStatisticsPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WorkersCumulativeStatisticsSolution} solution - Path solution
 *
 * @returns WorkersCumulativeStatisticsPage
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsPage = function
    WorkersCumulativeStatisticsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WorkersCumulativeStatisticsPage.prototype, Page.prototype);
WorkersCumulativeStatisticsPage.prototype.constructor = WorkersCumulativeStatisticsPage;

/* jshint ignore:start */
/**
 * Build an instance of WorkersCumulativeStatisticsInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsPage#
 *
 * @param {WorkersCumulativeStatisticsPayload} payload -
 *          Payload response from the API
 *
 * @returns WorkersCumulativeStatisticsInstance
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsPage.prototype.getInstance = function
    getInstance(payload) {
  return new WorkersCumulativeStatisticsInstance(this._version, payload, this._solution.workspaceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkersCumulativeStatisticsPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkersCumulativeStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} startTime -
 *          The beginning of the interval during which these statistics were calculated
 * @property {Date} endTime -
 *          The end of the interval during which these statistics were calculated
 * @property {object} activityDurations -
 *          The minimum, average, maximum, and total time that Workers spent in each Activity
 * @property {number} reservationsCreated -
 *          The total number of Reservations that were created
 * @property {number} reservationsAccepted -
 *          The total number of Reservations that were accepted
 * @property {number} reservationsRejected -
 *          The total number of Reservations that were rejected
 * @property {number} reservationsTimedOut -
 *          The total number of Reservations that were timed out
 * @property {number} reservationsCanceled -
 *          The total number of Reservations that were canceled
 * @property {number} reservationsRescinded -
 *          The total number of Reservations that were rescinded
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the Workers
 * @property {string} url - The absolute URL of the Workers statistics resource
 *
 * @param {V1} version - Version of the resource
 * @param {WorkersCumulativeStatisticsPayload} payload - The instance payload
 * @param {sid} workspaceSid - The SID of the Workspace that contains the Workers
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsInstance = function
    WorkersCumulativeStatisticsInstance(version, payload, workspaceSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.startTime = deserialize.iso8601DateTime(payload.start_time); // jshint ignore:line
  this.endTime = deserialize.iso8601DateTime(payload.end_time); // jshint ignore:line
  this.activityDurations = payload.activity_durations; // jshint ignore:line
  this.reservationsCreated = deserialize.integer(payload.reservations_created); // jshint ignore:line
  this.reservationsAccepted = deserialize.integer(payload.reservations_accepted); // jshint ignore:line
  this.reservationsRejected = deserialize.integer(payload.reservations_rejected); // jshint ignore:line
  this.reservationsTimedOut = deserialize.integer(payload.reservations_timed_out); // jshint ignore:line
  this.reservationsCanceled = deserialize.integer(payload.reservations_canceled); // jshint ignore:line
  this.reservationsRescinded = deserialize.integer(payload.reservations_rescinded); // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, };
};

Object.defineProperty(WorkersCumulativeStatisticsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new WorkersCumulativeStatisticsContext(this._version, this._solution.workspaceSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a WorkersCumulativeStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.endDate] -
 *          Only calculate statistics from on or before this date
 * @param {number} [opts.minutes] -
 *          Only calculate statistics since this many minutes in the past
 * @param {Date} [opts.startDate] -
 *          Only calculate statistics from on or after this date
 * @param {string} [opts.taskChannel] -
 *          Only calculate cumulative statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkersCumulativeStatisticsInstance
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsInstance.prototype.fetch = function fetch(opts,
    callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkersCumulativeStatisticsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkersCumulativeStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid - The SID of the Workspace with the resource to fetch
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsContext = function
    WorkersCumulativeStatisticsContext(version, workspaceSid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, };
  this._uri = `/Workspaces/${workspaceSid}/Workers/CumulativeStatistics`;
};

/* jshint ignore:start */
/**
 * fetch a WorkersCumulativeStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsContext#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.endDate] -
 *          Only calculate statistics from on or before this date
 * @param {number} [opts.minutes] -
 *          Only calculate statistics since this many minutes in the past
 * @param {Date} [opts.startDate] -
 *          Only calculate statistics from on or after this date
 * @param {string} [opts.taskChannel] -
 *          Only calculate cumulative statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkersCumulativeStatisticsInstance
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsContext.prototype.fetch = function fetch(opts,
    callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'EndDate': serialize.iso8601DateTime(_.get(opts, 'endDate')),
    'Minutes': _.get(opts, 'minutes'),
    'StartDate': serialize.iso8601DateTime(_.get(opts, 'startDate')),
    'TaskChannel': _.get(opts, 'taskChannel')
  });

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkersCumulativeStatisticsInstance(
      this._version,
      payload,
      this._solution.workspaceSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkersCumulativeStatisticsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

WorkersCumulativeStatisticsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WorkersCumulativeStatisticsList: WorkersCumulativeStatisticsList,
  WorkersCumulativeStatisticsPage: WorkersCumulativeStatisticsPage,
  WorkersCumulativeStatisticsInstance: WorkersCumulativeStatisticsInstance,
  WorkersCumulativeStatisticsContext: WorkersCumulativeStatisticsContext
};
