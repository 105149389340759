import React from "react";
import { View, Text, TouchableOpacity, Button, FlatList, AsyncStorage } from "react-native";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import {
  Thumbnail,
  List,
  ListItem,
  Separator,
  Item,
  Input,
  Label,
} from "native-base";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import axios from "axios";
import { API_URL } from "../constants";
import { addLocation, addConfig, resetCart, addLanguage, selectMenu , isLoading, addCampaign, setDeliveryMode, addOnlineChildren, selectMenuData, updateCartCount, totalPrice, allCart} from "./../store/actions";
import _ from "lodash";
import i18n from "../i18n";
import {FontLetterSpacing} from "./../handlers/utils"
import { AvailabilityHandler } from './../handlers/utils'
import { performRequest } from "./../services/apiHandler";
import Constants from "expo-constants";

class SelectDestinationScreen extends React.Component {
  static navigationOptions = {
    header: null,
    gesturesEnabled: true,
    animationEnabled: true,
  };
  state = {
    initial_places: this.props.config.delivery_circle
      ? _.orderBy(
          JSON.parse(this.props.config.delivery_circle),
          ["label"],
          ["asc"]
        )
      : [],

    // initial_places: this.props.config.delivery_circle ? JSON.parse(this.props.config.delivery_circle) : [],
    places: [],
    searchInput: "",
    areas: [],
  };
  componentDidMount() {
    this.setState({
      initial_places: this.props.config.delivery_circle
        ? _.orderBy(
            JSON.parse(this.props.config.delivery_circle),
            ["label"],
            ["asc"]
          )
        : [],
      places: this.props.config.delivery_circle
        ? _.orderBy(
            JSON.parse(this.props.config.delivery_circle),
            ["label"],
            ["asc"]
          )
        : [],
    });
  }
  searchDestination = (text) => {
    this.setState({ searchInput: text }, () => {
      let places = [...this.state.initial_places];
      let filtered_places = places.filter((item) => {
        return item.label.toLowerCase().includes(text.toLowerCase());
      });
      this.setState({ places: filtered_places });
    });
  };
  getOnlineConfig = (venue, platform = "online") => {
    return axios.get(
      API_URL + `api/v2/online-config/${venue}/?platform=${platform}`
    );
  };
  getMenuDetail = async (menu, venue) => {
    let response = await axios.get(API_URL + `api/online-menu/${venue}/${menu}/?menu=${menu}`)
    if (response.data) {
      let is_available = AvailabilityHandler(response.data)
      if (is_available.length) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
  getCampaign = (venue) => axios.get(API_URL + "api/campaigns/online-pwa/" + venue + "/" + "?platform=delivery")

  getModifiers = (venue) => axios.get(API_URL + "api/online-modifiers/" + venue + "/");

  getChild = (venue, platform) => axios.get(API_URL + "api/online-menu-children/" + venue + "/" + `?platform=${platform}`);


  getAllData = (uuid, platform) => {
    this.props.resetCart()
    axios.all([
      this.getChild(uuid, platform),
      this.getModifiers(uuid)
    ]).then(
      axios.spread(async (children, modifiers) => {
        let datum = JSON.parse(JSON.stringify(children.data));
        datum.forEach((element) => {
          let recoms = [];
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
        });
        this.props.addOnlineChildren(datum, modifiers.data);
      }))
  }
  getCart = (uuid, platform) => {
    return performRequest("get", `api/pwa/cart?uuid=${uuid}&mid=${Constants.deviceId}&platform=${platform}`,{})
  }
  loadData = async(item, params) => {
    this.setState({dataLoading:true})
    let uuid = item.online_ordering_prefix
    const exiting_uuid = await AsyncStorage.getItem("uuid");
    if (exiting_uuid == uuid && this.props.menu_type == "delivery" && this.props.allChildren.length && this.props.config && this.props.config.platform=="delivery") {

      this.setState({dataLoading:false})
      try {
        params.returnHere();
        return;
      } catch (error) {
        console.log(error, "errrr");
      }
      this.props.navigation.navigate("HomeScreen");
      return
    }
    await AsyncStorage.setItem("uuid", uuid);

    this.props.resetCart()
    this.props.isLoading();

    this.props.navigation.navigate("HomeScreen");

    axios.all([
      this.getOnlineConfig(uuid, "online"),
      this.getCart(uuid, "delivery")
    ]).then(
      axios.spread(async (response,cart) => {
        console.log(cart, "cartttttt")

        let config = response.data.find(data => data.platform == "delivery");
        console.log(item, "itemmmmm")
        config['minimum_order_amount'] = item.minimum_order_amount
        config['delivery_charge'] = item.delivery_charge
        let if_not_available = await this.getMenuDetail(config.menu_to_display, uuid)
        console.log("hereeeeeeeeeeeee", if_not_available)
        if (if_not_available) {
          this.throwMessageFn(
            "Menu Not Available",
            "red",
            "white"
          )
          return
        }
        let languages = [];
        if (config.languages) {
          languages = config.languages;
          languages.unshift(config.default_language);
        } else {
          languages.push(config.default_language);
        }
        this.props.addLanguage(languages);
        //    this.props.addDefaultLanguage(config.default_language.value);
        this.props.addConfig(config);
        this.props.selectMenu(config.menu_to_display)
        this.props.selectMenuData({ id: config.menu_to_display });
        this.getCampaign(uuid)
          .then(campaign => {
            this.setState({ modalVisible: !this.state.modalVisible })

            this.props.addCampaign(campaign.data);
            this.props.setDeliveryMode("delivery");
            this.getAllData(uuid, "delivery")
            if(cart.data){
              this.props.updateCartCount(cart.data.quantity || 0);
              this.props.totalPrice(cart.data.total || 0);
              this.props.allCart(cart.data.items || []);
            }
    
            this.setState({dataLoading:false})
            try {
              params.returnHere();
              return;
            } catch (error) {
              console.log(error, "errrr");
            }

          })


      }))

  }
  onSelectingLocation = (item) => {
    const { state, setParams, navigate } = this.props.navigation;
    const params = state.params || {};
    this.props.addLocation(item);
    console.log(this.props.platform, "iiiiiii")
    if(this.props.platform=="order_enterprise"){
      this.loadData(item, params)
      return
    }

    
    if(item.minimum_order_amount!=undefined || item.delivery_charge!=undefined ){
      let config = {...this.props.config}
      config['minimum_order_amount'] = item.minimum_order_amount
      config['delivery_charge'] = item.delivery_charge
      this.props.addConfig(config);
    }
    try {
      params.returnHere();
      return;
    } catch (error) {
      console.log(error, "errrr");
    }

    this.props.navigation.navigate("HomeScreen");
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontSize: this.props.design.custom_font.heading_size,
            fontWeight: this.props.design.custom_font.heading_is_bold ? "bold" : "normal",
            letterSpacing: FontLetterSpacing(this.props.design.custom_font.primary_letter_space),
            marginTop: 80,
            marginVertical: 20,
            marginLeft: 11,
            fontFamily: 'PrimaryFont'
          }}
        >
          {i18n.t("labels.select_destination")}
        </Text>
        <View
          style={{
            width: "96%",
            alignSelf: "center",
            height: 36,
            marginBottom: 20,
          }}
        >
          <Input
            onChangeText={(text) => this.searchDestination(text)}
            value={this.state.searchInput}
            style={{
              // fontSize:30,
              color: "grey",
              border: "1px solid grey",
              height: "100%",
              borderRadius: 5,
            }}
            placeholder={"Search destination"}
          />
        </View>

        {/* <View style={{flex:1, backgroundColor:'red'}}> */}

        {this.state.places ? (
          this.state.places.length ? (
            <FlatList
              data={this.state.places}
              renderItem={({ item }) => (
                <Collapse onToggle={(ee) => this.onSelectingLocation(item)}>
                  <CollapseHeader>
                    <Separator bordered>
                      <Text style={{fontFamily: 'SecondaryFont'}}>{item.label}</Text>
                    </Separator>
                  </CollapseHeader>
                </Collapse>
              )}
              keyExtractor={(item) => item.id}
            />
          ) : null
        ) : (
          <Text style={{fontFamily: 'SecondaryFont'}}>No Delivery Circle Added</Text>
        )}

        {/* </View> */}

        {/* <Button   
                title="Next screen"
                style={{marginTop:10}}
                onPress={() => this.props.navigation.navigate('MobileForm')}
                /> */}

        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => this.props.navigation.goBack()}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config.config,
    design: state.config.design,
    platform: state.config.platform,
    selectedMenu: state.menus.menu,
    menu_type: state.config.menu_type,
    allChildren: state.menus.allChildren,



  };
};
export default connect(mapStateToProps, { 
  addLocation, 
  addConfig, 
  resetCart, 
  addLanguage, 
  selectMenu, 
  selectMenuData,
  isLoading,
  addCampaign,
  setDeliveryMode,
  addOnlineChildren,
  updateCartCount,
  totalPrice,
  allCart
 })(
  SelectDestinationScreen
);
