'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../../../base/values');  /* jshint ignore:line */

var FunctionVersionContentList;
var FunctionVersionContentPage;
var FunctionVersionContentInstance;
var FunctionVersionContentContext;

/* jshint ignore:start */
/**
 * Initialize the FunctionVersionContentList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentList
 *
 * @param {Twilio.Serverless.V1} version - Version of the resource
 * @param {string} serviceSid -
 *          The SID of the Service that the Function Version resource is associated with
 * @param {string} functionSid -
 *          The SID of the Function that is the parent of the Function Version
 * @param {string} sid -
 *          The unique string that identifies the Function Version resource
 */
/* jshint ignore:end */
FunctionVersionContentList = function FunctionVersionContentList(version,
    serviceSid, functionSid, sid) {
  /* jshint ignore:start */
  /**
   * @function functionVersionContent
   * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentContext}
   */
  /* jshint ignore:end */
  function FunctionVersionContentListInstance(sid) {
    return FunctionVersionContentListInstance.get(sid);
  }

  FunctionVersionContentListInstance._version = version;
  // Path Solution
  FunctionVersionContentListInstance._solution = {
    serviceSid: serviceSid,
    functionSid: functionSid,
    sid: sid
  };
  /* jshint ignore:start */
  /**
   * Constructs a function_version_content
   *
   * @function get
   * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentList#
   *
   * @returns {Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentContext}
   */
  /* jshint ignore:end */
  FunctionVersionContentListInstance.get = function get() {
    return new FunctionVersionContentContext(
      this._version,
      this._solution.serviceSid,
      this._solution.functionSid,
      this._solution.sid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  FunctionVersionContentListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  FunctionVersionContentListInstance[util.inspect.custom] = function
      inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return FunctionVersionContentListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the FunctionVersionContentPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {FunctionVersionContentSolution} solution - Path solution
 *
 * @returns FunctionVersionContentPage
 */
/* jshint ignore:end */
FunctionVersionContentPage = function FunctionVersionContentPage(version,
    response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(FunctionVersionContentPage.prototype, Page.prototype);
FunctionVersionContentPage.prototype.constructor = FunctionVersionContentPage;

/* jshint ignore:start */
/**
 * Build an instance of FunctionVersionContentInstance
 *
 * @function getInstance
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentPage#
 *
 * @param {FunctionVersionContentPayload} payload - Payload response from the API
 *
 * @returns FunctionVersionContentInstance
 */
/* jshint ignore:end */
FunctionVersionContentPage.prototype.getInstance = function getInstance(payload)
    {
  return new FunctionVersionContentInstance(
    this._version,
    payload,
    this._solution.serviceSid,
    this._solution.functionSid,
    this._solution.sid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
FunctionVersionContentPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FunctionVersionContentPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FunctionVersionContentContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentInstance
 *
 * @property {string} sid -
 *          The unique string that identifies the Function Version resource
 * @property {string} accountSid -
 *          The SID of the Account that created the Function Version resource
 * @property {string} serviceSid -
 *          The SID of the Service that the Function Version resource is associated with
 * @property {string} functionSid -
 *          The SID of the Function that is the parent of the Function Version
 * @property {string} content - The content of the Function Version resource
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {FunctionVersionContentPayload} payload - The instance payload
 * @param {sid} serviceSid -
 *          The SID of the Service that the Function Version resource is associated with
 * @param {sid} functionSid -
 *          The SID of the Function that is the parent of the Function Version
 * @param {sid} sid -
 *          The unique string that identifies the Function Version resource
 */
/* jshint ignore:end */
FunctionVersionContentInstance = function
    FunctionVersionContentInstance(version, payload, serviceSid, functionSid,
    sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.serviceSid = payload.service_sid; // jshint ignore:line
  this.functionSid = payload.function_sid; // jshint ignore:line
  this.content = payload.content; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {serviceSid: serviceSid, functionSid: functionSid, sid: sid, };
};

Object.defineProperty(FunctionVersionContentInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new FunctionVersionContentContext(
          this._version,
          this._solution.serviceSid,
          this._solution.functionSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a FunctionVersionContentInstance
 *
 * @function fetch
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FunctionVersionContentInstance
 */
/* jshint ignore:end */
FunctionVersionContentInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
FunctionVersionContentInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FunctionVersionContentInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FunctionVersionContentContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} serviceSid -
 *          The SID of the Service to fetch the Function Version content from
 * @param {sid} functionSid -
 *          The SID of the Function that is the parent of the Function Version content to fetch
 * @param {sid} sid - The SID that identifies the Function Version content to fetch
 */
/* jshint ignore:end */
FunctionVersionContentContext = function FunctionVersionContentContext(version,
    serviceSid, functionSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {serviceSid: serviceSid, functionSid: functionSid, sid: sid, };
  this._uri = `/Services/${serviceSid}/Functions/${functionSid}/Versions/${sid}/Content`;
};

/* jshint ignore:start */
/**
 * fetch a FunctionVersionContentInstance
 *
 * @function fetch
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FunctionVersionContentInstance
 */
/* jshint ignore:end */
FunctionVersionContentContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new FunctionVersionContentInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.functionSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.FunctionContext.FunctionVersionContext.FunctionVersionContentContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
FunctionVersionContentContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

FunctionVersionContentContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  FunctionVersionContentList: FunctionVersionContentList,
  FunctionVersionContentPage: FunctionVersionContentPage,
  FunctionVersionContentInstance: FunctionVersionContentInstance,
  FunctionVersionContentContext: FunctionVersionContentContext
};
