import React from 'react'
import {} from 'react-native'
import {Dimensions,View,Text,TouchableOpacity,ScrollView} from "react-native";

const LanguageContainer = (props) => {
    return(
        <View style={{flex:1,alignItems:'flex-start',justifyContent:'flex-start',backgroundColor:'transparent', 
        // backdropFilter: "blur(5px)" 
        backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}>


            <View style={{flexDirection:'row',maxHeight:'40%',width:'100%', minHeight:'10%'}}>
                <TouchableOpacity onPress={props.closeModal} style={{flex:2,backgroundColor:'transparent',}}>
                    <View style={{flex:2,backgroundColor:'transparent',}}>

                    </View>
                </TouchableOpacity>
                <View style={{flex:1, backgroundColor:props.background_color,marginTop:60,marginRight:20,borderRadius:7}}>
                    <View style={{
                        width: 0,
                        height: 0,
                        backgroundColor: 'transparent',
                        borderStyle: 'solid',
                        borderLeftWidth: 5,
                        borderRightWidth: 5,
                        borderBottomWidth: 10,
                        borderLeftColor: 'transparent',
                        borderRightColor: 'transparent',
                        borderBottomColor: 'white',
                        position:'absolute',
                        right:8,
                        top:-6
                    }}/>
                    <ScrollView>
                        {props.children}
                    </ScrollView>
                </View>

            </View>


            {/*<View style={{width:'30%',height:'35%',backgroundColor:'white',alignSelf:'flex-end',marginTop:60,marginRight:20,borderRadius:7}}>*/}
                {/*<View style={{*/}
                    {/*width: 0,*/}
                    {/*height: 0,*/}
                    {/*backgroundColor: 'transparent',*/}
                    {/*borderStyle: 'solid',*/}
                    {/*borderLeftWidth: 5,*/}
                    {/*borderRightWidth: 5,*/}
                    {/*borderBottomWidth: 10,*/}
                    {/*borderLeftColor: 'transparent',*/}
                    {/*borderRightColor: 'transparent',*/}
                    {/*borderBottomColor: 'white',*/}
                    {/*position:'absolute',*/}
                    {/*right:8,*/}
                    {/*top:-6*/}
                {/*}}/>*/}
                {/*<ScrollView>*/}
                    {/*{props.children}*/}
                {/*</ScrollView>*/}
            {/*</View>*/}


            <TouchableOpacity onPress={props.closeModal}>
                <View style={{
                    height:Dimensions.get('window').height*.5,
                    backgroundColor:'transparent',
                    width:Dimensions.get('window').width,

                }}

                />
            </TouchableOpacity>

        </View>
    )
};

export default LanguageContainer