
import {socket_url} from './index'
// import { io } from "socket.io-client";


export const connectToMyMenuSocket = (data) => {
    let data_string = Buffer.from(JSON.stringify(data)).toString('base64')
    const chatSocket = new WebSocket(
        `${socket_url}?data=${data_string}`,
      )
    return chatSocket
      
}

// export const connectToMyMenuSocketIo = (data) => {
//   let data_string = Buffer.from(JSON.stringify(data)).toString('base64')
  
//   const socket = io(`${socket_url}?data=${data_string}`);
//   return socket

    
// }