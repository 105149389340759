import React,{Component,useState, useEffect}  from 'react';
import {View, TouchableOpacity, ScrollView, Image, CheckBox, Text, AsyncStorage} from 'react-native';
import {Row, Col,Button, Input, Icon, } from 'native-base';
import {connect} from 'react-redux'
import BackButton from './../components/backButton'
import axios from 'axios'
import {API_URL, image_api} from './../constants/index'
import Material from '@expo/vector-icons/MaterialCommunityIcons'
import {performRequest} from './../services/apiHandler'
import Toast from './../components/toast'
import { Spinner} from 'native-base'

import {style} from "../../assets/style";
import * as ImagePicker from 'expo-image-picker';
import i18n from "../i18n";
import { number } from 'prop-types';
import PhoneInput from 'react-phone-number-input'
import * as Localization from "expo-localization";
import { FontLetterSpacing } from './../handlers/utils';
import StyledText from "../components/styledText"


const  Profile = (props) => {

    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [gender, setGender] = useState('')
    const [error_message, setErrorMessage] = useState('')
    const [message_status, setMessageStatus] = useState('')
    const [message_display, setMessageDisplay] = useState('none')
    const [profile_picture, setProfilePicure] = useState(null)
    const [new_image, setNewImage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [is_email_verified, setIsEmailVerifed] = useState(true)
    const [is_phone_verified, setIsPhoneVerifed] = useState(true)
    const [countryCode, setcountryCode] = useState(props.config.country.cca2)
    const [country, setCountry] = useState(props.config.country)


    useEffect(()=> {
        performRequest('get', `api/v2/pwa/profile/${props.user.id}`)
        .then(response=>{
            // const [first_name, last_name, gender, email, mobile_number] = response.data
            setFirstName(response.data.first_name)
            setLastName(response.data.last_name)
            setGender(response.data.gender)
            setEmail(response.data.email)
            setMobile(response.data.mobile_number)
            setProfilePicure(response.data.avatar)
            setIsEmailVerifed(response.data.email_verified)
            setIsPhoneVerifed(response.data.phone_verified)

        })
        .catch(errr => {
            console.log(errr.response, 'errorrrrr')
        })
    },[props.user])
    const throwMessageFn = (message, status) => {

        setErrorMessage(message)
        setMessageStatus(status)
        setMessageDisplay('inherit')

        setTimeout(
            function() {
                setErrorMessage('')
                setMessageStatus('')
                setMessageDisplay('none')
            },
                // .bind(this),
            3000
        );
    };
    const onSubmitForm = () => {
        setLoading(true)
        let formData = new FormData();
        if(new_image){
            formData.append('avatar', profile_picture);
        }
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        if(gender){
            formData.append('gender', gender);

        }

        performRequest('patch', `api/v2/pwa/profile/${props.user.id}`, formData)
        .then(response=>{
            setNewImage(false)
            setLoading(false)
            throwMessageFn('User data updated', 'green')

        })
        .catch(errr => {
            throwMessageFn('Error updating data', 'red')
        })
    }

    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    
    //Usage example:
    
    const pickImage = async () => {
        // setLoading(true)
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          aspect: [4, 3],
          quality: 0,
          base64:false
        });
       
        setLoading(true)
        console.log(result)
        let base64Data = result.uri
        let file_format = base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
        console.log('uriiii', result, file_format)
        let file_name = `${props.config.venue + Math.round((new Date()).getTime() / 1000)}.${file_format}`
        performRequest("post", "api/v2/account/avatar/post", {
            file_path: `public/pwa/avatars/${file_name}`
        })
        .then(async response => {
            console.log(response.data, "dataaaa")
            if (response?.data?.fields) {
                let formData = new FormData();
                Object.entries(response?.data?.fields).forEach(([k, v]) => {
                  formData.append(k, v);
                });
                var file = dataURLtoFile(base64Data,file_name);
                formData.append("file", file);
      
                let responseUpload = await axios.post(response?.data?.url, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                console.log(responseUpload, "ppppppp")
                let url = response.data.url+response.data.fields.key;
                setProfilePicure(url);
                setLoading(false)
            }else{
                throwMessageFn('Error updating profile picture', 'red')
                setLoading(false)
            }

        })
        .catch(err=>{
            console.log(err, "ppppppp")
            setLoading(false)
            throwMessageFn('Error updating profile picture', 'red')

        })
    
        if (!result.cancelled) {
            // setProfilePicure(result.uri);
            setNewImage(true)
            setLoading(false)
        }
      };
      const goBack = () => {
        const { state, setParams, navigate } = props.navigation;
        const params = state.params || {};
        params.returnHere()
          props.navigation.navigate('AccountScreen')
      }
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      const verifyHandler = (type) => {
        AsyncStorage.setItem('login_trigger', "ProfileScreen")

          if(type == "phone"){
            if (!mobile) {
                throwMessageFn('Enter a mobile number', 'red')
                return
            }
            setLoading(true)
            let locale = Localization.locale.split("-")[0]
            let number = !mobile.startsWith("+") ? `+${mobile}` : mobile
            performRequest("get",`api/v2/pwa/number/verify/auth?number=${number}&locale=${locale}&channel=${"SMS"}&venue_name=${props.config.displayName ? props.config.displayName : props.config.venueName}&venue_id=${props.config.venue}`,)
                .then((response) => {
                    setLoading(false);
                        props.navigation.navigate('OtpScreen', { 'country_code': country.callingCode[0], 'number': number, country: countryCode, channel: "SMS", service: response.data.service })
       
                })
                .catch(() => {
                    setLoading(false);
                    throwMessageFn('Error sending otp. Try again.', 'red')
                })

          }else{
            if (!email) {
                throwMessageFn('Enter an email address', 'red')
                return
            }
            if(!validateEmail(email)){
                throwMessageFn('Enter a valid email address', 'red')
                return
            }
            setLoading(true)
            performRequest("post", "api/v2/account/email/verify/auth", {
                email,
                venue: props.config.venue
            })
            .then(response => {
                setLoading(false);
                props.navigation.navigate('OtpScreen', { channel: "email", service: "", email })
                })
            .catch(err => {
                setLoading(false);
                console.log(err.response, "err")
                throwMessageFn('Error sending verification email', 'red')
            })

    
          }
      }
        return(
            <View style={{flex:1,backgroundColor: props.config.list_page_background_color}}>

            <View style={{flex:1,marginHorizontal:12}}>


                <StyledText
                    font_color={props.design.list_view_header_color}
                    font_weight={props.design.list_view_header_font_weight}
                    font_style={props.design.list_view_header_font_style}
                    text_transform={props.design.list_view_header_font_transform}
                    font_family={props.design.list_view_header_font_family}
                    font_size={props.design.list_view_header_font_size?? 20}
                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                 style={{ marginTop:80,marginVertical:15,marginLeft:5}}>Profile</StyledText>

                <ScrollView >
                    <View style={{width:'100%', alignItems:"center"}}>
                        <TouchableOpacity disabled={loading} style={{width: 120,height:120, borderRadius:'100%',borderWidth:1,borderColor:props.design.list_view_text_color}}  onPress={pickImage}>
                            {
                                loading ?
                                    <Spinner color={props.design.top_buttons_text_color} />
                                    :
                                    <Image style={{width: 120,height:120, borderRadius:'100%'}} source={profile_picture ? {uri: image_api + profile_picture.split("/").splice(3).join("/") + "?w=200&h=200"} : require('./../../assets/avatar.png')}/>

                            }
                        </TouchableOpacity>
                    </View>


                <View style={{width:'96%',alignSelf:'center'}}>
                    <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{color: props.design.list_view_text_color}}
                     >First Name</StyledText>
                    <View style={{height:35,marginTop:5,marginBottom:20}}>
                        <Input 
                            value={first_name}
                            onChangeText={(text) => setFirstName(text)}
                            style={{
                                color:'grey',
                                border:'1px solid grey',
                                borderRadius:5
                            }}
                        />
                    </View>
                    <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{}} >Last Name</StyledText>
                    <View style={{height:35,marginTop:5,marginBottom:20}}>
                        <Input 
                            value={last_name}
                            onChangeText={(text) => setLastName(text)}
                            style={{
                                color:'grey',
                                border:'1px solid grey',
                                borderRadius:5
                            }}
                        />
                    </View>
                    <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{}} >Email</StyledText>
                    <View style={{height:35,marginTop:5,marginBottom:20}}>
                        <Input 
                        disabled={is_email_verified}
                            value={email}
                            onChangeText={(text) => setEmail(text)}
                            style={{
                                color:'grey',
                                border:'1px solid grey',
                                borderRadius:5
                            }}
                        />
                            <View style={{flexDirection:"row",justifyContent:"space-between"}} >
                            <StyledText
                                font_color={props.design.list_view_text_color}
                                font_weight={props.design.list_view_text_font_weight}
                                font_style={props.design.list_view_text_font_style}
                                text_transform={props.design.list_view_text_font_transform}
                                font_family={props.design.list_view_text_font_family}
                                font_size={props.design.list_view_text_font_size?? 12}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
    
                             style={{fontSize:10,marginTop:5}} >{is_email_verified ? i18n.t("labels.verified") : i18n.t("labels.not_verified") }</StyledText>
                            {
                                !is_email_verified &&
                                <TouchableOpacity  onPress={()=>verifyHandler("email")}>
                                    <StyledText
                                    font_color={props.design.list_view_text_color}
                                    font_weight={props.design.list_view_text_font_weight}
                                    font_style={props.design.list_view_text_font_style}
                                    text_transform={props.design.list_view_text_font_transform}
                                    font_family={props.design.list_view_text_font_family}
                                    font_size={props.design.list_view_text_font_size?? 12}
                                    letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
        
                                     style={{fontSize:10,marginTop:5}} >{ i18n.t("labels.verify_now") }</StyledText>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                    <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{fontFamily:'SecondaryFont'}} >Mobile</StyledText>
                    <View style={{height:35,marginTop:5,marginBottom:20}}>
                   
                            <PhoneInput
                            disabled={is_phone_verified}
                                style={{
                                    // fontSize: 40,
                                    height: '100%',
                                }}
                                // defaultCountry={countryCode}
                                value={mobile&&!mobile.startsWith("+") ? `+${mobile}`: mobile}
                                onChange={(text) => setMobile(text)} />
                        <View style={{flexDirection:"row",justifyContent:"space-between"}} >
                            <StyledText 
                                font_color={props.design.list_view_text_color}
                                font_weight={props.design.list_view_text_font_weight}
                                font_style={props.design.list_view_text_font_style}
                                text_transform={props.design.list_view_text_font_transform}
                                font_family={props.design.list_view_text_font_family}
                                font_size={props.design.list_view_text_font_size?? 12}
                                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                            style={{fontSize:10,marginTop:5}} >{is_phone_verified ? i18n.t("labels.verified") : i18n.t("labels.not_verified") }</StyledText>
                            {
                                !is_phone_verified &&
                                <TouchableOpacity onPress={()=>verifyHandler("phone")} >
                                    <StyledText 
                                        font_color={props.design.list_view_text_color}
                                        font_weight={props.design.list_view_text_font_weight}
                                        font_style={props.design.list_view_text_font_style}
                                        text_transform={props.design.list_view_text_font_transform}
                                        font_family={props.design.list_view_text_font_family}
                                        font_size={props.design.list_view_text_font_size?? 12}
                                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
        
                                    style={{fontSize:10,marginTop:5}} >{ i18n.t("labels.verify_now") }</StyledText>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                    <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{fontFamily:'SecondaryFont'}} >Gender</StyledText>
                    <View style={{height:35,marginTop:5,marginBottom:20}}>
                        <View style={{marginBottom:15, marginTop:15,flexDirection:'row'}}>

                        <StyledText 
                        font_color={props.design.list_view_text_color}
                        font_weight={props.design.list_view_text_font_weight}
                        font_style={props.design.list_view_text_font_style}
                        text_transform={props.design.list_view_text_font_transform}
                        font_family={props.design.list_view_text_font_family}
                        font_size={props.design.list_view_text_font_size?? 12}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                        style={{ }}>Male  </StyledText>
                        <TouchableOpacity  onPress={()=>setGender('1')}>
                            <CheckBox  value={ gender == '1'? true : false} color={props.design.list_view_text_color}/>
                        </TouchableOpacity>

                        <StyledText 
                            font_color={props.design.list_view_text_color}
                            font_weight={props.design.list_view_text_font_weight}
                            font_style={props.design.list_view_text_font_style}
                            text_transform={props.design.list_view_text_font_transform}
                            font_family={props.design.list_view_text_font_family}
                            font_size={props.design.list_view_text_font_size?? 12}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                        style={{marginLeft:20,}}>   Female  </StyledText>
                        <TouchableOpacity onPress={()=>setGender('2')}>
                            <CheckBox value={ gender == '2'? true : false} color={props.design.list_view_text_color}/>
                        </TouchableOpacity>

                        </View>
                    </View>





                    <TouchableOpacity disabled={loading} onPress={onSubmitForm}>
                        <View style={{borderRadius:5,width:'100%',height:50,backgroundColor: props.design.top_buttons_background_color,justifyContent:'center',flexDirection:'row', marginTop:20}}>
                           
                            <View style={{justifyContent:'center',alignItems:'center'}}>
                                {
                                    loading ? 
                                        <Spinner color={props.design.top_buttons_text_color} />
                                        :
                                        <StyledText 
                                        font_color={props.design.top_buttons_text_color}
                                            font_weight={props.design.top_button_text_font_weight}
                                            font_style={props.design.top_button_text_font_style}
                                            text_transform={props.design.top_button_text_font_transform}
                                            font_family={props.design.top_button_text_font_family}
                                            font_size={props.design.top_button_text_font_size ?? 13}
                                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                                        style={{ marginRight:10}}>Update Changes</StyledText>
                                }
                                
                            </View>
                        </View>
                    </TouchableOpacity>




                </View>


                </ScrollView>

            </View>

                <TouchableOpacity style={{position:'absolute',top:0}} onPress={goBack} >
                    <BackButton
                     detail_page={true}
                        border_color={`1px solid ${props.design.top_buttons_border_color}`}
                        // goBack={() => props.navigation.goBack()}
                        backgroundColor={props.design.top_buttons_background_color}
                        borderColor={'black'}
                        color={props.design.top_buttons_text_color}
                        default_language={props.default_language}
                        font_size={props.design.custom_font.heading_size}
                    />
                </TouchableOpacity>
                <Toast
                error_message={error_message}
                message_status={message_status}
                message_display={message_display}
                custom_font={props.design.custom_font}
            />     
           
            </View>
        )
}

Profile['navigationOptions'] = screenProps => ({
    header: null,
    gesturesEnabled: false,
    animationEnabled:true
})

const mapStateToProps = state => {
    return{
        menus: state.menus.menus,
        allChildren: state.menus.allChildren,
        immediateChildren: state.menus._immediateChildren,
        cart: state.cart.cart,
        cart_count: state.cart.cart_count,
        total_price: state.cart.total_price,
        config: state.config.config,
        languages: state.languages.languages,
        default_language: state.languages.default_language,
        isRTL: state.languages.isRTL,
        menu_type: state.config.menu_type,
        platform: state.config.platform,
        user: state.number.user,
        design: state.config.design,


    }
};

export default connect(mapStateToProps, {})(Profile)