'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SettingList;
var SettingPage;
var SettingInstance;
var SettingContext;

/* jshint ignore:start */
/**
 * Initialize the SettingList
 *
 * @constructor Twilio.Insights.V1.SettingList
 *
 * @param {Twilio.Insights.V1} version - Version of the resource
 */
/* jshint ignore:end */
SettingList = function SettingList(version) {
  /* jshint ignore:start */
  /**
   * @function settings
   * @memberof Twilio.Insights.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Insights.V1.SettingContext}
   */
  /* jshint ignore:end */
  function SettingListInstance(sid) {
    return SettingListInstance.get(sid);
  }

  SettingListInstance._version = version;
  // Path Solution
  SettingListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a setting
   *
   * @function get
   * @memberof Twilio.Insights.V1.SettingList#
   *
   * @returns {Twilio.Insights.V1.SettingContext}
   */
  /* jshint ignore:end */
  SettingListInstance.get = function get() {
    return new SettingContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Insights.V1.SettingList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SettingListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SettingListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SettingListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SettingPage
 *
 * @constructor Twilio.Insights.V1.SettingPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SettingSolution} solution - Path solution
 *
 * @returns SettingPage
 */
/* jshint ignore:end */
SettingPage = function SettingPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SettingPage.prototype, Page.prototype);
SettingPage.prototype.constructor = SettingPage;

/* jshint ignore:start */
/**
 * Build an instance of SettingInstance
 *
 * @function getInstance
 * @memberof Twilio.Insights.V1.SettingPage#
 *
 * @param {SettingPayload} payload - Payload response from the API
 *
 * @returns SettingInstance
 */
/* jshint ignore:end */
SettingPage.prototype.getInstance = function getInstance(payload) {
  return new SettingInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.SettingPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SettingPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SettingPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SettingContext
 *
 * @constructor Twilio.Insights.V1.SettingInstance
 *
 * @property {string} accountSid - The account_sid
 * @property {boolean} advancedFeatures - The advanced_features
 * @property {boolean} voiceTrace - The voice_trace
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {SettingPayload} payload - The instance payload
 */
/* jshint ignore:end */
SettingInstance = function SettingInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.advancedFeatures = payload.advanced_features; // jshint ignore:line
  this.voiceTrace = payload.voice_trace; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(SettingInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SettingContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a SettingInstance
 *
 * @function fetch
 * @memberof Twilio.Insights.V1.SettingInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.subaccountSid] - The subaccount_sid
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SettingInstance
 */
/* jshint ignore:end */
SettingInstance.prototype.fetch = function fetch(opts, callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * update a SettingInstance
 *
 * @function update
 * @memberof Twilio.Insights.V1.SettingInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {boolean} [opts.advancedFeatures] - The advanced_features
 * @param {boolean} [opts.voiceTrace] - The voice_trace
 * @param {string} [opts.subaccountSid] - The subaccount_sid
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SettingInstance
 */
/* jshint ignore:end */
SettingInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.SettingInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SettingInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SettingInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SettingContext
 *
 * @constructor Twilio.Insights.V1.SettingContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
SettingContext = function SettingContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/Voice/Settings`;
};

/* jshint ignore:start */
/**
 * fetch a SettingInstance
 *
 * @function fetch
 * @memberof Twilio.Insights.V1.SettingContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.subaccountSid] - The subaccount_sid
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SettingInstance
 */
/* jshint ignore:end */
SettingContext.prototype.fetch = function fetch(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'SubaccountSid': _.get(opts, 'subaccountSid')});

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SettingInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a SettingInstance
 *
 * @function update
 * @memberof Twilio.Insights.V1.SettingContext#
 *
 * @param {object} [opts] - Options for request
 * @param {boolean} [opts.advancedFeatures] - The advanced_features
 * @param {boolean} [opts.voiceTrace] - The voice_trace
 * @param {string} [opts.subaccountSid] - The subaccount_sid
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SettingInstance
 */
/* jshint ignore:end */
SettingContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'AdvancedFeatures': serialize.bool(_.get(opts, 'advancedFeatures')),
    'VoiceTrace': serialize.bool(_.get(opts, 'voiceTrace')),
    'SubaccountSid': _.get(opts, 'subaccountSid')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SettingInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Insights.V1.SettingContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SettingContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SettingContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SettingList: SettingList,
  SettingPage: SettingPage,
  SettingInstance: SettingInstance,
  SettingContext: SettingContext
};
