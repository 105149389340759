'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./supersim/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize supersim domain
 *
 * @constructor Twilio.Supersim
 *
 * @property {Twilio.Supersim.V1} v1 - v1 version
 * @property {Twilio.Supersim.V1.EsimProfileList} esimProfiles -
 *          esimProfiles resource
 * @property {Twilio.Supersim.V1.FleetList} fleets - fleets resource
 * @property {Twilio.Supersim.V1.IpCommandList} ipCommands - ipCommands resource
 * @property {Twilio.Supersim.V1.NetworkList} networks - networks resource
 * @property {Twilio.Supersim.V1.NetworkAccessProfileList} networkAccessProfiles -
 *          networkAccessProfiles resource
 * @property {Twilio.Supersim.V1.SettingsUpdateList} settingsUpdates -
 *          settingsUpdates resource
 * @property {Twilio.Supersim.V1.SimList} sims - sims resource
 * @property {Twilio.Supersim.V1.SmsCommandList} smsCommands - smsCommands resource
 * @property {Twilio.Supersim.V1.UsageRecordList} usageRecords -
 *          usageRecords resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Supersim(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://supersim.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(Supersim.prototype, Domain.prototype);
Supersim.prototype.constructor = Supersim;

Object.defineProperty(Supersim.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(Supersim.prototype,
  'esimProfiles', {
    get: function() {
      return this.v1.esimProfiles;
    }
});

Object.defineProperty(Supersim.prototype,
  'fleets', {
    get: function() {
      return this.v1.fleets;
    }
});

Object.defineProperty(Supersim.prototype,
  'ipCommands', {
    get: function() {
      return this.v1.ipCommands;
    }
});

Object.defineProperty(Supersim.prototype,
  'networks', {
    get: function() {
      return this.v1.networks;
    }
});

Object.defineProperty(Supersim.prototype,
  'networkAccessProfiles', {
    get: function() {
      return this.v1.networkAccessProfiles;
    }
});

Object.defineProperty(Supersim.prototype,
  'settingsUpdates', {
    get: function() {
      return this.v1.settingsUpdates;
    }
});

Object.defineProperty(Supersim.prototype,
  'sims', {
    get: function() {
      return this.v1.sims;
    }
});

Object.defineProperty(Supersim.prototype,
  'smsCommands', {
    get: function() {
      return this.v1.smsCommands;
    }
});

Object.defineProperty(Supersim.prototype,
  'usageRecords', {
    get: function() {
      return this.v1.usageRecords;
    }
});

module.exports = Supersim;
