import React from "react";
import { View, Text, TouchableOpacity, Button, FlatList } from "react-native";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import {
  Thumbnail,
  List,
  ListItem,
  Separator,
  Item,
  Input,
  Label,
} from "native-base";
import { connect } from "react-redux";
import BackButton from "../components/backButton";
import axios from "axios";
import { API_URL } from "../constants";
import { getModifiers, addMenu } from "../store/actions";
import _ from "lodash";
import { FontLetterSpacing } from "./../handlers/utils";
import StyledText from "../components/styledText"

class SelectDestinationScreen extends React.Component {
  static navigationOptions = {
    header: null,
    gesturesEnabled: true,
    animationEnabled: true,
  };

  onSelectingVenue = (item) => {
    axios
      .all([this.getAllModifiers(item.id), this.getMenu(item.id)])
      .then(
        axios.spread(async (modifiers, menus) => {
          this.props.getModifiers(modifiers.data);
          this.props.addMenu(menus.data);
          this.props.navigation.navigate("MenuHome");
        })
      )
      .catch((error) => {
        console.log(error.response, "resssss");
      });
  };
  getMenu = (id) => {
    return axios.get(API_URL + `api/qr-menu/${id}?platform=enterprise`);
  };

  getAllModifiers = (id) => {
    return axios.get(API_URL + `api/qr-modifiers/${id}?platform=enterprise`);
  };

  render() {
    const { design } = this.props;
    return (
      <View
        style={{ flex: 1, backgroundColor: design.list_view_background_color }}
      >
        <StyledText
        font_color={design.list_view_header_color}
        font_weight={design.list_view_header_font_weight}
        font_style={design.list_view_header_font_style}
        text_transform={design.list_view_header_font_transform}
        font_family={design.list_view_header_font_family}
        font_size={design.list_view_header_font_size?? 23}
        letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
          style={{
           
            marginTop: 80,
            marginVertical: 20,
            marginLeft: 11,
           
          }}
        >
          Select Venue
        </StyledText>
        {/* <View style={{width:'96%',alignSelf:'center',height:36,marginBottom:20}}>

            </View> */}

        {/* <View style={{flex:1, backgroundColor:'red'}}> */}

        {this.props.venues ? (
          this.props.venues.length ? (
            <FlatList
              data={this.props.venues}
              renderItem={({ item }) => (
                <Collapse
                  style={{ backgroundColor: design.list_view_background_color }}
                  onToggle={(ee) => this.onSelectingVenue(item)}
                >
                  <CollapseHeader
                    style={{
                      backgroundColor: design.list_view_background_color,
                    }}
                  >
                    <Separator bordered>
                      <StyledText 
                      font_color={design.list_view_text_color}
                      font_weight={design.list_view_text_font_weight}
                      font_style={design.list_view_text_font_style}
                      text_transform={design.list_view_text_font_transform}
                      font_family={design.list_view_text_font_family}
                      font_size={design.list_view_text_font_size?? 12}
                      letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                      style={{ }}>
                        {item.venue}
                      </StyledText>
                    </Separator>
                  </CollapseHeader>
                </Collapse>
              )}
              keyExtractor={(item) => item.id}
            />
          ) : null
        ) : (
          <StyledText 
          font_color={design.list_view_text_color}
                  font_weight={design.list_view_text_font_weight}
                  font_style={design.list_view_text_font_style}
                  text_transform={design.list_view_text_font_transform}
                  font_family={design.list_view_text_font_family}
                  font_size={design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
          style={{  }}>No Venues Available</StyledText>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config.config,
    venues: state.config.venues,
    design: state.config.design,
  };
};
export default connect(mapStateToProps, { getModifiers, addMenu })(
  SelectDestinationScreen
);
