'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var RecordingSettingsList;
var RecordingSettingsPage;
var RecordingSettingsInstance;
var RecordingSettingsContext;

/* jshint ignore:start */
/**
 * Initialize the RecordingSettingsList
 *
 * @constructor Twilio.Video.V1.RecordingSettingsList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 */
/* jshint ignore:end */
RecordingSettingsList = function RecordingSettingsList(version) {
  /* jshint ignore:start */
  /**
   * @function recordingSettings
   * @memberof Twilio.Video.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.RecordingSettingsContext}
   */
  /* jshint ignore:end */
  function RecordingSettingsListInstance(sid) {
    return RecordingSettingsListInstance.get(sid);
  }

  RecordingSettingsListInstance._version = version;
  // Path Solution
  RecordingSettingsListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a recording_settings
   *
   * @function get
   * @memberof Twilio.Video.V1.RecordingSettingsList#
   *
   * @returns {Twilio.Video.V1.RecordingSettingsContext}
   */
  /* jshint ignore:end */
  RecordingSettingsListInstance.get = function get() {
    return new RecordingSettingsContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.RecordingSettingsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  RecordingSettingsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  RecordingSettingsListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return RecordingSettingsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the RecordingSettingsPage
 *
 * @constructor Twilio.Video.V1.RecordingSettingsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {RecordingSettingsSolution} solution - Path solution
 *
 * @returns RecordingSettingsPage
 */
/* jshint ignore:end */
RecordingSettingsPage = function RecordingSettingsPage(version, response,
                                                        solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(RecordingSettingsPage.prototype, Page.prototype);
RecordingSettingsPage.prototype.constructor = RecordingSettingsPage;

/* jshint ignore:start */
/**
 * Build an instance of RecordingSettingsInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.RecordingSettingsPage#
 *
 * @param {RecordingSettingsPayload} payload - Payload response from the API
 *
 * @returns RecordingSettingsInstance
 */
/* jshint ignore:end */
RecordingSettingsPage.prototype.getInstance = function getInstance(payload) {
  return new RecordingSettingsInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RecordingSettingsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
RecordingSettingsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RecordingSettingsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the RecordingSettingsContext
 *
 * @constructor Twilio.Video.V1.RecordingSettingsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} awsCredentialsSid - The SID of the stored Credential resource
 * @property {string} awsS3Url -
 *          The URL of the AWS S3 bucket where the recordings are stored
 * @property {boolean} awsStorageEnabled -
 *          Whether all recordings are written to the aws_s3_url
 * @property {string} encryptionKeySid -
 *          The SID of the Public Key resource used for encryption
 * @property {boolean} encryptionEnabled -
 *          Whether all recordings are stored in an encrypted form
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V1} version - Version of the resource
 * @param {RecordingSettingsPayload} payload - The instance payload
 */
/* jshint ignore:end */
RecordingSettingsInstance = function RecordingSettingsInstance(version, payload)
    {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.awsCredentialsSid = payload.aws_credentials_sid; // jshint ignore:line
  this.awsS3Url = payload.aws_s3_url; // jshint ignore:line
  this.awsStorageEnabled = payload.aws_storage_enabled; // jshint ignore:line
  this.encryptionKeySid = payload.encryption_key_sid; // jshint ignore:line
  this.encryptionEnabled = payload.encryption_enabled; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(RecordingSettingsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new RecordingSettingsContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a RecordingSettingsInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.RecordingSettingsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed RecordingSettingsInstance
 */
/* jshint ignore:end */
RecordingSettingsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * create a RecordingSettingsInstance
 *
 * @function create
 * @memberof Twilio.Video.V1.RecordingSettingsInstance#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.friendlyName - A string to describe the resource
 * @param {string} [opts.awsCredentialsSid] -
 *          The SID of the stored Credential resource
 * @param {string} [opts.encryptionKeySid] -
 *          The SID of the Public Key resource to use for encryption
 * @param {string} [opts.awsS3Url] -
 *          The URL of the AWS S3 bucket where the recordings should be stored
 * @param {boolean} [opts.awsStorageEnabled] -
 *          Whether all recordings should be written to the aws_s3_url
 * @param {boolean} [opts.encryptionEnabled] -
 *          Whether all recordings should be stored in an encrypted form
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed RecordingSettingsInstance
 */
/* jshint ignore:end */
RecordingSettingsInstance.prototype.create = function create(opts, callback) {
  return this._proxy.create(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RecordingSettingsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
RecordingSettingsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RecordingSettingsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the RecordingSettingsContext
 *
 * @constructor Twilio.Video.V1.RecordingSettingsContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
RecordingSettingsContext = function RecordingSettingsContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/RecordingSettings/Default`;
};

/* jshint ignore:start */
/**
 * fetch a RecordingSettingsInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.RecordingSettingsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed RecordingSettingsInstance
 */
/* jshint ignore:end */
RecordingSettingsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new RecordingSettingsInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * create a RecordingSettingsInstance
 *
 * @function create
 * @memberof Twilio.Video.V1.RecordingSettingsContext#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.friendlyName - A string to describe the resource
 * @param {string} [opts.awsCredentialsSid] -
 *          The SID of the stored Credential resource
 * @param {string} [opts.encryptionKeySid] -
 *          The SID of the Public Key resource to use for encryption
 * @param {string} [opts.awsS3Url] -
 *          The URL of the AWS S3 bucket where the recordings should be stored
 * @param {boolean} [opts.awsStorageEnabled] -
 *          Whether all recordings should be written to the aws_s3_url
 * @param {boolean} [opts.encryptionEnabled] -
 *          Whether all recordings should be stored in an encrypted form
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed RecordingSettingsInstance
 */
/* jshint ignore:end */
RecordingSettingsContext.prototype.create = function create(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['friendlyName'])) {
    throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'AwsCredentialsSid': _.get(opts, 'awsCredentialsSid'),
    'EncryptionKeySid': _.get(opts, 'encryptionKeySid'),
    'AwsS3Url': _.get(opts, 'awsS3Url'),
    'AwsStorageEnabled': serialize.bool(_.get(opts, 'awsStorageEnabled')),
    'EncryptionEnabled': serialize.bool(_.get(opts, 'encryptionEnabled'))
  });

  var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new RecordingSettingsInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RecordingSettingsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
RecordingSettingsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

RecordingSettingsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  RecordingSettingsList: RecordingSettingsList,
  RecordingSettingsPage: RecordingSettingsPage,
  RecordingSettingsInstance: RecordingSettingsInstance,
  RecordingSettingsContext: RecordingSettingsContext
};
