'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var CredentialListList = require('./trunk/credentialList').CredentialListList;
var IpAccessControlListList = require(
    './trunk/ipAccessControlList').IpAccessControlListList;
var OriginationUrlList = require('./trunk/originationUrl').OriginationUrlList;
var Page = require('../../../base/Page');  /* jshint ignore:line */
var PhoneNumberList = require('./trunk/phoneNumber').PhoneNumberList;
var RecordingList = require('./trunk/recording').RecordingList;
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var TrunkList;
var TrunkPage;
var TrunkInstance;
var TrunkContext;

/* jshint ignore:start */
/**
 * Initialize the TrunkList
 *
 * @constructor Twilio.Trunking.V1.TrunkList
 *
 * @param {Twilio.Trunking.V1} version - Version of the resource
 */
/* jshint ignore:end */
TrunkList = function TrunkList(version) {
  /* jshint ignore:start */
  /**
   * @function trunks
   * @memberof Twilio.Trunking.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Trunking.V1.TrunkContext}
   */
  /* jshint ignore:end */
  function TrunkListInstance(sid) {
    return TrunkListInstance.get(sid);
  }

  TrunkListInstance._version = version;
  // Path Solution
  TrunkListInstance._solution = {};
  TrunkListInstance._uri = `/Trunks`;
  /* jshint ignore:start */
  /**
   * create a TrunkInstance
   *
   * @function create
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.friendlyName] - A string to describe the resource
   * @param {string} [opts.domainName] -
   *          The unique address you reserve on Twilio to which you route your SIP traffic
   * @param {string} [opts.disasterRecoveryUrl] -
   *          The HTTP URL that we should call if an error occurs while sending SIP traffic towards your configured Origination URL
   * @param {string} [opts.disasterRecoveryMethod] -
   *          The HTTP method we should use to call the disaster_recovery_url
   * @param {trunk.transfer_setting} [opts.transferMode] -
   *          The call transfer settings for the trunk
   * @param {boolean} [opts.secure] -
   *          Whether Secure Trunking is enabled for the trunk
   * @param {boolean} [opts.cnamLookupEnabled] -
   *          Whether Caller ID Name (CNAM) lookup should be enabled for the trunk
   * @param {trunk.transfer_caller_id} [opts.transferCallerId] -
   *          Caller Id for transfer target
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed TrunkInstance
   */
  /* jshint ignore:end */
  TrunkListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'DomainName': _.get(opts, 'domainName'),
      'DisasterRecoveryUrl': _.get(opts, 'disasterRecoveryUrl'),
      'DisasterRecoveryMethod': _.get(opts, 'disasterRecoveryMethod'),
      'TransferMode': _.get(opts, 'transferMode'),
      'Secure': serialize.bool(_.get(opts, 'secure')),
      'CnamLookupEnabled': serialize.bool(_.get(opts, 'cnamLookupEnabled')),
      'TransferCallerId': _.get(opts, 'transferCallerId')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrunkInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams TrunkInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  TrunkListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists TrunkInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrunkListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of TrunkInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrunkListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrunkPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of TrunkInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  TrunkListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new TrunkPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a trunk
   *
   * @function get
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Trunking.V1.TrunkContext}
   */
  /* jshint ignore:end */
  TrunkListInstance.get = function get(sid) {
    return new TrunkContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Trunking.V1.TrunkList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TrunkListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TrunkListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return TrunkListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TrunkPage
 *
 * @constructor Twilio.Trunking.V1.TrunkPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TrunkSolution} solution - Path solution
 *
 * @returns TrunkPage
 */
/* jshint ignore:end */
TrunkPage = function TrunkPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TrunkPage.prototype, Page.prototype);
TrunkPage.prototype.constructor = TrunkPage;

/* jshint ignore:start */
/**
 * Build an instance of TrunkInstance
 *
 * @function getInstance
 * @memberof Twilio.Trunking.V1.TrunkPage#
 *
 * @param {TrunkPayload} payload - Payload response from the API
 *
 * @returns TrunkInstance
 */
/* jshint ignore:end */
TrunkPage.prototype.getInstance = function getInstance(payload) {
  return new TrunkInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trunking.V1.TrunkPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrunkPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrunkContext
 *
 * @constructor Twilio.Trunking.V1.TrunkInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} domainName -
 *          The unique address you reserve on Twilio to which you route your SIP traffic
 * @property {string} disasterRecoveryMethod -
 *          The HTTP method we use to call the disaster_recovery_url
 * @property {string} disasterRecoveryUrl -
 *          The HTTP URL that we call if an error occurs while sending SIP traffic towards your configured Origination URL
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {boolean} secure - Whether Secure Trunking is enabled for the trunk
 * @property {object} recording - The recording settings for the trunk
 * @property {trunk.transfer_setting} transferMode -
 *          The call transfer settings for the trunk
 * @property {trunk.transfer_caller_id} transferCallerId -
 *          Caller Id for transfer target
 * @property {boolean} cnamLookupEnabled -
 *          Whether Caller ID Name (CNAM) lookup is enabled for the trunk
 * @property {string} authType - The types of authentication mapped to the domain
 * @property {string} authTypeSet - Reserved
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT when the resource was last updated
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} url - The absolute URL of the resource
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {TrunkPayload} payload - The instance payload
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
TrunkInstance = function TrunkInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.domainName = payload.domain_name; // jshint ignore:line
  this.disasterRecoveryMethod = payload.disaster_recovery_method; // jshint ignore:line
  this.disasterRecoveryUrl = payload.disaster_recovery_url; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.secure = payload.secure; // jshint ignore:line
  this.recording = payload.recording; // jshint ignore:line
  this.transferMode = payload.transfer_mode; // jshint ignore:line
  this.transferCallerId = payload.transfer_caller_id; // jshint ignore:line
  this.cnamLookupEnabled = payload.cnam_lookup_enabled; // jshint ignore:line
  this.authType = payload.auth_type; // jshint ignore:line
  this.authTypeSet = payload.auth_type_set; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(TrunkInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TrunkContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a TrunkInstance
 *
 * @function fetch
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a TrunkInstance
 *
 * @function remove
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * update a TrunkInstance
 *
 * @function update
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.domainName] -
 *          The unique address you reserve on Twilio to which you route your SIP traffic
 * @param {string} [opts.disasterRecoveryUrl] -
 *          The HTTP URL that we should call if an error occurs while sending SIP traffic towards your configured Origination URL
 * @param {string} [opts.disasterRecoveryMethod] -
 *          The HTTP method we should use to call the disaster_recovery_url
 * @param {trunk.transfer_setting} [opts.transferMode] -
 *          The call transfer settings for the trunk
 * @param {boolean} [opts.secure] -
 *          Whether Secure Trunking is enabled for the trunk
 * @param {boolean} [opts.cnamLookupEnabled] -
 *          Whether Caller ID Name (CNAM) lookup should be enabled for the trunk
 * @param {trunk.transfer_caller_id} [opts.transferCallerId] -
 *          Caller Id for transfer target
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the originationUrls
 *
 * @function originationUrls
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns {Twilio.Trunking.V1.TrunkContext.OriginationUrlList}
 */
/* jshint ignore:end */
TrunkInstance.prototype.originationUrls = function originationUrls() {
  return this._proxy.originationUrls;
};

/* jshint ignore:start */
/**
 * Access the credentialsLists
 *
 * @function credentialsLists
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns {Twilio.Trunking.V1.TrunkContext.CredentialListList}
 */
/* jshint ignore:end */
TrunkInstance.prototype.credentialsLists = function credentialsLists() {
  return this._proxy.credentialsLists;
};

/* jshint ignore:start */
/**
 * Access the ipAccessControlLists
 *
 * @function ipAccessControlLists
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns {Twilio.Trunking.V1.TrunkContext.IpAccessControlListList}
 */
/* jshint ignore:end */
TrunkInstance.prototype.ipAccessControlLists = function ipAccessControlLists() {
  return this._proxy.ipAccessControlLists;
};

/* jshint ignore:start */
/**
 * Access the phoneNumbers
 *
 * @function phoneNumbers
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns {Twilio.Trunking.V1.TrunkContext.PhoneNumberList}
 */
/* jshint ignore:end */
TrunkInstance.prototype.phoneNumbers = function phoneNumbers() {
  return this._proxy.phoneNumbers;
};

/* jshint ignore:start */
/**
 * Access the recordings
 *
 * @function recordings
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns {Twilio.Trunking.V1.TrunkContext.RecordingList}
 */
/* jshint ignore:end */
TrunkInstance.prototype.recordings = function recordings() {
  return this._proxy.recordings;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trunking.V1.TrunkInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrunkInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrunkContext
 *
 * @constructor Twilio.Trunking.V1.TrunkContext
 *
 * @property {Twilio.Trunking.V1.TrunkContext.OriginationUrlList} originationUrls -
 *          originationUrls resource
 * @property {Twilio.Trunking.V1.TrunkContext.CredentialListList} credentialsLists -
 *          credentialsLists resource
 * @property {Twilio.Trunking.V1.TrunkContext.IpAccessControlListList} ipAccessControlLists -
 *          ipAccessControlLists resource
 * @property {Twilio.Trunking.V1.TrunkContext.PhoneNumberList} phoneNumbers -
 *          phoneNumbers resource
 * @property {Twilio.Trunking.V1.TrunkContext.RecordingList} recordings -
 *          recordings resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
TrunkContext = function TrunkContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Trunks/${sid}`;

  // Dependents
  this._originationUrls = undefined;
  this._credentialsLists = undefined;
  this._ipAccessControlLists = undefined;
  this._phoneNumbers = undefined;
  this._recordings = undefined;
};

/* jshint ignore:start */
/**
 * fetch a TrunkInstance
 *
 * @function fetch
 * @memberof Twilio.Trunking.V1.TrunkContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TrunkInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a TrunkInstance
 *
 * @function remove
 * @memberof Twilio.Trunking.V1.TrunkContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a TrunkInstance
 *
 * @function update
 * @memberof Twilio.Trunking.V1.TrunkContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.domainName] -
 *          The unique address you reserve on Twilio to which you route your SIP traffic
 * @param {string} [opts.disasterRecoveryUrl] -
 *          The HTTP URL that we should call if an error occurs while sending SIP traffic towards your configured Origination URL
 * @param {string} [opts.disasterRecoveryMethod] -
 *          The HTTP method we should use to call the disaster_recovery_url
 * @param {trunk.transfer_setting} [opts.transferMode] -
 *          The call transfer settings for the trunk
 * @param {boolean} [opts.secure] -
 *          Whether Secure Trunking is enabled for the trunk
 * @param {boolean} [opts.cnamLookupEnabled] -
 *          Whether Caller ID Name (CNAM) lookup should be enabled for the trunk
 * @param {trunk.transfer_caller_id} [opts.transferCallerId] -
 *          Caller Id for transfer target
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'DomainName': _.get(opts, 'domainName'),
    'DisasterRecoveryUrl': _.get(opts, 'disasterRecoveryUrl'),
    'DisasterRecoveryMethod': _.get(opts, 'disasterRecoveryMethod'),
    'TransferMode': _.get(opts, 'transferMode'),
    'Secure': serialize.bool(_.get(opts, 'secure')),
    'CnamLookupEnabled': serialize.bool(_.get(opts, 'cnamLookupEnabled')),
    'TransferCallerId': _.get(opts, 'transferCallerId')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new TrunkInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(TrunkContext.prototype,
  'originationUrls', {
    get: function() {
      if (!this._originationUrls) {
        this._originationUrls = new OriginationUrlList(this._version, this._solution.sid);
      }
      return this._originationUrls;
    }
});

Object.defineProperty(TrunkContext.prototype,
  'credentialsLists', {
    get: function() {
      if (!this._credentialsLists) {
        this._credentialsLists = new CredentialListList(this._version, this._solution.sid);
      }
      return this._credentialsLists;
    }
});

Object.defineProperty(TrunkContext.prototype,
  'ipAccessControlLists', {
    get: function() {
      if (!this._ipAccessControlLists) {
        this._ipAccessControlLists = new IpAccessControlListList(this._version, this._solution.sid);
      }
      return this._ipAccessControlLists;
    }
});

Object.defineProperty(TrunkContext.prototype,
  'phoneNumbers', {
    get: function() {
      if (!this._phoneNumbers) {
        this._phoneNumbers = new PhoneNumberList(this._version, this._solution.sid);
      }
      return this._phoneNumbers;
    }
});

Object.defineProperty(TrunkContext.prototype,
  'recordings', {
    get: function() {
      if (!this._recordings) {
        this._recordings = new RecordingList(this._version, this._solution.sid);
      }
      return this._recordings;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trunking.V1.TrunkContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TrunkContext.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TrunkList: TrunkList,
  TrunkPage: TrunkPage,
  TrunkInstance: TrunkInstance,
  TrunkContext: TrunkContext
};
