'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var TwiML = require('./TwiML');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * <Response> TwiML for Messages
 *
 * @constructor MessagingResponse
 */
/* jshint ignore:end */
function MessagingResponse() {
  TwiML.call(this);
  this._propertyName = 'response';
}

MessagingResponse.prototype = Object.create(TwiML.prototype);
MessagingResponse.prototype.constructor = MessagingResponse;

/* jshint ignore:start */
/**
 * <Message> TwiML Verb
 *
 * @function message
 * @memberof MessagingResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.to] - Phone Number to send Message to
 * @param {string} [attributes.from] - Phone Number to send Message from
 * @param {string} [attributes.action] - Action URL
 * @param {string} [attributes.method] - Action URL Method
 * @param {string} [attributes.statusCallback] -
 *          Status callback URL. Deprecated in favor of action.
 * @param {string} body - Message Body
 *
 * @returns Message
 */
/* jshint ignore:end */
MessagingResponse.prototype.message = function message(attributes, body) {
  return new Message(this.response.ele('Message', attributes, body));
};

/* jshint ignore:start */
/**
 * <Redirect> TwiML Verb
 *
 * @function redirect
 * @memberof MessagingResponse#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} [attributes.method] - Redirect URL method
 * @param {url} url - Redirect URL
 *
 * @returns Redirect
 */
/* jshint ignore:end */
MessagingResponse.prototype.redirect = function redirect(attributes, url) {
  return new Redirect(this.response.ele('Redirect', attributes, url));
};


/* jshint ignore:start */
/**
 * <Redirect> TwiML Verb
 *
 * @constructor Redirect
 *
 * @param {object} redirect - <Redirect> TwiML Verb
 */
/* jshint ignore:end */
function Redirect(redirect) {
  this.redirect = redirect;
  this._propertyName = 'redirect';
}

Redirect.prototype = Object.create(TwiML.prototype);
Redirect.prototype.constructor = Redirect;


/* jshint ignore:start */
/**
 * <Message> TwiML Verb
 *
 * @constructor Message
 *
 * @param {object} message - <Message> TwiML Verb
 */
/* jshint ignore:end */
function Message(message) {
  this.message = message;
  this._propertyName = 'message';
}

Message.prototype = Object.create(TwiML.prototype);
Message.prototype.constructor = Message;

/* jshint ignore:start */
/**
 * <Body> TwiML Noun
 *
 * @function body
 * @memberof Message#
 *
 * @param {object} attributes - TwiML attributes
 * @param {string} message - Message Body
 *
 * @returns Body
 */
/* jshint ignore:end */
Message.prototype.body = function body(attributes, message) {
  return new Body(this.message.ele('Body', attributes, message));
};

/* jshint ignore:start */
/**
 * <Media> TwiML Noun
 *
 * @function media
 * @memberof Message#
 *
 * @param {object} attributes - TwiML attributes
 * @param {url} url - Media URL
 *
 * @returns Media
 */
/* jshint ignore:end */
Message.prototype.media = function media(attributes, url) {
  return new Media(this.message.ele('Media', attributes, url));
};


/* jshint ignore:start */
/**
 * <Media> TwiML Noun
 *
 * @constructor Media
 *
 * @param {object} media - <Media> TwiML Noun
 */
/* jshint ignore:end */
function Media(media) {
  this.media = media;
  this._propertyName = 'media';
}

Media.prototype = Object.create(TwiML.prototype);
Media.prototype.constructor = Media;


/* jshint ignore:start */
/**
 * <Body> TwiML Noun
 *
 * @constructor Body
 *
 * @param {object} body - <Body> TwiML Noun
 */
/* jshint ignore:end */
function Body(body) {
  this.body = body;
  this._propertyName = 'body';
}

Body.prototype = Object.create(TwiML.prototype);
Body.prototype.constructor = Body;

module.exports = MessagingResponse;
