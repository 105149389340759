'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var AddressConfigurationList = require(
    './v1/addressConfiguration').AddressConfigurationList;
var ConfigurationList = require('./v1/configuration').ConfigurationList;
var ConversationList = require('./v1/conversation').ConversationList;
var CredentialList = require('./v1/credential').CredentialList;
var ParticipantConversationList = require(
    './v1/participantConversation').ParticipantConversationList;
var RoleList = require('./v1/role').RoleList;
var ServiceList = require('./v1/service').ServiceList;
var UserList = require('./v1/user').UserList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V1 version of Conversations
 *
 * @constructor Twilio.Conversations.V1
 *
 * @property {Twilio.Conversations.V1.ConfigurationList} configuration -
 *          configuration resource
 * @property {Twilio.Conversations.V1.AddressConfigurationList} addressConfigurations -
 *          addressConfigurations resource
 * @property {Twilio.Conversations.V1.ConversationList} conversations -
 *          conversations resource
 * @property {Twilio.Conversations.V1.CredentialList} credentials -
 *          credentials resource
 * @property {Twilio.Conversations.V1.ParticipantConversationList} participantConversations -
 *          participantConversations resource
 * @property {Twilio.Conversations.V1.RoleList} roles - roles resource
 * @property {Twilio.Conversations.V1.ServiceList} services - services resource
 * @property {Twilio.Conversations.V1.UserList} users - users resource
 *
 * @param {Twilio.Conversations} domain - The twilio domain
 */
/* jshint ignore:end */
function V1(domain) {
  Version.prototype.constructor.call(this, domain, 'v1');

  // Resources
  this._configuration = undefined;
  this._addressConfigurations = undefined;
  this._conversations = undefined;
  this._credentials = undefined;
  this._participantConversations = undefined;
  this._roles = undefined;
  this._services = undefined;
  this._users = undefined;
}

_.extend(V1.prototype, Version.prototype);
V1.prototype.constructor = V1;

Object.defineProperty(V1.prototype,
  'configuration', {
    get: function() {
      this._configuration = this._configuration || new ConfigurationList(this);
      return this._configuration;
    }
});

Object.defineProperty(V1.prototype,
  'addressConfigurations', {
    get: function() {
      this._addressConfigurations = this._addressConfigurations || new AddressConfigurationList(this);
      return this._addressConfigurations;
    }
});

Object.defineProperty(V1.prototype,
  'conversations', {
    get: function() {
      this._conversations = this._conversations || new ConversationList(this);
      return this._conversations;
    }
});

Object.defineProperty(V1.prototype,
  'credentials', {
    get: function() {
      this._credentials = this._credentials || new CredentialList(this);
      return this._credentials;
    }
});

Object.defineProperty(V1.prototype,
  'participantConversations', {
    get: function() {
      this._participantConversations = this._participantConversations || new ParticipantConversationList(this);
      return this._participantConversations;
    }
});

Object.defineProperty(V1.prototype,
  'roles', {
    get: function() {
      this._roles = this._roles || new RoleList(this);
      return this._roles;
    }
});

Object.defineProperty(V1.prototype,
  'services', {
    get: function() {
      this._services = this._services || new ServiceList(this);
      return this._services;
    }
});

Object.defineProperty(V1.prototype,
  'users', {
    get: function() {
      this._users = this._users || new UserList(this);
      return this._users;
    }
});

module.exports = V1;
