import React, {Component} from 'react';
import {TouchableOpacity} from "react-native";
import {View, Text} from "react-native";
// import {BACK} from "../utils/languages";
import {style} from "../../assets/style";
import Icon from './Warning'

const DefaultLanguage = (props) => {
    return(
        <TouchableOpacity onPress={props.changeLanguage} activeOpacity={1}>
            <View style={[{backgroundColor:props.backgroundColor,border:props.border_color},style.languageButton]}>
                <i style={{color: props.color, fontSize: 25}} class="fas fa-globe"></i>
            </View>
        </TouchableOpacity>
    )
};
export default DefaultLanguage;