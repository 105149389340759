'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./wireless/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize wireless domain
 *
 * @constructor Twilio.Wireless
 *
 * @property {Twilio.Wireless.V1} v1 - v1 version
 * @property {Twilio.Wireless.V1.UsageRecordList} usageRecords -
 *          usageRecords resource
 * @property {Twilio.Wireless.V1.CommandList} commands - commands resource
 * @property {Twilio.Wireless.V1.RatePlanList} ratePlans - ratePlans resource
 * @property {Twilio.Wireless.V1.SimList} sims - sims resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Wireless(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://wireless.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(Wireless.prototype, Domain.prototype);
Wireless.prototype.constructor = Wireless;

Object.defineProperty(Wireless.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(Wireless.prototype,
  'usageRecords', {
    get: function() {
      return this.v1.usageRecords;
    }
});

Object.defineProperty(Wireless.prototype,
  'commands', {
    get: function() {
      return this.v1.commands;
    }
});

Object.defineProperty(Wireless.prototype,
  'ratePlans', {
    get: function() {
      return this.v1.ratePlans;
    }
});

Object.defineProperty(Wireless.prototype,
  'sims', {
    get: function() {
      return this.v1.sims;
    }
});

module.exports = Wireless;
