import React from 'react';
import {View, Text} from 'react-native'
import { Button,  } from "native-base";
import i18n from "../i18n";
import { FontLetterSpacing } from '../handlers/utils';
import StyledText from "../components/styledText"





const OrderButton = (props) => {
    return(
        <Button
        disabled={props.dataLoader}
        onPress={() => props.placeOrder()}
        style={{
          alignItems: "center",
          justifyContent: "center",
          // marginTop: 20,
          // marginBottom: 10,
          backgroundColor: props.design.top_buttons_background_color,
          height:50,
          width:'96%',
          borderRadius:8
        }}
      >
        {
          props.dataLoader ?
          <i style={{color: props.home_button_text_color, fontSize: 25}} class="fas fa-spinner fa-spin"></i>
          :
          <>
        <StyledText
        font_color={props.design.top_buttons_text_color}
        font_weight={"bold"}
        font_style={props.design.top_button_text_font_style}
        text_transform={props.design.top_button_text_font_transform}
        font_family={props.design.top_button_text_font_family}
        font_size={props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

          style={{}}
        >
          {props.currency + " "}
          {props.total}
        </StyledText>
        <StyledText
                font_color={props.design.top_buttons_text_color}
                font_weight={"bold"}
                font_style={props.design.top_button_text_font_style}
                text_transform={props.design.top_button_text_font_transform}
                font_family={props.design.top_button_text_font_family}
                font_size={props.design.top_button_text_font_size}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
        
          style={{
          
            marginLeft:30,
          }}
        >
          {i18n.t("labels.place_holder")}{" "}
        </StyledText>
        <i style={{
            color: props.design.top_buttons_text_color,
            fontWeight: "bold",
            marginLeft:5
          }} class="fas fa-chevron-right"></i>
          </>
        }
      </Button>
    )
}

export default OrderButton