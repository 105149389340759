import React from 'react'
import {View } from 'react-native'



export const Hr = () => <View style={{borderBottomColor:'#eff0f1', borderBottomWidth:1,marginVertical:5}}/>

 


export const Br = () => <View style={{marginVertical:2}}/>