import React from 'react'
import {View, Text} from 'react-native';
import StyledText from "../components/styledText"

import { FontLetterSpacing } from '../handlers/utils';



const ModifierText = (props) => {
    return(
        <View
                style={{
                height: "100%",
                justifyContent: "center",
                marginLeft: 4,
                padding: 5,
                alignItems: `flex-${props.direction}`,
                }}
            >
                <StyledText
                font_color={props.design.item_page_description_color}
                font_weight={props.design.item_page_description_font_weight}
                font_style={props.design.item_page_description_font_style}
                text_transform={props.design.item_page_description_font_transform}
                font_family={props.design.item_page_description_font_family}
                letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                font_size={props.design.item_page_description_font_size}

                style={{}}
                >
                {props.children}
                </StyledText>
        </View>
    )
}

export default ModifierText