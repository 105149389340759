'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var NotificationList = require('./challenge/notification').NotificationList;
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var ChallengeList;
var ChallengePage;
var ChallengeInstance;
var ChallengeContext;

/* jshint ignore:start */
/**
 * Initialize the ChallengeList
 *
 * @constructor Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList
 *
 * @param {Twilio.Verify.V2} version - Version of the resource
 * @param {string} serviceSid - Service Sid.
 * @param {string} identity - Unique external identifier of the Entity
 */
/* jshint ignore:end */
ChallengeList = function ChallengeList(version, serviceSid, identity) {
  /* jshint ignore:start */
  /**
   * @function challenges
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext}
   */
  /* jshint ignore:end */
  function ChallengeListInstance(sid) {
    return ChallengeListInstance.get(sid);
  }

  ChallengeListInstance._version = version;
  // Path Solution
  ChallengeListInstance._solution = {serviceSid: serviceSid, identity: identity};
  ChallengeListInstance._uri = `/Services/${serviceSid}/Entities/${identity}/Challenges`;
  /* jshint ignore:start */
  /**
   * create a ChallengeInstance
   *
   * @function create
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.factorSid - Factor Sid.
   * @param {Date} [opts.expirationDate] - The date-time when this Challenge expires
   * @param {string} [opts.details.message] -
   *          Shown to the user when the push notification arrives
   * @param {object|list} [opts.details.fields] -
   *          A list of objects that describe the Fields included in the Challenge
   * @param {object} [opts.hiddenDetails] -
   *          Hidden details provided to contextualize the Challenge
   * @param {string} [opts.authPayload] - Optional payload to verify the Challenge
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ChallengeInstance
   */
  /* jshint ignore:end */
  ChallengeListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['factorSid'])) {
      throw new Error('Required parameter "opts[\'factorSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'FactorSid': _.get(opts, 'factorSid'),
      'ExpirationDate': serialize.iso8601DateTime(_.get(opts, 'expirationDate')),
      'Details.Message': _.get(opts, 'details.message'),
      'Details.Fields': serialize.map(_.get(opts, 'details.fields'), function(e) { return serialize.object(e); }),
      'HiddenDetails': serialize.object(_.get(opts, 'hiddenDetails')),
      'AuthPayload': _.get(opts, 'authPayload')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChallengeInstance(
        this._version,
        payload,
        this._solution.serviceSid,
        this._solution.identity,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams ChallengeInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.factorSid] - Factor Sid.
   * @param {challenge.challenge_statuses} [opts.status] -
   *          The Status of theChallenges to fetch
   * @param {challenge.list_orders} [opts.order] -
   *          The sort order of the Challenges list
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ChallengeListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ChallengeInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.factorSid] - Factor Sid.
   * @param {challenge.challenge_statuses} [opts.status] -
   *          The Status of theChallenges to fetch
   * @param {challenge.list_orders} [opts.order] -
   *          The sort order of the Challenges list
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChallengeListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ChallengeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.factorSid] - Factor Sid.
   * @param {challenge.challenge_statuses} [opts.status] -
   *          The Status of theChallenges to fetch
   * @param {challenge.list_orders} [opts.order] -
   *          The sort order of the Challenges list
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChallengeListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'FactorSid': _.get(opts, 'factorSid'),
      'Status': _.get(opts, 'status'),
      'Order': _.get(opts, 'order'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChallengePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ChallengeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChallengeListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChallengePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a challenge
   *
   * @function get
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @param {string} sid - A string that uniquely identifies this Challenge.
   *
   * @returns {Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext}
   */
  /* jshint ignore:end */
  ChallengeListInstance.get = function get(sid) {
    return new ChallengeContext(this._version, this._solution.serviceSid, this._solution.identity, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ChallengeListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ChallengeListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return ChallengeListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ChallengePage
 *
 * @constructor Twilio.Verify.V2.ServiceContext.EntityContext.ChallengePage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ChallengeSolution} solution - Path solution
 *
 * @returns ChallengePage
 */
/* jshint ignore:end */
ChallengePage = function ChallengePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ChallengePage.prototype, Page.prototype);
ChallengePage.prototype.constructor = ChallengePage;

/* jshint ignore:start */
/**
 * Build an instance of ChallengeInstance
 *
 * @function getInstance
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengePage#
 *
 * @param {ChallengePayload} payload - Payload response from the API
 *
 * @returns ChallengeInstance
 */
/* jshint ignore:end */
ChallengePage.prototype.getInstance = function getInstance(payload) {
  return new ChallengeInstance(
    this._version,
    payload,
    this._solution.serviceSid,
    this._solution.identity
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChallengePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ChallengePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ChallengeContext
 *
 * @constructor Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeInstance
 *
 * @property {string} sid - A string that uniquely identifies this Challenge.
 * @property {string} accountSid - Account Sid.
 * @property {string} serviceSid - Service Sid.
 * @property {string} entitySid - Entity Sid.
 * @property {string} identity - Unique external identifier of the Entity
 * @property {string} factorSid - Factor Sid.
 * @property {Date} dateCreated - The date this Challenge was created
 * @property {Date} dateUpdated - The date this Challenge was updated
 * @property {Date} dateResponded - The date this Challenge was responded
 * @property {Date} expirationDate - The date-time when this Challenge expires
 * @property {challenge.challenge_statuses} status - The Status of this Challenge
 * @property {challenge.challenge_reasons} respondedReason -
 *          The Reason of this Challenge `status`
 * @property {object} details - Details about the Challenge.
 * @property {object} hiddenDetails - Hidden details about the Challenge
 * @property {object} metadata - Metadata of the challenge.
 * @property {challenge.factor_types} factorType -
 *          The Factor Type of this Challenge
 * @property {string} url - The URL of this resource.
 * @property {string} links - Nested resource URLs.
 *
 * @param {V2} version - Version of the resource
 * @param {ChallengePayload} payload - The instance payload
 * @param {sid} serviceSid - Service Sid.
 * @param {string} identity - Unique external identifier of the Entity
 * @param {sid} sid - A string that uniquely identifies this Challenge.
 */
/* jshint ignore:end */
ChallengeInstance = function ChallengeInstance(version, payload, serviceSid,
                                                identity, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.serviceSid = payload.service_sid; // jshint ignore:line
  this.entitySid = payload.entity_sid; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line
  this.factorSid = payload.factor_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.dateResponded = deserialize.iso8601DateTime(payload.date_responded); // jshint ignore:line
  this.expirationDate = deserialize.iso8601DateTime(payload.expiration_date); // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.respondedReason = payload.responded_reason; // jshint ignore:line
  this.details = payload.details; // jshint ignore:line
  this.hiddenDetails = payload.hidden_details; // jshint ignore:line
  this.metadata = payload.metadata; // jshint ignore:line
  this.factorType = payload.factor_type; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {serviceSid: serviceSid, identity: identity, sid: sid || this.sid, };
};

Object.defineProperty(ChallengeInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ChallengeContext(
          this._version,
          this._solution.serviceSid,
          this._solution.identity,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ChallengeInstance
 *
 * @function fetch
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChallengeInstance
 */
/* jshint ignore:end */
ChallengeInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a ChallengeInstance
 *
 * @function update
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.authPayload] - Optional payload to verify the Challenge
 * @param {object} [opts.metadata] - Metadata of the challenge.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChallengeInstance
 */
/* jshint ignore:end */
ChallengeInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the notifications
 *
 * @function notifications
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeInstance#
 *
 * @returns {Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext.NotificationList}
 */
/* jshint ignore:end */
ChallengeInstance.prototype.notifications = function notifications() {
  return this._proxy.notifications;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChallengeInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ChallengeInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ChallengeContext
 *
 * @constructor Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext
 *
 * @property {Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext.NotificationList} notifications -
 *          notifications resource
 *
 * @param {V2} version - Version of the resource
 * @param {sid} serviceSid - Service Sid.
 * @param {string} identity - Unique external identifier of the Entity
 * @param {sid} sid - A string that uniquely identifies this Challenge.
 */
/* jshint ignore:end */
ChallengeContext = function ChallengeContext(version, serviceSid, identity, sid)
                                              {
  this._version = version;

  // Path Solution
  this._solution = {serviceSid: serviceSid, identity: identity, sid: sid, };
  this._uri = `/Services/${serviceSid}/Entities/${identity}/Challenges/${sid}`;

  // Dependents
  this._notifications = undefined;
};

/* jshint ignore:start */
/**
 * fetch a ChallengeInstance
 *
 * @function fetch
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChallengeInstance
 */
/* jshint ignore:end */
ChallengeContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ChallengeInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.identity,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ChallengeInstance
 *
 * @function update
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.authPayload] - Optional payload to verify the Challenge
 * @param {object} [opts.metadata] - Metadata of the challenge.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChallengeInstance
 */
/* jshint ignore:end */
ChallengeContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'AuthPayload': _.get(opts, 'authPayload'),
    'Metadata': serialize.object(_.get(opts, 'metadata'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new ChallengeInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.identity,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ChallengeContext.prototype,
  'notifications', {
    get: function() {
      if (!this._notifications) {
        this._notifications = new NotificationList(
          this._version,
          this._solution.serviceSid,
          this._solution.identity,
          this._solution.sid
        );
      }
      return this._notifications;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.ServiceContext.EntityContext.ChallengeContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChallengeContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ChallengeContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ChallengeList: ChallengeList,
  ChallengePage: ChallengePage,
  ChallengeInstance: ChallengeInstance,
  ChallengeContext: ChallengeContext
};
