"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * Async wait for a variable till it gets defined in a parent
 *
 * • Usage Example: Waiting for async window.FB var to be initialized
 * - eg: `waitForVar('FB').then(FB => FB.test())`
 * - eg: `waitForVar('FB', { pollFrequency: 500, retries: 2, parent: window || global }).then(FB => FB.test())`
 * - eg: `waitForVar('FB', { retries: ({ retries } => retries * 500) }).then(FB => FB.test())`
 */
var waitForVar = function waitForVar(name) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    pollFrequency: 1000,
    retries: 100,
    parent: window
  },
      _ref$pollFrequency = _ref.pollFrequency,
      pollFrequency = _ref$pollFrequency === void 0 ? 1000 : _ref$pollFrequency,
      _ref$retries = _ref.retries,
      inRetries = _ref$retries === void 0 ? 100 : _ref$retries,
      _ref$parent = _ref.parent,
      parent = _ref$parent === void 0 ? window : _ref$parent;

  // eslint-disable-next-line no-prototype-builtins
  if (parent && parent.hasOwnProperty(name)) {
    return Promise.resolve(parent[name]);
  }

  if (!inRetries) {
    return Promise.resolve(undefined);
  }

  var retries = inRetries - 1;
  return new Promise(function (resolve) {
    return setTimeout(resolve, typeof pollFrequency === 'function' ? pollFrequency({
      retries: retries
    }) : pollFrequency);
  }).then(function () {
    return waitForVar(name, {
      pollFrequency: pollFrequency,
      parent: parent,
      retries: retries
    });
  });
};

var _default = waitForVar;
exports.default = _default;