'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var CompositionSettingsList;
var CompositionSettingsPage;
var CompositionSettingsInstance;
var CompositionSettingsContext;

/* jshint ignore:start */
/**
 * Initialize the CompositionSettingsList
 *
 * @constructor Twilio.Video.V1.CompositionSettingsList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 */
/* jshint ignore:end */
CompositionSettingsList = function CompositionSettingsList(version) {
  /* jshint ignore:start */
  /**
   * @function compositionSettings
   * @memberof Twilio.Video.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.CompositionSettingsContext}
   */
  /* jshint ignore:end */
  function CompositionSettingsListInstance(sid) {
    return CompositionSettingsListInstance.get(sid);
  }

  CompositionSettingsListInstance._version = version;
  // Path Solution
  CompositionSettingsListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a composition_settings
   *
   * @function get
   * @memberof Twilio.Video.V1.CompositionSettingsList#
   *
   * @returns {Twilio.Video.V1.CompositionSettingsContext}
   */
  /* jshint ignore:end */
  CompositionSettingsListInstance.get = function get() {
    return new CompositionSettingsContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.CompositionSettingsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  CompositionSettingsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  CompositionSettingsListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return CompositionSettingsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the CompositionSettingsPage
 *
 * @constructor Twilio.Video.V1.CompositionSettingsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {CompositionSettingsSolution} solution - Path solution
 *
 * @returns CompositionSettingsPage
 */
/* jshint ignore:end */
CompositionSettingsPage = function CompositionSettingsPage(version, response,
                                                            solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(CompositionSettingsPage.prototype, Page.prototype);
CompositionSettingsPage.prototype.constructor = CompositionSettingsPage;

/* jshint ignore:start */
/**
 * Build an instance of CompositionSettingsInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.CompositionSettingsPage#
 *
 * @param {CompositionSettingsPayload} payload - Payload response from the API
 *
 * @returns CompositionSettingsInstance
 */
/* jshint ignore:end */
CompositionSettingsPage.prototype.getInstance = function getInstance(payload) {
  return new CompositionSettingsInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionSettingsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionSettingsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CompositionSettingsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CompositionSettingsContext
 *
 * @constructor Twilio.Video.V1.CompositionSettingsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} awsCredentialsSid - The SID of the stored Credential resource
 * @property {string} awsS3Url -
 *          The URL of the AWS S3 bucket where the compositions are stored
 * @property {boolean} awsStorageEnabled -
 *          Whether all compositions are written to the aws_s3_url
 * @property {string} encryptionKeySid -
 *          The SID of the Public Key resource used for encryption
 * @property {boolean} encryptionEnabled -
 *          Whether all compositions are stored in an encrypted form
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V1} version - Version of the resource
 * @param {CompositionSettingsPayload} payload - The instance payload
 */
/* jshint ignore:end */
CompositionSettingsInstance = function CompositionSettingsInstance(version,
    payload) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.awsCredentialsSid = payload.aws_credentials_sid; // jshint ignore:line
  this.awsS3Url = payload.aws_s3_url; // jshint ignore:line
  this.awsStorageEnabled = payload.aws_storage_enabled; // jshint ignore:line
  this.encryptionKeySid = payload.encryption_key_sid; // jshint ignore:line
  this.encryptionEnabled = payload.encryption_enabled; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(CompositionSettingsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new CompositionSettingsContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a CompositionSettingsInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.CompositionSettingsInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionSettingsInstance
 */
/* jshint ignore:end */
CompositionSettingsInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * create a CompositionSettingsInstance
 *
 * @function create
 * @memberof Twilio.Video.V1.CompositionSettingsInstance#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.friendlyName -
 *          A descriptive string that you create to describe the resource
 * @param {string} [opts.awsCredentialsSid] -
 *          The SID of the stored Credential resource
 * @param {string} [opts.encryptionKeySid] -
 *          The SID of the Public Key resource to use for encryption
 * @param {string} [opts.awsS3Url] -
 *          The URL of the AWS S3 bucket where the compositions should be stored
 * @param {boolean} [opts.awsStorageEnabled] -
 *          Whether all compositions should be written to the aws_s3_url
 * @param {boolean} [opts.encryptionEnabled] -
 *          Whether all compositions should be stored in an encrypted form
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionSettingsInstance
 */
/* jshint ignore:end */
CompositionSettingsInstance.prototype.create = function create(opts, callback) {
  return this._proxy.create(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionSettingsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionSettingsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CompositionSettingsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CompositionSettingsContext
 *
 * @constructor Twilio.Video.V1.CompositionSettingsContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
CompositionSettingsContext = function CompositionSettingsContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/CompositionSettings/Default`;
};

/* jshint ignore:start */
/**
 * fetch a CompositionSettingsInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.CompositionSettingsContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionSettingsInstance
 */
/* jshint ignore:end */
CompositionSettingsContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new CompositionSettingsInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * create a CompositionSettingsInstance
 *
 * @function create
 * @memberof Twilio.Video.V1.CompositionSettingsContext#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.friendlyName -
 *          A descriptive string that you create to describe the resource
 * @param {string} [opts.awsCredentialsSid] -
 *          The SID of the stored Credential resource
 * @param {string} [opts.encryptionKeySid] -
 *          The SID of the Public Key resource to use for encryption
 * @param {string} [opts.awsS3Url] -
 *          The URL of the AWS S3 bucket where the compositions should be stored
 * @param {boolean} [opts.awsStorageEnabled] -
 *          Whether all compositions should be written to the aws_s3_url
 * @param {boolean} [opts.encryptionEnabled] -
 *          Whether all compositions should be stored in an encrypted form
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionSettingsInstance
 */
/* jshint ignore:end */
CompositionSettingsContext.prototype.create = function create(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['friendlyName'])) {
    throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'AwsCredentialsSid': _.get(opts, 'awsCredentialsSid'),
    'EncryptionKeySid': _.get(opts, 'encryptionKeySid'),
    'AwsS3Url': _.get(opts, 'awsS3Url'),
    'AwsStorageEnabled': serialize.bool(_.get(opts, 'awsStorageEnabled')),
    'EncryptionEnabled': serialize.bool(_.get(opts, 'encryptionEnabled'))
  });

  var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new CompositionSettingsInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionSettingsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionSettingsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

CompositionSettingsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  CompositionSettingsList: CompositionSettingsList,
  CompositionSettingsPage: CompositionSettingsPage,
  CompositionSettingsInstance: CompositionSettingsInstance,
  CompositionSettingsContext: CompositionSettingsContext
};
