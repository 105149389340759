'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./events/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize events domain
 *
 * @constructor Twilio.Events
 *
 * @property {Twilio.Events.V1} v1 - v1 version
 * @property {Twilio.Events.V1.EventTypeList} eventTypes - eventTypes resource
 * @property {Twilio.Events.V1.SchemaList} schemas - schemas resource
 * @property {Twilio.Events.V1.SinkList} sinks - sinks resource
 * @property {Twilio.Events.V1.SubscriptionList} subscriptions -
 *          subscriptions resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Events(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://events.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(Events.prototype, Domain.prototype);
Events.prototype.constructor = Events;

Object.defineProperty(Events.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(Events.prototype,
  'eventTypes', {
    get: function() {
      return this.v1.eventTypes;
    }
});

Object.defineProperty(Events.prototype,
  'schemas', {
    get: function() {
      return this.v1.schemas;
    }
});

Object.defineProperty(Events.prototype,
  'sinks', {
    get: function() {
      return this.v1.sinks;
    }
});

Object.defineProperty(Events.prototype,
  'subscriptions', {
    get: function() {
      return this.v1.subscriptions;
    }
});

module.exports = Events;
