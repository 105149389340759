'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ChannelList;
var ChannelPage;
var ChannelInstance;
var ChannelContext;

/* jshint ignore:start */
/**
 * Initialize the ChannelList
 *
 * @constructor Twilio.FlexApi.V1.ChannelList
 *
 * @param {Twilio.FlexApi.V1} version - Version of the resource
 */
/* jshint ignore:end */
ChannelList = function ChannelList(version) {
  /* jshint ignore:start */
  /**
   * @function channel
   * @memberof Twilio.FlexApi.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.FlexApi.V1.ChannelContext}
   */
  /* jshint ignore:end */
  function ChannelListInstance(sid) {
    return ChannelListInstance.get(sid);
  }

  ChannelListInstance._version = version;
  // Path Solution
  ChannelListInstance._solution = {};
  ChannelListInstance._uri = `/Channels`;
  /* jshint ignore:start */
  /**
   * Streams ChannelInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ChannelListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ChannelInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChannelListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChannelListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ChannelListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a ChannelInstance
   *
   * @function create
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.flexFlowSid - The SID of the Flex Flow
   * @param {string} opts.identity -
   *          The identity value that identifies the new resource's chat User
   * @param {string} opts.chatUserFriendlyName - The chat participant's friendly name
   * @param {string} opts.chatFriendlyName - The chat channel's friendly name
   * @param {string} [opts.target] - The Target Contact Identity
   * @param {string} [opts.chatUniqueName] - The chat channel's unique name
   * @param {string} [opts.preEngagementData] - The pre-engagement data
   * @param {string} [opts.taskSid] - The SID of the TaskRouter Task
   * @param {string} [opts.taskAttributes] -
   *          The Task attributes to be added for the TaskRouter Task
   * @param {boolean} [opts.longLived] - Whether to create the channel as long-lived
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ChannelInstance
   */
  /* jshint ignore:end */
  ChannelListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['flexFlowSid'])) {
      throw new Error('Required parameter "opts[\'flexFlowSid\']" missing.');
    }
    if (_.isUndefined(opts['identity'])) {
      throw new Error('Required parameter "opts[\'identity\']" missing.');
    }
    if (_.isUndefined(opts['chatUserFriendlyName'])) {
      throw new Error('Required parameter "opts[\'chatUserFriendlyName\']" missing.');
    }
    if (_.isUndefined(opts['chatFriendlyName'])) {
      throw new Error('Required parameter "opts[\'chatFriendlyName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'FlexFlowSid': _.get(opts, 'flexFlowSid'),
      'Identity': _.get(opts, 'identity'),
      'ChatUserFriendlyName': _.get(opts, 'chatUserFriendlyName'),
      'ChatFriendlyName': _.get(opts, 'chatFriendlyName'),
      'Target': _.get(opts, 'target'),
      'ChatUniqueName': _.get(opts, 'chatUniqueName'),
      'PreEngagementData': _.get(opts, 'preEngagementData'),
      'TaskSid': _.get(opts, 'taskSid'),
      'TaskAttributes': _.get(opts, 'taskAttributes'),
      'LongLived': serialize.bool(_.get(opts, 'longLived'))
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ChannelInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a channel
   *
   * @function get
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @param {string} sid -
   *          The SID that identifies the Flex chat channel resource to fetch
   *
   * @returns {Twilio.FlexApi.V1.ChannelContext}
   */
  /* jshint ignore:end */
  ChannelListInstance.get = function get(sid) {
    return new ChannelContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.FlexApi.V1.ChannelList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ChannelListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ChannelListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return ChannelListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ChannelPage
 *
 * @constructor Twilio.FlexApi.V1.ChannelPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ChannelSolution} solution - Path solution
 *
 * @returns ChannelPage
 */
/* jshint ignore:end */
ChannelPage = function ChannelPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ChannelPage.prototype, Page.prototype);
ChannelPage.prototype.constructor = ChannelPage;

/* jshint ignore:start */
/**
 * Build an instance of ChannelInstance
 *
 * @function getInstance
 * @memberof Twilio.FlexApi.V1.ChannelPage#
 *
 * @param {ChannelPayload} payload - Payload response from the API
 *
 * @returns ChannelInstance
 */
/* jshint ignore:end */
ChannelPage.prototype.getInstance = function getInstance(payload) {
  return new ChannelInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ChannelPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChannelPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ChannelPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ChannelContext
 *
 * @constructor Twilio.FlexApi.V1.ChannelInstance
 *
 * @property {string} accountSid -
 *          The SID of the Account that created the resource and owns this Workflow
 * @property {string} flexFlowSid - The SID of the Flex Flow
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} userSid - The SID of the chat user
 * @property {string} taskSid - The SID of the TaskRouter Task
 * @property {string} url - The absolute URL of the Flex chat channel resource
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the Flex chat channel was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the Flex chat channel was last updated
 *
 * @param {V1} version - Version of the resource
 * @param {ChannelPayload} payload - The instance payload
 * @param {sid} sid -
 *          The SID that identifies the Flex chat channel resource to fetch
 */
/* jshint ignore:end */
ChannelInstance = function ChannelInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.flexFlowSid = payload.flex_flow_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.userSid = payload.user_sid; // jshint ignore:line
  this.taskSid = payload.task_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(ChannelInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ChannelContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ChannelInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.ChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChannelInstance
 */
/* jshint ignore:end */
ChannelInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a ChannelInstance
 *
 * @function remove
 * @memberof Twilio.FlexApi.V1.ChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChannelInstance
 */
/* jshint ignore:end */
ChannelInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ChannelInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChannelInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ChannelInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ChannelContext
 *
 * @constructor Twilio.FlexApi.V1.ChannelContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid -
 *          The SID that identifies the Flex chat channel resource to fetch
 */
/* jshint ignore:end */
ChannelContext = function ChannelContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Channels/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a ChannelInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.ChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChannelInstance
 */
/* jshint ignore:end */
ChannelContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ChannelInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a ChannelInstance
 *
 * @function remove
 * @memberof Twilio.FlexApi.V1.ChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ChannelInstance
 */
/* jshint ignore:end */
ChannelContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.ChannelContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ChannelContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ChannelContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ChannelList: ChannelList,
  ChannelPage: ChannelPage,
  ChannelInstance: ChannelInstance,
  ChannelContext: ChannelContext
};
