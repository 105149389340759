'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var SinkTestList;
var SinkTestPage;
var SinkTestInstance;

/* jshint ignore:start */
/**
 * Initialize the SinkTestList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkTestList
 *
 * @param {Twilio.Events.V1} version - Version of the resource
 * @param {string} sid - A string that uniquely identifies this Sink.
 */
/* jshint ignore:end */
SinkTestList = function SinkTestList(version, sid) {
  /* jshint ignore:start */
  /**
   * @function sinkTest
   * @memberof Twilio.Events.V1.SinkContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Events.V1.SinkContext.SinkTestContext}
   */
  /* jshint ignore:end */
  function SinkTestListInstance(sid) {
    return SinkTestListInstance.get(sid);
  }

  SinkTestListInstance._version = version;
  // Path Solution
  SinkTestListInstance._solution = {sid: sid};
  SinkTestListInstance._uri = `/Sinks/${sid}/Test`;
  /* jshint ignore:start */
  /**
   * create a SinkTestInstance
   *
   * @function create
   * @memberof Twilio.Events.V1.SinkContext.SinkTestList#
   *
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SinkTestInstance
   */
  /* jshint ignore:end */
  SinkTestListInstance.create = function create(callback) {
    var deferred = Q.defer();
    var promise = this._version.create({uri: this._uri, method: 'POST'});

    promise = promise.then(function(payload) {
      deferred.resolve(new SinkTestInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Events.V1.SinkContext.SinkTestList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SinkTestListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SinkTestListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SinkTestListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SinkTestPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkTestPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SinkTestSolution} solution - Path solution
 *
 * @returns SinkTestPage
 */
/* jshint ignore:end */
SinkTestPage = function SinkTestPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SinkTestPage.prototype, Page.prototype);
SinkTestPage.prototype.constructor = SinkTestPage;

/* jshint ignore:start */
/**
 * Build an instance of SinkTestInstance
 *
 * @function getInstance
 * @memberof Twilio.Events.V1.SinkContext.SinkTestPage#
 *
 * @param {SinkTestPayload} payload - Payload response from the API
 *
 * @returns SinkTestInstance
 */
/* jshint ignore:end */
SinkTestPage.prototype.getInstance = function getInstance(payload) {
  return new SinkTestInstance(this._version, payload, this._solution.sid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.SinkContext.SinkTestPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SinkTestPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SinkTestPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SinkTestContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.SinkContext.SinkTestInstance
 *
 * @property {string} result -
 *          Feedback indicating whether the test event was generated.
 *
 * @param {V1} version - Version of the resource
 * @param {SinkTestPayload} payload - The instance payload
 * @param {sid} sid - A string that uniquely identifies this Sink.
 */
/* jshint ignore:end */
SinkTestInstance = function SinkTestInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.result = payload.result; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.SinkContext.SinkTestInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SinkTestInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SinkTestInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SinkTestList: SinkTestList,
  SinkTestPage: SinkTestPage,
  SinkTestInstance: SinkTestInstance
};
