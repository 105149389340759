'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var PhoneNumberList = require('./service/phoneNumber').PhoneNumberList;
var SessionList = require('./service/session').SessionList;
var ShortCodeList = require('./service/shortCode').ShortCodeList;
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var ServiceList;
var ServicePage;
var ServiceInstance;
var ServiceContext;

/* jshint ignore:start */
/**
 * Initialize the ServiceList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceList
 *
 * @param {Twilio.Proxy.V1} version - Version of the resource
 */
/* jshint ignore:end */
ServiceList = function ServiceList(version) {
  /* jshint ignore:start */
  /**
   * @function services
   * @memberof Twilio.Proxy.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Proxy.V1.ServiceContext}
   */
  /* jshint ignore:end */
  function ServiceListInstance(sid) {
    return ServiceListInstance.get(sid);
  }

  ServiceListInstance._version = version;
  // Path Solution
  ServiceListInstance._solution = {};
  ServiceListInstance._uri = `/Services`;
  /* jshint ignore:start */
  /**
   * Streams ServiceInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ServiceListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ServiceInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ServiceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServicePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ServiceInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ServiceListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServicePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a ServiceInstance
   *
   * @function create
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.uniqueName -
   *          An application-defined string that uniquely identifies the resource
   * @param {number} [opts.defaultTtl] - Default TTL for a Session, in seconds
   * @param {string} [opts.callbackUrl] -
   *          The URL we should call when the interaction status changes
   * @param {service.geo_match_level} [opts.geoMatchLevel] -
   *          Where a proxy number must be located relative to the participant identifier
   * @param {service.number_selection_behavior} [opts.numberSelectionBehavior] -
   *          The preference for Proxy Number selection for the Service instance
   * @param {string} [opts.interceptCallbackUrl] -
   *          The URL we call on each interaction
   * @param {string} [opts.outOfSessionCallbackUrl] -
   *          The URL we call when an inbound call or SMS action occurs on a closed or non-existent Session
   * @param {string} [opts.chatInstanceSid] - The SID of the Chat Service Instance
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ServiceInstance
   */
  /* jshint ignore:end */
  ServiceListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['uniqueName'])) {
      throw new Error('Required parameter "opts[\'uniqueName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'UniqueName': _.get(opts, 'uniqueName'),
      'DefaultTtl': _.get(opts, 'defaultTtl'),
      'CallbackUrl': _.get(opts, 'callbackUrl'),
      'GeoMatchLevel': _.get(opts, 'geoMatchLevel'),
      'NumberSelectionBehavior': _.get(opts, 'numberSelectionBehavior'),
      'InterceptCallbackUrl': _.get(opts, 'interceptCallbackUrl'),
      'OutOfSessionCallbackUrl': _.get(opts, 'outOfSessionCallbackUrl'),
      'ChatInstanceSid': _.get(opts, 'chatInstanceSid')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ServiceInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a service
   *
   * @function get
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Proxy.V1.ServiceContext}
   */
  /* jshint ignore:end */
  ServiceListInstance.get = function get(sid) {
    return new ServiceContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Proxy.V1.ServiceList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ServiceListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ServiceListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return ServiceListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ServicePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServicePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ServiceSolution} solution - Path solution
 *
 * @returns ServicePage
 */
/* jshint ignore:end */
ServicePage = function ServicePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ServicePage.prototype, Page.prototype);
ServicePage.prototype.constructor = ServicePage;

/* jshint ignore:start */
/**
 * Build an instance of ServiceInstance
 *
 * @function getInstance
 * @memberof Twilio.Proxy.V1.ServicePage#
 *
 * @param {ServicePayload} payload - Payload response from the API
 *
 * @returns ServiceInstance
 */
/* jshint ignore:end */
ServicePage.prototype.getInstance = function getInstance(payload) {
  return new ServiceInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServicePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServicePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ServicePage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ServiceContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} uniqueName -
 *          An application-defined string that uniquely identifies the resource
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} chatInstanceSid - The SID of the Chat Service Instance
 * @property {string} callbackUrl -
 *          The URL we call when the interaction status changes
 * @property {number} defaultTtl - Default TTL for a Session, in seconds
 * @property {service.number_selection_behavior} numberSelectionBehavior -
 *          The preference for Proxy Number selection for the Service instance
 * @property {service.geo_match_level} geoMatchLevel -
 *          Where a proxy number must be located relative to the participant identifier
 * @property {string} interceptCallbackUrl - The URL we call on each interaction
 * @property {string} outOfSessionCallbackUrl -
 *          The URL we call when an inbound call or SMS action occurs on a closed or non-existent Session
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} url - The absolute URL of the Service resource
 * @property {string} links - The URLs of resources related to the Service
 *
 * @param {V1} version - Version of the resource
 * @param {ServicePayload} payload - The instance payload
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
ServiceInstance = function ServiceInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.chatInstanceSid = payload.chat_instance_sid; // jshint ignore:line
  this.callbackUrl = payload.callback_url; // jshint ignore:line
  this.defaultTtl = deserialize.integer(payload.default_ttl); // jshint ignore:line
  this.numberSelectionBehavior = payload.number_selection_behavior; // jshint ignore:line
  this.geoMatchLevel = payload.geo_match_level; // jshint ignore:line
  this.interceptCallbackUrl = payload.intercept_callback_url; // jshint ignore:line
  this.outOfSessionCallbackUrl = payload.out_of_session_callback_url; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(ServiceInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ServiceContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ServiceInstance
 *
 * @function fetch
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a ServiceInstance
 *
 * @function remove
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * update a ServiceInstance
 *
 * @function update
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.uniqueName] -
 *          An application-defined string that uniquely identifies the resource
 * @param {number} [opts.defaultTtl] - Default TTL for a Session, in seconds
 * @param {string} [opts.callbackUrl] -
 *          The URL we should call when the interaction status changes
 * @param {service.geo_match_level} [opts.geoMatchLevel] -
 *          Where a proxy number must be located relative to the participant identifier
 * @param {service.number_selection_behavior} [opts.numberSelectionBehavior] -
 *          The preference for Proxy Number selection for the Service instance
 * @param {string} [opts.interceptCallbackUrl] -
 *          The URL we call on each interaction
 * @param {string} [opts.outOfSessionCallbackUrl] -
 *          The URL we call when an inbound call or SMS action occurs on a closed or non-existent Session
 * @param {string} [opts.chatInstanceSid] - The SID of the Chat Service Instance
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the sessions
 *
 * @function sessions
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @returns {Twilio.Proxy.V1.ServiceContext.SessionList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.sessions = function sessions() {
  return this._proxy.sessions;
};

/* jshint ignore:start */
/**
 * Access the phoneNumbers
 *
 * @function phoneNumbers
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @returns {Twilio.Proxy.V1.ServiceContext.PhoneNumberList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.phoneNumbers = function phoneNumbers() {
  return this._proxy.phoneNumbers;
};

/* jshint ignore:start */
/**
 * Access the shortCodes
 *
 * @function shortCodes
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @returns {Twilio.Proxy.V1.ServiceContext.ShortCodeList}
 */
/* jshint ignore:end */
ServiceInstance.prototype.shortCodes = function shortCodes() {
  return this._proxy.shortCodes;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServiceInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServiceInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ServiceInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ServiceContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceContext
 *
 * @property {Twilio.Proxy.V1.ServiceContext.SessionList} sessions -
 *          sessions resource
 * @property {Twilio.Proxy.V1.ServiceContext.PhoneNumberList} phoneNumbers -
 *          phoneNumbers resource
 * @property {Twilio.Proxy.V1.ServiceContext.ShortCodeList} shortCodes -
 *          shortCodes resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
ServiceContext = function ServiceContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Services/${sid}`;

  // Dependents
  this._sessions = undefined;
  this._phoneNumbers = undefined;
  this._shortCodes = undefined;
};

/* jshint ignore:start */
/**
 * fetch a ServiceInstance
 *
 * @function fetch
 * @memberof Twilio.Proxy.V1.ServiceContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ServiceInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a ServiceInstance
 *
 * @function remove
 * @memberof Twilio.Proxy.V1.ServiceContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ServiceInstance
 *
 * @function update
 * @memberof Twilio.Proxy.V1.ServiceContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.uniqueName] -
 *          An application-defined string that uniquely identifies the resource
 * @param {number} [opts.defaultTtl] - Default TTL for a Session, in seconds
 * @param {string} [opts.callbackUrl] -
 *          The URL we should call when the interaction status changes
 * @param {service.geo_match_level} [opts.geoMatchLevel] -
 *          Where a proxy number must be located relative to the participant identifier
 * @param {service.number_selection_behavior} [opts.numberSelectionBehavior] -
 *          The preference for Proxy Number selection for the Service instance
 * @param {string} [opts.interceptCallbackUrl] -
 *          The URL we call on each interaction
 * @param {string} [opts.outOfSessionCallbackUrl] -
 *          The URL we call when an inbound call or SMS action occurs on a closed or non-existent Session
 * @param {string} [opts.chatInstanceSid] - The SID of the Chat Service Instance
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ServiceInstance
 */
/* jshint ignore:end */
ServiceContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'UniqueName': _.get(opts, 'uniqueName'),
    'DefaultTtl': _.get(opts, 'defaultTtl'),
    'CallbackUrl': _.get(opts, 'callbackUrl'),
    'GeoMatchLevel': _.get(opts, 'geoMatchLevel'),
    'NumberSelectionBehavior': _.get(opts, 'numberSelectionBehavior'),
    'InterceptCallbackUrl': _.get(opts, 'interceptCallbackUrl'),
    'OutOfSessionCallbackUrl': _.get(opts, 'outOfSessionCallbackUrl'),
    'ChatInstanceSid': _.get(opts, 'chatInstanceSid')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new ServiceInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ServiceContext.prototype,
  'sessions', {
    get: function() {
      if (!this._sessions) {
        this._sessions = new SessionList(this._version, this._solution.sid);
      }
      return this._sessions;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'phoneNumbers', {
    get: function() {
      if (!this._phoneNumbers) {
        this._phoneNumbers = new PhoneNumberList(this._version, this._solution.sid);
      }
      return this._phoneNumbers;
    }
});

Object.defineProperty(ServiceContext.prototype,
  'shortCodes', {
    get: function() {
      if (!this._shortCodes) {
        this._shortCodes = new ShortCodeList(this._version, this._solution.sid);
      }
      return this._shortCodes;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServiceContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ServiceContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ServiceContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ServiceList: ServiceList,
  ServicePage: ServicePage,
  ServiceInstance: ServiceInstance,
  ServiceContext: ServiceContext
};
