import React, { useState, useEffect } from 'react';

import { 
    Elements,
    PaymentRequestButtonElement,
    useStripe,
 } from '@stripe/react-stripe-js';
 import { Spinner} from 'native-base'


import { performRequest } from './../services/apiHandler';




const DigiWallet = (props) => {
    const stripe = useStripe();

  // const [paymentRequest, setPaymentRequest] = useState("loading");
  const handlePaymentMethodReceived = (event) => {
    const paymentDetails = {
      payment_method: event.paymentMethod.id,
      shipping: {
        name: "",
        phone: "",
        address: {
          line1: "",
          city: "",
          postal_code: "",
          state: "",
          country: "",
        },
        // name: event.shippingAddress.recipient,
        // phone: event.shippingAddress.phone,
        // address: {
        //   line1: event.shippingAddress.addressLine[0],
        //   city: event.shippingAddress.city,
        //   postal_code: event.shippingAddress.postalCode,
        //   state: event.shippingAddress.region,
        //   country: event.shippingAddress.country,
        // },
      },
    };
    let data = {
      "payment_service_id":props.service_id,
      "amount": props.total,
      "currency": props.currency,
      "payment_details": paymentDetails,
      "channel": props.platform == "qr" ? 2 : props.platform  == "delivery" ? 3 : 4,

    }
    performRequest('post', "api/pwa/create-payment-intend/",data, false )
    .then(async response => {
      event.complete('success');
      props.completeOnlinePayment(response.data)
      // const { error, paymentIntent } = await stripe.confirmCardPayment(
      //   response.data.client_secret,
      //   {payment_method: event.paymentMethod.id},
      //   {handleActions: false}
      // );
      // if (error) {
      //   props.throwMessageFn(error, "red");
      //   return;
      // }
      // if (paymentIntent.status === 'succeeded') {
      //   event.complete('success');
      //   props.completeOnlinePayment(paymentIntent)
      // } else {
      //   props.throwMessageFn("Payment Failed. try another payment method", "red");
      //   console.log(
      //     `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
      //   );
      // }


      // event.complete('fail');
    })
    .catch(error => {
      props.throwMessageFn("Payment Failed. try another payment method", "red");
      event.complete('fail');
    })
}
    useEffect(() => {
        if (stripe) {
          if(props.paymentConfigured && (props.paymentConfigured.service == "stripe" || props.paymentConfigured.service == "my menu pay") && stripe.applePay){
            stripe.applePay.stripeAccount = props.paymentConfigured.stripe_user_id;
          }

          const pr = stripe.paymentRequest({
            country: props.country,
            currency: props.currency,
            total: {
              label: props.venue,
              amount: props.total,
            },
            requestPayerName: false,
            requestPayerEmail: false,
            requestShipping: false,
            shippingOptions: [
              // {
              //   id: '-',
              //   label: '-',
              //   detail: '-',
              //   amount: 0,
              // },
            ],
          });
          console.log("######################=========== stripe")

        // Check the availability of the Payment Request API first.
         pr.canMakePayment().then((result) => {
          console.log("######################=========== stripe", result)

            props.setAvailablePaymentMethod(result)
          if (result) {
            console.log(result, "llllllll")
            pr.on('paymentmethod', handlePaymentMethodReceived);
            props.setPaymentRequest(pr);
          }else{
            console.log(result, "pppppppppppppppppp")
            props.setPaymentRequest("");
          }
        });
        }
      }, [stripe,props.total ]);
      if(props.paymentRequest && props.paymentRequest !="loading"){
        return <>
        {props.children}
        </>
      }
      if(props.paymentRequest && props.paymentRequest =="loading"){
        return(
            <Spinner/>
            )
      }
      return <div/>

}
export default DigiWallet