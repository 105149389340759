'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var OauthList;
var OauthPage;
var OauthInstance;
var OauthContext;

/* jshint ignore:start */
/**
 * Initialize the OauthList
 *
 * @constructor Twilio.Oauth.V1.OauthList
 *
 * @param {Twilio.Oauth.V1} version - Version of the resource
 */
/* jshint ignore:end */
OauthList = function OauthList(version) {
  /* jshint ignore:start */
  /**
   * @function oauth
   * @memberof Twilio.Oauth.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Oauth.V1.OauthContext}
   */
  /* jshint ignore:end */
  function OauthListInstance(sid) {
    return OauthListInstance.get(sid);
  }

  OauthListInstance._version = version;
  // Path Solution
  OauthListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a oauth
   *
   * @function get
   * @memberof Twilio.Oauth.V1.OauthList#
   *
   * @returns {Twilio.Oauth.V1.OauthContext}
   */
  /* jshint ignore:end */
  OauthListInstance.get = function get() {
    return new OauthContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Oauth.V1.OauthList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  OauthListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  OauthListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return OauthListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the OauthPage
 *
 * @constructor Twilio.Oauth.V1.OauthPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {OauthSolution} solution - Path solution
 *
 * @returns OauthPage
 */
/* jshint ignore:end */
OauthPage = function OauthPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(OauthPage.prototype, Page.prototype);
OauthPage.prototype.constructor = OauthPage;

/* jshint ignore:start */
/**
 * Build an instance of OauthInstance
 *
 * @function getInstance
 * @memberof Twilio.Oauth.V1.OauthPage#
 *
 * @param {OauthPayload} payload - Payload response from the API
 *
 * @returns OauthInstance
 */
/* jshint ignore:end */
OauthPage.prototype.getInstance = function getInstance(payload) {
  return new OauthInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OauthPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
OauthPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

OauthPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the OauthContext
 *
 * @constructor Twilio.Oauth.V1.OauthInstance
 *
 * @property {object} keys - A collection of certificates
 * @property {string} url - The url
 *
 * @param {V1} version - Version of the resource
 * @param {OauthPayload} payload - The instance payload
 */
/* jshint ignore:end */
OauthInstance = function OauthInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.keys = payload.keys; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(OauthInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new OauthContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a OauthInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.OauthInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed OauthInstance
 */
/* jshint ignore:end */
OauthInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OauthInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
OauthInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

OauthInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the OauthContext
 *
 * @constructor Twilio.Oauth.V1.OauthContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
OauthContext = function OauthContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/certs`;
};

/* jshint ignore:start */
/**
 * fetch a OauthInstance
 *
 * @function fetch
 * @memberof Twilio.Oauth.V1.OauthContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed OauthInstance
 */
/* jshint ignore:end */
OauthContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new OauthInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Oauth.V1.OauthContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
OauthContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

OauthContext.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  OauthList: OauthList,
  OauthPage: OauthPage,
  OauthInstance: OauthInstance,
  OauthContext: OauthContext
};
