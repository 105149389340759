import React from "react";

import { createStackNavigator } from 'react-navigation-stack';

import { createAppContainer } from 'react-navigation';


import MenuHome from './../screens/menuHome'
import HomeScreen from './../screens/mainHome'
import CartScreen from "../screens/cartScreen";
import RecipeDetail from "../screens/recipeDetail";
import PaymentScreen from "../screens/paymentScreen";
import CheckoutScreen from "../screens/checkoutScreen";
// import FallbackScreen from '../screens/fallbackScreen';

import MobileForm from '../screens/MobileForm';
import OtpScreen from "../screens/otpScreen";
import selectDestinationScreen from "../screens/selectDestinationScreen";
import UserRegistrationScreen from "../screens/userRegistration"
import AddressFormScreen from '../screens/addAddress'
import RecentOrdersScreen from '../screens/recentOrdersScreen'
// import IndexScreen from '../screens/indexScreen'
import ProfileScreen from '../screens/profileScreen'
import AccountScreen from '../screens/accountsHome'
import AddressScreen from '../screens/addressScreen'

import CardScreen from '../screens/savedCards'

import VenuesScreen from '../screens/venuesScreen'

const HomeNavigator = createStackNavigator(
    {
        // VenuesScreen:{
        //     screen: VenuesScreen
        // },

        HomeScreen:{
            screen: HomeScreen,
        },
        MenuHome: {
            screen: MenuHome,
        },
        CartScreen:{
            screen: CartScreen
        },
        RecipeDetail:{
            screen: RecipeDetail
        },

        PaymentScreen: {
            screen: PaymentScreen
        },
        CheckoutScreen:{
            screen: CheckoutScreen
        },
        MobileForm: {
            screen: MobileForm
        },
        UserRegistrationScreen:{
            screen: UserRegistrationScreen
        },
        AddressFormScreen: {
            screen: AddressFormScreen
        },
        OtpScreen: {
            screen: OtpScreen,
        },
        RecentOrdersScreen:{
            screen: RecentOrdersScreen
        },
        ProfileScreen:{
            screen: ProfileScreen
        },
        AccountScreen:{
            screen: AccountScreen
        }, 
        AddressScreen:{
            screen: AddressScreen
        }, 
        CardScreen:{
            screen: CardScreen
        }, 
        // FallbackScreen:{
        //     screen: FallbackScreen
        // }
        // navigationOptions,


    },



);

export default createAppContainer(HomeNavigator);
