'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var EventTypeList;
var EventTypePage;
var EventTypeInstance;
var EventTypeContext;

/* jshint ignore:start */
/**
 * Initialize the EventTypeList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.EventTypeList
 *
 * @param {Twilio.Events.V1} version - Version of the resource
 */
/* jshint ignore:end */
EventTypeList = function EventTypeList(version) {
  /* jshint ignore:start */
  /**
   * @function eventTypes
   * @memberof Twilio.Events.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Events.V1.EventTypeContext}
   */
  /* jshint ignore:end */
  function EventTypeListInstance(sid) {
    return EventTypeListInstance.get(sid);
  }

  EventTypeListInstance._version = version;
  // Path Solution
  EventTypeListInstance._solution = {};
  EventTypeListInstance._uri = `/Types`;
  /* jshint ignore:start */
  /**
   * Streams EventTypeInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.schemaId] - A string to filter Event Types by schema.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  EventTypeListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists EventTypeInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.schemaId] - A string to filter Event Types by schema.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EventTypeListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of EventTypeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.schemaId] - A string to filter Event Types by schema.
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EventTypeListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'SchemaId': _.get(opts, 'schemaId'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new EventTypePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of EventTypeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EventTypeListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new EventTypePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a event_type
   *
   * @function get
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @param {string} type - A string that uniquely identifies this Event Type.
   *
   * @returns {Twilio.Events.V1.EventTypeContext}
   */
  /* jshint ignore:end */
  EventTypeListInstance.get = function get(type) {
    return new EventTypeContext(this._version, type);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Events.V1.EventTypeList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  EventTypeListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  EventTypeListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return EventTypeListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the EventTypePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.EventTypePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {EventTypeSolution} solution - Path solution
 *
 * @returns EventTypePage
 */
/* jshint ignore:end */
EventTypePage = function EventTypePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(EventTypePage.prototype, Page.prototype);
EventTypePage.prototype.constructor = EventTypePage;

/* jshint ignore:start */
/**
 * Build an instance of EventTypeInstance
 *
 * @function getInstance
 * @memberof Twilio.Events.V1.EventTypePage#
 *
 * @param {EventTypePayload} payload - Payload response from the API
 *
 * @returns EventTypeInstance
 */
/* jshint ignore:end */
EventTypePage.prototype.getInstance = function getInstance(payload) {
  return new EventTypeInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.EventTypePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
EventTypePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

EventTypePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the EventTypeContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.EventTypeInstance
 *
 * @property {string} type - The Event Type identifier.
 * @property {string} schemaId - The Schema identifier for this Event Type.
 * @property {Date} dateCreated - The date this Event Type was created.
 * @property {Date} dateUpdated - The date this Event Type was updated.
 * @property {string} description - Event Type description.
 * @property {string} url - The URL of this resource.
 * @property {string} links - The links
 *
 * @param {V1} version - Version of the resource
 * @param {EventTypePayload} payload - The instance payload
 * @param {string} type - A string that uniquely identifies this Event Type.
 */
/* jshint ignore:end */
EventTypeInstance = function EventTypeInstance(version, payload, type) {
  this._version = version;

  // Marshaled Properties
  this.type = payload.type; // jshint ignore:line
  this.schemaId = payload.schema_id; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.description = payload.description; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {type: type || this.type, };
};

Object.defineProperty(EventTypeInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new EventTypeContext(this._version, this._solution.type);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a EventTypeInstance
 *
 * @function fetch
 * @memberof Twilio.Events.V1.EventTypeInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EventTypeInstance
 */
/* jshint ignore:end */
EventTypeInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.EventTypeInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
EventTypeInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

EventTypeInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the EventTypeContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Events.V1.EventTypeContext
 *
 * @param {V1} version - Version of the resource
 * @param {string} type - A string that uniquely identifies this Event Type.
 */
/* jshint ignore:end */
EventTypeContext = function EventTypeContext(version, type) {
  this._version = version;

  // Path Solution
  this._solution = {type: type, };
  this._uri = `/Types/${type}`;
};

/* jshint ignore:start */
/**
 * fetch a EventTypeInstance
 *
 * @function fetch
 * @memberof Twilio.Events.V1.EventTypeContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EventTypeInstance
 */
/* jshint ignore:end */
EventTypeContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new EventTypeInstance(this._version, payload, this._solution.type));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Events.V1.EventTypeContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
EventTypeContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

EventTypeContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  EventTypeList: EventTypeList,
  EventTypePage: EventTypePage,
  EventTypeInstance: EventTypeInstance,
  EventTypeContext: EventTypeContext
};
