import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
// import Ant from '@expo/vector-icons/AntDesign';
import Icon from "./Warning";
import { THREE_FRACTION_CURRENCY } from "./../constants";
import i18n from "../i18n";
import {FontLetterSpacing, PriceLoader} from "./../handlers/utils"
import StyledText from "../components/styledText"

// const priceLoader = (price, currency) => {
//   if (THREE_FRACTION_CURRENCY.includes(currency)) {
//     return price ? price.toFixed(3) : "";
//   } else {
//     if (currency == "¥") {
//       return price ? parseInt(price) : "";
//     } else {
//       return price ? price.toFixed(2) : "";
//     }
//   }
// };

const CartTotal = (props) => (
  <View
    style={{
      width: "100%" /*height:75*/,
      position: "absolute",
      bottom: 0,
      backgroundColor: "transparent",
      justifyContent: "flex-end",
    }}
  >
    {/*<TouchableOpacity style={{width:'100%'}} onPress={props.navigateToCart}>*/}
    <View
      style={{
        width: "100%",
        minHeight: 50,
        backgroundColor: props.design.top_buttons_background_color,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ marginLeft: 17 }}>
        <StyledText
        font_color={props.design.top_buttons_text_color}
        font_weight={props.design.top_button_text_font_weight}
        font_style={props.design.top_button_text_font_style}
        text_transform={props.design.top_button_text_font_transform}
        font_family={props.design.top_button_text_font_family}
        font_size={props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}


          style={{
            marginBottom: 2,
          }}
        >
          {console.log(props.total, "sss")}
          {props.total} {i18n.t("labels.item_in_cart")}
        </StyledText>
        <StyledText
        font_color={props.design.top_buttons_text_color}
        font_weight={props.design.top_button_text_font_weight}
        font_style={props.design.top_button_text_font_style}
        text_transform={props.design.top_button_text_font_transform}
        font_family={props.design.top_button_text_font_family}
        font_size={props.design.top_button_text_font_size}
        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
        style={{ fontWeight: "500", }}
        >
          {props.display_currency && props.currency}{" "}
          {props.display_price &&
            PriceLoader(props.total_price, props.currency, props.display_fraction)}
        </StyledText>
      </View>
      <View style={{ marginRight: 17, flexDirection: "row" }}>
        {props.cartScreen ? (
          <View style={{ flexDirection: "row" }}>
            <StyledText
            font_color={props.design.top_buttons_text_color}
            font_weight={props.design.top_button_text_font_weight}
            font_style={props.design.top_button_text_font_style}
            text_transform={props.design.top_button_text_font_transform}
            font_family={props.design.top_button_text_font_family}
            font_size={props.design.top_button_text_font_size}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
              style={{}}
            >
              {props.payment_enable
                ? i18n.t("labels.review_order")
                : i18n.t("labels.place_holder")}
            </StyledText>
            {/* <Ant name={'caretright'} color={props.text_color} style={{marginLeft:5,marginTop: 5.5}}/> */}
            <Icon
              color={props.design.top_buttons_text_color}
              size={15}
              name={"play"}
              style={{ marginLeft: 5, marginTop: 4.5}}
            />
          </View>
        ) : (
          <View style={{ flexDirection: "row" }}>
            <StyledText
            font_color={props.design.top_buttons_text_color}
            font_weight={props.design.top_button_text_font_weight}
            font_style={props.design.top_button_text_font_style}
            text_transform={props.design.top_button_text_font_transform}
            font_family={props.design.top_button_text_font_family}
            font_size={props.design.top_button_text_font_size}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
              style={{}}
            >
              {i18n.t("labels.view_cart")}
            </StyledText>
            {/* <Ant name={'caretright'} color={props.text_color} style={{marginLeft:5,marginTop:5.5}}/> */}
            <Icon
              color={props.design.top_buttons_text_color}
              size={15}
              name={"play"}
              style={{ marginLeft: 5, marginTop: 4.5}}
            />
          </View>
        )}
      </View>
    </View>
    {/*</TouchableOpacity>*/}
  </View>
);

export default CartTotal;
