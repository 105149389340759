'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var FlowList = require('./v2/flow').FlowList;
var FlowValidateList = require('./v2/flowValidate').FlowValidateList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V2 version of Studio
 *
 * @constructor Twilio.Studio.V2
 *
 * @property {Twilio.Studio.V2.FlowList} flows - flows resource
 * @property {Twilio.Studio.V2.FlowValidateList} flowValidate -
 *          flowValidate resource
 *
 * @param {Twilio.Studio} domain - The twilio domain
 */
/* jshint ignore:end */
function V2(domain) {
  Version.prototype.constructor.call(this, domain, 'v2');

  // Resources
  this._flows = undefined;
  this._flowValidate = undefined;
}

_.extend(V2.prototype, Version.prototype);
V2.prototype.constructor = V2;

Object.defineProperty(V2.prototype,
  'flows', {
    get: function() {
      this._flows = this._flows || new FlowList(this);
      return this._flows;
    }
});

Object.defineProperty(V2.prototype,
  'flowValidate', {
    get: function() {
      this._flowValidate = this._flowValidate || new FlowValidateList(this);
      return this._flowValidate;
    }
});

module.exports = V2;
