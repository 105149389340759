import React from 'react';
import {View, StyleSheet, Animated, Dimensions} from 'react-native';
const {width, height} = Dimensions.get('window')


class ProgressiveImage extends React.Component {

    thumbnailAnimated = new Animated.Value(0);
    imageAnimated = new Animated.Value(0);
    handleThumbnailLoad = () => {
        Animated.timing(this.thumbnailAnimated, {
            toValue: 1,
        }).start();
    };
    onImageLoad = () => {
        Animated.timing(this.imageAnimated, {
            toValue: 1,
        }).start();
    };
    render() {
        const {
            thumbnailSource,
            source,
            style,
            ...props
        } = this.props;
        return (
            <View style={{width:'100%',height:'100%', position: this.props.position}}>
                <Animated.Image
                    {...props}
                    source={thumbnailSource}
                    style={[style, { opacity: this.thumbnailAnimated }]}
                    onLoad={this.handleThumbnailLoad}
                    blurRadius={1}
                />
                <Animated.Image
                    {...props}
                    source={source}
                    style={[styles.imageOverlay, { opacity: this.imageAnimated }, style]}
                    onLoad={this.onImageLoad}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    imageOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
    },
    container: {
        backgroundColor: '#e1e4e8',
    },
});
export default ProgressiveImage;