'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SettingsUpdateList;
var SettingsUpdatePage;
var SettingsUpdateInstance;

/* jshint ignore:start */
/**
 * Initialize the SettingsUpdateList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Supersim.V1.SettingsUpdateList
 *
 * @param {Twilio.Supersim.V1} version - Version of the resource
 */
/* jshint ignore:end */
SettingsUpdateList = function SettingsUpdateList(version) {
  /* jshint ignore:start */
  /**
   * @function settingsUpdates
   * @memberof Twilio.Supersim.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Supersim.V1.SettingsUpdateContext}
   */
  /* jshint ignore:end */
  function SettingsUpdateListInstance(sid) {
    return SettingsUpdateListInstance.get(sid);
  }

  SettingsUpdateListInstance._version = version;
  // Path Solution
  SettingsUpdateListInstance._solution = {};
  SettingsUpdateListInstance._uri = `/SettingsUpdates`;
  /* jshint ignore:start */
  /**
   * Streams SettingsUpdateInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Supersim.V1.SettingsUpdateList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.sim] - Filter the Settings Updates by Super SIM
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  SettingsUpdateListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists SettingsUpdateInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Supersim.V1.SettingsUpdateList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.sim] - Filter the Settings Updates by Super SIM
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SettingsUpdateListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of SettingsUpdateInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Supersim.V1.SettingsUpdateList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.sim] - Filter the Settings Updates by Super SIM
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SettingsUpdateListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Sim': _.get(opts, 'sim'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SettingsUpdatePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of SettingsUpdateInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Supersim.V1.SettingsUpdateList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SettingsUpdateListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new SettingsUpdatePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Supersim.V1.SettingsUpdateList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SettingsUpdateListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SettingsUpdateListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return SettingsUpdateListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SettingsUpdatePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Supersim.V1.SettingsUpdatePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SettingsUpdateSolution} solution - Path solution
 *
 * @returns SettingsUpdatePage
 */
/* jshint ignore:end */
SettingsUpdatePage = function SettingsUpdatePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SettingsUpdatePage.prototype, Page.prototype);
SettingsUpdatePage.prototype.constructor = SettingsUpdatePage;

/* jshint ignore:start */
/**
 * Build an instance of SettingsUpdateInstance
 *
 * @function getInstance
 * @memberof Twilio.Supersim.V1.SettingsUpdatePage#
 *
 * @param {SettingsUpdatePayload} payload - Payload response from the API
 *
 * @returns SettingsUpdateInstance
 */
/* jshint ignore:end */
SettingsUpdatePage.prototype.getInstance = function getInstance(payload) {
  return new SettingsUpdateInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Supersim.V1.SettingsUpdatePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SettingsUpdatePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SettingsUpdatePage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SettingsUpdateContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Supersim.V1.SettingsUpdateInstance
 *
 * @property {string} sid - The unique identifier of this Settings Update
 * @property {string} iccid - The ICCID associated with the SIM
 * @property {string} simSid -
 *          The SID of the Super SIM to which this Settings Update was applied
 * @property {settings_update.status} status - The Status of this Settings Update
 * @property {object} packages -
 *          Array containing the different Settings Packages that will be applied to the SIM after the update completes
 * @property {Date} dateCompleted -
 *          The time when the update successfully completed and the new settings were applied to the SIM
 * @property {Date} dateCreated - The date this Settings Update was created
 * @property {Date} dateUpdated - The date this Settings Update was last updated
 *
 * @param {V1} version - Version of the resource
 * @param {SettingsUpdatePayload} payload - The instance payload
 */
/* jshint ignore:end */
SettingsUpdateInstance = function SettingsUpdateInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.iccid = payload.iccid; // jshint ignore:line
  this.simSid = payload.sim_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.packages = payload.packages; // jshint ignore:line
  this.dateCompleted = deserialize.iso8601DateTime(payload.date_completed); // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Supersim.V1.SettingsUpdateInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SettingsUpdateInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SettingsUpdateInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SettingsUpdateList: SettingsUpdateList,
  SettingsUpdatePage: SettingsUpdatePage,
  SettingsUpdateInstance: SettingsUpdateInstance
};
