import {
    ADD_CARD
} from "../actionTypes";


const initialState = {
       cardNumber:'',
       expiryYear:'',
       expiryMonth:'',
       cvv:'',
       service: '',
       token: '',
       card: '',
       saveCard: true,
       oldCard: false,
       scheme: "",
       stripeObject: "",




};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CARD:
            return {
                ...state,
                cardNumber: action.cardNumber,
                expiryYear: action.expiryYear,
                expiryMonth: action.expiryMonth,
                cvv: action.cvv,
                service: action.service,
                token: action.token,
                card: action.card,
                saveCard: action.saveCard,
                oldCard: action.oldCard,
                scheme: action.scheme,
                stripeObject:action.stripeObject,
            };
       

        default:
            return state;
    }
};

export default reducer;