import React from 'react';
import { View, TouchableOpacity, Text, TextInput, AsyncStorage } from 'react-native';
import BackButton from './../components/backButton'
import { connect } from 'react-redux'
import Toast from './../components/toast'
import axios from 'axios'
import { API_URL, serviceID, accountSid, authToken } from '../constants'
import { addVerifiedNumber, adduser, addToken, feedbackModal } from './../store/actions'
import { Spinner } from 'native-base'
import { NavigationActions, } from 'react-navigation';
import OtpInput from 'react-otp-input';
import * as Localization from "expo-localization";
import { performRequest } from './../services/apiHandler'
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';
import ReCAPTCHA from "react-google-recaptcha";

import {  ReCAPTCHA_KEY } from './../constants/index'

import Constants from 'expo-constants';


let mid = Constants.installationId



const client = require('twilio')(accountSid, authToken);



class OtpScreen extends React.Component {

    state = {
        error_message: '',
        message_status: '',
        message_display: 'none',

        timeLeft: 30,
        resendDisabled: true,
        otp: '',
        loader: false,
        google_token : ""


    }

    throwMessageFn = (message, status) => {
        this.setState({
            message_status: status,
            error_message: message,
            message_display: 'inherit'
        });

        setTimeout(
            function () {
                this.setState({
                    message_status: '',
                    error_message: '',
                    message_display: 'none'
                });
            }
                .bind(this),
            3000
        );
    };

    static navigationOptions = {
        header: null,
        gesturesEnabled: false,
        animationEnabled: true,

    };
    timerId = () => {
        this.interval = setInterval(() => this.countdown(), 1000)
    };


    countdown = () => {
        if (this.state.timeLeft == -1) {
            clearTimeout(this.interval);
            this.setState({ resendDisabled: false }, () => console.log('bleeeee'))
        } else {
            this.setState((prevState) => ({ timeLeft: prevState.timeLeft - 1 }))
        }
    }


    componentDidMount() {
        this.timerId()

        // if ("OTPCredential" in window) {
        //     const ac = new AbortController();
        //     console.log(ac)
        //     navigator.credentials
        //       .get({
        //         otp: { transport: ["sms"] },
        //         signal: ac.signal
        //       })
        //       .then((otp) => {
        //           console.log(otp, 'hereeeeeeeeeeeee')
        //         this.setState({ otp: otp.code });
        //         ac.abort();
        //       })
        //       .catch((err) => {
        //         console.log(err,'errrrrrrrrrr')
        //         ac.abort();
        //         console.log(err);
        //       });
        //   }
        console.log(1)
        if ('OTPCredential' in window) {
            console.log(2)
            window.addEventListener('DOMContentLoaded', e => {
                console.log(3)

                const ac = new AbortController();

                navigator.credentials.get({
                    otp: { transport: ['sms'] },
                    signal: ac.signal
                }).then(otp => {
                    console.log(4)

                    this.setState({ otp: otp.code });
                    ac.abort();

                }).catch(err => {
                    console.log(5)

                    console.log(err);
                    ac.abort();
                });
            });
        }


    }
    componentWillUnmount() {
        clearTimeout(this.interval);
    }
    onChangeCaptcha = (google_token) => {
        this.setState({google_token})
      }
    resendCode = () => {
        this.setState({ loader: true })
        const { country_code, number, channel } = this.props.navigation.state.params
        let locale = Localization.locale.split("-")[0]

        axios.get(API_URL + `api/v2/pwa/number/verify/?number=${number}&locale=${locale}&venue_name=${this.props.config.displayName ? this.props.config.displayName : this.props.config.venueName}&channel=${channel}&venue_id=${this.props.config.venue}&token=${this.state.google_token}`)
            .then(() => {
                this.setState({ loader: false })
            })
            .catch(() => {
                this.setState({ loader: false })
                this.throwMessageFn('Error sending otp. Try again.', 'red')
            })
        this.setState({ timeLeft: 30, resendDisabled: true }, () => {
            this.timerId()

        })
    }

    resendEmailCode = () => {
        this.setState({ loader: true })
        const { channel, email } = this.props.navigation.state.params
        let locale = Localization.locale.split("-")[0]

        performRequest("post", "api/v2/account/email/verify", {
            email,
            venue: this.props.config.venue,
            
        }, false)
            .then(() => {
                this.setState({ loader: false })

            })
            .catch(() => {
                this.setState({ loader: false })
                this.throwMessageFn('Error sending otp. Try again.', 'red')
            })
        this.setState({ timeLeft: 30, resendDisabled: true }, () => {
            this.timerId()

        })
    }
    componentWillUnmount() {
        clearTimeout(this.interval);
    }
    setScreenActive = () => {
        this.setState({
            isLandingPageActive: true
        })
    }
    onChangeOTP = (otp) => {
        const { country_code, number, country, service, channel } = this.props.navigation.state.params
        this.setState({ otp: otp }, async() => {
            if (this.state.otp.length == 4) {
                this.setState({ loader: true })
                const uuid = await AsyncStorage.getItem('uuid');
                // axios.get(API_URL+`api/otp/verify/?number=${country_code+number}&otp=${otp}&mid=${mid}`)
                axios.get(API_URL + `api/v2/pwa/otp/verify/?number=${number.substring(1)}&otp=${otp}&mid=${mid}&service=${service}&channel=${channel}&venue_id=${this.props.config.venue}&uuid=${uuid}`)

                    .then(response => {
                        this.postOtpVerification(response, channel, "mobile_number")

                        // if (response.data.status == 'approved') {
                        //     this.props.addVerifiedNumber(number)

                        //     this.throwMessageFn('Verified successfully', 'green')
                        //     this.setState({ loader: false })
                        //     if (response.data.is_user) {
                        //         await AsyncStorage.setItem('token', response.data.user.token);
                        //         this.props.addToken(response.data.user.token)
                        //         this.props.adduser(response.data.user)

                        //         if (this.props.platform == 'qr') {
                        //             try {
                        //                 const screen = await AsyncStorage.getItem('login_trigger')
                        //                 console.log(screen, "screeeeen")
                        //                 await AsyncStorage.removeItem("login_trigger")

                        //                 this.props.navigation.navigate(screen)

                        //             } catch (e) {
                        //                 const navigateAction = NavigationActions.navigate({
                        //                     routeName: 'MenuHome',
                        //                     params: {
                        //                         setScreenActive: () => this.setScreenActive()
                        //                     },
                        //                 });
                        //                 this.props.navigation.dispatch(navigateAction);
                        //             }
                        //             return


                        //         } else {
                        //             try {
                        //                 const screen = await AsyncStorage.getItem('login_trigger')
                        //                 console.log(screen, "screeeeen")
                        //                 await AsyncStorage.removeItem("login_trigger")
                        //                 this.props.navigation.navigate(screen)

                        //             } catch (e) {
                        //                 const navigateAction = NavigationActions.navigate({
                        //                     routeName: 'IndexScreen',
                        //                     params: {
                        //                         // setScreenActive: () => this.setScreenActive()
                        //                     },
                        //                 });
                        //                 this.props.navigation.dispatch(navigateAction);
                        //             }
                        //             return

                        //         }
                        //     } else {
                        //         this.setState({ loader: false })
                        //         this.throwMessageFn('User not registered', 'red')
                        //         return
                        //     }
                        // } else {
                        //     this.setState({ loader: false })
                        //     this.throwMessageFn('Error in Verifying', 'red')
                        // }
                    })
                    .catch(() => {
                        this.setState({ loader: false })
                        this.throwMessageFn('Error in Verifying', 'red')
                    })
            }
        })
    }

    postOtpVerification = async(response, channel, type) => {
        await AsyncStorage.setItem('token', response.data.token);
        this.props.addToken(response.data.user.token)
        this.props.adduser(response.data.user)
        if (!response.data.user[type=="email"? "mobile_number":"email"] && !response.data.user.first_name){
            if(type=="email"){
                this.props.navigation.navigate('UserRegistrationScreen', {
                    email_param: response.data.user.email,
                    first_name_param: response.data.user.first_name,
                    last_name_param: "",
                    channel,
                    is_email_verified:true
                })
            }else{
                const { country_code, number, country } = this.props.navigation.state.params
                this.props.navigation.navigate('UserRegistrationScreen', {
                    number_param: number,
                    country,
                    country_code,
                    first_name_param: response.data.user.first_name,
                    last_name_param: response.data.user.last_name,
                    channel,
                    is_phone_verified:true
                })
            }
        }else{
            try {
                const screen = await AsyncStorage.getItem('login_trigger')
                const is_feedback = localStorage.getItem("is_feedback")
                console.log(is_feedback, "lllllllllllll")
                if(is_feedback){
                    localStorage.removeItem("is_feedback")
                    this.props.feedbackModal(true)
                }
                await AsyncStorage.removeItem("login_trigger")
                this.props.navigation.navigate(screen)
            } catch (e) {
                if(this.props.platform == 'qr'){
                    const navigateAction = NavigationActions.navigate({
                        routeName: 'MenuHome',
                        params: {
                        },
                    });
                    this.props.navigation.dispatch(navigateAction);
                }else{
                    const navigateAction = NavigationActions.navigate({
                        routeName: 'IndexScreen',
                        params: {
                        },
                    });
                    this.props.navigation.dispatch(navigateAction);
                }
                
            }
        }
    }
    onChangeEmailOTP = (otp) => {
        const { channel,email } = this.props.navigation.state.params
        this.setState({ otp: otp }, () => {
            if (this.state.otp.length == 4) {
                this.setState({ loader: true })

                performRequest("post", "api/v2/account/email/otp/verify", {
                    email,
                    venue: this.props.config.venue,
                    otp
                }, false)
                .then(response => {
                    this.setState({ loader: false })

                    this.postOtpVerification(response, channel, "email")
                })
                .catch(err => {
                    console.log(err.response, "err")
                    this.setState({ loader: false })

                    this.throwMessageFn('Error sending verification email', 'red')
                })
            }
        })
    }


    render() {
        const { country_code, number, channel, email } = this.props.navigation.state.params
        return (
            <View style={{ backgroundColor: this.props.design.list_view_background_color, flex: 1 }} >
                {
                    channel !== "email" &&
                    <View style={{ alignItems: 'center' }}>
                        <StyledText style={{ fontSize: this.props.design.custom_font.heading_size ?? 20, fontFamily: 'SecondaryFont', fontWeight: 'bold', marginTop: 60, color: this.props.design.list_view_text_color }}>What's the code?</StyledText>
                        <StyledText style={{ marginTop: 15, fontFamily: 'SecondaryFont', color: this.props.design.list_view_text_color }}>Enter the code sent to</StyledText>
                        {/* <Text style={{fontWeight:'bold',marginTop:5, color: this.props.design.list_view_text_color}}>+{country_code} {number}</Text> */}
                        <StyledText 
                        font_color={this.props.design.list_view_header_color}
                        font_weight={this.props.design.list_view_header_font_weight}
                        font_style={this.props.design.list_view_header_font_style}
                        text_transform={this.props.design.list_view_header_font_transform}
                        font_family={this.props.design.list_view_header_font_family}
                        font_size={this.props.design.list_view_header_font_size?? 20}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
    
                        style={{  marginTop: 5 }}>{number}</StyledText>
                        {
                            channel.toLowerCase() == "whatsapp" ?
                                <StyledText
                                    font_color={this.props.design.list_view_text_color}
                                    font_weight={this.props.design.list_view_text_font_weight}
                                    font_style={this.props.design.list_view_text_font_style}
                                    text_transform={this.props.design.list_view_text_font_transform}
                                    font_family={this.props.design.list_view_text_font_family}
                                    font_size={this.props.design.list_view_text_font_size?? 12}
                                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                                 style={{ marginTop: 5 }}>through Whatsapp</StyledText>
                                :
                                <View />


                        }


                        <View style={{ width: '100%', height: 80, marginTop: 40, justifyContent: 'center', alignItems: "center" }}>
                            <OtpInput
                                autocomplete="one-time-code"
                                isInputNum={true}
                                type={"tel"}
                                value={this.state.otp}
                                onChange={this.onChangeOTP}
                                numInputs={4}
                                separator={<span>-</span>}
                   
                                inputStyle={{
                                    height: 50,
                                    width: 50,
                             
                                    fontSize: this.props.design.custom_font.heading_size ??40,
                                  
                                }}
                            />

                        
                        </View>

                        <View style={{ height: 60, width: '60%' }}>
                        {/* <ReCAPTCHA
                            sitekey={ReCAPTCHA_KEY}
                            onChange={this.onChangeCaptcha}
                        /> */}
                            <TouchableOpacity
                                style={{ height: 60, }}
                                disabled={this.state.resendDisabled}
                                onPress={() => this.resendCode()}

                            >
                                <View style={{
                                    width: '100%',
                                    height: '100%',
                                    marginTop: 40,
                                    borderRadius: 8,
                                    border: '1px solid grey',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: this.props.design.top_buttons_background_color
                                }}>
                                    {
                                        this.state.loader ?
                                            <Spinner color={this.props.design.top_buttons_text_color} />
                                            :
                                            <StyledText 
                                            font_color={this.props.design.top_buttons_text_color}
                                            font_weight={this.props.design.top_button_text_font_weight}
                                            font_style={this.props.design.top_button_text_font_style}
                                            text_transform={this.props.design.top_button_text_font_transform}
                                            font_family={this.props.design.top_button_text_font_family}
                                            font_size={this.props.design.top_button_text_font_size}
                                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                                            style={{}} >Resend code {this.state.timeLeft != -1 && `( ${this.state.timeLeft} )`}</StyledText>
                                    }


                                </View>
                            </TouchableOpacity>
                        </View>





                    </View>

                }





                {
                    channel == "email" &&
                    <View style={{ alignItems: 'center' }}>
                        <StyledText 
                        font_color={this.props.design.list_view_header_color}
                        font_weight={this.props.design.list_view_header_font_weight}
                        font_style={this.props.design.list_view_header_font_style}
                        text_transform={this.props.design.list_view_header_font_transform}
                        font_family={this.props.design.list_view_header_font_family}
                        font_size={this.props.design.list_view_header_font_size?? 20}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
    
                        style={{
                              marginTop: 60, }}>What's the code?</StyledText>
                        <StyledText 
                         font_color={this.props.design.list_view_text_color}
                         font_weight={this.props.design.list_view_text_font_weight}
                         font_style={this.props.design.list_view_text_font_style}
                         text_transform={this.props.design.list_view_text_font_transform}
                         font_family={this.props.design.list_view_text_font_family}
                         font_size={this.props.design.list_view_text_font_size?? 12}
                         letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{ marginTop: 15, }}>Enter the code sent to</StyledText>
                        <StyledText 
                         font_color={this.props.design.list_view_text_color}
                         font_weight={this.props.design.list_view_text_font_weight}
                         font_style={this.props.design.list_view_text_font_style}
                         text_transform={this.props.design.list_view_text_font_transform}
                         font_family={this.props.design.list_view_text_font_family}
                         font_size={this.props.design.list_view_text_font_size?? 12}
                         letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{ fontWeight: 'bold', marginTop: 5 }}>{email}</StyledText>
        

                        <View style={{ width: '100%', height: 80, marginTop: 40, justifyContent: 'center', alignItems: "center" }}>
                            <OtpInput
                                autocomplete="one-time-code"
                                isInputNum={true}
                                type={"tel"}
                                value={this.state.otp}
                                onChange={this.onChangeEmailOTP}
                                numInputs={4}
                                separator={<span>-</span>}
                                containerStyle={{

                                }}
                                inputStyle={{
                                    height: 50,
                                    width: 50,

                                    fontSize: this.props.design.custom_font.heading_size ?? 40,

                                }}
                            />

                        </View>

                        <View style={{ height: 60, width: '60%' }}>
                            <TouchableOpacity
                                style={{ height: 60, }}
                                disabled={this.state.resendDisabled}
                                onPress={() => this.resendEmailCode()}

                            >
                                <View style={{
                                    width: '100%',
                                    height: '100%',
                                    marginTop: 40,
                                    borderRadius: 8,
                                    border: '1px solid grey',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: this.props.design.top_buttons_background_color
                                }}>
                                    {
                                        this.state.loader ?
                                            <Spinner color={this.props.design.top_buttons_text_color} />
                                            :
                                            <StyledText 
                                            font_color={this.props.design.top_buttons_text_color}
                                            font_weight={this.props.design.top_button_text_font_weight}
                                            font_style={this.props.design.top_button_text_font_style}
                                            text_transform={this.props.design.top_button_text_font_transform}
                                            font_family={this.props.design.top_button_text_font_family}
                                            font_size={this.props.design.top_button_text_font_size}
                                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                                            style={{ }} >Resend code {this.state.timeLeft != -1 && `( ${this.state.timeLeft} )`}</StyledText>
                                    }


                                </View>
                            </TouchableOpacity>
                        </View>





                    </View>

                }

                <TouchableOpacity style={{ position: 'absolute', top: 0 }} onPress={() => this.props.navigation.goBack()} >
                    <BackButton
                        border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
                        // goBack={() => this.props.navigation.goBack()}
                        backgroundColor={this.props.design.top_buttons_background_color}
                        borderColor={'black'}
                        color={this.props.design.top_buttons_text_color}
                        default_language={this.props.default_language}
                        font_size={this.props.design.custom_font.heading_size}

                    />
                </TouchableOpacity>

                <Toast
                    error_message={this.state.error_message}
                    message_status={this.state.message_status}
                    message_display={this.state.message_display}
                    custom_font={this.props.design.custom_font}
                />

            </View>
        )
    }
}


const mapStateToProps = state => {
    return {
        menus: state.menus.menus,
        allChildren: state.menus.allChildren,
        immediateChildren: state.menus._immediateChildren,
        cart: state.cart.cart,
        cart_count: state.cart.cart_count,
        total_price: state.cart.total_price,
        config: state.config.config,
        languages: state.languages.languages,
        default_language: state.languages.default_language,
        isRTL: state.languages.isRTL,
        platform: state.config.platform,
        design: state.config.design,


    }
};

export default connect(mapStateToProps, { addVerifiedNumber, adduser, addToken, feedbackModal })(OtpScreen)