'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var SyncListPermissionList;
var SyncListPermissionPage;
var SyncListPermissionInstance;
var SyncListPermissionContext;

/* jshint ignore:start */
/**
 * Initialize the SyncListPermissionList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList
 *
 * @param {Twilio.Preview.Sync} version - Version of the resource
 * @param {string} serviceSid - Sync Service Instance SID.
 * @param {string} listSid - Sync List SID.
 */
/* jshint ignore:end */
SyncListPermissionList = function SyncListPermissionList(version, serviceSid,
                                                          listSid) {
  /* jshint ignore:start */
  /**
   * @function syncListPermissions
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext}
   */
  /* jshint ignore:end */
  function SyncListPermissionListInstance(sid) {
    return SyncListPermissionListInstance.get(sid);
  }

  SyncListPermissionListInstance._version = version;
  // Path Solution
  SyncListPermissionListInstance._solution = {serviceSid: serviceSid, listSid: listSid};
  SyncListPermissionListInstance._uri = `/Services/${serviceSid}/Lists/${listSid}/Permissions`;
  /* jshint ignore:start */
  /**
   * Streams SyncListPermissionInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists SyncListPermissionInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of SyncListPermissionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SyncListPermissionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of SyncListPermissionInstance records from the
   * API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new SyncListPermissionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a sync_list_permission
   *
   * @function get
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @param {string} identity -
   *          Identity of the user to whom the Sync List Permission applies.
   *
   * @returns {Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext}
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.get = function get(identity) {
    return new SyncListPermissionContext(
      this._version,
      this._solution.serviceSid,
      this._solution.listSid,
      identity
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SyncListPermissionListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SyncListPermissionListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return SyncListPermissionListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SyncListPermissionPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionPage
 *
 * @param {Sync} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SyncListPermissionSolution} solution - Path solution
 *
 * @returns SyncListPermissionPage
 */
/* jshint ignore:end */
SyncListPermissionPage = function SyncListPermissionPage(version, response,
                                                          solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SyncListPermissionPage.prototype, Page.prototype);
SyncListPermissionPage.prototype.constructor = SyncListPermissionPage;

/* jshint ignore:start */
/**
 * Build an instance of SyncListPermissionInstance
 *
 * @function getInstance
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionPage#
 *
 * @param {SyncListPermissionPayload} payload - Payload response from the API
 *
 * @returns SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionPage.prototype.getInstance = function getInstance(payload) {
  return new SyncListPermissionInstance(
    this._version,
    payload,
    this._solution.serviceSid,
    this._solution.listSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SyncListPermissionPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SyncListPermissionPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SyncListPermissionContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionInstance
 *
 * @property {string} accountSid - Twilio Account SID.
 * @property {string} serviceSid - Sync Service Instance SID.
 * @property {string} listSid - Sync List SID.
 * @property {string} identity -
 *          Identity of the user to whom the Sync List Permission applies.
 * @property {boolean} read - Read access.
 * @property {boolean} write - Write access.
 * @property {boolean} manage - Manage access.
 * @property {string} url - URL of this Sync List Permission.
 *
 * @param {Sync} version - Version of the resource
 * @param {SyncListPermissionPayload} payload - The instance payload
 * @param {sid} serviceSid - Sync Service Instance SID.
 * @param {sid} listSid - Sync List SID.
 * @param {string} identity -
 *          Identity of the user to whom the Sync List Permission applies.
 */
/* jshint ignore:end */
SyncListPermissionInstance = function SyncListPermissionInstance(version,
    payload, serviceSid, listSid, identity) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.serviceSid = payload.service_sid; // jshint ignore:line
  this.listSid = payload.list_sid; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line
  this.read = payload.read; // jshint ignore:line
  this.write = payload.write; // jshint ignore:line
  this.manage = payload.manage; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {serviceSid: serviceSid, listSid: listSid, identity: identity || this.identity, };
};

Object.defineProperty(SyncListPermissionInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SyncListPermissionContext(
          this._version,
          this._solution.serviceSid,
          this._solution.listSid,
          this._solution.identity
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a SyncListPermissionInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a SyncListPermissionInstance
 *
 * @function remove
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * update a SyncListPermissionInstance
 *
 * @function update
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionInstance#
 *
 * @param {object} opts - Options for request
 * @param {boolean} opts.read - Read access.
 * @param {boolean} opts.write - Write access.
 * @param {boolean} opts.manage - Manage access.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SyncListPermissionInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SyncListPermissionInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SyncListPermissionContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext
 *
 * @param {Sync} version - Version of the resource
 * @param {sid} serviceSid - The service_sid
 * @param {sid_like} listSid - Sync List SID or unique name.
 * @param {string} identity -
 *          Identity of the user to whom the Sync List Permission applies.
 */
/* jshint ignore:end */
SyncListPermissionContext = function SyncListPermissionContext(version,
    serviceSid, listSid, identity) {
  this._version = version;

  // Path Solution
  this._solution = {serviceSid: serviceSid, listSid: listSid, identity: identity, };
  this._uri = `/Services/${serviceSid}/Lists/${listSid}/Permissions/${identity}`;
};

/* jshint ignore:start */
/**
 * fetch a SyncListPermissionInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SyncListPermissionInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.listSid,
      this._solution.identity
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a SyncListPermissionInstance
 *
 * @function remove
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a SyncListPermissionInstance
 *
 * @function update
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext#
 *
 * @param {object} opts - Options for request
 * @param {boolean} opts.read - Read access.
 * @param {boolean} opts.write - Write access.
 * @param {boolean} opts.manage - Manage access.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SyncListPermissionInstance
 */
/* jshint ignore:end */
SyncListPermissionContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['read'])) {
    throw new Error('Required parameter "opts[\'read\']" missing.');
  }
  if (_.isUndefined(opts['write'])) {
    throw new Error('Required parameter "opts[\'write\']" missing.');
  }
  if (_.isUndefined(opts['manage'])) {
    throw new Error('Required parameter "opts[\'manage\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'Read': serialize.bool(_.get(opts, 'read')),
    'Write': serialize.bool(_.get(opts, 'write')),
    'Manage': serialize.bool(_.get(opts, 'manage'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SyncListPermissionInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.listSid,
      this._solution.identity
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Sync.ServiceContext.SyncListContext.SyncListPermissionContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SyncListPermissionContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SyncListPermissionContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SyncListPermissionList: SyncListPermissionList,
  SyncListPermissionPage: SyncListPermissionPage,
  SyncListPermissionInstance: SyncListPermissionInstance,
  SyncListPermissionContext: SyncListPermissionContext
};
