'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var RestoreAssistantList;
var RestoreAssistantPage;
var RestoreAssistantInstance;

/* jshint ignore:start */
/**
 * Initialize the RestoreAssistantList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.RestoreAssistantList
 *
 * @param {Twilio.Autopilot.V1} version - Version of the resource
 */
/* jshint ignore:end */
RestoreAssistantList = function RestoreAssistantList(version) {
  /* jshint ignore:start */
  /**
   * @function restoreAssistant
   * @memberof Twilio.Autopilot.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Autopilot.V1.RestoreAssistantContext}
   */
  /* jshint ignore:end */
  function RestoreAssistantListInstance(sid) {
    return RestoreAssistantListInstance.get(sid);
  }

  RestoreAssistantListInstance._version = version;
  // Path Solution
  RestoreAssistantListInstance._solution = {};
  RestoreAssistantListInstance._uri = `/Assistants/Restore`;
  /* jshint ignore:start */
  /**
   * update a RestoreAssistantInstance
   *
   * @function update
   * @memberof Twilio.Autopilot.V1.RestoreAssistantList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.assistant - The unique string that identifies the resource
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed RestoreAssistantInstance
   */
  /* jshint ignore:end */
  RestoreAssistantListInstance.update = function update(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['assistant'])) {
      throw new Error('Required parameter "opts[\'assistant\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'Assistant': _.get(opts, 'assistant')});

    var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new RestoreAssistantInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Autopilot.V1.RestoreAssistantList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  RestoreAssistantListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  RestoreAssistantListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return RestoreAssistantListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the RestoreAssistantPage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.RestoreAssistantPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {RestoreAssistantSolution} solution - Path solution
 *
 * @returns RestoreAssistantPage
 */
/* jshint ignore:end */
RestoreAssistantPage = function RestoreAssistantPage(version, response,
                                                      solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(RestoreAssistantPage.prototype, Page.prototype);
RestoreAssistantPage.prototype.constructor = RestoreAssistantPage;

/* jshint ignore:start */
/**
 * Build an instance of RestoreAssistantInstance
 *
 * @function getInstance
 * @memberof Twilio.Autopilot.V1.RestoreAssistantPage#
 *
 * @param {RestoreAssistantPayload} payload - Payload response from the API
 *
 * @returns RestoreAssistantInstance
 */
/* jshint ignore:end */
RestoreAssistantPage.prototype.getInstance = function getInstance(payload) {
  return new RestoreAssistantInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.RestoreAssistantPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
RestoreAssistantPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RestoreAssistantPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the RestoreAssistantContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.RestoreAssistantInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} sid - The unique string that identifies the resource
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT when the resource was last updated
 * @property {string} uniqueName -
 *          An application-defined string that uniquely identifies the resource
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {boolean} needsModelBuild - Whether model needs to be rebuilt
 * @property {string} latestModelBuildSid - Reserved
 * @property {boolean} logQueries -
 *          Whether queries should be logged and kept after training
 * @property {string} developmentStage -
 *          A string describing the state of the assistant.
 * @property {string} callbackUrl - Reserved
 * @property {string} callbackEvents - Reserved
 *
 * @param {V1} version - Version of the resource
 * @param {RestoreAssistantPayload} payload - The instance payload
 */
/* jshint ignore:end */
RestoreAssistantInstance = function RestoreAssistantInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.needsModelBuild = payload.needs_model_build; // jshint ignore:line
  this.latestModelBuildSid = payload.latest_model_build_sid; // jshint ignore:line
  this.logQueries = payload.log_queries; // jshint ignore:line
  this.developmentStage = payload.development_stage; // jshint ignore:line
  this.callbackUrl = payload.callback_url; // jshint ignore:line
  this.callbackEvents = payload.callback_events; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.RestoreAssistantInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
RestoreAssistantInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RestoreAssistantInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  RestoreAssistantList: RestoreAssistantList,
  RestoreAssistantPage: RestoreAssistantPage,
  RestoreAssistantInstance: RestoreAssistantInstance
};
