import {
    LANGUAGES,
    DEFAULT_LANGUAGE
} from "../actionTypes";


const initialState = {
    languages: [],
    default_language:'en',
    isRTL:false


};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LANGUAGES:
            return {
                ...state,
                languages: action.languages
            };
        case DEFAULT_LANGUAGE:
            return {
                ...state,
                default_language: action.language,
                isRTL: action.language === 'ar' || action.language === 'he' ? true : false

            };

        default:
            return state;
    }
};

export default reducer;