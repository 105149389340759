import {
    ADD_CARD
} from "../actionTypes";




export const addCard = (
    cardNumber, 
    expiryYear,
    expiryMonth,
    cvv, 
    service, 
    token, 
    card, 
    saveCard, 
    oldCard, 
    scheme,
    stripeObject,
    ) => {
    return{
        type: ADD_CARD,
        cardNumber: cardNumber,
        expiryYear:expiryYear,
        expiryMonth: expiryMonth,
        cvv: cvv,
        service: service,
        token: token,
        card : card,
        saveCard: saveCard,
        oldCard: oldCard,
        scheme,
        stripeObject,
    };
};