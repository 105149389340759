'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */

var ArchivedCallList;
var ArchivedCallPage;
var ArchivedCallInstance;
var ArchivedCallContext;

/* jshint ignore:start */
/**
 * Initialize the ArchivedCallList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Voice.V1.ArchivedCallList
 *
 * @param {Twilio.Voice.V1} version - Version of the resource
 */
/* jshint ignore:end */
ArchivedCallList = function ArchivedCallList(version) {
  /* jshint ignore:start */
  /**
   * @function archivedCalls
   * @memberof Twilio.Voice.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Voice.V1.ArchivedCallContext}
   */
  /* jshint ignore:end */
  function ArchivedCallListInstance(sid) {
    return ArchivedCallListInstance.get(sid);
  }

  ArchivedCallListInstance._version = version;
  // Path Solution
  ArchivedCallListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a archived_call
   *
   * @function get
   * @memberof Twilio.Voice.V1.ArchivedCallList#
   *
   * @param {string} date - The date of the Call in UTC.
   * @param {string} sid - The unique string that identifies this resource
   *
   * @returns {Twilio.Voice.V1.ArchivedCallContext}
   */
  /* jshint ignore:end */
  ArchivedCallListInstance.get = function get(date, sid) {
    return new ArchivedCallContext(this._version, date, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Voice.V1.ArchivedCallList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ArchivedCallListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ArchivedCallListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ArchivedCallListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ArchivedCallPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Voice.V1.ArchivedCallPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ArchivedCallSolution} solution - Path solution
 *
 * @returns ArchivedCallPage
 */
/* jshint ignore:end */
ArchivedCallPage = function ArchivedCallPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ArchivedCallPage.prototype, Page.prototype);
ArchivedCallPage.prototype.constructor = ArchivedCallPage;

/* jshint ignore:start */
/**
 * Build an instance of ArchivedCallInstance
 *
 * @function getInstance
 * @memberof Twilio.Voice.V1.ArchivedCallPage#
 *
 * @param {ArchivedCallPayload} payload - Payload response from the API
 *
 * @returns ArchivedCallInstance
 */
/* jshint ignore:end */
ArchivedCallPage.prototype.getInstance = function getInstance(payload) {
  return new ArchivedCallInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.ArchivedCallPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ArchivedCallPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ArchivedCallPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ArchivedCallContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Voice.V1.ArchivedCallInstance
 *
 * @property {Date} date - date
 * @property {string} sid - sid
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V1} version - Version of the resource
 * @param {ArchivedCallPayload} payload - The instance payload
 * @param {date} date - The date of the Call in UTC.
 * @param {sid} sid - The unique string that identifies this resource
 */
/* jshint ignore:end */
ArchivedCallInstance = function ArchivedCallInstance(version, payload, date,
                                                      sid) {
  this._version = version;

  // Marshaled Properties
  this.date = deserialize.iso8601Date(payload.date); // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {date: date || this.date, sid: sid || this.sid, };
};

Object.defineProperty(ArchivedCallInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ArchivedCallContext(this._version, this._solution.date, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * remove a ArchivedCallInstance
 *
 * @function remove
 * @memberof Twilio.Voice.V1.ArchivedCallInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ArchivedCallInstance
 */
/* jshint ignore:end */
ArchivedCallInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.ArchivedCallInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ArchivedCallInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ArchivedCallInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ArchivedCallContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Voice.V1.ArchivedCallContext
 *
 * @param {V1} version - Version of the resource
 * @param {date} date - The date of the Call in UTC.
 * @param {sid} sid - The unique string that identifies this resource
 */
/* jshint ignore:end */
ArchivedCallContext = function ArchivedCallContext(version, date, sid) {
  this._version = version;

  // Path Solution
  this._solution = {date: date, sid: sid, };
  this._uri = `/Archives/${date}/Calls/${sid}`;
};

/* jshint ignore:start */
/**
 * remove a ArchivedCallInstance
 *
 * @function remove
 * @memberof Twilio.Voice.V1.ArchivedCallContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ArchivedCallInstance
 */
/* jshint ignore:end */
ArchivedCallContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.ArchivedCallContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ArchivedCallContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ArchivedCallContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ArchivedCallList: ArchivedCallList,
  ArchivedCallPage: ArchivedCallPage,
  ArchivedCallInstance: ArchivedCallInstance,
  ArchivedCallContext: ArchivedCallContext
};
