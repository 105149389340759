import React, {Component} from 'react';
import {View, Text} from "react-native";

import {Icon} from 'native-base'
import {style} from "../../assets/style";





class FilterButton extends React.Component{

    render(){
        return(
            <View style={[style.fontButton,{backgroundColor:this.props.backgroundColor,border:this.props.border_color}]}>
                <i style={{color: this.props.color, fontSize:25}} class="fas fa-sliders-h"></i>
            </View>
        );
    }
};
export default FilterButton;


