'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./messaging/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize messaging domain
 *
 * @constructor Twilio.Messaging
 *
 * @property {Twilio.Messaging.V1} v1 - v1 version
 * @property {Twilio.Messaging.V1.BrandRegistrationList} brandRegistrations -
 *          brandRegistrations resource
 * @property {Twilio.Messaging.V1.DeactivationsList} deactivations -
 *          deactivations resource
 * @property {Twilio.Messaging.V1.ExternalCampaignList} externalCampaign -
 *          externalCampaign resource
 * @property {Twilio.Messaging.V1.ServiceList} services - services resource
 * @property {Twilio.Messaging.V1.TollfreeVerificationList} tollfreeVerifications -
 *          tollfreeVerifications resource
 * @property {Twilio.Messaging.V1.UsecaseList} usecases - usecases resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Messaging(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://messaging.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(Messaging.prototype, Domain.prototype);
Messaging.prototype.constructor = Messaging;

Object.defineProperty(Messaging.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(Messaging.prototype,
  'brandRegistrations', {
    get: function() {
      return this.v1.brandRegistrations;
    }
});

Object.defineProperty(Messaging.prototype,
  'deactivations', {
    get: function() {
      return this.v1.deactivations;
    }
});

Object.defineProperty(Messaging.prototype,
  'externalCampaign', {
    get: function() {
      return this.v1.externalCampaign;
    }
});

Object.defineProperty(Messaging.prototype,
  'services', {
    get: function() {
      return this.v1.services;
    }
});

Object.defineProperty(Messaging.prototype,
  'tollfreeVerifications', {
    get: function() {
      return this.v1.tollfreeVerifications;
    }
});

Object.defineProperty(Messaging.prototype,
  'usecases', {
    get: function() {
      return this.v1.usecases;
    }
});

module.exports = Messaging;
