'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var BulkCountryUpdateList;
var BulkCountryUpdatePage;
var BulkCountryUpdateInstance;

/* jshint ignore:start */
/**
 * Initialize the BulkCountryUpdateList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateList
 *
 * @param {Twilio.Voice.V1} version - Version of the resource
 */
/* jshint ignore:end */
BulkCountryUpdateList = function BulkCountryUpdateList(version) {
  /* jshint ignore:start */
  /**
   * @function bulkCountryUpdates
   * @memberof Twilio.Voice.V1.DialingPermissionsContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateContext}
   */
  /* jshint ignore:end */
  function BulkCountryUpdateListInstance(sid) {
    return BulkCountryUpdateListInstance.get(sid);
  }

  BulkCountryUpdateListInstance._version = version;
  // Path Solution
  BulkCountryUpdateListInstance._solution = {};
  BulkCountryUpdateListInstance._uri = `/DialingPermissions/BulkCountryUpdates`;
  /* jshint ignore:start */
  /**
   * create a BulkCountryUpdateInstance
   *
   * @function create
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.updateRequest - URL encoded JSON array of update objects
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed BulkCountryUpdateInstance
   */
  /* jshint ignore:end */
  BulkCountryUpdateListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['updateRequest'])) {
      throw new Error('Required parameter "opts[\'updateRequest\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'UpdateRequest': _.get(opts, 'updateRequest')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new BulkCountryUpdateInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  BulkCountryUpdateListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  BulkCountryUpdateListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return BulkCountryUpdateListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the BulkCountryUpdatePage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdatePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {BulkCountryUpdateSolution} solution - Path solution
 *
 * @returns BulkCountryUpdatePage
 */
/* jshint ignore:end */
BulkCountryUpdatePage = function BulkCountryUpdatePage(version, response,
                                                        solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(BulkCountryUpdatePage.prototype, Page.prototype);
BulkCountryUpdatePage.prototype.constructor = BulkCountryUpdatePage;

/* jshint ignore:start */
/**
 * Build an instance of BulkCountryUpdateInstance
 *
 * @function getInstance
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdatePage#
 *
 * @param {BulkCountryUpdatePayload} payload - Payload response from the API
 *
 * @returns BulkCountryUpdateInstance
 */
/* jshint ignore:end */
BulkCountryUpdatePage.prototype.getInstance = function getInstance(payload) {
  return new BulkCountryUpdateInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdatePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
BulkCountryUpdatePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BulkCountryUpdatePage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the BulkCountryUpdateContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateInstance
 *
 * @property {number} updateCount - The number of countries updated
 * @property {string} updateRequest - A URL encoded JSON array of update objects
 *
 * @param {V1} version - Version of the resource
 * @param {BulkCountryUpdatePayload} payload - The instance payload
 */
/* jshint ignore:end */
BulkCountryUpdateInstance = function BulkCountryUpdateInstance(version, payload)
    {
  this._version = version;

  // Marshaled Properties
  this.updateCount = deserialize.integer(payload.update_count); // jshint ignore:line
  this.updateRequest = payload.update_request; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.DialingPermissionsContext.BulkCountryUpdateInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
BulkCountryUpdateInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

BulkCountryUpdateInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  BulkCountryUpdateList: BulkCountryUpdateList,
  BulkCountryUpdatePage: BulkCountryUpdatePage,
  BulkCountryUpdateInstance: BulkCountryUpdateInstance
};
