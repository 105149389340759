'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var RecordingRulesList;
var RecordingRulesPage;
var RecordingRulesInstance;

/* jshint ignore:start */
/**
 * Initialize the RecordingRulesList
 *
 * @constructor Twilio.Video.V1.RoomContext.RecordingRulesList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 * @param {string} roomSid - The SID of the Room resource for the Recording Rules
 */
/* jshint ignore:end */
RecordingRulesList = function RecordingRulesList(version, roomSid) {
  /* jshint ignore:start */
  /**
   * @function recordingRules
   * @memberof Twilio.Video.V1.RoomContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.RoomContext.RecordingRulesContext}
   */
  /* jshint ignore:end */
  function RecordingRulesListInstance(sid) {
    return RecordingRulesListInstance.get(sid);
  }

  RecordingRulesListInstance._version = version;
  // Path Solution
  RecordingRulesListInstance._solution = {roomSid: roomSid};
  RecordingRulesListInstance._uri = `/Rooms/${roomSid}/RecordingRules`;
  /* jshint ignore:start */
  /**
   * fetch a RecordingRulesInstance
   *
   * @function fetch
   * @memberof Twilio.Video.V1.RoomContext.RecordingRulesList#
   *
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed RecordingRulesInstance
   */
  /* jshint ignore:end */
  RecordingRulesListInstance.fetch = function fetch(callback) {
    var deferred = Q.defer();
    var promise = this._version.fetch({uri: this._uri, method: 'GET'});

    promise = promise.then(function(payload) {
      deferred.resolve(new RecordingRulesInstance(this._version, payload, this._solution.roomSid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * update a RecordingRulesInstance
   *
   * @function update
   * @memberof Twilio.Video.V1.RoomContext.RecordingRulesList#
   *
   * @param {object} [opts] - Options for request
   * @param {object} [opts.rules] - A JSON-encoded array of recording rules
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed RecordingRulesInstance
   */
  /* jshint ignore:end */
  RecordingRulesListInstance.update = function update(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({'Rules': serialize.object(_.get(opts, 'rules'))});

    var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new RecordingRulesInstance(this._version, payload, this._solution.roomSid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.RoomContext.RecordingRulesList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  RecordingRulesListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  RecordingRulesListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return RecordingRulesListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the RecordingRulesPage
 *
 * @constructor Twilio.Video.V1.RoomContext.RecordingRulesPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {RecordingRulesSolution} solution - Path solution
 *
 * @returns RecordingRulesPage
 */
/* jshint ignore:end */
RecordingRulesPage = function RecordingRulesPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(RecordingRulesPage.prototype, Page.prototype);
RecordingRulesPage.prototype.constructor = RecordingRulesPage;

/* jshint ignore:start */
/**
 * Build an instance of RecordingRulesInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.RoomContext.RecordingRulesPage#
 *
 * @param {RecordingRulesPayload} payload - Payload response from the API
 *
 * @returns RecordingRulesInstance
 */
/* jshint ignore:end */
RecordingRulesPage.prototype.getInstance = function getInstance(payload) {
  return new RecordingRulesInstance(this._version, payload, this._solution.roomSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.RecordingRulesPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
RecordingRulesPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RecordingRulesPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the RecordingRulesContext
 *
 * @constructor Twilio.Video.V1.RoomContext.RecordingRulesInstance
 *
 * @property {string} roomSid -
 *          The SID of the Room resource for the Recording Rules
 * @property {string} rules -
 *          A collection of recording Rules that describe how to include or exclude matching tracks for recording
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 *
 * @param {V1} version - Version of the resource
 * @param {RecordingRulesPayload} payload - The instance payload
 * @param {sid} roomSid - The SID of the Room resource for the Recording Rules
 */
/* jshint ignore:end */
RecordingRulesInstance = function RecordingRulesInstance(version, payload,
                                                          roomSid) {
  this._version = version;

  // Marshaled Properties
  this.roomSid = payload.room_sid; // jshint ignore:line
  this.rules = payload.rules; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {roomSid: roomSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.RecordingRulesInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
RecordingRulesInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

RecordingRulesInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  RecordingRulesList: RecordingRulesList,
  RecordingRulesPage: RecordingRulesPage,
  RecordingRulesInstance: RecordingRulesInstance
};
