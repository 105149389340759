import React, { Component } from "react";
import {
  View,
  TextInput,
  Text,
  Image,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
  AsyncStorage
} from "react-native";
import { Button, Form } from "native-base";
import BackButton from "./../components/backButton";
import { connect } from "react-redux";
import Toast from "./../components/toast";
import { addCard, adduser, addToken } from "./../store/actions";
import {
  MERCHANT_CODE,
  MERCHANT_CODE_TEST,
  PUBLISHABLE_KEY,
  PUBLISHABLE_KEY_TEST,
  CHECKOUT_PUB_KEY,
  SQUARE_URL,
  SQUARE_APP_ID
} from "./../constants/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, createToken } from "@stripe/stripe-js";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import { performRequest } from "./../services/apiHandler";
import CheckoutForm, {
  CardNumberFrame,
  CVVFrame,
  ExpiryDateFrame,
} from "checkout-frames-react";
import i18n from "../i18n";
import DigiWallet from './../components/digiWalletStripe'
import Icon from "./../components/Warning";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';

class CheckoutFormStripe extends React.Component {
  state = {
    saveCard : true
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;

    if (elements == null) {
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if(error){
      this.props.throwMessageFn(error.message, "red");
      return;
    }else{
      console.log(error, paymentMethod, "jjjj")

      this.props.addCard(
        paymentMethod.card.last4,
        paymentMethod.card.exp_year,
        paymentMethod.card.exp_month,
        "dummy",
        "stripe",
        paymentMethod.id,
         "",// this.state.saveCard ? response.token.card : "",
        this.state.saveCard,
        "",
        paymentMethod.card.brand,
        stripe
      );
      this.props.navigation.navigate("CheckoutScreen");
    }
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <View
        style={{
          border: "1px solid grey",
          justifyContent: "center",
          height: 35,
        }}
      >
        <CardElement 
        options={{
          style: {
            base: {
              fontSize: "20px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
        />
        </View>
        <View
        style={{
          height: 35,
          alignItems:"flex-end",
          marginTop:2
        }}
      >
        <Image 
        style={{ width: 90, height: 20 }}
                    source={require("./../../assets/stripe.png")}
                    />
          </View>
        <View style={styles.checkboxContainer}>
        <CheckBox
          value={this.state.saveCard}
          onValueChange={(setSelection) =>
            this.setState({ saveCard: !this.state.saveCard })
          }
          style={styles.checkbox}
        />
        <StyledText style={styles.label}>Save card for future payments</StyledText>
      </View>
      <View style={{ height: 20 }} />
      <button
      type="submit"
        style={{
          marginTop: 10,
          backgroundColor: this.props.design.top_buttons_background_color,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height:40,
          border:0

        }}
        // onPress={this.handleSubmit}
      >
        <StyledText style={{ color: this.props.design.top_buttons_text_color , fontFamily: 'SecondaryFont'}}>
          {" "}
          {i18n.t("order.add_card")}
        </StyledText>
      </button>

        {/* <button type="submit" disabled={!stripe}>
          Pay
        </button> */}
      </form>
    );
  }
}

class PaymentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      cvv: "",
      month: "",
      year: "",
      error_message: "",
      message_status: "",
      message_display: "none",
      card: "",
      card: "",
      saveCard: true,
      cards: [],
      payButtonDisabled: true,
      total: 0,
      squarePayLoading: true
    };
    this.card;

    if (
      this.props.config.payment &&
      (this.props.config.payment.service == "stripe" || 
      this.props.config.payment.service == "my menu pay"
      )
    ) {
      this.stripePromise = loadStripe(this.props.config.payment.key);
  
    }
  }
  componentDidMount() {

      if (this.props.config.payment.service == "stripe" || 
      this.props.config.payment.service == "my menu pay") {
        performRequest("get", `api/v2/pwa/user/cards/?service=${this.props.config.payment?.id}`)
        .then((response) => {
          this.setState({ cards: response.data });
        })
        .catch((error) => {
          this.props.adduser("");
          this.props.addToken("");
          // this.props.navigation.navigate('MobileForm')
        });
      } 
      if (this.props.config.payment.service == "square pay") {
        this.squarePayCard()
        performRequest("get", `api/v2/pwa/user/cards/?service=${this.props.config.payment?.id}`)
        .then((response) => {
          this.setState({ cards: response.data });
        })
        .catch((error) => {
          this.props.adduser("");
          this.props.addToken("");
          // this.props.navigation.navigate('MobileForm')
        });
      }

    // }
  }
  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };

  handleSubmit = async (event) => {
    console.log(event,this.stripePromise,this.props.elements, "eeee")
    
    // const p = (await this.stripePromise).createPaymentMethod(this.cardref).then(function(result) {
      // Handle result.error or result.paymentMethod
    //   console.log(result, "rrrrr")
    // });
    const token = (await this.stripePromise)
      .createToken(this.cardref)
      .then((response) => {
        console.log(response, "rrrrrr",this.cardref)
        if (response.error) {
          this.throwMessageFn(response.error.message, "red");
          return;
        }
        this.props.addCard(
          response.token.card.last4,
          response.token.card.exp_year,
          response.token.card.exp_month,
          "dummy",
          "stripe",
          response.token.id,
          this.state.saveCard ? response.token.card : "",
          this.state.saveCard,
          "",
          response.token.card.brand,
        );
        this.props.navigation.navigate("CheckoutScreen");
      });
  };

  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };
  onChange = (e) => {
    let formattedText = e.split(" ").join("");
    if (formattedText.length > 0) {
      formattedText = formattedText.match(new RegExp(".{1,4}", "g")).join(" ");
    }
    this.setState({ cardNumber: formattedText });
    // this.setState({ cardNumber: e });
  };

  onChangeCvv = (cvv) => {
    this.setState({ cvv: cvv });
  };
  onChangeMonth = (month) => {
    this.setState({ month: month });
  };
  onChangeYear = (year) => {
    this.setState({ year: year });
  };

  submitCard = () => {
    const { cvv, year, month, cardNumber } = this.state;
    if (!cvv || !year || !month || !cardNumber) {
      this.throwMessageFn("Fill all card details", "red");
      return;
    }

    this.props.addCard(cardNumber, year, month, cvv, "2checkout");
    this.props.navigation.navigate("CheckoutScreen");
  };

  twoCheckoutCard = () => (
    <View style={{ width: "90%", alignSelf: "center", marginTop: 80 }}>
      <StyledText style={{ fontSize:  this.props.design.custom_font.body_size ?? 10, marginBottom: 4, fontFamily:'SecondaryFont' }}>Card Number</StyledText>
      <TextInput
        keyboardType="phone-pad"
        maxLength={16}
        maxLength={19}
        placeholder={"XXXX-XXXX-XXXX-XXXX"}
        placeholderStyle={{ fontSize: this.props.design.custom_font.body_size ?? 13 }}
        placeholderTextColor={"#b4b7b9"}
        value={this.state.cardNumber}
        onChangeText={(e) => this.onChange(e)}
        style={{
          fontSize: this.props.design.custom_font.sub_heading_size ?? 20,
          // backgroundColor:'grey',
          height: 35,
          border: "1px solid grey", 
          // fontFamily: 'SecondaryFont'
        }}
      />
      {this.state.cardNumber.startsWith("4") ? (
        <Image
          source={require("../../assets/visa.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("5") ? (
        <Image
          source={require("../../assets/master.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("6") ? (
        <Image
          source={require("../../assets/discover.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : this.state.cardNumber.startsWith("2") ? (
        <Image
          source={require("../../assets/jcb.png")}
          style={{
            width: 35,
            height: 25,
            position: "absolute",
            right: 5,
            top: 20,
          }}
        />
      ) : null}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <View style={{ flex: 2, flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
            <StyledText style={{ fontSize: this.props.design.custom_font.body_size ?? 10, marginBottom: 4, fontFamily: 'SecondaryFont' }}>Exp. Month</StyledText>

            <TextInput
              keyboardType="phone-pad"
              onChangeText={this.onChangeMonth}
              placeholder={"MM"}
              maxLength={2}
              value={this.state.month}
              placeholderStyle={{ fontSize: this.props.design.custom_font.sub_heading_size ?? 13 }}
              placeholderTextColor={"#b4b7b9"}
              style={{
                fontSize: this.props.design.custom_font.heading_size ?? 20,
                // backgroundColor:'grey',
                height: 35,
                border: "1px solid grey",
                width: "95%",
                alignSelf: "flex-start",
                //  fontFamily: 'SecondaryFont'
              }}
            />
          </View>

          <View style={{ width: "50%", alignSelf: "flex-end" }}>
            <StyledText style={{ fontSize: 10, marginBottom: 4, fontFamily: 'SecondaryFont' }}>Exp. Year</StyledText>

            <TextInput
              keyboardType="phone-pad"
              onChangeText={this.onChangeYear}
              placeholder={"YYYY"}
              maxLength={4}
              value={this.state.year}
              placeholderStyle={{ fontSize: 13 }}
              placeholderTextColor={"#b4b7b9"}
              style={{
                fontSize: 20,
                // backgroundColor:'grey',
                height: 35,
                border: "1px solid grey",
                width: "95%",
                //  fontFamily: 'SecondaryFont'
              }}
            />
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <StyledText style={{ fontSize: 10, marginBottom: 4, marginLeft: 30, fontFamily: 'SecondaryFont' }}>
            cvv
          </StyledText>

          <TextInput
            keyboardType="phone-pad"
            maxLength={3}
            value={this.state.cvv}
            onChangeText={this.onChangeCvv}
            placeholder={"CVV"}
            placeholderStyle={{ fontSize: 13 }}
            placeholderTextColor={"#b4b7b9"}
            style={{
              fontSize: 20,
              // backgroundColor:'grey',
              height: 35,
              border: "1px solid grey",
              width: "80%",
              alignSelf: "flex-end",
              //  fontFamily: 'SecondaryFont'
            }}
          />
        </View>
      </View>
      <View style={{ height: 20 }} />
      <Button
        style={{
          marginTop: 10,
          backgroundColor: this.props.design.top_buttons_background_color,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",

        }}
        onPress={() => this.submitCard()}
      >
        <StyledText style={{ color: this.props.design.top_buttons_text_color, fontFamily: 'SecondaryFont' }}>
          {" "}
          {i18n.t("order.add_card")}
        </StyledText>
      </Button>
    </View>
  );
   

  
  stripeCard = () => (
    <View style={{ width: "90%", alignSelf: "center", marginTop: 80 }}>
        <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {({stripe, elements}) => (
                <CheckoutFormStripe throwMessageFn={this.throwMessageFn} navigation={this.props.navigation} addCard={this.props.addCard} design={this.props.design} config={this.props.config} stripe={stripe} elements={elements} />
          )}
        </ElementsConsumer>
        </Elements>
    </View>
  );
  onCardValidationChanged = (valid) => {
    this.setState({ payButtonDisabled: !valid });
  };
  onCardTokenized = (token) => {
    if (!token) {
      this.throwMessageFn("Something went wrong", "red");
      return;
    }
    this.props.addCard(
      "XXXX-XXXX-XXXX-XXXX",
      "dummy",
      "dummy",
      "dummy",
      "stripe",
      token,
      false,
      false
    );
    this.props.navigation.navigate("CheckoutScreen");
  };

  checkoutCard = () => {
    return (
      <View style={{ width: "90%", alignSelf: "center", marginTop: 80 }}>
        <CheckoutForm
          publicKey={CHECKOUT_PUB_KEY}
          onCardValidationChanged={this.onCardValidationChanged}
          onCardTokenized={this.onCardTokenized}
        >
          <StyledText style={{ fontSize: this.props.design.custom_font.body_size ?? 10, marginBottom: 4, fontFamily: 'SecondaryFont' }}>Card Number</StyledText>
          <CardNumberFrame
            placeholder={"Card Number"}
            placeholderStyle={{ fontSize: 13 }}
            placeholderTextColor={"#b4b7b9"}
            style={{
              fontSize: this.props.design.custom_font.heading_size ?? 20,
              // backgroundColor:'grey',
              height: 35,
              border: "1px solid grey",
              alignSelf: "flex-start",
            }}
          />

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <View style={{ flex: 2 }}>
              <StyledText style={{ fontSize:  this.props.design.custom_font.body_size ?? 10, marginBottom: 4, fontFamily: 'SecondaryFont' }}>Exp. Date</StyledText>

              <ExpiryDateFrame
                placeholder={"Exp. Date"}
                placeholderStyle={{ fontSize: this.props.design.custom_font.sub_heading_size ?? 13 }}
                placeholderTextColor={"#b4b7b9"}
                style={{
                  fontSize: this.props.design.custom_font.sub_heading_size ?? 20,
                  // backgroundColor:'grey',
                  height: 35,
                  border: "1px solid grey",
                  alignSelf: "flex-start",
                }}
              />
            </View>
            <View style={{ flex: 0.5 }} />
            <View style={{ flex: 2 }}>
              <StyledText style={{ fontSize: this.props.design.custom_font.body_size ?? 10, marginBottom: 4, fontFamily: 'SecondaryFont' }}>cvv</StyledText>
              <CVVFrame
                placeholder={"CVV"}
                placeholderStyle={{ fontSize: this.props.design.custom_font.sub_heading_size ?? 13 }}
                placeholderTextColor={"#b4b7b9"}
                style={{
                  fontSize: this.props.design.custom_font.sub_heading_size ?? 20,
                  // backgroundColor:'grey',
                  height: 35,
                  border: "1px solid grey",
                  alignSelf: "flex-start",
                }}
              />
            </View>
          </View>
          <View style={{ height: 20 }} />
   
          <button
            style={{
              marginTop: 10,
              backgroundColor: this.props.design.top_buttons_background_color,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRadius: 5,
              borderWidth: 1,
              boxShadow: "none",
              height: 45,
            }}
            type="submit"
            disabled={this.state.payButtonDisabled}
          >
            <StyledText style={{ color: this.props.design.top_buttons_text_color , fontFamily: 'SecondaryFont'}}>
              {i18n.t("order.add_card")}
            </StyledText>
          </button>
        </CheckoutForm>
      </View>
    );
  };

  squareTokanizeCard = async() => {
    this.setState({squarePayLoading: true})
    try {

      const result = await this.card.tokenize();

      if (result.status === 'OK') {
        if(result && result.details && result.details.card){
          
          const {brand, expMonth, expYear, last4} = result.details.card;
          let token = await AsyncStorage.getItem('token')
          if(token && this.state.saveCard){
            performRequest("post", "/api/v2/pwa/square/card/save/", {
              pay_id: this.props.config.payment.id,
              token: result.token
            })
            .then(response => {
              this.props.addCard(
                last4,
                "dummy",
                "dummy",
                "dummy",
                "square pay",
                response.data.data.card_id,
                "",
                false,
                true,
                brand
              );
              this.setState({squarePayLoading: false}, () => {
                this.props.navigation.navigate("CheckoutScreen");

              })
            })
            .catch(err => {
              this.setState({squarePayLoading: false})
              this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
            })
          }else{
            this.props.addCard(
              last4,
              expYear,
              expMonth,
              "dummy",
              "square pay",
              result.token,
              true,
              true,
              "",
              brand,
            );
            this.setState({squarePayLoading: false}, () => {
              this.props.navigation.navigate("CheckoutScreen");

            })
          }
        
        }
        console.log(`Payment token is ${result.token}`, result);
        
      }else{
        this.setState({squarePayLoading: false})
        this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
      }

    } catch (e) {
      this.setState({squarePayLoading: false})

      this.throwMessageFn(i18n.t("labels.transaction_failed"), "red");
      console.error(e);

    }
  }

  squarePayCard = async() => {
 
    let sqPaymentScript = document.createElement('script');
    sqPaymentScript.src = SQUARE_URL;
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    sqPaymentScript.onload = async()=>{
      const payments = window.Square.payments(SQUARE_APP_ID, this.props.config.payment.locationId);
      this.card = await payments.card();
      await this.card.attach('#card');
      this.setState({squarePayLoading:false})
    }





  }
  cardLoader = () => {
    if (this.props.config.payment.service == "stripe" || 
    this.props.config.payment.service == "my menu pay") {
      return this.stripeCard();
    } else  if (this.props.config.payment.service == "square pay") {
      return <>
            <View style={{marginTop:50}} nativeID="card" ></View>
            {
              this.props.user ?
              <View style={[styles.checkboxContainer, {alignSelf:"center"}]}>
              <CheckBox
                value={this.state.saveCard}
                onValueChange={(setSelection) =>
                  this.setState({ saveCard: !this.state.saveCard })
                }
                style={styles.checkbox}
              />
              <StyledText style={styles.label}>Save card for future payments</StyledText>
            </View>
            :
            <View/>

            }

            <Button
              disabled={this.state.squarePayLoading}
              style={{
                marginTop: 5,
                backgroundColor: this.props.design.top_buttons_background_color,
                alignItems: "center",
                justifyContent: "center",
                width: 301,
                alignSelf:"center"
      
              }}
              onPress={() => this.squareTokanizeCard()}
            >
              {
                this.state.squarePayLoading ? 
                <i style={{color: this.props.design.top_buttons_text_color, fontSize:25}} class="fas fa-spinner fa-spin"></i>
                :
              <StyledText style={{ color: this.props.design.top_buttons_text_color, fontFamily: 'SecondaryFont' }}>
                {" "}
                {i18n.t("labels.submit")}
              </StyledText>
              }
              
            </Button>
      </>
    } 
    else {
      return this.checkoutCard();
    }
  };
  selectCard = (item) => {
    this.props.addCard(
      item.last_four_digits,
      "dummy",
      "dummy",
      "dummy",
      "stripe",
      item.id,
      "",
      false,
      true,
      item.card_scheme
    );
    this.props.navigation.navigate("CheckoutScreen");
  };

  //Square Pay - Remove Card
  deleteSavedCard = (item) => {         
      performRequest("post", "/api/v2/pwa/card/delete", {
      pay_id: this.props.config.payment?.id,
      card_id: item.id
    })
    .then(response => { 
      if(response.data.status == "success"){
        this.throwMessageFn(i18n.t("labels.card_deleted"), "green");
        let filteredCardList = this.state.cards.filter((e) => e.id != item.id);              
        this.setState({cards: filteredCardList});
      } else {
        this.throwMessageFn(i18n.t("labels.card_deleted_error"), "red");
      }   
    })
  }


  render() {
    const { cardNumber, cvv, month, year } = this.state;
    return (
      <View>
        <View style={{marginTop:50}} />

          {/* <View
            style={{ width: "90%", alignSelf: "center", }}
          >
            <Elements stripe={this.stripePromise}>
                <DigiWallet
                paymentConfigured = {this.props.config.payment}
                completeOnlinePayment={this.props.navigation.state.params.completeOnlinePayment}
                throwMessageFn={this.throwMessageFn}
                  service_id={this.props.config.payment
                    ? this.props.config.payment.id
                    : ""}
                  venue={this.props.config.venueName}
                  platform={this.props.config.platform}
                  currency={this.props.config.currency_code.toLowerCase()}
                  country={this.props.config.country.cca2}
                  total={this.props.navigation.state ? this.props.navigation.state.params ? this.props.navigation.state.params.total : 0 : 0}
                />
          </Elements>
        </View> */}
      {this.state.cards.length > 0 && (
          <View
            style={{ width: "90%", alignSelf: "center", marginVertical: 20 }}
          >

          <View style={{marginBottom:20, }} />
          {/* <View style={{border:'2px solid lightgrey'}} /> */}
          {/* <View style={{marginBottom:10,}} /> */}

 
            <StyledText style={{fontFamily:'SecondaryFont'}}>Saved Cards</StyledText>
            <View style={{ marginBottom: 10 }} />
            {this.state.cards.map((item) => (
              <TouchableOpacity onPress={() => this.selectCard(item)}>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "black",
                    borderRadius: 8,
                    width: "100%",
                    height: 50,
                    marginBottom: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    
                  }}
                >
                  <StyledText style={{fontFamily: 'SecondaryFont', marginLeft:"12px"}}>XXXX-XXXX-XXXX-{item.last_four_digits}</StyledText>
                  <StyledText style={{fontFamily: 'SecondaryFont', width:"100px", textAlign:"center"}}>{item.card_scheme}</StyledText>
                  <StyledText style={{fontFamily: 'SecondaryFont'}}>{item.expires_on}</StyledText>


                  <TouchableOpacity onPress={() => this.deleteSavedCard(item)}>
                        <Icon
                          name={"trash"}
                          color={"red"}
                          size={25}
                          style={{ marginRight: 7 }}
                        />
                      </TouchableOpacity>
                </View>

               
              </TouchableOpacity>
            ))}
          </View>
        )}





        {this.props.config.payment ? this.cardLoader() : this.checkoutCard()}

        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => this.props.navigation.goBack()}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_buttons_border_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>

        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          custom_font={this.props.design.custom_font}
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    //   marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
    fontFamily: 'SecondaryFont'
  },
});
const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    platform: state.config.platform,
    design: state.config.design,
    user: state.number.user,
  };
};
export default connect(mapStateToProps, { addCard, adduser, addToken })(
  PaymentScreen
);

