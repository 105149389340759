'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SourceIpMappingList;
var SourceIpMappingPage;
var SourceIpMappingInstance;
var SourceIpMappingContext;

/* jshint ignore:start */
/**
 * Initialize the SourceIpMappingList
 *
 * @constructor Twilio.Voice.V1.SourceIpMappingList
 *
 * @param {Twilio.Voice.V1} version - Version of the resource
 */
/* jshint ignore:end */
SourceIpMappingList = function SourceIpMappingList(version) {
  /* jshint ignore:start */
  /**
   * @function sourceIpMappings
   * @memberof Twilio.Voice.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Voice.V1.SourceIpMappingContext}
   */
  /* jshint ignore:end */
  function SourceIpMappingListInstance(sid) {
    return SourceIpMappingListInstance.get(sid);
  }

  SourceIpMappingListInstance._version = version;
  // Path Solution
  SourceIpMappingListInstance._solution = {};
  SourceIpMappingListInstance._uri = `/SourceIpMappings`;
  /* jshint ignore:start */
  /**
   * create a SourceIpMappingInstance
   *
   * @function create
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.ipRecordSid -
   *          The unique string that identifies an IP Record
   * @param {string} opts.sipDomainSid -
   *          The unique string that identifies a SIP Domain
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SourceIpMappingInstance
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['ipRecordSid'])) {
      throw new Error('Required parameter "opts[\'ipRecordSid\']" missing.');
    }
    if (_.isUndefined(opts['sipDomainSid'])) {
      throw new Error('Required parameter "opts[\'sipDomainSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'IpRecordSid': _.get(opts, 'ipRecordSid'),
      'SipDomainSid': _.get(opts, 'sipDomainSid')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SourceIpMappingInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams SourceIpMappingInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists SourceIpMappingInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of SourceIpMappingInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SourceIpMappingPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of SourceIpMappingInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new SourceIpMappingPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a source_ip_mapping
   *
   * @function get
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Voice.V1.SourceIpMappingContext}
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.get = function get(sid) {
    return new SourceIpMappingContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Voice.V1.SourceIpMappingList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SourceIpMappingListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SourceIpMappingListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return SourceIpMappingListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SourceIpMappingPage
 *
 * @constructor Twilio.Voice.V1.SourceIpMappingPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SourceIpMappingSolution} solution - Path solution
 *
 * @returns SourceIpMappingPage
 */
/* jshint ignore:end */
SourceIpMappingPage = function SourceIpMappingPage(version, response, solution)
                                                    {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SourceIpMappingPage.prototype, Page.prototype);
SourceIpMappingPage.prototype.constructor = SourceIpMappingPage;

/* jshint ignore:start */
/**
 * Build an instance of SourceIpMappingInstance
 *
 * @function getInstance
 * @memberof Twilio.Voice.V1.SourceIpMappingPage#
 *
 * @param {SourceIpMappingPayload} payload - Payload response from the API
 *
 * @returns SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingPage.prototype.getInstance = function getInstance(payload) {
  return new SourceIpMappingInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.SourceIpMappingPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SourceIpMappingPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SourceIpMappingPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SourceIpMappingContext
 *
 * @constructor Twilio.Voice.V1.SourceIpMappingInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} ipRecordSid - The unique string that identifies an IP Record
 * @property {string} sipDomainSid - The unique string that identifies a SIP Domain
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT that the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT that the resource was last updated
 * @property {string} url - The absolute URL of the resource
 *
 * @param {V1} version - Version of the resource
 * @param {SourceIpMappingPayload} payload - The instance payload
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
SourceIpMappingInstance = function SourceIpMappingInstance(version, payload,
                                                            sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.ipRecordSid = payload.ip_record_sid; // jshint ignore:line
  this.sipDomainSid = payload.sip_domain_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(SourceIpMappingInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SourceIpMappingContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a SourceIpMappingInstance
 *
 * @function fetch
 * @memberof Twilio.Voice.V1.SourceIpMappingInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a SourceIpMappingInstance
 *
 * @function update
 * @memberof Twilio.Voice.V1.SourceIpMappingInstance#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.sipDomainSid -
 *          The unique string that identifies a SIP Domain
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a SourceIpMappingInstance
 *
 * @function remove
 * @memberof Twilio.Voice.V1.SourceIpMappingInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.SourceIpMappingInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SourceIpMappingInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SourceIpMappingInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SourceIpMappingContext
 *
 * @constructor Twilio.Voice.V1.SourceIpMappingContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
SourceIpMappingContext = function SourceIpMappingContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/SourceIpMappings/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a SourceIpMappingInstance
 *
 * @function fetch
 * @memberof Twilio.Voice.V1.SourceIpMappingContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SourceIpMappingInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a SourceIpMappingInstance
 *
 * @function update
 * @memberof Twilio.Voice.V1.SourceIpMappingContext#
 *
 * @param {object} opts - Options for request
 * @param {string} opts.sipDomainSid -
 *          The unique string that identifies a SIP Domain
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['sipDomainSid'])) {
    throw new Error('Required parameter "opts[\'sipDomainSid\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({'SipDomainSid': _.get(opts, 'sipDomainSid')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SourceIpMappingInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a SourceIpMappingInstance
 *
 * @function remove
 * @memberof Twilio.Voice.V1.SourceIpMappingContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SourceIpMappingInstance
 */
/* jshint ignore:end */
SourceIpMappingContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Voice.V1.SourceIpMappingContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SourceIpMappingContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SourceIpMappingContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SourceIpMappingList: SourceIpMappingList,
  SourceIpMappingPage: SourceIpMappingPage,
  SourceIpMappingInstance: SourceIpMappingInstance,
  SourceIpMappingContext: SourceIpMappingContext
};
