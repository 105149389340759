import React, { Component } from "react";
import Navigation from "./src/navigation/index";
import OnlineNavigation from "./src/navigation/online_navigation";
import EntNavigation from "./src/navigation/enterprise";
import {Launcher} from './chat'
import {connectToMyMenuSocket, connectToMyMenuSocketIo} from './src/constants/chatSocket'
import axios from "axios/index";
import { connect } from "react-redux";
import {
  addMenu,
  addConfig,
  addLanguage,
  addDefaultLanguage,
  addTable,
  getModifiers,
  selectMenu,
  resetCart,
  addChildren,
  addOnlineChildren,
  setPlatform,
  adduser,
  addToken,
  addCampaign,
  setVenues,
  setDesign,
  selectMenuData,
  isLoading,
  addMessage,
  allowOrdering,
  addPrimaryFontFace,
  addSecondaryFontFace,
  totalPrice,
  allCart,
  updateCartCount
} from "./src/store/actions/index";
import * as Localization from "expo-localization";
import { API_URL, TESTING_PLATFORM, DEFAULT_PRIMARY_FONT_URL, DEFAULT_SECONDARY_FONT_URL } from "./src/constants";
import { AsyncStorage, View, Text, Dimensions, Image } from "react-native";
import FallbackScreen from "./src/screens/fallbackScreen";
import { performRequest } from "./src/services/apiHandler";
import Constants from "expo-constants";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import NoMenuNavigation from "./src/navigation/no_menu";
import i18n from "./src/i18n";
import ReactPixel from 'react-facebook-pixel';
import {AvailabilityHandler} from './src/handlers/utils'
import './fonts/Lato-Regular.ttf';
import './fonts/Muli.ttf';

import {FontLetterSpacing} from './src/handlers/utils';



const { height, width } = Dimensions.get("window");
window.resizeTo(width / 2, height);

class InitialRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      noID: false,
      isError: false,
      errorStatus: "",
      venue_name: "",
      platform: "qr",
      chatWindowOpen: false,
      messages: [],
      newMessageCount: 0,
      isMessageLoading: false,
      uuid: ""
    };
    this.socket
    this.launcher = React.createRef()

  }
  setTrigger = () => {
    setInterval(() => {

      let data = {
        "type": "trigger",
      }
      this.socket.send(JSON.stringify({action:"sendmessage",data}));
    }, 400000);

  }

  
  connectToFbPixel = (config, uuid, platform) => {
    // if(config.venue == "6791"){
    //   const options = {
    //     autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    //     debug: false, // enable logs
    //   };
    //   ReactPixel.init('594918641561959', options);
      
    //   ReactPixel.pageView(); // For tracking page view
    //   // ReactPixel.track(event, ); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    //   // ReactPixel.trackSingle('594918641561959', event, ); // For tracking default events.
    //   // ReactPixel.trackCustom(event, ); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    //   // ReactPixel.trackSingleCustom('594918641561959', event, ); // For tracking custom events.
    // }else{

      // axios.get(API_URL + "api/pwa/pixel/" + uuid + '/', { uuid })
      performRequest("get", `${API_URL}api/pwa/pixel/${uuid}/?platform=${platform}&uuid=${uuid}`, {}, false)
      .then((response) => {
        if(response.data.facebook){
          const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
          };
          ReactPixel.init(response.data.facebook, options);
          ReactPixel.pageView(); // For tracking page view
        }
      }).catch(err=>{
        console.log(err, "errrrrrrrr")
      })
    // }
  }


  connectToSocket = (config, uuid) => {
    let venue_id = config.venue
    if(Array.isArray(config)){
      let delivery_config = config.find((item) => item.platform == "delivery");
      venue_id = delivery_config.venue
    }
    let data = {
        "venue": venue_id,
        "user_id": Constants.deviceId,
        "platform": "guest",
        "uuid": uuid
    }
    // this.socket = connectToMyMenuSocketIo(data)
    // this.socket.on("connect", (event) => {
    //   console.log("connectttttttttttt", event, this.socket.id)
    // })
    this.socket = connectToMyMenuSocket(data)
    // this.socket.onopen = () => {
    //   this.setTrigger()
    // }
    this.socket.onmessage = (e)=> {
      
      let data = JSON.parse(e.data)
      if(data.action && data.action == "menu_update"){
        this.props.allowOrdering(false)
        return
      }
      if(data.chatData){
        if(data.chatData.sender.type =="staff"){
          this.setState({
            newMessageCount: this.state.newMessageCount+1
          })
          this.launcher.current.checkIfNewMessage();
        }
  
        this.props.addMessage([data.chatData, ])
        
      }else{
        if(data.sender.type =="staff"){
          this.launcher.current.checkIfNewMessage();
        }
  
        this.props.addMessage([data, ])

        this.setState({
          newMessageCount: this.state.newMessageCount+1
        })
      }

  };  


  this.socket.onclose = (e) => {
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    setTimeout(() => {
      let uuid = window.location.pathname.substring(1);
      this.connectToSocket(config, uuid);
    }, 1000);
  }

}
  loadMessages = () => {
    this.setState({isMessageLoading:true})
    if(!this.state.chatWindowOpen){
      let venue_id = this.props.config.venue
      if(Array.isArray(this.props.config)){
        let delivery_config = this.props.config.find((item) => item.platform == "delivery");
        venue_id = delivery_config.venue
      }
      performRequest("get", `api/guest-messages/?venue=${venue_id}&user=${Constants.deviceId}`, null, false)
      .then(response => {
        // this.setState({messages: response.data.data})
        this.props.addMessage(response.data.data)
        this.setState({isMessageLoading: false})
      })
      .catch(error => {
        this.setState({isMessageLoading: false})
      })
    }

    this.setState({chatWindowOpen: !this.state.chatWindowOpen, newMessageCount: 0})
  }
  getAllModifiers = async (uuid, platform = "qr") => {
    axios
      .get(API_URL + `api/qr-modifiers/${uuid}/?platform=${platform}`)
      .then((response) => {
        this.props.getModifiers(response.data);
      })
      .catch((error) => {});
  };
  trackVisit = async (config) => {
    let data = {
      mid: Constants.deviceId,
      device_info: JSON.stringify(Constants.platform),
      venue: config.venue,
      user: this.props.user ? this.props.user.id : "",
    };
    axios.post(API_URL + "api/v2/pwa/track/visit", data);
  };
  getRecommendedItems = async (uuid, platform = "qr") => {
    // if(platform=='qr'){
    axios
      .get(API_URL + "api/campaigns/pwa/" + uuid + "/", { platform })
      .then((response) => {
        this.props.addCampaign(response.data);
      })
      .catch((error) => {});
    // }else{

    // }
  };
  getAnalyticsData = (uuid) => {
    axios
      .get(API_URL + "api/pwa/analytics/" + uuid+"/", { uuid })
      .then((response) => {
        Array.isArray(response.data) &&
        response.data.map((item) => {
          if (item.type && item.identifier && item.code) {
            let type = JSON.parse(item.type);
            if (type.value == 1) {
              // ReactGA.initialize(item.code);
              ReactGA.initialize(item.code, {
                debug: false,
                standardImplementation: true,
                // titleCase: false,
                gaOptions: {
                  siteSpeedSampleRate: 100,
                },
              });
              ReactGA.pageview(
                window.location.pathname + window.location.search
              );
              ReactGA.ga("send", "pageview");

            } else {
              const tagManagerArgs = {
                gtmId: item.code,
                dataLayerName: uuid,
              };
              TagManager.initialize(tagManagerArgs);
            }
          }
        });
      });
  };
  getAnalyticsDataOnline = (uuid) => {
    axios
      .get(API_URL + "api/pwa/online-analytics/" + uuid + '/', { uuid })
      .then((response) => {
        Array.isArray(response.data) &&
        response.data.map((item) => {
          if (item.type && item.identifier && item.code) {
            let type = JSON.parse(item.type);
            if (type.value == 1) {
              // ReactGA.initialize(item.code);
              ReactGA.initialize(item.code, {
                debug: false,
                standardImplementation: true,
                // titleCase: false,
                gaOptions: {
                  siteSpeedSampleRate: 100,
                },
              });
              ReactGA.pageview(
                window.location.pathname + window.location.search
              );
              ReactGA.ga("send", "pageview");
            } else {
              const tagManagerArgs = {
                gtmId: item.code,
                dataLayerName: uuid,
              };
              TagManager.initialize(tagManagerArgs);
            }
          }
        });
      });
  };

  get_data_qr = (uuid) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let theme_id = 0;
    if ("theme" in params) {
      theme_id = params["theme"];
    }

    this.getRecommendedItems(uuid);
    this.getAnalyticsData(uuid);
    this.getConfig(uuid)
      .then(async (response) => {
        this.getAllModifiers(uuid);
        await this.props.addConfig(response.data);
        axios
          .all([
            this.getMenu(uuid),
            this.getTable(uuid),
            this.getConfig(uuid),
            this.getTheme(uuid, "qr", theme_id),
            this.getCart(uuid, "qr"),
          ])
          .then(
            axios.spread(async (res_menu, table, config, theme, cart) => {
                console.log(cart.data, "cartttttttttttttttt")
                if(cart.data){
                  this.props.updateCartCount(cart.data.quantity || 0);
                  this.props.totalPrice(cart.data.total || 0);
                  this.props.allCart(cart.data.items || []);
                }
              
                await AsyncStorage.setItem("table", table.data.table);
                this.connectToSocket(config.data, uuid)
                this.connectToFbPixel(config.data, uuid, "qr")

                let menu = AvailabilityHandler([...res_menu.data])
                if ("menu" in params) {
                    let selected_menu = menu.find(
                      (item) => item.id == params["menu"]
                    );
                    if (selected_menu) {
                      this.props.selectMenuData(selected_menu);
                      this.props.selectMenu(selected_menu.id);
                      this.setState({ no_menu: true });
                      this.props.addChildren([]);
                      this.loadChildren(selected_menu, uuid, "qr")
                    }
                  }

              this.trackVisit(config.data);
              this.setState({ venue_name: config.data.displayName, table_data: table.data });
              await this.props.addMenu(menu);
              await this.props.addTable(table.data);
              this.props.setDesign(theme.data);

              let languages = [];
              if (config.data.languages) {
                languages = config.data.languages;
                languages.unshift(config.data.default_language);
              } else {
                languages.push(config.data.default_language);
              }
              this.props.addLanguage(languages);
              // this.props.addDefaultLanguage(config.data.default_language.value);
              this.setLocale(config.data);

              this.setState({ isReady: true });

              console.log(config.data, "===CONFIG DATA");
              console.log(theme, "=======theme");
              //Load Primary Font Data
              if(theme.data?.custom_font && theme.data?.custom_font.primary_font &&  theme.data?.custom_font.primary_font.indexOf("||") > -1 ) {
                    let primaryFontLoc = theme.data.custom_font.primary_font.split("||")[0];
                    this.loadCustomFont("PrimaryFont", primaryFontLoc);
              } else {
                console.log("primaryyyyyyyy")
                    this.loadCustomFont("PrimaryFont", DEFAULT_PRIMARY_FONT_URL);          
              }

              //Load Secondary Font Data
              if(theme.data?.custom_font && theme.data?.custom_font.secondary_font &&  theme.data?.custom_font.secondary_font.indexOf("||") > -1 ) {
                let secondaryFontLoc = theme.data.custom_font.secondary_font.split("||")[0];
                this.loadCustomFont("SecondaryFont", secondaryFontLoc);    
             
              } else {
                this.loadCustomFont("SecondaryFont", DEFAULT_SECONDARY_FONT_URL);               
              }

               
              
            })
          );
      })
      .catch((error) => {
        try {
          this.setState({ errorStatus: error.response.data }, () => {
            this.setState({ isError: true, isReady: true });
          });
        } catch (error) {}
      });
  };

  getCart = (uuid, platform) => {
    return performRequest("get", `api/pwa/cart?uuid=${uuid}&mid=${Constants.deviceId}&platform=${platform}`,{})
  }
  loadCustomFont = (fontName, fontLocation) => { 
    let  fontFaceObj = new FontFace(fontName, 'url(' + fontLocation + ')');
    //let _props = this.props;
    fontFaceObj.load().then(function (loadedFont) {
        document.fonts.add(loadedFont);
      
    }).catch(function (error) {
        console.log('Failed to load font: ' + fontName + error)
    })               
  }

  validateToken = async () => {
    performRequest("get", "api/v2/pwa/token/")
      .then((response) => {})
      .catch((error) => {
        AsyncStorage.removeItem("token");
        this.props.adduser("");
        this.props.addToken("");
      });
  };

  loadChildren = (item, uuid, platform) => {
    let menuid = item.id;
    this.props.isLoading();

    axios
      .get(
        API_URL +
          "api/qr-menu-children/" +
          uuid +
          "/?menu=" +
          menuid +
          "&platform=" +
          platform +
          "&limit=1500&page=" +
          1
      )
      .then((response) => {
        let datum = JSON.parse(JSON.stringify(response.data.results));

        datum.forEach((element) => {
          let recoms = [];
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
        });

        this.props.addChildren(datum);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadEntConfigs = async (uuid) => {
    this.setState({ platform: "enterprise" });
    this.props.setPlatform("enterprise");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let theme_id = 0;
    if ("theme" in params) {
      theme_id = params["theme"];
    }

    this.getRecommendedItems(uuid, "enterprise");
    this.getAllModifiers(uuid, "enterprise");
    axios
      .all([
        this.getConfig(uuid, "enterprise"),
        // this.getEntVenues(uuid),
        this.getTheme(uuid, "enterprise", theme_id),
        this.getMenu(uuid, "enterprise"),
      ])
      .then(
        axios.spread(async (config, theme, menus) => {
          this.connectToSocket(config.data, uuid)
          this.connectToFbPixel(config.data, uuid, "enterprise")

          if ("menu" in params) {
            let selected_menu = menus.data.find(
              (item) => item.id == params["menu"]
            );
            if (selected_menu) {
              this.props.selectMenuData(selected_menu);
              this.props.selectMenu(selected_menu.id);
              this.setState({ no_menu: true });
              this.props.addChildren([]);
              this.loadChildren(selected_menu, uuid, "enterprise")
            }
          }

          // this.props.setVenues(venues.data)
          await AsyncStorage.setItem("uuid", uuid);

          this.props.addMenu(menus.data);
          // track visit here =================

          // ===================
          this.props.addConfig(config.data);
          let languages = [];
          if (config.data.languages) {
            languages = config.data.languages;
            languages.unshift(config.data.default_language);
          } else {
            languages.push(config.data.default_language);
          }
          this.props.addLanguage(languages);
          // if("language" in params){
          //   let lang = config.data.languages.find(item => item.value == params['language'])
          //   if(lang){
          //       this.props.addDefaultLanguage(lang.value);
          //   }else{
          //       this.props.addDefaultLanguage(config.data.default_language.value);
          //   }

          // }else{
          //     this.props.addDefaultLanguage(config.data.default_language.value);
          // }

          this.setLocale(config.data);

          this.props.setDesign(theme.data);

          this.setState({ isReady: true });
        })
      )
      .catch((error) => {
        this.setState({ isReady: true });
      });
  };
  setLocale = (data) => {
    try {
      i18n.defaultLocale = data.default_language.value;
      this.props.addDefaultLanguage(data.default_language.value);

      for (let index = 0; index < data.languages.length; index++) {
        const element = data.languages[index];

        if (Localization.locale.split("-")[0] === element.value) {
          i18n.locale = Localization.locale.split("-")[0];
          this.props.addDefaultLanguage(i18n.locale);
        }
      }
    } catch (error) {
      i18n.defaultLocale = "en";
    }
  };

  getEntVenues = (group) => {
    return axios.get(API_URL + `api/ent/venues/${group}/`);
  };
  getTheme = (group, platform = "online", theme_id = 0) => {
    return axios.get(
      API_URL +
        `api/pwa/theme/${group}/?platform=${platform}&theme_id=${theme_id}`
    );
  };
  customDomain = () => {
    if(!window.location.hostname.startsWith("order.mydigimenu") 
    && !window.location.hostname.startsWith("order-menu.mydigimenu")
    && !window.location.hostname.startsWith("order-qa.mydigimenu") 
    ){
      return true
    }
    return false
  }
  loadContentBasedOnUUID = async (uuid) => {
   await AsyncStorage.removeItem("uuid");

    // alert(window.location.hostname);
    if (!uuid) {
      // this.setState({noID: true})
    }
    if (
      window.location.hostname.startsWith("order.mydigimenu") ||
      window.location.hostname.startsWith("order-menu.mydigimenu") ||
      window.location.hostname.startsWith("order-qa.mydigimenu") ||
      (window.location.hostname.startsWith("localhost") &&
        TESTING_PLATFORM == "online")
    ) {
      // ================= is online menu =========================

      // this.getChildrenAsyc(uuid);
      this.getAnalyticsDataOnline(uuid);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      console.log(urlSearchParams, params, "ppppppppp")

      axios
        .all([this.getOnlineConfig(uuid, params.platform|| "online"), this.getTheme(uuid, params.platform|| "online")])
        .then(
          axios.spread(async (config, theme) => {
            console.log( "9999999999999999999999")

            // await AsyncStorage.setItem("uuid", uuid);

            if(params.platform=="enterprise"){
              this.setState({ platform: "enterprise" });
              this.props.setPlatform("order_enterprise" );
        
              this.connectToSocket(config.data, uuid)
              this.connectToFbPixel(config.data, uuid, params.platform|| "enterprise")
              this.trackVisit(config.data);
              await this.props.addConfig(config.data); 
              this.props.selectMenu(config.data.menu_to_display);
              // let languages = [];
              // console.log(config.data.languages, "lannnnnnnnnnnnnnnnnn")
              // if (config.data.languages) {
              //   languages = config.data.languages;
              //   languages.unshift(config.data.default_language);
              // } else {
              //   languages.push(config.data.default_language);
              // }
              // this.props.addLanguage(languages);
              this.setLocale(config.data);            
              this.props.setDesign(theme.data);

              
              if (theme.data?.custom_font && theme.data?.custom_font.primary_font && theme.data?.custom_font.primary_font.indexOf("||") > -1) {
                let primaryFontLoc = theme.data.custom_font.primary_font.split("||")[0];
                this.loadCustomFont("PrimaryFont", primaryFontLoc);
              } else {
                this.loadCustomFont("PrimaryFont", DEFAULT_PRIMARY_FONT_URL);
              }
              //Load Secondary Font Data
              if (theme.data?.custom_font && theme.data?.custom_font.secondary_font && theme.data?.custom_font.secondary_font.indexOf("||") > -1) {
                let secondaryFontLoc = theme.data.custom_font.secondary_font.split("||")[0];
                this.loadCustomFont("SecondaryFont", secondaryFontLoc);
  
              } else {
                this.loadCustomFont("SecondaryFont", DEFAULT_SECONDARY_FONT_URL);
              }
              // ======================= enterprise endd ======================
            }else{
              await AsyncStorage.setItem("uuid", uuid);
              this.setState({ platform: "online" });
              this.props.setPlatform("online" );
        
              this.connectToSocket(config.data, uuid)
              this.connectToFbPixel(config.data[0], uuid, "online")
              this.trackVisit(config.data[0]);
              await this.props.addConfig(config.data);
              this.props.selectMenu(config.data.menu_to_display);
              let languages = [];
              console.log(config.data.languages, "lannnnnnnnnnnnnnnnnn")
              if (config.data.languages) {
                languages = config.data.languages;
                languages.unshift(config.data.default_language);
              } else {
                languages.push(config.data.default_language);
              }
              console.log("222222")
              this.props.addLanguage(languages);
              this.setLocale(config.data[0]);            
              this.props.setDesign(theme.data);
              let delivery_config = this.props.config.find((item) => item.platform == "delivery");         
          
              if (delivery_config && theme.data?.custom_font && theme.data?.custom_font.primary_font && theme.data?.custom_font.primary_font.indexOf("||") > -1) {
                let primaryFontLoc = theme.data.custom_font.primary_font.split("||")[0];
                this.loadCustomFont("PrimaryFont", primaryFontLoc);
              } else {
                this.loadCustomFont("PrimaryFont", DEFAULT_PRIMARY_FONT_URL);
              }
  
              console.log("333333")
              //Load Secondary Font Data
              if (delivery_config && theme.data?.custom_font && theme.data?.custom_font.secondary_font && theme.data?.custom_font.secondary_font.indexOf("||") > -1) {
                let secondaryFontLoc = theme.data.custom_font.secondary_font.split("||")[0];
                this.loadCustomFont("SecondaryFont", secondaryFontLoc);
  
              } else {
                this.loadCustomFont("SecondaryFont", DEFAULT_SECONDARY_FONT_URL);
              }

            }


            console.log("44444")
            this.setState({ isReady: true });
          })
        )
        .catch((error) => {
          console.log(error, "errrrrrrrrrrrrrrrrrr")
          this.setState({ noID: true });
        });
      // ================= end online menu =========================
    } else if (
      window.location.hostname.startsWith("qr.mydigimenu") ||
      window.location.hostname.startsWith("qr-menu.mydigimenu") ||
      window.location.hostname.startsWith("qr-qa.mydigimenu") ||
      (window.location.hostname.startsWith("localhost") && TESTING_PLATFORM == "qr") ||
      this.customDomain()
    ) {
      //  ==================================== qr menu ========================================
      let is_uuid = this.isUUID(uuid);
      if (is_uuid) {
        // is qr menu
        this.get_data_qr(uuid);
        await AsyncStorage.setItem("uuid", uuid);
      } else {
        let is_general = window.location.href.split("/").reverse()[1];
        if (is_general == "gr") {
          AsyncStorage.getItem("uuid", (err, uuid) => {
            if (err) {
              return;
            } else {
              this.get_data_qr(uuid);
              uuid = uuid;
            }
          });
        } else if (is_general == "tr") {
          this.setState({ noID: true });
          return;
        }
      }
      //  ==================================== end qr menu ========================================
    }

  };

  async componentDidMount() {
    this.validateToken()
    let uuid = window.location.pathname.substring(1);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({uuid})

    // AsyncStorage.removeItem("login_trigger")
    if(uuid){
      console.log((params && params.platform == "enterprise") && !window.location.hostname.startsWith("order.mydigimenu") && (window.location.hostname.startsWith("localhost") && TESTING_PLATFORM == "qr"), "llllllll")
        if(
          (window.location.hostname.startsWith("qr.mydigimenu") ||
          window.location.hostname.startsWith("qr-menu.mydigimenu") ||
          (window.location.hostname.startsWith("localhost") &&
            TESTING_PLATFORM == "qr")) &&
            (params && params.platform == "enterprise") 
          ){
            this.loadEntConfigs(uuid)
        }else{
            this.loadContentBasedOnUUID(uuid)
        }
    }
  }

  getMenu = (uuid, platform = "qr") => {
    return axios.get(API_URL + `api/qr-menu/${uuid}/?platform=${platform}`);
  };
  getTable = (uuid) => {
    return axios.get(API_URL + "api/qr-table/" + uuid+ "/");
  };
  getConfig = (uuid, platform = "qr") => {
    return axios.get(API_URL + `api/v2/qr-config/${uuid}/?platform=${platform}`);
  };
  isUUID = (uuid) => {
    let pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(uuid);
  };

  getOnlineConfig = (venue, platform = "online") => {
    return axios.get(
      API_URL + `api/v2/online-config/${venue}/?platform=${platform}`
    );
  };

  getChildrenAsyc = async (venue) => {
    this.props.resetCart();
    axios.all([this.getModifiers(venue), this.getChild(venue)]).then(
      axios.spread(async (modifiers, children) => {
        let datum = JSON.parse(JSON.stringify(children.data));

        datum.forEach((element) => {
          let recoms = [];
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
        });
        this.props.addOnlineChildren(datum, modifiers.data);
      })
    );
    //  axios.get(API_URL+'api/online-menu-children/'+venue)
    //  .then(response => this.props.addChildren(response.data))
  };

  getChild = (venue) =>
    axios.get(API_URL + "api/online-menu-children/" + venue + "/");
  getModifiers = (venue) =>
    axios.get(API_URL + "api/online-modifiers/" + venue + "/");
  renderScreen = () => {
    if (this.state.platform == "qr") {
        if (this.state.no_menu) {
            return <NoMenuNavigation />;
          }
      return <Navigation />;
    } else if (this.state.platform == "enterprise") {
      if (
        window.location.hostname.startsWith("order.mydigimenu") ||
        window.location.hostname.startsWith("order-menu.mydigimenu") ||
        window.location.hostname.startsWith("order-qa.mydigimenu") ||
        (window.location.hostname.startsWith("localhost") &&
          TESTING_PLATFORM == "online")
      ){
        return <OnlineNavigation />;
      }
      if (this.state.no_menu) {
        return <NoMenuNavigation />;
      }
      return <Navigation />;
    } else {
      return <OnlineNavigation />;
    }
  };
  _onMessageWasSent = async(event) => {
    let uuid = window.location.pathname.substring(1);
    let sender = {
      name: this.props.user ? `${this.props.user.first_name.toUpperCase()} ${this.props.user.last_name.toUpperCase()}` : "Guest",
      type: "guest",
      id: this.props.user ? this.props.user.id : "",
      device_id: Constants.deviceId,
      email: this.props.user ? this.props.user.email : "",
      number: this.props.number ? this.props.number : ""
    }
    let venue_id = this.props.config.venue
    let venue_name = this.props.config.venueName
    if(Array.isArray(this.props.config)){
      let delivery_config = this.props.config.find((item) => item.platform == "delivery");
      venue_id = delivery_config.venue
      venue_name = delivery_config.venueName
    }

    let data = {
      "venue": venue_id,
      "user": Constants.deviceId,
      "table": this.state.table_data ? this.state.table_data : {uuid:Constants.deviceId, name: "Online Menu", table: "", venue:venue_name },
      'message': event.data.text,
      "message_type":'text',
      "device_info": JSON.stringify(Constants.platform),
      "sender": sender,
    }
    if(this.props.messages.length){
      data['message_id'] = this.props.messages[this.props.messages.length-1].sk.split('#')[3]
    }
    // let messages = [...this.state.messages, data]
    // this.setState({messages})


    let response = await this.socket.send(JSON.stringify({action:"sendmessage",data}));
    
  }
  showLauncher = () => {
    let config = this.props.config
    if(Array.isArray(this.props.config)){
      config = this.props.config.find((item) => item.platform == "delivery");
    }
    if(config && config.chat_enabled){
      return(
        <Launcher
          ref={this.launcher}
          isMessageLoading={this.state.isMessageLoading}
          handleClick = {() => this.loadMessages()}
          isOpen={this.state.chatWindowOpen}
            agentProfile={{
              teamName: this.state.venue_name+" support",
              imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
            }}
            onMessageWasSent={this._onMessageWasSent}
            messageList={this.props.messages}
            showEmoji
            newMessagesCount={this.state.newMessageCount}
            config={this.props.config}
            design={this.props.design}

          />
      )
    }else{
      return(<></>)
      
    }
    

  }

  render() {
    const { noID, isError, isReady } = this.state;
    if (noID) {
      return (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: height,
          }}
        >
          <Text style={{   fontFamily:'SecondaryFont'}}>{i18n.t("labels.scan_qr")}</Text>
        </View>
      );
    }
    if (!isReady) {
      if (width > 999) {
        return (
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#A98535",
              }}
            >
              <Image
                style={{ width: 200, height: 200 }}
                source={{ uri: require("./assets/splash.png") }}
              />
            </View>
            <View style={{ flex: 1.9 }}></View>
          </View>
        );
      } else {
        return (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#A98535",
            }}
          >
            <Image
              style={{ width: 200, height: 200 }}
              source={{ uri: require("./assets/splash.png") }}
            />
          </View>
        );
      }
    } else if (isReady && !isError) {
      if (width > 999) {
        return (
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>{this.renderScreen()}</View>
            <View
              style={{
                flex: 1.9,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!!this.props.config.logo_image &&
                <View style={{
                  bottom:20,
                  width: "100%",
                  alignItems: "center"
                }} >
                  <View style={{
                    width: 64,
                    borderRadius: 5,
                    height: 64
                  }}>
                    <Image
                      source={{ uri: this.props.config.logo_image }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </View>
                </View>

        }
              <Text
                style={{
                  //fontWeight: "bold",
                  letterSpacing: FontLetterSpacing(this.props.design?.custom_font?.primary_letter_space),
                  fontWeight: this.props.design?.custom_font?.heading_is_bold ? "bold": "normal",
                  fontSize: this.props.design?.custom_font?.heading_size ?? 30,
                  //fontSize: 30,
                  color: "brown",
                  textDecorationLine: "underline",
                  fontFamily:'PrimaryFont'
                }}
              >              
                {this.state.venue_name} 
              </Text>
            </View>
          </View>
        );
      } else {
        if (this.state.platform == "qr") {
          return <>
          {
            this.state.no_menu ? 
            <NoMenuNavigation />
            :
            <Navigation />
          }
          {/* <Navigation /> */}
          {
            this.props.config && this.props.config.chat_enabled && this.props.config.general_qr.qr_id != this.state.uuid &&
            <View style={{position:"absolute", bottom:0, width:50, padding:10}} >
              <Launcher
                ref={this.launcher}
                isMessageLoading={this.state.isMessageLoading}
                handleClick = {() => this.loadMessages()}
                isOpen={this.state.chatWindowOpen}
                  agentProfile={{
                    teamName: this.state.venue_name+" support",
                    imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                  }}
                  onMessageWasSent={this._onMessageWasSent}
                  messageList={this.props.messages}
                  showEmoji
                  newMessagesCount={this.state.newMessageCount}
                  config={this.props.config}
                  design={this.props.design}
                />
                </View> 
          }

          </>;
        }
        if (this.state.platform == "enterprise") {
          if (
            window.location.hostname.startsWith("order.mydigimenu") ||
            window.location.hostname.startsWith("order-menu.mydigimenu") ||
            window.location.hostname.startsWith("order-qa.mydigimenu") ||
            (window.location.hostname.startsWith("localhost") &&
              TESTING_PLATFORM == "online")
          ){
            return <OnlineNavigation />;
          }
          if (this.state.no_menu) {
            return <NoMenuNavigation />;
          }
          return <Navigation />;
        }
        return <>
        <OnlineNavigation />
        {
          this.showLauncher()
          }
        </>;
      }
    } else {
      if (width > 999) {
        return (
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <FallbackScreen errorStatus={this.state.errorStatus} />
            </View>
            <View
              style={{
                flex: 1.9,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  // fontWeight: "bold",
                  letterSpacing: FontLetterSpacing(this.props.design?.custom_font?.primary_letter_space),
                  fontWeight: this.props.design?.custom_font?.heading_is_bold ? "bold": "normal",
                  fontSize: this.props.design?.custom_font?.heading_size ?? 30,
                  color: "brown",
                  textDecorationLine: "underline",
                  fontFamily:'PrimaryFont'
                }}
              >
                {this.state.venue_name}  
              </Text>
            </View>
          </View>
        );
      } else {
        return <FallbackScreen errorStatus={this.state.errorStatus} />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    user: state.number.user,
    default_language: state.languages.default_language,
    config: state.config.config,
    messages: state.messages.messages,
    design: state.config.design,
    number: state.number.number,
    platform: state.config.platform,
    primaryFontFace:state.fontFace.primaryFontFace,
    secondaryFontFace:state.fontFace.secondaryFontFace,
  };
};

export default connect(mapStateToProps, {
  addMenu,
  addConfig,
  addLanguage,
  addDefaultLanguage,
  addTable,
  getModifiers,
  selectMenu,
  resetCart,
  addChildren,
  addOnlineChildren,
  setPlatform,
  adduser,
  addToken,
  addCampaign,
  setVenues,
  setDesign,
  selectMenuData,
  isLoading,
  addMessage,
  allowOrdering,
  addPrimaryFontFace,
  addSecondaryFontFace,
  totalPrice,
  allCart,
  updateCartCount
})(InitialRender);
