'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var AuthTypeCallsList = require(
    './authTypes/authCallsMapping').AuthTypeCallsList;
var AuthTypeRegistrationsList = require(
    './authTypes/authRegistrationsMapping').AuthTypeRegistrationsList;

var AuthTypesList;

/* jshint ignore:start */
/**
 * Initialize the AuthTypesList
 *
 * @constructor Twilio.Api.V2010.AccountContext.SipContext.DomainContext.AuthTypesList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The SID of the Account that created the resource
 * @param {string} domainSid - The unique string that identifies the resource
 */
/* jshint ignore:end */
AuthTypesList = function AuthTypesList(version, accountSid, domainSid) {
  /* jshint ignore:start */
  /**
   * @function auth
   * @memberof Twilio.Api.V2010.AccountContext.SipContext.DomainContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.SipContext.DomainContext.AuthTypesContext}
   */
  /* jshint ignore:end */
  function AuthTypesListInstance(sid) {
    return AuthTypesListInstance.get(sid);
  }

  AuthTypesListInstance._version = version;
  // Path Solution
  AuthTypesListInstance._solution = {accountSid: accountSid, domainSid: domainSid};

  // Components
  AuthTypesListInstance._calls = undefined;
  AuthTypesListInstance._registrations = undefined;

  Object.defineProperty(AuthTypesListInstance,
    'calls', {
      get: function calls() {
        if (!this._calls) {
          this._calls = new AuthTypeCallsList(
            this._version,
            this._solution.accountSid,
            this._solution.domainSid
          );
        }

        return this._calls;
      }
  });

  Object.defineProperty(AuthTypesListInstance,
    'registrations', {
      get: function registrations() {
        if (!this._registrations) {
          this._registrations = new AuthTypeRegistrationsList(
            this._version,
            this._solution.accountSid,
            this._solution.domainSid
          );
        }

        return this._registrations;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.SipContext.DomainContext.AuthTypesList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  AuthTypesListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  AuthTypesListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return AuthTypesListInstance;
};

module.exports = {
  AuthTypesList: AuthTypesList
};
