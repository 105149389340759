import {
    ADD_LOCATION
} from "../actionTypes";


const initialState = {
       location : {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOCATION:
            return {
                ...state,
                location: action.location
            };
        default:
            return state;
    }
};

export default reducer;