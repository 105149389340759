import React, {Component, } from 'react';
import {

    View,
    ScrollView,
    TouchableOpacity,
    Text,
    Linking,
    StyleSheet,
    Dimensions

} from 'react-native';
const {height, width} = Dimensions.get('window')

import Modal from 'modal-react-native-web';
import { Video } from 'expo-av';
import ProgressiveImage from './../components/progressiveImage'
import {image_api} from './../constants/index'

import Icon from './../components/Warning'

import ReactPlayer from 'react-player/lazy'
import { BlurView } from 'expo-blur';
import { FontLetterSpacing } from './../handlers/utils';
import {useSelector} from "react-redux"
import StyledText from "../components/styledText"
// const image_api = "https://d2ceauryvxkzea.cloudfront.net/fit-in/300x300/"

const playVideo = () => setTimeout(() => {
    return true
},4000)



const ModalFunction = (props) => {
    const design = useSelector(state => state.config.design)

    const contentRenderer = (content_type)=> {

        if(content_type == 1){
            return(
                <ProgressiveImage
                    position={'absolute'}
                    thumbnailSource={{ uri: image_api + props.campaign.content.split('/').splice(3).join('/')+"?w=100&h=100" }}
                    source={{uri: image_api + props.campaign.content.split('/').splice(3).join('/')+"?w=800&h=800"}}
                    style={{  width: '100%', height: '100%'}}
                    resizeMode="contain"
                />
            )
        }else if(content_type == 2){
            return(
                <video 
                muted
                 autoplay="true"
                  playsinline="true"
                  preload="true"
                  width={"100%"}
                  height="100%"
                  style={{
                    // objectFit: "fill"
                    // width: "100%",
                    // height: "auto",
                    // maxHeight: "100%"
                  }}
                >
                  <source src={props.campaign.content} />

                </video>

                // <ReactPlayer 
                //     playsinline
                //     // muted
                //     playing={playVideo}
                //     loop
                //     controls={false}
                //     width={'100%'}
                //     height={'100%'}
                //     url={props.campaign.content}
                //     // resizeMode="cover"
                // />

         
            // <Video
            //     // ref={this._handleVideoRef}
            //     source={{ uri: props.campaign.content_file }}
            //     rate={1.0}
            //     volume={1.0}
            //     resizeMode="contain"
            //     shouldPlay={props.campaignModal}
            //     isLooping
            //     style={{ width: '100%', height: '100%', }}
            // />
            )
        }
    }
    return(
        <Modal
            animationType="slide"
            transparent
            visible={props.campaignModal}
            onRequestClose={() => {props.closeCampaignModal(!props.campaignModal)}}
        >
            {
                props.campaign ? 
                <View style={{
                    width:width,
                    height:height,
                    backgroundColor: "transparent",
                    // padding:  22,
                    justifyContent: 'center',
                    // alignItems: 'center',
                    borderRadius: 4,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    alignSelf:'center',
                    backdropFilter: "blur(5px)" 
                    // marginTop:10

                }} >
                <BlurView intensity={200} style={[StyleSheet.absoluteFill]}>
      

                    <View  style={{flexDirection:'row',height:'10%'}}>
                        
                        <View style={{flex:1,alignItems:'center',marginTop:15}}>
                        {
                            props.campaign.show_title ?
                            <StyledText
                                numberOfLines={1}
                                font_color={design.popup_heading_text_color}
                                font_weight={design.popup_heading_font_weight}
                                font_style={design.popup_heading_font_style}
                                text_transform={design.popup_heading_font_transform}
                                font_family={design.popup_heading_font_family}
                                font_size={design.popup_heading_font_size}
                                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                                style={{textAlign:'center'}}
                            >
                                {props.campaign.title}
                                </StyledText>
                            
                            :
                            <View/>
                        }
                        {
                            props.campaign.show_desc ? 
                            <StyledText
                            font_color={design.popup_text_color}
                            font_weight={design.popup_text_font_weight}
                            font_style={design.popup_text_font_style}
                            text_transform={design.popup_text_font_transform}
                            font_family={design.popup_text_font_family}
                            font_size={design.popup_text_font_size}
                            letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                            style={{textAlign:'center'}}
                        >
                            {props.campaign.description}
                            </StyledText>
                            : <View/>
                        }
                        
                        </View>
                        <View style={{borderRadius:10,alignItems:'flex-end',margin:10}}>
                            <TouchableOpacity onPress={props.closeCampaignModal}>
                                <View  style={{borderWidth:1,borderColor:design.popup_button_border_color,width:45,height:45,borderRadius:7,alignItems:'center',justifyContent:'center', backgroundColor:design.popup_button_color}}>
                                    <Icon style={{color:props.popup_button_text_color}} size={30} name={'close'}/>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                
                    <View  style={{alignItems:'center', width:'100%', height:'90%',}}>
                        <View style={{width:'97%',height:'90%'}}>
                            {contentRenderer(props.campaign.content_type)}
                        
                        </View>    
                    </View>
                </BlurView>
                </View>
                : <View/>
            }


    </Modal>
    )
}

export default ModalFunction