import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  PanResponder,
  AsyncStorage,
  FlatList,
  Platform,
} from "react-native";
import {
  addCount,
  addDefaultLanguage,
  addToCart,
  removeItemFromCart,
  subtractCount,
  totalPrice,
  updateChildren,
  allCart,
  updateCartCount
} from "../store/actions";
import { connect } from "react-redux";
import BackButton from "./../components/backButton";
import Bell from "./../components/fontSize";
import DefaultLanguage from "./../components/DefaultLanguage";
import LanguageContainer from "./../components/languageContainer";
import Modal from "modal-react-native-web";
// import Icon from '@expo/vector-icons/Ionicons'
// import Material from '@expo/vector-icons/MaterialCommunityIcons'
import Ant from "@expo/vector-icons/AntDesign";
import Toast from "./../components/toast";

import Warning from "./../components/Warning";
import HTMLView from "react-native-htmlview";
import CheckBox from "react-native-check-box";
import * as Animatable from "react-native-animatable";
import CartTotal from "./../components/cartTotal";
import { Video } from "expo-av";
import { style } from "../../assets/style";
import CartButton from "./../components/cartButton";
import ProgressiveImage from "./../components/progressiveImage";
import Icon from "./../components/Warning";
import CampaignModal from "../components/campaignModal";
import { NavigationActions } from "react-navigation";
import { THREE_FRACTION_CURRENCY, image_api, API_URL } from "./../constants";
import { Textarea, Button, Badge } from "native-base";
import { PushData } from "./../constants/tagViewpush";
import i18n from "../i18n";
import ReactPlayer from 'react-player/lazy'
import Picker from 'react-mobile-picker';
import Heart from "react-heart"
import Constants from "expo-constants";
import ModifierText from "./../components/modifierText";
import ModifierPrice from "./../components/ModifierPrice"
import ModifierCounterContainer from "./../components/modifierCountContainer"
import ModifierRequired from "./../components/modifierRequired"
import _ from "lodash"
import axios from 'axios'
import IntlLabel from "./../handlers/languageHandler";
import IntlLabelFn from "./../handlers/languageHandlerFn";
import ItemLabel from "./../components/itemLabel";
import { FontLetterSpacing, PriceLoader } from "./../handlers/utils"
import { useSelector } from "react-redux"
import StyledText from "../components/styledText"
import { performRequest } from "../services/apiHandler";

import ItemCalorie from "./../components/itemCalorie";
import CardBorder from "./../components/cardBorder";
import ShowMoreItem from "./../components/showMoreItem";

let { width, height } = Dimensions.get("window");

class RecipeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageModal: false,
      section: this.props.navigation.state.params.section,
      item: this.props.navigation.state.params.item,
      selectedPrice: this.props.navigation.state.params.item.selected_price
        ? this.props.navigation.state.params.item.selected_price
        : null,
      warningIndex: null,
      // selected_items:[],
      selectedIndex: this.props.navigation.state.params.index,
      itemVideoPlay: true,
      shouldPlay: true,
      itemsToRemove: [],
      removeItemModal: false,
      message_display: "none",
      uuid: "",
      isDetailScreenActive: true,
      campaignModal: false,
      campaignSelected: "",
      campaignIndex: "",
      item_campaigns: [],
      item_count: 1,
      isLiked: false,
      item_modifiers: [],
      setAutoplay: false,
      itemDescriptionHeight: "50px",
      isDescriptionCollapsed: true,
      initLoadCount: 5

    };
    this._handleVideoRef;
  }
  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };
  componentWillUnmount() {
    clearTimeout(this.camp_timeout);
    let all_children = [...this.props.allChildren];
    let item_selected_from_children = all_children.find(
      (itm) => itm.id === this.state.item.id && itm.type === "item"
    );

    this.makeModifiersFalse(item_selected_from_children);
    // this.makeItemModifiersFalse()

  }
  itemModifiers = () => {
    let item = { ...this.state.item };
    let all_children = [...this.props.allChildren];

    let normal_modifiers_unprocessed = [...item.modifiers].filter(item => item._type == "new")
    let combo_modifier_unprocessed = [...item.modifiers].filter(item => item._type == "item")
    console.log(combo_modifier_unprocessed, "combooooooooooo")


    let normal_modifiers = normal_modifiers_unprocessed.map(mod_item => {
      let modifiers = mod_item.modifier_modifier_group.map(mod => {
        if (mod.item && mod.is_item_modifier) {
          let _item = all_children.find(
            (itm) => itm.id == mod.item && itm.type === "item"
          );
          return {
            ...mod,
            ..._item,
            price: mod['price']
          }
        } else {
          return mod
        }
      })
      return {
        ...mod_item,
        modifier_modifier_group: modifiers
      }

    })

    let combo_modifiers = combo_modifier_unprocessed.map(mod_item => {
      let modifiers = mod_item.modifier_modifier_group.map(mod => {
        let _item = all_children.find(
          (itm) => itm.id == mod.item && itm.type === "item"
        );
        return {
          ...mod,
          ..._item,
          price: mod['price'],
          mod_id: mod['id']

        }
      })
      return {
        ...mod_item,
        modifier_modifier_group: modifiers
      }

    })





    item.modifiers = normal_modifiers
    item.combo_modifiers = combo_modifiers
    this.setState({ item })


    // if(this.state.item.item_modifiers){
    //   let selected_item =  all_children.find(
    //     (itm) => itm.id == this.state.item.id && itm.type === "item"
    //   );
    //   let item_mods = JSON.parse(JSON.stringify(selected_item.item_modifiers))
    //   console.log(item_mods, "kkkkkkkk")
    //   let item_modifiers = item_mods.map(mod_item => {
    // let _item =  all_children.find(
    //   (itm) => itm.id == mod_item.modifiergroup.split("_")[0] && itm.type === "item"
    // );

    //     if(_item){
    //       return {
    //         ...JSON.parse(JSON.stringify(_item)),
    //         ...mod_item
    //       }
    //     }

    //   })
    //   console.log(item_modifiers, "hhhhhhh")
    //   this.setState({item_modifiers})

    // }
  }
  async componentDidMount() {
    this.itemModifiers()
    if (this._handleVideoRef) {
      setTimeout(() => {
        this._handleVideoRef
        this._handleVideoRef.play()
        this.setState({ setAutoplay: true })
      }, 2000);
    }
    if (this.props.config.show_favourite) {
      axios.get(`${API_URL}api/v2/receipe/like?venue=${this.props.config.venue}&user=${Constants.deviceId}&item=${this.state.item.id}`)
        .then(response => {
          if (response.data.is_liked) {
            this.setState({ isLiked: true })
          }
        })
        .catch(err => {
          this.setState({ isLiked: false })

        })
    }

    if (Platform.OS === "web") {
      width = 450;
    }
    let item_campaign = this.props.campaigns.filter((campaign) => {
      return (
        campaign.campaign_starts_after == "3" &&
        this.state.item.id == campaign.start_when.id &&
        campaign.content_type != "4" &&
        campaign.content_type != "3"
      );
    });
    if (item_campaign.length) {
      this.camp_timeout = setTimeout(() => {
        if (this.state.isDetailScreenActive) {
          this.setState({
            campaignModal: true,
            campaignSelected: item_campaign[0],
            campaignIndex: 0,
            item_campaigns: item_campaign,
          });
        }
      }, 2000);
    }

    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.setState({ uuid: uuid });
        PushData(
          this.props.navigation.state.routeName,
          this.props.config.venueName ? this.props.config.venueName : "Ent",
          uuid ? uuid : "Ent",
          {}
        );
      }
    });
  }

  closeCampaignModal = () => {
    if (this.state.item_campaigns.length > this.state.campaignIndex) {
      this.setState({ campaignModal: false });
      setTimeout(() => {
        this.setState({
          campaignSelected:
            this.state.item_campaigns[this.state.campaignIndex + 1],
          campaignIndex: this.state.campaignIndex + 1,
          campaignModal: true,
        });
      }, 2000);
    } else {
      this.setState({ campaignModal: false });
    }
  };

  throwMessageFn = (message, status) => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
        });
      }.bind(this),
      3000
    );
  };

  defaultLangView = () => (
    <LanguageContainer
      background_color={this.props.design.popup_background_color}
      closeModal={() => this.setState({ languageModal: false })}
    >
      {this.props.languages.map((item) => (
        <TouchableOpacity
          onPress={() => {
            i18n.locale = item.value;

            this.props.addDefaultLanguage(item.value);
            this.setState({ languageModal: false });
          }}
        >
          <View
            style={{
              paddingLeft: 3,
              paddingVertical: 7,
              borderBottom: "1px solid black",
              justifyContent: "center",
            }}
          >
            <StyledText
              font_color={this.props.design.popup_text_color}
              font_weight={this.props.design.popup_text_font_weight}
              font_style={this.props.design.popup_text_font_style}
              text_transform={this.props.design.popup_text_font_transform}
              font_family={this.props.design.popup_text_font_family}
              font_size={this.props.design.popup_text_font_size ?? 16}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

              style={{}}
            >{item.label}</StyledText>
          </View>
        </TouchableOpacity>
      ))}
    </LanguageContainer>
  );
  onClickPrice = (item, index) => {
    console.log(item, "itemmmmmmm")
    this.setState({ selectedPrice: item });
  };

  handleViewRef = (ref) => (this.view = ref);

  bounce = (index) => {
    this.setState({ warningIndex: index });
    setTimeout(() => {
      this.setState({ warningIndex: null });
    }, 2500);
  };

  onModifierClick = (parentIndex, childIndex, childPrice) => {
    let item = { ...this.state.item };
    item.modifiers[parentIndex]["required_indicator"] = false;
    if (
      item.modifiers[parentIndex].modifier_modifier_group[childIndex]["checked"]
    ) {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = false;
      item.modifiers[parentIndex]["checked_count"] -= 1;
      item.modifier_total_price -= childPrice;
    } else {
      item.modifiers[parentIndex].modifier_modifier_group[childIndex][
        "checked"
      ] = true;
      if (!item.modifier_total_price) {
        item["modifier_total_price"] = childPrice;
      } else {
        item.modifier_total_price += childPrice;
      }
      if (item.modifiers[parentIndex]["checked_count"]) {
        item.modifiers[parentIndex]["checked_count"] += 1;
      } else {
        item.modifiers[parentIndex]["checked_count"] = 1;
      }
    }
    this.setState({ item: item });
  };

  makeModifiersFalse = (item) => {
    console.log("ooooo")
    for (let i = 0; i < this.state.item.modifiers.length; i++) {
      delete item.modifiers[i].checked_count;
      for (
        let j = 0;
        j < this.state.item.modifiers[i].modifier_modifier_group.length;
        j++
      ) {
        // let mod = this.state.item.modifiers[i].modifier_modifier_group[j]
        // if(mod.is_item_modifier && mod.modifiers && mod.modifiers.length){

        //   for(let k=0; k<mod.modifiers.length;k++){
        //     for(let l=0; l< mod.modifiers[k].modifier_modifier_group.length; l++){
        //       mod.modifiers[k].modifier_modifier_group[l].count = 0
        //       mod.modifiers[k].modifier_modifier_group[l].required_indicator = false

        //     }
        //     mod.modifiers[k].total_count = 0;
        //     mod.modifiers[k].modifier_total_price = 0;
        //   }
        // }
        this.state.item.modifiers[i].modifier_modifier_group[j].checked = false;
        this.state.item.modifiers[i]["required_indicator"] = false;
      }
    }
  };
  makeItemModifiersFalse = (item) => {
    this.setState({ item_modifiers: [] })
  };

  validateItemModifier = () => {
    let item = { ...this.state.item };
    let item_modifiers = [...item.modifiers];
    let data = {
      failed: false,
      amount: 0,
      modifiers: []
    }
    let _modifiers = []
    let mod_total = 0;

    for (let item_i = 0; item_i < item_modifiers.length; item_i++) {
      // console.log("11", item_modifiers[item_i])
      for (let item_m = 0; item_m < item_modifiers[item_i].modifier_modifier_group.length; item_m++) {

        let mod = item_modifiers[item_i].modifier_modifier_group[item_m]
        // console.log("222", mod)
        if (mod.is_item_modifier && mod.modifiers) {
          for (let mod_j = 0; mod_j < mod.modifiers.length; mod_j++) {
            let child_mod = mod.modifiers[mod_j]
            // console.log("33", child_mod)

            if (child_mod.min && !child_mod.total_count || (child_mod.min > child_mod.total_count)) {
              data['failed'] = true
              mod.modifiers[mod_j].required_indicator = true
              break;
            }
          }

          if (mod.count) {
            let item_mod = {
              names: JSON.stringify(mod.item_names),
              count: mod.count,
              price: mod.price,
              _type: "new",
              id: mod.id,
              modifier_group: mod.modifiergroup,
              is_item_modifier: mod.is_item_modifier
            }
            _modifiers.push(item_mod)
          }


        }
      }
      // return data
    }
    this.setState({ item })

    // validateItemModifier = () => {
    //   let item_modifiers = [...this.state.item_modifiers];
    //   let data = {
    //     failed: false
    //   }
    //   let _modifiers = []
    //   let mod_total = 0;
    //   for (let item_i = 0; item_i < item_modifiers.length; item_i++) {
    //     if (item_modifiers[item_i].required && !item_modifiers[item_i].price.length && !item_modifiers[item_i].count) {
    //       data['failed'] = true
    //       break;
    //     } else if (parseInt(item_modifiers[item_i].min) > item_modifiers[item_i].count && item_modifiers[item_i].required) {
    //       data['failed'] = true
    //       break;
    //     }
    //     else if (!item_modifiers[item_i].price.length) {
    //       // create data to return here
    //       if (item_modifiers[item_i].count) {
    //         let item_mod = {
    //           names: JSON.stringify(item_modifiers[item_i].item_names),
    //           count: item_modifiers[item_i].count,
    //           price: item_modifiers[item_i].price,
    //           type: "item",
    //           id: item_modifiers[item_i].id,
    //           modifier_group: item_modifiers[item_i].modifiergroup.split('_')[0]
    //         }
    //         _modifiers.push(item_mod)
    //       }

    //     }
    //     if (item_modifiers[item_i].required && !item_modifiers[item_i].total_count && item_modifiers[item_i].price.length) {
    //       data['failed'] = true
    //       item_modifiers[item_i].required_indicator = true
    //       break;
    //     } else if (parseInt(item_modifiers[item_i].min) > item_modifiers[item_i].total_count && item_modifiers[item_i].required) {
    //       data['failed'] = true
    //       item_modifiers[item_i].required_indicator = true
    //       break;
    //     } else if (item_modifiers[item_i].price.length) {
    //       if (item_modifiers[item_i].total_count) {
    //         if (item_modifiers[item_i].modifier_total_price) {
    //           mod_total += item_modifiers[item_i].modifier_total_price
    //         }
    //         item_modifiers[item_i].price.forEach(element => {
    //           // console.log(element, "eeeeeeeeeeeeee")
    //           if (element.count) {
    //             let item_mod = {
    //               names: JSON.stringify(element.description),
    //               item_names: JSON.stringify(item_modifiers[item_i].item_names),
    //               count: element.count,
    //               price: element.price,
    //               type: "item",
    //               id: item_modifiers[item_i].id,
    //               modifier_group: item_modifiers[item_i].modifiergroup.split('_')[0]
    //             }
    //             _modifiers.push(item_mod)
    //           }
    //         });
    //       }
    //     }

    //     if (item_modifiers[item_i].total_count) {
    //       for (let item_m = 0; item_m < item_modifiers[item_i].modifiers.length; item_m++) {
    //         let mod_item = item_modifiers[item_i].modifiers[item_m]
    //         if (mod_item.min > 0 && (mod_item.total_count < mod_item.min || !mod_item.total_count)) {
    //           data['failed'] = true;
    //           mod_item["required_indicator"] = true;
    //           break;
    //         } else {
    //           console.log(mod_item, "xxxxxxxxxxxxxxxx")
    //           if (mod_item.modifier_total_price) {
    //             mod_total += mod_item.modifier_total_price
    //           }
    //           for (let j = 0; j < mod_item.modifier_modifier_group.length; j++) {
    //             if (mod_item.modifier_modifier_group[j].count && mod_item.modifier_modifier_group[j].count > 0) {
    //               _modifiers.push(mod_item.modifier_modifier_group[j]);
    //             }
    //           }
    //         }
    //       }
    //     }

    //   }
    //   if (data['failed']) {
    //     this.setState({ item_modifiers })
    //   }
    //   data["modifiers"] = _modifiers
    //   data["amount"] = mod_total
    return data

  }

  _onOptionSelect = () => {
    let item = { ...this.state.item };
    let item_to_change_in_page = { ...this.state.item };
    let failed = false;
    let item_modifier = this.validateItemModifier()
    console.log(item_modifier)
    if (item_modifier.failed) {
      this.throwMessageFn("Select Modifiers", "red");
      this.scrollListRef.scrollTo({ x: Dimensions.get('window').width / 2, y: 0, animated: Dimensions.get('window').height / 2 })
      return;
    }
    Promise.all(
      item.modifiers.map((item) => {
        if (item.min > 0) {
          if (item.checked_count < item.min || !item.checked_count) {
            failed = true;
            item["required_indicator"] = true;
          } else {
          }
        } else {
          // failed = true;
          // item['required_indicator'] = true;
        }
      }),
      item.combo_modifiers.map((item) => {
        if (item.min > 0) {
          if (item.checked_count < item.min || !item.checked_count) {
            failed = true;
            item["required_indicator"] = true;
          } else {
          }
          item.modifier_modifier_group.forEach(mod_item => {
            if (mod_item.min && mod_item.min > 0 && !mod_item.checked) {

              failed = true;
              item["required_indicator"] = true;
            }
          })

        } else {
          // failed = true;
          // item['required_indicator'] = true;
        }
      })
    ).then(() => {
      if (!failed) {
        this.setState({ cartLoading: true })
        // if everything okay
        let _modifier = [];
        let nested_modifier_total = 0

        // let combo_total = 0
        for (let i = 0; i < item.combo_modifiers.length; i++) {
          for (
            let j = 0;
            j < item.combo_modifiers[i].modifier_modifier_group.length;
            j++
          ) {
            if (item.combo_modifiers[i].modifier_modifier_group[j].checked && item.combo_modifiers[i].modifier_modifier_group[j].checked > 0) {
              item.combo_modifiers[i].modifier_modifier_group[j]['count'] = item.combo_modifiers[i].modifier_modifier_group[j].checked
              let comb_mod = item.combo_modifiers[i].modifier_modifier_group[j]
              // combo_total += comb_mod.price
              console.log(item.combo_modifiers[i], "99999999999999999999")
              let combo = {
                names: JSON.stringify(comb_mod.item_names),
                count: comb_mod.count,
                price: comb_mod.price,
                _type: "item",
                id: comb_mod.mod_id,
                modifier_group: comb_mod.modifier_group,
                is_item_modifier: comb_mod.is_item_modifier
              }
              _modifier.push(combo);
            }
          }
        }

        for (let i = 0; i < item.modifiers.length; i++) {
          for (
            let j = 0;
            j < item.modifiers[i].modifier_modifier_group.length;
            j++
          ) {
            if (item.modifiers[i].modifier_modifier_group[j].checked && item.modifiers[i].modifier_modifier_group[j].checked > 0) {
              item.modifiers[i].modifier_modifier_group[j]['count'] = item.modifiers[i].modifier_modifier_group[j].checked
              if (item.modifiers[i].modifier_modifier_group[j].is_item_modifier && item.modifiers[i].modifier_modifier_group[j].modifiers) {

                let mod = item.modifiers[i].modifier_modifier_group[j]
                let nested_mods = []

                for (let k = 0; k < mod.modifiers.length; k++) {

                  nested_modifier_total += mod.modifiers[k].modifier_total_price * mod.checked
                  for (let l = 0; l < mod.modifiers[k].modifier_modifier_group.length; l++) {
                    if (mod.modifiers[k].modifier_modifier_group[l].count) {
                      _modifier.push({ ...mod.modifiers[k].modifier_modifier_group[l], _type: "new", is_nested: true })
                      nested_mods.push({ ...mod.modifiers[k].modifier_modifier_group[l], _type: "new" })

                      mod.modifiers[k].modifier_modifier_group[l].count = 0
                    }
                  }
                  mod.modifiers[k].modifier_total_price = 0
                  mod.modifiers[k].total_count = 0

                }
                console.log(mod, "2222222222222")
                let item_mod = {
                  names: JSON.stringify(mod.item_names),
                  count: mod.count,
                  price: mod.price,
                  _type: "new",
                  id: mod.id,
                  modifier_group: mod.modifier_group,
                  is_item_modifier: mod.is_item_modifier,
                  nested_modifier_total: nested_modifier_total,
                  nested_mods: nested_mods

                }
                _modifier.push(item_mod)

              } else {
                console.log(item.modifiers[i].modifier_modifier_group[j], "111111111")

                _modifier.push({ ...item.modifiers[i].modifier_modifier_group[j], _type: "new" });
              }
            }
          }
        }


        // if (item_modifier.modifiers) {
        //   _modifier = [..._modifier, ...item_modifier.modifiers]
        // }
        // return
        // item["modifier"] = _modifier;

        let all_children = [...this.props.allChildren];

        // combo_total = this.state.item_count * combo_total

        // nested_modifier_total = this.state.item_count * nested_modifier_total
        // item['modifier_total_price'] = item['modifier_total_price'] + nested_modifier_total

        let payload = {
          item: item.id,
          mid: Constants.deviceId,
          price: this.state.selectedPrice ? this.state.selectedPrice.id : this.state.item.price.length == 1 ? this.state.item.price[0].id : 0,
          modifiers: _modifier,
          quantity: this.state.item_count,
          uuid: this.state.uuid,
          platform: this.props.platform !== "qr" ? this.props.menu_type : this.props.platform,
          delivery_charge: this.props.config.delivery_charge,
          notes: this.state.instructions
        }
        console.log(payload, "pppppppppp")
        performRequest("post", "api/pwa/cart", payload)
          .then(response => {
            var index = _.findIndex(all_children, { id: this.state.item.id, type: "item" });
            all_children[index].count = all_children[index].count ? all_children[index].count + this.state.item_count : this.state.item_count;
            if (this.state.selectedPrice && all_children[index].price && all_children[index].price.length > 1) {
              all_children[index].price.find(item => item.id == this.state.selectedPrice.id).cart_item_id = response.data.item.cart_item_id
            } else {
              all_children[index].cart_item_id = response.data.item.cart_item_id
            }
            this.props.updateChildren(all_children);
            console.log(response.data, "responseeeeeeee")
            //    for (let i = 0; i < this.state.item_count; i++) {
            //   this.props.addCount();
            // }
            this.props.updateCartCount(this.state.item_count);
            let cart_items = [...this.props.cart, response.data.cart_item];
            this.props.allCart(cart_items);

            this.setState({ item_count: 0, item_modifiers: [] })
            this.props.totalPrice(response.data.total_price);

            // let all_data=[...all_children]
            // let item_selected_from_children = all_children.find(
            //       (itm) => itm.id === this.state.item.id && itm.type === "item"
            //     );

            // item_selected_from_children.count
            //   ? (item_selected_from_children.count =
            //     item_selected_from_children.count + this.state.item_count)
            //   : (item_selected_from_children.count = this.state.item_count); // updating all children count
            console.log(all_children, "allllllllllllllll")

            this.setState({ item: { ...all_children[index] } });
            this.setState({ cartLoading: false })

            this.makeModifiersFalse({ ...all_children[index] });

            this.props.navigation.goBack()

          })
          .catch(error => {
            console.log(error, "errorrrrrr")
            this.setState({ cartLoading: false })


          })


        // if (this.state.item.price.length > 1) {
        //   if (this.state.selectedPrice) {
        //     let item_selected_from_children = all_children.find(
        //       (itm) => itm.id === this.state.item.id && itm.type === "item"
        //     );
        //     item_selected_from_children.count
        //       ? (item_selected_from_children.count =
        //         item_selected_from_children.count + this.state.item_count)
        //       : (item_selected_from_children.count = this.state.item_count); // updating all children count
        //     this.props.updateChildren(all_children);
        //     this.setState({ item: item_selected_from_children });

        //     this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state
        //     for (let i = 0; i < this.state.item_count; i++) {
        //       this.props.addCount();
        //     }

        //     let price = this.props.total_price;
        //     let temp_price = this.state.selectedPrice.price;


        //     // item.modifier.map((mod_item) => (temp_price += mod_item.price));
        //     let final_amount = price + (temp_price * this.state.item_count)
        //     let modifier_total = 0
        //     // let item_modifier_total = 0
        //     if (item.modifier_total_price) {
        //       modifier_total = item.modifier_total_price * this.state.item_count
        //     }
        //     // if (item_modifier.amount) {
        //     //   item_modifier_total = item_modifier.amount * this.state.item_count
        //     // }
        //     final_amount = final_amount + modifier_total 
        //     // + nested_modifier_total
        //     // + item_modifier_total

        //     this.props.totalPrice(final_amount);
        //     // this.props.totalPrice(price + this.state.selectedPrice.price);

        //     let all_cart = [...this.props.cart];
        //     let if_item_exist = all_cart.find(
        //       (cartItem) =>
        //         cartItem.selected_price.id === this.state.selectedPrice.id &&
        //         JSON.stringify(item.modifier) ==
        //         JSON.stringify(cartItem.modifier)
        //     );
        //     if (if_item_exist) {
        //       if_item_exist.count += this.state.item_count;
        //       this.props.allCart(all_cart);
        //     } else {
        //       item.count = this.state.item_count;
        //       item["selected_price"] = this.state.selectedPrice;
        //       this.props.addToCart(item);
        //     }
        //   } else {
        //     this.throwMessageFn(
        //       i18n.t("labels.please_select_at_least_one_option"),
        //       "red"
        //     );
        //   }
        // } else if (this.state.item.price.length == 1) {
        //   this.setState({ selectedPrice: this.state.item.price[0] }, () => {
        //     let item_selected_from_children = all_children.find(
        //       (itm) => itm.id === this.state.item.id && itm.type === "item"
        //     );
        //     item_selected_from_children.count
        //       ? (item_selected_from_children.count =
        //         item_selected_from_children.count + this.state.item_count)
        //       : (item_selected_from_children.count = this.state.item_count); // updating all children count
        //     this.props.updateChildren(all_children);
        //     this.setState({ item: item_selected_from_children });

        //     this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state

        //     for (let i = 0; i < this.state.item_count; i++) {
        //       this.props.addCount();
        //     }




        //     let price = this.props.total_price;
        //     let temp_price = this.state.selectedPrice
        //       ? this.state.selectedPrice.price
        //       : 0;

        //     // item.modifier.map((mod_item) => (temp_price += mod_item.price));
        //     let final_amount = price + (temp_price * this.state.item_count)
        //     let modifier_total = 0
        //     // let item_modifier_total = 0
        //     if (item.modifier_total_price) {
        //       modifier_total = item.modifier_total_price * this.state.item_count
        //     }
        //     // if (item_modifier.amount) {
        //     //   item_modifier_total = item_modifier.amount * this.state.item_count
        //     // }

        //     // console.log(final_amount,modifier_total, nested_modifier_total , "mmmmmmmmmmmmmmmm" )
        //     final_amount = final_amount + modifier_total 
        //     // + nested_modifier_total
        //     // + item_modifier_total

        //     this.props.totalPrice(final_amount);


        //     let all_cart = [...this.props.cart];

        //     let if_item_exist = all_cart.find(
        //       (cartItem) =>
        //         cartItem.selected_price.id === this.state.selectedPrice.id &&
        //         JSON.stringify(item.modifier) ==
        //         JSON.stringify(cartItem.modifier)
        //     );
        //     if (if_item_exist) {
        //       if_item_exist.count += this.state.item_count;
        //       this.props.allCart(all_cart);
        //     } else {
        //       item.count = this.state.item_count;
        //       item["selected_price"] = this.state.selectedPrice;

        //       this.props.addToCart(item);
        //     }
        //   });
        // } else if (this.state.item.price.length == 0) {
        //   let _js = _.cloneDeep(this.state.item)
        //   // console.log(JSON.stringify({
        //   //   food_type: _js.food_type,
        //   //   id:_js.id,
        //   //   item_descriptions: _js.item_descriptions,
        //   //   item_names:_js.item_names,
        //   //   menu:_js.menu,
        //   //   modifier_total_price:_js.modifier_total_price,
        //   //   modifiers:_js.modifiers,
        //   //   preparation_time: _js.preparation_time,
        //   //   price: _js.price,
        //   //   section: _js.section,
        //   //   type: _js.type,
        //   // }), "itemmmmmmmmmmmmmm")
        //   this.setState(
        //     {
        //       selectedPrice: {
        //         // id: JSON.stringify(this.state.item),
        //         id: JSON.stringify({
        //           food_type: _js.food_type,
        //           id: _js.id,
        //           item_descriptions: _js.item_descriptions,
        //           item_names: _js.item_names,
        //           menu: _js.menu,
        //           modifier_total_price: _js.modifier_total_price,
        //           modifiers: _js.modifiers,
        //           preparation_time: _js.preparation_time,
        //           price: _js.price,
        //           section: _js.section,
        //           type: _js.type,
        //         }),
        //         price: 0,
        //         description: { [this.props.default_language]: "" },
        //         order: 0,
        //       },
        //     },
        //     () => {
        //       let item_selected_from_children = all_children.find(
        //         (itm) => itm.id === this.state.item.id && itm.type === "item"
        //       );
        //       item_selected_from_children.count
        //         ? (item_selected_from_children.count =
        //           item_selected_from_children.count + this.state.item_count)
        //         : (item_selected_from_children.count = this.state.item_count); // updating all children count
        //       this.props.updateChildren(all_children);
        //       this.setState({ item: item_selected_from_children });

        //       this.makeModifiersFalse(item_selected_from_children); // making modifiers to initial state

        //       for (let i = 0; i < this.state.item_count; i++) {
        //         this.props.addCount();
        //       }



        //       let price = this.props.total_price;
        //       let temp_price = this.state.selectedPrice
        //         ? this.state.selectedPrice.price
        //         : 0;


        //       let final_amount = price + (temp_price * this.state.item_count)
        //       let modifier_total = 0
        //       // let item_modifier_total = 0
        //       if (item.modifier_total_price) {
        //         modifier_total = item.modifier_total_price * this.state.item_count
        //       }
        //       // if (item_modifier.amount) {
        //       //   item_modifier_total = item_modifier.amount * this.state.item_count
        //       // }
        //       final_amount = final_amount + modifier_total 
        //       // + nested_modifier_total
        //       // + item_modifier_total
        //       this.props.totalPrice(final_amount);


        //       let all_cart = [...this.props.cart];

        //       let if_item_exist = all_cart.find(
        //         (cartItem) =>
        //           cartItem.selected_price.id === this.state.selectedPrice.id &&
        //           JSON.stringify(item.modifier) ==
        //           JSON.stringify(cartItem.modifier)
        //       );
        //       if (if_item_exist) {
        //         if_item_exist.count += this.state.item_count;
        //         this.props.allCart(all_cart);
        //       } else {
        //         item.count = this.state.item_count;
        //         item["selected_price"] = this.state.selectedPrice;

        //         this.props.addToCart(item);
        //       }
        //     }
        //   );
        // }

      } else {
        this.throwMessageFn("Select Modifiers", "red");
        this.scrollListRef.scrollTo({ x: 0, y: 0, animated: true })
      }

    });
  };

  // _renderRemoveSelectedItems = () => (
  //   <View
  //     style={{
  //       flex: 1,
  //       alignItems: "flex-end",
  //       justifyContent: "flex-end",
  //       backgroundColor: "transparent",
  //       // backdropFilter: "blur(5px)" 
  //       backgroundColor: "rgba(0, 0, 0, 0.5)"
  //     }}
  //   >
  //     <TouchableOpacity
  //       onPress={() => this.setState({ removeItemModal: false })}
  //     >
  //       <View
  //         style={{
  //           // height:Dimensions.get('window').height*.5,
  //           backgroundColor: "transparent",
  //           width: Dimensions.get("window").width,
  //           // backgroundColor:'rgba(0, 0, 0, 0.4)'
  //         }}
  //       />
  //     </TouchableOpacity>

  //     <View
  //       style={{
  //         width: Dimensions.get("window").width,
  //         backgroundColor: "white",
  //         borderWidth: 1,
  //         justifyContent: "center",
  //         borderColor: "rgba(0, 0, 0, 0.1)",
  //       }}
  //     >
  //       <View
  //         style={{
  //           height: 40,
  //           width: "100%",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //           borderBottomWidth: 1,
  //           borderBottomColor: "rgba(12, 10, 10, 0.2)",
  //           flexDirection: "row",
  //         }}
  //       >
  //         <StyledText style={{ marginLeft: 10,fontFamily:'SecondaryFont' }}>
  //           {this.state.selectedItem
  //             ? <IntlLabel data={this.state.selectedItem.item_names} /> 
  //             : null}
  //         </StyledText>
  //         <TouchableOpacity
  //           onPress={() => this.setState({ removeItemModal: false })}
  //         >
  //           <View style={style.closeButton}>
  //             <Icon name={"close"} color={"#F44336"} size={24} />
  //           </View>
  //         </TouchableOpacity>
  //       </View>
  //       <ScrollView>
  //         {this.state.itemsToRemove.map((item) => (
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 55,
  //               marginBottom: 5,
  //               flexDirection: "row",
  //               justifyContent: "space-between",
  //               alignItems: "center",
  //             }}
  //           >
  //             <View style={{ marginLeft: 8 }}>
  //               <StyledText style={{   letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontWeight: this.props.design.custom_font.card_body_is_bold ? "bold" : 500, fontSize:  this.props.design.custom_font.card_body_size ?? 12, fontFamily:'SecondaryFont' }}>
  //                 <IntlLabel data={item.item_names} /> 
  //               </StyledText>
  //               <StyledText style={{  letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontWeight: this.props.design.custom_font.card_body_is_bold ? "bold" : 400, fontSize:  this.props.design.custom_font.card_body_size ?? 10, fontFamily:'SecondaryFont' }}>
  //                 {this.props.config.currency} {item.selected_price.price}
  //               </StyledText>
  //               <StyledText style={{   letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontWeight: this.props.design.custom_font.card_body_is_bold ? "bold" : "normal", fontSize: this.props.design.custom_font.card_body_size ?? 10, fontFamily:'SecondaryFont' }}>
  //                 option:{" "}
  //                 <IntlLabel data={item.selected_price.description} /> 
  //               </StyledText>
  //               <View style={{ flexDirection: "row" }}>
  //                 <StyledText style={{   letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontWeight: this.props.design.custom_font.card_body_is_bold ? "bold" : "normal", fontSize:  this.props.design.custom_font.card_body_size ?? 10 }}>Extra : </StyledText>
  //                 {item.modifier !== undefined
  //                   ? item.modifier.map((item) => (
  //                     <StyledText
  //                       style={{
  //                         fontSize: this.props.design.custom_font.card_body_size ?? 10,
  //                         fontWeight: this.props.design.custom_font.card_body_is_bold ? "bold" : "normal",
  //                         letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
  //                         fontStyle: "italic",
  //                         writingDirection: "rtl",
  //                         marginLeft: 10,
  //                         color: "grey",
  //                         fontFamily:'SecondaryFont'
  //                       }}
  //                     >
  //                       <IntlLabel data={JSON.parse(item.names)} /> 
  //                     </StyledText>
  //                   ))
  //                   : null}
  //               </View>
  //             </View>
  //             <View>
  //               <CartButton
  //                 config={this.props.config}
  //                 item={item}
  //                 removeItemFromCartFn={this.removeItemCount}
  //                 onRemoveItem={this.removeFromCart}
  //                 removeItemModal={this.state.removeItemModal}
  //                 increaseItemCount={() => this.increaseItemCount(item)}
  //                 design={this.props.design}
  //               />
  //             </View>
  //           </View>
  //         ))}
  //       </ScrollView>
  //     </View>
  //   </View>
  // );

  increaseItemCount = (item) => {
    item.count = item.count + 1;
    let all_cart = [...this.props.cart];
    this.props.removeItemFromCart(all_cart);

    // updating cart count in all children
    let all_children = [...this.props.allChildren];
    let selected_item = all_children.find(
      (itm) => itm.id == item.id && item.type === "item"
    );
    selected_item.count += 1;
    this.props.updateChildren(all_children);

    // add total price
    let price = this.props.total_price;
    let temp_price = item.selected_price.price;
    item.modifier.map((mod_price) => (temp_price += mod_price.price));
    this.props.totalPrice(price + temp_price);
    // this.props.totalPrice(price + item.selected_price.price);
    // ===================

    this.props.addCount();
  };

  removeItemCount = (item) => {
    this.props.subtractCount();

    // substract total price
    let price = this.props.total_price;
    let temp_price = item.selected_price.price;
    item.modifier.map((mod_price) => (temp_price += mod_price.price));
    this.props.totalPrice(price - temp_price);
    // this.props.totalPrice(price - item.selected_price.price);
    // ======================

    item.count = item.count - 1;
    let find_item = this.props.allChildren.find((itm) => itm.id === item.id);
    find_item["count"] =
      find_item["count"] > 1 ? find_item.count - 1 : delete find_item["count"];
    let all_childrens = [...this.props.allChildren];
    this.props.updateChildren(all_childrens);
    // ========================
  };

  _onPlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.didJustFinish) {
      this.setState({ itemVideoPlay: false });
    }
  };

  // onPressRemove = (item) => {
  //   let all_items = [...this.props.allChildren];
  //   let cart = [...this.props.cart];

  //   if (this.state.item.count > 0) {
  //     if (this.state.item.count > 1) {
  //       let items_to_remove = cart.filter(
  //         (cart_item) => cart_item.id == item.id
  //       );
  //       this.setState({
  //         removeItemModal: true,
  //         itemsToRemove: items_to_remove,
  //       });
  //     } else {
  //       // removing item from main cart
  //       let cart_items = [...this.props.cart];
  //       let sorted_cart = cart_items.filter(
  //         (cart_item) => cart_item.id !== item.id
  //       );
  //       this.props.removeItemFromCart(sorted_cart);
  //       // =====================

  //       this.props.subtractCount();

  //       // substract total price
  //       let price = this.props.total_price;
  //       let find_item_in_cart = cart_items.find((itm) => itm.id === item.id);
  //       let temp_price = find_item_in_cart.selected_price.price;
  //       find_item_in_cart.modifier.map(
  //         (mod_item) => (temp_price += mod_item.price)
  //       );
  //       this.props.totalPrice(price - temp_price);
  //       // this.props.totalPrice(price - find_item_in_cart.selected_price.price);
  //       // ===========

  //       let find_item = all_items.find((itm) => itm.id === item.id);
  //       delete find_item.count;
  //       this.props.updateChildren(all_items);
  //     }
  //   } else {
  //     this.throwMessageFn("Nothing to delete", "red");
  //   }
  // };

  removeFromCart = (item) => {
    this.props.subtractCount();

    // substract total price
    let price = this.props.total_price;
    let temp_price = item.selected_price.price;
    item.modifier.map((mod_item) => (temp_price += mod_item.price));
    this.props.totalPrice(price - temp_price);
    // this.props.totalPrice(price - item.selected_price.price);
    // ===========

    // reducing item count from main counter
    let all_children = [...this.props.allChildren];
    let find_item = all_children.find((itm) => itm.id === item.id);
    // delete find_item.count;
    if (find_item.count > 1) {
      find_item.count = find_item.count - 1;
    } else {
      delete find_item.count;
    }
    this.props.updateChildren(all_children);

    let all_cart = [...this.props.cart];
    let sorted_cart = all_cart.filter(
      (cart_item) =>
        cart_item.selected_price.id !== item.selected_price.id ||
        JSON.stringify(cart_item.modifier) != JSON.stringify(item.modifier)
    );
    this.props.removeItemFromCart(sorted_cart);

    let sorded_selected_item = this.state.itemsToRemove.filter(
      (selected_item) => {
        return (
          JSON.stringify(selected_item.modifier) !=
          JSON.stringify(item.modifier) ||
          selected_item.selected_price.id !== item.selected_price.id
        );
      }
    );

    this.setState({ itemsToRemove: sorded_selected_item });
    if (sorded_selected_item.length == 0) {
      this.setState({ removeItemModal: false });
    }
    // ========================
  };
  priceLoader = (price) => {
    if (THREE_FRACTION_CURRENCY.includes(this.props.config.currency)) {
      return price ? price.toFixed(3) : "0.000";
    } else {
      if (this.props.config.currency == "¥") {
        return price ? parseInt(price) : "0";
      } else {
        return price ? price.toFixed(2) : "0.00";
      }
    }
  };

  webview = (item, htmlstyles) => {
    try {
      if (item.item_descriptions[this.props.default_language] != "")
        return (
          <HTMLView
            value={
              item.item_descriptions
                ? IntlLabelFn(item.item_descriptions, this.props.config.default_language.value, this.props.default_language)
                : ""
            }
            stylesheet={htmlstyles}
          />
        );
      else
        return (
          <Text
            numberOfLines={2}
            style={{
              marginLeft: 9,
              marginTop: 9,
              fontWeight: 460,
              fontFamily: 'SecondaryFont'
            }}
          >
            {/* Rose natural syrup, double shot of coffee, choice of milk (skim,
              full fat, almond) */}
          </Text>
        );
    } catch (error) {
      console.log(error);
    }
  };
  onIncreaseItemModifierCount = (parentIndex, parentItem) => {
    let item = [...this.state.item_modifiers];

    if (item[parentIndex]["count"]) {
      item[parentIndex]["count"] += 1;
    } else {
      item[parentIndex]["count"] = 1;
    }
    this.setState({ item_modifiers: item });
  }
  onDecreaseItemModifierCount = (parentIndex, parentItem) => {
    let item = [...this.state.item_modifiers];

    if (item[parentIndex]["count"]) {
      item[parentIndex]["count"] -= 1;
    } else {
      item[parentIndex]["count"] = 0;
    }


    this.setState({ item_modifiers: item });
  }
  onDecreaseItemPriceModifierCount = (parentIndex, parentItem, parentPriceIndex, parentPriceItem) => {
    let item = [...this.state.item_modifiers];
    if (item[parentIndex].price[parentPriceIndex]["count"]) {
      item[parentIndex].price[parentPriceIndex]["count"] -= 1;
      item[parentIndex]["total_count"] -= 1;
    } else {
      item[parentIndex].price[parentPriceIndex]["count"] = 0;
      item[parentIndex]["total_count"] = 0;
    }

    if (!item[parentIndex].modifier_total_price) {
      item[parentIndex]["modifier_total_price"] = 0;
    } else {
      item[parentIndex].modifier_total_price -= item[parentIndex].price[parentPriceIndex]["price"];
    }
    this.setState({ item_modifiers: item });
  }

  onIncreaseItemPriceModifierCount = (parentIndex, parentItem, parentPriceIndex, parentPriceItem) => {
    let item = [...this.state.item_modifiers];

    item[parentIndex]["required_indicator"] = false;


    if (item[parentIndex].price[parentPriceIndex]["count"]) {
      item[parentIndex].price[parentPriceIndex]["count"] += 1;
    } else {
      item[parentIndex].price[parentPriceIndex]["count"] = 1;
    }

    if (item[parentIndex]['total_count']) {
      item[parentIndex]["total_count"] += 1;
    } else {
      item[parentIndex]["total_count"] = 1;
    }

    if (!item[parentIndex].modifier_total_price) {
      item[parentIndex]["modifier_total_price"] = item[parentIndex].price[parentPriceIndex]["price"];
    } else {
      item[parentIndex]["modifier_total_price"] += item[parentIndex].price[parentPriceIndex]["price"];
    }
    this.setState({ item_modifiers: item });

  }

  // itemPriceModifiersCount = (parentIndex, parentItem, parentPriceIndex, parentPriceItem) => {
  //   return (
  //     <View
  //       style={{
  //         // width: "100%",
  //         justifyContent: "center",
  //         flexDirection: "row",
  //       }}
  //     >

  //       <TouchableOpacity
  //         // style={{ marginBottom: 50 }}
  //         onPress={() => this.onDecreaseItemPriceModifierCount(parentIndex, parentItem, parentPriceIndex, parentPriceItem)}
  //         disabled={!parentItem.price[parentPriceIndex]["count"] || parentItem.price[parentPriceIndex]["count"] == 0}
  //       >
  //         <View
  //           style={{
  //             width: 25,
  //             height: 25,
  //             backgroundColor:
  //               this.props.design.top_buttons_background_color,
  //             borderRadius: 8,
  //             margin: 10,
  //             marginRight: 15,
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Icon
  //             color={this.props.design.top_buttons_text_color}
  //             size={15}
  //             name={"minus"}
  //           />
  //         </View>
  //       </TouchableOpacity>

  //       <View
  //         style={{
  //           alignItems: "center",
  //           justifyContent: "center",
  //           // marginBottom: 50,
  //         }}
  //       >
  //         <StyledText
  //           style={{
  //             color: this.props.design.item_page_description_color,
  //             fontFamily:'SecondaryFont'
  //           }}
  //         >
  //           {parentItem.price[parentPriceIndex]["count"] ? parentItem.price[parentPriceIndex]["count"] : 0}
  //           {/* {item.count ? item.count : 0} */}
  //         </StyledText>
  //       </View>

  //       <TouchableOpacity
  //         onPress={() => this.onIncreaseItemPriceModifierCount(parentIndex, parentItem, parentPriceIndex, parentPriceItem)}
  //         disabled={parseInt(parentItem.max) != 0 && parseInt(parentItem.max) === parentItem["total_count"]}

  //       // disabled={config.general_qr.qr_id == this.state.uuid}
  //       >
  //         <View
  //           style={{
  //             width: 25,
  //             height: 25,
  //             backgroundColor:
  //               this.props.design.top_buttons_background_color,
  //             borderRadius: 8,
  //             margin: 10,
  //             marginRight: 15,
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Icon
  //             color={this.props.design.top_buttons_text_color}
  //             size={15}
  //             name={"plus"}
  //           />
  //         </View>
  //       </TouchableOpacity>
  //     </View>
  //   )
  // }
  // itemModifiersCount = (parentIndex, parentItem) => {

  //   return (
  //     <View
  //       style={{
  //         // width: "100%",
  //         justifyContent: "center",
  //         flexDirection: "row",
  //       }}
  //     >

  //       <TouchableOpacity
  //         // style={{ marginBottom: 50 }}
  //         onPress={() => this.onDecreaseItemModifierCount(parentIndex, parentItem)}
  //         disabled={!parentItem["count"] || parentItem["count"] == 0}
  //       >
  //         <View
  //           style={{
  //             width: 25,
  //             height: 25,
  //             backgroundColor:
  //               this.props.design.top_buttons_background_color,
  //             borderRadius: 8,
  //             margin: 10,
  //             marginRight: 15,
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Icon
  //             color={this.props.design.top_buttons_text_color}
  //             size={15}
  //             name={"minus"}
  //           />
  //         </View>
  //       </TouchableOpacity>

  //       <View
  //         style={{
  //           alignItems: "center",
  //           justifyContent: "center",
  //           // marginBottom: 50,
  //         }}
  //       >
  //         <StyledText
  //           style={{
  //             color: this.props.design.item_page_description_color,
  //             fontFamily:'SecondaryFont'
  //           }}
  //         >
  //           {parentItem["count"] ? parentItem["count"] : 0}
  //         </StyledText>
  //       </View>

  //       <TouchableOpacity
  //         onPress={() => this.onIncreaseItemModifierCount(parentIndex, parentItem)}
  //         disabled={parseInt(parentItem.max) != 0 && parseInt(parentItem.max) === parentItem.count}

  //       // disabled={config.general_qr.qr_id == this.state.uuid}
  //       >
  //         <View
  //           style={{
  //             width: 25,
  //             height: 25,
  //             backgroundColor:
  //               this.props.design.top_buttons_background_color,
  //             borderRadius: 8,
  //             margin: 10,
  //             marginRight: 15,
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Icon
  //             color={this.props.design.top_buttons_text_color}
  //             size={15}
  //             name={"plus"}
  //           />
  //         </View>
  //       </TouchableOpacity>
  //     </View>
  //   )
  // }
  onDecreaseModifierItemCount = (parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex) => {
    let item = { ...this.state.item };

    // parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_modifier_group[childPIndex]['count']

    let count = item.modifiers[parentIndex].
      modifier_modifier_group[childIndex].modifiers[parentPIndex].
      modifier_modifier_group[childPIndex]['count']

    if (count) {
      count -= 1;
    } else {
      count = 0;
    }

    item.modifiers[parentIndex].
      modifier_modifier_group[childIndex].modifiers[parentPIndex].
      modifier_modifier_group[childPIndex]['count'] = count

    let total_price = item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_total_price
    if (!total_price) {
      total_price = 0;
    } else {
      total_price -= childItem['price'];
    }

    item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_total_price = total_price

    let total_count = item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].total_count

    if (total_count) {
      total_count -= 1;
    } else {
      total_count = 0;
    }

    item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].total_count = total_count
    this.setState({ item });

  }
  onIncreaseModifierItemCount = (parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex) => {
    let item = { ...this.state.item };

    // item.modifiers[parentIndex].
    // modifier_modifier_group[childIndex].modifiers[parentPIndex].
    // modifier_modifier_group[childPIndex]['count']

    item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex]["required_indicator"] = false;

    let count = item.modifiers[parentIndex].
      modifier_modifier_group[childIndex].modifiers[parentPIndex].
      modifier_modifier_group[childPIndex]['count']

    if (count) {
      count += 1;
    } else {
      count = 1;
    }
    item.modifiers[parentIndex].
      modifier_modifier_group[childIndex].modifiers[parentPIndex].
      modifier_modifier_group[childPIndex]['count'] = count

    let total_price = item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_total_price
    // console.log(
    //   item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_total_price,
    //   childItem['price'],childPItem,
    //    "aaaaaaaaaaaaa")

    if (!total_price) {
      total_price = childPItem['price'];
    } else {
      total_price += childPItem['price'];
    }
    console.log(total_price, "uuuuuuuuuuuuu")
    item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_total_price = total_price

    let total_count = item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].total_count

    if (total_count) {
      total_count += 1;
    } else {
      total_count = 1;
    }
    item.modifiers[parentIndex].modifier_modifier_group[childIndex].modifiers[parentPIndex].total_count = total_count

    this.setState({ item });
  }
  // onDecreaseModifierItemCount = (parentIndex, parentItem, parentPIndex, parentPItem, childIndex, childItem) => {
  //   let item = [...this.state.item_modifiers];




  //   if (item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"]) {
  //     item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"] -= 1;
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"] = 0;
  //   }

  //   if (!item[parentIndex].modifiers[parentPIndex].modifier_total_price) {
  //     item[parentIndex].modifiers[parentPIndex]["modifier_total_price"] = 0;
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex]["modifier_total_price"] -= childItem['price'];
  //   }

  //   if (item[parentIndex].modifiers[parentPIndex]["total_count"]) {
  //     item[parentIndex].modifiers[parentPIndex]["total_count"] -= 1;
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex]["total_count"] = 0;
  //   }

  //   this.setState({ item_modifiers: item });

  // }
  // onIncreaseModifierItemCount = (parentIndex, parentItem, parentPIndex, parentPItem, childIndex, childItem) => {
  //   let item = [...this.state.item_modifiers];

  //   console.log()
  //   item[parentIndex].modifiers[parentPIndex]["required_indicator"] = false;


  //   if (item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"]) {
  //     item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"] += 1;
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex].modifier_modifier_group[childIndex]["count"] = 1;
  //   }

  //   if (!item[parentIndex].modifiers[parentPIndex].modifier_total_price) {
  //     item[parentIndex].modifiers[parentPIndex].modifier_total_price = childItem['price'];
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex].modifier_total_price += childItem['price'];
  //   }

  //   if (item[parentIndex].modifiers[parentPIndex]["total_count"]) {
  //     item[parentIndex].modifiers[parentPIndex]["total_count"] += 1;
  //   } else {
  //     item[parentIndex].modifiers[parentPIndex]["total_count"] = 1;
  //   }


  //   this.setState({ item_modifiers: item });
  // }
  modifierItemCount = (parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex) => {
    return (
      <View
        style={{
          // width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >

        <TouchableOpacity
          onPress={() => this.onDecreaseModifierItemCount(parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex)}
          disabled={!parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_modifier_group[childPIndex]['count']}
        >

          <View
            style={{
              width: 25,
              height: 25,
              backgroundColor:
                this.props.design.top_buttons_background_color,
              borderRadius: 8,
              margin: 10,
              marginRight: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              color={this.props.design.top_buttons_text_color}
              size={15}
              name={"minus"}
            />
          </View>
        </TouchableOpacity>

        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 50,
          }}
        >
          <StyledText
            font_color={this.props.design.item_page_description_color}
            font_weight={this.props.design.item_page_description_font_weight}
            font_style={this.props.design.item_page_description_font_style}
            text_transform={this.props.design.item_page_description_font_transform}
            font_family={this.props.design.item_page_description_font_family}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            font_size={this.props.design.item_page_description_font_size}

            style={{}}
          >
            {
              parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_modifier_group[childPIndex]['count'] || 0
            }
          </StyledText>
        </View>
        {/* parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].modifier_modifier_group[childPIndex]['count'] */}
        <TouchableOpacity
          onPress={() => this.onIncreaseModifierItemCount(parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex)}
          disabled={parseInt(parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].max) != 0 && parseInt(parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex].max) === parentItem.modifier_modifier_group[childIndex].modifiers[parentPIndex]["total_count"] || !parentItem.checked_count}
        >
          <View
            style={{
              width: 25,
              height: 25,
              backgroundColor:
                this.props.design.top_buttons_background_color,
              borderRadius: 8,
              margin: 10,
              marginRight: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              color={this.props.design.top_buttons_text_color}
              size={15}
              name={"plus"}
            />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  modifiersCount = (parentIndex,
    childIndex,
    childItemPrice, parentItem, item, type) => {

    return (
      <View
        style={{
          // width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >

        <TouchableOpacity
          // style={{ marginBottom: 50 }}
          onPress={() => this.onDecreaseModifierCount(parentIndex,
            childIndex,
            childItemPrice, parentItem, type)}
          disabled={
            !item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] ||
            item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] == 0
          }
        >
          <View
            style={{
              width: 25,
              height: 25,
              backgroundColor:
                this.props.design.top_buttons_background_color,
              borderRadius: 8,
              margin: 10,
              marginRight: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              color={this.props.design.top_buttons_text_color}
              size={15}
              name={"minus"}
            />
          </View>
        </TouchableOpacity>

        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 50,
          }}
        >
          <StyledText
            font_color={this.props.design.item_page_description_color}
            font_weight={this.props.design.item_page_description_font_weight}
            font_style={this.props.design.item_page_description_font_style}
            text_transform={this.props.design.item_page_description_font_transform}
            font_family={this.props.design.item_page_description_font_family}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            font_size={this.props.design.item_page_description_font_size}
            style={{

            }}
          >
            {
              item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] ?
                item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] : 0
            }
            {/* {item.count ? item.count : 0} */}
          </StyledText>
        </View>

        <TouchableOpacity
          onPress={() => this.onIncreaseModifierCount(parentIndex,
            childIndex,
            childItemPrice, type)}
          disabled={
            (parentItem.max != 0 && parentItem.max === parentItem.checked_count) ||
            (item[type][parentIndex].modifier_modifier_group[childIndex]["max"] != 0 && item[type][parentIndex].modifier_modifier_group[childIndex]["max"] == item[type][parentIndex].modifier_modifier_group[childIndex].checked)
          }

        // disabled={config.general_qr.qr_id == this.state.uuid}
        >
          <View
            style={{
              width: 25,
              height: 25,
              backgroundColor:
                this.props.design.top_buttons_background_color,
              borderRadius: 8,
              margin: 10,
              marginRight: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              color={this.props.design.top_buttons_text_color}
              size={15}
              name={"plus"}
            />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  onIncreaseModifierCount = (parentIndex,
    childIndex,
    childPrice, type) => {
    let item = { ...this.state.item };
    item[type][parentIndex]["required_indicator"] = false;


    if (item[type][parentIndex].modifier_modifier_group[childIndex]["checked"]) {
      item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] += 1;
    } else {
      item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] = 1;
    }

    if (!item.modifier_total_price) {
      item["modifier_total_price"] = childPrice;
    } else {
      item.modifier_total_price += childPrice;
    }
    if (item[type][parentIndex]["checked_count"]) {
      item[type][parentIndex]["checked_count"] += 1;
    } else {
      item[type][parentIndex]["checked_count"] = 1;
    }
    this.setState({ item: item });


  }
  onDecreaseModifierCount = (parentIndex,
    childIndex,
    childPrice, parentItem, type) => {
    let item = { ...this.state.item };


    if (item[type][parentIndex].modifier_modifier_group[childIndex]["checked"]) {
      item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] -= 1;
    } else {
      item[type][parentIndex].modifier_modifier_group[childIndex]["checked"] = 0;
    }

    if (!item.modifier_total_price) {
      item["modifier_total_price"] = 0;
    } else {
      item.modifier_total_price -= childPrice;
    }
    if (item[type][parentIndex]["checked_count"]) {
      item[type][parentIndex]["checked_count"] -= 1;
    } else {
      item[type][parentIndex]["checked_count"] = 0;
    }
    this.setState({ item: item });
  }
  makeLike = () => {
    axios.post(API_URL + "api/v2/receipe/like", {
      item: this.state.item.id,
      device: Constants.deviceId,
      venue: this.props.config.venue,
      is_liked: !this.state.isLiked
    })
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.log(error.response)

      })
    this.setState({ isLiked: !this.state.isLiked })
  }

  checkDisabled = () => {
    const { config, platform } = this.props;
    if (platform == "qr") {
      if (
        config.general_qr.qr_id == this.state.uuid ||
        !config.accepting_orders || this.state.item.snooze
      ) {
        return true;
      }
      return false;
    } else {
      if (
        !config.accepting_orders || this.state.item.snooze
      ) {
        return true;
      }
      return false;
    }
  };
  showFoodType = (type, imgStyle) => {
    if (type) {
      if (type == "5") {
        return <Image
          style={[imgStyle], {
            position: "absolute",
            bottom: 5,
            left: 5, height: 16, width: 16, zIndex: 1000
          }}
          source={require("./../../assets/non.png")}
        />
      } else {
        return <Icon
          name={"veg"}
          style={{
            position: "absolute",
            bottom: 5,
            left: 5,
            color:
              type == 1
                ? "green"
                : type == 2
                  ? "brown"
                  : type == 3
                    ? "yellow"
                    : "transparent",
            fontSize: 16,
            zIndex: 1000
          }}
        />
      }
    } else {
      return <></>
    }
  }


  clickHappens = (id) => () => {
    console.log(id, "----")
    this.setState(prevState => ({
      clicked: prevState.clicked === id ? null : id, // <-- toggle back to null or to new id
    }));
  }


  showMoreExtras(itemIndex, type) {
    console.log(type, "-----TYPE");
    let item = { ...this.state.item };
    item[type][itemIndex].isExpanded = !item[type][itemIndex].isExpanded;
    this.setState({ item });
  }


  render() {
    const { item, itemVideoPlay, shouldPlay } = this.state;
    const { config, design } = this.props;

    var htmlstyles = StyleSheet.create({
      a: {
        //fontWeight: "300",
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        marginVertical: 5,


      },
      p: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        fontFamily: this.props.design.item_page_description_font_family,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        color: this.props.design.item_page_description_color,
        position: "relative",
        margin: 1,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
      },
      strong: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        fontFamily: this.props.design.item_page_description_font_family,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
      },
      h1: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      h2: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      h3: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      h4: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      h5: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      div: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
      body: {
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        fontFamily: this.props.design.item_page_description_font_family,
      },
    });

    var htmlstylesR = StyleSheet.create({
      a: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,

        color: design.grid_view_text_color,
        marginVertical: 5,
      },
      p: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,

        color: design.grid_view_text_color,
        position: "relative",
        margin: 1,
      },
      strong: {
        fontWeight: "bold",
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        color: design.grid_view_text_color,
      },
      h1: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 10,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,

        color: design.grid_view_text_color,
      },
      h2: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        color: design.grid_view_text_color,
      },
      h3: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        color: design.grid_view_text_color,
      },
      h4: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        color: design.grid_view_text_color,
      },
      h5: {
        fontWeight: this.props.design.item_page_description_font_weight ?? 300,
        fontSize: this.props.design.custom_font.item_page_description_font_size ?? 13,
        letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
        fontFamily: this.props.design.item_page_description_font_family,
        color: this.props.design.item_page_description_color,
        textTransform: this.props.design.item_page_description_font_transform,
        fontStyle: this.props.design.item_page_description_font_style,
        color: design.grid_view_text_color,
      }

    });
    // const image_api = "https://d2ceauryvxkzea.cloudfront.net/fit-in/800x800/"
    // const image_api_high_res = "https://d2ceauryvxkzea.cloudfront.net/fit-in/350x350/"

    let imgStyle = !this.props.isRTL
      ? {
        borderBottomLeftRadius: 6,
        borderTopLeftRadius: 6,
      }
      : {
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
      };
    return (
      <View
        style={{
          backgroundColor: this.props.design.item_page_background_color,
          flex: 1,
        }}
      >

        <View style={{ height: height * 0.9 }} >

          <ScrollView

            // style={{marginBottom: 70}} 
            ref={(ref) => { this.scrollListRef = ref; }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
                width: "100%",
                backgroundColor: "#FFF",
              }}
            >

              {
                this.state.section && (this.state.section.show_item_image || this.state.section.type == "menu") &&
                <View style={{ width: "100%", flex: 2.5 }}>
                  {item.video ? (
                    itemVideoPlay ? (
                      <>
                        <video
                          ref={(r) => (this._handleVideoRef = r)}
                          // poster={image_api +
                          //           item.image.split("/").splice(3).join("/") +
                          //           "?w=200&h=200"}  
                          onEnded={() => this.setState({ itemVideoPlay: false })}
                          autoplay={this.state.setAutoplay.toString()}
                          playsinline="true"
                          preload="true"
                          width={"100%"}
                          height="100%"
                          style={{
                            objectFit: "fill"
                            // width: "100%",
                            // height: "auto",
                            // maxHeight: "100%"
                          }}
                        >
                          <source src={item.video} />

                        </video>
                        <div style={{ position: "absolute", width: "100%", height: "100%", opacity: !this.state.setAutoplay ? 1 : 0, transition: "opacity 0.7s" }}>
                          <ProgressiveImage
                            thumbnailSource={
                              item.image
                                ? {
                                  uri:
                                    image_api +
                                    item.image.split("/").splice(3).join("/") +
                                    "?w=200&h=200",
                                }
                                :
                                this.props.design.show_default_item_image && this.props.design.default_item_image ?
                                  {
                                    uri:
                                      image_api +
                                      this.props.design.default_item_image.split("/").splice(3).join("/") +
                                      "?w=200&h=200",
                                  }
                                  :
                                  require("./../../assets/food.png")
                            }
                            source={
                              item.image
                                ? {
                                  uri:
                                    image_api +
                                    item.image.split("/").splice(3).join("/") +
                                    "?w=600&h=600",
                                }
                                : require("./../../assets/food.png")
                            }
                            style={{ width: "100%", height: "100%" }}
                            resizeMode="cover"
                          />
                        </div>
                        {
                          this.showFoodType(item.food_type, imgStyle)
                        }
                      </>
                    ) : (
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ProgressiveImage
                          thumbnailSource={
                            item.image
                              ? {
                                uri:
                                  image_api +
                                  item.image.split("/").splice(3).join("/") +
                                  "?w=200&h=200",
                              }
                              :
                              this.props.design.show_default_item_image && this.props.design.default_item_image ?
                                {
                                  uri:
                                    image_api +
                                    this.props.design.default_item_image.split("/").splice(3).join("/") +
                                    "?w=200&h=200",
                                }
                                :
                                require("./../../assets/food.png")
                          }
                          source={
                            item.image
                              ? {
                                uri:
                                  image_api +
                                  item.image.split("/").splice(3).join("/") +
                                  "?w=600&h=600",
                              }
                              : require("./../../assets/food.png")
                          }
                          style={{ width: "100%", height: "100%" }}
                          resizeMode="cover"
                        />
                        {
                          this.showFoodType(item.food_type, imgStyle)
                        }

                        <TouchableOpacity
                          onPress={() => {
                            this.setState({ itemVideoPlay: true })
                          }}
                          activeOpacity={1}
                          style={{
                            position: "absolute",
                            // marginTop: 80 
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: "#1515158f",
                              borderRadius: 100,
                              borderWidth: 8,
                              borderColor: "#f9f9f99c",
                              width: 100,
                              height: 100,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Icon
                              name="play"
                              style={{
                                fontSize: 70,
                                color: "#f9f9f99c",
                                marginLeft: 11,
                              }}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    )
                  ) : (
                    <ProgressiveImage
                      thumbnailSource={
                        item.image
                          ? {
                            uri:
                              image_api +
                              item.image.split("/").splice(3).join("/") +
                              "?w=200&h=200",
                          }
                          :
                          this.props.design.show_default_item_image && this.props.design.default_item_image ?
                            {
                              uri:
                                image_api +
                                this.props.design.default_item_image.split("/").splice(3).join("/") +
                                "?w=200&h=200",
                            }
                            :
                            require("./../../assets/food.png")
                      }
                      source={
                        item.image
                          ? {
                            uri:
                              image_api +
                              item.image.split("/").splice(3).join("/") +
                              "?w=600&h=600",
                          }
                          :
                          this.props.design.show_default_item_image && this.props.design.default_item_image ?
                            {
                              uri:
                                image_api +
                                this.props.design.default_item_image.split("/").splice(3).join("/") +
                                "?w=200&h=200",
                            }
                            :
                            require("./../../assets/food.png")
                      }
                      // source={item.image ? {uri: item.image.split('/').splice(3).join('/') + "?w=200&h=200" } : require('./../../assets/food.png')}
                      style={{ width: "100%", height: "100%" }}
                      resizeMode="cover"
                    />

                  )

                  }
                  {
                    this.showFoodType(item.food_type, imgStyle)
                  }

                </View>
              }

              <View
                style={{
                  flex: 4,
                  backgroundColor: this.props.design.item_page_background_color,
                  alignItems: "center",
                  marginTop: (this.state.section && !this.state.section.show_item_image) && 40
                }}
              >
                {
                  this.props.config.show_favourite &&
                  <View style={{ height: 25, width: 25, position: 'absolute', right: 8, top: 8 }} >
                    <Heart activeColor={design.top_buttons_background_color} inactiveColor={design.top_buttons_background_color} isActive={this.state.isLiked} onClick={() => this.makeLike()} />
                  </View>
                }

                <StyledText
                  font_color={this.props.design.item_page_heading_color}
                  font_weight={this.props.design.item_page_heading_font_weight}
                  font_style={this.props.design.item_page_heading_font_style}
                  text_transform={this.props.design.item_page_heading_font_transform}
                  font_family={this.props.design.item_page_heading_font_family}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  font_size={this.props.design.item_page_heading_font_size}

                  style={{
                    marginHorizontal: 8,
                    marginTop: 15,
                  }}
                >
                  {item.mark_item_as_new && (
                    <Image
                      source={require("../../assets/new.png")}
                      style={style.iconStyle}
                    />
                  )}

                  {item.signature && (
                    <Image
                      source={require("../../assets/signature.png")}
                      style={style.iconStyle}
                    />
                  )}
                  {item.item_names
                    ? <IntlLabel data={item.item_names} />
                    : ""}

                </StyledText>

                <View style={{ justifyContent: "center", alignItems: "center", width: "70%", maxWidth: "380px", marginBottom: "7px" }}>
                  <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                      {item.chefs_special && (
                        <ItemLabel
                          page={'item_detail'}
                          design={this.props.design}
                          // backgroundColor={this.props.design.top_buttons_background_color}
                          // textColor={this.props.design.top_buttons_text_color}
                          // pillFontSize={ this.props.design.custom_font.pill_size}
                          // custom_font={this.props.design.custom_font}  
                          textMessage={i18n.t("labels.chefs_special")} />
                      )}

                      {item.best_seller && (
                        <ItemLabel
                          page={'item_detail'}
                          design={this.props.design}
                          // backgroundColor={this.props.design.top_buttons_background_color}
                          // textColor={this.props.design.top_buttons_text_color}
                          // pillFontSize={ this.props.design.custom_font.pill_size}
                          // custom_font={this.props.design.custom_font}  
                          textMessage={i18n.t("labels.best_seller")} />
                      )}

                      {item.our_favorite && (
                        <ItemLabel
                          page={'item_detail'}
                          design={this.props.design}
                          // backgroundColor={this.props.design.top_buttons_background_color}
                          // textColor={this.props.design.top_buttons_text_color}
                          // pillFontSize={ this.props.design.custom_font.pill_size}
                          // custom_font={this.props.design.custom_font}  
                          textMessage={i18n.t("labels.our_favorite")} />
                      )}

                      {item.must_try && (
                        <ItemLabel
                          page={'item_detail'}
                          design={this.props.design}
                          // backgroundColor={this.props.design.top_buttons_background_color}
                          // textColor={this.props.design.top_buttons_text_color}
                          // pillFontSize={ this.props.design.custom_font.pill_size}
                          // custom_font={this.props.design.custom_font}  
                          textMessage={i18n.t("labels.must_try")} />
                      )}

                      {item.healthy && (
                        <ItemLabel
                          page={'item_detail'}
                          design={this.props.design}
                          // backgroundColor={this.props.design.top_buttons_background_color}
                          // textColor={this.props.design.top_buttons_text_color}
                          // pillFontSize={ this.props.design.custom_font.pill_size}
                          // custom_font={this.props.design.custom_font}  
                          textMessage={i18n.t("labels.healthy")} />
                      )}
                    </View></ScrollView></View>


                <View style={{ flexDirection: "row" }}>
                  {item.price.length === 1 && (
                    <View>
                      <StyledText
                        font_color={this.props.design.item_price_text_color}
                        font_weight={this.props.design.item_price_text_font_weight}
                        font_style={this.props.design.item_price_text_font_style}
                        text_transform={this.props.design.item_page_description_font_transform}
                        font_family={this.props.design.item_price_text_font_family}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        font_size={this.props.design.item_price_text_font_size}

                        style={{
                          marginVertical: 6,
                        }}
                      >
                        {this.props.config.display_currency &&
                          this.props.config.currency}{" "}
                        {this.props.config.display_price &&
                          PriceLoader(item.price[0].price, this.props.config.currency, this.props.config.display_fraction)}{" "}
                        {item.price[0].description[this.props.default_language]
                          ? `(${IntlLabelFn(item.price[0].description, this.props.config.default_language.value, this.props.default_language)})`
                          : ""}
                      </StyledText>
                    </View>
                  )}

                  {item.price.length === 1 && item.price[0].calorie ? (
                    <StyledText
                      font_color={this.props.design.item_price_text_color}
                      font_weight={this.props.design.item_price_text_font_weight}
                      font_style={this.props.design.item_price_text_font_style}
                      text_transform={this.props.design.item_page_description_font_transform}
                      font_family={this.props.design.item_price_text_font_family}
                      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                      font_size={this.props.design.item_price_text_font_size}

                      style={{
                        marginVertical: 6,
                      }}
                    >
                      {" "}
                      - {item.price[0].calorie} Cal
                    </StyledText>
                  ) : null}
                </View>

                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  {item.preparation_time ? (
                    <Icon
                      name={"timer"}
                      size={25}
                      color={this.props.design.item_page_description_color}
                      style={{ margin: 0, padding: 0 }}
                    />
                  ) : null}
                  {item.preparation_time ? (
                    <StyledText
                      font_color={this.props.design.item_page_description_color}
                      font_weight={this.props.design.item_page_description_font_weight}
                      font_style={this.props.design.item_page_description_font_style}
                      text_transform={this.props.design.item_page_description_font_transform}
                      font_family={this.props.design.item_page_description_font_family}
                      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                      font_size={this.props.design.item_page_description_font_size}

                      style={{
                        alignSelf: "center",
                        marginVertical: 5,
                        marginLeft: 5,
                      }}
                    >
                      {item.preparation_time}
                    </StyledText>
                  ) : null}
                </View>
                <View
                  horizontal
                  style={{
                    flexDirection: this.props.isRTL ? "row-reverse" : "row",
                    marginVertical: 7,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {item.warning.map((item, i) => {
                    return (
                      <TouchableOpacity
                        style={{ marginBottom: 10, marginRight: 4, borderRadius: 8 }}
                        onPress={() => this.bounce(i)}
                      >
                        {
                          !this.props.config.show_allergen_label ?
                            <Warning
                              name={item.warning.ingredient}
                              color={this.props.design.item_page_description_color}
                              size={this.props.design.custom_font.pill_size ?? 27}
                              style={{ marginRight: 10, marginBottom: 4 }}
                            />
                            :
                            <View style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingHorizontal: 3,
                              border: `1px solid ${this.props.design.top_buttons_background_color}`,
                              backgroundColor: this.props.design.top_buttons_background_color,
                              flexDirection: 'row',
                              borderRadius: 4
                            }} >
                              <Warning
                                name={item.warning.ingredient}
                                color={this.props.design.top_buttons_text_color}
                                size={this.props.design.custom_font.pill_size ?? 12}
                              // style={{ marginRight: 10, marginBottom: 4 }}
                              />
                              <Text
                                style={{
                                  color: this.props.design.top_buttons_text_color,
                                  fontSize: this.props.design.custom_font.pill_size,
                                  fontWeight: this.props.design.custom_font.pill_is_bold ? "bold" : "normal",
                                  letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                                  marginLeft: 3,
                                  padding: 0,
                                  fontFamily: 'PrimaryFont'
                                }}
                              >{item.warning.description}</Text>

                            </View>

                        }



                      </TouchableOpacity>
                    );
                  })}
                </View>



                <View style={{ alignSelf: "center", marginHorizontal: 8 }}>
                  <StyledText
                    font_color={this.props.design.item_page_description_color}
                    font_weight={this.props.design.item_page_description_font_weight}
                    font_style={this.props.design.item_page_description_font_style}
                    text_transform={this.props.design.item_page_description_font_transform}
                    font_family={this.props.design.item_page_description_font_family}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    font_size={this.props.design.item_page_description_font_size}
                    style={{
                      direction: this.props.isRTL && "rtl",
                      marginRight: this.props.isRTL && 9,
                      textAlign: this.props.isRTL ? "right" : "left",

                    }}
                  >
                    <HTMLView
                      value=
                      {
                        item.item_descriptions ? IntlLabelFn(item.item_descriptions, this.props.config.default_language.value, this.props.default_language) : ""
                      }

                      stylesheet={htmlstyles}
                      onLinkPress={(url) => console.log("clicked link: ", url)}
                    />
                  </StyledText>
                </View>
                {
                  (IntlLabelFn(item.item_descriptions, this.props.config.default_language.value, this.props.default_language).length > 169) ?
                    <View style={{ alignSelf: this.props.isRTL ? "start" : "end", marginHorizontal: 8, marginTop: 3 }}>
                      <TouchableOpacity onPress={() =>
                        this.setState({ isDescriptionCollapsed: !this.state.isDescriptionCollapsed }, () => {
                          this.setState({ itemDescriptionHeight: this.state.isDescriptionCollapsed ? "50px" : "" })
                        }
                        )}>

                        <ShowMoreItem design={this.props.design} text={this.state.isDescriptionCollapsed ? "more >" : "< less"}></ShowMoreItem>
                      </TouchableOpacity>
                    </View> : <View></View>}


                {item.price.length > 1 ? (
                  <View
                    style={{
                      direction: this.props.isRTL && "rtl",
                      width: "98%",
                      marginTop: 20,
                      marginBottom: 10,
                      borderColor: 'lightgrey',
                      color: this.props.design.top_buttons_text_color,
                      borderWidth: .5,
                      borderRadius: 5,
                    }}
                  >

                    <Text
                      style={{
                        fontWeight: 500,
                        //fontSize: this.props.design.custom_font.body_size,           
                        marginTop: 8,
                        marginLeft: 10,
                        color: this.props.design.item_page_description_color,
                        fontFamily: 'SecondaryFont'
                      }}
                    >
                      {i18n.t("labels.choose_from_variant")}
                    </Text>


                    <Text
                      style={{
                        fontWeight: 500,
                        marginTop: 3,
                        marginLeft: 10,
                        color: this.props.design.item_page_description_color,
                        fontSize: this.props.design.custom_font.card_title_size,
                        fontFamily: 'SecondaryFont'
                      }}
                    >

                      {"Select " + "1" + " of " + item.price.length}
                    </Text>

                    <CardBorder />


                    {item.price.map((item, index) => (
                      <TouchableOpacity
                        onPress={() => this.onClickPrice(item, index)}
                      >
                        <>
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignSelf: "center",
                              marginHorizontal: 5,
                              alignItems: "center"
                            }}
                          >


                            <View
                              style={{
                                flexDirection: "row",
                                width: "65%",
                                marginLeft: 5
                              }}
                            >
                              {
                                config.general_qr.qr_id != this.state.uuid ?
                                  this.state.selectedPrice ? (
                                    this.state.selectedPrice.id == item.id ?

                                      <Ant
                                        name={"checkcircle"}
                                        color={
                                          this.props.design.top_buttons_background_color
                                        }
                                        size={18}
                                        style={{ marginTop: 1 }}
                                      />
                                      : (
                                        <View
                                          style={{
                                            borderRadius: 20,
                                            border: `1px solid ${this.props.design.top_buttons_background_color}`,
                                            padding: 1,
                                            width: 20,
                                            height: 20,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            //  backgroundColor: "red"
                                          }}
                                        />
                                      )
                                  ) : (
                                    <View
                                      style={{
                                        borderRadius: 20,
                                        border: `1px solid ${this.props.design.top_buttons_background_color}`,
                                        padding: 1,
                                        width: 20,
                                        height: 20,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        //backgroundColor: "red"
                                      }}
                                    />
                                  )
                                  :
                                  <></>
                              }

                              <StyledText
                                font_color={this.props.design.item_price_text_color}
                                font_weight={this.props.design.item_price_text_font_weight}
                                font_style={this.props.design.item_price_text_font_style}
                                text_transform={this.props.design.item_page_description_font_transform}
                                font_family={this.props.design.item_price_text_font_family}
                                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                font_size={this.props.design.item_price_text_font_size}
                                numberOfLines={1}
                                style={{
                                  marginLeft: 6,
                                }}
                              >
                                {" "}
                                {item.description[this.props.default_language]
                                  ? item.description[
                                    this.props.default_language
                                  ].slice(0, 30)
                                  : item.description[this.props.config.default_language.value]
                                    .slice(0, 30)}
                              </StyledText>
                            </View>


                            <View style={{ alignItems: "end" }}>
                            <StyledText
                                font_color={this.props.design.item_price_text_color}
                                font_weight={this.props.design.item_price_text_font_weight}
                                font_style={this.props.design.item_price_text_font_style}
                                text_transform={this.props.design.item_page_description_font_transform}
                                font_family={this.props.design.item_price_text_font_family}
                                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                font_size={this.props.design.item_price_text_font_size}
                                numberOfLines={1}
                                style={{
                                  marginRight: 10,
                                  marginVertical: 5,
                                }}
                              >
                             

                                {this.props.config.display_currency &&
                                  this.props.config.currency}{" "}

                                {this.props.config.display_price &&
                                  PriceLoader(item.price, this.props.config.currency, this.props.config.display_fraction)}
                              </StyledText>
                              {item.calorie ? <ItemCalorie fontSize={this.props.design.custom_font.card_title_size} color={this.props.design.item_price_text_color} calorie={item.calorie} /> : null}

                            </View>


                          </View>
                        </>
                      </TouchableOpacity>
                    ))}
                  </View>
                ) : null}


                {config.general_qr.qr_id !== this.state.uuid &&
                  item.combo_modifiers &&
                  Array.isArray(item.combo_modifiers) &&
                  item.combo_modifiers.length > 0 && (
                    <View
                      style={{
                        flexDirection: "column",
                        width: '100%',
                        marginTop: 10
                      }}
                    >


                      {item.combo_modifiers.map((parentItem, parentIndex) => (
                        <View
                          style={[style.modifierContainer, {
                            width: "98%",
                            borderColor: "lightgrey",
                            marginTop: 8,
                            borderWidth: .5,
                            borderRadius: 5,
                            backgroundColor: this.props.design.item_page_background_color,
                            direction: this.props.isRTL && "rtl",
                          }]}
                        >
                          <View
                            disabled
                            block
                            style={[style.modifierItem, {
                              backgroundColor: this.props.popup_button_color,
                            }]}
                          >

                            <View>
                              <ModifierText design={this.props.design} direction={"start"} >
                                {JSON.parse(parentItem.names)[
                                  this.props.default_language
                                ]
                                  ? JSON.parse(parentItem.names)
                                  [this.props.default_language].toUpperCase()
                                    .slice(0, 38)
                                  : JSON.parse(parentItem.names)
                                  [this.props.config.default_language.value].toUpperCase()
                                    .slice(0, 38)}
                              </ModifierText>

                              {parentItem.required ? (<Text
                                style={{
                                  fontWeight: 500,
                                  marginLeft: 10,
                                  color: this.props.design.item_page_description_color,
                                  fontSize: this.props.design.custom_font.card_title_size,
                                  fontFamily: 'SecondaryFont'
                                }}
                              >
                                {"Select " + parentItem.min + " of " + parentItem.modifier_modifier_group.length}
                              </Text>) : null}
                            </View>

                            {parentItem.required ? (
                              <ModifierRequired
                                design={this.props.design}
                                required_indicator={parentItem.required_indicator}
                                top_buttons_background_color={this.props.design.top_buttons_background_color}
                                item_page_background_color={this.props.design.item_page_background_color}
                                body_size={this.props.design.custom_font.body_size}
                                min={parentItem.min}
                                custom_font={this.props.design.custom_font}
                              />
                            ) : null}
                          </View>


                          <CardBorder />


                          {/* <View style={{ height: 12 }} /> */}
                          {parentItem.modifier_modifier_group.slice(0, parentItem.isExpanded ? parentItem.modifier_modifier_group.length : this.state.initLoadCount).map(
                            (childItem, childIndex) => (
                              // if(parentItem.max === ){}

                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: 6,
                                  marginRight: 5
                                }}
                              >

                                <ModifierCounterContainer
                                  design={this.props.design}
                                  qr_id={config.general_qr.qr_id}
                                  uuid={this.state.uuid}
                                  item_price_text_color={this.props.design.item_price_text_color}
                                  description={
                                    childItem.item_names ? <IntlLabel data={childItem.item_names} /> : ""
                                  }
                                >
                                  {this.modifiersCount(parentIndex,
                                    childIndex,
                                    childItem.price, parentItem, item, "combo_modifiers")}
                                </ModifierCounterContainer>

                                <ModifierPrice design={this.props.design}>
                                  {childItem.price > 0 ? "+" : ""}{" "}
                                  {this.props.config.display_currency &&
                                    this.props.config.currency}{" "}
                                  {this.props.config.display_price &&
                                    PriceLoader(childItem.price, this.props.config.currency, this.props.config.display_fraction)}
                                </ModifierPrice>

                              </View>

                            )
                          )}


                          {parentItem.modifier_modifier_group.length > 5 ?


                            <TouchableWithoutFeedback
                              onPress={(e) => {
                                this.showMoreExtras(parentIndex, "combo_modifiers");
                              }}
                            >
                              <View style={{ marginLeft: 10, marginTop: 10, marginBottom: 15 }}>
                                <ShowMoreItem design={this.props.design} text={
                                  !parentItem.isExpanded ? ("View More (+" + (parentItem.modifier_modifier_group.length - this.state.initLoadCount) + ")") : "View Less"
                                }></ShowMoreItem>

                              </View>
                            </TouchableWithoutFeedback> : <></>

                          }
                        </View>



                      ))}


                    </View>
                  )}




                {item.modifiers.length > 0 || this.state.item_modifiers.length > 0 ? (
                  <View
                    style={{
                      marginTop: 12,
                      width: "100%",
                      backgroundColor: this.props.design.item_page_background_color
                    }}
                  >



                    {item.modifiers.map((parentItem, parentIndex) => (

                      <View
                        style={[style.modifierContainer, {
                          width: "98%",
                          borderColor: "lightgrey",
                          color: this.props.design.top_buttons_text_color,
                          marginTop: 12,
                          borderWidth: 1,
                          borderRadius: 5,
                          backgroundColor: this.props.design.item_page_background_color,
                          direction: this.props.isRTL && "rtl"
                        }]}
                      >

                        <View
                          disabled
                          block
                          style={[style.modifierItem, {
                            backgroundColor: this.props.popup_button_color,
                          }]}
                        >
                          <View>
                            <ModifierText
                              design={this.props.design}
                              direction={"start"}
                              item_page_description_color={this.props.design.item_page_description_color}>
                              {JSON.parse(parentItem.names)[
                                this.props.default_language
                              ]
                                ? JSON.parse(parentItem.names)
                                [this.props.default_language].toUpperCase()
                                  .slice(0, 38)
                                : JSON.parse(parentItem.names)
                                [this.props.config.default_language.value].toUpperCase()
                                  .slice(0, 38)}
                            </ModifierText>

                            <Text
                              style={{
                                fontWeight: 500,
                                marginLeft: 10,
                                color: this.props.design.item_page_description_color,
                                fontSize: this.props.design.custom_font.card_title_size,
                                fontFamily: 'SecondaryFont'
                              }}
                            >
                              {"Select " + "1" + " of " + parentItem.modifier_modifier_group.length}
                            </Text>

                          </View>
                          {parentItem.required ? (
                            <ModifierRequired
                              design={this.props.design}
                              required_indicator={parentItem.required_indicator}
                              top_buttons_background_color={this.props.design.top_buttons_background_color}
                              item_page_background_color={this.props.design.item_page_background_color}
                              body_size={this.props.design.custom_font.body_size}
                              min={parentItem.min}
                              custom_font={this.props.design.custom_font}
                            />
                          ) : null}
                        </View>



                        <CardBorder />


                        {/* <View style={{ height: 12 }} /> */}
                        {parentItem.modifier_modifier_group.slice(0, parentItem.isExpanded ? parentItem.modifier_modifier_group.length : this.state.initLoadCount).map(
                          (childItem, childIndex) =>

                            <>

                              {
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginBottom: 6,
                                    marginRight: 5
                                  }}
                                >

                                  <ModifierCounterContainer
                                    design={this.props.design}
                                    qr_id={config.general_qr.qr_id}
                                    uuid={this.state.uuid}
                                    item_price_text_color={this.props.design.item_price_text_color}
                                    description={
                                      childItem.is_item_modifier && childItem.item_names ?
                                        <IntlLabel data={childItem.item_names} /> :
                                        <IntlLabel data={JSON.parse(childItem.names)} />

                                    }
                                  >

                                    {this.modifiersCount(parentIndex,
                                      childIndex,
                                      childItem.price, parentItem, item, "modifiers")}
                                  </ModifierCounterContainer>

                                  <View style={{ justifyContent: "center" }} >
                                    <ModifierPrice design={this.props.design} item_price_text_color={this.props.design.item_price_text_color}>
                                      {childItem.price > 0 ? "+" : ""}{" "}
                                      {this.props.config.display_currency &&
                                        this.props.config.currency}{" "}
                                      {this.props.config.display_price &&
                                        PriceLoader(childItem.price, this.props.config.currency, this.props.config.display_fraction)}
                                    </ModifierPrice>

                                    {childItem.cal ? <ItemCalorie fontSize={this.props.design.custom_font.card_title_size} color={this.props.design.item_price_text_color} calorie={childItem.cal} /> : null}
                                  </View>
                                </View>

                              }



                              {
                                childItem.is_item_modifier && (

                                  <View style={{ marginLeft: 10 }} >

                                    {childItem.modifiers && childItem.modifiers.map((parentPItem, parentPIndex) => (
                                      <View
                                        style={[style.modifierContainer, {
                                          backgroundColor: this.props.design.item_page_background_color,
                                          direction: this.props.isRTL && "rtl",
                                          width: "100%",

                                        }]}
                                      >


                                        <View
                                          disabled
                                          block
                                          style={[style.modifierItem, {
                                            backgroundColor: this.props.popup_button_color,
                                          }]}
                                        >
                                          <ModifierText design={this.props.design} direction={"start"} item_page_description_color={this.props.design.item_page_description_color}>
                                            {JSON.parse(parentPItem.names)[
                                              this.props.default_language
                                            ]
                                              ? JSON.parse(parentPItem.names)
                                              [this.props.default_language].toUpperCase()
                                                .slice(0, 38)
                                              : JSON.parse(parentPItem.names)
                                              [this.props.config.default_language.value].toUpperCase()
                                                .slice(0, 38)}
                                          </ModifierText>

                                          {parentPItem.required ? (
                                            <ModifierRequired
                                              design={this.props.design}
                                              required_indicator={parentPItem.required_indicator}
                                              top_buttons_background_color={this.props.design.top_buttons_background_color}
                                              item_page_background_color={this.props.design.item_page_background_color}
                                              body_size={this.props.design.custom_font.body_size}
                                              min={parentPItem.min}
                                              custom_font={this.props.design.custom_font}
                                            />
                                          ) : null}
                                        </View>

                                        <View style={{ height: 12 }} />
                                        {/* {parentPItem.modifier_modifier_group.slice(0, parentPItem.isExpanded ? parentPItem.modifier_modifier_group.length : this.state.initLoadCount ).map( */}
                                        {parentPItem.modifier_modifier_group.map(
                                          (childPItem, childPIndex) => {
                                            if (!childPItem.is_item_modifier) {
                                              return (
                                                // if(parentItem.max === ){}
                                                <View
                                                  style={{
                                                    flexDirection: "row",
                                                    marginBottom: 6,
                                                  }}
                                                >

                                                  <ModifierCounterContainer
                                                    qr_id={config.general_qr.qr_id}
                                                    uuid={this.state.uuid}
                                                    item_price_text_color={this.props.design.item_price_text_color}
                                                    description={
                                                      <IntlLabel data={JSON.parse(childPItem.names)} />

                                                    }
                                                  >

                                                    {this.modifierItemCount(parentIndex, parentItem, parentPIndex, parentPItem, childPIndex, childPItem, childItem, childIndex)}
                                                  </ModifierCounterContainer>
                                                  <View>
                                                    <ModifierPrice
                                                      design={this.props.design}
                                                      item_price_text_color={this.props.design.item_price_text_color}>
                                                      {childPItem.price > 0 ? "+" : ""}{" "}
                                                      {this.props.config.display_currency &&
                                                        this.props.config.currency}{" "}
                                                      {this.props.config.display_price &&
                                                        PriceLoader(childPItem.price, this.props.config.currency, this.props.config.display_fraction)}
                                                    </ModifierPrice>

                                                    {childPItem.cal ? <ItemCalorie fontSize={this.props.design.custom_font.card_title_size} color={this.props.design.item_price_text_color} calorie={childPItem.cal} /> : null}
                                                  </View>
                                                </View>
                                              )
                                            }
                                          })}

                                        {/* {parentPItem.modifier_modifier_group.length > 5 ?


                                                            <TouchableWithoutFeedback
                                                              onPress={(e) => {
                                                              // this.showMoreExtras(parentPIndex, "modifier_modifier_group");
                                                              console.log(parentIndex, parentItem, parentPIndex, parentPItem, "-----")
                                                              }}
                                                            >
                                                              <View style={{ marginLeft: 12, marginBottom: 15 }}>
                                                                {!parentItem.isExpanded ?
                                                                  <Text style={{
                                                                    fontWeight: 500,
                                                                    color: this.props.design.item_page_description_color,
                                                                    fontSize: this.props.design.custom_font.body_size,
                                                                    fontFamily: 'SecondaryFont'
                                                                  }}>+{(parentPItem.modifier_modifier_group.length) - this.state.initLoadCount} {"more >"}</Text>
                                                                  : <Text style={{
                                                                    fontWeight: 500,
                                                                    color: this.props.design.item_page_description_color,
                                                                    fontSize: this.props.design.custom_font.body_size,
                                                                    fontFamily: 'SecondaryFont'
                                                                  }}>{"Show Less"}</Text>
                                                                }

                                                              
                                                              </View>
                                                            </TouchableWithoutFeedback> : <></>

                                                          } */}
                                      </View>
                                    ))}


                                  </View>

                                )
                              }
                            </>

                        )}

                        {parentItem.modifier_modifier_group.length > 5 ?

                          <TouchableWithoutFeedback
                            onPress={(e) => {
                              this.showMoreExtras(parentIndex, "modifiers");
                            }}
                          >
                            <View style={{ marginLeft: 10, marginTop: 10, marginBottom: 15 }}>
                              <ShowMoreItem design={this.props.design} text={
                                !parentItem.isExpanded ? ("View More (+" + (parentItem.modifier_modifier_group.length - this.state.initLoadCount) + ")") : "View Less"
                              }></ShowMoreItem>
                            </View>

                          </TouchableWithoutFeedback> : <></>

                        }

                      </View>

                    ))}


                  </View>
                ) : null}





                {config.general_qr.qr_id != this.state.uuid &&
                  this.props.platform !== "enterprise" && config.comment_enabled && (
                    <View
                      style={{
                        marginVertical: 20,
                        marginHorizontal: 5,
                        width: "90%",
                      }}
                    >
                      <StyledText
                        font_color={this.props.design.item_page_description_color}
                        font_weight={this.props.design.item_page_description_font_weight}
                        font_style={this.props.design.item_page_description_font_style}
                        text_transform={this.props.design.item_page_description_font_transform}
                        font_family={this.props.design.item_page_description_font_family}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        font_size={this.props.design.item_page_description_font_size}
                        style={{
                          fontSize: this.props.design.custom_font.heading_size,
                          fontWeight: this.props.design.custom_font.heading_is_bold ? "bold" : "normal",
                          letterSpacing: FontLetterSpacing(this.props.design.custom_font.secondary_letter_space),
                          marginTop: 10,
                        }}
                      >
                        {i18n.t("labels.write_instructions")}
                      </StyledText>
                      <View style={{ marginTop: 5 }}>
                        <Textarea
                          rowSpan={2}
                          bordered
                          maxLength={250}
                          value={this.state.instructions}
                          onChangeText={(text) => {
                            let item = this.state.item;
                            item["comment"] = text;
                            this.setState({ item, instructions: text });
                          }}
                          placeholderTextColor={
                            "lightgrey"
                          }
                          // placeholder={i18n.t(
                          //   "checkout.item_comment_placeholder"
                          // )}
                          style={{
                            color: this.props.design.item_page_description_color,
                            border: "1px solid grey",
                            borderRadius: 5,
                            height: 90,
                            fontFamily: 'SecondaryFont'
                          }}
                        />
                      </View>
                    </View>
                  )}









                {config.general_qr.qr_id == this.state.uuid &&
                  item.recommended_items &&
                  Array.isArray(item.recommended_items) &&
                  item.recommended_items.length > 0 && (
                    <View
                      style={{
                        // flex: 2,
                        flexDirection: "column",
                      }}
                    >
                      <StyledText
                        font_color={this.props.design.item_page_description_color}
                        font_weight={this.props.design.item_page_description_font_weight}
                        font_style={this.props.design.item_page_description_font_style}
                        text_transform={this.props.design.item_page_description_font_transform}
                        font_family={this.props.design.item_page_description_font_family}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        font_size={this.props.design.item_page_description_font_size}

                        style={{
                          marginHorizontal: 8,
                          //fontWeight: 400,
                          marginVertical: 7,
                          marginTop: 8,
                          marginLeft: 24,
                          alignSelf: "center",
                        }}
                      >
                        {i18n.t("labels.goes_well_with")}
                      </StyledText>

                      <ScrollView horizontal pagingEnabled>
                        <View>

                          {
                            item.recommended_items.map((item, index) => (
                              <TouchableWithoutFeedback
                                onPress={() => {
                                  clearTimeout(this.camp_timeout);
                                  let section = this.props.allChildren.find((Sitem) => {
                                    return item.section == Sitem.id && Sitem.type == "section";
                                  });
                                  this.props.navigation.push("RecipeDetail", {
                                    item: item,
                                    index: index,
                                    section
                                  });
                                }}
                              >
                                <View
                                  style={{
                                    borderBottomLeftRadius: 6,
                                    borderTopLeftRadius: 6,
                                    marginHorizontal: 8,
                                    marginBottom: 8,
                                    flexDirection: "row",
                                    alignSelf: "center",
                                    borderRadius: 6,
                                    elevation: 5,
                                    shadowColor: "black",
                                    shadowOffset: { width: 0, height: 0.55 * 5 },
                                    shadowOpacity: 0.3,
                                    shadowRadius: 0.8 * 5,
                                    width: width * 0.80,
                                    //   this.state.item.recommended_items.length < 2
                                    //     ? width * 0.9
                                    //     : width * 0.65,
                                  }}
                                >
                                  <View
                                    style={{
                                      borderBottomLeftRadius: 6,
                                      borderTopLeftRadius: 6,
                                    }}
                                  >
                                    <Image
                                      style={{
                                        height: width * 0.25,
                                        width: width * 0.251,
                                        borderBottomLeftRadius: 6,
                                        borderTopLeftRadius: 6,
                                      }}
                                      source={{
                                        uri:
                                          image_api +
                                          item.image
                                            .split("/")
                                            .splice(3)
                                            .join("/") +
                                          "?w=200&h=200",
                                      }}
                                    />
                                  </View>
                                  <View
                                    style={{ flexDirection: "column", flex: 1 }}
                                  >
                                    <View>
                                      <StyledText
                                        font_color={this.props.design.item_page_description_color}
                                        font_weight={this.props.design.item_page_description_font_weight}
                                        font_style={this.props.design.item_page_description_font_style}
                                        text_transform={this.props.design.item_page_description_font_transform}
                                        font_family={this.props.design.item_page_description_font_family}
                                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                        font_size={this.props.design.item_page_description_font_size}

                                        numberOfLines={1}
                                        style={{
                                          marginLeft: 9,
                                          marginTop: 9,
                                          fontWeight: 460,
                                          marginRight: this.props.isRTL && 9,
                                        }}
                                      >
                                        {item.mark_item_as_new && (
                                          <Image
                                            source={require("../../assets/new.png")}
                                            style={style.iconStyle}
                                          />
                                        )}
                                        {item.signature && (
                                          <Image
                                            source={require("../../assets/signature.png")}
                                            style={style.iconStyle}
                                          />
                                        )}
                                        <IntlLabel data={item.item_names} />
                                      </StyledText>
                                    </View>
                                    <StyledText
                                      numberOfLines={3}
                                      font_color={this.props.design.grid_view_text_color}
                                      font_weight={this.props.design.grid_view_text_font_weight}
                                      font_style={this.props.design.grid_view_text_font_style}
                                      text_transform={this.props.design.grid_view_text_font_transform}
                                      font_family={this.props.design.grid_view_text_font_family}
                                      letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                      font_size={this.props.design.grid_view_text_font_size ?? 12}

                                      style={{
                                        marginHorizontal: 10,
                                        marginTop: 2,
                                        direction: this.props.isRTL && "rtl",
                                        height: 30,

                                        textAlign: this.props.isRTL ? "right" : "left"
                                      }}
                                    >
                                      {this.webview(item, htmlstylesR)}
                                    </StyledText>

                                    {item.price.length > 0 &&
                                      this.props.config.display_price ? (
                                      <StyledText
                                        font_color={this.props.design.item_price_text_color}
                                        font_weight={this.props.design.item_price_text_font_weight}
                                        font_style={this.props.design.item_price_text_font_style}
                                        text_transform={this.props.design.item_page_description_font_transform}
                                        font_family={this.props.design.item_price_text_font_family}
                                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                        font_size={this.props.design.item_price_text_font_size}
                                        style={{
                                          fontWeight: 460,
                                          marginLeft: !this.props.isRTL && 9,
                                          right: this.props.isRTL && 9,
                                        }}
                                      >
                                        {this.props.config.display_currency &&
                                          this.props.config.currency}{" "}
                                        {PriceLoader(item.price[0].price, this.props.config.currency, this.props.config.display_fraction)}
                                      </StyledText>
                                    ) : null}
                                  </View>
                                </View>
                              </TouchableWithoutFeedback>
                            ))
                          }
                        </View>
                      </ScrollView>
                    </View>
                  )}
              </View>
            </View>

            {this.state.message_display != "none" && (
              <Toast
                error_message={this.state.error_message}
                message_status={this.state.message_status}
                message_display={this.state.message_display}
                custom_font={this.props.design.custom_font}
              />
            )}

            <Modal
              transparent
              // animationType="slide"
              visible={this.state.languageModal}
              onRequestClose={() => {
                Alert.alert("Modal has been closed.");
              }}
            >
              {this.defaultLangView()}
            </Modal>




            {/* <Modal
              transparent
              style={{ backgroundColor: "transparent" }}
              animationType="slide"
              visible={this.state.removeItemModal}
              onRequestClose={() => {
                Alert.alert("Modal has been closed.");
              }}
            >
              {this._renderRemoveSelectedItems()}
            </Modal> */}


          </ScrollView>
        </View>

        <View style={{ height: 80 }} />


        {
          config.general_qr.qr_id != this.state.uuid &&
          this.props.platform !== "enterprise" && (
            <View style={[style.checkoutButtonContainer, { backgroundColor: this.props.design.item_page_background_color }]} >
              {
                !this.props.config.accepting_orders ?
                  <View style={{ width: "100%", height: 30, backgroundColor: "grey", justifyContent: "center", alignItems: "center" }} >
                    <Text
                      style={{ color: "rgb(56 56 56)", fontFamily: 'SecondaryFont' }} >
                      {i18n.t("labels.not_accepting_order")}
                    </Text>
                  </View>
                  :
                  <>


                    <View style={{ flex: 1 }} >
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <TouchableOpacity
                          // style={{ marginBottom: 50 }}
                          onPress={() => {
                            if (this.state.item_count > 1) {
                              this.setState({ item_count: this.state.item_count - 1 })
                            }
                          }
                          }
                        >
                          <View
                            style={{
                              width: 40,
                              height: 40,
                              // backgroundColor:
                              //   this.props.design.home_button_background_color,
                              borderRadius: 8,
                              margin: 15,
                              marginLeft: 20,
                              justifyContent: "center",
                              alignItems: "center",
                              // borderColor: this.props.design.home_button_background_color,
                              border: "1px solid " + this.props.design.top_buttons_background_color
                            }}
                          >
                            <Icon
                              color={this.props.design.top_buttons_background_color}
                              size={15}
                              name={"minus"}
                            />
                          </View>
                        </TouchableOpacity>

                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            // marginBottom: 50,
                          }}
                        >
                          <StyledText
                            font_color={this.props.design.item_page_description_color}
                            font_weight={this.props.design.item_page_description_font_weight}
                            font_style={this.props.design.item_page_description_font_style}
                            text_transform={this.props.design.item_page_description_font_transform}
                            font_family={this.props.design.item_page_description_font_family}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                            font_size={this.props.design.item_page_description_font_size}

                            style={{

                            }}
                          >
                            {this.state.item_count}
                          </StyledText>
                        </View>

                        <TouchableOpacity
                          onPress={() => {

                            this.setState({ item_count: this.state.item_count + 1 })


                          }}
                          disabled={config.general_qr.qr_id == this.state.uuid}
                        >
                          <View
                            style={{
                              width: 40,
                              height: 40,
                              // backgroundColor:
                              //   this.props.design.home_button_background_color,
                              borderRadius: 8,
                              margin: 15,
                              marginLeft: 20,
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid " + this.props.design.top_buttons_background_color
                            }}
                          >
                            <Icon
                              color={this.props.design.top_buttons_background_color}
                              size={15}
                              name={"plus"}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={{ flex: 1 }} >
                      <Button
                        disabled={this.checkDisabled() || this.state.cartLoading}
                        onPress={() => {
                          if (this.state.item_count > 0) {
                            if (item.price.length > 1 && !this.state.selectedPrice) {
                              this.throwMessageFn(
                                i18n.t("labels.please_select_at_least_one_option"),
                                "red"
                              );
                              return
                            }
                            this._onOptionSelect()
                          }
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          // marginTop: 20,
                          // marginBottom: 10,
                          backgroundColor: this.props.design.top_buttons_background_color,
                          // height: 50,
                          width: '98%',
                          borderRadius: 8,
                          // marginBottom: 10
                        }}
                      >
                        <StyledText
                          font_color={this.props.design.top_buttons_text_color}
                          font_weight={this.props.design.top_button_text_font_weight}
                          font_style={this.props.design.top_button_text_font_style}
                          text_transform={this.props.design.top_button_text_font_transform}
                          font_family={this.props.design.top_button_text_font_family}
                          font_size={this.props.design.top_button_text_font_size}
                          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                          style={{
                          }}
                        >
                          {
                            this.state.cartLoading ?
                              <i style={{ color: this.props.design.grid_view_button_text_color }} class="fas fa-spinner fa-spin"></i>
                              :
                              i18n.t("labels.add_to_card")
                          }
                        </StyledText>
                      </Button>
                    </View>
                  </>
              }
            </View>
          )
        }
        <CampaignModal
          campaign={this.state.campaignSelected}
          campaignModal={this.state.campaignModal}
          closeCampaignModal={this.closeCampaignModal}
          popup_text_color={this.props.design.popup_text_color}
          popup_button_color={this.props.design.popup_button_color}
          popup_button_text_color={this.props.design.popup_text_color}
          popup_button_border_color={this.props.design.popup_button_color}
          popup_background_color={this.props.design.popup_background_color}
          subheading_font_size={this.props.design.custom_font.sub_heading_size}
          body_font_size={this.props.design.custom_font.body_size}
          custom_font={this.props.design.custom_font}
        />


        <TouchableOpacity
          style={{ position: "absolute", top: 0 }}
          onPress={() => {
            const { params } = this.props.navigation.state;
            this.setState({ isDetailScreenActive: false }, () => {
              clearTimeout(this.camp_timeout);
              this.props.navigation.goBack();
            });
          }}
        >
          <BackButton
            border_color={`1px solid ${this.props.design.top_buttons_background_color}`}
            // goBack={() => this.props.navigation.goBack()}
            backgroundColor={this.props.design.top_buttons_background_color}
            borderColor={"black"}
            color={this.props.design.top_buttons_text_color}
            default_language={this.props.default_language}
            detail_page={true}
            font_size={this.props.design.custom_font.heading_size}
          />
        </TouchableOpacity>


      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    allChildren: state.menus.allChildren,
    immediateChildren: state.menus._immediateChildren,
    cart: state.cart.cart,
    cart_count: state.cart.cart_count,
    total_price: state.cart.total_price,
    config: state.config.config,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    isRTL: state.languages.isRTL,
    campaigns: state.menus.campaigns,
    design: state.config.design,
    platform: state.config.platform,
    menu_type: state.config.menu_type,

  };
};

export default connect(mapStateToProps, {
  updateChildren,
  addToCart,
  removeItemFromCart,
  addCount,
  subtractCount,
  totalPrice,
  addDefaultLanguage,
  allCart,
  updateCartCount
})(RecipeDetail);




{/* <ReactPlayer 
onEnded={()=> this.setState({ itemVideoPlay: false })}
ref={(r) => (this._handleVideoRef = r)}
playsinline
muted
playing={itemVideoPlay}
// loop
controls={false}
width={width}
height={'100%'}
url={item.video}
// resizeMode="contain"
/>
<Video
ref={(r) => (this._handleVideoRef = r)}
onPlaybackStatusUpdate={(playbackStatus) =>
  this._onPlaybackStatusUpdate(playbackStatus)
}
source={{ uri: item.video }}
rate={1.0}
volume={1.0}
isMuted={true}
resizeMode="cover"
shouldPlay={itemVideoPlay}
// useNativeControls
style={{ width: "100%", height: "100%" }}
/> */}