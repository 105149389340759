import React from "react"
import {Text} from "react-native";



const StyledText = (props) =>{
    // console.log(props.style, "styleeeeeeeeeee")
    let _rest_styles = {...props.style}
    return(
        <Text
        adjustsFontSizeToFit={props.adjustsFontSizeToFit}
        numberOfLines={props.numberOfLines}
        style={{
            color:props.font_color,
            fontSize:props.font_size,
            fontWeight:props.font_weight,
            fontStyle: props.font_style,
            textTransform:props.text_transform,
            fontFamily: props.font_family,
            letterSpacing:props.letter_spacing,
            ..._rest_styles
        }}
        >{props.children}
        </Text>
    )
}

export default StyledText