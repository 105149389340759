'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var FieldValueList = require('./fieldType/fieldValue').FieldValueList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var FieldTypeList;
var FieldTypePage;
var FieldTypeInstance;
var FieldTypeContext;

/* jshint ignore:start */
/**
 * Initialize the FieldTypeList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.FieldTypeList
 *
 * @param {Twilio.Preview.Understand} version - Version of the resource
 * @param {string} assistantSid - The unique ID of the Assistant.
 */
/* jshint ignore:end */
FieldTypeList = function FieldTypeList(version, assistantSid) {
  /* jshint ignore:start */
  /**
   * @function fieldTypes
   * @memberof Twilio.Preview.Understand.AssistantContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.FieldTypeContext}
   */
  /* jshint ignore:end */
  function FieldTypeListInstance(sid) {
    return FieldTypeListInstance.get(sid);
  }

  FieldTypeListInstance._version = version;
  // Path Solution
  FieldTypeListInstance._solution = {assistantSid: assistantSid};
  FieldTypeListInstance._uri = `/Assistants/${assistantSid}/FieldTypes`;
  /* jshint ignore:start */
  /**
   * Streams FieldTypeInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  FieldTypeListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists FieldTypeInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  FieldTypeListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of FieldTypeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  FieldTypeListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new FieldTypePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of FieldTypeInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  FieldTypeListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new FieldTypePage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a FieldTypeInstance
   *
   * @function create
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.uniqueName -
   *          A user-provided string that uniquely identifies this resource as an alternative to the sid. Unique up to 64 characters long.
   * @param {string} [opts.friendlyName] -
   *          A user-provided string that identifies this resource. It is non-unique and can up to 255 characters long.
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed FieldTypeInstance
   */
  /* jshint ignore:end */
  FieldTypeListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['uniqueName'])) {
      throw new Error('Required parameter "opts[\'uniqueName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'UniqueName': _.get(opts, 'uniqueName'),
      'FriendlyName': _.get(opts, 'friendlyName')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new FieldTypeInstance(
        this._version,
        payload,
        this._solution.assistantSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a field_type
   *
   * @function get
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @param {string} sid - The sid
   *
   * @returns {Twilio.Preview.Understand.AssistantContext.FieldTypeContext}
   */
  /* jshint ignore:end */
  FieldTypeListInstance.get = function get(sid) {
    return new FieldTypeContext(this._version, this._solution.assistantSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  FieldTypeListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  FieldTypeListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return FieldTypeListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the FieldTypePage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.FieldTypePage
 *
 * @param {Understand} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {FieldTypeSolution} solution - Path solution
 *
 * @returns FieldTypePage
 */
/* jshint ignore:end */
FieldTypePage = function FieldTypePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(FieldTypePage.prototype, Page.prototype);
FieldTypePage.prototype.constructor = FieldTypePage;

/* jshint ignore:start */
/**
 * Build an instance of FieldTypeInstance
 *
 * @function getInstance
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypePage#
 *
 * @param {FieldTypePayload} payload - Payload response from the API
 *
 * @returns FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypePage.prototype.getInstance = function getInstance(payload) {
  return new FieldTypeInstance(this._version, payload, this._solution.assistantSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
FieldTypePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FieldTypePage.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FieldTypeContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.FieldTypeInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account that created this Field Type.
 * @property {Date} dateCreated - The date that this resource was created
 * @property {Date} dateUpdated - The date that this resource was last updated
 * @property {string} friendlyName -
 *          A user-provided string that identifies this resource. It is non-unique and can up to 255 characters long.
 * @property {string} links - The links
 * @property {string} assistantSid - The unique ID of the Assistant.
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {string} uniqueName -
 *          A user-provided string that uniquely identifies this resource as an alternative to the sid. Unique up to 64 characters long.
 * @property {string} url - The url
 *
 * @param {Understand} version - Version of the resource
 * @param {FieldTypePayload} payload - The instance payload
 * @param {sid} assistantSid - The unique ID of the Assistant.
 * @param {sid_like} sid - The sid
 */
/* jshint ignore:end */
FieldTypeInstance = function FieldTypeInstance(version, payload, assistantSid,
                                                sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line
  this.assistantSid = payload.assistant_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {assistantSid: assistantSid, sid: sid || this.sid, };
};

Object.defineProperty(FieldTypeInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new FieldTypeContext(
          this._version,
          this._solution.assistantSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a FieldTypeInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a FieldTypeInstance
 *
 * @function update
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          A user-provided string that identifies this resource. It is non-unique and can up to 255 characters long.
 * @param {string} [opts.uniqueName] -
 *          A user-provided string that uniquely identifies this resource as an alternative to the sid. Unique up to 64 characters long.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a FieldTypeInstance
 *
 * @function remove
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Access the fieldValues
 *
 * @function fieldValues
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeInstance#
 *
 * @returns {Twilio.Preview.Understand.AssistantContext.FieldTypeContext.FieldValueList}
 */
/* jshint ignore:end */
FieldTypeInstance.prototype.fieldValues = function fieldValues() {
  return this._proxy.fieldValues;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
FieldTypeInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FieldTypeInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FieldTypeContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Preview.Understand.AssistantContext.FieldTypeContext
 *
 * @property {Twilio.Preview.Understand.AssistantContext.FieldTypeContext.FieldValueList} fieldValues -
 *          fieldValues resource
 *
 * @param {Understand} version - Version of the resource
 * @param {sid_like} assistantSid - The assistant_sid
 * @param {sid_like} sid - The sid
 */
/* jshint ignore:end */
FieldTypeContext = function FieldTypeContext(version, assistantSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {assistantSid: assistantSid, sid: sid, };
  this._uri = `/Assistants/${assistantSid}/FieldTypes/${sid}`;

  // Dependents
  this._fieldValues = undefined;
};

/* jshint ignore:start */
/**
 * fetch a FieldTypeInstance
 *
 * @function fetch
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new FieldTypeInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a FieldTypeInstance
 *
 * @function update
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.friendlyName] -
 *          A user-provided string that identifies this resource. It is non-unique and can up to 255 characters long.
 * @param {string} [opts.uniqueName] -
 *          A user-provided string that uniquely identifies this resource as an alternative to the sid. Unique up to 64 characters long.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'FriendlyName': _.get(opts, 'friendlyName'),
    'UniqueName': _.get(opts, 'uniqueName')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new FieldTypeInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a FieldTypeInstance
 *
 * @function remove
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FieldTypeInstance
 */
/* jshint ignore:end */
FieldTypeContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(FieldTypeContext.prototype,
  'fieldValues', {
    get: function() {
      if (!this._fieldValues) {
        this._fieldValues = new FieldValueList(
          this._version,
          this._solution.assistantSid,
          this._solution.sid
        );
      }
      return this._fieldValues;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Preview.Understand.AssistantContext.FieldTypeContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
FieldTypeContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

FieldTypeContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  FieldTypeList: FieldTypeList,
  FieldTypePage: FieldTypePage,
  FieldTypeInstance: FieldTypeInstance,
  FieldTypeContext: FieldTypeContext
};
