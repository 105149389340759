import React, {Component} from 'react';
import {View, TouchableOpacity, ScrollView, Dimensions, Text} from 'react-native';

import {Icon} from  'native-base'
import {style} from "../../assets/style";
import Material from '@expo/vector-icons/MaterialCommunityIcons'
import { FontLetterSpacing } from './../handlers/utils';



class PaymentButton extends Component {
    render(){
        return(
            <TouchableOpacity onPress={this.props.onSelectingPaymentMethod}>
                <View style={{flexDirection:'row',width:'100%',height:'100%'}}>
                    <View style={{flex:3,justifyContent:'center',alignItems:'flex-start',marginLeft:8}}>
                    <View style={style.closeButton}>
                        <i style={{color: "#5d6670", fontSize: 13}} class={this.props.icon}></i>
                        {/* <Material size={13} name={this.props.icon} color={'#5d6670'}/>   */}
                    </View>
                    </View>
                    <View style={{justifyContent:'center',alignItems:'flex-start'}}>
                        <Text style={{fontWeight:'500', letterSpacing :FontLetterSpacing(this.props.design.custom_font.secondary_letter_space), fontSize:this.props.design.popup_button_font_weight ?? 13, fontFamily:this.props.design.popup_button_font_family}}>{this.props.title}</Text>
                    </View>
        
                </View>
            </TouchableOpacity>
        )
    }
}

export default PaymentButton