'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var NotificationList = require('./configuration/notification').NotificationList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var WebhookList = require('./configuration/webhook').WebhookList;
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var ConfigurationList;
var ConfigurationPage;
var ConfigurationInstance;
var ConfigurationContext;

/* jshint ignore:start */
/**
 * Initialize the ConfigurationList
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConfigurationList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 * @param {string} chatServiceSid - The unique string that identifies the resource
 */
/* jshint ignore:end */
ConfigurationList = function ConfigurationList(version, chatServiceSid) {
  /* jshint ignore:start */
  /**
   * @function configuration
   * @memberof Twilio.Conversations.V1.ServiceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.ConfigurationContext}
   */
  /* jshint ignore:end */
  function ConfigurationListInstance(sid) {
    return ConfigurationListInstance.get(sid);
  }

  ConfigurationListInstance._version = version;
  // Path Solution
  ConfigurationListInstance._solution = {chatServiceSid: chatServiceSid};

  // Components
  ConfigurationListInstance._notifications = undefined;
  ConfigurationListInstance._webhooks = undefined;

  /* jshint ignore:start */
  /**
   * Constructs a configuration
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationList#
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.ConfigurationContext}
   */
  /* jshint ignore:end */
  ConfigurationListInstance.get = function get() {
    return new ConfigurationContext(this._version, this._solution.chatServiceSid);
  };

  Object.defineProperty(ConfigurationListInstance,
    'notifications', {
      get: function notifications() {
        if (!this._notifications) {
          this._notifications = new NotificationList(this._version, this._solution.chatServiceSid);
        }

        return this._notifications;
      }
  });

  Object.defineProperty(ConfigurationListInstance,
    'webhooks', {
      get: function webhooks() {
        if (!this._webhooks) {
          this._webhooks = new WebhookList(this._version, this._solution.chatServiceSid);
        }

        return this._webhooks;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ConfigurationListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ConfigurationListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return ConfigurationListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationPage
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConfigurationPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ConfigurationSolution} solution - Path solution
 *
 * @returns ConfigurationPage
 */
/* jshint ignore:end */
ConfigurationPage = function ConfigurationPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ConfigurationPage.prototype, Page.prototype);
ConfigurationPage.prototype.constructor = ConfigurationPage;

/* jshint ignore:start */
/**
 * Build an instance of ConfigurationInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationPage#
 *
 * @param {ConfigurationPayload} payload - Payload response from the API
 *
 * @returns ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationPage.prototype.getInstance = function getInstance(payload) {
  return new ConfigurationInstance(this._version, payload, this._solution.chatServiceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConfigurationPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConfigurationInstance
 *
 * @property {string} chatServiceSid -
 *          The unique string that identifies the resource
 * @property {string} defaultConversationCreatorRoleSid -
 *          The role assigned to a conversation creator user when they join a new conversation
 * @property {string} defaultConversationRoleSid -
 *          The role assigned to users when they are added to a conversation
 * @property {string} defaultChatServiceRoleSid -
 *          The service role assigned to users when they are added to the service
 * @property {string} url - An absolute URL for this service configuration.
 * @property {string} links -
 *          Absolute URL to access the push notifications configuration of this service.
 * @property {boolean} reachabilityEnabled -
 *          Whether the Reachability Indicator feature is enabled for this Conversations Service
 *
 * @param {V1} version - Version of the resource
 * @param {ConfigurationPayload} payload - The instance payload
 * @param {sid} chatServiceSid - The unique string that identifies the resource
 */
/* jshint ignore:end */
ConfigurationInstance = function ConfigurationInstance(version, payload,
                                                        chatServiceSid) {
  this._version = version;

  // Marshaled Properties
  this.chatServiceSid = payload.chat_service_sid; // jshint ignore:line
  this.defaultConversationCreatorRoleSid = payload.default_conversation_creator_role_sid; // jshint ignore:line
  this.defaultConversationRoleSid = payload.default_conversation_role_sid; // jshint ignore:line
  this.defaultChatServiceRoleSid = payload.default_chat_service_role_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line
  this.reachabilityEnabled = payload.reachability_enabled; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {chatServiceSid: chatServiceSid, };
};

Object.defineProperty(ConfigurationInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ConfigurationContext(this._version, this._solution.chatServiceSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ConfigurationInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a ConfigurationInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.defaultConversationCreatorRoleSid] -
 *          The role assigned to a conversation creator when they join a new conversation
 * @param {string} [opts.defaultConversationRoleSid] -
 *          The role assigned to users when they are added to a conversation
 * @param {string} [opts.defaultChatServiceRoleSid] -
 *          The service role assigned to users when they are added to the service
 * @param {boolean} [opts.reachabilityEnabled] -
 *          Whether the Reachability Indicator feature is enabled for this Conversations Service
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ConfigurationInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ConfigurationContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConfigurationContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} chatServiceSid -
 *          The SID of the Service configuration resource to fetch
 */
/* jshint ignore:end */
ConfigurationContext = function ConfigurationContext(version, chatServiceSid) {
  this._version = version;

  // Path Solution
  this._solution = {chatServiceSid: chatServiceSid, };
  this._uri = `/Services/${chatServiceSid}/Configuration`;
};

/* jshint ignore:start */
/**
 * fetch a ConfigurationInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConfigurationInstance(this._version, payload, this._solution.chatServiceSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ConfigurationInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.defaultConversationCreatorRoleSid] -
 *          The role assigned to a conversation creator when they join a new conversation
 * @param {string} [opts.defaultConversationRoleSid] -
 *          The role assigned to users when they are added to a conversation
 * @param {string} [opts.defaultChatServiceRoleSid] -
 *          The service role assigned to users when they are added to the service
 * @param {boolean} [opts.reachabilityEnabled] -
 *          Whether the Reachability Indicator feature is enabled for this Conversations Service
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ConfigurationInstance
 */
/* jshint ignore:end */
ConfigurationContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'DefaultConversationCreatorRoleSid': _.get(opts, 'defaultConversationCreatorRoleSid'),
    'DefaultConversationRoleSid': _.get(opts, 'defaultConversationRoleSid'),
    'DefaultChatServiceRoleSid': _.get(opts, 'defaultChatServiceRoleSid'),
    'ReachabilityEnabled': serialize.bool(_.get(opts, 'reachabilityEnabled'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new ConfigurationInstance(this._version, payload, this._solution.chatServiceSid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConfigurationContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ConfigurationContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ConfigurationContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ConfigurationList: ConfigurationList,
  ConfigurationPage: ConfigurationPage,
  ConfigurationInstance: ConfigurationInstance,
  ConfigurationContext: ConfigurationContext
};
