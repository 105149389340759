'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var WorkspaceRealTimeStatisticsList;
var WorkspaceRealTimeStatisticsPage;
var WorkspaceRealTimeStatisticsInstance;
var WorkspaceRealTimeStatisticsContext;

/* jshint ignore:start */
/**
 * Initialize the WorkspaceRealTimeStatisticsList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid - The SID of the Workspace
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsList = function
    WorkspaceRealTimeStatisticsList(version, workspaceSid) {
  /* jshint ignore:start */
  /**
   * @function realTimeStatistics
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsContext}
   */
  /* jshint ignore:end */
  function WorkspaceRealTimeStatisticsListInstance(sid) {
    return WorkspaceRealTimeStatisticsListInstance.get(sid);
  }

  WorkspaceRealTimeStatisticsListInstance._version = version;
  // Path Solution
  WorkspaceRealTimeStatisticsListInstance._solution = {workspaceSid: workspaceSid};
  /* jshint ignore:start */
  /**
   * Constructs a workspace_real_time_statistics
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsList#
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsContext}
   */
  /* jshint ignore:end */
  WorkspaceRealTimeStatisticsListInstance.get = function get() {
    return new WorkspaceRealTimeStatisticsContext(this._version, this._solution.workspaceSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WorkspaceRealTimeStatisticsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WorkspaceRealTimeStatisticsListInstance[util.inspect.custom] = function
      inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return WorkspaceRealTimeStatisticsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WorkspaceRealTimeStatisticsPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WorkspaceRealTimeStatisticsSolution} solution - Path solution
 *
 * @returns WorkspaceRealTimeStatisticsPage
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsPage = function
    WorkspaceRealTimeStatisticsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WorkspaceRealTimeStatisticsPage.prototype, Page.prototype);
WorkspaceRealTimeStatisticsPage.prototype.constructor = WorkspaceRealTimeStatisticsPage;

/* jshint ignore:start */
/**
 * Build an instance of WorkspaceRealTimeStatisticsInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsPage#
 *
 * @param {WorkspaceRealTimeStatisticsPayload} payload -
 *          Payload response from the API
 *
 * @returns WorkspaceRealTimeStatisticsInstance
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsPage.prototype.getInstance = function
    getInstance(payload) {
  return new WorkspaceRealTimeStatisticsInstance(this._version, payload, this._solution.workspaceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkspaceRealTimeStatisticsPage.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkspaceRealTimeStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {object} activityStatistics -
 *          The number of current Workers by Activity
 * @property {number} longestTaskWaitingAge - The age of the longest waiting Task
 * @property {string} longestTaskWaitingSid - The SID of the longest waiting Task
 * @property {object} tasksByPriority - The number of Tasks by priority
 * @property {object} tasksByStatus - The number of Tasks by their current status
 * @property {number} totalTasks - The total number of Tasks
 * @property {number} totalWorkers - The total number of Workers in the Workspace
 * @property {string} workspaceSid - The SID of the Workspace
 * @property {string} url - The absolute URL of the Workspace statistics resource
 *
 * @param {V1} version - Version of the resource
 * @param {WorkspaceRealTimeStatisticsPayload} payload - The instance payload
 * @param {sid} workspaceSid - The SID of the Workspace
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsInstance = function
    WorkspaceRealTimeStatisticsInstance(version, payload, workspaceSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.activityStatistics = payload.activity_statistics; // jshint ignore:line
  this.longestTaskWaitingAge = deserialize.integer(payload.longest_task_waiting_age); // jshint ignore:line
  this.longestTaskWaitingSid = payload.longest_task_waiting_sid; // jshint ignore:line
  this.tasksByPriority = payload.tasks_by_priority; // jshint ignore:line
  this.tasksByStatus = payload.tasks_by_status; // jshint ignore:line
  this.totalTasks = deserialize.integer(payload.total_tasks); // jshint ignore:line
  this.totalWorkers = deserialize.integer(payload.total_workers); // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, };
};

Object.defineProperty(WorkspaceRealTimeStatisticsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new WorkspaceRealTimeStatisticsContext(this._version, this._solution.workspaceSid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a WorkspaceRealTimeStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.taskChannel] -
 *          Only calculate real-time statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkspaceRealTimeStatisticsInstance
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsInstance.prototype.fetch = function fetch(opts,
    callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkspaceRealTimeStatisticsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkspaceRealTimeStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid - The SID of the Workspace to fetch
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsContext = function
    WorkspaceRealTimeStatisticsContext(version, workspaceSid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, };
  this._uri = `/Workspaces/${workspaceSid}/RealTimeStatistics`;
};

/* jshint ignore:start */
/**
 * fetch a WorkspaceRealTimeStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.taskChannel] -
 *          Only calculate real-time statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkspaceRealTimeStatisticsInstance
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsContext.prototype.fetch = function fetch(opts,
    callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'TaskChannel': _.get(opts, 'taskChannel')});

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkspaceRealTimeStatisticsInstance(
      this._version,
      payload,
      this._solution.workspaceSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkspaceRealTimeStatisticsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkspaceRealTimeStatisticsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

WorkspaceRealTimeStatisticsContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WorkspaceRealTimeStatisticsList: WorkspaceRealTimeStatisticsList,
  WorkspaceRealTimeStatisticsPage: WorkspaceRealTimeStatisticsPage,
  WorkspaceRealTimeStatisticsInstance: WorkspaceRealTimeStatisticsInstance,
  WorkspaceRealTimeStatisticsContext: WorkspaceRealTimeStatisticsContext
};
