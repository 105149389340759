'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var TrunkList;
var TrunkPage;
var TrunkInstance;
var TrunkContext;

/* jshint ignore:start */
/**
 * Initialize the TrunkList
 *
 * @constructor Twilio.Routes.V2.TrunkList
 *
 * @param {Twilio.Routes.V2} version - Version of the resource
 */
/* jshint ignore:end */
TrunkList = function TrunkList(version) {
  /* jshint ignore:start */
  /**
   * @function trunks
   * @memberof Twilio.Routes.V2#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Routes.V2.TrunkContext}
   */
  /* jshint ignore:end */
  function TrunkListInstance(sid) {
    return TrunkListInstance.get(sid);
  }

  TrunkListInstance._version = version;
  // Path Solution
  TrunkListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a trunk
   *
   * @function get
   * @memberof Twilio.Routes.V2.TrunkList#
   *
   * @param {string} sipTrunkDomain - The SIP Trunk
   *
   * @returns {Twilio.Routes.V2.TrunkContext}
   */
  /* jshint ignore:end */
  TrunkListInstance.get = function get(sipTrunkDomain) {
    return new TrunkContext(this._version, sipTrunkDomain);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Routes.V2.TrunkList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  TrunkListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  TrunkListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return TrunkListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the TrunkPage
 *
 * @constructor Twilio.Routes.V2.TrunkPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {TrunkSolution} solution - Path solution
 *
 * @returns TrunkPage
 */
/* jshint ignore:end */
TrunkPage = function TrunkPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(TrunkPage.prototype, Page.prototype);
TrunkPage.prototype.constructor = TrunkPage;

/* jshint ignore:start */
/**
 * Build an instance of TrunkInstance
 *
 * @function getInstance
 * @memberof Twilio.Routes.V2.TrunkPage#
 *
 * @param {TrunkPayload} payload - Payload response from the API
 *
 * @returns TrunkInstance
 */
/* jshint ignore:end */
TrunkPage.prototype.getInstance = function getInstance(payload) {
  return new TrunkInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.TrunkPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrunkPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrunkContext
 *
 * @constructor Twilio.Routes.V2.TrunkInstance
 *
 * @property {string} sipTrunkDomain - The SIP Trunk
 * @property {string} url - The absolute URL of the resource
 * @property {string} sid -
 *          A string that uniquely identifies the Inbound Processing Region assignments for this SIP Trunk.
 * @property {string} accountSid - Account Sid.
 * @property {string} friendlyName -
 *          A human readable description of the Inbound Processing Region assignments for this SIP Trunk.
 * @property {string} voiceRegion -
 *          The Inbound Processing Region used for this SIP Trunk for voice.
 * @property {Date} dateCreated -
 *          The date that this SIP Trunk was assigned an Inbound Processing Region.
 * @property {Date} dateUpdated -
 *          The date that the Inbound Processing Region was updated for this SIP Trunk.
 *
 * @param {V2} version - Version of the resource
 * @param {TrunkPayload} payload - The instance payload
 * @param {string} sipTrunkDomain - The SIP Trunk
 */
/* jshint ignore:end */
TrunkInstance = function TrunkInstance(version, payload, sipTrunkDomain) {
  this._version = version;

  // Marshaled Properties
  this.sipTrunkDomain = payload.sip_trunk_domain; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.voiceRegion = payload.voice_region; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sipTrunkDomain: sipTrunkDomain || this.sipTrunkDomain, };
};

Object.defineProperty(TrunkInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new TrunkContext(this._version, this._solution.sipTrunkDomain);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a TrunkInstance
 *
 * @function update
 * @memberof Twilio.Routes.V2.TrunkInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.voiceRegion] -
 *          The Inbound Processing Region used for this SIP Trunk for voice
 * @param {string} [opts.friendlyName] -
 *          A human readable description of this resource.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a TrunkInstance
 *
 * @function fetch
 * @memberof Twilio.Routes.V2.TrunkInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.TrunkInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

TrunkInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the TrunkContext
 *
 * @constructor Twilio.Routes.V2.TrunkContext
 *
 * @param {V2} version - Version of the resource
 * @param {string} sipTrunkDomain - The SIP Trunk
 */
/* jshint ignore:end */
TrunkContext = function TrunkContext(version, sipTrunkDomain) {
  this._version = version;

  // Path Solution
  this._solution = {sipTrunkDomain: sipTrunkDomain, };
  this._uri = `/Trunks/${sipTrunkDomain}`;
};

/* jshint ignore:start */
/**
 * update a TrunkInstance
 *
 * @function update
 * @memberof Twilio.Routes.V2.TrunkContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.voiceRegion] -
 *          The Inbound Processing Region used for this SIP Trunk for voice
 * @param {string} [opts.friendlyName] -
 *          A human readable description of this resource.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'VoiceRegion': _.get(opts, 'voiceRegion'),
    'FriendlyName': _.get(opts, 'friendlyName')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new TrunkInstance(this._version, payload, this._solution.sipTrunkDomain));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a TrunkInstance
 *
 * @function fetch
 * @memberof Twilio.Routes.V2.TrunkContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed TrunkInstance
 */
/* jshint ignore:end */
TrunkContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new TrunkInstance(this._version, payload, this._solution.sipTrunkDomain));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Routes.V2.TrunkContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
TrunkContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

TrunkContext.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  TrunkList: TrunkList,
  TrunkPage: TrunkPage,
  TrunkInstance: TrunkInstance,
  TrunkContext: TrunkContext
};
