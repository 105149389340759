import React from 'react';
import {connect, useSelector} from "react-redux"



const LanguageHandler = ({data, is_html}) => {
    const default_lan = useSelector(state => state.config.config.default_language?.value)
    const selected_lan = useSelector(state => state.languages.default_language)
    if(is_html){
        if(data[selected_lan]){
            return data[selected_lan]
          }
            return data[default_lan]
    }
    if(data[selected_lan]){
        return <>{data[selected_lan]}</>
      }
        return <>{data[default_lan]}</>

}

export default LanguageHandler