'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var ParticipantList;
var ParticipantPage;
var ParticipantInstance;
var ParticipantContext;

/* jshint ignore:start */
/**
 * Initialize the ParticipantList
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList
 *
 * @param {Twilio.Conversations.V1} version - Version of the resource
 * @param {string} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @param {string} conversationSid -
 *          The unique ID of the Conversation for this participant.
 */
/* jshint ignore:end */
ParticipantList = function ParticipantList(version, chatServiceSid,
                                            conversationSid) {
  /* jshint ignore:start */
  /**
   * @function participants
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext}
   */
  /* jshint ignore:end */
  function ParticipantListInstance(sid) {
    return ParticipantListInstance.get(sid);
  }

  ParticipantListInstance._version = version;
  // Path Solution
  ParticipantListInstance._solution = {
    chatServiceSid: chatServiceSid,
    conversationSid: conversationSid
  };
  ParticipantListInstance._uri = `/Services/${chatServiceSid}/Conversations/${conversationSid}/Participants`;
  /* jshint ignore:start */
  /**
   * create a ParticipantInstance
   *
   * @function create
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.identity] -
   *          A unique string identifier for the conversation participant as Conversation User.
   * @param {string} [opts.messagingBinding.address] -
   *          The address of the participant's device.
   * @param {string} [opts.messagingBinding.proxyAddress] -
   *          The address of the Twilio phone number that the participant is in contact with.
   * @param {Date} [opts.dateCreated] - The date that this resource was created.
   * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
   * @param {string} [opts.attributes] -
   *          An optional string metadata field you can use to store any data you wish.
   * @param {string} [opts.messagingBinding.projectedAddress] -
   *          The address of the Twilio phone number that is used in Group MMS.
   * @param {string} [opts.roleSid] -
   *          The SID of a conversation-level Role to assign to the participant
   * @param {participant.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
   *          The X-Twilio-Webhook-Enabled HTTP request header
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ParticipantInstance
   */
  /* jshint ignore:end */
  ParticipantListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Identity': _.get(opts, 'identity'),
      'MessagingBinding.Address': _.get(opts, 'messagingBinding.address'),
      'MessagingBinding.ProxyAddress': _.get(opts, 'messagingBinding.proxyAddress'),
      'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
      'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
      'Attributes': _.get(opts, 'attributes'),
      'MessagingBinding.ProjectedAddress': _.get(opts, 'messagingBinding.projectedAddress'),
      'RoleSid': _.get(opts, 'roleSid')
    });
    var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data, headers: headers});

    promise = promise.then(function(payload) {
      deferred.resolve(new ParticipantInstance(
        this._version,
        payload,
        this._solution.chatServiceSid,
        this._solution.conversationSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams ParticipantInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ParticipantListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ParticipantInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ParticipantInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ParticipantPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ParticipantInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ParticipantPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a participant
   *
   * @function get
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @param {string} sid -
   *          A 34 character string that uniquely identifies this resource.
   *
   * @returns {Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext}
   */
  /* jshint ignore:end */
  ParticipantListInstance.get = function get(sid) {
    return new ParticipantContext(
      this._version,
      this._solution.chatServiceSid,
      this._solution.conversationSid,
      sid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ParticipantListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ParticipantListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ParticipantListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantPage
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ParticipantSolution} solution - Path solution
 *
 * @returns ParticipantPage
 */
/* jshint ignore:end */
ParticipantPage = function ParticipantPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ParticipantPage.prototype, Page.prototype);
ParticipantPage.prototype.constructor = ParticipantPage;

/* jshint ignore:start */
/**
 * Build an instance of ParticipantInstance
 *
 * @function getInstance
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantPage#
 *
 * @param {ParticipantPayload} payload - Payload response from the API
 *
 * @returns ParticipantInstance
 */
/* jshint ignore:end */
ParticipantPage.prototype.getInstance = function getInstance(payload) {
  return new ParticipantInstance(
    this._version,
    payload,
    this._solution.chatServiceSid,
    this._solution.conversationSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ParticipantPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantInstance
 *
 * @property {string} accountSid -
 *          The unique ID of the Account responsible for this participant.
 * @property {string} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @property {string} conversationSid -
 *          The unique ID of the Conversation for this participant.
 * @property {string} sid -
 *          A 34 character string that uniquely identifies this resource.
 * @property {string} identity -
 *          A unique string identifier for the conversation participant as Conversation User.
 * @property {string} attributes -
 *          An optional string metadata field you can use to store any data you wish.
 * @property {object} messagingBinding -
 *          Information about how this participant exchanges messages with the conversation.
 * @property {string} roleSid -
 *          The SID of a conversation-level Role to assign to the participant
 * @property {Date} dateCreated - The date that this resource was created.
 * @property {Date} dateUpdated - The date that this resource was last updated.
 * @property {string} url - An absolute URL for this participant.
 * @property {number} lastReadMessageIndex -
 *          Index of last “read” message in the Conversation for the Participant.
 * @property {string} lastReadTimestamp -
 *          Timestamp of last “read” message in the Conversation for the Participant.
 *
 * @param {V1} version - Version of the resource
 * @param {ParticipantPayload} payload - The instance payload
 * @param {sid} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @param {sid} conversationSid -
 *          The unique ID of the Conversation for this participant.
 * @param {sid_like} sid -
 *          A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ParticipantInstance = function ParticipantInstance(version, payload,
                                                    chatServiceSid,
                                                    conversationSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.chatServiceSid = payload.chat_service_sid; // jshint ignore:line
  this.conversationSid = payload.conversation_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line
  this.attributes = payload.attributes; // jshint ignore:line
  this.messagingBinding = payload.messaging_binding; // jshint ignore:line
  this.roleSid = payload.role_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.lastReadMessageIndex = deserialize.integer(payload.last_read_message_index); // jshint ignore:line
  this.lastReadTimestamp = payload.last_read_timestamp; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {
    chatServiceSid: chatServiceSid,
    conversationSid: conversationSid,
    sid: sid || this.sid,
  };
};

Object.defineProperty(ParticipantInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ParticipantContext(
          this._version,
          this._solution.chatServiceSid,
          this._solution.conversationSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a ParticipantInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.identity] -
 *          A unique string identifier for the conversation participant as Conversation User.
 * @param {string} [opts.attributes] -
 *          An optional string metadata field you can use to store any data you wish.
 * @param {string} [opts.roleSid] -
 *          The SID of a conversation-level Role to assign to the participant
 * @param {string} [opts.messagingBinding.proxyAddress] -
 *          The address of the Twilio phone number that the participant is in contact with.
 * @param {string} [opts.messagingBinding.projectedAddress] -
 *          The address of the Twilio phone number that is used in Group MMS.
 * @param {number} [opts.lastReadMessageIndex] -
 *          Index of last “read” message in the Conversation for the Participant.
 * @param {string} [opts.lastReadTimestamp] -
 *          Timestamp of last “read” message in the Conversation for the Participant.
 * @param {participant.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a ParticipantInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {participant.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantInstance.prototype.remove = function remove(opts, callback) {
  return this._proxy.remove(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a ParticipantInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ParticipantInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantContext
 *
 * @constructor Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} chatServiceSid -
 *          The SID of the Conversation Service that the resource is associated with.
 * @param {sid_like} conversationSid -
 *          The unique ID of the Conversation for this participant.
 * @param {sid_like} sid -
 *          A 34 character string that uniquely identifies this resource.
 */
/* jshint ignore:end */
ParticipantContext = function ParticipantContext(version, chatServiceSid,
                                                  conversationSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {chatServiceSid: chatServiceSid, conversationSid: conversationSid, sid: sid, };
  this._uri = `/Services/${chatServiceSid}/Conversations/${conversationSid}/Participants/${sid}`;
};

/* jshint ignore:start */
/**
 * update a ParticipantInstance
 *
 * @function update
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.dateCreated] - The date that this resource was created.
 * @param {Date} [opts.dateUpdated] - The date that this resource was last updated.
 * @param {string} [opts.identity] -
 *          A unique string identifier for the conversation participant as Conversation User.
 * @param {string} [opts.attributes] -
 *          An optional string metadata field you can use to store any data you wish.
 * @param {string} [opts.roleSid] -
 *          The SID of a conversation-level Role to assign to the participant
 * @param {string} [opts.messagingBinding.proxyAddress] -
 *          The address of the Twilio phone number that the participant is in contact with.
 * @param {string} [opts.messagingBinding.projectedAddress] -
 *          The address of the Twilio phone number that is used in Group MMS.
 * @param {number} [opts.lastReadMessageIndex] -
 *          Index of last “read” message in the Conversation for the Participant.
 * @param {string} [opts.lastReadTimestamp] -
 *          Timestamp of last “read” message in the Conversation for the Participant.
 * @param {participant.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'DateCreated': serialize.iso8601DateTime(_.get(opts, 'dateCreated')),
    'DateUpdated': serialize.iso8601DateTime(_.get(opts, 'dateUpdated')),
    'Identity': _.get(opts, 'identity'),
    'Attributes': _.get(opts, 'attributes'),
    'RoleSid': _.get(opts, 'roleSid'),
    'MessagingBinding.ProxyAddress': _.get(opts, 'messagingBinding.proxyAddress'),
    'MessagingBinding.ProjectedAddress': _.get(opts, 'messagingBinding.projectedAddress'),
    'LastReadMessageIndex': _.get(opts, 'lastReadMessageIndex'),
    'LastReadTimestamp': _.get(opts, 'lastReadTimestamp')
  });
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data, headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new ParticipantInstance(
      this._version,
      payload,
      this._solution.chatServiceSid,
      this._solution.conversationSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a ParticipantInstance
 *
 * @function remove
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext#
 *
 * @param {object} [opts] - Options for request
 * @param {participant.webhook_enabled_type} [opts.xTwilioWebhookEnabled] -
 *          The X-Twilio-Webhook-Enabled HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantContext.prototype.remove = function remove(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var headers = values.of({'X-Twilio-Webhook-Enabled': _.get(opts, 'xTwilioWebhookEnabled')});

  var promise = this._version.remove({uri: this._uri, method: 'DELETE', headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a ParticipantInstance
 *
 * @function fetch
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ParticipantInstance(
      this._version,
      payload,
      this._solution.chatServiceSid,
      this._solution.conversationSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Conversations.V1.ServiceContext.ConversationContext.ParticipantContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ParticipantContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ParticipantList: ParticipantList,
  ParticipantPage: ParticipantPage,
  ParticipantInstance: ParticipantInstance,
  ParticipantContext: ParticipantContext
};
