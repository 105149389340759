'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var FormList = require('./v2/form').FormList;
var SafelistList = require('./v2/safelist').SafelistList;
var ServiceList = require('./v2/service').ServiceList;
var TemplateList = require('./v2/template').TemplateList;
var VerificationAttemptList = require(
    './v2/verificationAttempt').VerificationAttemptList;
var VerificationAttemptsSummaryList = require(
    './v2/verificationAttemptsSummary').VerificationAttemptsSummaryList;
var Version = require('../../base/Version');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize the V2 version of Verify
 *
 * @constructor Twilio.Verify.V2
 *
 * @property {Twilio.Verify.V2.FormList} forms - forms resource
 * @property {Twilio.Verify.V2.SafelistList} safelist - safelist resource
 * @property {Twilio.Verify.V2.ServiceList} services - services resource
 * @property {Twilio.Verify.V2.VerificationAttemptList} verificationAttempts -
 *          verificationAttempts resource
 * @property {Twilio.Verify.V2.VerificationAttemptsSummaryList} verificationAttemptsSummary -
 *          verificationAttemptsSummary resource
 * @property {Twilio.Verify.V2.TemplateList} templates - templates resource
 *
 * @param {Twilio.Verify} domain - The twilio domain
 */
/* jshint ignore:end */
function V2(domain) {
  Version.prototype.constructor.call(this, domain, 'v2');

  // Resources
  this._forms = undefined;
  this._safelist = undefined;
  this._services = undefined;
  this._verificationAttempts = undefined;
  this._verificationAttemptsSummary = undefined;
  this._templates = undefined;
}

_.extend(V2.prototype, Version.prototype);
V2.prototype.constructor = V2;

Object.defineProperty(V2.prototype,
  'forms', {
    get: function() {
      this._forms = this._forms || new FormList(this);
      return this._forms;
    }
});

Object.defineProperty(V2.prototype,
  'safelist', {
    get: function() {
      this._safelist = this._safelist || new SafelistList(this);
      return this._safelist;
    }
});

Object.defineProperty(V2.prototype,
  'services', {
    get: function() {
      this._services = this._services || new ServiceList(this);
      return this._services;
    }
});

Object.defineProperty(V2.prototype,
  'verificationAttempts', {
    get: function() {
      this._verificationAttempts = this._verificationAttempts || new VerificationAttemptList(this);
      return this._verificationAttempts;
    }
});

Object.defineProperty(V2.prototype,
  'verificationAttemptsSummary', {
    get: function() {
      this._verificationAttemptsSummary = this._verificationAttemptsSummary || new VerificationAttemptsSummaryList(this);
      return this._verificationAttemptsSummary;
    }
});

Object.defineProperty(V2.prototype,
  'templates', {
    get: function() {
      this._templates = this._templates || new TemplateList(this);
      return this._templates;
    }
});

module.exports = V2;
