'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var ReplaceItemsList;
var ReplaceItemsPage;
var ReplaceItemsInstance;

/* jshint ignore:start */
/**
 * Initialize the ReplaceItemsList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsList
 *
 * @param {Twilio.Numbers.V2} version - Version of the resource
 * @param {string} bundleSid - The unique string that identifies the resource.
 */
/* jshint ignore:end */
ReplaceItemsList = function ReplaceItemsList(version, bundleSid) {
  /* jshint ignore:start */
  /**
   * @function replaceItems
   * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsContext}
   */
  /* jshint ignore:end */
  function ReplaceItemsListInstance(sid) {
    return ReplaceItemsListInstance.get(sid);
  }

  ReplaceItemsListInstance._version = version;
  // Path Solution
  ReplaceItemsListInstance._solution = {bundleSid: bundleSid};
  ReplaceItemsListInstance._uri = `/RegulatoryCompliance/Bundles/${bundleSid}/ReplaceItems`;
  /* jshint ignore:start */
  /**
   * create a ReplaceItemsInstance
   *
   * @function create
   * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.fromBundleSid -
   *          The source bundle sid to copy the item assignments from
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ReplaceItemsInstance
   */
  /* jshint ignore:end */
  ReplaceItemsListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['fromBundleSid'])) {
      throw new Error('Required parameter "opts[\'fromBundleSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'FromBundleSid': _.get(opts, 'fromBundleSid')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ReplaceItemsInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ReplaceItemsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ReplaceItemsListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ReplaceItemsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ReplaceItemsPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ReplaceItemsSolution} solution - Path solution
 *
 * @returns ReplaceItemsPage
 */
/* jshint ignore:end */
ReplaceItemsPage = function ReplaceItemsPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ReplaceItemsPage.prototype, Page.prototype);
ReplaceItemsPage.prototype.constructor = ReplaceItemsPage;

/* jshint ignore:start */
/**
 * Build an instance of ReplaceItemsInstance
 *
 * @function getInstance
 * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsPage#
 *
 * @param {ReplaceItemsPayload} payload - Payload response from the API
 *
 * @returns ReplaceItemsInstance
 */
/* jshint ignore:end */
ReplaceItemsPage.prototype.getInstance = function getInstance(payload) {
  return new ReplaceItemsInstance(this._version, payload, this._solution.bundleSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ReplaceItemsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ReplaceItemsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ReplaceItemsContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} regulationSid - The unique string of a regulation
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {replace_items.status} status -
 *          The verification status of the Bundle resource
 * @property {Date} validUntil -
 *          The ISO 8601 date and time in GMT when the resource will be valid until
 * @property {string} email - The email address
 * @property {string} statusCallback -
 *          The URL we call to inform your application of status changes
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 *
 * @param {V2} version - Version of the resource
 * @param {ReplaceItemsPayload} payload - The instance payload
 * @param {sid} bundleSid - The unique string that identifies the resource.
 */
/* jshint ignore:end */
ReplaceItemsInstance = function ReplaceItemsInstance(version, payload,
                                                      bundleSid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.regulationSid = payload.regulation_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.validUntil = deserialize.iso8601DateTime(payload.valid_until); // jshint ignore:line
  this.email = payload.email; // jshint ignore:line
  this.statusCallback = payload.status_callback; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {bundleSid: bundleSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Numbers.V2.RegulatoryComplianceContext.BundleContext.ReplaceItemsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ReplaceItemsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ReplaceItemsInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ReplaceItemsList: ReplaceItemsList,
  ReplaceItemsPage: ReplaceItemsPage,
  ReplaceItemsInstance: ReplaceItemsInstance
};
