import React, {Component} from 'react';
import {View, TouchableHighlight, Image, Dimensions, Text} from 'react-native';




class FallbackPage extends Component{
   
    getErrorMessage = () => {
        switch(this.props.errorStatus.status){
            case 1 :
                return "Table not found. The table"
            case 2:
                return "One leg of this table is broken. Please sit on other table"
            case 3:
                return "Incorrect venue configuration. Contact support to get assistance on venue configuration"
            case 4:
                return "Menu not available at this moment"
            case 5:
                return "Menu not available at this moment"
            case 6:
                return "Error occured. Contact support"
            case 7 :
                return "Seems like the kitchen is empty"
            case 8 :
                return "License Expired"
    
        }
    }

    MymenuLogo = () => (
        <View style={{backgroundColor:'transparent',width:Dimensions.get('window').width*0.5}}>
            <TouchableHighlight onPress={()=> window.open("https://www.mydigimenu.com/","_blank")} >
                <Image style={{width:220,height:40}} source={require('./../../assets/logo-tans.png')}/>
            </TouchableHighlight>
        </View>
    );

    render(){
        return(
            <View style={{width:'100%',height:'100%'}}>
                <View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
                    <Image source={{uri: `https://mymenu-backend-staging.s3.ap-south-1.amazonaws.com/static/${this.props.errorStatus.status}.png`}} style={{width:'50%',height:'50%',resizeMode:'contain'}} />
                </View>
                <View style={{flex:1.5,alignItems:'center',marginHorizontal:8}}>
                    {/* <Text style={{fontSize:30,color:'grey',marginBottom:15, fontFamily:'SecondaryFont'}}>Oops!</Text> */}
                    <Text style={{fontSize:23,color:'grey',textAlign:'center', fontFamily:'SecondaryFont'}}>{this.getErrorMessage()}</Text>

                </View>

                <View style={{position:'absolute',height:60,width:'100%',bottom:0,justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                    {this.MymenuLogo()}
                </View>
            </View>
        )
    }
}
export default FallbackPage