'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var DayList;
var DayPage;
var DayInstance;
var DayContext;

/* jshint ignore:start */
/**
 * Initialize the DayList
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.DayList
 *
 * @param {Twilio.Bulkexports.V1} version - Version of the resource
 * @param {string} resourceType -
 *          The type of communication – Messages, Calls, Conferences, and Participants
 */
/* jshint ignore:end */
DayList = function DayList(version, resourceType) {
  /* jshint ignore:start */
  /**
   * @function days
   * @memberof Twilio.Bulkexports.V1.ExportContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Bulkexports.V1.ExportContext.DayContext}
   */
  /* jshint ignore:end */
  function DayListInstance(sid) {
    return DayListInstance.get(sid);
  }

  DayListInstance._version = version;
  // Path Solution
  DayListInstance._solution = {resourceType: resourceType};
  DayListInstance._uri = `/Exports/${resourceType}/Days`;
  /* jshint ignore:start */
  /**
   * Streams DayInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  DayListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists DayInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DayListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of DayInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DayListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new DayPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of DayInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  DayListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new DayPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a day
   *
   * @function get
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @param {string} day - The date of the data in the file
   *
   * @returns {Twilio.Bulkexports.V1.ExportContext.DayContext}
   */
  /* jshint ignore:end */
  DayListInstance.get = function get(day) {
    return new DayContext(this._version, this._solution.resourceType, day);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Bulkexports.V1.ExportContext.DayList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  DayListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  DayListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return DayListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the DayPage
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.DayPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {DaySolution} solution - Path solution
 *
 * @returns DayPage
 */
/* jshint ignore:end */
DayPage = function DayPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(DayPage.prototype, Page.prototype);
DayPage.prototype.constructor = DayPage;

/* jshint ignore:start */
/**
 * Build an instance of DayInstance
 *
 * @function getInstance
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayPage#
 *
 * @param {DayPayload} payload - Payload response from the API
 *
 * @returns DayInstance
 */
/* jshint ignore:end */
DayPage.prototype.getInstance = function getInstance(payload) {
  return new DayInstance(this._version, payload, this._solution.resourceType);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
DayPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DayPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DayContext
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.DayInstance
 *
 * @property {string} redirectTo - The redirect_to
 * @property {string} day - The date of the data in the file
 * @property {number} size - Size of the file in bytes
 * @property {string} createDate - The date when resource is created
 * @property {string} friendlyName -
 *          The friendly name specified when creating the job
 * @property {string} resourceType -
 *          The type of communication – Messages, Calls, Conferences, and Participants
 *
 * @param {V1} version - Version of the resource
 * @param {DayPayload} payload - The instance payload
 * @param {string} resourceType -
 *          The type of communication – Messages, Calls, Conferences, and Participants
 * @param {string} day - The date of the data in the file
 */
/* jshint ignore:end */
DayInstance = function DayInstance(version, payload, resourceType, day) {
  this._version = version;

  // Marshaled Properties
  this.redirectTo = payload.redirect_to; // jshint ignore:line
  this.day = payload.day; // jshint ignore:line
  this.size = deserialize.integer(payload.size); // jshint ignore:line
  this.createDate = payload.create_date; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.resourceType = payload.resource_type; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {resourceType: resourceType, day: day || this.day, };
};

Object.defineProperty(DayInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new DayContext(this._version, this._solution.resourceType, this._solution.day);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a DayInstance
 *
 * @function fetch
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DayInstance
 */
/* jshint ignore:end */
DayInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
DayInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DayInstance.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DayContext
 *
 * @constructor Twilio.Bulkexports.V1.ExportContext.DayContext
 *
 * @param {V1} version - Version of the resource
 * @param {string} resourceType -
 *          The type of communication – Messages, Calls, Conferences, and Participants
 * @param {string} day - The date of the data in the file
 */
/* jshint ignore:end */
DayContext = function DayContext(version, resourceType, day) {
  this._version = version;

  // Path Solution
  this._solution = {resourceType: resourceType, day: day, };
  this._uri = `/Exports/${resourceType}/Days/${day}`;
};

/* jshint ignore:start */
/**
 * fetch a DayInstance
 *
 * @function fetch
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DayInstance
 */
/* jshint ignore:end */
DayContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new DayInstance(
      this._version,
      payload,
      this._solution.resourceType,
      this._solution.day
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Bulkexports.V1.ExportContext.DayContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
DayContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

DayContext.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  DayList: DayList,
  DayPage: DayPage,
  DayInstance: DayInstance,
  DayContext: DayContext
};
