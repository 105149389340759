
import {} from './../constants'

var http = window.location.host.startsWith('localhost') ? 'http://':'https://'

const loadMasterCard = (config, callback) => {

    
    const script = document.createElement('script');
      script.src = 'https://test-rakbankpay.mtf.gateway.mastercard.com/checkout/version/59/checkout.js';
      script['data-error'] = http+window.location.host+"/" + config
      script['data-cancel'] = http+window.location.host+"/" + config
      script['data-complete'] = http+window.location.host+"/" + config
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }

    export default loadMasterCard;


{/* <script src="https://test-rakbankpay.mtf.gateway.mastercard.com/checkout/version/59/checkout.js"   
      data-error="https://qr-menu.mydigimenu.com"
      data-cancel="https://qr-menu.mydigimenu.com"
      data-complete="https://qr-menu.mydigimenu.com"
      >
    </script>   */}