'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var FeedbackList;
var FeedbackPage;
var FeedbackInstance;
var FeedbackContext;

/* jshint ignore:start */
/**
 * Initialize the FeedbackList
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.FeedbackList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The unique sid that identifies this account
 * @param {string} callSid - The unique string that identifies this resource
 */
/* jshint ignore:end */
FeedbackList = function FeedbackList(version, accountSid, callSid) {
  /* jshint ignore:start */
  /**
   * @function feedback
   * @memberof Twilio.Api.V2010.AccountContext.CallContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext}
   */
  /* jshint ignore:end */
  function FeedbackListInstance(sid) {
    return FeedbackListInstance.get(sid);
  }

  FeedbackListInstance._version = version;
  // Path Solution
  FeedbackListInstance._solution = {accountSid: accountSid, callSid: callSid};
  /* jshint ignore:start */
  /**
   * Constructs a feedback
   *
   * @function get
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackList#
   *
   * @returns {Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext}
   */
  /* jshint ignore:end */
  FeedbackListInstance.get = function get() {
    return new FeedbackContext(this._version, this._solution.accountSid, this._solution.callSid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  FeedbackListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  FeedbackListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return FeedbackListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the FeedbackPage
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.FeedbackPage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {FeedbackSolution} solution - Path solution
 *
 * @returns FeedbackPage
 */
/* jshint ignore:end */
FeedbackPage = function FeedbackPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(FeedbackPage.prototype, Page.prototype);
FeedbackPage.prototype.constructor = FeedbackPage;

/* jshint ignore:start */
/**
 * Build an instance of FeedbackInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackPage#
 *
 * @param {FeedbackPayload} payload - Payload response from the API
 *
 * @returns FeedbackInstance
 */
/* jshint ignore:end */
FeedbackPage.prototype.getInstance = function getInstance(payload) {
  return new FeedbackInstance(
    this._version,
    payload,
    this._solution.accountSid,
    this._solution.callSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
FeedbackPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FeedbackPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FeedbackContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.FeedbackInstance
 *
 * @property {string} accountSid - The unique sid that identifies this account
 * @property {Date} dateCreated - The date this resource was created
 * @property {Date} dateUpdated - The date this resource was last updated
 * @property {feedback.issues} issues - Issues experienced during the call
 * @property {number} qualityScore - 1 to 5 quality score
 * @property {string} sid -
 *          A string that uniquely identifies this feedback resource
 *
 * @param {V2010} version - Version of the resource
 * @param {FeedbackPayload} payload - The instance payload
 * @param {sid} accountSid - The unique sid that identifies this account
 * @param {sid} callSid - The unique string that identifies this resource
 */
/* jshint ignore:end */
FeedbackInstance = function FeedbackInstance(version, payload, accountSid,
                                              callSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.rfc2822DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.rfc2822DateTime(payload.date_updated); // jshint ignore:line
  this.issues = payload.issues; // jshint ignore:line
  this.qualityScore = deserialize.integer(payload.quality_score); // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, callSid: callSid, };
};

Object.defineProperty(FeedbackInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new FeedbackContext(
          this._version,
          this._solution.accountSid,
          this._solution.callSid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a FeedbackInstance
 *
 * @function fetch
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * create a FeedbackInstance
 *
 * @function create
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackInstance#
 *
 * @param {object} opts - Options for request
 * @param {number} opts.qualityScore -
 *          The call quality expressed as an integer from 1 to 5
 * @param {feedback.issues|list} [opts.issue] - Issues experienced during the call
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackInstance.prototype.create = function create(opts, callback) {
  return this._proxy.create(opts, callback);
};

/* jshint ignore:start */
/**
 * update a FeedbackInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.qualityScore] -
 *          The call quality expressed as an integer from 1 to 5
 * @param {feedback.issues|list} [opts.issue] - Issues experienced during the call
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
FeedbackInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

FeedbackInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the FeedbackContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext
 *
 * @param {V2010} version - Version of the resource
 * @param {sid} accountSid - The unique sid that identifies this account
 * @param {sid} callSid - The call sid that uniquely identifies the call
 */
/* jshint ignore:end */
FeedbackContext = function FeedbackContext(version, accountSid, callSid) {
  this._version = version;

  // Path Solution
  this._solution = {accountSid: accountSid, callSid: callSid, };
  this._uri = `/Accounts/${accountSid}/Calls/${callSid}/Feedback.json`;
};

/* jshint ignore:start */
/**
 * fetch a FeedbackInstance
 *
 * @function fetch
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new FeedbackInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.callSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * create a FeedbackInstance
 *
 * @function create
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext#
 *
 * @param {object} opts - Options for request
 * @param {number} opts.qualityScore -
 *          The call quality expressed as an integer from 1 to 5
 * @param {feedback.issues|list} [opts.issue] - Issues experienced during the call
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackContext.prototype.create = function create(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['qualityScore'])) {
    throw new Error('Required parameter "opts[\'qualityScore\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'QualityScore': _.get(opts, 'qualityScore'),
    'Issue': serialize.map(_.get(opts, 'issue'), function(e) { return e; })
  });

  var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new FeedbackInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.callSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a FeedbackInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.qualityScore] -
 *          The call quality expressed as an integer from 1 to 5
 * @param {feedback.issues|list} [opts.issue] - Issues experienced during the call
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed FeedbackInstance
 */
/* jshint ignore:end */
FeedbackContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'QualityScore': _.get(opts, 'qualityScore'),
    'Issue': serialize.map(_.get(opts, 'issue'), function(e) { return e; })
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new FeedbackInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.callSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.FeedbackContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
FeedbackContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

FeedbackContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  FeedbackList: FeedbackList,
  FeedbackPage: FeedbackPage,
  FeedbackInstance: FeedbackInstance,
  FeedbackContext: FeedbackContext
};
