'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var UserDefinedMessageList;
var UserDefinedMessagePage;
var UserDefinedMessageInstance;

/* jshint ignore:start */
/**
 * Initialize the UserDefinedMessageList
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - Account SID.
 * @param {string} callSid - Call SID.
 */
/* jshint ignore:end */
UserDefinedMessageList = function UserDefinedMessageList(version, accountSid,
                                                          callSid) {
  /* jshint ignore:start */
  /**
   * @function userDefinedMessages
   * @memberof Twilio.Api.V2010.AccountContext.CallContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageContext}
   */
  /* jshint ignore:end */
  function UserDefinedMessageListInstance(sid) {
    return UserDefinedMessageListInstance.get(sid);
  }

  UserDefinedMessageListInstance._version = version;
  // Path Solution
  UserDefinedMessageListInstance._solution = {accountSid: accountSid, callSid: callSid};
  UserDefinedMessageListInstance._uri = `/Accounts/${accountSid}/Calls/${callSid}/UserDefinedMessages.json`;
  /* jshint ignore:start */
  /**
   * create a UserDefinedMessageInstance
   *
   * @function create
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.content -
   *          The User Defined Message in the form of URL-encoded JSON string.
   * @param {string} [opts.idempotencyKey] -
   *          A unique string value to identify API call. This should be a unique string value per API call and can be a randomly generated.
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed UserDefinedMessageInstance
   */
  /* jshint ignore:end */
  UserDefinedMessageListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['content'])) {
      throw new Error('Required parameter "opts[\'content\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'Content': _.get(opts, 'content'),
      'IdempotencyKey': _.get(opts, 'idempotencyKey')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new UserDefinedMessageInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  UserDefinedMessageListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  UserDefinedMessageListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return UserDefinedMessageListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the UserDefinedMessagePage
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessagePage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {UserDefinedMessageSolution} solution - Path solution
 *
 * @returns UserDefinedMessagePage
 */
/* jshint ignore:end */
UserDefinedMessagePage = function UserDefinedMessagePage(version, response,
                                                          solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(UserDefinedMessagePage.prototype, Page.prototype);
UserDefinedMessagePage.prototype.constructor = UserDefinedMessagePage;

/* jshint ignore:start */
/**
 * Build an instance of UserDefinedMessageInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessagePage#
 *
 * @param {UserDefinedMessagePayload} payload - Payload response from the API
 *
 * @returns UserDefinedMessageInstance
 */
/* jshint ignore:end */
UserDefinedMessagePage.prototype.getInstance = function getInstance(payload) {
  return new UserDefinedMessageInstance(
    this._version,
    payload,
    this._solution.accountSid,
    this._solution.callSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessagePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
UserDefinedMessagePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UserDefinedMessagePage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the UserDefinedMessageContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageInstance
 *
 * @property {string} accountSid - Account SID.
 * @property {string} callSid - Call SID.
 * @property {string} sid - User Defined Message SID.
 * @property {Date} dateCreated - The date this User Defined Message was created.
 *
 * @param {V2010} version - Version of the resource
 * @param {UserDefinedMessagePayload} payload - The instance payload
 * @param {sid} accountSid - Account SID.
 * @param {sid} callSid - Call SID.
 */
/* jshint ignore:end */
UserDefinedMessageInstance = function UserDefinedMessageInstance(version,
    payload, accountSid, callSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.callSid = payload.call_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.dateCreated = deserialize.rfc2822DateTime(payload.date_created); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, callSid: callSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.UserDefinedMessageInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
UserDefinedMessageInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UserDefinedMessageInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  UserDefinedMessageList: UserDefinedMessageList,
  UserDefinedMessagePage: UserDefinedMessagePage,
  UserDefinedMessageInstance: UserDefinedMessageInstance
};
