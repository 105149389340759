'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V1 = require('./frontlineApi/V1');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize frontline_api domain
 *
 * @constructor Twilio.FrontlineApi
 *
 * @property {Twilio.FrontlineApi.V1} v1 - v1 version
 * @property {Twilio.FrontlineApi.V1.UserList} users - users resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function FrontlineApi(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://frontline-api.twilio.com');

  // Versions
  this._v1 = undefined;
}

_.extend(FrontlineApi.prototype, Domain.prototype);
FrontlineApi.prototype.constructor = FrontlineApi;

Object.defineProperty(FrontlineApi.prototype,
  'v1', {
    get: function() {
      this._v1 = this._v1 || new V1(this);
      return this._v1;
    }
});

Object.defineProperty(FrontlineApi.prototype,
  'users', {
    get: function() {
      return this.v1.users;
    }
});

module.exports = FrontlineApi;
