'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var DeploymentList = require('./environment/deployment').DeploymentList;
var LogList = require('./environment/log').LogList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var VariableList = require('./environment/variable').VariableList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var EnvironmentList;
var EnvironmentPage;
var EnvironmentInstance;
var EnvironmentContext;

/* jshint ignore:start */
/**
 * Initialize the EnvironmentList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.EnvironmentList
 *
 * @param {Twilio.Serverless.V1} version - Version of the resource
 * @param {string} serviceSid -
 *          The SID of the Service that the Environment resource is associated with
 */
/* jshint ignore:end */
EnvironmentList = function EnvironmentList(version, serviceSid) {
  /* jshint ignore:start */
  /**
   * @function environments
   * @memberof Twilio.Serverless.V1.ServiceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Serverless.V1.ServiceContext.EnvironmentContext}
   */
  /* jshint ignore:end */
  function EnvironmentListInstance(sid) {
    return EnvironmentListInstance.get(sid);
  }

  EnvironmentListInstance._version = version;
  // Path Solution
  EnvironmentListInstance._solution = {serviceSid: serviceSid};
  EnvironmentListInstance._uri = `/Services/${serviceSid}/Environments`;
  /* jshint ignore:start */
  /**
   * Streams EnvironmentInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  EnvironmentListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists EnvironmentInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EnvironmentListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of EnvironmentInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EnvironmentListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new EnvironmentPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of EnvironmentInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  EnvironmentListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new EnvironmentPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a EnvironmentInstance
   *
   * @function create
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.uniqueName -
   *          A user-defined string that uniquely identifies the Environment resource
   * @param {string} [opts.domainSuffix] -
   *          A URL-friendly name that represents the environment
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed EnvironmentInstance
   */
  /* jshint ignore:end */
  EnvironmentListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['uniqueName'])) {
      throw new Error('Required parameter "opts[\'uniqueName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'UniqueName': _.get(opts, 'uniqueName'),
      'DomainSuffix': _.get(opts, 'domainSuffix')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new EnvironmentInstance(
        this._version,
        payload,
        this._solution.serviceSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a environment
   *
   * @function get
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @param {string} sid - The SID of the Environment resource to fetch
   *
   * @returns {Twilio.Serverless.V1.ServiceContext.EnvironmentContext}
   */
  /* jshint ignore:end */
  EnvironmentListInstance.get = function get(sid) {
    return new EnvironmentContext(this._version, this._solution.serviceSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  EnvironmentListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  EnvironmentListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return EnvironmentListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the EnvironmentPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.EnvironmentPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {EnvironmentSolution} solution - Path solution
 *
 * @returns EnvironmentPage
 */
/* jshint ignore:end */
EnvironmentPage = function EnvironmentPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(EnvironmentPage.prototype, Page.prototype);
EnvironmentPage.prototype.constructor = EnvironmentPage;

/* jshint ignore:start */
/**
 * Build an instance of EnvironmentInstance
 *
 * @function getInstance
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentPage#
 *
 * @param {EnvironmentPayload} payload - Payload response from the API
 *
 * @returns EnvironmentInstance
 */
/* jshint ignore:end */
EnvironmentPage.prototype.getInstance = function getInstance(payload) {
  return new EnvironmentInstance(this._version, payload, this._solution.serviceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
EnvironmentPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

EnvironmentPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the EnvironmentContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.EnvironmentInstance
 *
 * @property {string} sid -
 *          The unique string that identifies the Environment resource
 * @property {string} accountSid -
 *          The SID of the Account that created the Environment resource
 * @property {string} serviceSid -
 *          The SID of the Service that the Environment resource is associated with
 * @property {string} buildSid - The SID of the build deployed in the environment
 * @property {string} uniqueName -
 *          A user-defined string that uniquely identifies the Environment resource
 * @property {string} domainSuffix -
 *          A URL-friendly name that represents the environment
 * @property {string} domainName -
 *          The domain name for all Functions and Assets deployed in the Environment
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the Environment resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the Environment resource was last updated
 * @property {string} url - The absolute URL of the Environment resource
 * @property {string} links -
 *          The URLs of the Environment resource's nested resources
 *
 * @param {V1} version - Version of the resource
 * @param {EnvironmentPayload} payload - The instance payload
 * @param {sid} serviceSid -
 *          The SID of the Service that the Environment resource is associated with
 * @param {sid} sid - The SID of the Environment resource to fetch
 */
/* jshint ignore:end */
EnvironmentInstance = function EnvironmentInstance(version, payload, serviceSid,
                                                    sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.serviceSid = payload.service_sid; // jshint ignore:line
  this.buildSid = payload.build_sid; // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.domainSuffix = payload.domain_suffix; // jshint ignore:line
  this.domainName = payload.domain_name; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {serviceSid: serviceSid, sid: sid || this.sid, };
};

Object.defineProperty(EnvironmentInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new EnvironmentContext(
          this._version,
          this._solution.serviceSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a EnvironmentInstance
 *
 * @function fetch
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EnvironmentInstance
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a EnvironmentInstance
 *
 * @function remove
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EnvironmentInstance
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Access the variables
 *
 * @function variables
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @returns {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.VariableList}
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.variables = function variables() {
  return this._proxy.variables;
};

/* jshint ignore:start */
/**
 * Access the deployments
 *
 * @function deployments
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @returns {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.DeploymentList}
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.deployments = function deployments() {
  return this._proxy.deployments;
};

/* jshint ignore:start */
/**
 * Access the logs
 *
 * @function logs
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @returns {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.LogList}
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.logs = function logs() {
  return this._proxy.logs;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
EnvironmentInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

EnvironmentInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the EnvironmentContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Serverless.V1.ServiceContext.EnvironmentContext
 *
 * @property {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.VariableList} variables -
 *          variables resource
 * @property {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.DeploymentList} deployments -
 *          deployments resource
 * @property {Twilio.Serverless.V1.ServiceContext.EnvironmentContext.LogList} logs -
 *          logs resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} serviceSid -
 *          The SID of the Service to fetch the Environment resource from
 * @param {sid} sid - The SID of the Environment resource to fetch
 */
/* jshint ignore:end */
EnvironmentContext = function EnvironmentContext(version, serviceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {serviceSid: serviceSid, sid: sid, };
  this._uri = `/Services/${serviceSid}/Environments/${sid}`;

  // Dependents
  this._variables = undefined;
  this._deployments = undefined;
  this._logs = undefined;
};

/* jshint ignore:start */
/**
 * fetch a EnvironmentInstance
 *
 * @function fetch
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EnvironmentInstance
 */
/* jshint ignore:end */
EnvironmentContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new EnvironmentInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a EnvironmentInstance
 *
 * @function remove
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed EnvironmentInstance
 */
/* jshint ignore:end */
EnvironmentContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(EnvironmentContext.prototype,
  'variables', {
    get: function() {
      if (!this._variables) {
        this._variables = new VariableList(this._version, this._solution.serviceSid, this._solution.sid);
      }
      return this._variables;
    }
});

Object.defineProperty(EnvironmentContext.prototype,
  'deployments', {
    get: function() {
      if (!this._deployments) {
        this._deployments = new DeploymentList(
          this._version,
          this._solution.serviceSid,
          this._solution.sid
        );
      }
      return this._deployments;
    }
});

Object.defineProperty(EnvironmentContext.prototype,
  'logs', {
    get: function() {
      if (!this._logs) {
        this._logs = new LogList(this._version, this._solution.serviceSid, this._solution.sid);
      }
      return this._logs;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Serverless.V1.ServiceContext.EnvironmentContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
EnvironmentContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

EnvironmentContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  EnvironmentList: EnvironmentList,
  EnvironmentPage: EnvironmentPage,
  EnvironmentInstance: EnvironmentInstance,
  EnvironmentContext: EnvironmentContext
};
