'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var CustomerProfilesChannelEndpointAssignmentList;
var CustomerProfilesChannelEndpointAssignmentPage;
var CustomerProfilesChannelEndpointAssignmentInstance;
var CustomerProfilesChannelEndpointAssignmentContext;

/* jshint ignore:start */
/**
 * Initialize the CustomerProfilesChannelEndpointAssignmentList
 *
 * @constructor Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList
 *
 * @param {Twilio.Trusthub.V1} version - Version of the resource
 * @param {string} customerProfileSid -
 *          The unique string that identifies the CustomerProfile resource.
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentList = function
    CustomerProfilesChannelEndpointAssignmentList(version, customerProfileSid) {
  /* jshint ignore:start */
  /**
   * @function customerProfilesChannelEndpointAssignment
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext}
   */
  /* jshint ignore:end */
  function CustomerProfilesChannelEndpointAssignmentListInstance(sid) {
    return CustomerProfilesChannelEndpointAssignmentListInstance.get(sid);
  }

  CustomerProfilesChannelEndpointAssignmentListInstance._version = version;
  // Path Solution
  CustomerProfilesChannelEndpointAssignmentListInstance._solution = {
    customerProfileSid: customerProfileSid
  };
  CustomerProfilesChannelEndpointAssignmentListInstance._uri = `/CustomerProfiles/${customerProfileSid}/ChannelEndpointAssignments`;
  /* jshint ignore:start */
  /**
   * create a CustomerProfilesChannelEndpointAssignmentInstance
   *
   * @function create
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.channelEndpointType - The type of channel endpoint
   * @param {string} opts.channelEndpointSid - The sid of an channel endpoint
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed CustomerProfilesChannelEndpointAssignmentInstance
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.create = function
      create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['channelEndpointType'])) {
      throw new Error('Required parameter "opts[\'channelEndpointType\']" missing.');
    }
    if (_.isUndefined(opts['channelEndpointSid'])) {
      throw new Error('Required parameter "opts[\'channelEndpointSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'ChannelEndpointType': _.get(opts, 'channelEndpointType'),
      'ChannelEndpointSid': _.get(opts, 'channelEndpointSid')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new CustomerProfilesChannelEndpointAssignmentInstance(
        this._version,
        payload,
        this._solution.customerProfileSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Streams CustomerProfilesChannelEndpointAssignmentInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.channelEndpointSid] - The sid of an channel endpoint
   * @param {string} [opts.channelEndpointSids] -
   *          comma separated list of channel endpoint sids
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.each = function each(opts,
      callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists CustomerProfilesChannelEndpointAssignmentInstance records from the API as
   * a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.channelEndpointSid] - The sid of an channel endpoint
   * @param {string} [opts.channelEndpointSids] -
   *          comma separated list of channel endpoint sids
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.list = function list(opts,
      callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of CustomerProfilesChannelEndpointAssignmentInstance
   * records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.channelEndpointSid] - The sid of an channel endpoint
   * @param {string} [opts.channelEndpointSids] -
   *          comma separated list of channel endpoint sids
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.page = function page(opts,
      callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'ChannelEndpointSid': _.get(opts, 'channelEndpointSid'),
      'ChannelEndpointSids': _.get(opts, 'channelEndpointSids'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new CustomerProfilesChannelEndpointAssignmentPage(
        this._version,
        payload,
        this._solution
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of
   * CustomerProfilesChannelEndpointAssignmentInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.getPage = function
      getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new CustomerProfilesChannelEndpointAssignmentPage(
        this._version,
        payload,
        this._solution
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a customer_profiles_channel_endpoint_assignment
   *
   * @function get
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext}
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.get = function get(sid) {
    return new CustomerProfilesChannelEndpointAssignmentContext(
      this._version,
      this._solution.customerProfileSid,
      sid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  CustomerProfilesChannelEndpointAssignmentListInstance.toJSON = function toJSON()
      {
    return this._solution;
  };

  CustomerProfilesChannelEndpointAssignmentListInstance[util.inspect.custom] =
      function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return CustomerProfilesChannelEndpointAssignmentListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the CustomerProfilesChannelEndpointAssignmentPage
 *
 * @constructor Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {CustomerProfilesChannelEndpointAssignmentSolution} solution -
 *          Path solution
 *
 * @returns CustomerProfilesChannelEndpointAssignmentPage
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentPage = function
    CustomerProfilesChannelEndpointAssignmentPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(CustomerProfilesChannelEndpointAssignmentPage.prototype, Page.prototype);
CustomerProfilesChannelEndpointAssignmentPage.prototype.constructor = CustomerProfilesChannelEndpointAssignmentPage;

/* jshint ignore:start */
/**
 * Build an instance of CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @function getInstance
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentPage#
 *
 * @param {CustomerProfilesChannelEndpointAssignmentPayload} payload -
 *          Payload response from the API
 *
 * @returns CustomerProfilesChannelEndpointAssignmentInstance
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentPage.prototype.getInstance = function
    getInstance(payload) {
  return new CustomerProfilesChannelEndpointAssignmentInstance(
    this._version,
    payload,
    this._solution.customerProfileSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentPage.prototype.toJSON = function
    toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CustomerProfilesChannelEndpointAssignmentPage.prototype[util.inspect.custom] =
    function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CustomerProfilesChannelEndpointAssignmentContext
 *
 * @constructor Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} customerProfileSid -
 *          The unique string that identifies the CustomerProfile resource.
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} channelEndpointType - The type of channel endpoint
 * @property {string} channelEndpointSid - The sid of an channel endpoint
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {string} url - The absolute URL of the Identity resource
 *
 * @param {V1} version - Version of the resource
 * @param {CustomerProfilesChannelEndpointAssignmentPayload} payload -
 *          The instance payload
 * @param {sid} customerProfileSid -
 *          The unique string that identifies the CustomerProfile resource.
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentInstance = function
    CustomerProfilesChannelEndpointAssignmentInstance(version, payload,
    customerProfileSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.customerProfileSid = payload.customer_profile_sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.channelEndpointType = payload.channel_endpoint_type; // jshint ignore:line
  this.channelEndpointSid = payload.channel_endpoint_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {customerProfileSid: customerProfileSid, sid: sid || this.sid, };
};

Object.defineProperty(CustomerProfilesChannelEndpointAssignmentInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new CustomerProfilesChannelEndpointAssignmentContext(
          this._version,
          this._solution.customerProfileSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @function fetch
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CustomerProfilesChannelEndpointAssignmentInstance
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentInstance.prototype.fetch = function
    fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @function remove
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CustomerProfilesChannelEndpointAssignmentInstance
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentInstance.prototype.remove = function
    remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentInstance.prototype.toJSON = function
    toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CustomerProfilesChannelEndpointAssignmentInstance.prototype[util.inspect.custom]
    = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CustomerProfilesChannelEndpointAssignmentContext
 *
 * @constructor Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} customerProfileSid -
 *          The unique string that identifies the resource.
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentContext = function
    CustomerProfilesChannelEndpointAssignmentContext(version,
    customerProfileSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {customerProfileSid: customerProfileSid, sid: sid, };
  this._uri = `/CustomerProfiles/${customerProfileSid}/ChannelEndpointAssignments/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @function fetch
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CustomerProfilesChannelEndpointAssignmentInstance
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentContext.prototype.fetch = function
    fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new CustomerProfilesChannelEndpointAssignmentInstance(
      this._version,
      payload,
      this._solution.customerProfileSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a CustomerProfilesChannelEndpointAssignmentInstance
 *
 * @function remove
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CustomerProfilesChannelEndpointAssignmentInstance
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentContext.prototype.remove = function
    remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Trusthub.V1.CustomerProfilesContext.CustomerProfilesChannelEndpointAssignmentContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
CustomerProfilesChannelEndpointAssignmentContext.prototype.toJSON = function
    toJSON() {
  return this._solution;
};

CustomerProfilesChannelEndpointAssignmentContext.prototype[util.inspect.custom]
    = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  CustomerProfilesChannelEndpointAssignmentList: CustomerProfilesChannelEndpointAssignmentList,
  CustomerProfilesChannelEndpointAssignmentPage: CustomerProfilesChannelEndpointAssignmentPage,
  CustomerProfilesChannelEndpointAssignmentInstance: CustomerProfilesChannelEndpointAssignmentInstance,
  CustomerProfilesChannelEndpointAssignmentContext: CustomerProfilesChannelEndpointAssignmentContext
};
