'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var Domain = require('../base/Domain');  /* jshint ignore:line */
var V2 = require('./verify/V2');  /* jshint ignore:line */


/* jshint ignore:start */
/**
 * Initialize verify domain
 *
 * @constructor Twilio.Verify
 *
 * @property {Twilio.Verify.V2} v2 - v2 version
 * @property {Twilio.Verify.V2.FormList} forms - forms resource
 * @property {Twilio.Verify.V2.SafelistList} safelist - safelist resource
 * @property {Twilio.Verify.V2.ServiceList} services - services resource
 * @property {Twilio.Verify.V2.VerificationAttemptList} verificationAttempts -
 *          verificationAttempts resource
 * @property {Twilio.Verify.V2.VerificationAttemptsSummaryList} verificationAttemptsSummary -
 *          verificationAttemptsSummary resource
 * @property {Twilio.Verify.V2.TemplateList} templates - templates resource
 *
 * @param {Twilio} twilio - The twilio client
 */
/* jshint ignore:end */
function Verify(twilio) {
  Domain.prototype.constructor.call(this, twilio, 'https://verify.twilio.com');

  // Versions
  this._v2 = undefined;
}

_.extend(Verify.prototype, Domain.prototype);
Verify.prototype.constructor = Verify;

Object.defineProperty(Verify.prototype,
  'v2', {
    get: function() {
      this._v2 = this._v2 || new V2(this);
      return this._v2;
    }
});

Object.defineProperty(Verify.prototype,
  'forms', {
    get: function() {
      return this.v2.forms;
    }
});

Object.defineProperty(Verify.prototype,
  'safelist', {
    get: function() {
      return this.v2.safelist;
    }
});

Object.defineProperty(Verify.prototype,
  'services', {
    get: function() {
      return this.v2.services;
    }
});

Object.defineProperty(Verify.prototype,
  'verificationAttempts', {
    get: function() {
      return this.v2.verificationAttempts;
    }
});

Object.defineProperty(Verify.prototype,
  'verificationAttemptsSummary', {
    get: function() {
      return this.v2.verificationAttemptsSummary;
    }
});

Object.defineProperty(Verify.prototype,
  'templates', {
    get: function() {
      return this.v2.templates;
    }
});

module.exports = Verify;
