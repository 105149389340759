import React, { PureComponent } from "react";
import { View, Text, FlatList, ScrollView, Image } from "react-native";
import { style } from "../../assets/style";
import SectionCampaign from "./MenuCampaign";
import IntlLabel from "./../handlers/languageHandler";
import IntlLabelFn from "./../handlers/languageHandlerFn";
import { FontLetterSpacing } from "./../handlers/utils";
import i18n from "../i18n";
import StyledText from "../components/styledText"

const ListItem = ({
  item,
  allChildren,
  default_language,
  itemContent,
  renderContent,
  config,
  index,
  length,
  section_campaigns,
  onClickCampaign,
  design,
}) => {

  const setSpecialNotes = () => {
    let text = config.special_notes_multi ? 
    IntlLabelFn(
      JSON.parse(config.special_notes_multi), 
      config.default_language.value, 
      default_language
      ) : ""
      return text
  }
  return (
    <View   nativeID={`recipe-${index}`}
    >

      {item.type ? (
        item.type == "menu" ? (
          itemContent(
            allChildren.filter(
              (childrenId) =>
                childrenId.section == null && childrenId.type == "item" && childrenId.show_item
            ),
            item
          )
        ) : (
          <>
            <View
              style={{ alignItems: "center", marginBottom: 5, width: "100%",marginTop:10 }}
            >
              {config.section_name_enabled ? (
                <StyledText
                font_color={design.list_view_header_color}
                font_weight={design.list_view_header_font_weight}
                font_style={design.list_view_header_font_style}
                text_transform={design.list_view_header_font_transform}
                font_family={design.list_view_header_font_family}
                font_size={design.list_view_header_font_size?? 23}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                  style={{
                    textAlign: "center",
                    marginHorizontal: 14,
                    alignSelf: "center",
                    marginBottom:2
                  }}
                >
                  <IntlLabel  data={item.item_names} />
                </StyledText>
              ) : null}
              {config.section_desc_enabled ? (
                <StyledText
                  font_color={design.list_view_sub_text_color}
                  font_weight={design.list_view_sub_text_font_weight}
                  font_style={design.list_view_sub_text_font_style}
                  text_transform={design.list_view_sub_text_font_transform}
                  font_family={design.list_view_sub_text_font_family}
                  font_size={design.list_view_sub_text_font_size?? 15}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                  style={{ 
                    textAlign: "center",
                    marginHorizontal: 14,
                    alignSelf: "center",
                    marginBottom:2
                  }}
                >
                  <IntlLabel  data={item.item_descriptions} />
                </StyledText>
              ) : null}
              {config.section_note_enabled ? (
                <StyledText
                  font_color={design.list_view_text_color}
                  font_weight={design.list_view_text_font_weight}
                  font_style={design.list_view_text_font_style}
                  text_transform={design.list_view_text_font_transform}
                  font_family={design.list_view_text_font_family}
                  font_size={design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                  style={{
                    textAlign: "center",
                    marginHorizontal: 14,
                    alignSelf: "center",
                    marginBottom:2
                  }}
                >
                  {item.note}
                </StyledText>
              ) : null}
            </View>
            {/* {console.log(section_campaigns, "sectionnnnnnnnnnn")} */}
            {section_campaigns ? (
              section_campaigns.length ? (
                <View style={{ marginBottom: 5, height: 145 }}>
                  <ScrollView
                    pagingEnabled
                    horizontal
                    showsHorizontalScrollIndicator={false}
                  >
                    {section_campaigns.map((item) => (
                      <SectionCampaign
                        item={item}
                        menu_campaign={section_campaigns}
                        onPressItem={() => onClickCampaign(item)}
                      />
                    ))}
                  </ScrollView>
                </View>
              ) : (
                <View />
              )
            ) : (
              <View />
            )}

            {itemContent(
              allChildren.filter(
                (childrenId) =>
                  childrenId.section === item.id && childrenId.type == "item"
              ),
              item
            )}
            {renderContent(
              allChildren.filter(
                (childrenId) =>
                  childrenId.section === item.id && childrenId.type == "section"
              )
            )}
          </>
        )
      ) : (
        <>
          <View
            style={{ alignItems: "center", marginBottom: 20, width: "100%" }}
          >
           
            {config.section_name_enabled ? (
              <StyledText
                font_color={design.list_view_header_color}
                font_weight={design.list_view_header_font_weight}
                font_style={design.list_view_header_font_style}
                text_transform={design.list_view_header_font_transform}
                font_family={design.list_view_header_font_family}
                font_size={design.list_view_header_font_size?? 23}
                letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                style={{
                  textAlign: "center",
                  marginHorizontal: 14,
                  alignSelf: "center",
                  marginBottom:2
                }}
              >
                <IntlLabel  data={item.item_names} />
              </StyledText>
            ) : null}
            {config.section_desc_enabled ? (
              <StyledText
                  font_color={design.list_view_sub_text_color}
                  font_weight={design.list_view_sub_text_font_weight}
                  font_style={design.list_view_sub_text_font_style}
                  text_transform={design.list_view_sub_text_font_transform}
                  font_family={design.list_view_sub_text_font_family}
                  font_size={design.list_view_sub_text_font_size?? 15}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}

                style={{
                  textAlign: "center",
                  marginHorizontal: 14,
                  alignSelf: "center",
                  marginBottom:2
                }}
              >
                <IntlLabel  data={item.item_descriptions} />
              </StyledText>
            ) : null}
            {config.section_note_enabled ? (
              <StyledText
                font_color={design.list_view_text_color}
                  font_weight={design.list_view_text_font_weight}
                  font_style={design.list_view_text_font_style}
                  text_transform={design.list_view_text_font_transform}
                  font_family={design.list_view_text_font_family}
                  font_size={design.list_view_text_font_size?? 12}
                  letter_spacing={FontLetterSpacing(design.custom_font.primary_letter_space)}
                style={{
                  textAlign: "center",
                  marginHorizontal: 14,
                  alignSelf: "center",
                  marginBottom:2
                }}
              >
                {item.note}
              </StyledText>
            ) : null}
          </View>
          {section_campaigns ? (
            section_campaigns.length ? (
              <View style={{ marginBottom: 5, height: 145 }}>
                <ScrollView
                  pagingEnabled
                  horizontal
                  showsHorizontalScrollIndicator={false}
                >
                  {section_campaigns.map((item) => (
                    <SectionCampaign
                      item={item}
                      menu_campaign={section_campaigns}
                      onPressItem={() => onClickCampaign(item)}
                    />
                  ))}
                </ScrollView>
              </View>
            ) : (
              <View />
            )
          ) : (
            <View />
          )}
          {itemContent(
            allChildren.filter(
              (childrenId) =>
                childrenId.section === item.id && childrenId.type == "item"
            ),
            item
          )}
          {renderContent(
            allChildren.filter(
              (childrenId) =>
                childrenId.section === item.id && childrenId.type == "section"
            )
          )}
        </>
      )}

      {length == index + 1 && setSpecialNotes()!="" ? (
        <View
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              borderTopWidth: 2,
              borderColor: "#f5f5f5",
              width: "100%",
              marginBottom: 15,
            }}
          ></View>
          <Text
            style={{
              color: design.list_view_header_color,
              textAlign: "center",
              padding: 5,
              fontFamily: design.list_view_header_font_family
            }}
          >
            {setSpecialNotes()}
          </Text>


        </View>
      ) : (
        <View></View>
      )}
      {length == index + 1 && (
          <View style={{minHeight:100,width:"100%", backgroundColor:'transparent'}} >
          <View
            style={{
              borderTopWidth: 3,
              borderColor: "#f5f5f5",
              width: "100%",
              marginTop: 15,
              marginBottom:5
            }}
          ></View>

            <View style={{justifyContent:"center",alignItems:"center",padding:8}} >
              {
                !!config.logo_image&&
                <View style={{justifyContent:"center",alignItems:"center",width:75,height:75}}>
                  <Image
                      source={{uri : config.logo_image}}
                      resizeMode="contain"
                      style={{ width: "100%", height: "100%" }}
                  />
                </View>
              }


              </View>
              <View style={{justifyContent:"center",alignItems:"center",padding:8}} >
                <Text style={{fontFamily: design.list_view_header_font_family,fontWeight:"bold",textAlign:"center", color: design.list_view_header_color}}>{config.displayName}</Text>
                {
                  !!(config.address.address || config.address.city) &&
                  <Text  style={{fontFamily: design.list_view_header_font_family,textAlign:"center", color: design.list_view_header_color}}>{config.address.address} {!!config.address.address && !!config.address.city && ","} {config.address.city}</Text>
                }
                {
                  !!config.address.email && 
                  <Text  style={{fontFamily: design.list_view_header_font_family,textAlign:"center", color: design.list_view_header_color}}>{config.address.email}</Text>

                }
                {
                  !!config.taxId.taxDisplayName &&
                  <Text  style={{fontFamily: design.list_view_header_font_family,textAlign:"center", color: design.list_view_header_color}}>{config.taxId.taxDisplayName} : {config.taxId.taxId}</Text>

                }
                {
                  !!config.privacy_url &&
                  <Text  style={{fontFamily: design.list_view_header_font_family,textAlign:"center", color: design.list_view_header_color}} onPress={()=>window.open(config.privacy_url)}> <i class="fas fa-link"></i>{i18n.t("labels.privacy_policy")}</Text>

                }
              </View>
          </View>
      )}
    </View>
  );
};

export default ListItem;
