'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var SiprecList;
var SiprecPage;
var SiprecInstance;
var SiprecContext;

/* jshint ignore:start */
/**
 * Initialize the SiprecList
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.SiprecList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The SID of the Account that created this resource
 * @param {string} callSid - The SID of the Call the resource is associated with
 */
/* jshint ignore:end */
SiprecList = function SiprecList(version, accountSid, callSid) {
  /* jshint ignore:start */
  /**
   * @function siprec
   * @memberof Twilio.Api.V2010.AccountContext.CallContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.CallContext.SiprecContext}
   */
  /* jshint ignore:end */
  function SiprecListInstance(sid) {
    return SiprecListInstance.get(sid);
  }

  SiprecListInstance._version = version;
  // Path Solution
  SiprecListInstance._solution = {accountSid: accountSid, callSid: callSid};
  SiprecListInstance._uri = `/Accounts/${accountSid}/Calls/${callSid}/Siprec.json`;
  /* jshint ignore:start */
  /**
   * create a SiprecInstance
   *
   * @function create
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.name] - The name of this resource
   * @param {string} [opts.connectorName] -
   *          Unique name used when configuring the connector via Marketplace Add-on.
   * @param {siprec.track} [opts.track] -
   *          One of `inbound_track`, `outbound_track`, `both_tracks`.
   * @param {string} [opts.statusCallback] - Absolute URL of the status callback.
   * @param {string} [opts.statusCallbackMethod] -
   *          The http method for the status_callback.
   * @param {string} [opts.parameter1.name] - Parameter name
   * @param {string} [opts.parameter1.value] - Parameter value
   * @param {string} [opts.parameter2.name] - Parameter name
   * @param {string} [opts.parameter2.value] - Parameter value
   * @param {string} [opts.parameter3.name] - Parameter name
   * @param {string} [opts.parameter3.value] - Parameter value
   * @param {string} [opts.parameter4.name] - Parameter name
   * @param {string} [opts.parameter4.value] - Parameter value
   * @param {string} [opts.parameter5.name] - Parameter name
   * @param {string} [opts.parameter5.value] - Parameter value
   * @param {string} [opts.parameter6.name] - Parameter name
   * @param {string} [opts.parameter6.value] - Parameter value
   * @param {string} [opts.parameter7.name] - Parameter name
   * @param {string} [opts.parameter7.value] - Parameter value
   * @param {string} [opts.parameter8.name] - Parameter name
   * @param {string} [opts.parameter8.value] - Parameter value
   * @param {string} [opts.parameter9.name] - Parameter name
   * @param {string} [opts.parameter9.value] - Parameter value
   * @param {string} [opts.parameter10.name] - Parameter name
   * @param {string} [opts.parameter10.value] - Parameter value
   * @param {string} [opts.parameter11.name] - Parameter name
   * @param {string} [opts.parameter11.value] - Parameter value
   * @param {string} [opts.parameter12.name] - Parameter name
   * @param {string} [opts.parameter12.value] - Parameter value
   * @param {string} [opts.parameter13.name] - Parameter name
   * @param {string} [opts.parameter13.value] - Parameter value
   * @param {string} [opts.parameter14.name] - Parameter name
   * @param {string} [opts.parameter14.value] - Parameter value
   * @param {string} [opts.parameter15.name] - Parameter name
   * @param {string} [opts.parameter15.value] - Parameter value
   * @param {string} [opts.parameter16.name] - Parameter name
   * @param {string} [opts.parameter16.value] - Parameter value
   * @param {string} [opts.parameter17.name] - Parameter name
   * @param {string} [opts.parameter17.value] - Parameter value
   * @param {string} [opts.parameter18.name] - Parameter name
   * @param {string} [opts.parameter18.value] - Parameter value
   * @param {string} [opts.parameter19.name] - Parameter name
   * @param {string} [opts.parameter19.value] - Parameter value
   * @param {string} [opts.parameter20.name] - Parameter name
   * @param {string} [opts.parameter20.value] - Parameter value
   * @param {string} [opts.parameter21.name] - Parameter name
   * @param {string} [opts.parameter21.value] - Parameter value
   * @param {string} [opts.parameter22.name] - Parameter name
   * @param {string} [opts.parameter22.value] - Parameter value
   * @param {string} [opts.parameter23.name] - Parameter name
   * @param {string} [opts.parameter23.value] - Parameter value
   * @param {string} [opts.parameter24.name] - Parameter name
   * @param {string} [opts.parameter24.value] - Parameter value
   * @param {string} [opts.parameter25.name] - Parameter name
   * @param {string} [opts.parameter25.value] - Parameter value
   * @param {string} [opts.parameter26.name] - Parameter name
   * @param {string} [opts.parameter26.value] - Parameter value
   * @param {string} [opts.parameter27.name] - Parameter name
   * @param {string} [opts.parameter27.value] - Parameter value
   * @param {string} [opts.parameter28.name] - Parameter name
   * @param {string} [opts.parameter28.value] - Parameter value
   * @param {string} [opts.parameter29.name] - Parameter name
   * @param {string} [opts.parameter29.value] - Parameter value
   * @param {string} [opts.parameter30.name] - Parameter name
   * @param {string} [opts.parameter30.value] - Parameter value
   * @param {string} [opts.parameter31.name] - Parameter name
   * @param {string} [opts.parameter31.value] - Parameter value
   * @param {string} [opts.parameter32.name] - Parameter name
   * @param {string} [opts.parameter32.value] - Parameter value
   * @param {string} [opts.parameter33.name] - Parameter name
   * @param {string} [opts.parameter33.value] - Parameter value
   * @param {string} [opts.parameter34.name] - Parameter name
   * @param {string} [opts.parameter34.value] - Parameter value
   * @param {string} [opts.parameter35.name] - Parameter name
   * @param {string} [opts.parameter35.value] - Parameter value
   * @param {string} [opts.parameter36.name] - Parameter name
   * @param {string} [opts.parameter36.value] - Parameter value
   * @param {string} [opts.parameter37.name] - Parameter name
   * @param {string} [opts.parameter37.value] - Parameter value
   * @param {string} [opts.parameter38.name] - Parameter name
   * @param {string} [opts.parameter38.value] - Parameter value
   * @param {string} [opts.parameter39.name] - Parameter name
   * @param {string} [opts.parameter39.value] - Parameter value
   * @param {string} [opts.parameter40.name] - Parameter name
   * @param {string} [opts.parameter40.value] - Parameter value
   * @param {string} [opts.parameter41.name] - Parameter name
   * @param {string} [opts.parameter41.value] - Parameter value
   * @param {string} [opts.parameter42.name] - Parameter name
   * @param {string} [opts.parameter42.value] - Parameter value
   * @param {string} [opts.parameter43.name] - Parameter name
   * @param {string} [opts.parameter43.value] - Parameter value
   * @param {string} [opts.parameter44.name] - Parameter name
   * @param {string} [opts.parameter44.value] - Parameter value
   * @param {string} [opts.parameter45.name] - Parameter name
   * @param {string} [opts.parameter45.value] - Parameter value
   * @param {string} [opts.parameter46.name] - Parameter name
   * @param {string} [opts.parameter46.value] - Parameter value
   * @param {string} [opts.parameter47.name] - Parameter name
   * @param {string} [opts.parameter47.value] - Parameter value
   * @param {string} [opts.parameter48.name] - Parameter name
   * @param {string} [opts.parameter48.value] - Parameter value
   * @param {string} [opts.parameter49.name] - Parameter name
   * @param {string} [opts.parameter49.value] - Parameter value
   * @param {string} [opts.parameter50.name] - Parameter name
   * @param {string} [opts.parameter50.value] - Parameter value
   * @param {string} [opts.parameter51.name] - Parameter name
   * @param {string} [opts.parameter51.value] - Parameter value
   * @param {string} [opts.parameter52.name] - Parameter name
   * @param {string} [opts.parameter52.value] - Parameter value
   * @param {string} [opts.parameter53.name] - Parameter name
   * @param {string} [opts.parameter53.value] - Parameter value
   * @param {string} [opts.parameter54.name] - Parameter name
   * @param {string} [opts.parameter54.value] - Parameter value
   * @param {string} [opts.parameter55.name] - Parameter name
   * @param {string} [opts.parameter55.value] - Parameter value
   * @param {string} [opts.parameter56.name] - Parameter name
   * @param {string} [opts.parameter56.value] - Parameter value
   * @param {string} [opts.parameter57.name] - Parameter name
   * @param {string} [opts.parameter57.value] - Parameter value
   * @param {string} [opts.parameter58.name] - Parameter name
   * @param {string} [opts.parameter58.value] - Parameter value
   * @param {string} [opts.parameter59.name] - Parameter name
   * @param {string} [opts.parameter59.value] - Parameter value
   * @param {string} [opts.parameter60.name] - Parameter name
   * @param {string} [opts.parameter60.value] - Parameter value
   * @param {string} [opts.parameter61.name] - Parameter name
   * @param {string} [opts.parameter61.value] - Parameter value
   * @param {string} [opts.parameter62.name] - Parameter name
   * @param {string} [opts.parameter62.value] - Parameter value
   * @param {string} [opts.parameter63.name] - Parameter name
   * @param {string} [opts.parameter63.value] - Parameter value
   * @param {string} [opts.parameter64.name] - Parameter name
   * @param {string} [opts.parameter64.value] - Parameter value
   * @param {string} [opts.parameter65.name] - Parameter name
   * @param {string} [opts.parameter65.value] - Parameter value
   * @param {string} [opts.parameter66.name] - Parameter name
   * @param {string} [opts.parameter66.value] - Parameter value
   * @param {string} [opts.parameter67.name] - Parameter name
   * @param {string} [opts.parameter67.value] - Parameter value
   * @param {string} [opts.parameter68.name] - Parameter name
   * @param {string} [opts.parameter68.value] - Parameter value
   * @param {string} [opts.parameter69.name] - Parameter name
   * @param {string} [opts.parameter69.value] - Parameter value
   * @param {string} [opts.parameter70.name] - Parameter name
   * @param {string} [opts.parameter70.value] - Parameter value
   * @param {string} [opts.parameter71.name] - Parameter name
   * @param {string} [opts.parameter71.value] - Parameter value
   * @param {string} [opts.parameter72.name] - Parameter name
   * @param {string} [opts.parameter72.value] - Parameter value
   * @param {string} [opts.parameter73.name] - Parameter name
   * @param {string} [opts.parameter73.value] - Parameter value
   * @param {string} [opts.parameter74.name] - Parameter name
   * @param {string} [opts.parameter74.value] - Parameter value
   * @param {string} [opts.parameter75.name] - Parameter name
   * @param {string} [opts.parameter75.value] - Parameter value
   * @param {string} [opts.parameter76.name] - Parameter name
   * @param {string} [opts.parameter76.value] - Parameter value
   * @param {string} [opts.parameter77.name] - Parameter name
   * @param {string} [opts.parameter77.value] - Parameter value
   * @param {string} [opts.parameter78.name] - Parameter name
   * @param {string} [opts.parameter78.value] - Parameter value
   * @param {string} [opts.parameter79.name] - Parameter name
   * @param {string} [opts.parameter79.value] - Parameter value
   * @param {string} [opts.parameter80.name] - Parameter name
   * @param {string} [opts.parameter80.value] - Parameter value
   * @param {string} [opts.parameter81.name] - Parameter name
   * @param {string} [opts.parameter81.value] - Parameter value
   * @param {string} [opts.parameter82.name] - Parameter name
   * @param {string} [opts.parameter82.value] - Parameter value
   * @param {string} [opts.parameter83.name] - Parameter name
   * @param {string} [opts.parameter83.value] - Parameter value
   * @param {string} [opts.parameter84.name] - Parameter name
   * @param {string} [opts.parameter84.value] - Parameter value
   * @param {string} [opts.parameter85.name] - Parameter name
   * @param {string} [opts.parameter85.value] - Parameter value
   * @param {string} [opts.parameter86.name] - Parameter name
   * @param {string} [opts.parameter86.value] - Parameter value
   * @param {string} [opts.parameter87.name] - Parameter name
   * @param {string} [opts.parameter87.value] - Parameter value
   * @param {string} [opts.parameter88.name] - Parameter name
   * @param {string} [opts.parameter88.value] - Parameter value
   * @param {string} [opts.parameter89.name] - Parameter name
   * @param {string} [opts.parameter89.value] - Parameter value
   * @param {string} [opts.parameter90.name] - Parameter name
   * @param {string} [opts.parameter90.value] - Parameter value
   * @param {string} [opts.parameter91.name] - Parameter name
   * @param {string} [opts.parameter91.value] - Parameter value
   * @param {string} [opts.parameter92.name] - Parameter name
   * @param {string} [opts.parameter92.value] - Parameter value
   * @param {string} [opts.parameter93.name] - Parameter name
   * @param {string} [opts.parameter93.value] - Parameter value
   * @param {string} [opts.parameter94.name] - Parameter name
   * @param {string} [opts.parameter94.value] - Parameter value
   * @param {string} [opts.parameter95.name] - Parameter name
   * @param {string} [opts.parameter95.value] - Parameter value
   * @param {string} [opts.parameter96.name] - Parameter name
   * @param {string} [opts.parameter96.value] - Parameter value
   * @param {string} [opts.parameter97.name] - Parameter name
   * @param {string} [opts.parameter97.value] - Parameter value
   * @param {string} [opts.parameter98.name] - Parameter name
   * @param {string} [opts.parameter98.value] - Parameter value
   * @param {string} [opts.parameter99.name] - Parameter name
   * @param {string} [opts.parameter99.value] - Parameter value
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SiprecInstance
   */
  /* jshint ignore:end */
  SiprecListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Name': _.get(opts, 'name'),
      'ConnectorName': _.get(opts, 'connectorName'),
      'Track': _.get(opts, 'track'),
      'StatusCallback': _.get(opts, 'statusCallback'),
      'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod'),
      'Parameter1.Name': _.get(opts, 'parameter1.name'),
      'Parameter1.Value': _.get(opts, 'parameter1.value'),
      'Parameter2.Name': _.get(opts, 'parameter2.name'),
      'Parameter2.Value': _.get(opts, 'parameter2.value'),
      'Parameter3.Name': _.get(opts, 'parameter3.name'),
      'Parameter3.Value': _.get(opts, 'parameter3.value'),
      'Parameter4.Name': _.get(opts, 'parameter4.name'),
      'Parameter4.Value': _.get(opts, 'parameter4.value'),
      'Parameter5.Name': _.get(opts, 'parameter5.name'),
      'Parameter5.Value': _.get(opts, 'parameter5.value'),
      'Parameter6.Name': _.get(opts, 'parameter6.name'),
      'Parameter6.Value': _.get(opts, 'parameter6.value'),
      'Parameter7.Name': _.get(opts, 'parameter7.name'),
      'Parameter7.Value': _.get(opts, 'parameter7.value'),
      'Parameter8.Name': _.get(opts, 'parameter8.name'),
      'Parameter8.Value': _.get(opts, 'parameter8.value'),
      'Parameter9.Name': _.get(opts, 'parameter9.name'),
      'Parameter9.Value': _.get(opts, 'parameter9.value'),
      'Parameter10.Name': _.get(opts, 'parameter10.name'),
      'Parameter10.Value': _.get(opts, 'parameter10.value'),
      'Parameter11.Name': _.get(opts, 'parameter11.name'),
      'Parameter11.Value': _.get(opts, 'parameter11.value'),
      'Parameter12.Name': _.get(opts, 'parameter12.name'),
      'Parameter12.Value': _.get(opts, 'parameter12.value'),
      'Parameter13.Name': _.get(opts, 'parameter13.name'),
      'Parameter13.Value': _.get(opts, 'parameter13.value'),
      'Parameter14.Name': _.get(opts, 'parameter14.name'),
      'Parameter14.Value': _.get(opts, 'parameter14.value'),
      'Parameter15.Name': _.get(opts, 'parameter15.name'),
      'Parameter15.Value': _.get(opts, 'parameter15.value'),
      'Parameter16.Name': _.get(opts, 'parameter16.name'),
      'Parameter16.Value': _.get(opts, 'parameter16.value'),
      'Parameter17.Name': _.get(opts, 'parameter17.name'),
      'Parameter17.Value': _.get(opts, 'parameter17.value'),
      'Parameter18.Name': _.get(opts, 'parameter18.name'),
      'Parameter18.Value': _.get(opts, 'parameter18.value'),
      'Parameter19.Name': _.get(opts, 'parameter19.name'),
      'Parameter19.Value': _.get(opts, 'parameter19.value'),
      'Parameter20.Name': _.get(opts, 'parameter20.name'),
      'Parameter20.Value': _.get(opts, 'parameter20.value'),
      'Parameter21.Name': _.get(opts, 'parameter21.name'),
      'Parameter21.Value': _.get(opts, 'parameter21.value'),
      'Parameter22.Name': _.get(opts, 'parameter22.name'),
      'Parameter22.Value': _.get(opts, 'parameter22.value'),
      'Parameter23.Name': _.get(opts, 'parameter23.name'),
      'Parameter23.Value': _.get(opts, 'parameter23.value'),
      'Parameter24.Name': _.get(opts, 'parameter24.name'),
      'Parameter24.Value': _.get(opts, 'parameter24.value'),
      'Parameter25.Name': _.get(opts, 'parameter25.name'),
      'Parameter25.Value': _.get(opts, 'parameter25.value'),
      'Parameter26.Name': _.get(opts, 'parameter26.name'),
      'Parameter26.Value': _.get(opts, 'parameter26.value'),
      'Parameter27.Name': _.get(opts, 'parameter27.name'),
      'Parameter27.Value': _.get(opts, 'parameter27.value'),
      'Parameter28.Name': _.get(opts, 'parameter28.name'),
      'Parameter28.Value': _.get(opts, 'parameter28.value'),
      'Parameter29.Name': _.get(opts, 'parameter29.name'),
      'Parameter29.Value': _.get(opts, 'parameter29.value'),
      'Parameter30.Name': _.get(opts, 'parameter30.name'),
      'Parameter30.Value': _.get(opts, 'parameter30.value'),
      'Parameter31.Name': _.get(opts, 'parameter31.name'),
      'Parameter31.Value': _.get(opts, 'parameter31.value'),
      'Parameter32.Name': _.get(opts, 'parameter32.name'),
      'Parameter32.Value': _.get(opts, 'parameter32.value'),
      'Parameter33.Name': _.get(opts, 'parameter33.name'),
      'Parameter33.Value': _.get(opts, 'parameter33.value'),
      'Parameter34.Name': _.get(opts, 'parameter34.name'),
      'Parameter34.Value': _.get(opts, 'parameter34.value'),
      'Parameter35.Name': _.get(opts, 'parameter35.name'),
      'Parameter35.Value': _.get(opts, 'parameter35.value'),
      'Parameter36.Name': _.get(opts, 'parameter36.name'),
      'Parameter36.Value': _.get(opts, 'parameter36.value'),
      'Parameter37.Name': _.get(opts, 'parameter37.name'),
      'Parameter37.Value': _.get(opts, 'parameter37.value'),
      'Parameter38.Name': _.get(opts, 'parameter38.name'),
      'Parameter38.Value': _.get(opts, 'parameter38.value'),
      'Parameter39.Name': _.get(opts, 'parameter39.name'),
      'Parameter39.Value': _.get(opts, 'parameter39.value'),
      'Parameter40.Name': _.get(opts, 'parameter40.name'),
      'Parameter40.Value': _.get(opts, 'parameter40.value'),
      'Parameter41.Name': _.get(opts, 'parameter41.name'),
      'Parameter41.Value': _.get(opts, 'parameter41.value'),
      'Parameter42.Name': _.get(opts, 'parameter42.name'),
      'Parameter42.Value': _.get(opts, 'parameter42.value'),
      'Parameter43.Name': _.get(opts, 'parameter43.name'),
      'Parameter43.Value': _.get(opts, 'parameter43.value'),
      'Parameter44.Name': _.get(opts, 'parameter44.name'),
      'Parameter44.Value': _.get(opts, 'parameter44.value'),
      'Parameter45.Name': _.get(opts, 'parameter45.name'),
      'Parameter45.Value': _.get(opts, 'parameter45.value'),
      'Parameter46.Name': _.get(opts, 'parameter46.name'),
      'Parameter46.Value': _.get(opts, 'parameter46.value'),
      'Parameter47.Name': _.get(opts, 'parameter47.name'),
      'Parameter47.Value': _.get(opts, 'parameter47.value'),
      'Parameter48.Name': _.get(opts, 'parameter48.name'),
      'Parameter48.Value': _.get(opts, 'parameter48.value'),
      'Parameter49.Name': _.get(opts, 'parameter49.name'),
      'Parameter49.Value': _.get(opts, 'parameter49.value'),
      'Parameter50.Name': _.get(opts, 'parameter50.name'),
      'Parameter50.Value': _.get(opts, 'parameter50.value'),
      'Parameter51.Name': _.get(opts, 'parameter51.name'),
      'Parameter51.Value': _.get(opts, 'parameter51.value'),
      'Parameter52.Name': _.get(opts, 'parameter52.name'),
      'Parameter52.Value': _.get(opts, 'parameter52.value'),
      'Parameter53.Name': _.get(opts, 'parameter53.name'),
      'Parameter53.Value': _.get(opts, 'parameter53.value'),
      'Parameter54.Name': _.get(opts, 'parameter54.name'),
      'Parameter54.Value': _.get(opts, 'parameter54.value'),
      'Parameter55.Name': _.get(opts, 'parameter55.name'),
      'Parameter55.Value': _.get(opts, 'parameter55.value'),
      'Parameter56.Name': _.get(opts, 'parameter56.name'),
      'Parameter56.Value': _.get(opts, 'parameter56.value'),
      'Parameter57.Name': _.get(opts, 'parameter57.name'),
      'Parameter57.Value': _.get(opts, 'parameter57.value'),
      'Parameter58.Name': _.get(opts, 'parameter58.name'),
      'Parameter58.Value': _.get(opts, 'parameter58.value'),
      'Parameter59.Name': _.get(opts, 'parameter59.name'),
      'Parameter59.Value': _.get(opts, 'parameter59.value'),
      'Parameter60.Name': _.get(opts, 'parameter60.name'),
      'Parameter60.Value': _.get(opts, 'parameter60.value'),
      'Parameter61.Name': _.get(opts, 'parameter61.name'),
      'Parameter61.Value': _.get(opts, 'parameter61.value'),
      'Parameter62.Name': _.get(opts, 'parameter62.name'),
      'Parameter62.Value': _.get(opts, 'parameter62.value'),
      'Parameter63.Name': _.get(opts, 'parameter63.name'),
      'Parameter63.Value': _.get(opts, 'parameter63.value'),
      'Parameter64.Name': _.get(opts, 'parameter64.name'),
      'Parameter64.Value': _.get(opts, 'parameter64.value'),
      'Parameter65.Name': _.get(opts, 'parameter65.name'),
      'Parameter65.Value': _.get(opts, 'parameter65.value'),
      'Parameter66.Name': _.get(opts, 'parameter66.name'),
      'Parameter66.Value': _.get(opts, 'parameter66.value'),
      'Parameter67.Name': _.get(opts, 'parameter67.name'),
      'Parameter67.Value': _.get(opts, 'parameter67.value'),
      'Parameter68.Name': _.get(opts, 'parameter68.name'),
      'Parameter68.Value': _.get(opts, 'parameter68.value'),
      'Parameter69.Name': _.get(opts, 'parameter69.name'),
      'Parameter69.Value': _.get(opts, 'parameter69.value'),
      'Parameter70.Name': _.get(opts, 'parameter70.name'),
      'Parameter70.Value': _.get(opts, 'parameter70.value'),
      'Parameter71.Name': _.get(opts, 'parameter71.name'),
      'Parameter71.Value': _.get(opts, 'parameter71.value'),
      'Parameter72.Name': _.get(opts, 'parameter72.name'),
      'Parameter72.Value': _.get(opts, 'parameter72.value'),
      'Parameter73.Name': _.get(opts, 'parameter73.name'),
      'Parameter73.Value': _.get(opts, 'parameter73.value'),
      'Parameter74.Name': _.get(opts, 'parameter74.name'),
      'Parameter74.Value': _.get(opts, 'parameter74.value'),
      'Parameter75.Name': _.get(opts, 'parameter75.name'),
      'Parameter75.Value': _.get(opts, 'parameter75.value'),
      'Parameter76.Name': _.get(opts, 'parameter76.name'),
      'Parameter76.Value': _.get(opts, 'parameter76.value'),
      'Parameter77.Name': _.get(opts, 'parameter77.name'),
      'Parameter77.Value': _.get(opts, 'parameter77.value'),
      'Parameter78.Name': _.get(opts, 'parameter78.name'),
      'Parameter78.Value': _.get(opts, 'parameter78.value'),
      'Parameter79.Name': _.get(opts, 'parameter79.name'),
      'Parameter79.Value': _.get(opts, 'parameter79.value'),
      'Parameter80.Name': _.get(opts, 'parameter80.name'),
      'Parameter80.Value': _.get(opts, 'parameter80.value'),
      'Parameter81.Name': _.get(opts, 'parameter81.name'),
      'Parameter81.Value': _.get(opts, 'parameter81.value'),
      'Parameter82.Name': _.get(opts, 'parameter82.name'),
      'Parameter82.Value': _.get(opts, 'parameter82.value'),
      'Parameter83.Name': _.get(opts, 'parameter83.name'),
      'Parameter83.Value': _.get(opts, 'parameter83.value'),
      'Parameter84.Name': _.get(opts, 'parameter84.name'),
      'Parameter84.Value': _.get(opts, 'parameter84.value'),
      'Parameter85.Name': _.get(opts, 'parameter85.name'),
      'Parameter85.Value': _.get(opts, 'parameter85.value'),
      'Parameter86.Name': _.get(opts, 'parameter86.name'),
      'Parameter86.Value': _.get(opts, 'parameter86.value'),
      'Parameter87.Name': _.get(opts, 'parameter87.name'),
      'Parameter87.Value': _.get(opts, 'parameter87.value'),
      'Parameter88.Name': _.get(opts, 'parameter88.name'),
      'Parameter88.Value': _.get(opts, 'parameter88.value'),
      'Parameter89.Name': _.get(opts, 'parameter89.name'),
      'Parameter89.Value': _.get(opts, 'parameter89.value'),
      'Parameter90.Name': _.get(opts, 'parameter90.name'),
      'Parameter90.Value': _.get(opts, 'parameter90.value'),
      'Parameter91.Name': _.get(opts, 'parameter91.name'),
      'Parameter91.Value': _.get(opts, 'parameter91.value'),
      'Parameter92.Name': _.get(opts, 'parameter92.name'),
      'Parameter92.Value': _.get(opts, 'parameter92.value'),
      'Parameter93.Name': _.get(opts, 'parameter93.name'),
      'Parameter93.Value': _.get(opts, 'parameter93.value'),
      'Parameter94.Name': _.get(opts, 'parameter94.name'),
      'Parameter94.Value': _.get(opts, 'parameter94.value'),
      'Parameter95.Name': _.get(opts, 'parameter95.name'),
      'Parameter95.Value': _.get(opts, 'parameter95.value'),
      'Parameter96.Name': _.get(opts, 'parameter96.name'),
      'Parameter96.Value': _.get(opts, 'parameter96.value'),
      'Parameter97.Name': _.get(opts, 'parameter97.name'),
      'Parameter97.Value': _.get(opts, 'parameter97.value'),
      'Parameter98.Name': _.get(opts, 'parameter98.name'),
      'Parameter98.Value': _.get(opts, 'parameter98.value'),
      'Parameter99.Name': _.get(opts, 'parameter99.name'),
      'Parameter99.Value': _.get(opts, 'parameter99.value')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SiprecInstance(
        this._version,
        payload,
        this._solution.accountSid,
        this._solution.callSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a siprec
   *
   * @function get
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecList#
   *
   * @param {string} sid - The SID of the Siprec resource, or the `name`
   *
   * @returns {Twilio.Api.V2010.AccountContext.CallContext.SiprecContext}
   */
  /* jshint ignore:end */
  SiprecListInstance.get = function get(sid) {
    return new SiprecContext(this._version, this._solution.accountSid, this._solution.callSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SiprecListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SiprecListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SiprecListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SiprecPage
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.SiprecPage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SiprecSolution} solution - Path solution
 *
 * @returns SiprecPage
 */
/* jshint ignore:end */
SiprecPage = function SiprecPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SiprecPage.prototype, Page.prototype);
SiprecPage.prototype.constructor = SiprecPage;

/* jshint ignore:start */
/**
 * Build an instance of SiprecInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecPage#
 *
 * @param {SiprecPayload} payload - Payload response from the API
 *
 * @returns SiprecInstance
 */
/* jshint ignore:end */
SiprecPage.prototype.getInstance = function getInstance(payload) {
  return new SiprecInstance(
    this._version,
    payload,
    this._solution.accountSid,
    this._solution.callSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SiprecPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SiprecPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SiprecContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.SiprecInstance
 *
 * @property {string} sid - The SID of the Siprec resource.
 * @property {string} accountSid -
 *          The SID of the Account that created this resource
 * @property {string} callSid - The SID of the Call the resource is associated with
 * @property {string} name - The name of this resource
 * @property {siprec.status} status - The status - one of `stopped`, `in-progress`
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT that this resource was last updated
 * @property {string} uri -
 *          The URI of the resource, relative to `https://api.twilio.com`
 *
 * @param {V2010} version - Version of the resource
 * @param {SiprecPayload} payload - The instance payload
 * @param {sid} accountSid - The SID of the Account that created this resource
 * @param {sid} callSid - The SID of the Call the resource is associated with
 * @param {sid_like} sid - The SID of the Siprec resource, or the `name`
 */
/* jshint ignore:end */
SiprecInstance = function SiprecInstance(version, payload, accountSid, callSid,
                                          sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.callSid = payload.call_sid; // jshint ignore:line
  this.name = payload.name; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.dateUpdated = deserialize.rfc2822DateTime(payload.date_updated); // jshint ignore:line
  this.uri = payload.uri; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, callSid: callSid, sid: sid || this.sid, };
};

Object.defineProperty(SiprecInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SiprecContext(
          this._version,
          this._solution.accountSid,
          this._solution.callSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a SiprecInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecInstance#
 *
 * @param {object} opts - Options for request
 * @param {siprec.update_status} opts.status -
 *          The status. Must have the value `stopped`
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SiprecInstance
 */
/* jshint ignore:end */
SiprecInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SiprecInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SiprecInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SiprecContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.CallContext.SiprecContext
 *
 * @param {V2010} version - Version of the resource
 * @param {sid} accountSid - The SID of the Account that created this resource
 * @param {sid} callSid - The SID of the Call the resource is associated with
 * @param {sid_like} sid - The SID of the Siprec resource, or the `name`
 */
/* jshint ignore:end */
SiprecContext = function SiprecContext(version, accountSid, callSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {accountSid: accountSid, callSid: callSid, sid: sid, };
  this._uri = `/Accounts/${accountSid}/Calls/${callSid}/Siprec/${sid}.json`;
};

/* jshint ignore:start */
/**
 * update a SiprecInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecContext#
 *
 * @param {object} opts - Options for request
 * @param {siprec.update_status} opts.status -
 *          The status. Must have the value `stopped`
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SiprecInstance
 */
/* jshint ignore:end */
SiprecContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['status'])) {
    throw new Error('Required parameter "opts[\'status\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({'Status': _.get(opts, 'status')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SiprecInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.callSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.CallContext.SiprecContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SiprecContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SiprecContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SiprecList: SiprecList,
  SiprecPage: SiprecPage,
  SiprecInstance: SiprecInstance,
  SiprecContext: SiprecContext
};
