'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var SubscribeRulesList;
var SubscribeRulesPage;
var SubscribeRulesInstance;

/* jshint ignore:start */
/**
 * Initialize the SubscribeRulesList
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 * @param {string} roomSid - The SID of the Room resource for the Subscribe Rules
 * @param {string} participantSid -
 *          The SID of the Participant resource for the Subscribe Rules
 */
/* jshint ignore:end */
SubscribeRulesList = function SubscribeRulesList(version, roomSid,
                                                  participantSid) {
  /* jshint ignore:start */
  /**
   * @function subscribeRules
   * @memberof Twilio.Video.V1.RoomContext.ParticipantContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesContext}
   */
  /* jshint ignore:end */
  function SubscribeRulesListInstance(sid) {
    return SubscribeRulesListInstance.get(sid);
  }

  SubscribeRulesListInstance._version = version;
  // Path Solution
  SubscribeRulesListInstance._solution = {roomSid: roomSid, participantSid: participantSid};
  SubscribeRulesListInstance._uri = `/Rooms/${roomSid}/Participants/${participantSid}/SubscribeRules`;
  /* jshint ignore:start */
  /**
   * fetch a SubscribeRulesInstance
   *
   * @function fetch
   * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList#
   *
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SubscribeRulesInstance
   */
  /* jshint ignore:end */
  SubscribeRulesListInstance.fetch = function fetch(callback) {
    var deferred = Q.defer();
    var promise = this._version.fetch({uri: this._uri, method: 'GET'});

    promise = promise.then(function(payload) {
      deferred.resolve(new SubscribeRulesInstance(
        this._version,
        payload,
        this._solution.roomSid,
        this._solution.participantSid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * update a SubscribeRulesInstance
   *
   * @function update
   * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList#
   *
   * @param {object} [opts] - Options for request
   * @param {object} [opts.rules] - A JSON-encoded array of subscribe rules
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SubscribeRulesInstance
   */
  /* jshint ignore:end */
  SubscribeRulesListInstance.update = function update(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({'Rules': serialize.object(_.get(opts, 'rules'))});

    var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SubscribeRulesInstance(
        this._version,
        payload,
        this._solution.roomSid,
        this._solution.participantSid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SubscribeRulesListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SubscribeRulesListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return SubscribeRulesListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SubscribeRulesPage
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SubscribeRulesSolution} solution - Path solution
 *
 * @returns SubscribeRulesPage
 */
/* jshint ignore:end */
SubscribeRulesPage = function SubscribeRulesPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SubscribeRulesPage.prototype, Page.prototype);
SubscribeRulesPage.prototype.constructor = SubscribeRulesPage;

/* jshint ignore:start */
/**
 * Build an instance of SubscribeRulesInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesPage#
 *
 * @param {SubscribeRulesPayload} payload - Payload response from the API
 *
 * @returns SubscribeRulesInstance
 */
/* jshint ignore:end */
SubscribeRulesPage.prototype.getInstance = function getInstance(payload) {
  return new SubscribeRulesInstance(
    this._version,
    payload,
    this._solution.roomSid,
    this._solution.participantSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SubscribeRulesPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SubscribeRulesPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SubscribeRulesContext
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesInstance
 *
 * @property {string} participantSid -
 *          The SID of the Participant resource for the Subscribe Rules
 * @property {string} roomSid -
 *          The SID of the Room resource for the Subscribe Rules
 * @property {string} rules -
 *          A collection of Subscribe Rules that describe how to include or exclude matching tracks
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 *
 * @param {V1} version - Version of the resource
 * @param {SubscribeRulesPayload} payload - The instance payload
 * @param {sid} roomSid - The SID of the Room resource for the Subscribe Rules
 * @param {sid} participantSid -
 *          The SID of the Participant resource for the Subscribe Rules
 */
/* jshint ignore:end */
SubscribeRulesInstance = function SubscribeRulesInstance(version, payload,
                                                          roomSid,
                                                          participantSid) {
  this._version = version;

  // Marshaled Properties
  this.participantSid = payload.participant_sid; // jshint ignore:line
  this.roomSid = payload.room_sid; // jshint ignore:line
  this.rules = payload.rules; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {roomSid: roomSid, participantSid: participantSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SubscribeRulesInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SubscribeRulesInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SubscribeRulesList: SubscribeRulesList,
  SubscribeRulesPage: SubscribeRulesPage,
  SubscribeRulesInstance: SubscribeRulesInstance
};
