// @flow

export default () => {
  const bodyTheme = {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center'
  };

  return bodyTheme;
};
