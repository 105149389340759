import React from 'react'
import {View, Text} from 'react-native';
import { Badge } from "native-base";



const ShowMoreItem = (props) => {
    return(
       
        <Badge style={{
          alignSelf: "flex-start",
          backgroundColor: props.design.top_buttons_background_color,
          borderRadius: "3px",
          // minHeight: "18px",
          padding: 3,
          color: props.design.top_buttons_text_color,
          marginTop: 4,
          marginRight: "5px",
          height: "25px"
        }} ><View><Text style={{
          alignSelf: 'center',
          textAlign: 'center',
          fontSize: 12,
          color: props.design.top_buttons_text_color,
          marginTop: 0,
          marginBottom: "2px",
          fontFamily: "PrimaryFont",
  
        }}> {props.text }</Text></View></Badge>
     
    )
}

export default ShowMoreItem

     