import React, { useState,useEffect, useRef,  } from 'react';
import {View, TouchableOpacity, ActivityIndicator, Dimensions, Image, ScrollView} from "react-native"
import BackButton from './backButton'
import Constants from 'expo-constants';
const { width, height } = Dimensions.get("window")
import ProgressiveImage from "./progressiveImage";
import { API_URL, image_api } from "../constants/index";
import {Spinner} from "native-base"
// import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, Inject } from '@syncfusion/ej2-react-pdfviewer';
// let html =(data)=> {return(`<html>
// <body>
//     <object data=${data} type="application/pdf">
//         <embed src=${data} type="application/pdf" />
//     </object>
// </body>
// </html>`)}


const App = () => (
    <View style={{alignItems:'center', justifyContent:"center",flex:1}}>

      <ActivityIndicator size="large" color="#00ff00" />
    </View>
  );
  


const PdfViewer = (props) =>{
    const iframeRef = useRef(null);
    const intervalRef = useRef(null);

    const viewer = useRef(null);

    const [isLoading, setLoader] = useState(true)
    const [activeIndex, setActiveIndex] = useState(false)
    const [items, setItems] = useState([])


    // useEffect(()=>{
    //     // setLoader(true)
    //     // console.log(Constants,Constants.deviceName, "ssssssssssssssssssssssssssssssssss")
    //     // if(Constants.deviceName != 'Safari'){
    //     //     // window.open(props.menu.pdf_file)
    //     // }else{
    //     // let a = document.createElement("a");
    //     // document.body.appendChild(a);
    //     // a.style = "display: none";
    //     // a.href = props.menu.pdf_file;
    //     // a.download = "pdf menu";
    //     // a.click();
    //     // document.body.removeChild(a);

    //     // }

    //     intervalRef.current = setInterval(updateIframeSrc, 3000);


    // },[])
    // const updateIframeSrc = () => {
    //     iframeRef.current.src = getIframeLink();
    // }
    // const getIframeLink =() => {
    //     return `https://docs.google.com/gview?embedded=true&url=${props.menu.pdf_file}`;
    // }


    // useEffect(() => {
    //     WebViewer(
    //       {
    //         path: '/webviewer/lib',
    //         initialDoc: "props.menu.pdf_file",
    //       },
    //       viewer.current,
    //     ).then((instance) => {
    //     //   const { documentViewer, annotationManager, Annotations } = instance.Core;
    
       
    //     });
    //   }, []);

// if(isLoading){
//     return <App/>
// }

const removeIndex = (value) => {
    setActiveIndex(false)
    // console.log(value, "enddddddddd")
    // let _ = activeIndex
    // let selectedIndex = _.indexOf(value);
    // let x = _.splice(selectedIndex, 1);
    // setActiveIndex(_)

    // console.log(x,_, "enddddddd")

}
const addIndex = (value)=> {
    setActiveIndex(true)
    // console.log(value, "startttttttt")
    // let _ = activeIndex
    // _.push(value)
    // setActiveIndex(_)
    // console.log(_, "startttttttt")
}
// useEffect(()=>{
//     console.log(items, props.menu.pdf_images, "111111111111")

//     const fetchData = async () =>{
//         console.log(items, props.menu.pdf_images, "22222222")

//         const items = [];
//         for (const item of props.menu.pdf_images) {
//             console.log(item, "pppppppppppp")
//           const { uri } = item;
//           const [width, height] = await getImageSize(item);
//           console.log(width, height, "hereeeeeeeee")

//           items.push({
//             uri: item,
//             width,
//             height,
//           });
//         }
//         // return items
//         console.log(items, "3333333")
//         setItems(items)
    
//     }
//    fetchData().catch(console.error);;

//     },[])
const getImageSize = async uri => new Promise(resolve => {
    console.log("lllllllllllllll", uri)
    Image.getSize(uri, (width, height) => {
        console.log("aaaaaaa", width, height)

      resolve([width, height]);
    });
  })
  const imageWidth =() =>{
      if(width>999){
        return "100%"
      }else{
          return width
      }
  }
  return (
    <View style={{flex:1}}>
        {/* {
            isLoading && <App />
        } */}
        {/* <ScrollView style={{height:"100%"}} > */}
            <View style={{width:"100%",height:"100%"}} >
                {
                    props.menu.pdf_images.map((item, index) => {
                        
                        console.log(item, height, "itemmmmmmmm")

                            return(
                                <>
                                <View style={{width,height: height, marginTop:5, justifyContent:"center", alignItems:"center", display: activeIndex? "block":"none"}} >
                                    <Spinner color={props.color} />
                                </View>
                                <img width={imageWidth()} onLoadStart={()=> addIndex(index+1) } onLoadEnd={(e)=> {
                                    // console.log(e.nativeEvent,"ooooooo")
                                    removeIndex(index+1)
                                } } src={image_api + item
                                    .split("/")
                                    .splice(3)
                                    .join("/") + "?q=70"} />
                                {/* <Image onLoadStart={()=> addIndex(index+1) } onLoadEnd={(e)=> {
                                    // console.log(e.nativeEvent,"ooooooo")
                                    removeIndex(index+1)
                                } } style={{width:"100%",height: height , marginTop:5, resizeMode: "stretch",display: !activeIndex? "block":"none"}} source={{uri: 
                                image_api + item
                                    .split("/")
                                    .splice(3)
                                    .join("/") + "?q=70",}} /> */}
                                </>
                            )
                        }
                        

                    )}


                </View>

            {/* </ScrollView> */}
        {/* <div className="webviewer" ref={viewer}></div> */}
        {/* <embed src={props.menu.pdf_file} width= "500" height= "375"/> */}
        {/* <object data={props.menu.pdf_file} type="application/pdf" width="100%" height="100%">
            <p>Your web browser doesn't have a PDF plugin.
            Instead you can <a href="filename.pdf">click here to
            download the PDF file.</a></p>
            </object> */}
            {/* <object 
            ref={iframeRef}
            width={width} 
            height={height} 
            data={`https://docs.google.com/gview?embedded=true&url=${props.menu.pdf_file}`}
            onLoad={()=>{
                console.log("hereeeeeeeeeeeeeeeeeee")
                clearInterval(intervalRef.current);
                // console.log(window.document.getElementsByClassName('ndfHFb-c4YZDc-Wrql6b').remove())
                setLoader(false)}}
            ></object> */}
            



            {/* <iframe
                ref={iframeRef}
                style={isLoading?{display:"none"}:{}}
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${props.menu.pdf_file}#toolbar=0&scrollbar=0`}
                frameBorder="0"
                scrolling="auto"
                height="100%"
                width="100%"
                onLoad={()=>{
                    clearInterval(intervalRef.current);
                    // console.log(window.document.getElementsByClassName('ndfHFb-c4YZDc-Wrql6b').remove())
                    setLoader(false)}}
            ></iframe> */}

        {/* <TouchableOpacity style={{position:'absolute',top:7}} onPress={() => props.navigateBack()} >
            <BackButton
                border_color={`1px solid ${props.border_color}`}
                backgroundColor={props.backgroundColor}
                borderColor={props.borderColor}
                color={props.color}
                default_language={props.default_language}
                font_size = {props.font_size}

            />
        </TouchableOpacity> */}
    </View>

  );
}

export default PdfViewer