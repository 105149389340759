export const debug =
  window.location.host.startsWith("localhost") ||
  window.location.host.startsWith("qr-menu")
    ? true
    : false;

export const API_URL = window.location.host.startsWith("localhost")
? "https://backend.mydigimenu.com/" //"https://0470-65-0-227-196.ngrok.io/"
: window.location.host.startsWith("qr-menu") ||
  window.location.host.startsWith("order-menu")
? "https://backend-clone.mydigimenu.com/"
: 
window.location.host.startsWith("qr-qa") || window.location.host.startsWith("order-qa") ? 
"https://backend-qa.mydigimenu.com/"
:
"https://backend.mydigimenu.com/";
// export const API_URL = 'https://backend.mydigimenu.com/';

// export const TESTING_PLATFORM = "online";
export const TESTING_PLATFORM = "qr";

/*~~~~~~~~~~~~2CHECKOUT~~~~~~~~~~~~~~~*/
// liveeeeeeeee
export const MERCHANT_CODE = debug ? "901422057" : "250211546276";
export const PUBLISHABLE_KEY = debug
  ? "71AD9CCE-09AE-42D5-98A7-2D56199AA0AA"
  : "BB902762-9DCD-4141-812B-C0196EED9738";

// ------------STRIPE -------------------
export const STRIPE_PUB_KEY = 'pk_test_51J8pxOI7CMETPi83yRbjTKSJJaRiLXJgG9PLwWzamnMST5tQYd9MZzEhfNxk1FIfxdeThJuDWvwSQoKyvQyhbMjY00SSDRxI0q'

// checkout
// export const CHECKOUT_PUB_KEY = 'pk_test_5d1c7163-93c2-42aa-9a32-75b0c9ac28c1'
export const CHECKOUT_PUB_KEY = debug
  ? "pk_test_5c5084de-ff11-40c3-9863-09b4be856de0"
  : "pk_009661e6-1b1c-4158-bf20-3d4292481eb9";

// facebook clientid
export const FB_CLIENT = "412765560439768";
export const GOOGLECLIENTID =
  "396708838320-sn8l3maf1b0vg3fubecj60ujrano4jai.apps.googleusercontent.com";

export const APPLEID = "com.mydigimenu.apple"

export const REDIRECT_URL = window.location.origin;

// console.log(window.location.origin, "lllllll")

const status_codes = {
  1: "Table Not Found",
  2: "Table Deleted",
  3: "Error Configuration",
  4: "Package Expired",
  5: "Subscription Cancelled",
  6: "Unknown Error",
};

// currencies which need 3 fraction
export const THREE_FRACTION_CURRENCY = [
  "OMR",
  "KWD",
  "BHD",
  "CLF",
  "UYW",
  "IQD",
  "JOD",
  "LYD",
  "LAK",
  "RO"
];

//  export const image_api = "https://d2ceauryvxkzea.cloudfront.net/fit-in/300x300/"

// export const image_api = "https://my-menu.imgix.net/";
 export const image_api = "https://images.mydigimenu.com/"

// twillio private
// export const serviceID = 'VA220a1a5c06ad43ab2f2ea71d94458ae2';
// export const accountSid = 'AC501bd64999806d521a6708d21bdf60e7';
// export const authToken = 'db84870adf81f81e5eb32c9a870eb70b';

// twillio live
export const serviceID = "VA83e08e1a7579c9ed14a495f7ea140187";
export const accountSid = "ACb3a8bea58776b24476554f70d63e3a6a";
export const authToken = "8e8b2d0a01840fe4740aef75398119a3";


export const socket_url = debug ? "wss://f5wfaehn1e.execute-api.me-south-1.amazonaws.com/Prod" : "wss://e0kij7pda1.execute-api.me-south-1.amazonaws.com/Prod"

export const SPECIAL_VENUE = 'festive-takeaway'
// export const SPECIAL_VENUE = 'www'

// export const WHATSAPP_COUNTRIES = [
//   "IN", "AE", "SA", "BR", "ZA", "KE", "NG", "AR", "MY", "CO", "TR", "ES", "ID"
// ]
export const WHATSAPP_COUNTRIES = [
  "IN",
]

export const SQUARE_APP_ID = debug ? "sandbox-sq0idb-Mm4dw93eoIirkqRP-s7H-w" : ""
// export const SQUARE_URL = debug ? "https://js.squareupsandbox.com/v2/paymentform" :"https://js.squareup.com/v2/paymentform";
export const SQUARE_URL = debug ? "https://sandbox.web.squarecdn.com/v1/square.js" :"https://web.squarecdn.com/v1/square.js";

export const PAYTM_URL = debug ? "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/" : "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/";

export const DEFAULT_PRIMARY_FONT_URL = "./fonts/Lato-Regular.ttf";
export const DEFAULT_SECONDARY_FONT_URL = "./fonts/Lato-Regular.ttf";
export const ReCAPTCHA_KEY = "6LebzkclAAAAAJUu7Np0qvGPTd2U6Te39bDjRsPL"