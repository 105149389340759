import React from 'react'
import {View, Text,Dimensions} from 'react-native'
import { FontLetterSpacing } from './../handlers/utils'
// import {Button} from 'native-base'
import StyledText from "../components/styledText"
import {useSelector} from "react-redux"

const Toast = (props) => {
    const design = useSelector(state => state.config.design)

    return(
    <View style={{position:'absolute', height:100,width:'97%',top:Dimensions.get('window').height/2, backgroundColor:props.message_status,borderRadius:6,alignSelf:'center',alignItems:'center',justifyContent:'center', display:props.message_display}}>
        <StyledText 
        font_weight={design.popup_text_font_weight}
        font_style={design.popup_text_font_style}
        text_transform={design.popup_text_font_transform}
        font_family={design.popup_text_font_family}
        font_size={design.popup_text_font_size?? 20}

        style={{
            color:props.warn_text ? props.warn_text : 'white',
            
            }}>
            {props.error_message}
            </StyledText>
    </View>
)}
// const Toast = (props) => (
//     <View style={{position:'absolute', height:50,width:'100%',top:0, backgroundColor:props.message_status,borderRadius:0,alignSelf:'center',alignItems:'center',justifyContent:'center', display:props.message_display}}>
//         <Text style={{color:'white',fontSize:15}}>{props.error_message}</Text>
        
//     </View>
// )

export default Toast