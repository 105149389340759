import React from 'react'
import {View, Text, Image} from 'react-native';
import { style } from "../../assets/style";



const ItemCalorie = (props) => {
    return(
        <View style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "end",
            alignSelf: "center",
            marginBottom: 15,
            marginRight: 10
        }}>
            <Image
                source={require("../../assets/cal.png")}
                style={style.iconStyle}
            />

            <Text
                style={{
                    color: props.color,
                    marginRight: 10,
                    fontFamily: 'SecondaryFont',
                    fontSize: props.fontSize
                }}
            >
                {props.calorie} cal
            </Text>

        </View>
    )
}

export default ItemCalorie