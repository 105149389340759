import React from 'react'
import {View, Text} from 'react-native';
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';




const ModifierPrice = (props) => {
    return(
        <View
        style={{
            alignItems: "flex-end",
            justifyContent: 'center',
            padding:5,
            marginRight: 10
        }}
        >
        <StyledText
        font_color={props.design.item_price_text_color}
            font_weight={props.design.item_price_text_font_weight}
            font_style={props.design.item_price_text_font_style}
            text_transform={props.design.item_price_text_font_transform}
            font_family={props.design.item_price_text_font_family}
            font_size={props.design.item_price_text_font_size}
            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
            style={{}}
        >
            {props.children}
        </StyledText>
        </View>
    )
}

export default ModifierPrice