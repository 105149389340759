'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var BundleList = require('./regulatoryCompliance/bundle').BundleList;
var EndUserList = require('./regulatoryCompliance/endUser').EndUserList;
var EndUserTypeList = require(
    './regulatoryCompliance/endUserType').EndUserTypeList;
var RegulationList = require(
    './regulatoryCompliance/regulation').RegulationList;
var SupportingDocumentList = require(
    './regulatoryCompliance/supportingDocument').SupportingDocumentList;
var SupportingDocumentTypeList = require(
    './regulatoryCompliance/supportingDocumentType').SupportingDocumentTypeList;

var RegulatoryComplianceList;

/* jshint ignore:start */
/**
 * Initialize the RegulatoryComplianceList
 *
 * @constructor Twilio.Numbers.V2.RegulatoryComplianceList
 *
 * @param {Twilio.Numbers.V2} version - Version of the resource
 */
/* jshint ignore:end */
RegulatoryComplianceList = function RegulatoryComplianceList(version) {
  /* jshint ignore:start */
  /**
   * @function regulatoryCompliance
   * @memberof Twilio.Numbers.V2#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Numbers.V2.RegulatoryComplianceContext}
   */
  /* jshint ignore:end */
  function RegulatoryComplianceListInstance(sid) {
    return RegulatoryComplianceListInstance.get(sid);
  }

  RegulatoryComplianceListInstance._version = version;
  // Path Solution
  RegulatoryComplianceListInstance._solution = {};

  // Components
  RegulatoryComplianceListInstance._bundles = undefined;
  RegulatoryComplianceListInstance._endUsers = undefined;
  RegulatoryComplianceListInstance._endUserTypes = undefined;
  RegulatoryComplianceListInstance._regulations = undefined;
  RegulatoryComplianceListInstance._supportingDocuments = undefined;
  RegulatoryComplianceListInstance._supportingDocumentTypes = undefined;

  Object.defineProperty(RegulatoryComplianceListInstance,
    'bundles', {
      get: function bundles() {
        if (!this._bundles) {
          this._bundles = new BundleList(this._version);
        }

        return this._bundles;
      }
  });

  Object.defineProperty(RegulatoryComplianceListInstance,
    'endUsers', {
      get: function endUsers() {
        if (!this._endUsers) {
          this._endUsers = new EndUserList(this._version);
        }

        return this._endUsers;
      }
  });

  Object.defineProperty(RegulatoryComplianceListInstance,
    'endUserTypes', {
      get: function endUserTypes() {
        if (!this._endUserTypes) {
          this._endUserTypes = new EndUserTypeList(this._version);
        }

        return this._endUserTypes;
      }
  });

  Object.defineProperty(RegulatoryComplianceListInstance,
    'regulations', {
      get: function regulations() {
        if (!this._regulations) {
          this._regulations = new RegulationList(this._version);
        }

        return this._regulations;
      }
  });

  Object.defineProperty(RegulatoryComplianceListInstance,
    'supportingDocuments', {
      get: function supportingDocuments() {
        if (!this._supportingDocuments) {
          this._supportingDocuments = new SupportingDocumentList(this._version);
        }

        return this._supportingDocuments;
      }
  });

  Object.defineProperty(RegulatoryComplianceListInstance,
    'supportingDocumentTypes', {
      get: function supportingDocumentTypes() {
        if (!this._supportingDocumentTypes) {
          this._supportingDocumentTypes = new SupportingDocumentTypeList(this._version);
        }

        return this._supportingDocumentTypes;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Numbers.V2.RegulatoryComplianceList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  RegulatoryComplianceListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  RegulatoryComplianceListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return RegulatoryComplianceListInstance;
};

module.exports = {
  RegulatoryComplianceList: RegulatoryComplianceList
};
