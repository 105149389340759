import {
    SET_MESSAGE,
    ALLOW_ORDERING
} from "../actionTypes";




export const addMessage = (messages) => {
    return{
        type: SET_MESSAGE,
        messages,

    };
};


export const allowOrdering = (allow_ordering) => {
    return{
        type: ALLOW_ORDERING,
        allow_ordering,

    };
};
