'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var AnonymizeList = require(
    './roomParticipant/roomParticipantAnonymize').AnonymizeList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var PublishedTrackList = require(
    './roomParticipant/roomParticipantPublishedTrack').PublishedTrackList;
var SubscribeRulesList = require(
    './roomParticipant/roomParticipantSubscribeRule').SubscribeRulesList;
var SubscribedTrackList = require(
    './roomParticipant/roomParticipantSubscribedTrack').SubscribedTrackList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var ParticipantList;
var ParticipantPage;
var ParticipantInstance;
var ParticipantContext;

/* jshint ignore:start */
/**
 * Initialize the ParticipantList
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 * @param {string} roomSid - The SID of the participant's room
 */
/* jshint ignore:end */
ParticipantList = function ParticipantList(version, roomSid) {
  /* jshint ignore:start */
  /**
   * @function participants
   * @memberof Twilio.Video.V1.RoomContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.RoomContext.ParticipantContext}
   */
  /* jshint ignore:end */
  function ParticipantListInstance(sid) {
    return ParticipantListInstance.get(sid);
  }

  ParticipantListInstance._version = version;
  // Path Solution
  ParticipantListInstance._solution = {roomSid: roomSid};
  ParticipantListInstance._uri = `/Rooms/${roomSid}/Participants`;
  /* jshint ignore:start */
  /**
   * Streams ParticipantInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {participant.status} [opts.status] -
   *          Read only the participants with this status
   * @param {string} [opts.identity] -
   *          Read only the Participants with this user identity value
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Participants that started after this date in UTC ISO 8601 format
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Participants that started before this date in ISO 8601 format
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  ParticipantListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists ParticipantInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {participant.status} [opts.status] -
   *          Read only the participants with this status
   * @param {string} [opts.identity] -
   *          Read only the Participants with this user identity value
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Participants that started after this date in UTC ISO 8601 format
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Participants that started before this date in ISO 8601 format
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of ParticipantInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @param {object} [opts] - Options for request
   * @param {participant.status} [opts.status] -
   *          Read only the participants with this status
   * @param {string} [opts.identity] -
   *          Read only the Participants with this user identity value
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Participants that started after this date in UTC ISO 8601 format
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Participants that started before this date in ISO 8601 format
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Status': _.get(opts, 'status'),
      'Identity': _.get(opts, 'identity'),
      'DateCreatedAfter': serialize.iso8601DateTime(_.get(opts, 'dateCreatedAfter')),
      'DateCreatedBefore': serialize.iso8601DateTime(_.get(opts, 'dateCreatedBefore')),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ParticipantPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of ParticipantInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  ParticipantListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new ParticipantPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a participant
   *
   * @function get
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @param {string} sid - The SID that identifies the resource to fetch
   *
   * @returns {Twilio.Video.V1.RoomContext.ParticipantContext}
   */
  /* jshint ignore:end */
  ParticipantListInstance.get = function get(sid) {
    return new ParticipantContext(this._version, this._solution.roomSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.RoomContext.ParticipantList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ParticipantListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ParticipantListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return ParticipantListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantPage
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ParticipantSolution} solution - Path solution
 *
 * @returns ParticipantPage
 */
/* jshint ignore:end */
ParticipantPage = function ParticipantPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ParticipantPage.prototype, Page.prototype);
ParticipantPage.prototype.constructor = ParticipantPage;

/* jshint ignore:start */
/**
 * Build an instance of ParticipantInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.RoomContext.ParticipantPage#
 *
 * @param {ParticipantPayload} payload - Payload response from the API
 *
 * @returns ParticipantInstance
 */
/* jshint ignore:end */
ParticipantPage.prototype.getInstance = function getInstance(payload) {
  return new ParticipantInstance(this._version, payload, this._solution.roomSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.ParticipantPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ParticipantPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantContext
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} roomSid - The SID of the participant's room
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {participant.status} status - The status of the Participant
 * @property {string} identity - The string that identifies the resource's User
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {Date} startTime -
 *          The time of participant connected to the room in ISO 8601 format
 * @property {Date} endTime -
 *          The time when the participant disconnected from the room in ISO 8601 format
 * @property {number} duration -
 *          Duration of time in seconds the participant was connected
 * @property {string} url - The absolute URL of the resource
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {ParticipantPayload} payload - The instance payload
 * @param {sid} roomSid - The SID of the participant's room
 * @param {sid_like} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
ParticipantInstance = function ParticipantInstance(version, payload, roomSid,
                                                    sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.roomSid = payload.room_sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.identity = payload.identity; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.startTime = deserialize.iso8601DateTime(payload.start_time); // jshint ignore:line
  this.endTime = deserialize.iso8601DateTime(payload.end_time); // jshint ignore:line
  this.duration = deserialize.integer(payload.duration); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {roomSid: roomSid, sid: sid || this.sid, };
};

Object.defineProperty(ParticipantInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new ParticipantContext(this._version, this._solution.roomSid, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a ParticipantInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a ParticipantInstance
 *
 * @function update
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {participant.status} [opts.status] - The new status of the resource
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the publishedTracks
 *
 * @function publishedTracks
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @returns {Twilio.Video.V1.RoomContext.ParticipantContext.PublishedTrackList}
 */
/* jshint ignore:end */
ParticipantInstance.prototype.publishedTracks = function publishedTracks() {
  return this._proxy.publishedTracks;
};

/* jshint ignore:start */
/**
 * Access the subscribedTracks
 *
 * @function subscribedTracks
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @returns {Twilio.Video.V1.RoomContext.ParticipantContext.SubscribedTrackList}
 */
/* jshint ignore:end */
ParticipantInstance.prototype.subscribedTracks = function subscribedTracks() {
  return this._proxy.subscribedTracks;
};

/* jshint ignore:start */
/**
 * Access the subscribeRules
 *
 * @function subscribeRules
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @returns {Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList}
 */
/* jshint ignore:end */
ParticipantInstance.prototype.subscribeRules = function subscribeRules() {
  return this._proxy.subscribeRules;
};

/* jshint ignore:start */
/**
 * Access the anonymize
 *
 * @function anonymize
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @returns {Twilio.Video.V1.RoomContext.ParticipantContext.AnonymizeList}
 */
/* jshint ignore:end */
ParticipantInstance.prototype.anonymize = function anonymize() {
  return this._proxy.anonymize;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.ParticipantInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ParticipantInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ParticipantContext
 *
 * @constructor Twilio.Video.V1.RoomContext.ParticipantContext
 *
 * @property {Twilio.Video.V1.RoomContext.ParticipantContext.PublishedTrackList} publishedTracks -
 *          publishedTracks resource
 * @property {Twilio.Video.V1.RoomContext.ParticipantContext.SubscribedTrackList} subscribedTracks -
 *          subscribedTracks resource
 * @property {Twilio.Video.V1.RoomContext.ParticipantContext.SubscribeRulesList} subscribeRules -
 *          subscribeRules resource
 * @property {Twilio.Video.V1.RoomContext.ParticipantContext.AnonymizeList} anonymize -
 *          anonymize resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} roomSid -
 *          The SID of the room with the Participant resource to fetch
 * @param {sid_like} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
ParticipantContext = function ParticipantContext(version, roomSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {roomSid: roomSid, sid: sid, };
  this._uri = `/Rooms/${roomSid}/Participants/${sid}`;

  // Dependents
  this._publishedTracks = undefined;
  this._subscribedTracks = undefined;
  this._subscribeRules = undefined;
  this._anonymize = undefined;
};

/* jshint ignore:start */
/**
 * fetch a ParticipantInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new ParticipantInstance(
      this._version,
      payload,
      this._solution.roomSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a ParticipantInstance
 *
 * @function update
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext#
 *
 * @param {object} [opts] - Options for request
 * @param {participant.status} [opts.status] - The new status of the resource
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed ParticipantInstance
 */
/* jshint ignore:end */
ParticipantContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({'Status': _.get(opts, 'status')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new ParticipantInstance(
      this._version,
      payload,
      this._solution.roomSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(ParticipantContext.prototype,
  'publishedTracks', {
    get: function() {
      if (!this._publishedTracks) {
        this._publishedTracks = new PublishedTrackList(
          this._version,
          this._solution.roomSid,
          this._solution.sid
        );
      }
      return this._publishedTracks;
    }
});

Object.defineProperty(ParticipantContext.prototype,
  'subscribedTracks', {
    get: function() {
      if (!this._subscribedTracks) {
        this._subscribedTracks = new SubscribedTrackList(
          this._version,
          this._solution.roomSid,
          this._solution.sid
        );
      }
      return this._subscribedTracks;
    }
});

Object.defineProperty(ParticipantContext.prototype,
  'subscribeRules', {
    get: function() {
      if (!this._subscribeRules) {
        this._subscribeRules = new SubscribeRulesList(
          this._version,
          this._solution.roomSid,
          this._solution.sid
        );
      }
      return this._subscribeRules;
    }
});

Object.defineProperty(ParticipantContext.prototype,
  'anonymize', {
    get: function() {
      if (!this._anonymize) {
        this._anonymize = new AnonymizeList(this._version, this._solution.roomSid, this._solution.sid);
      }
      return this._anonymize;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.RoomContext.ParticipantContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
ParticipantContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

ParticipantContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ParticipantList: ParticipantList,
  ParticipantPage: ParticipantPage,
  ParticipantInstance: ParticipantInstance,
  ParticipantContext: ParticipantContext
};
