import {
    ADD_VERIFIED_NUMBER,
    ADD_USER,
    SET_TOKEN,
    BACK_TO_PROFILE
} from "../actionTypes";




export const addVerifiedNumber = (number) => {
    return{
        type: ADD_VERIFIED_NUMBER,
        number: number

    };
};


export const adduser = (user) => {
    return{
        type: ADD_USER,
        user: user

    };
};

export const addToken = (token) => {
    return{
        type: SET_TOKEN,
        token: token
    }
}

export const backToProfile = (is_profile_page) => {
    return{
        type: BACK_TO_PROFILE,
        is_profile_page
    }
}