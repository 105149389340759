import React from 'react';
import {View,Text} from 'react-native';
import {  Button } from 'native-base';
import TipButtons from './tipButton'


const Tips = (props)=> {

    return(
        <View style={{flexDirection:"row",justifyContent:"center", marginVertical:7, marginHorizontal:7}}>
            <TipButtons
                design={props.design}
                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'5 %'}
                calculatePercent={props.calculateFivePercent}
            />
            <TipButtons
                            design={props.design}

                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'10 %'}
                calculatePercent={props.calculateTenPercent}
            />
            <TipButtons
                            design={props.design}

                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'15 %'}
                calculatePercent={props.calculateFifteenPercent}
            />
            <TipButtons
                            design={props.design}

                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'20 %'}
                calculatePercent={props.calculateTwentyPercent}
            />
            <TipButtons
                            design={props.design}

                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'25 %'}
                calculatePercent={props.calculateTwentyFivePercent}
            />
            <TipButtons
                            design={props.design}

                top_buttons_background_color={props.top_buttons_background_color}
                tip_buttons_text_color={props.tip_buttons_text_color}
                percentage={'30 %'}
                calculatePercent={props.calculateThirtyPercent}
            />
    </View>
    )
}

export default Tips