'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var InteractionList = require('./session/interaction').InteractionList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var ParticipantList = require('./session/participant').ParticipantList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var SessionList;
var SessionPage;
var SessionInstance;
var SessionContext;

/* jshint ignore:start */
/**
 * Initialize the SessionList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceContext.SessionList
 *
 * @param {Twilio.Proxy.V1} version - Version of the resource
 * @param {string} serviceSid - The SID of the resource's parent Service
 */
/* jshint ignore:end */
SessionList = function SessionList(version, serviceSid) {
  /* jshint ignore:start */
  /**
   * @function sessions
   * @memberof Twilio.Proxy.V1.ServiceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Proxy.V1.ServiceContext.SessionContext}
   */
  /* jshint ignore:end */
  function SessionListInstance(sid) {
    return SessionListInstance.get(sid);
  }

  SessionListInstance._version = version;
  // Path Solution
  SessionListInstance._solution = {serviceSid: serviceSid};
  SessionListInstance._uri = `/Services/${serviceSid}/Sessions`;
  /* jshint ignore:start */
  /**
   * Streams SessionInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  SessionListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists SessionInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SessionListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of SessionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SessionListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SessionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of SessionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  SessionListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new SessionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a SessionInstance
   *
   * @function create
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.uniqueName] -
   *          An application-defined string that uniquely identifies the resource
   * @param {Date} [opts.dateExpiry] -
   *          The ISO 8601 date when the Session should expire
   * @param {number} [opts.ttl] - When the session will expire
   * @param {session.mode} [opts.mode] - The Mode of the Session
   * @param {session.status} [opts.status] - Session status
   * @param {object|list} [opts.participants] -
   *          The Participant objects to include in the new session
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SessionInstance
   */
  /* jshint ignore:end */
  SessionListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'UniqueName': _.get(opts, 'uniqueName'),
      'DateExpiry': serialize.iso8601DateTime(_.get(opts, 'dateExpiry')),
      'Ttl': _.get(opts, 'ttl'),
      'Mode': _.get(opts, 'mode'),
      'Status': _.get(opts, 'status'),
      'Participants': serialize.map(_.get(opts, 'participants'), function(e) { return serialize.object(e); })
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SessionInstance(
        this._version,
        payload,
        this._solution.serviceSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a session
   *
   * @function get
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Proxy.V1.ServiceContext.SessionContext}
   */
  /* jshint ignore:end */
  SessionListInstance.get = function get(sid) {
    return new SessionContext(this._version, this._solution.serviceSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Proxy.V1.ServiceContext.SessionList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SessionListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SessionListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SessionListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SessionPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceContext.SessionPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SessionSolution} solution - Path solution
 *
 * @returns SessionPage
 */
/* jshint ignore:end */
SessionPage = function SessionPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SessionPage.prototype, Page.prototype);
SessionPage.prototype.constructor = SessionPage;

/* jshint ignore:start */
/**
 * Build an instance of SessionInstance
 *
 * @function getInstance
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionPage#
 *
 * @param {SessionPayload} payload - Payload response from the API
 *
 * @returns SessionInstance
 */
/* jshint ignore:end */
SessionPage.prototype.getInstance = function getInstance(payload) {
  return new SessionInstance(this._version, payload, this._solution.serviceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SessionPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SessionPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SessionContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceContext.SessionInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} serviceSid - The SID of the resource's parent Service
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {Date} dateStarted - The ISO 8601 date when the Session started
 * @property {Date} dateEnded - The ISO 8601 date when the Session ended
 * @property {Date} dateLastInteraction -
 *          The ISO 8601 date when the Session last had an interaction
 * @property {Date} dateExpiry - The ISO 8601 date when the Session should expire
 * @property {string} uniqueName -
 *          An application-defined string that uniquely identifies the resource
 * @property {session.status} status - The status of the Session
 * @property {string} closedReason - The reason the Session ended
 * @property {number} ttl - When the session will expire
 * @property {session.mode} mode - The Mode of the Session
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} url - The absolute URL of the Session resource
 * @property {string} links - The URLs of resources related to the Session
 *
 * @param {V1} version - Version of the resource
 * @param {SessionPayload} payload - The instance payload
 * @param {sid} serviceSid - The SID of the resource's parent Service
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
SessionInstance = function SessionInstance(version, payload, serviceSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.serviceSid = payload.service_sid; // jshint ignore:line
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateStarted = deserialize.iso8601DateTime(payload.date_started); // jshint ignore:line
  this.dateEnded = deserialize.iso8601DateTime(payload.date_ended); // jshint ignore:line
  this.dateLastInteraction = deserialize.iso8601DateTime(payload.date_last_interaction); // jshint ignore:line
  this.dateExpiry = deserialize.iso8601DateTime(payload.date_expiry); // jshint ignore:line
  this.uniqueName = payload.unique_name; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.closedReason = payload.closed_reason; // jshint ignore:line
  this.ttl = deserialize.integer(payload.ttl); // jshint ignore:line
  this.mode = payload.mode; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {serviceSid: serviceSid, sid: sid || this.sid, };
};

Object.defineProperty(SessionInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SessionContext(this._version, this._solution.serviceSid, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a SessionInstance
 *
 * @function fetch
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a SessionInstance
 *
 * @function remove
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * update a SessionInstance
 *
 * @function update
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.dateExpiry] -
 *          The ISO 8601 date when the Session should expire
 * @param {number} [opts.ttl] - When the session will expire
 * @param {session.status} [opts.status] - The new status of the resource
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the interactions
 *
 * @function interactions
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @returns {Twilio.Proxy.V1.ServiceContext.SessionContext.InteractionList}
 */
/* jshint ignore:end */
SessionInstance.prototype.interactions = function interactions() {
  return this._proxy.interactions;
};

/* jshint ignore:start */
/**
 * Access the participants
 *
 * @function participants
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @returns {Twilio.Proxy.V1.ServiceContext.SessionContext.ParticipantList}
 */
/* jshint ignore:end */
SessionInstance.prototype.participants = function participants() {
  return this._proxy.participants;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SessionInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SessionInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SessionContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Proxy.V1.ServiceContext.SessionContext
 *
 * @property {Twilio.Proxy.V1.ServiceContext.SessionContext.InteractionList} interactions -
 *          interactions resource
 * @property {Twilio.Proxy.V1.ServiceContext.SessionContext.ParticipantList} participants -
 *          participants resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} serviceSid - The SID of the Service to fetch the resource from
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
SessionContext = function SessionContext(version, serviceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {serviceSid: serviceSid, sid: sid, };
  this._uri = `/Services/${serviceSid}/Sessions/${sid}`;

  // Dependents
  this._interactions = undefined;
  this._participants = undefined;
};

/* jshint ignore:start */
/**
 * fetch a SessionInstance
 *
 * @function fetch
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SessionInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a SessionInstance
 *
 * @function remove
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a SessionInstance
 *
 * @function update
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionContext#
 *
 * @param {object} [opts] - Options for request
 * @param {Date} [opts.dateExpiry] -
 *          The ISO 8601 date when the Session should expire
 * @param {number} [opts.ttl] - When the session will expire
 * @param {session.status} [opts.status] - The new status of the resource
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SessionInstance
 */
/* jshint ignore:end */
SessionContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'DateExpiry': serialize.iso8601DateTime(_.get(opts, 'dateExpiry')),
    'Ttl': _.get(opts, 'ttl'),
    'Status': _.get(opts, 'status')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new SessionInstance(
      this._version,
      payload,
      this._solution.serviceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(SessionContext.prototype,
  'interactions', {
    get: function() {
      if (!this._interactions) {
        this._interactions = new InteractionList(
          this._version,
          this._solution.serviceSid,
          this._solution.sid
        );
      }
      return this._interactions;
    }
});

Object.defineProperty(SessionContext.prototype,
  'participants', {
    get: function() {
      if (!this._participants) {
        this._participants = new ParticipantList(
          this._version,
          this._solution.serviceSid,
          this._solution.sid
        );
      }
      return this._participants;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Proxy.V1.ServiceContext.SessionContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SessionContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SessionContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SessionList: SessionList,
  SessionPage: SessionPage,
  SessionInstance: SessionInstance,
  SessionContext: SessionContext
};
