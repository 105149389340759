import React from "react";

import { createStackNavigator } from 'react-navigation-stack';

import { createAppContainer } from 'react-navigation';


import MenuHome from './../screens/menuHome'
import HomeScreen from './../screens/mainHome'
import CartScreen from "../screens/cartScreen";
import RecipeDetail from "../screens/recipeDetail";
import PaymentScreen from "../screens/paymentScreen";
import MobileForm from '../screens/MobileForm';
import OtpScreen from "../screens/otpScreen";
import selectDestinationScreen from "../screens/selectDestinationScreen";
import UserRegistrationScreen from "../screens/userRegistration"
import CheckoutScreen from "../screens/checkoutScreen"
import AddressFormScreen from '../screens/addAddress'
import RecentOrdersScreen from '../screens/recentOrdersScreen'
import IndexScreen from '../screens/indexScreen'
import ProfileScreen from '../screens/profileScreen'
import AccountScreen from '../screens/accountsHome'
import AddressScreen from '../screens/addressScreen'
import CardScreen from '../screens/savedCards'
import LikedScreen from '../screens/likedItems'

const HomeNavigator = createStackNavigator(
    {        

       
        IndexScreen:{
            screen: IndexScreen
        },
        
        selectDestinationScreen: {
            screen: selectDestinationScreen
        },

        HomeScreen:{
            screen: HomeScreen,
        },
        CartScreen:{
            screen: CartScreen
        },
        RecipeDetail:{
            screen: RecipeDetail
        },

        AddressFormScreen: {
            screen: AddressFormScreen
        },
        MobileForm: {
            screen: MobileForm
        },
        OtpScreen: {
            screen: OtpScreen,
        },
   
        MenuHome: {
            screen: MenuHome,
        },
// .....
        PaymentScreen: {
            screen: PaymentScreen
        },
        UserRegistrationScreen:{
            screen: UserRegistrationScreen
        },
        CheckoutScreen:{
            screen: CheckoutScreen
        },
        RecentOrdersScreen:{
            screen: RecentOrdersScreen
        },

        ProfileScreen:{
            screen: ProfileScreen
        },
        AccountScreen:{
            screen: AccountScreen
        }, 
        AddressScreen:{
            screen: AddressScreen
        },        
        CardScreen:{
            screen: CardScreen
        }, 
        LikedScreen:{
            screen: LikedScreen
        }, 
    },



);

export default createAppContainer(HomeNavigator);