'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var DialogueList;
var DialoguePage;
var DialogueInstance;
var DialogueContext;

/* jshint ignore:start */
/**
 * Initialize the DialogueList
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.DialogueList
 *
 * @param {Twilio.Autopilot.V1} version - Version of the resource
 * @param {string} assistantSid -
 *          The SID of the Assistant that is the parent of the resource
 */
/* jshint ignore:end */
DialogueList = function DialogueList(version, assistantSid) {
  /* jshint ignore:start */
  /**
   * @function dialogues
   * @memberof Twilio.Autopilot.V1.AssistantContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Autopilot.V1.AssistantContext.DialogueContext}
   */
  /* jshint ignore:end */
  function DialogueListInstance(sid) {
    return DialogueListInstance.get(sid);
  }

  DialogueListInstance._version = version;
  // Path Solution
  DialogueListInstance._solution = {assistantSid: assistantSid};
  /* jshint ignore:start */
  /**
   * Constructs a dialogue
   *
   * @function get
   * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Autopilot.V1.AssistantContext.DialogueContext}
   */
  /* jshint ignore:end */
  DialogueListInstance.get = function get(sid) {
    return new DialogueContext(this._version, this._solution.assistantSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  DialogueListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  DialogueListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return DialogueListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the DialoguePage
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.DialoguePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {DialogueSolution} solution - Path solution
 *
 * @returns DialoguePage
 */
/* jshint ignore:end */
DialoguePage = function DialoguePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(DialoguePage.prototype, Page.prototype);
DialoguePage.prototype.constructor = DialoguePage;

/* jshint ignore:start */
/**
 * Build an instance of DialogueInstance
 *
 * @function getInstance
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialoguePage#
 *
 * @param {DialoguePayload} payload - Payload response from the API
 *
 * @returns DialogueInstance
 */
/* jshint ignore:end */
DialoguePage.prototype.getInstance = function getInstance(payload) {
  return new DialogueInstance(this._version, payload, this._solution.assistantSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialoguePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
DialoguePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DialoguePage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DialogueContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.DialogueInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} assistantSid -
 *          The SID of the Assistant that is the parent of the resource
 * @property {string} sid - The unique string that identifies the resource
 * @property {object} data -
 *          The JSON string that describes the dialogue session object
 * @property {string} url - The absolute URL of the Dialogue resource
 *
 * @param {V1} version - Version of the resource
 * @param {DialoguePayload} payload - The instance payload
 * @param {sid} assistantSid -
 *          The SID of the Assistant that is the parent of the resource
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
DialogueInstance = function DialogueInstance(version, payload, assistantSid,
                                              sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.assistantSid = payload.assistant_sid; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.data = payload.data; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {assistantSid: assistantSid, sid: sid || this.sid, };
};

Object.defineProperty(DialogueInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new DialogueContext(this._version, this._solution.assistantSid, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a DialogueInstance
 *
 * @function fetch
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DialogueInstance
 */
/* jshint ignore:end */
DialogueInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
DialogueInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

DialogueInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the DialogueContext
 *
 * PLEASE NOTE that this class contains preview products that are subject to
 * change. Use them with caution. If you currently do not have developer preview
 * access, please contact help@twilio.com.
 *
 * @constructor Twilio.Autopilot.V1.AssistantContext.DialogueContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid_like} assistantSid -
 *          The SID of the Assistant that is the parent of the resource to fetch
 * @param {sid_like} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
DialogueContext = function DialogueContext(version, assistantSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {assistantSid: assistantSid, sid: sid, };
  this._uri = `/Assistants/${assistantSid}/Dialogues/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a DialogueInstance
 *
 * @function fetch
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed DialogueInstance
 */
/* jshint ignore:end */
DialogueContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new DialogueInstance(
      this._version,
      payload,
      this._solution.assistantSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Autopilot.V1.AssistantContext.DialogueContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
DialogueContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

DialogueContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  DialogueList: DialogueList,
  DialoguePage: DialoguePage,
  DialogueInstance: DialogueInstance,
  DialogueContext: DialogueContext
};
