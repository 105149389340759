import React from "react"
import { TouchableOpacity, View, Image } from 'react-native';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { style } from './../../assets/style'




export const GoogleButton = (props) => {
           const GoogleAuthHandler = useGoogleLogin({
            onSuccess: tokenResponse => {
                props.googleAuthentication(tokenResponse)
            },
            onError: error => console.log(error, "errorrr")
          });
          
    return(
        <TouchableOpacity onPress={() => GoogleAuthHandler()}>
            <View style={style.socialButtons}>
                <Image source={require('../../assets/google.png')} style={{ width: 23, height: 23 }} />
            </View>
        </TouchableOpacity>
    )
}
