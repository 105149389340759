import React, {Component} from 'react';
import {AsyncStorage, TouchableOpacity} from "react-native";
import {View, Text} from "react-native";
// import Icono from '@expo/vector-icons/Ionicons';
// import FA from '@expo/vector-icons/FontAwesome5'
import {style} from "../../assets/style";
import axios from 'axios'
import {API_URL} from './../constants/index'
// import {Icon} from 'native-base'
import Icon from './Warning'





class fontSize extends React.Component{

    state = {
        loading : false
    }



    FunctionToBell = async() => {
        this.setState({loading:true})
        const  uuid = await AsyncStorage.getItem('uuid');
        axios.get(API_URL+`api/waiter/messages/${uuid}/`)
        .then(response => {
            console.log(response.data)
            this.setState({loading:false})
            this.props.FunctionToBell(response.data)
        })
        .catch(error => {
            this.setState({loading:false})
        })
        
        
        // axios.get(API_URL+'api/call-waiter/'+uuid)
        //     .then(response => {
        //         // console.log(response.data)
        //         this.props.throwMessageFn()
        //         this.setState({loading:false})
        //     })
        //     .catch(error => {
        //         this.setState({loading:false})
        //         // console.log(error, 'errrrrrrrrrrrrr')
        //     })
    };



    render(){
        return(
            <TouchableOpacity onPress={this.FunctionToBell} activeOpacity={1}>
                <View style={[style.fontButton,{backgroundColor:this.props.backgroundColor,border:this.props.border_color}]}>
                    {/* <FA name="concierge-bell" style={{fontSize: 30, color: this.props.color}}/> */}
                    {/*<Text style={{fontSize: 30, color: props.color}}>{props.default_language}</Text>*/}
                    {/* <Icon name="bell2" style={{fontSize: 30, color: this.props.color}}/> */}
                    {
                        this.state.loading ? 
                        <i style={{color: this.props.color, fontSize:25}} class="fas fa-spinner fa-spin"></i>
                        :
                        <i style={{color: this.props.color, fontSize:25}} class="fas fa-concierge-bell"></i>
                    }
                    
                    
                </View>
            </TouchableOpacity>
        );
    }
};
export default fontSize;


