'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var UsecaseList;
var UsecasePage;
var UsecaseInstance;

/* jshint ignore:start */
/**
 * Initialize the UsecaseList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.UsecaseList
 *
 * @param {Twilio.Messaging.V1} version - Version of the resource
 */
/* jshint ignore:end */
UsecaseList = function UsecaseList(version) {
  /* jshint ignore:start */
  /**
   * @function usecases
   * @memberof Twilio.Messaging.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Messaging.V1.UsecaseContext}
   */
  /* jshint ignore:end */
  function UsecaseListInstance(sid) {
    return UsecaseListInstance.get(sid);
  }

  UsecaseListInstance._version = version;
  // Path Solution
  UsecaseListInstance._solution = {};
  UsecaseListInstance._uri = `/Services/Usecases`;
  /* jshint ignore:start */
  /**
   * fetch a UsecaseInstance
   *
   * @function fetch
   * @memberof Twilio.Messaging.V1.UsecaseList#
   *
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed UsecaseInstance
   */
  /* jshint ignore:end */
  UsecaseListInstance.fetch = function fetch(callback) {
    var deferred = Q.defer();
    var promise = this._version.fetch({uri: this._uri, method: 'GET'});

    promise = promise.then(function(payload) {
      deferred.resolve(new UsecaseInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Messaging.V1.UsecaseList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  UsecaseListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  UsecaseListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return UsecaseListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the UsecasePage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.UsecasePage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {UsecaseSolution} solution - Path solution
 *
 * @returns UsecasePage
 */
/* jshint ignore:end */
UsecasePage = function UsecasePage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(UsecasePage.prototype, Page.prototype);
UsecasePage.prototype.constructor = UsecasePage;

/* jshint ignore:start */
/**
 * Build an instance of UsecaseInstance
 *
 * @function getInstance
 * @memberof Twilio.Messaging.V1.UsecasePage#
 *
 * @param {UsecasePayload} payload - Payload response from the API
 *
 * @returns UsecaseInstance
 */
/* jshint ignore:end */
UsecasePage.prototype.getInstance = function getInstance(payload) {
  return new UsecaseInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.UsecasePage#
 *
 * @returns Object
 */
/* jshint ignore:end */
UsecasePage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UsecasePage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the UsecaseContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Messaging.V1.UsecaseInstance
 *
 * @property {object} usecases - Human readable Messaging Service Use Case details
 *
 * @param {V1} version - Version of the resource
 * @param {UsecasePayload} payload - The instance payload
 */
/* jshint ignore:end */
UsecaseInstance = function UsecaseInstance(version, payload) {
  this._version = version;

  // Marshaled Properties
  this.usecases = payload.usecases; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Messaging.V1.UsecaseInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
UsecaseInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

UsecaseInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  UsecaseList: UsecaseList,
  UsecasePage: UsecasePage,
  UsecaseInstance: UsecaseInstance
};
