
import {THREE_FRACTION_CURRENCY} from './../constants'



export const percentageHandler =  {
   priceLoader(price, currency, display_fraction){
     if(display_fraction){
        if(THREE_FRACTION_CURRENCY.includes(currency)){
            return price.toFixed(3)
        }else{
            return price.toFixed(2)
        }
     }else{
      return parseInt(price)
     }
      
  },
  
  getPercentage(percentage, amount, currency, display_fraction) {
    const percentResult = this.priceLoader((percentage/ 100) * amount, currency, display_fraction);
        return percentResult
  },

  getTotal(promo, amount, delivery_charge, menu_type, currency){
    let percentResult = 0
    if (promo.promo_type == "1"){
       percentResult = this.priceLoader((promo.percentage_discount/ 100) * amount, currency);
    }else{
      percentResult = parseFloat(promo.percentage_discount)
    }
    let result = amount - percentResult
    if(delivery_charge && menu_type == 'delivery'){
      result = result + parseFloat(delivery_charge)
    }
    return this.priceLoader(parseFloat(result), currency)
  }
}

