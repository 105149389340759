'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var ValidationRequestList;
var ValidationRequestPage;
var ValidationRequestInstance;

/* jshint ignore:start */
/**
 * Initialize the ValidationRequestList
 *
 * @constructor Twilio.Api.V2010.AccountContext.ValidationRequestList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The SID of the Account that created the resource
 */
/* jshint ignore:end */
ValidationRequestList = function ValidationRequestList(version, accountSid) {
  /* jshint ignore:start */
  /**
   * @function validationRequests
   * @memberof Twilio.Api.V2010.AccountContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.ValidationRequestContext}
   */
  /* jshint ignore:end */
  function ValidationRequestListInstance(sid) {
    return ValidationRequestListInstance.get(sid);
  }

  ValidationRequestListInstance._version = version;
  // Path Solution
  ValidationRequestListInstance._solution = {accountSid: accountSid};
  ValidationRequestListInstance._uri = `/Accounts/${accountSid}/OutgoingCallerIds.json`;
  /* jshint ignore:start */
  /**
   * create a ValidationRequestInstance
   *
   * @function create
   * @memberof Twilio.Api.V2010.AccountContext.ValidationRequestList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.phoneNumber - The phone number to verify in E.164 format
   * @param {string} [opts.friendlyName] - A string to describe the resource
   * @param {number} [opts.callDelay] -
   *          The number of seconds to delay before initiating the verification call
   * @param {string} [opts.extension] -
   *          The digits to dial after connecting the verification call
   * @param {string} [opts.statusCallback] -
   *          The URL we should call to send status information to your application
   * @param {string} [opts.statusCallbackMethod] -
   *          The HTTP method we should use to call status_callback
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed ValidationRequestInstance
   */
  /* jshint ignore:end */
  ValidationRequestListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['phoneNumber'])) {
      throw new Error('Required parameter "opts[\'phoneNumber\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'PhoneNumber': _.get(opts, 'phoneNumber'),
      'FriendlyName': _.get(opts, 'friendlyName'),
      'CallDelay': _.get(opts, 'callDelay'),
      'Extension': _.get(opts, 'extension'),
      'StatusCallback': _.get(opts, 'statusCallback'),
      'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new ValidationRequestInstance(this._version, payload));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.ValidationRequestList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  ValidationRequestListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  ValidationRequestListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return ValidationRequestListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the ValidationRequestPage
 *
 * @constructor Twilio.Api.V2010.AccountContext.ValidationRequestPage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {ValidationRequestSolution} solution - Path solution
 *
 * @returns ValidationRequestPage
 */
/* jshint ignore:end */
ValidationRequestPage = function ValidationRequestPage(version, response,
                                                        solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(ValidationRequestPage.prototype, Page.prototype);
ValidationRequestPage.prototype.constructor = ValidationRequestPage;

/* jshint ignore:start */
/**
 * Build an instance of ValidationRequestInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.ValidationRequestPage#
 *
 * @param {ValidationRequestPayload} payload - Payload response from the API
 *
 * @returns ValidationRequestInstance
 */
/* jshint ignore:end */
ValidationRequestPage.prototype.getInstance = function getInstance(payload) {
  return new ValidationRequestInstance(this._version, payload, this._solution.accountSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.ValidationRequestPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
ValidationRequestPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ValidationRequestPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the ValidationRequestContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.ValidationRequestInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} callSid - The SID of the Call the resource is associated with
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} phoneNumber - The phone number to verify in E.164 format
 * @property {string} validationCode -
 *          The 6 digit validation code that someone must enter to validate the Caller ID  when `phone_number` is called
 *
 * @param {V2010} version - Version of the resource
 * @param {ValidationRequestPayload} payload - The instance payload
 * @param {sid} accountSid - The SID of the Account that created the resource
 */
/* jshint ignore:end */
ValidationRequestInstance = function ValidationRequestInstance(version, payload,
    accountSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.callSid = payload.call_sid; // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.phoneNumber = payload.phone_number; // jshint ignore:line
  this.validationCode = payload.validation_code; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, };
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.ValidationRequestInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
ValidationRequestInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

ValidationRequestInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  ValidationRequestList: ValidationRequestList,
  ValidationRequestPage: ValidationRequestPage,
  ValidationRequestInstance: ValidationRequestInstance
};
