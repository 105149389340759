import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  SafeAreaView,
  Dimensions,
  ScrollView,
  AsyncStorage,
  TextInput,
  CheckBox,
  ImageBackground,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  TouchableHighlight,
  TouchableOpacity,
  // PanResponder
} from "react-native";
import Constants from 'expo-constants';


// import {Root,FooterTab,Footer} from 'native-base'
// import Icono from '@expo/vector-icons/Ionicons';
import { API_URL, image_api } from "./../constants/index";
import axios from "axios";
import { connect } from "react-redux";
import {
  addChildren,
  resetCart,
  addDefaultLanguage,
  selectMenu,
  isLoading,
  selectMenuData,
  feedbackModal
} from "../store/actions";

import Modal from "modal-react-native-web";
import Bell from "./../components/fontSize";
import DefaultLanguage from "./../components/DefaultLanguage";
import { style } from "../../assets/style";
import LanguageContainer from "./../components/languageContainer";
import Toast from "./../components/toast";
import StarRating from "react-native-star-rating";
import StarRatings from "react-star-ratings";

import ProfileButton from "./../components/profileButton";

import HTMLView from "react-native-htmlview";
import ProgressiveImage from "./../components/progressiveImage";
import Icon from "./../components/Warning";
import CampaignModal from "../components/campaignModal";
import { NavigationActions } from "react-navigation";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import OrderSuccessModal from "./../components/orderSuccessModal";

const { height, width } = Dimensions.get("window");
import { PushData } from "./../constants/tagViewpush";
import i18n from "../i18n";
import { Ionicons } from "@expo/vector-icons";
import FeedbackButton from "./../components/FeedbackButton";
import IntlLabel from "./../handlers/languageHandler";
import IntlLabelFn from "./../handlers/languageHandlerFn";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from './../handlers/utils';
import { performRequest } from './../services/apiHandler';

class MenuHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      loaderModal: false,
      defaultLangModal: false,
      error_message: "",
      message_status: "",
      message_display: "none",
      FeedbackModal: false,
      feedback_q_a: [],
      infoModal: false,
      page: 1,
      uuid: "",
      isLandingPageActive: true,
      order_response: "",
      orderSuccessModal: false,
      selectedCampaign: "",
      campaignModal: false,
      bellModal: false,
      waiterMessages: [],
      warn_text: "white",
      selectedMessage: "",
      versionVisibility: "none"
      // campaignModal: false
    };
  }
  setScreenActive = () => {
    this.setState({
      isLandingPageActive: true,
    });
  };
  orderScreen = () => {
    this.setState(
      {
        orderSuccessModal: false,
        order_response: "",
      },
      () =>
        this.props.navigation.navigate("RecentOrdersScreen", {
          returnAccount: this.returnAccount,
        })
    );
  };
  closeSuccessModal = () => {
    this.setState({
      orderSuccessModal: false,
      order_response: "",
    });
  };
  returnAccount = () => {
    this.props.navigation.navigate("MenuHome");
  };

  componentDidMount() {
    // this.props.resetCart();

    document.title = this.props.config.displayName + " | My Menu Mobile";
    let venue_formatted = this.props.config.venueName
      .replace(/\s+/g, "-")
      .toLowerCase();
    AsyncStorage.getItem("order_status", async (err, status) => {
      if (!err) {
        if (status == "success") {
          await this.throwMessageFn(
            "Order Placed",
            this.props.design.home_button_background_color
          );
          let order_response = JSON.parse(
            await AsyncStorage.getItem("order_response")
          );
          this.setState({
            order_response,
            orderSuccessModal: true,
          });
        } else if (status == "error") {
          this.throwMessageFn("Error placing order", "red");
        }
        await AsyncStorage.removeItem("order_status");
      }
    });

    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        // if(!window.location.host.startsWith('localhost') && !window.location.host.startsWith('qr-menu')){
        //     if(this.props.config.general_qr.qr_id == uuid){
        //         window.history.pushState("object or string", "Title", "/gr/"+venue_formatted);
        //     }else{
        //         window.history.pushState("object or string", "Title", "/tr/"+venue_formatted);
        //     }
        // }

        this.setState({ uuid: uuid });
        let feedback_q_a = [];
        if (this.props.config.survey.question_survey) {
          Promise.all(
            this.props.config.survey.question_survey.map((item, index) => {
              item["display_warning"] = "none";
              feedback_q_a.push(item);
            })
          ).then(() => {
            this.setState({ feedback_q_a: feedback_q_a });
          });
        }
        PushData(
          this.props.navigation.state.routeName,
          this.props.config.venueName ? this.props.config.venueName : "Ent",
          uuid ? uuid : "Ent",
          {}
        );
      }
    });
  }

  throwMessageFn = (message, status, warn_text = "white") => {
    this.setState({
      message_status: status,
      error_message: message,
      message_display: "inherit",
      warn_text
    });

    setTimeout(
      function () {
        this.setState({
          message_status: "",
          error_message: "",
          message_display: "none",
          warn_text: "white"
        });
      }.bind(this),
      3000
    );
  };
  sendWaiterMessage = async (message) => {
    const uuid = await AsyncStorage.getItem('uuid');
    axios.get(API_URL + `api/call-waiter/${uuid}?message=${message}&platform=${this.props.platform}`)
      .then(response => {
        this.throwMessageFn(
          "A call has been send to waiter ",
          this.props.design.popup_background_color,
          this.props.design.popup_text_color
        )
        this.setState({ bellModal: false, selectedMessage: "" })
      })
      .catch(error => {
        this.throwMessageFn(
          "Error Sending Message",
          this.props.design.popup_background_color,
          this.props.design.popup_text_color

        )
      })
  }
  _renderBellContent = () => (
    <TouchableOpacity
      style={{
        flex: 1, alignItems: "center", justifyContent: "center",
        // backdropFilter: "blur(5px)" 
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
      onPressOut={() => {
        this.setState({ bellModal: false });
      }}
    >
      <View
        style={{
          width: Dimensions.get("window").width * 0.6,
          minHeight: "20%",
          backgroundColor: this.props.design.popup_background_color,
          paddingTop: 22,
          paddingHorizontal: 22,
          // justifyContent: "center",
          // alignItems: "center",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.1)",


        }}
      >
        <StyledText
          font_color={this.props.design.popup_heading_text_color}
          font_weight={this.props.design.popup_heading_font_weight}
          font_style={this.props.design.popup_heading_font_style}
          text_transform={this.props.design.popup_heading_font_transform}
          font_family={this.props.design.popup_heading_font_family}
          font_size={this.props.design.popup_heading_font_size}
          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
          style={{ alignSelf: 'center' }}

        // style={{color: this.props.design.popup_text_color,fontSize: this.props.design.custom_font.sub_heading_size ?? 16,alignSelf:"center", fontFamily: 'SecondaryFont'}} 
        >
          {i18n.t("labels.waiter_message")}
        </StyledText>
        <View style={{ marginVertical: 8, flexDirection: "row", flexWrap: "wrap" }}>
          {
            this.state.waiterMessages.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    borderBottomColor: "black",
                    borderBottomWidth: 1,
                    padding: 10,
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    // key={index}
                    // onPress={() => this.getAllChildrens(item)}
                    onPress={() => this.setState({ selectedMessage: item.message[this.props.default_language] })}
                  >
                    <View style={{ backgroundColor: item.message[this.props.default_language] == this.state.selectedMessage ? this.props.design.popup_button_color : "transparent" }} >
                      <StyledText
                        font_color={item.message[this.props.default_language] == this.state.selectedMessage ? this.props.design.popup_button_text_color : this.props.design.popup_text_color}
                        font_weight={this.props.design.popup_text_font_weight}
                        font_style={this.props.design.popup_text_font_style}
                        text_transform={this.props.design.popup_text_font_transform}
                        font_family={this.props.design.popup_text_font_family}
                        font_size={this.props.design.popup_text_font_size ?? 16}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{ textAlign: "center" }}
                      >
                        <IntlLabel data={item.message} />
                      </StyledText>
                    </View>
                  </TouchableOpacity>
                </View>

              )
            })
          }
          <View style={{ marginVertical: 15, justifyContent: "center", alignItems: "center", width: "100%" }} >
            <TouchableOpacity onPress={() => this.sendWaiterMessage(this.state.selectedMessage)} >
              <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: this.props.design.popup_button_color, borderRadius: 5, height: 40, padding: 6, marginRight: 3, marginBottom: 3, alignSelf: "center" }} >

                <StyledText
                  font_color={this.props.design.popup_button_text_color}
                  font_weight={this.props.design.popup_button_font_weight}
                  font_style={this.props.design.popup_button_font_style}
                  text_transform={this.props.design.popup_button_font_transform}
                  font_family={this.props.design.popup_button_font_family}
                  font_size={this.props.design.popup_button_font_size}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                >
                  {i18n.t("labels.send_message")}
                </StyledText>
              </View>
            </TouchableOpacity>
          </View>

        </View>


      </View>
    </TouchableOpacity>
  );

  _renderLoaderContent = () => (
    <TouchableOpacity
      style={{
        flex: 1, alignItems: "center", justifyContent: "center",
        // backdropFilter: "blur(5px)" 
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }}
    >
      <View
        style={{
          width: Dimensions.get("window").width * 0.8,
          // backgroundColor: 'red',
          padding: 22,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <Image
          source={require("./../../assets/gif.gif")}
          style={{ width: 100, height: 100 }}
        />
        <StyledText
          font_color={this.props.design.popup_text_color}
          font_weight={this.props.design.popup_text_font_weight}
          font_style={this.props.design.popup_text_font_style}
          text_transform={this.props.design.popup_text_font_transform}
          font_family={this.props.design.popup_text_font_family}
          font_size={this.props.design.popup_text_font_size}
          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

          style={{ marginTop: 10, }}>
          {i18n.t("labels.loading")} ...
        </StyledText>
      </View>
    </TouchableOpacity>
  );
  getResponsiveURL = (data) => {
    try {
      let url = data.replace(
        "https://mymenu-backend-staging.s3.amazonaws.com",
        "https://my-menu.imgix.net"
      );
      return `${url}?w=${width * 0.9}&h=$width * 0.5`;
    } catch (error) {
      return url;
    }
  };
  _renderModalContent = () => {
    if (this.props.config.menu_list_view) {
      return (
        <TouchableOpacity
          // style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
          style={{
            flex: 1, alignItems: "center", justifyContent: "center",
            // backdropFilter: "blur(8px)"
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
          onPressOut={() => {
            this.setModalVisible(!this.state.modalVisible);
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: Dimensions.get("window").width * 0.73,
                backgroundColor: this.props.design.popup_background_color,
                // borderColor:'black',
                // borderWidth:1,
                padding: 4,
                // justifyContent: 'center',
                // alignItems: 'center',
                borderRadius: 5,
                maxHeight: "80%",
                backdropFilter: "blur(5px)",
                // alignItems:"center"
              }}
            >
              <ScrollView >
                {/*<List>*/}
                {this.props.menus
                  ? this.props.menus.map((item, index) => {
                    if (item.published === true && item.deleted == null) {
                      return (
                        <View
                          key={index}
                          style={{

                            // borderBottomColor: this.props.design.popup_button_color,
                            // marginVertical:5,
                            // borderBottomWidth: 1,
                            // padding: 10,
                            // // width: "100%",
                            // alignItems: "center",
                            // backgroundColor:this.props.design.popup_button_color,
                            //   marginBottom: 12,
                            //       borderRadius:5,
                          }}
                        >
                          <TouchableOpacity
                            key={index}
                            onPress={() => this.getAllChildrens(item)}
                          >
                            <View style={{
                              // borderBottomColor: "black",
                              // borderBottomWidth: 1,
                              padding: 10,
                              // width: "100%",
                              alignItems: "center",
                              backgroundColor: this.props.design.popup_button_color,
                              marginBottom: index + 1 == this.props.menus.length ? 0 : 4,
                              borderRadius: 5,
                              // marginBottom:2,
                              //     borderRadius:5,
                              //     backgroundColor:this.props.design.popup_button_color
                            }}>
                              <StyledText
                                font_color={this.props.design.popup_button_text_color}
                                font_weight={this.props.design.popup_button_font_weight}
                                font_style={this.props.design.popup_button_font_style}
                                text_transform={this.props.design.popup_button_font_transform}
                                font_family={this.props.design.popup_button_font_family}
                                font_size={this.props.design.popup_button_font_size ?? 16}
                                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                style={{
                                  textAlign: "center",
                                }}
                              >

                                <IntlLabel data={item.menu_titles} />
                              </StyledText>
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    }
                  })
                  : null}
                {/*</List>*/}
              </ScrollView>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      );
    } else {
      return (
        <TouchableOpacity
          style={{
            flex: 1,
            alignItems: "center",
            backgroundColor: "white",

            flexDirection: "column",
            justifyContent: "center",
          }}
          onPressOut={() => {
            this.setModalVisible(!this.state.modalVisible);
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                // width: { width },
                // borderColor:'black',
                // borderWidth:1,
                // justifyContent: 'center',
                // alignItems: 'center',
                flex: 1,
                backgroundColor: this.props.design.popup_background_color,
                borderRadius: 4,
              }}
            >
              <KeyboardAwareScrollView
                style={{
                  marginTop: 54,
                }}
              >
                {/*<List>*/}
                {this.props.menus
                  ? this.props.menus.map((item, index) => {
                    if (item.published === true && item.deleted == null) {
                      return (
                        <View
                          key={index}
                          style={{
                            padding: 10,

                            alignItems: "center",
                          }}
                        >
                          <TouchableOpacity
                            key={index}
                            onPress={() => this.getAllChildrens(item)}
                          >
                            <View
                              style={{
                                borderTopRightRadius: 8,
                                borderTopLeftRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                overflow: "hidden",
                              }}
                            >
                              <Image
                                style={{
                                  width: width * 0.9,
                                  height: width * 0.5,
                                  resizeMode: "stretch",

                                  borderRadius: 8,

                                  alignItems: "center",
                                }}
                                source={
                                  item.image
                                    ? {
                                      uri: this.getResponsiveURL(
                                        item.image
                                      ),
                                    }
                                    : require("./../../assets/food.png")
                                }
                              />
                              <View
                                style={{
                                  fontSize: this.props.design.custom_font.sub_heading_size ?? 16,
                                  backgroundColor: "black",
                                  textAlign: "start",
                                  width: Dimensions.get("window").width * 0.8,
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  opacity: 0.65,
                                  paddingHorizontal: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderTopRightRadius: 4,
                                  borderTopLeftRadius: 4,
                                  width: width * 0.9,
                                  textAlign: "center",
                                }}
                              >
                                <StyledText

                                  adjustsFontSizeToFit={true}
                                  font_color={this.props.design.popup_text_color}
                                  font_weight={this.props.design.popup_text_font_weight}
                                  font_style={this.props.design.popup_text_font_style}
                                  text_transform={this.props.design.popup_text_font_transform}
                                  font_family={this.props.design.popup_text_font_family}
                                  font_size={this.props.design.popup_text_font_size ?? 22}
                                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                                  style={{
                                    color: "white",
                                    textAlign: "start",
                                    paddingVertical: 4,
                                    paddingHorizontal: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderTopRightRadius: 4,
                                    borderTopLeftRadius: 4,
                                  }}
                                >

                                  <IntlLabel data={item.menu_titles} />
                                </StyledText>
                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    }
                  })
                  : null}
                {/*</List>*/}
              </KeyboardAwareScrollView>
              <View
                style={{
                  marginBottom: 24,
                  borderRadius: 10,
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.setModalVisible(!this.state.modalVisible)}
                >
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: "black",
                      width: 50,
                      height: 50,
                      borderRadius: 7,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      style={{ color: "black", fontSize: 25 }}
                      class="fas fa-times"
                    ></i>
                    {/* <Icon style={{ color: "black" }} size={30} name={"close"} /> */}

                    {/* <Icono style={{color:'black'}} size={30} name={'md-close'}/> */}
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      );
    }
  };

  async setModalVisible(visible) {
    let uuid = await AsyncStorage.getItem("uuid");

    const table = await AsyncStorage.getItem("table");
    if (!table) {
      this.loadNewMenu(visible);
      // this.props.resetCart();
      this.saveTable(uuid);
    } else {
      axios
        .get(API_URL + "api/qr-table/" + uuid)
        .then(async (response) => {
          if (response.data.table == table) {
            if (this.props.menus.length > 1) {
              this.showMenus(visible);
            } else {
              this.loadNewMenu(visible);
              // this.props.resetCart();
              await AsyncStorage.setItem("table", response.data.table);
            }
          } else {
            this.loadNewMenu(visible);
            // this.props.resetCart();
            await AsyncStorage.setItem("table", response.data.table);
          }
        })
        .catch((error) => { });
    }
  }

  saveTable = (uuid) => {
    axios
      .get(API_URL + "api/qr-table/" + uuid)
      .then(async (response) => {
        await AsyncStorage.setItem("table", response.data.table);
      })
      .catch((error) => { });
  };

  showMenus = (visible) => {
    this.setState({ modalVisible: visible });
  };

  loadNewMenu = (visible) => {
    console.log("gggg")
    if (this.props.menus.length === 1) {
      // this.setState({shouldPlay: false});
      let item = this.props.menus[0];
      // this.props.selectMenu(item.id);

      // set selected menu in redux
      this.props.selectMenuData(item);

      this.getAllChildrens(item);
    } else if (this.props.menus.length === 0) {
      // Toast.show({ text: "You don't have any menus", position: 'bottom' })
    } else {
      this.setState({ modalVisible: visible });
    }
  };

  getAllChildrens = async (item) => {
    // this.props.resetCart();

    let menuid = item.id;
    if (menuid !== this.props.menuID) {
      this.props.addChildren([]);
    }
    AsyncStorage.getItem("uuid", (err, uuid) => {
      if (err) {
        return;
      } else {
        this.asyncChildrenCall(uuid, menuid);
        this.setState(
          { modalVisible: false, isLandingPageActive: false },
          () => {
            // set menu data in redux
            this.props.selectMenuData(item);

            if (
              // this.props.config.login_required &&
              this.props.config.login_to_see_menu
            ) {
              if (!this.props.user) {
                AsyncStorage.setItem('login_trigger', "HomeScreen")
                this.props.navigation.navigate("MobileForm");
              } else {
                this.navigateToHome();
              }
            } else {
              this.navigateToHome();
            }
          }
        );
      }
    });
  };

  navigateToHome = () => {
    const navigateAction = NavigationActions.navigate({
      routeName: "HomeScreen",
      params: {
        setScreenActive: () => this.setScreenActive(),
      },
    });
    this.props.navigation.dispatch(navigateAction);
  };

  asyncChildrenCall = async (uuid, menuid) => {
    this.props.selectMenu(menuid);
    this.props.isLoading();

    axios
      .get(
        API_URL +
        "api/qr-menu-children/" +
        uuid +
        "/?menu=" +
        menuid +
        "&platform=" +
        this.props.platform +
        "&limit=1500&page=" +
        this.state.page
      )
      .then((response) => {
        let datum = JSON.parse(JSON.stringify(response.data.results));

        datum.forEach((element) => {
          let recoms = [];
          let comb = []
          if (element.recommended_items) {
            element.recommended_items.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) recoms.push(data);
            });
          }
          element.recommended_items = recoms;
          if (element.combos) {
            element.combos.forEach((rItem) => {
              let data = datum.find(
                (val) => rItem == val.id && val.type == "item"
              );
              rItem = data;
              if (data) comb.push(data);
            });
          }
          element.combos = comb;
        });

        this.props.addChildren(datum);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onClickProfile = () => {
    if (this.props.user) {
      this.props.navigation.navigate("AccountScreen");
    } else {
      AsyncStorage.setItem('login_trigger', "MenuHome")
      this.props.navigation.navigate("MobileForm");
    }
  };
  feedbackHandler = () => {
    if (this.props.config.login_required_for_feedback && !this.props.user) {
      AsyncStorage.setItem('login_trigger', "MenuHome")
      localStorage.setItem("is_feedback", true)
      this.props.navigation.navigate("MobileForm");
      return
    }
    if (this.props.config.use_external_feedback && this.props.config.feedback_url) {
      window.open(this.props.config.feedback_url, "_blank",);
      return
    } else {
      this.props.feedbackModal(true)
      // this.setState({ FeedbackModal: true })

    }
  }
  FunctionToBell = (data) => {
    if (data.length) {
      console.log(data[0], "kkkkkkkkkkkkkkkkkkkkk")
      this.setState({ waiterMessages: data[0].messages })

    }
    this.setState({ bellModal: true })

  }
  TopButtons = () => (
    <View style={{ height: "100%", position: "absolute", width: "100%" }}>
      <View
        style={{
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        {this.props.config.capture_profile ||
          this.props.config.login_required ? (
          <TouchableOpacity onPress={() => this.onClickProfile()}>
            <ProfileButton
              border_color={`1px solid ${this.props.design.info_button_background_color}`}
              backgroundColor={this.props.design.info_button_background_color}
              borderColor={"black"}
              color={this.props.design.info_button_text_color}
              default_language={this.props.default_language}
            />
          </TouchableOpacity>
        ) : (
          <View />
        )}
        {this.props.config.show_service_button &&
          this.props.config.general_qr.qr_id != this.state.uuid && (
            <Bell
              FunctionToBell={this.FunctionToBell}
              border_color={`1px solid ${this.props.design.info_button_background_color}`}
              throwMessageFn={() =>
                this.throwMessageFn(
                  "A call has been send to waiter ",
                  this.props.design.home_button_background_color
                )
              }
              backgroundColor={this.props.design.info_button_background_color}
              borderColor={"black"}
              color={this.props.design.info_button_text_color}
              default_language={this.props.default_language}
            />
          )}

        {this.props.config.multi_language ? (
          <DefaultLanguage
            border_color={`1px solid ${this.props.design.info_button_background_color}`}
            changeLanguage={() =>
              this.setState({ defaultLangModal: !this.state.defaultLangModal })
            }
            backgroundColor={this.props.design.info_button_background_color}
            borderColor={"black"}
            color={this.props.design.info_button_text_color}
            default_language={this.props.default_language}
            heading_size={this.props.design.custom_font.heading_size}
          />
        ) : null}
      </View>
      <View
        style={{
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        {this.props.config.show_info && (
          <TouchableOpacity
            onPress={() => this.setState({ infoModal: true })}
            activeOpacity={1}
          >
            <View
              style={[
                style.fontButton,
                {
                  backgroundColor:
                    this.props.design.info_button_background_color,
                  border: `1px solid ${this.props.config.info_button_background_color}`,
                },
              ]}
            >
              <i
                style={{
                  color: this.props.design.info_button_text_color,
                  fontSize: 22,
                }}
                class="fa fa-info"
              ></i>
            </View>
          </TouchableOpacity>
        )}
        {this.props.config.feedback && (
          <FeedbackButton
            design={this.props.design}
            config={this.props.config}
            feedbackHandler={this.feedbackHandler}
            style={style}

          />
        )}

      </View>
    </View>
  );

  MymenuLogo = () => (
    <View
      style={{ backgroundColor: "transparent", width: this.state.width * 0.5 }}
    >
      <TouchableOpacity onPress={()=> window.open("https://www.mydigimenu.com/","_blank")} onLongPress={() => this.setState({ versionVisibility: "block" })} >
        <Image
          style={{ width: 140, height: 40 }}
          source={require("./../../assets/logo-tans.png")}
        />
      </TouchableOpacity>
    </View>
  );
  onPressRedirect = () => {
    window.open("https://all.accor.com/gb/mobile/mobile-app.shtml", "_blank");
  };
  defaultLangView = () => (
    <LanguageContainer
      background_color={this.props.design.popup_background_color}
      closeModal={() => this.setState({ defaultLangModal: false })}
    >
      {this.props.languages.map((item, i) => (
        <TouchableOpacity
          key={i}
          onPress={() => {
            i18n.locale = item.value;

            this.props.addDefaultLanguage(item.value);
            this.setState({ defaultLangModal: false });
          }}
        >
          <View
            style={{
              paddingLeft: 3,
              paddingVertical: 7,
              borderBottom: "1px solid black",
              justifyContent: "center",
            }}
          >
            <StyledText
              font_color={this.props.design.popup_text_color}
              font_weight={this.props.design.popup_text_font_weight}
              font_style={this.props.design.popup_text_font_style}
              text_transform={this.props.design.popup_text_font_transform}
              font_family={this.props.design.popup_text_font_family}
              font_size={this.props.design.popup_text_font_size ?? 18}
              letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
              style={{}}
            >{item.label}
            </StyledText>
          </View>
        </TouchableOpacity>
      ))}
    </LanguageContainer>
  );

  onChangeFeedbackText = (text, index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = text;

    this.setState({ feedback_q_a: Q_A });
  };

  ratingCompleted = (rating, index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = rating;
    this.setState({ feedback_q_a: Q_A });
  };
  onPressYesBox = (index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = true;
    this.setState({ feedback_q_a: Q_A });
  };
  onPressNoBox = (index) => {
    let Q_A = this.state.feedback_q_a;
    Q_A[index]["answer"] = false;
    this.setState({ feedback_q_a: Q_A });
  };

  onSubmitFeedBack = () => {
    let feedbacks = this.state.feedback_q_a;
    let new_feedback_with_warning = [];
    let warning_count = 0;
    Promise.all(
      feedbacks.map((item, index) => {
        if (item.mandatory_answer && item.answer == null) {
          item["display_warning"] = "flex";
          warning_count = warning_count + 1;
          new_feedback_with_warning.push(item);
        } else {
          new_feedback_with_warning.push(item);
        }
      })
    ).then(async () => {
      if (warning_count > 0) {
        this.setState({ feedback_q_a: new_feedback_with_warning });
      } else {
        let results = this.props.config.survey;
        results["question_survey"] = this.state.feedback_q_a;
        let results_converted = JSON.stringify(results);
        // await AsyncStorage.getItem('LOGIN',async (err, res) => {
        //     if (res){
        //         let login = JSON.parse(res);
        const table = await AsyncStorage.getItem("uuid");
        performRequest("post", "api/result/create/pwa/", {
          results: results_converted,
          venue: table,
        })
          .then((response) => {
            this.throwMessageFn(
              i18n.t("labels.feedback_submitted_successfully"),
              this.props.design.home_button_background_color
            );

            let feedback_q_a = [];
            Promise.all(
              this.props.survey.question_survey.map((item, index) => {
                item["display_warning"] = "none";
                item["answer"] = null;
                feedback_q_a.push(item);
              })
            ).then(() => {
              this.setState({ feedback: this.props.survey });
              this.setState({ feedback_q_a: feedback_q_a }, () => {
                this.props.feedbackModal(false)
                // this.setState({ FeedbackModal: false });
              });
            });
          })
          .catch((error) => {
            this.props.feedbackModal(false)
            // this.setState({ FeedbackModal: false });
            // this.throwMessageFn('Error submitting feedback', 'red')
          });
        //     }
        // });
      }
    });
  };

  infoModalView = () => {
    const { config, design } = this.props;
    var htmlstyles = StyleSheet.create({
      a: {
        fontWeight: "300",
        //fontSize: 15,
        fontSize: design.custom_font.body_size ?? 15,
        color: design.popup_text_color,
        marginVertical: 5,
        fontFamily: "SecondaryFont"
      },
      p: {
        //fontSize: 15,
        fontSize: design.custom_font.body_size ?? 15,
        color: design.popup_text_color,
        position: "relative",
        margin: 1,
        fontFamily: this.props.design.popup_text_font_family
      },
      strong: {
        //fontSize: 15,
        fontSize: design.custom_font.body_size ?? 15,
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      h1: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      h2: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      h3: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      h4: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      h5: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
      div: {
        color: design.popup_text_color,
        fontFamily: this.props.design.popup_text_font_family
      },
    });
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)" }}>
        <View
          style={{
            width: Dimensions.get('window').width * .8,
            backgroundColor: this.props.design.popup_background_color,
            borderWidth: 1,
            // padding:  22,
            justifyContent: 'center',
            // alignItems: 'center',
            borderRadius: 4,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            height: Dimensions.get('window').height * .8
          }}
        >
          <ImageBackground imageStyle={{ borderRadius: 4 }} style={{ width: '100%', height: '100%', justifyContent: 'flex-end' }} source={this.props.config.info.image && { uri: this.props.config.info.image }}>
            <View style={{ flexDirection: 'row', width: '90%' }}>
              <View style={{ alignItems: 'center', marginBottom: 60, borderRadius: 10, flex: 1, marginTop: 20 }}>
                <View style={{ alignItems: 'center', marginBottom: 22 }}>
                  {/**fontSize:18 */}
                  <StyledText
                    font_color={this.props.design.popup_heading_text_color}
                    font_weight={this.props.design.popup_heading_font_weight}
                    font_style={this.props.design.popup_heading_font_style}
                    text_transform={this.props.design.popup_heading_font_transform}
                    font_family={this.props.design.popup_heading_font_family}
                    font_size={this.props.design.popup_heading_font_size}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}>
                    {this.props.config.info.header}
                  </StyledText>
                </View>
              </View>
            </View>
            <ScrollView>
              <View style={{ width: '100%', paddingHorizontal: 15 }}>
                <HTMLView
                  value={this.props.config.info.text}
                  stylesheet={htmlstyles}
                  onLinkPress={(url) => console.log('clicked link: ', url)}
                />

              </View>
            </ScrollView>
            <View style={{ borderRadius: 10, position: 'absolute', right: 5, top: 5 }}>
              <TouchableOpacity onPress={() => this.setState({ infoModal: false })}>
                <View style={{ borderWidth: 1, borderColor: 'black', width: 50, height: 50, borderRadius: 7, alignItems: 'center', justifyContent: 'center', backgroundColor: this.props.design.popup_button_color }}>
                  <Icon style={{ color: this.props.design.popup_button_text_color }} size={30} name={'close'} />
                </View>
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
      </View>
    );
  };
  feedbackModalView = () => (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backdropFilter: "blur(5px)" }}>
      <View
        style={{
          width: Dimensions.get("window").width * 0.8,
          backgroundColor: this.props.design.popup_background_color,
          borderWidth: 1,
          padding: 22,
          justifyContent: "center",
          // alignItems: 'center',
          borderRadius: 4,
          borderColor: "rgba(0, 0, 0, 0.1)",
          height: "95%",
        }}
      >
        <View style={{ flexDirection: "row" }}>

          <View
            style={{
              width: "70%",
              alignItems: "center",
              marginBottom: 60,
              borderRadius: 10,
              flex: 1,
              marginTop: 20,
            }}
          >
            <View style={{ alignItems: "center", marginBottom: 22 }}>
              <StyledText
                font_color={this.props.design.popup_heading_text_color}
                font_weight={this.props.design.popup_heading_font_weight}
                font_style={this.props.design.popup_heading_font_style}
                text_transform={this.props.design.popup_heading_font_transform}
                font_family={this.props.design.popup_heading_font_family}
                font_size={this.props.design.popup_heading_font_size ?? 25}
                letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                style={{}}
              >
                {this.props.config.survey.header}
              </StyledText>
            </View>
          </View>
        </View>
        <KeyboardAwareScrollView>
          {
            !!this.props.user &&
            <View style={{  padding: 10 }}>
            
              {
                !!(this.props.user.first_name || this.props.user.last_name) &&
                <View style={{ marginBottom: 15 }}>
                  <StyledText
                    font_color={this.props.design.popup_text_color}
                    font_weight={this.props.design.popup_text_font_weight}
                    font_style={this.props.design.popup_text_font_style}
                    text_transform={this.props.design.popup_text_font_transform}
                    font_family={this.props.design.popup_text_font_family}
                    font_size={this.props.design.popup_text_font_size ?? 14}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}
                  >
                    <IntlLabel data={{ en: "Full Name" }} /> {" "}
                  </StyledText>
                  <TextInput
                    disabled={true}
                    style={{
                      fontSize: this.props.design.popup_text_font_size ?? 16,
                      borderBottomWidth: 1,
                      borderColor: this.props.design.popup_text_color,
                      color: this.props.design.popup_text_color,
                      // fontFamily: this.props.design.popup_text_font_family
                    }}
                    value={this.props.user && `${this.props.user.first_name || ""} ${this.props.user.last_name || ""}`}
                  />
                </View>
              }


              {
                !!this.props.user.email &&
                <View style={{ marginBottom: 15 }}>
                  <StyledText
                    font_color={this.props.design.popup_text_color}
                    font_weight={this.props.design.popup_text_font_weight}
                    font_style={this.props.design.popup_text_font_style}
                    text_transform={this.props.design.popup_text_font_transform}
                    font_family={this.props.design.popup_text_font_family}
                    font_size={this.props.design.popup_text_font_size ?? 14}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}
                  >
                    <IntlLabel data={{ en: "Email" }} /> {" "}
                  </StyledText>
                  <TextInput
                    disabled={true}
                    style={{
                      fontSize: this.props.design.popup_text_font_size ?? 16,
                      borderBottomWidth: 1,
                      borderColor: this.props.design.popup_text_color,
                      color: this.props.design.popup_text_color,
                      // fontFamily: this.props.design.popup_text_font_family
                    }}
                    value={this.props.user.email}
                  />
                </View>
              }
              {
                !!this.props.user.mobile_number &&
                <View style={{ marginBottom: 15 }}>
                  <StyledText
                    font_color={this.props.design.popup_text_color}
                    font_weight={this.props.design.popup_text_font_weight}
                    font_style={this.props.design.popup_text_font_style}
                    text_transform={this.props.design.popup_text_font_transform}
                    font_family={this.props.design.popup_text_font_family}
                    font_size={this.props.design.popup_text_font_size ?? 14}
                    letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                    style={{}}
                  >
                    <IntlLabel data={{ en: "Phone" }} /> {" "}
                  </StyledText>
                  <TextInput
                    disabled={true}
                    style={{
                      fontSize: this.props.design.popup_text_font_size ?? 16,
                      borderBottomWidth: 1,
                      borderColor: this.props.design.popup_text_color,
                      color: this.props.design.popup_text_color,
                      // fontFamily: this.props.design.popup_text_font_family
                    }}
                    value={this.props.user.mobile_number}
                  />
                </View>
              }
            </View>
          }
          <View>

          </View>
          {this.props.config.survey.question_survey
            ? this.props.config.survey.question_survey.length > 0
              ? this.props.config.survey.question_survey.map((item, index) => {
                return (
                  <View key={index} style={{ padding: 10 }}>
                    <View style={{ flexDirection: "row" }}>
                      <StyledText
                        font_color={this.props.design.popup_text_color}
                        font_weight={this.props.design.popup_text_font_weight}
                        font_style={this.props.design.popup_text_font_style}
                        text_transform={this.props.design.popup_text_font_transform}
                        font_family={this.props.design.popup_text_font_family}
                        font_size={this.props.design.popup_text_font_size ?? 14}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                        style={{}}
                      >
                        <IntlLabel data={item.questions} /> {" "}
                      </StyledText>
                      {item.mandatory_answer ? (
                        <StyledText
                          font_color={this.props.design.popup_text_color}
                          font_weight={this.props.design.popup_text_font_weight}
                          font_style={this.props.design.popup_text_font_style}
                          text_transform={this.props.design.popup_text_font_transform}
                          font_family={this.props.design.popup_text_font_family}
                          font_size={this.props.design.popup_text_font_size ?? 14}
                          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                          style={{}}
                        >
                          *
                        </StyledText>
                      ) : null}
                    </View>
                    {item.mandatory_answer ? (
                      <StyledText
                        font_color={"red"}
                        font_weight={this.props.design.popup_text_font_weight}
                        font_style={this.props.design.popup_text_font_style}
                        text_transform={this.props.design.popup_text_font_transform}
                        font_family={this.props.design.popup_text_font_family}
                        font_size={this.props.design.popup_text_font_size ?? 14}
                        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                        style={{
                          display: item.display_warning
                        }}
                      >
                        {i18n.t("labels.answer_required")}
                      </StyledText>
                    ) : null}
                    {item.type == 1 ? (
                      <View style={{ marginBottom: 15 }}>
                        <TextInput
                          style={{
                            fontSize: this.props.design.popup_text_font_size ?? 16,
                            borderBottomWidth: 1,
                            borderColor: this.props.design.popup_text_color,
                            color: this.props.design.popup_text_color,
                            // fontFamily: this.props.design.popup_text_font_family
                          }}
                          onChangeText={(text) =>
                            this.onChangeFeedbackText(text, index)
                          }
                        />
                      </View>
                    ) : null}
                    {item.type == 2 ? (
                      <View
                        style={{
                          marginBottom: 15,
                          marginTop: 8,
                          alignItems: "flex-start",
                        }}
                      >

                        <StarRatings
                          rating={
                            this.state.feedback_q_a.length &&
                            this.state.feedback_q_a[index].answer
                          }
                          starRatedColor={"#FFD700"}
                          starHoverColor={"#FFD700"}
                          starEmptyColor={"lightgrey"}
                          changeRating={(e) => this.ratingCompleted(e, index)}
                          numberOfStars={5}
                          name="rating"
                          starDimension="25px"
                        />
                      </View>
                    ) : null}
                    {item.type == 3 ? (
                      <View>
                        <View
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            flexDirection: "row",
                          }}
                        >
                          <StyledText
                            font_color={this.props.design.popup_text_color}
                            font_weight={this.props.design.popup_text_font_weight}
                            font_style={this.props.design.popup_text_font_style}
                            text_transform={this.props.design.popup_text_font_transform}
                            font_family={this.props.design.popup_text_font_family}
                            font_size={this.props.design.popup_text_font_size ?? 14}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                            style={{}}
                          >
                            {i18n.t("button_text.yes")}{" "}
                          </StyledText>
                          <TouchableOpacity
                            onPress={() => this.onPressYesBox(index)}
                          >
                            <CheckBox
                              value={item.answer === true ? true : false}
                              color={this.props.design.popup_text_color}
                            />
                          </TouchableOpacity>

                          <StyledText
                            font_color={this.props.design.popup_text_color}
                            font_weight={this.props.design.popup_text_font_weight}
                            font_style={this.props.design.popup_text_font_style}
                            text_transform={this.props.design.popup_text_font_transform}
                            font_family={this.props.design.popup_text_font_family}
                            font_size={this.props.design.popup_text_font_size ?? 14}
                            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}

                            style={{
                              marginLeft: 20,
                            }}
                          >
                            {" "}
                            {i18n.t("button_text.no")}{" "}
                          </StyledText>
                          <TouchableOpacity
                            onPress={() => this.onPressNoBox(index)}
                          >
                            <CheckBox
                              value={item.answer === false ? true : false}
                              color={this.props.design.popup_text_color}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    ) : null}
                  </View>
                );
              })
              : null
            : null}
        </KeyboardAwareScrollView>
        <View
          style={{ borderRadius: 10, position: "absolute", right: 5, top: 5 }}
        >
          <TouchableOpacity
            onPress={() => {
              console.log("hereeeeeeeeee")
              this.props.feedbackModal(false)
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: this.props.design.popup_button_color,
                width: 50,
                height: 50,
                borderRadius: 7,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: this.props.design.popup_button_color,
              }}
            >
              <Icon
                style={{ color: this.props.design.popup_button_text_color }}
                size={30}
                name={"close"}
              />
            </View>
          </TouchableOpacity>
        </View>

        {this.props.config.survey.question_survey ? (
          this.props.config.survey.question_survey.length > 0 ? (
            <TouchableOpacity onPress={() => this.onSubmitFeedBack()}>
              <View
                style={{
                  backgroundColor: this.props.design.popup_button_color,
                  width: "100%",
                  marginHorizontal: 3,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 35,
                  borderRadius: 7,
                }}
              >
                <StyledText
                  font_color={this.props.design.popup_button_text_color}
                  font_weight={this.props.design.popup_button_font_weight}
                  font_style={this.props.design.popup_button_font_style}
                  text_transform={this.props.design.popup_button_font_transform}
                  font_family={this.props.design.popup_button_font_family}
                  font_size={this.props.design.popup_button_font_size}
                  letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
                  style={{}}
                >
                  {i18n.t("labels.submit")}
                </StyledText>
              </View>
            </TouchableOpacity>
          ) : null
        ) : null}
      </View>
    </View>
  );

  static navigationOptions = {
    header: null,
    gesturesEnabled: false,
    animationEnabled: false,
  };
  socialIcon = (sourc) => (
    <View
      style={{
        height: 35,
        width: 35,
        backgroundColor: this.props.design.info_button_background_color,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
      }}
    >

      <i style={{ color: this.props.design.info_button_text_color, fontSize: 25 }}
        class={sourc}></i>

    </View>
  );
  // menuButtonStartorLeft = (finalVenueName) =>
  //   finalVenueName.split("").map((e) => (
  //     <Text
  //       style={{
  //         fontSize: 30,
  //         color: this.props.design.home_button_text_color,
  //         textAlign: "center", fontFamily: 'PrimaryFont'
  //       }}
  //     >
  //       {e == null ? "" : e}
  //     </Text>
  //   ));
  loadSocialIconStyle = () => {
    switch (this.props.config.social_profile_icon_position) {

      case "center_left":
      case "center_right":
      case "top_left":
      case "top_right":
      case "bottom_left":
      case "bottom_right":
        return {
          flexDirection: "column",
          borderRadius: 4,
        }
      default:
        return {
          flexDirection: "row",
          borderRadius: 4,
        }

    }
  }
  loadSocialIconContainerStyle = () => {
    switch (this.props.config.social_profile_icon_position) {

      case "center_left":
        return {
          position: "fixed",
          left: this.props.config.menu_button_position == "left" ? 50 : 2,
          top: "40%",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // alignSelf: 'center'
        }
      case "center_right":
        return {
          position: "fixed",
          right: this.props.config.menu_button_position == "right" ? 50 : 2,
          top: "40%",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: 'center'
        }
      case "top":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 130,
          alignSelf: "center",
        }
      case "top_left":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 60,
          left: 1,
        }
      case "top_right":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 60,
          right: 1,
        }
      case "bottom_right":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 70,
          right: 1,
        }
      case "bottom_left":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 70,
          left: 1,
        }
      case "bottom":
        return {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          bottom: 70,
          alignSelf: "center",
        }
      default:
        return {
          justifyContent: "center",
          alignItems: "center",
          marginTop: 25
        }

    }
  }
  loadMenuButtonStyle = () => {
    // let style = {
    //   alignItems: "center",
    //   justifyContent: "center",
    //   borderRadius: 10,
    //   marginTop: 150,
    // }
    switch (this.props.config.menu_button_position) {

      case "left":
        return {
          position: "fixed",
          left: 0,
          top: "35%",
          flex: 1,
          padding: 8,
          height: 200
        }
      case "right":
        return {
          position: "fixed",
          right: 0,
          top: "35%",
          flex: 1,
          padding: 8,
          height: 200
        }
      case "top":
        return {
          flex: 1,
          // marginTop:70,
          justifyContent: "center",
          alignItems: "center",
          position: 'fixed',
          top: 70,
          alignSelf: "center",
          width: 170,
          height: 45

        }
      case "bottom":
        return {
          flex: 1,
          bottom: this.props.config.social_profile_icon_position == "bottom" ? 130 : 90,
          alignSelf: "center",
          position: 'fixed',
          width: 170,
          height: 45
        }
      default:
        return {
          alignItems: "center",
          justifyContent: "center",
          marginTop: 150,
          borderRadius: 10,
          width: 170,
          height: 45
        }

    }
  }
  renderMenuText = () => {
    const { config, default_language } = this.props
    let text = config.welcome_messages_multi ?
      IntlLabelFn(
        JSON.parse(config.welcome_messages_multi),
        config.default_language.value,
        default_language
      ) : "MENU"

    if (this.props.config.menu_button_position == "left" || this.props.config.menu_button_position == "right") {
      return (
        text.split("").slice(0, 8).map(e => (
          <StyledText
            font_color={this.props.design.home_button_text_color}
            font_weight={this.props.design.home_button_text_font_weight}
            font_style={this.props.design.home_button_text_font_style}
            text_transform={this.props.design.home_button_text_font_transform}
            font_family={this.props.design.home_button_text_font_family}
            font_size={this.props.design.home_button_text_font_size ?? 18}
            letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
            style={{ textAlign: "center" }}
          >
            {e}
          </StyledText>
        ))
      )
    }
    return (
      <StyledText
        numberOfLines={1}
        font_color={this.props.design.home_button_text_color}
        font_weight={this.props.design.home_button_text_font_weight}
        font_style={this.props.design.home_button_text_font_style}
        text_transform={this.props.design.home_button_text_font_transform}
        font_family={this.props.design.home_button_text_font_family}
        font_size={this.props.design.home_button_text_font_size ?? 18}
        letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
        style={{
          textAlign: "center",
          margin: 8,
        }}
      >

        {text.slice(0, 15)}
      </StyledText>
    )

  }
  socialIconsVMargin = () => {
    switch (this.props.config.social_profile_icon_position) {

      case "center_left":
      case "center_right":
      case "top_left":
      case "top_right":
      case "bottom_left":
      case "bottom_right":
        return 4
      default: 8

    }
  }
  socialIconsHMargin = () => {
    switch (this.props.config.social_profile_icon_position) {

      // case "center_left":
      // case "center_right":
      case "top_left":
      case "top_right":
      case "bottom_left":
      case "bottom_right":
        return 4
      default:
        return 8

    }
  }
  render() {
    var menuShape = this.props.config.menu_button_shape;
    let buttonRadius = {
      borderRadius: 0,
    };
    if (menuShape == "curved") {
      if (this.props.config.menu_button_position == "right") {
        buttonRadius = {
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }
      } else if (this.props.config.menu_button_position == "left") {
        buttonRadius = {
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }
      } else {
        buttonRadius = {
          borderRadius: 10,
        }
      }
    }
    else if (menuShape == "circle") {
      if (this.props.config.menu_button_position == "right") {
        buttonRadius = {
          borderTopLeftRadius: 100,
          borderBottomLeftRadius: 100,
        }
      } else if (this.props.config.menu_button_position == "left") {
        buttonRadius = {
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
        }
      }
      else {
        buttonRadius = {
          borderRadius: 100,
        }
      }
    }

    // let finalVenueName = this.props.config.welcome_message
    //   ? this.props.config.welcome_message
    //   : "MENU";

    // if (finalVenueName.length > 10)
    //   finalVenueName = finalVenueName.substring(0, 9);

    // menuPosition = "center";
    // socialIconPosition = "center";

    return (
      <SafeAreaView
        // {...this._panResponder.panHandlers}
        style={{
          backgroundColor:
            !this.props.design.display_image_for_home_page_background &&
            this.props.design.home_page_background_color,
          flex: 1,
        }}
      >
        {this.props.design.display_image_for_home_page_background && (
          <ProgressiveImage
            position={"absolute"}
            thumbnailSource={{
              uri:
                image_api +
                this.props.design.background_image
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=250&h=250",
            }}
            source={{
              uri:
                image_api +
                this.props.design.background_image
                  .split("/")
                  .splice(3)
                  .join("/") +
                "?w=800&h=800",
            }}
            style={{ width: "100%", height: "100%", position: "absolute" }}
            resizeMode="cover"
          />


        )}
        {
          !!this.props.config.logo_image && this.props.design.is_logo_enabled &&
          <View style={style.logoContainer} >
            <View style={style.logoImgContainer}>
              <Image
                source={{ uri: this.props.config.logo_image }}
                style={{ width: "100%", height: "100%" }}
              />
            </View>
          </View>

        }
        <View
          style={{
            flex: 1,
            // flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setModalVisible(true);
            }}
          >
            <View
              style={[
                style.welcomeContainer, this.loadMenuButtonStyle(), buttonRadius,
                {
                  backgroundColor:
                    this.props.design.home_button_background_color,
                },
              ]}
            >
              {this.renderMenuText()}

            </View>
          </TouchableOpacity>


          {this.props.config.social_profile_icons &&
            (
              <View
                style={this.loadSocialIconContainerStyle()}
              >
                <View
                  style={this.loadSocialIconStyle()}
                >
                  {
                    !!this.props.config.social_profile.facebook &&
                    <View
                      style={{
                        borderRadius: 4,
                        alignSelf: "center",
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),
                      }}
                    >
                      <TouchableWithoutFeedback
                        style={{
                          zIndex: 56,
                        }}
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.facebook ? "https://facebook.com/" + this.props.config.social_profile.facebook : "https://facebook.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-facebook-f")}
                      </TouchableWithoutFeedback>
                    </View>

                  }
                  {
                    !!this.props.config.social_profile.twitter &&
                    <View
                      style={{
                        borderRadius: 4,
                        alignSelf: "center",
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),
                      }}
                    >
                      <TouchableWithoutFeedback
                        style={{
                          zIndex: 56,
                        }}
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.twitter ? "https://twitter.com/" + this.props.config.social_profile.twitter : "https://twitter.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-twitter")}
                      </TouchableWithoutFeedback>
                    </View>
                  }

                  {
                    !!this.props.config.social_profile.instagram &&
                    <View
                      style={{
                        alignSelf: "center",
                        borderRadius: 4,
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),

                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.instagram ? "https://instagram.com/" + this.props.config.social_profile.instagram : "https://instagram.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-instagram")}
                      </TouchableWithoutFeedback>
                    </View>
                  }

                  {
                    !!this.props.config.social_profile.tiktok &&
                    <View
                      style={{
                        alignSelf: "center",
                        borderRadius: 4,
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),

                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.tiktok ? "https://tiktok.com/@" + this.props.config.social_profile.tiktok : "https://tiktok.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-tiktok")}
                      </TouchableWithoutFeedback>
                    </View>
                  }


                  {
                    !!this.props.config.social_profile.snapchat &&
                    <View
                      style={{
                        alignSelf: "center",
                        borderRadius: 4,
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),

                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.snapchat ? "https://snapchat.com/add/" + this.props.config.social_profile.snapchat : "https://snapchat.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-snapchat")}
                      </TouchableWithoutFeedback>
                    </View>
                  }


                  {
                    !!this.props.config.social_profile.youtube &&
                    <View
                      style={{
                        alignSelf: "center",
                        borderRadius: 4,
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),

                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.youtube ? "https://youtube.com/c/" + this.props.config.social_profile.youtube : "https://youtube.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fab fa-youtube")}
                      </TouchableWithoutFeedback>
                    </View>
                  }
                  {
                    !!this.props.config.social_profile.website &&
                    <View
                      style={{
                        alignSelf: "center",
                        borderRadius: 4,
                        marginVertical: this.socialIconsVMargin(),
                        marginHorizontal: this.socialIconsHMargin(),

                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (Platform.OS == "web") {
                            window.open(
                              `${this.props.config.social_profile.website ? this.props.config.social_profile.website : "https://instagram.com/"}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        {this.socialIcon("fas fa-globe")}
                      </TouchableWithoutFeedback>
                    </View>
                  }
                </View>
              </View>
            )}
        </View>

        <Modal
          // animationType="slide"
          transparent
          visible={this.state.modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this._renderModalContent()}
        </Modal>

        {/*<View style={{position:'absolute',height:60,width:'100%',top:0,justifyContent:'center',flexDirection:'row'}}>*/}
        {/*{this.FooterButtons()}*/}
        {/*/!*{this.MymenuLogo()}*!/*/}
        {/*</View>*/}

        <View
          style={{
            position: "absolute",
            height: 60,
            width: "100%",
            top: 0,
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {this.TopButtons()}
        </View>

        <View
          style={{
            position: "absolute",
            height: 60,
            width: "100%",
            bottom: 0,
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {this.MymenuLogo()}
        </View>

        <Modal
          // animationType="slide"
          transparent={false}
          visible={this.state.loaderModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this._renderLoaderContent()}
        </Modal>

        <Modal
          transparent
          // animationType="slide"
          visible={this.state.defaultLangModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this.defaultLangView()}
        </Modal>

        {/*feedback modal*/}
        <Modal
          transparent
          animationType="slide"
          visible={this.props.FeedbackModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this.feedbackModalView()}
        </Modal>

        {/*end feedback modal*/}

        {/*info modal*/}
        <Modal
          transparent
          animationType="slide"
          visible={this.state.infoModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this.infoModalView()}
        </Modal>

        {/*end info modal*/}


        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.bellModal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          {this._renderBellContent()}
        </Modal>



        <Toast
          error_message={this.state.error_message}
          message_status={this.state.message_status}
          message_display={this.state.message_display}
          warn_text={this.state.warn_text}
          custom_font={this.props.design.custom_font}
        />

        {/* <CampaignModal
                    campaign={this.state.selectedCampaign}
                    campaignModal={this.state.campaignModal}
                    closeCampaignModal={this.closeCampaignModal}
                    popup_text_color={this.state.popup_text_color}
                    popup_button_color={this.state.popup_button_color}
                    popup_background_color={this.state.popup_background_color}
                /> */}
        <Modal
          transparent
          style={{ backgroundColor: "transparent" }}
          animationType="slide"
          // visible={true}
          visible={this.state.orderSuccessModal}
        >
          <OrderSuccessModal
            order_response={this.state.order_response}
            platform={this.props.platform}
            menu_type={this.props.menu_type}
            config={this.props.config}
            design={this.props.design}
            orderScreen={() => this.orderScreen()}
            closeSuccessModal={() => this.closeSuccessModal()}
          />
        </Modal>
        <StyledText
          font_color={this.props.design.home_button_text_color}
          font_weight={this.props.design.home_button_text_font_weight}
          font_style={this.props.design.home_button_text_font_style}
          text_transform={this.props.design.home_button_text_font_transform}
          font_family={this.props.design.home_button_text_font_family}
          font_size={11}
          letter_spacing={FontLetterSpacing(this.props.design.custom_font.primary_letter_space)}
          style={{ position: "absolute", bottom: 0, alignSelf: "center", display: this.state.versionVisibility }} >
          v{Constants.manifest.version}
        </StyledText>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    config: state.config.config,
    allChildren: state.menus.allChildren,
    languages: state.languages.languages,
    default_language: state.languages.default_language,
    menuID: state.menus.menuID,
    user: state.number.user,
    platform: state.config.platform,
    menu_type: state.config.menu_type,
    campaigns: state.menus.campaigns,
    design: state.config.design,
    FeedbackModal: state.config.FeedbackModal
  };
};

export default connect(mapStateToProps, {
  addChildren,
  resetCart,
  addDefaultLanguage,
  selectMenu,
  isLoading,
  selectMenuData,
  feedbackModal
})(MenuHome);
