import React from 'react';
import {View,Text} from 'react-native';
import {  Button } from 'native-base';
import { style } from "../../assets/style";
import StyledText from "../components/styledText"
import { FontLetterSpacing } from '../handlers/utils';

const TipButtons = (props) => {
    return(
        <Button
            onPress={props.calculatePercent}
                style={[style.tipContainer, {borderColor:props.design.top_buttons_background_color}]}>
                    <StyledText 
                    font_color={props.design.top_buttons_background_color}
                    font_weight={props.design.top_button_text_font_weight}
                        font_style={props.design.top_button_text_font_style}
                        text_transform={props.design.top_button_text_font_transform}
                        font_family={props.design.top_button_text_font_family}
                        font_size={props.design.top_button_text_font_size}
                        letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}

                    style={{}}> 
                     {props.percentage} 
                     </StyledText>
        </Button>
    )
}

export default TipButtons