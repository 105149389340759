'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var AssignedAddOnList = require(
    './incomingPhoneNumber/assignedAddOn').AssignedAddOnList;
var LocalList = require('./incomingPhoneNumber/local').LocalList;
var MobileList = require('./incomingPhoneNumber/mobile').MobileList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var TollFreeList = require('./incomingPhoneNumber/tollFree').TollFreeList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var IncomingPhoneNumberList;
var IncomingPhoneNumberPage;
var IncomingPhoneNumberInstance;
var IncomingPhoneNumberContext;

/* jshint ignore:start */
/**
 * Initialize the IncomingPhoneNumberList
 *
 * @constructor Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList
 *
 * @param {Twilio.Api.V2010} version - Version of the resource
 * @param {string} accountSid - The SID of the Account that created the resource
 */
/* jshint ignore:end */
IncomingPhoneNumberList = function IncomingPhoneNumberList(version, accountSid)
                                                            {
  /* jshint ignore:start */
  /**
   * @function incomingPhoneNumbers
   * @memberof Twilio.Api.V2010.AccountContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext}
   */
  /* jshint ignore:end */
  function IncomingPhoneNumberListInstance(sid) {
    return IncomingPhoneNumberListInstance.get(sid);
  }

  IncomingPhoneNumberListInstance._version = version;
  // Path Solution
  IncomingPhoneNumberListInstance._solution = {accountSid: accountSid};
  IncomingPhoneNumberListInstance._uri = `/Accounts/${accountSid}/IncomingPhoneNumbers.json`;

  // Components
  IncomingPhoneNumberListInstance._local = undefined;
  IncomingPhoneNumberListInstance._mobile = undefined;
  IncomingPhoneNumberListInstance._tollFree = undefined;

  /* jshint ignore:start */
  /**
   * Streams IncomingPhoneNumberInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {object} [opts] - Options for request
   * @param {boolean} [opts.beta] - Whether to include new phone numbers
   * @param {string} [opts.friendlyName] -
   *          A string that identifies the IncomingPhoneNumber resources to read
   * @param {string} [opts.phoneNumber] -
   *          The phone numbers of the IncomingPhoneNumber resources to read
   * @param {string} [opts.origin] -
   *          Include phone numbers based on their origin. By default, phone numbers of all origin are included.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists IncomingPhoneNumberInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {object} [opts] - Options for request
   * @param {boolean} [opts.beta] - Whether to include new phone numbers
   * @param {string} [opts.friendlyName] -
   *          A string that identifies the IncomingPhoneNumber resources to read
   * @param {string} [opts.phoneNumber] -
   *          The phone numbers of the IncomingPhoneNumber resources to read
   * @param {string} [opts.origin] -
   *          Include phone numbers based on their origin. By default, phone numbers of all origin are included.
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of IncomingPhoneNumberInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {object} [opts] - Options for request
   * @param {boolean} [opts.beta] - Whether to include new phone numbers
   * @param {string} [opts.friendlyName] -
   *          A string that identifies the IncomingPhoneNumber resources to read
   * @param {string} [opts.phoneNumber] -
   *          The phone numbers of the IncomingPhoneNumber resources to read
   * @param {string} [opts.origin] -
   *          Include phone numbers based on their origin. By default, phone numbers of all origin are included.
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Beta': serialize.bool(_.get(opts, 'beta')),
      'FriendlyName': _.get(opts, 'friendlyName'),
      'PhoneNumber': _.get(opts, 'phoneNumber'),
      'Origin': _.get(opts, 'origin'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new IncomingPhoneNumberPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of IncomingPhoneNumberInstance records from the
   * API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.getPage = function getPage(targetUrl, callback)
                                                              {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new IncomingPhoneNumberPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a IncomingPhoneNumberInstance
   *
   * @function create
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.apiVersion] -
   *          The API version to use for incoming calls made to the new phone number
   * @param {string} [opts.friendlyName] - A string to describe the new phone number
   * @param {string} [opts.smsApplicationSid] -
   *          The SID of the application to handle SMS messages
   * @param {string} [opts.smsFallbackMethod] -
   *          HTTP method used with sms_fallback_url
   * @param {string} [opts.smsFallbackUrl] -
   *          The URL we call when an error occurs while executing TwiML
   * @param {string} [opts.smsMethod] - The HTTP method to use with sms url
   * @param {string} [opts.smsUrl] -
   *          The URL we should call when the new phone number receives an incoming SMS message
   * @param {string} [opts.statusCallback] -
   *          The URL we should call to send status information to your application
   * @param {string} [opts.statusCallbackMethod] -
   *          HTTP method we should use to call status_callback
   * @param {string} [opts.voiceApplicationSid] -
   *          The SID of the application to handle the new phone number
   * @param {boolean} [opts.voiceCallerIdLookup] -
   *          Whether to lookup the caller's name
   * @param {string} [opts.voiceFallbackMethod] -
   *          The HTTP method used with voice_fallback_url
   * @param {string} [opts.voiceFallbackUrl] -
   *          The URL we will call when an error occurs in TwiML
   * @param {string} [opts.voiceMethod] - The HTTP method used with the voice_url
   * @param {string} [opts.voiceUrl] -
   *          The URL we should call when the phone number receives a call
   * @param {incoming_phone_number.emergency_status} [opts.emergencyStatus] -
   *          Displays if emergency calling is enabled for this number.
   * @param {string} [opts.emergencyAddressSid] -
   *          The emergency address configuration to use for emergency calling
   * @param {string} [opts.trunkSid] -
   *          SID of the trunk to handle calls to the new phone number
   * @param {string} [opts.identitySid] -
   *          The SID of the Identity resource to associate with the new phone number
   * @param {string} [opts.addressSid] -
   *          The SID of the Address resource associated with the phone number
   * @param {incoming_phone_number.voice_receive_mode} [opts.voiceReceiveMode] -
   *          Incoming call type: fax or voice
   * @param {string} [opts.bundleSid] -
   *          The SID of the Bundle resource associated with number
   * @param {string} [opts.phoneNumber] -
   *          The phone number to purchase in E.164 format
   * @param {string} [opts.areaCode] - The desired area code for the new phone number
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.create = function create(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PhoneNumber': _.get(opts, 'phoneNumber'),
      'AreaCode': _.get(opts, 'areaCode'),
      'ApiVersion': _.get(opts, 'apiVersion'),
      'FriendlyName': _.get(opts, 'friendlyName'),
      'SmsApplicationSid': _.get(opts, 'smsApplicationSid'),
      'SmsFallbackMethod': _.get(opts, 'smsFallbackMethod'),
      'SmsFallbackUrl': _.get(opts, 'smsFallbackUrl'),
      'SmsMethod': _.get(opts, 'smsMethod'),
      'SmsUrl': _.get(opts, 'smsUrl'),
      'StatusCallback': _.get(opts, 'statusCallback'),
      'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod'),
      'VoiceApplicationSid': _.get(opts, 'voiceApplicationSid'),
      'VoiceCallerIdLookup': serialize.bool(_.get(opts, 'voiceCallerIdLookup')),
      'VoiceFallbackMethod': _.get(opts, 'voiceFallbackMethod'),
      'VoiceFallbackUrl': _.get(opts, 'voiceFallbackUrl'),
      'VoiceMethod': _.get(opts, 'voiceMethod'),
      'VoiceUrl': _.get(opts, 'voiceUrl'),
      'EmergencyStatus': _.get(opts, 'emergencyStatus'),
      'EmergencyAddressSid': _.get(opts, 'emergencyAddressSid'),
      'TrunkSid': _.get(opts, 'trunkSid'),
      'IdentitySid': _.get(opts, 'identitySid'),
      'AddressSid': _.get(opts, 'addressSid'),
      'VoiceReceiveMode': _.get(opts, 'voiceReceiveMode'),
      'BundleSid': _.get(opts, 'bundleSid')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new IncomingPhoneNumberInstance(
        this._version,
        payload,
        this._solution.accountSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a incoming_phone_number
   *
   * @function get
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext}
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.get = function get(sid) {
    return new IncomingPhoneNumberContext(this._version, this._solution.accountSid, sid);
  };

  Object.defineProperty(IncomingPhoneNumberListInstance,
    'local', {
      get: function local() {
        if (!this._local) {
          this._local = new LocalList(this._version, this._solution.accountSid);
        }

        return this._local;
      }
  });

  Object.defineProperty(IncomingPhoneNumberListInstance,
    'mobile', {
      get: function mobile() {
        if (!this._mobile) {
          this._mobile = new MobileList(this._version, this._solution.accountSid);
        }

        return this._mobile;
      }
  });

  Object.defineProperty(IncomingPhoneNumberListInstance,
    'tollFree', {
      get: function tollFree() {
        if (!this._tollFree) {
          this._tollFree = new TollFreeList(this._version, this._solution.accountSid);
        }

        return this._tollFree;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  IncomingPhoneNumberListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  IncomingPhoneNumberListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return IncomingPhoneNumberListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the IncomingPhoneNumberPage
 *
 * @constructor Twilio.Api.V2010.AccountContext.IncomingPhoneNumberPage
 *
 * @param {V2010} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {IncomingPhoneNumberSolution} solution - Path solution
 *
 * @returns IncomingPhoneNumberPage
 */
/* jshint ignore:end */
IncomingPhoneNumberPage = function IncomingPhoneNumberPage(version, response,
                                                            solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(IncomingPhoneNumberPage.prototype, Page.prototype);
IncomingPhoneNumberPage.prototype.constructor = IncomingPhoneNumberPage;

/* jshint ignore:start */
/**
 * Build an instance of IncomingPhoneNumberInstance
 *
 * @function getInstance
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberPage#
 *
 * @param {IncomingPhoneNumberPayload} payload - Payload response from the API
 *
 * @returns IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberPage.prototype.getInstance = function getInstance(payload) {
  return new IncomingPhoneNumberInstance(this._version, payload, this._solution.accountSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
IncomingPhoneNumberPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

IncomingPhoneNumberPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the IncomingPhoneNumberContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} addressSid -
 *          The SID of the Address resource associated with the phone number
 * @property {incoming_phone_number.address_requirement} addressRequirements -
 *          Whether the phone number requires an Address registered with Twilio.
 * @property {string} apiVersion -
 *          The API version used to start a new TwiML session
 * @property {boolean} beta -
 *          Whether the phone number is new to the Twilio platform
 * @property {PhoneNumberCapabilities} capabilities -
 *          Indicate if a phone can receive calls or messages
 * @property {Date} dateCreated -
 *          The RFC 2822 date and time in GMT that the resource was created
 * @property {Date} dateUpdated -
 *          The RFC 2822 date and time in GMT that the resource was last updated
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} identitySid -
 *          The SID of the Identity resource associated with number
 * @property {string} phoneNumber - The phone number in E.164 format
 * @property {string} origin - The phone number's origin. Can be twilio or hosted.
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} smsApplicationSid -
 *          The SID of the application that handles SMS messages sent to the phone number
 * @property {string} smsFallbackMethod -
 *          The HTTP method used with sms_fallback_url
 * @property {string} smsFallbackUrl -
 *          The URL that we call when an error occurs while retrieving or executing the TwiML
 * @property {string} smsMethod - The HTTP method to use with sms_url
 * @property {string} smsUrl -
 *          The URL we call when the phone number receives an incoming SMS message
 * @property {string} statusCallback -
 *          The URL to send status information to your application
 * @property {string} statusCallbackMethod -
 *          The HTTP method we use to call status_callback
 * @property {string} trunkSid -
 *          The SID of the Trunk that handles calls to the phone number
 * @property {string} uri -
 *          The URI of the resource, relative to `https://api.twilio.com`
 * @property {incoming_phone_number.voice_receive_mode} voiceReceiveMode -
 *          The voice_receive_mode
 * @property {string} voiceApplicationSid -
 *          The SID of the application that handles calls to the phone number
 * @property {boolean} voiceCallerIdLookup - Whether to lookup the caller's name
 * @property {string} voiceFallbackMethod -
 *          The HTTP method used with voice_fallback_url
 * @property {string} voiceFallbackUrl -
 *          The URL we call when an error occurs in TwiML
 * @property {string} voiceMethod - The HTTP method used with the voice_url
 * @property {string} voiceUrl -
 *          The URL we call when the phone number receives a call
 * @property {incoming_phone_number.emergency_status} emergencyStatus -
 *          Displays if emergency calling is enabled for this number.
 * @property {string} emergencyAddressSid -
 *          The emergency address configuration to use for emergency calling
 * @property {incoming_phone_number.emergency_address_status} emergencyAddressStatus -
 *          State of the emergency address configuration for the phone number
 * @property {string} bundleSid -
 *          The SID of the Bundle resource associated with number
 * @property {string} status - The status
 *
 * @param {V2010} version - Version of the resource
 * @param {IncomingPhoneNumberPayload} payload - The instance payload
 * @param {sid} accountSid - The SID of the Account that created the resource
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance = function IncomingPhoneNumberInstance(version,
    payload, accountSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.addressSid = payload.address_sid; // jshint ignore:line
  this.addressRequirements = payload.address_requirements; // jshint ignore:line
  this.apiVersion = payload.api_version; // jshint ignore:line
  this.beta = payload.beta; // jshint ignore:line
  this.capabilities = payload.capabilities; // jshint ignore:line
  this.dateCreated = deserialize.rfc2822DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.rfc2822DateTime(payload.date_updated); // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.identitySid = payload.identity_sid; // jshint ignore:line
  this.phoneNumber = payload.phone_number; // jshint ignore:line
  this.origin = payload.origin; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.smsApplicationSid = payload.sms_application_sid; // jshint ignore:line
  this.smsFallbackMethod = payload.sms_fallback_method; // jshint ignore:line
  this.smsFallbackUrl = payload.sms_fallback_url; // jshint ignore:line
  this.smsMethod = payload.sms_method; // jshint ignore:line
  this.smsUrl = payload.sms_url; // jshint ignore:line
  this.statusCallback = payload.status_callback; // jshint ignore:line
  this.statusCallbackMethod = payload.status_callback_method; // jshint ignore:line
  this.trunkSid = payload.trunk_sid; // jshint ignore:line
  this.uri = payload.uri; // jshint ignore:line
  this.voiceReceiveMode = payload.voice_receive_mode; // jshint ignore:line
  this.voiceApplicationSid = payload.voice_application_sid; // jshint ignore:line
  this.voiceCallerIdLookup = payload.voice_caller_id_lookup; // jshint ignore:line
  this.voiceFallbackMethod = payload.voice_fallback_method; // jshint ignore:line
  this.voiceFallbackUrl = payload.voice_fallback_url; // jshint ignore:line
  this.voiceMethod = payload.voice_method; // jshint ignore:line
  this.voiceUrl = payload.voice_url; // jshint ignore:line
  this.emergencyStatus = payload.emergency_status; // jshint ignore:line
  this.emergencyAddressSid = payload.emergency_address_sid; // jshint ignore:line
  this.emergencyAddressStatus = payload.emergency_address_status; // jshint ignore:line
  this.bundleSid = payload.bundle_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {accountSid: accountSid, sid: sid || this.sid, };
};

Object.defineProperty(IncomingPhoneNumberInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new IncomingPhoneNumberContext(
          this._version,
          this._solution.accountSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * update a IncomingPhoneNumberInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.accountSid] -
 *          The SID of the Account that created the resource to update
 * @param {string} [opts.apiVersion] -
 *          The API version to use for incoming calls made to the phone number
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.smsApplicationSid] -
 *          Unique string that identifies the application
 * @param {string} [opts.smsFallbackMethod] -
 *          HTTP method used with sms_fallback_url
 * @param {string} [opts.smsFallbackUrl] -
 *          The URL we call when an error occurs while executing TwiML
 * @param {string} [opts.smsMethod] - The HTTP method to use with sms_url
 * @param {string} [opts.smsUrl] -
 *          The URL we should call when the phone number receives an incoming SMS message
 * @param {string} [opts.statusCallback] -
 *          The URL we should call to send status information to your application
 * @param {string} [opts.statusCallbackMethod] -
 *          The HTTP method we should use to call status_callback
 * @param {string} [opts.voiceApplicationSid] -
 *          The SID of the application to handle the phone number
 * @param {boolean} [opts.voiceCallerIdLookup] -
 *          Whether to lookup the caller's name
 * @param {string} [opts.voiceFallbackMethod] -
 *          The HTTP method used with fallback_url
 * @param {string} [opts.voiceFallbackUrl] -
 *          The URL we will call when an error occurs in TwiML
 * @param {string} [opts.voiceMethod] - The HTTP method used with the voice_url
 * @param {string} [opts.voiceUrl] -
 *          The URL we should call when the phone number receives a call
 * @param {incoming_phone_number.emergency_status} [opts.emergencyStatus] -
 *          Displays if emergency calling is enabled for this number.
 * @param {string} [opts.emergencyAddressSid] -
 *          The emergency address configuration to use for emergency calling
 * @param {string} [opts.trunkSid] -
 *          SID of the trunk to handle phone calls to the phone number
 * @param {incoming_phone_number.voice_receive_mode} [opts.voiceReceiveMode] -
 *          Incoming call type: fax or voice
 * @param {string} [opts.identitySid] -
 *          Unique string that identifies the identity associated with number
 * @param {string} [opts.addressSid] -
 *          The SID of the Address resource associated with the phone number
 * @param {string} [opts.bundleSid] -
 *          The SID of the Bundle resource associated with number
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * fetch a IncomingPhoneNumberInstance
 *
 * @function fetch
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a IncomingPhoneNumberInstance
 *
 * @function remove
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Access the assignedAddOns
 *
 * @function assignedAddOns
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance#
 *
 * @returns {Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext.AssignedAddOnList}
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance.prototype.assignedAddOns = function assignedAddOns()
    {
  return this._proxy.assignedAddOns;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
IncomingPhoneNumberInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

IncomingPhoneNumberInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the IncomingPhoneNumberContext
 *
 * @constructor Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext
 *
 * @property {Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext.AssignedAddOnList} assignedAddOns -
 *          assignedAddOns resource
 *
 * @param {V2010} version - Version of the resource
 * @param {sid} accountSid -
 *          The SID of the Account that created the resource to fetch
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
IncomingPhoneNumberContext = function IncomingPhoneNumberContext(version,
    accountSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {accountSid: accountSid, sid: sid, };
  this._uri = `/Accounts/${accountSid}/IncomingPhoneNumbers/${sid}.json`;

  // Dependents
  this._assignedAddOns = undefined;
};

/* jshint ignore:start */
/**
 * update a IncomingPhoneNumberInstance
 *
 * @function update
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.accountSid] -
 *          The SID of the Account that created the resource to update
 * @param {string} [opts.apiVersion] -
 *          The API version to use for incoming calls made to the phone number
 * @param {string} [opts.friendlyName] - A string to describe the resource
 * @param {string} [opts.smsApplicationSid] -
 *          Unique string that identifies the application
 * @param {string} [opts.smsFallbackMethod] -
 *          HTTP method used with sms_fallback_url
 * @param {string} [opts.smsFallbackUrl] -
 *          The URL we call when an error occurs while executing TwiML
 * @param {string} [opts.smsMethod] - The HTTP method to use with sms_url
 * @param {string} [opts.smsUrl] -
 *          The URL we should call when the phone number receives an incoming SMS message
 * @param {string} [opts.statusCallback] -
 *          The URL we should call to send status information to your application
 * @param {string} [opts.statusCallbackMethod] -
 *          The HTTP method we should use to call status_callback
 * @param {string} [opts.voiceApplicationSid] -
 *          The SID of the application to handle the phone number
 * @param {boolean} [opts.voiceCallerIdLookup] -
 *          Whether to lookup the caller's name
 * @param {string} [opts.voiceFallbackMethod] -
 *          The HTTP method used with fallback_url
 * @param {string} [opts.voiceFallbackUrl] -
 *          The URL we will call when an error occurs in TwiML
 * @param {string} [opts.voiceMethod] - The HTTP method used with the voice_url
 * @param {string} [opts.voiceUrl] -
 *          The URL we should call when the phone number receives a call
 * @param {incoming_phone_number.emergency_status} [opts.emergencyStatus] -
 *          Displays if emergency calling is enabled for this number.
 * @param {string} [opts.emergencyAddressSid] -
 *          The emergency address configuration to use for emergency calling
 * @param {string} [opts.trunkSid] -
 *          SID of the trunk to handle phone calls to the phone number
 * @param {incoming_phone_number.voice_receive_mode} [opts.voiceReceiveMode] -
 *          Incoming call type: fax or voice
 * @param {string} [opts.identitySid] -
 *          Unique string that identifies the identity associated with number
 * @param {string} [opts.addressSid] -
 *          The SID of the Address resource associated with the phone number
 * @param {string} [opts.bundleSid] -
 *          The SID of the Bundle resource associated with number
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'AccountSid': _.get(opts, 'accountSid'),
    'ApiVersion': _.get(opts, 'apiVersion'),
    'FriendlyName': _.get(opts, 'friendlyName'),
    'SmsApplicationSid': _.get(opts, 'smsApplicationSid'),
    'SmsFallbackMethod': _.get(opts, 'smsFallbackMethod'),
    'SmsFallbackUrl': _.get(opts, 'smsFallbackUrl'),
    'SmsMethod': _.get(opts, 'smsMethod'),
    'SmsUrl': _.get(opts, 'smsUrl'),
    'StatusCallback': _.get(opts, 'statusCallback'),
    'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod'),
    'VoiceApplicationSid': _.get(opts, 'voiceApplicationSid'),
    'VoiceCallerIdLookup': serialize.bool(_.get(opts, 'voiceCallerIdLookup')),
    'VoiceFallbackMethod': _.get(opts, 'voiceFallbackMethod'),
    'VoiceFallbackUrl': _.get(opts, 'voiceFallbackUrl'),
    'VoiceMethod': _.get(opts, 'voiceMethod'),
    'VoiceUrl': _.get(opts, 'voiceUrl'),
    'EmergencyStatus': _.get(opts, 'emergencyStatus'),
    'EmergencyAddressSid': _.get(opts, 'emergencyAddressSid'),
    'TrunkSid': _.get(opts, 'trunkSid'),
    'VoiceReceiveMode': _.get(opts, 'voiceReceiveMode'),
    'IdentitySid': _.get(opts, 'identitySid'),
    'AddressSid': _.get(opts, 'addressSid'),
    'BundleSid': _.get(opts, 'bundleSid')
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new IncomingPhoneNumberInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * fetch a IncomingPhoneNumberInstance
 *
 * @function fetch
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new IncomingPhoneNumberInstance(
      this._version,
      payload,
      this._solution.accountSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a IncomingPhoneNumberInstance
 *
 * @function remove
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed IncomingPhoneNumberInstance
 */
/* jshint ignore:end */
IncomingPhoneNumberContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(IncomingPhoneNumberContext.prototype,
  'assignedAddOns', {
    get: function() {
      if (!this._assignedAddOns) {
        this._assignedAddOns = new AssignedAddOnList(
          this._version,
          this._solution.accountSid,
          this._solution.sid
        );
      }
      return this._assignedAddOns;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Api.V2010.AccountContext.IncomingPhoneNumberContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
IncomingPhoneNumberContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

IncomingPhoneNumberContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  IncomingPhoneNumberList: IncomingPhoneNumberList,
  IncomingPhoneNumberPage: IncomingPhoneNumberPage,
  IncomingPhoneNumberInstance: IncomingPhoneNumberInstance,
  IncomingPhoneNumberContext: IncomingPhoneNumberContext
};
