'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var InteractionChannelInviteList = require(
    './interactionChannel/interactionChannelInvite').InteractionChannelInviteList;
var InteractionChannelParticipantList = require(
    './interactionChannel/interactionChannelParticipant').InteractionChannelParticipantList;
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var InteractionChannelList;
var InteractionChannelPage;
var InteractionChannelInstance;
var InteractionChannelContext;

/* jshint ignore:start */
/**
 * Initialize the InteractionChannelList
 *
 * @constructor Twilio.FlexApi.V1.InteractionContext.InteractionChannelList
 *
 * @param {Twilio.FlexApi.V1} version - Version of the resource
 * @param {string} interactionSid - The unique string that identifies the resource.
 */
/* jshint ignore:end */
InteractionChannelList = function InteractionChannelList(version,
                                                          interactionSid) {
  /* jshint ignore:start */
  /**
   * @function channels
   * @memberof Twilio.FlexApi.V1.InteractionContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext}
   */
  /* jshint ignore:end */
  function InteractionChannelListInstance(sid) {
    return InteractionChannelListInstance.get(sid);
  }

  InteractionChannelListInstance._version = version;
  // Path Solution
  InteractionChannelListInstance._solution = {interactionSid: interactionSid};
  InteractionChannelListInstance._uri = `/Interactions/${interactionSid}/Channels`;
  /* jshint ignore:start */
  /**
   * Streams InteractionChannelInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists InteractionChannelInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of InteractionChannelInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new InteractionChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of InteractionChannelInstance records from the
   * API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new InteractionChannelPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a interaction_channel
   *
   * @function get
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @param {string} sid - The unique string that identifies the resource
   *
   * @returns {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext}
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.get = function get(sid) {
    return new InteractionChannelContext(this._version, this._solution.interactionSid, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  InteractionChannelListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  InteractionChannelListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return InteractionChannelListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the InteractionChannelPage
 *
 * @constructor Twilio.FlexApi.V1.InteractionContext.InteractionChannelPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {InteractionChannelSolution} solution - Path solution
 *
 * @returns InteractionChannelPage
 */
/* jshint ignore:end */
InteractionChannelPage = function InteractionChannelPage(version, response,
                                                          solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(InteractionChannelPage.prototype, Page.prototype);
InteractionChannelPage.prototype.constructor = InteractionChannelPage;

/* jshint ignore:start */
/**
 * Build an instance of InteractionChannelInstance
 *
 * @function getInstance
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelPage#
 *
 * @param {InteractionChannelPayload} payload - Payload response from the API
 *
 * @returns InteractionChannelInstance
 */
/* jshint ignore:end */
InteractionChannelPage.prototype.getInstance = function getInstance(payload) {
  return new InteractionChannelInstance(this._version, payload, this._solution.interactionSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
InteractionChannelPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

InteractionChannelPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the InteractionChannelContext
 *
 * @constructor Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance
 *
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} interactionSid -
 *          The unique string that identifies the resource.
 * @property {interaction_channel.type} type - The Interaction Channel's type.
 * @property {interaction_channel.channel_status} status -
 *          The status of this channel.
 * @property {number} errorCode - The Twilio error code for a failed channel.
 * @property {string} errorMessage - The error message for a failed channel.
 * @property {string} url - The url
 * @property {string} links - The links
 *
 * @param {V1} version - Version of the resource
 * @param {InteractionChannelPayload} payload - The instance payload
 * @param {sid} interactionSid - The unique string that identifies the resource.
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
InteractionChannelInstance = function InteractionChannelInstance(version,
    payload, interactionSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.interactionSid = payload.interaction_sid; // jshint ignore:line
  this.type = payload.type; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.errorCode = deserialize.integer(payload.error_code); // jshint ignore:line
  this.errorMessage = payload.error_message; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {interactionSid: interactionSid, sid: sid || this.sid, };
};

Object.defineProperty(InteractionChannelInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new InteractionChannelContext(
          this._version,
          this._solution.interactionSid,
          this._solution.sid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a InteractionChannelInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed InteractionChannelInstance
 */
/* jshint ignore:end */
InteractionChannelInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a InteractionChannelInstance
 *
 * @function update
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance#
 *
 * @param {object} opts - Options for request
 * @param {interaction_channel.status} opts.status -
 *          Required. The Interaction channels's status
 * @param {object} [opts.routing] - Optional. The state of associated tasks.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed InteractionChannelInstance
 */
/* jshint ignore:end */
InteractionChannelInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the invites
 *
 * @function invites
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance#
 *
 * @returns {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext.InteractionChannelInviteList}
 */
/* jshint ignore:end */
InteractionChannelInstance.prototype.invites = function invites() {
  return this._proxy.invites;
};

/* jshint ignore:start */
/**
 * Access the participants
 *
 * @function participants
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance#
 *
 * @returns {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext.InteractionChannelParticipantList}
 */
/* jshint ignore:end */
InteractionChannelInstance.prototype.participants = function participants() {
  return this._proxy.participants;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
InteractionChannelInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

InteractionChannelInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the InteractionChannelContext
 *
 * @constructor Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext
 *
 * @property {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext.InteractionChannelInviteList} invites -
 *          invites resource
 * @property {Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext.InteractionChannelParticipantList} participants -
 *          participants resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} interactionSid - The unique string that identifies the resource
 * @param {sid} sid - The unique string that identifies the resource
 */
/* jshint ignore:end */
InteractionChannelContext = function InteractionChannelContext(version,
    interactionSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {interactionSid: interactionSid, sid: sid, };
  this._uri = `/Interactions/${interactionSid}/Channels/${sid}`;

  // Dependents
  this._invites = undefined;
  this._participants = undefined;
};

/* jshint ignore:start */
/**
 * fetch a InteractionChannelInstance
 *
 * @function fetch
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed InteractionChannelInstance
 */
/* jshint ignore:end */
InteractionChannelContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new InteractionChannelInstance(
      this._version,
      payload,
      this._solution.interactionSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a InteractionChannelInstance
 *
 * @function update
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext#
 *
 * @param {object} opts - Options for request
 * @param {interaction_channel.status} opts.status -
 *          Required. The Interaction channels's status
 * @param {object} [opts.routing] - Optional. The state of associated tasks.
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed InteractionChannelInstance
 */
/* jshint ignore:end */
InteractionChannelContext.prototype.update = function update(opts, callback) {
  if (_.isUndefined(opts)) {
    throw new Error('Required parameter "opts" missing.');
  }
  if (_.isUndefined(opts['status'])) {
    throw new Error('Required parameter "opts[\'status\']" missing.');
  }

  var deferred = Q.defer();
  var data = values.of({
    'Status': _.get(opts, 'status'),
    'Routing': serialize.object(_.get(opts, 'routing'))
  });

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new InteractionChannelInstance(
      this._version,
      payload,
      this._solution.interactionSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(InteractionChannelContext.prototype,
  'invites', {
    get: function() {
      if (!this._invites) {
        this._invites = new InteractionChannelInviteList(
          this._version,
          this._solution.interactionSid,
          this._solution.sid
        );
      }
      return this._invites;
    }
});

Object.defineProperty(InteractionChannelContext.prototype,
  'participants', {
    get: function() {
      if (!this._participants) {
        this._participants = new InteractionChannelParticipantList(
          this._version,
          this._solution.interactionSid,
          this._solution.sid
        );
      }
      return this._participants;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.FlexApi.V1.InteractionContext.InteractionChannelContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
InteractionChannelContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

InteractionChannelContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  InteractionChannelList: InteractionChannelList,
  InteractionChannelPage: InteractionChannelPage,
  InteractionChannelInstance: InteractionChannelInstance,
  InteractionChannelContext: InteractionChannelContext
};
