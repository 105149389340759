import {
    SET_PRIMARY_FONT_FACE,
    SET_SECONDARY_FONT_FACE
} from "../actionTypes";


const initialState = {
       primaryFontFace: {},
       secondaryFontFace: {},   
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRIMARY_FONT_FACE:       
  
            let primaryFontLoc = action.loadedFontFace;
            console.log(action.loadedFontFace, "LOCCCC")
          
            return {
                ...state,
                primaryFontFace: primaryFontLoc
            };

          

           

        case SET_SECONDARY_FONT_FACE:
            let secondaryFontLoc = action.location;
            let  secondaryFontFaceObj = new FontFace("SecondaryFont", 'url(' + secondaryFontLoc + ')');

            secondaryFontFaceObj.load().then(function (loadedFont) {
                document.fonts.add(loadedFont);
             
                return {
                    ...state,
                    secondaryFontFace: loadedFont
                };
            }).catch(function (error) {
                console.log('Failed to load font: ' + error)
            })

          
        default:
            return state;
    }
};

export default reducer;