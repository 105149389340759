import React, { Component } from 'react';
import closeIcon from './../assets/close-icon.png';


class Header extends Component {

  render() {
    return (
      <div style={{
        backgroundColor: this.props.design.top_buttons_background_color
      }} className="sc-header">
        <img className="sc-header--img" src={this.props.imageUrl} alt="" />
        <div style={{
        color: this.props.design.top_buttons_text_color
      }} className="sc-header--team-name"> {this.props.teamName} </div>
        <div  className="sc-header--close-button" style={{display:'flex',justifyContent:'center',alignItems:'center'}} onClick={this.props.onClose}>
          {/* <img src={closeIcon} alt="" /> */}
          <i style={{
        color: this.props.design.top_buttons_text_color,
        fontSize:25     
      }} class="fa fa-times" aria-hidden="true"></i>

        </div>
      </div>
    );
  }
}

export default Header;
