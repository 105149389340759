'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../../base/Page');  /* jshint ignore:line */
var serialize = require(
    '../../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../../base/values');  /* jshint ignore:line */

var WorkerStatisticsList;
var WorkerStatisticsPage;
var WorkerStatisticsInstance;
var WorkerStatisticsContext;

/* jshint ignore:start */
/**
 * Initialize the WorkerStatisticsList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @param {string} workerSid -
 *          The SID of the Worker that contains the WorkerChannel
 */
/* jshint ignore:end */
WorkerStatisticsList = function WorkerStatisticsList(version, workspaceSid,
                                                      workerSid) {
  /* jshint ignore:start */
  /**
   * @function statistics
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsContext}
   */
  /* jshint ignore:end */
  function WorkerStatisticsListInstance(sid) {
    return WorkerStatisticsListInstance.get(sid);
  }

  WorkerStatisticsListInstance._version = version;
  // Path Solution
  WorkerStatisticsListInstance._solution = {workspaceSid: workspaceSid, workerSid: workerSid};
  /* jshint ignore:start */
  /**
   * Constructs a worker_statistics
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsList#
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsContext}
   */
  /* jshint ignore:end */
  WorkerStatisticsListInstance.get = function get() {
    return new WorkerStatisticsContext(
      this._version,
      this._solution.workspaceSid,
      this._solution.workerSid
    );
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WorkerStatisticsListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WorkerStatisticsListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return WorkerStatisticsListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WorkerStatisticsPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WorkerStatisticsSolution} solution - Path solution
 *
 * @returns WorkerStatisticsPage
 */
/* jshint ignore:end */
WorkerStatisticsPage = function WorkerStatisticsPage(version, response,
                                                      solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WorkerStatisticsPage.prototype, Page.prototype);
WorkerStatisticsPage.prototype.constructor = WorkerStatisticsPage;

/* jshint ignore:start */
/**
 * Build an instance of WorkerStatisticsInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsPage#
 *
 * @param {WorkerStatisticsPayload} payload - Payload response from the API
 *
 * @returns WorkerStatisticsInstance
 */
/* jshint ignore:end */
WorkerStatisticsPage.prototype.getInstance = function getInstance(payload) {
  return new WorkerStatisticsInstance(
    this._version,
    payload,
    this._solution.workspaceSid,
    this._solution.workerSid
  );
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerStatisticsPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerStatisticsPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {object} cumulative -
 *          An object that contains the cumulative statistics for the Worker
 * @property {string} workerSid -
 *          The SID of the Worker that contains the WorkerChannel
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @property {string} url -
 *          The absolute URL of the WorkerChannel statistics resource
 *
 * @param {V1} version - Version of the resource
 * @param {WorkerStatisticsPayload} payload - The instance payload
 * @param {sid} workspaceSid -
 *          The SID of the Workspace that contains the WorkerChannel
 * @param {sid} workerSid - The SID of the Worker that contains the WorkerChannel
 */
/* jshint ignore:end */
WorkerStatisticsInstance = function WorkerStatisticsInstance(version, payload,
    workspaceSid, workerSid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.cumulative = payload.cumulative; // jshint ignore:line
  this.workerSid = payload.worker_sid; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, };
};

Object.defineProperty(WorkerStatisticsInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new WorkerStatisticsContext(
          this._version,
          this._solution.workspaceSid,
          this._solution.workerSid
        );
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a WorkerStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.minutes] -
 *          Only calculate statistics since this many minutes in the past
 * @param {Date} [opts.startDate] -
 *          Only calculate statistics from on or after this date
 * @param {Date} [opts.endDate] -
 *          Only include usage that occurred on or before this date
 * @param {string} [opts.taskChannel] -
 *          Only calculate statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerStatisticsInstance
 */
/* jshint ignore:end */
WorkerStatisticsInstance.prototype.fetch = function fetch(opts, callback) {
  return this._proxy.fetch(opts, callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerStatisticsInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerStatisticsInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerStatisticsContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid -
 *          The SID of the Workspace with the WorkerChannel to fetch
 * @param {sid} workerSid - The SID of the Worker with the WorkerChannel to fetch
 */
/* jshint ignore:end */
WorkerStatisticsContext = function WorkerStatisticsContext(version,
                                                            workspaceSid,
                                                            workerSid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, workerSid: workerSid, };
  this._uri = `/Workspaces/${workspaceSid}/Workers/${workerSid}/Statistics`;
};

/* jshint ignore:start */
/**
 * fetch a WorkerStatisticsInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsContext#
 *
 * @param {object} [opts] - Options for request
 * @param {number} [opts.minutes] -
 *          Only calculate statistics since this many minutes in the past
 * @param {Date} [opts.startDate] -
 *          Only calculate statistics from on or after this date
 * @param {Date} [opts.endDate] -
 *          Only include usage that occurred on or before this date
 * @param {string} [opts.taskChannel] -
 *          Only calculate statistics on this TaskChannel
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerStatisticsInstance
 */
/* jshint ignore:end */
WorkerStatisticsContext.prototype.fetch = function fetch(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'Minutes': _.get(opts, 'minutes'),
    'StartDate': serialize.iso8601DateTime(_.get(opts, 'startDate')),
    'EndDate': serialize.iso8601DateTime(_.get(opts, 'endDate')),
    'TaskChannel': _.get(opts, 'taskChannel')
  });

  var promise = this._version.fetch({uri: this._uri, method: 'GET', params: data});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkerStatisticsInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.workerSid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerStatisticsContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

WorkerStatisticsContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WorkerStatisticsList: WorkerStatisticsList,
  WorkerStatisticsPage: WorkerStatisticsPage,
  WorkerStatisticsInstance: WorkerStatisticsInstance,
  WorkerStatisticsContext: WorkerStatisticsContext
};
