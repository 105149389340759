'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var CompositionList;
var CompositionPage;
var CompositionInstance;
var CompositionContext;

/* jshint ignore:start */
/**
 * Initialize the CompositionList
 *
 * @constructor Twilio.Video.V1.CompositionList
 *
 * @param {Twilio.Video.V1} version - Version of the resource
 */
/* jshint ignore:end */
CompositionList = function CompositionList(version) {
  /* jshint ignore:start */
  /**
   * @function compositions
   * @memberof Twilio.Video.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Video.V1.CompositionContext}
   */
  /* jshint ignore:end */
  function CompositionListInstance(sid) {
    return CompositionListInstance.get(sid);
  }

  CompositionListInstance._version = version;
  // Path Solution
  CompositionListInstance._solution = {};
  CompositionListInstance._uri = `/Compositions`;
  /* jshint ignore:start */
  /**
   * Streams CompositionInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {object} [opts] - Options for request
   * @param {composition.status} [opts.status] -
   *          Read only Composition resources with this status
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Composition resources created on or after this {@link https://en.wikipedia.org/wiki/ISO_8601|ISO 8601} date-time with time zone
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Composition resources created before this ISO 8601 date-time with time zone
   * @param {string} [opts.roomSid] -
   *          Read only Composition resources with this Room SID
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  CompositionListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists CompositionInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {object} [opts] - Options for request
   * @param {composition.status} [opts.status] -
   *          Read only Composition resources with this status
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Composition resources created on or after this {@link https://en.wikipedia.org/wiki/ISO_8601|ISO 8601} date-time with time zone
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Composition resources created before this ISO 8601 date-time with time zone
   * @param {string} [opts.roomSid] -
   *          Read only Composition resources with this Room SID
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CompositionListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of CompositionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {object} [opts] - Options for request
   * @param {composition.status} [opts.status] -
   *          Read only Composition resources with this status
   * @param {Date} [opts.dateCreatedAfter] -
   *          Read only Composition resources created on or after this {@link https://en.wikipedia.org/wiki/ISO_8601|ISO 8601} date-time with time zone
   * @param {Date} [opts.dateCreatedBefore] -
   *          Read only Composition resources created before this ISO 8601 date-time with time zone
   * @param {string} [opts.roomSid] -
   *          Read only Composition resources with this Room SID
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CompositionListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'Status': _.get(opts, 'status'),
      'DateCreatedAfter': serialize.iso8601DateTime(_.get(opts, 'dateCreatedAfter')),
      'DateCreatedBefore': serialize.iso8601DateTime(_.get(opts, 'dateCreatedBefore')),
      'RoomSid': _.get(opts, 'roomSid'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new CompositionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of CompositionInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  CompositionListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new CompositionPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a CompositionInstance
   *
   * @function create
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.roomSid -
   *          The SID of the Group Room with the media tracks to be used as composition sources
   * @param {object} [opts.videoLayout] -
   *          An object that describes the video layout of the composition
   * @param {string|list} [opts.audioSources] -
   *          An array of track names from the same group room to merge
   * @param {string|list} [opts.audioSourcesExcluded] -
   *          An array of track names to exclude
   * @param {string} [opts.resolution] -
   *          A string that describes the columns (width) and rows (height) of the generated composed video in pixels
   * @param {composition.format} [opts.format] -
   *          The container format of the composition's media files
   * @param {string} [opts.statusCallback] -
   *          The URL we should call to send status information to your application
   * @param {string} [opts.statusCallbackMethod] -
   *          The HTTP method we should use to call status_callback
   * @param {boolean} [opts.trim] -
   *          Whether to clip the intervals where there is no active media in the composition
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed CompositionInstance
   */
  /* jshint ignore:end */
  CompositionListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['roomSid'])) {
      throw new Error('Required parameter "opts[\'roomSid\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'RoomSid': _.get(opts, 'roomSid'),
      'VideoLayout': serialize.object(_.get(opts, 'videoLayout')),
      'AudioSources': serialize.map(_.get(opts, 'audioSources'), function(e) { return e; }),
      'AudioSourcesExcluded': serialize.map(_.get(opts, 'audioSourcesExcluded'), function(e) { return e; }),
      'Resolution': _.get(opts, 'resolution'),
      'Format': _.get(opts, 'format'),
      'StatusCallback': _.get(opts, 'statusCallback'),
      'StatusCallbackMethod': _.get(opts, 'statusCallbackMethod'),
      'Trim': serialize.bool(_.get(opts, 'trim'))
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new CompositionInstance(this._version, payload, this._solution.sid));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a composition
   *
   * @function get
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @param {string} sid - The SID that identifies the resource to fetch
   *
   * @returns {Twilio.Video.V1.CompositionContext}
   */
  /* jshint ignore:end */
  CompositionListInstance.get = function get(sid) {
    return new CompositionContext(this._version, sid);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Video.V1.CompositionList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  CompositionListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  CompositionListInstance[util.inspect.custom] = function inspect(depth, options)
      {
    return util.inspect(this.toJSON(), options);
  };

  return CompositionListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the CompositionPage
 *
 * @constructor Twilio.Video.V1.CompositionPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {CompositionSolution} solution - Path solution
 *
 * @returns CompositionPage
 */
/* jshint ignore:end */
CompositionPage = function CompositionPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(CompositionPage.prototype, Page.prototype);
CompositionPage.prototype.constructor = CompositionPage;

/* jshint ignore:start */
/**
 * Build an instance of CompositionInstance
 *
 * @function getInstance
 * @memberof Twilio.Video.V1.CompositionPage#
 *
 * @param {CompositionPayload} payload - Payload response from the API
 *
 * @returns CompositionInstance
 */
/* jshint ignore:end */
CompositionPage.prototype.getInstance = function getInstance(payload) {
  return new CompositionInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CompositionPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CompositionContext
 *
 * @constructor Twilio.Video.V1.CompositionInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {composition.status} status - The status of the composition
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateCompleted - Date when the media processing task finished
 * @property {Date} dateDeleted -
 *          The ISO 8601 date and time in GMT when the composition generated media was deleted
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} roomSid -
 *          The SID of the Group Room that generated the audio and video tracks used in the composition
 * @property {string} audioSources -
 *          The array of track names to include in the composition
 * @property {string} audioSourcesExcluded -
 *          The array of track names to exclude from the composition
 * @property {object} videoLayout -
 *          An object that describes the video layout of the composition
 * @property {string} resolution -
 *          The dimensions of the video image in pixels expressed as columns (width) and rows (height)
 * @property {boolean} trim - Whether to remove intervals with no media
 * @property {composition.format} format -
 *          The container format of the composition's media files as specified in the POST request that created the Composition resource
 * @property {number} bitrate - The average bit rate of the composition's media
 * @property {number} size - The size of the composed media file in bytes
 * @property {number} duration -
 *          The duration of the composition's media file in seconds
 * @property {string} mediaExternalLocation -
 *          The URL of the media file associated with the composition when stored externally
 * @property {string} statusCallback -
 *          The URL called to send status information on every composition event.
 * @property {string} statusCallbackMethod -
 *          The HTTP method used to call `status_callback`
 * @property {string} url - The absolute URL of the resource
 * @property {string} links -
 *          The URL of the media file associated with the composition
 *
 * @param {V1} version - Version of the resource
 * @param {CompositionPayload} payload - The instance payload
 * @param {sid} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
CompositionInstance = function CompositionInstance(version, payload, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.status = payload.status; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateCompleted = deserialize.iso8601DateTime(payload.date_completed); // jshint ignore:line
  this.dateDeleted = deserialize.iso8601DateTime(payload.date_deleted); // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.roomSid = payload.room_sid; // jshint ignore:line
  this.audioSources = payload.audio_sources; // jshint ignore:line
  this.audioSourcesExcluded = payload.audio_sources_excluded; // jshint ignore:line
  this.videoLayout = payload.video_layout; // jshint ignore:line
  this.resolution = payload.resolution; // jshint ignore:line
  this.trim = payload.trim; // jshint ignore:line
  this.format = payload.format; // jshint ignore:line
  this.bitrate = deserialize.integer(payload.bitrate); // jshint ignore:line
  this.size = deserialize.integer(payload.size); // jshint ignore:line
  this.duration = deserialize.integer(payload.duration); // jshint ignore:line
  this.mediaExternalLocation = payload.media_external_location; // jshint ignore:line
  this.statusCallback = payload.status_callback; // jshint ignore:line
  this.statusCallbackMethod = payload.status_callback_method; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {sid: sid || this.sid, };
};

Object.defineProperty(CompositionInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new CompositionContext(this._version, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a CompositionInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.CompositionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionInstance
 */
/* jshint ignore:end */
CompositionInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a CompositionInstance
 *
 * @function remove
 * @memberof Twilio.Video.V1.CompositionInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionInstance
 */
/* jshint ignore:end */
CompositionInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

CompositionInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the CompositionContext
 *
 * @constructor Twilio.Video.V1.CompositionContext
 *
 * @param {V1} version - Version of the resource
 * @param {sid} sid - The SID that identifies the resource to fetch
 */
/* jshint ignore:end */
CompositionContext = function CompositionContext(version, sid) {
  this._version = version;

  // Path Solution
  this._solution = {sid: sid, };
  this._uri = `/Compositions/${sid}`;
};

/* jshint ignore:start */
/**
 * fetch a CompositionInstance
 *
 * @function fetch
 * @memberof Twilio.Video.V1.CompositionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionInstance
 */
/* jshint ignore:end */
CompositionContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new CompositionInstance(this._version, payload, this._solution.sid));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a CompositionInstance
 *
 * @function remove
 * @memberof Twilio.Video.V1.CompositionContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed CompositionInstance
 */
/* jshint ignore:end */
CompositionContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Video.V1.CompositionContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
CompositionContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

CompositionContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  CompositionList: CompositionList,
  CompositionPage: CompositionPage,
  CompositionInstance: CompositionInstance,
  CompositionContext: CompositionContext
};
