'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SafelistList;
var SafelistPage;
var SafelistInstance;
var SafelistContext;

/* jshint ignore:start */
/**
 * Initialize the SafelistList
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Verify.V2.SafelistList
 *
 * @param {Twilio.Verify.V2} version - Version of the resource
 */
/* jshint ignore:end */
SafelistList = function SafelistList(version) {
  /* jshint ignore:start */
  /**
   * @function safelist
   * @memberof Twilio.Verify.V2#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Verify.V2.SafelistContext}
   */
  /* jshint ignore:end */
  function SafelistListInstance(sid) {
    return SafelistListInstance.get(sid);
  }

  SafelistListInstance._version = version;
  // Path Solution
  SafelistListInstance._solution = {};
  SafelistListInstance._uri = `/SafeList/Numbers`;
  /* jshint ignore:start */
  /**
   * create a SafelistInstance
   *
   * @function create
   * @memberof Twilio.Verify.V2.SafelistList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.phoneNumber - The phone number to be added in SafeList.
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed SafelistInstance
   */
  /* jshint ignore:end */
  SafelistListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['phoneNumber'])) {
      throw new Error('Required parameter "opts[\'phoneNumber\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({'PhoneNumber': _.get(opts, 'phoneNumber')});

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new SafelistInstance(this._version, payload, this._solution.phoneNumber));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a safelist
   *
   * @function get
   * @memberof Twilio.Verify.V2.SafelistList#
   *
   * @param {string} phoneNumber - The phone number to be fetched from SafeList.
   *
   * @returns {Twilio.Verify.V2.SafelistContext}
   */
  /* jshint ignore:end */
  SafelistListInstance.get = function get(phoneNumber) {
    return new SafelistContext(this._version, phoneNumber);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Verify.V2.SafelistList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SafelistListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SafelistListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return SafelistListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SafelistPage
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Verify.V2.SafelistPage
 *
 * @param {V2} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SafelistSolution} solution - Path solution
 *
 * @returns SafelistPage
 */
/* jshint ignore:end */
SafelistPage = function SafelistPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SafelistPage.prototype, Page.prototype);
SafelistPage.prototype.constructor = SafelistPage;

/* jshint ignore:start */
/**
 * Build an instance of SafelistInstance
 *
 * @function getInstance
 * @memberof Twilio.Verify.V2.SafelistPage#
 *
 * @param {SafelistPayload} payload - Payload response from the API
 *
 * @returns SafelistInstance
 */
/* jshint ignore:end */
SafelistPage.prototype.getInstance = function getInstance(payload) {
  return new SafelistInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.SafelistPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SafelistPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SafelistPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SafelistContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Verify.V2.SafelistInstance
 *
 * @property {string} sid - The unique string that identifies the resource.
 * @property {string} phoneNumber - The phone number in SafeList.
 * @property {string} url - The absolute URL of the SafeList resource.
 *
 * @param {V2} version - Version of the resource
 * @param {SafelistPayload} payload - The instance payload
 * @param {string} phoneNumber - The phone number to be fetched from SafeList.
 */
/* jshint ignore:end */
SafelistInstance = function SafelistInstance(version, payload, phoneNumber) {
  this._version = version;

  // Marshaled Properties
  this.sid = payload.sid; // jshint ignore:line
  this.phoneNumber = payload.phone_number; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {phoneNumber: phoneNumber || this.phoneNumber, };
};

Object.defineProperty(SafelistInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SafelistContext(this._version, this._solution.phoneNumber);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a SafelistInstance
 *
 * @function fetch
 * @memberof Twilio.Verify.V2.SafelistInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SafelistInstance
 */
/* jshint ignore:end */
SafelistInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * remove a SafelistInstance
 *
 * @function remove
 * @memberof Twilio.Verify.V2.SafelistInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SafelistInstance
 */
/* jshint ignore:end */
SafelistInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.SafelistInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SafelistInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SafelistInstance.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SafelistContext
 *
 * PLEASE NOTE that this class contains beta products that are subject to change.
 * Use them with caution.
 *
 * @constructor Twilio.Verify.V2.SafelistContext
 *
 * @param {V2} version - Version of the resource
 * @param {string} phoneNumber - The phone number to be fetched from SafeList.
 */
/* jshint ignore:end */
SafelistContext = function SafelistContext(version, phoneNumber) {
  this._version = version;

  // Path Solution
  this._solution = {phoneNumber: phoneNumber, };
  this._uri = `/SafeList/Numbers/${phoneNumber}`;
};

/* jshint ignore:start */
/**
 * fetch a SafelistInstance
 *
 * @function fetch
 * @memberof Twilio.Verify.V2.SafelistContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SafelistInstance
 */
/* jshint ignore:end */
SafelistContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SafelistInstance(this._version, payload, this._solution.phoneNumber));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a SafelistInstance
 *
 * @function remove
 * @memberof Twilio.Verify.V2.SafelistContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SafelistInstance
 */
/* jshint ignore:end */
SafelistContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Verify.V2.SafelistContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SafelistContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SafelistContext.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SafelistList: SafelistList,
  SafelistPage: SafelistPage,
  SafelistInstance: SafelistInstance,
  SafelistContext: SafelistContext
};
