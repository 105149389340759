import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import { FontLetterSpacing } from './../handlers/utils';
import { style } from "../../assets/style";
import i18n from "../i18n";
import StyledText from "../components/styledText"



const SelectedPayment = (props) => {
    return(
        <View>
            
            <TouchableOpacity onPress={() => props.togglePaymentModal()}>
                <View style={{flexDirection:"row", alignItems:'center', justifyContent:'center'}} >
                    <View style={style.checkoutIconWrap}>
                        <i style={{
                        color: props.list_view_text_color,
                        fontWeight: "bold",
                        }} class={props.icon}></i>
                    </View>
                    <View style={{ flex: 4, justifyContent: "center",flexDirection:'column' }}>
                        <StyledText 
                            font_color={props.design.list_view_text_color}
                            font_weight={props.design.list_view_text_font_weight}
                            font_style={props.design.list_view_text_font_style}
                            text_transform={props.design.list_view_text_font_transform}
                            font_family={props.design.list_view_text_font_family}
                            font_size={props.design.list_view_text_font_size?? 12}
                            letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                        style={{
                           
                              marginLeft:5,
                               marginBottom:2, 
                             
                               }} >
                            Payment Mode <i style={{color: props.list_view_text_color}} class="fas fa-chevron-down"></i>
                            </StyledText>
                        <StyledText 
                          font_color={props.design.list_view_text_color}
                          font_weight={props.design.list_view_text_font_weight}
                          font_style={props.design.list_view_text_font_style}
                          text_transform={props.design.list_view_text_font_transform}
                          font_family={props.design.list_view_text_font_family}
                          font_size={props.design.list_view_text_font_size?? 12}
                          letter_spacing={FontLetterSpacing(props.design.custom_font.primary_letter_space)}
                        style={{ 
                            marginLeft:5, 
                            }}>
                        {props.label ? i18n.t(props.label) : ""} {props.label_suf}
                        </StyledText>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export default SelectedPayment