import axios from "axios";
import { API_URL } from "../constants";
import {AsyncStorage} from 'react-native'
import {connect} from 'react-redux'

export const performRequest = async (method, url, data, login = true) => {
  const config = {
    method,
    url,
    baseURL: API_URL,
  };
  if (
    method == "put" ||
    method == "patch" ||
    method == "post"
  ) {
    config.data = data;
  }
  config.headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  if (login) {
    let token = await AsyncStorage.getItem('token')


    config.headers.Authorization = "Bearer "+token;
  }
  return axios.request(config);
  // Add a request interceptor
};

// axios.interceptors.response.use((response) => {
//     return response
// }, function (error) {

//     const {config, response: {status}} = error
//     if (status === 403) {
//         // logout

//     }
//     return Promise.reject(error)
// });
