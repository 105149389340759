import React, {Component} from 'react';
import {View, Text} from "react-native";

import {Icon} from 'native-base'
import {style} from "../../assets/style";





class MenuButton extends React.Component{

    render(){
        return(
            <View style={[style.fontButton,{backgroundColor:this.props.backgroundColor,border:this.props.border_color}]}>
                <i style={{color: this.props.color, fontSize:25}} class={this.props.showTopButtons?"fas fa-times" : `fas fa-bars`}></i>
            </View>
        );
    }
};
export default MenuButton;


