'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../base/Page');  /* jshint ignore:line */
var deserialize = require(
    '../../../base/deserialize');  /* jshint ignore:line */
var values = require('../../../base/values');  /* jshint ignore:line */

var SecondaryAuthTokenList;
var SecondaryAuthTokenPage;
var SecondaryAuthTokenInstance;
var SecondaryAuthTokenContext;

/* jshint ignore:start */
/**
 * Initialize the SecondaryAuthTokenList
 *
 * @constructor Twilio.Accounts.V1.SecondaryAuthTokenList
 *
 * @param {Twilio.Accounts.V1} version - Version of the resource
 */
/* jshint ignore:end */
SecondaryAuthTokenList = function SecondaryAuthTokenList(version) {
  /* jshint ignore:start */
  /**
   * @function secondaryAuthToken
   * @memberof Twilio.Accounts.V1#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Accounts.V1.SecondaryAuthTokenContext}
   */
  /* jshint ignore:end */
  function SecondaryAuthTokenListInstance(sid) {
    return SecondaryAuthTokenListInstance.get(sid);
  }

  SecondaryAuthTokenListInstance._version = version;
  // Path Solution
  SecondaryAuthTokenListInstance._solution = {};
  /* jshint ignore:start */
  /**
   * Constructs a secondary_auth_token
   *
   * @function get
   * @memberof Twilio.Accounts.V1.SecondaryAuthTokenList#
   *
   * @returns {Twilio.Accounts.V1.SecondaryAuthTokenContext}
   */
  /* jshint ignore:end */
  SecondaryAuthTokenListInstance.get = function get() {
    return new SecondaryAuthTokenContext(this._version);
  };

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Accounts.V1.SecondaryAuthTokenList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  SecondaryAuthTokenListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  SecondaryAuthTokenListInstance[util.inspect.custom] = function inspect(depth,
      options) {
    return util.inspect(this.toJSON(), options);
  };

  return SecondaryAuthTokenListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the SecondaryAuthTokenPage
 *
 * @constructor Twilio.Accounts.V1.SecondaryAuthTokenPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {SecondaryAuthTokenSolution} solution - Path solution
 *
 * @returns SecondaryAuthTokenPage
 */
/* jshint ignore:end */
SecondaryAuthTokenPage = function SecondaryAuthTokenPage(version, response,
                                                          solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(SecondaryAuthTokenPage.prototype, Page.prototype);
SecondaryAuthTokenPage.prototype.constructor = SecondaryAuthTokenPage;

/* jshint ignore:start */
/**
 * Build an instance of SecondaryAuthTokenInstance
 *
 * @function getInstance
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenPage#
 *
 * @param {SecondaryAuthTokenPayload} payload - Payload response from the API
 *
 * @returns SecondaryAuthTokenInstance
 */
/* jshint ignore:end */
SecondaryAuthTokenPage.prototype.getInstance = function getInstance(payload) {
  return new SecondaryAuthTokenInstance(this._version, payload);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
SecondaryAuthTokenPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SecondaryAuthTokenPage.prototype[util.inspect.custom] = function inspect(depth,
    options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SecondaryAuthTokenContext
 *
 * @constructor Twilio.Accounts.V1.SecondaryAuthTokenInstance
 *
 * @property {string} accountSid -
 *          The SID of the Account that the secondary Auth Token was created for
 * @property {Date} dateCreated -
 *          The ISO 8601 formatted date and time in UTC when the resource was created
 * @property {Date} dateUpdated -
 *          The ISO 8601 formatted date and time in UTC when the resource was last updated
 * @property {string} secondaryAuthToken - The generated secondary Auth Token
 * @property {string} url -
 *          The URI for this resource, relative to `https://accounts.twilio.com`
 *
 * @param {V1} version - Version of the resource
 * @param {SecondaryAuthTokenPayload} payload - The instance payload
 */
/* jshint ignore:end */
SecondaryAuthTokenInstance = function SecondaryAuthTokenInstance(version,
    payload) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.secondaryAuthToken = payload.secondary_auth_token; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {};
};

Object.defineProperty(SecondaryAuthTokenInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new SecondaryAuthTokenContext(this._version);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * create a SecondaryAuthTokenInstance
 *
 * @function create
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SecondaryAuthTokenInstance
 */
/* jshint ignore:end */
SecondaryAuthTokenInstance.prototype.create = function create(callback) {
  return this._proxy.create(callback);
};

/* jshint ignore:start */
/**
 * remove a SecondaryAuthTokenInstance
 *
 * @function remove
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SecondaryAuthTokenInstance
 */
/* jshint ignore:end */
SecondaryAuthTokenInstance.prototype.remove = function remove(callback) {
  return this._proxy.remove(callback);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
SecondaryAuthTokenInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

SecondaryAuthTokenInstance.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the SecondaryAuthTokenContext
 *
 * @constructor Twilio.Accounts.V1.SecondaryAuthTokenContext
 *
 * @param {V1} version - Version of the resource
 */
/* jshint ignore:end */
SecondaryAuthTokenContext = function SecondaryAuthTokenContext(version) {
  this._version = version;

  // Path Solution
  this._solution = {};
  this._uri = `/AuthTokens/Secondary`;
};

/* jshint ignore:start */
/**
 * create a SecondaryAuthTokenInstance
 *
 * @function create
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SecondaryAuthTokenInstance
 */
/* jshint ignore:end */
SecondaryAuthTokenContext.prototype.create = function create(callback) {
  var deferred = Q.defer();
  var promise = this._version.create({uri: this._uri, method: 'POST'});

  promise = promise.then(function(payload) {
    deferred.resolve(new SecondaryAuthTokenInstance(this._version, payload));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a SecondaryAuthTokenInstance
 *
 * @function remove
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed SecondaryAuthTokenInstance
 */
/* jshint ignore:end */
SecondaryAuthTokenContext.prototype.remove = function remove(callback) {
  var deferred = Q.defer();
  var promise = this._version.remove({uri: this._uri, method: 'DELETE'});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Accounts.V1.SecondaryAuthTokenContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
SecondaryAuthTokenContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

SecondaryAuthTokenContext.prototype[util.inspect.custom] = function
    inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  SecondaryAuthTokenList: SecondaryAuthTokenList,
  SecondaryAuthTokenPage: SecondaryAuthTokenPage,
  SecondaryAuthTokenInstance: SecondaryAuthTokenInstance,
  SecondaryAuthTokenContext: SecondaryAuthTokenContext
};
