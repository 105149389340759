'use strict';

/* jshint ignore:start */
/**
 * This code was generated by
 * \ / _    _  _|   _  _
 *  | (_)\/(_)(_|\/| |(/_  v1.0.0
 *       /       /
 */
/* jshint ignore:end */

var Q = require('q');  /* jshint ignore:line */
var _ = require('lodash');  /* jshint ignore:line */
var util = require('util');  /* jshint ignore:line */
var Page = require('../../../../base/Page');  /* jshint ignore:line */
var ReservationList = require('./worker/reservation').ReservationList;
var WorkerChannelList = require('./worker/workerChannel').WorkerChannelList;
var WorkerStatisticsList = require(
    './worker/workerStatistics').WorkerStatisticsList;
var WorkersCumulativeStatisticsList = require(
    './worker/workersCumulativeStatistics').WorkersCumulativeStatisticsList;
var WorkersRealTimeStatisticsList = require(
    './worker/workersRealTimeStatistics').WorkersRealTimeStatisticsList;
var WorkersStatisticsList = require(
    './worker/workersStatistics').WorkersStatisticsList;
var deserialize = require(
    '../../../../base/deserialize');  /* jshint ignore:line */
var serialize = require('../../../../base/serialize');  /* jshint ignore:line */
var values = require('../../../../base/values');  /* jshint ignore:line */

var WorkerList;
var WorkerPage;
var WorkerInstance;
var WorkerContext;

/* jshint ignore:start */
/**
 * Initialize the WorkerList
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerList
 *
 * @param {Twilio.Taskrouter.V1} version - Version of the resource
 * @param {string} workspaceSid - The SID of the Workspace that contains the Worker
 */
/* jshint ignore:end */
WorkerList = function WorkerList(version, workspaceSid) {
  /* jshint ignore:start */
  /**
   * @function workers
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext#
   *
   * @param {string} sid - sid of instance
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext}
   */
  /* jshint ignore:end */
  function WorkerListInstance(sid) {
    return WorkerListInstance.get(sid);
  }

  WorkerListInstance._version = version;
  // Path Solution
  WorkerListInstance._solution = {workspaceSid: workspaceSid};
  WorkerListInstance._uri = `/Workspaces/${workspaceSid}/Workers`;

  // Components
  WorkerListInstance._statistics = undefined;

  /* jshint ignore:start */
  /**
   * Streams WorkerInstance records from the API.
   *
   * This operation lazily loads records as efficiently as possible until the limit
   * is reached.
   *
   * The results are passed into the callback function, so this operation is memory
   * efficient.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function each
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.activityName] -
   *          The activity_name of the Worker resources to read
   * @param {string} [opts.activitySid] -
   *          The activity_sid of the Worker resources to read
   * @param {string} [opts.available] -
   *          Whether to return Worker resources that are available or unavailable
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the Worker resources to read
   * @param {string} [opts.targetWorkersExpression] -
   *          Filter by Workers that would match an expression on a TaskQueue
   * @param {string} [opts.taskQueueName] -
   *          The friendly_name of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.taskQueueSid] -
   *          The SID of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.ordering] - Sorting parameter for Workers
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         each() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no pageSize is defined but a limit is defined,
   *         each() will attempt to read the limit with the most efficient
   *         page size, i.e. min(limit, 1000)
   * @param {Function} [opts.callback] -
   *         Function to process each record. If this and a positional
   *         callback are passed, this one will be used
   * @param {Function} [opts.done] -
   *          Function to be called upon completion of streaming
   * @param {Function} [callback] - Function to process each record
   */
  /* jshint ignore:end */
  WorkerListInstance.each = function each(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    if (opts.callback) {
      callback = opts.callback;
    }
    if (_.isUndefined(callback)) {
      throw new Error('Callback function must be provided');
    }

    var done = false;
    var currentPage = 1;
    var currentResource = 0;
    var limits = this._version.readLimits({
      limit: opts.limit,
      pageSize: opts.pageSize
    });

    function onComplete(error) {
      done = true;
      if (_.isFunction(opts.done)) {
        opts.done(error);
      }
    }

    function fetchNextPage(fn) {
      var promise = fn();
      if (_.isUndefined(promise)) {
        onComplete();
        return;
      }

      promise.then(function(page) {
        _.each(page.instances, function(instance) {
          if (done || (!_.isUndefined(opts.limit) && currentResource >= opts.limit)) {
            done = true;
            return false;
          }

          currentResource++;
          callback(instance, onComplete);
        });

        if (!done) {
          currentPage++;
          fetchNextPage(_.bind(page.nextPage, page));
        } else {
          onComplete();
        }
      });

      promise.catch(onComplete);
    }

    fetchNextPage(_.bind(this.page, this, _.merge(opts, limits)));
  };

  /* jshint ignore:start */
  /**
   * Lists WorkerInstance records from the API as a list.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function list
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.activityName] -
   *          The activity_name of the Worker resources to read
   * @param {string} [opts.activitySid] -
   *          The activity_sid of the Worker resources to read
   * @param {string} [opts.available] -
   *          Whether to return Worker resources that are available or unavailable
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the Worker resources to read
   * @param {string} [opts.targetWorkersExpression] -
   *          Filter by Workers that would match an expression on a TaskQueue
   * @param {string} [opts.taskQueueName] -
   *          The friendly_name of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.taskQueueSid] -
   *          The SID of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.ordering] - Sorting parameter for Workers
   * @param {number} [opts.limit] -
   *         Upper limit for the number of records to return.
   *         list() guarantees never to return more than limit.
   *         Default is no limit
   * @param {number} [opts.pageSize] -
   *         Number of records to fetch per request,
   *         when not set will use the default value of 50 records.
   *         If no page_size is defined but a limit is defined,
   *         list() will attempt to read the limit with the most
   *         efficient page size, i.e. min(limit, 1000)
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerListInstance.list = function list(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};
    var deferred = Q.defer();
    var allResources = [];
    opts.callback = function(resource, done) {
      allResources.push(resource);

      if (!_.isUndefined(opts.limit) && allResources.length === opts.limit) {
        done();
      }
    };

    opts.done = function(error) {
      if (_.isUndefined(error)) {
        deferred.resolve(allResources);
      } else {
        deferred.reject(error);
      }
    };

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    this.each(opts);
    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single page of WorkerInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function page
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {object} [opts] - Options for request
   * @param {string} [opts.activityName] -
   *          The activity_name of the Worker resources to read
   * @param {string} [opts.activitySid] -
   *          The activity_sid of the Worker resources to read
   * @param {string} [opts.available] -
   *          Whether to return Worker resources that are available or unavailable
   * @param {string} [opts.friendlyName] -
   *          The friendly_name of the Worker resources to read
   * @param {string} [opts.targetWorkersExpression] -
   *          Filter by Workers that would match an expression on a TaskQueue
   * @param {string} [opts.taskQueueName] -
   *          The friendly_name of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.taskQueueSid] -
   *          The SID of the TaskQueue that the Workers to read are eligible for
   * @param {string} [opts.ordering] - Sorting parameter for Workers
   * @param {string} [opts.pageToken] - PageToken provided by the API
   * @param {number} [opts.pageNumber] -
   *          Page Number, this value is simply for client state
   * @param {number} [opts.pageSize] - Number of records to return, defaults to 50
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerListInstance.page = function page(opts, callback) {
    if (_.isFunction(opts)) {
      callback = opts;
      opts = {};
    }
    opts = opts || {};

    var deferred = Q.defer();
    var data = values.of({
      'ActivityName': _.get(opts, 'activityName'),
      'ActivitySid': _.get(opts, 'activitySid'),
      'Available': _.get(opts, 'available'),
      'FriendlyName': _.get(opts, 'friendlyName'),
      'TargetWorkersExpression': _.get(opts, 'targetWorkersExpression'),
      'TaskQueueName': _.get(opts, 'taskQueueName'),
      'TaskQueueSid': _.get(opts, 'taskQueueSid'),
      'Ordering': _.get(opts, 'ordering'),
      'PageToken': opts.pageToken,
      'Page': opts.pageNumber,
      'PageSize': opts.pageSize
    });

    var promise = this._version.page({uri: this._uri, method: 'GET', params: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new WorkerPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Retrieve a single target page of WorkerInstance records from the API.
   *
   * The request is executed immediately.
   *
   * If a function is passed as the first argument, it will be used as the callback
   * function.
   *
   * @function getPage
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {string} [targetUrl] - API-generated URL for the requested results page
   * @param {function} [callback] - Callback to handle list of records
   *
   * @returns {Promise} Resolves to a list of records
   */
  /* jshint ignore:end */
  WorkerListInstance.getPage = function getPage(targetUrl, callback) {
    var deferred = Q.defer();

    var promise = this._version._domain.twilio.request({method: 'GET', uri: targetUrl});

    promise = promise.then(function(payload) {
      deferred.resolve(new WorkerPage(this._version, payload, this._solution));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * create a WorkerInstance
   *
   * @function create
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {object} opts - Options for request
   * @param {string} opts.friendlyName - A string to describe the resource
   * @param {string} [opts.activitySid] -
   *          The SID of a valid Activity that describes the new Worker's initial state
   * @param {string} [opts.attributes] -
   *          A valid JSON string that describes the new Worker
   * @param {function} [callback] - Callback to handle processed record
   *
   * @returns {Promise} Resolves to processed WorkerInstance
   */
  /* jshint ignore:end */
  WorkerListInstance.create = function create(opts, callback) {
    if (_.isUndefined(opts)) {
      throw new Error('Required parameter "opts" missing.');
    }
    if (_.isUndefined(opts['friendlyName'])) {
      throw new Error('Required parameter "opts[\'friendlyName\']" missing.');
    }

    var deferred = Q.defer();
    var data = values.of({
      'FriendlyName': _.get(opts, 'friendlyName'),
      'ActivitySid': _.get(opts, 'activitySid'),
      'Attributes': _.get(opts, 'attributes')
    });

    var promise = this._version.create({uri: this._uri, method: 'POST', data: data});

    promise = promise.then(function(payload) {
      deferred.resolve(new WorkerInstance(
        this._version,
        payload,
        this._solution.workspaceSid,
        this._solution.sid
      ));
    }.bind(this));

    promise.catch(function(error) {
      deferred.reject(error);
    });

    if (_.isFunction(callback)) {
      deferred.promise.nodeify(callback);
    }

    return deferred.promise;
  };

  /* jshint ignore:start */
  /**
   * Constructs a worker
   *
   * @function get
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @param {string} sid - The SID of the resource to fetch
   *
   * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext}
   */
  /* jshint ignore:end */
  WorkerListInstance.get = function get(sid) {
    return new WorkerContext(this._version, this._solution.workspaceSid, sid);
  };

  Object.defineProperty(WorkerListInstance,
    'statistics', {
      get: function statistics() {
        if (!this._statistics) {
          this._statistics = new WorkersStatisticsList(this._version, this._solution.workspaceSid);
        }

        return this._statistics;
      }
  });

  /* jshint ignore:start */
  /**
   * Provide a user-friendly representation
   *
   * @function toJSON
   * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerList#
   *
   * @returns Object
   */
  /* jshint ignore:end */
  WorkerListInstance.toJSON = function toJSON() {
    return this._solution;
  };

  WorkerListInstance[util.inspect.custom] = function inspect(depth, options) {
    return util.inspect(this.toJSON(), options);
  };

  return WorkerListInstance;
};


/* jshint ignore:start */
/**
 * Initialize the WorkerPage
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerPage
 *
 * @param {V1} version - Version of the resource
 * @param {Response<string>} response - Response from the API
 * @param {WorkerSolution} solution - Path solution
 *
 * @returns WorkerPage
 */
/* jshint ignore:end */
WorkerPage = function WorkerPage(version, response, solution) {
  // Path Solution
  this._solution = solution;

  Page.prototype.constructor.call(this, version, response, this._solution);
};

_.extend(WorkerPage.prototype, Page.prototype);
WorkerPage.prototype.constructor = WorkerPage;

/* jshint ignore:start */
/**
 * Build an instance of WorkerInstance
 *
 * @function getInstance
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerPage#
 *
 * @param {WorkerPayload} payload - Payload response from the API
 *
 * @returns WorkerInstance
 */
/* jshint ignore:end */
WorkerPage.prototype.getInstance = function getInstance(payload) {
  return new WorkerInstance(this._version, payload, this._solution.workspaceSid);
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerPage#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerPage.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerPage.prototype[util.inspect.custom] = function inspect(depth, options) {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance
 *
 * @property {string} accountSid - The SID of the Account that created the resource
 * @property {string} activityName -
 *          The friendly_name of the Worker's current Activity
 * @property {string} activitySid - The SID of the Worker's current Activity
 * @property {string} attributes - The JSON string that describes the Worker
 * @property {boolean} available - Whether the Worker is available to perform tasks
 * @property {Date} dateCreated -
 *          The ISO 8601 date and time in GMT when the resource was created
 * @property {Date} dateStatusChanged -
 *          The date and time in GMT of the last change to the Worker's activity
 * @property {Date} dateUpdated -
 *          The ISO 8601 date and time in GMT when the resource was last updated
 * @property {string} friendlyName -
 *          The string that you assigned to describe the resource
 * @property {string} sid - The unique string that identifies the resource
 * @property {string} workspaceSid -
 *          The SID of the Workspace that contains the Worker
 * @property {string} url - The absolute URL of the Worker resource
 * @property {string} links - The URLs of related resources
 *
 * @param {V1} version - Version of the resource
 * @param {WorkerPayload} payload - The instance payload
 * @param {sid} workspaceSid - The SID of the Workspace that contains the Worker
 * @param {sid} sid - The SID of the resource to fetch
 */
/* jshint ignore:end */
WorkerInstance = function WorkerInstance(version, payload, workspaceSid, sid) {
  this._version = version;

  // Marshaled Properties
  this.accountSid = payload.account_sid; // jshint ignore:line
  this.activityName = payload.activity_name; // jshint ignore:line
  this.activitySid = payload.activity_sid; // jshint ignore:line
  this.attributes = payload.attributes; // jshint ignore:line
  this.available = payload.available; // jshint ignore:line
  this.dateCreated = deserialize.iso8601DateTime(payload.date_created); // jshint ignore:line
  this.dateStatusChanged = deserialize.iso8601DateTime(payload.date_status_changed); // jshint ignore:line
  this.dateUpdated = deserialize.iso8601DateTime(payload.date_updated); // jshint ignore:line
  this.friendlyName = payload.friendly_name; // jshint ignore:line
  this.sid = payload.sid; // jshint ignore:line
  this.workspaceSid = payload.workspace_sid; // jshint ignore:line
  this.url = payload.url; // jshint ignore:line
  this.links = payload.links; // jshint ignore:line

  // Context
  this._context = undefined;
  this._solution = {workspaceSid: workspaceSid, sid: sid || this.sid, };
};

Object.defineProperty(WorkerInstance.prototype,
  '_proxy', {
    get: function() {
      if (!this._context) {
        this._context = new WorkerContext(this._version, this._solution.workspaceSid, this._solution.sid);
      }

      return this._context;
    }
});

/* jshint ignore:start */
/**
 * fetch a WorkerInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerInstance.prototype.fetch = function fetch(callback) {
  return this._proxy.fetch(callback);
};

/* jshint ignore:start */
/**
 * update a WorkerInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.activitySid] -
 *          The SID of the Activity that describes the Worker's initial state
 * @param {string} [opts.attributes] - The JSON string that describes the Worker
 * @param {string} [opts.friendlyName] - A string to describe the Worker
 * @param {boolean} [opts.rejectPendingReservations] -
 *          Whether to reject the Worker's pending reservations
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerInstance.prototype.update = function update(opts, callback) {
  return this._proxy.update(opts, callback);
};

/* jshint ignore:start */
/**
 * remove a WorkerInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerInstance.prototype.remove = function remove(opts, callback) {
  return this._proxy.remove(opts, callback);
};

/* jshint ignore:start */
/**
 * Access the realTimeStatistics
 *
 * @function realTimeStatistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersRealTimeStatisticsList}
 */
/* jshint ignore:end */
WorkerInstance.prototype.realTimeStatistics = function realTimeStatistics() {
  return this._proxy.realTimeStatistics;
};

/* jshint ignore:start */
/**
 * Access the cumulativeStatistics
 *
 * @function cumulativeStatistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsList}
 */
/* jshint ignore:end */
WorkerInstance.prototype.cumulativeStatistics = function cumulativeStatistics()
    {
  return this._proxy.cumulativeStatistics;
};

/* jshint ignore:start */
/**
 * Access the statistics
 *
 * @function statistics
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsList}
 */
/* jshint ignore:end */
WorkerInstance.prototype.statistics = function statistics() {
  return this._proxy.statistics;
};

/* jshint ignore:start */
/**
 * Access the reservations
 *
 * @function reservations
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList}
 */
/* jshint ignore:end */
WorkerInstance.prototype.reservations = function reservations() {
  return this._proxy.reservations;
};

/* jshint ignore:start */
/**
 * Access the workerChannels
 *
 * @function workerChannels
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList}
 */
/* jshint ignore:end */
WorkerInstance.prototype.workerChannels = function workerChannels() {
  return this._proxy.workerChannels;
};

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerInstance#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerInstance.prototype.toJSON = function toJSON() {
  let clone = {};
  _.forOwn(this, function(value, key) {
    if (!_.startsWith(key, '_') && ! _.isFunction(value)) {
      clone[key] = value;
    }
  });
  return clone;
};

WorkerInstance.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};


/* jshint ignore:start */
/**
 * Initialize the WorkerContext
 *
 * @constructor Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext
 *
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersRealTimeStatisticsList} realTimeStatistics -
 *          realTimeStatistics resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkersCumulativeStatisticsList} cumulativeStatistics -
 *          cumulativeStatistics resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerStatisticsList} statistics -
 *          statistics resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.ReservationList} reservations -
 *          reservations resource
 * @property {Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext.WorkerChannelList} workerChannels -
 *          workerChannels resource
 *
 * @param {V1} version - Version of the resource
 * @param {sid} workspaceSid - The SID of the Workspace with the Worker to fetch
 * @param {sid} sid - The SID of the resource to fetch
 */
/* jshint ignore:end */
WorkerContext = function WorkerContext(version, workspaceSid, sid) {
  this._version = version;

  // Path Solution
  this._solution = {workspaceSid: workspaceSid, sid: sid, };
  this._uri = `/Workspaces/${workspaceSid}/Workers/${sid}`;

  // Dependents
  this._realTimeStatistics = undefined;
  this._cumulativeStatistics = undefined;
  this._statistics = undefined;
  this._reservations = undefined;
  this._workerChannels = undefined;
};

/* jshint ignore:start */
/**
 * fetch a WorkerInstance
 *
 * @function fetch
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
 *
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerContext.prototype.fetch = function fetch(callback) {
  var deferred = Q.defer();
  var promise = this._version.fetch({uri: this._uri, method: 'GET'});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkerInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * update a WorkerInstance
 *
 * @function update
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.activitySid] -
 *          The SID of the Activity that describes the Worker's initial state
 * @param {string} [opts.attributes] - The JSON string that describes the Worker
 * @param {string} [opts.friendlyName] - A string to describe the Worker
 * @param {boolean} [opts.rejectPendingReservations] -
 *          Whether to reject the Worker's pending reservations
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerContext.prototype.update = function update(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var data = values.of({
    'ActivitySid': _.get(opts, 'activitySid'),
    'Attributes': _.get(opts, 'attributes'),
    'FriendlyName': _.get(opts, 'friendlyName'),
    'RejectPendingReservations': serialize.bool(_.get(opts, 'rejectPendingReservations'))
  });
  var headers = values.of({'If-Match': _.get(opts, 'ifMatch')});

  var promise = this._version.update({uri: this._uri, method: 'POST', data: data, headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(new WorkerInstance(
      this._version,
      payload,
      this._solution.workspaceSid,
      this._solution.sid
    ));
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

/* jshint ignore:start */
/**
 * remove a WorkerInstance
 *
 * @function remove
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
 *
 * @param {object} [opts] - Options for request
 * @param {string} [opts.ifMatch] - The If-Match HTTP request header
 * @param {function} [callback] - Callback to handle processed record
 *
 * @returns {Promise} Resolves to processed WorkerInstance
 */
/* jshint ignore:end */
WorkerContext.prototype.remove = function remove(opts, callback) {
  if (_.isFunction(opts)) {
    callback = opts;
    opts = {};
  }
  opts = opts || {};

  var deferred = Q.defer();
  var headers = values.of({'If-Match': _.get(opts, 'ifMatch')});

  var promise = this._version.remove({uri: this._uri, method: 'DELETE', headers: headers});

  promise = promise.then(function(payload) {
    deferred.resolve(payload);
  }.bind(this));

  promise.catch(function(error) {
    deferred.reject(error);
  });

  if (_.isFunction(callback)) {
    deferred.promise.nodeify(callback);
  }

  return deferred.promise;
};

Object.defineProperty(WorkerContext.prototype,
  'realTimeStatistics', {
    get: function() {
      if (!this._realTimeStatistics) {
        this._realTimeStatistics = new WorkersRealTimeStatisticsList(
          this._version,
          this._solution.workspaceSid
        );
      }
      return this._realTimeStatistics;
    }
});

Object.defineProperty(WorkerContext.prototype,
  'cumulativeStatistics', {
    get: function() {
      if (!this._cumulativeStatistics) {
        this._cumulativeStatistics = new WorkersCumulativeStatisticsList(
          this._version,
          this._solution.workspaceSid
        );
      }
      return this._cumulativeStatistics;
    }
});

Object.defineProperty(WorkerContext.prototype,
  'statistics', {
    get: function() {
      if (!this._statistics) {
        this._statistics = new WorkerStatisticsList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._statistics;
    }
});

Object.defineProperty(WorkerContext.prototype,
  'reservations', {
    get: function() {
      if (!this._reservations) {
        this._reservations = new ReservationList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._reservations;
    }
});

Object.defineProperty(WorkerContext.prototype,
  'workerChannels', {
    get: function() {
      if (!this._workerChannels) {
        this._workerChannels = new WorkerChannelList(
          this._version,
          this._solution.workspaceSid,
          this._solution.sid
        );
      }
      return this._workerChannels;
    }
});

/* jshint ignore:start */
/**
 * Provide a user-friendly representation
 *
 * @function toJSON
 * @memberof Twilio.Taskrouter.V1.WorkspaceContext.WorkerContext#
 *
 * @returns Object
 */
/* jshint ignore:end */
WorkerContext.prototype.toJSON = function toJSON() {
  return this._solution;
};

WorkerContext.prototype[util.inspect.custom] = function inspect(depth, options)
    {
  return util.inspect(this.toJSON(), options);
};

module.exports = {
  WorkerList: WorkerList,
  WorkerPage: WorkerPage,
  WorkerInstance: WorkerInstance,
  WorkerContext: WorkerContext
};
