import {
    SET_MESSAGE,
    ALLOW_ORDERING
} from "../actionTypes";


const initialState = {
       messages: [],
       allow_ordering: true
       
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MESSAGE:
            let new_messages = [...state.messages, ...action.messages]
            return {
                ...state,
                messages: new_messages
            };

            case ALLOW_ORDERING:
                return {
                    ...state,
                    allow_ordering: action.allow_ordering
                };
        default:
            return state;
    }
};

export default reducer;