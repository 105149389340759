import React,{Component} from 'react';
import {View, Text, TouchableOpacity, TextInput, TouchableWithoutFeedback} from 'react-native'
import { FontLetterSpacing } from '../handlers/utils';
import {style} from "../../assets/style";
// import Icono from '@expo/vector-icons/Ionicons'
import Icon from './Warning'


class CartButton extends Component{


    render(){
        const {config, design} = this.props;
        return(
            <View style={[style.cartButton,{backgroundColor:design.grid_view_button_background_color}]}>
                <div disabled={this.props.cartButtonLoading} onClick={() => !this.props.cartButtonLoading && this.props.editCartItem(this.props.item, "minus")}>
                    <View style={{alignItems:'center',justifyContent:'center',width:20}}>
                    {
                        this.props.cartButtonLoading ? 
                        <i style={{color: design.grid_view_button_text_color}} class="fas fa-spinner fa-spin"></i>
                        :
                        <Icon color={design.grid_view_button_text_color} size={design.custom_font.card_button_size} name={'minus'} />

                        }
                    </View>
                </div>
                <View style={{width:20}}>
                    <TextInput
                        onChangeText={text => onChangeText(text)}
                        value={this.props.item.count}
                        style={{
                            textAlign:'center', 
                            letterSpacing: FontLetterSpacing(design?.custom_font?.secondary_letter_space),
                             fontWeight: design.grid_view_button_text_font_weight, 
                             fontSize:design.grid_view_button_text_font_size,
                             color:design.grid_view_button_text_color, 
                            //  fontFamily: design.grid_view_button_text_font_family
                            }}
                        editable={false}
                    />
                </View>

                <div disabled={this.props.cartButtonLoading} onClick={()=> !this.props.cartButtonLoading && this.props.editCartItem(this.props.item, "add")}>
                    <View style={{alignItems:'center',justifyContent:'center',width:20}}>
                        {
                            this.props.cartButtonLoading ? 
                            <i style={{color: design.grid_view_button_text_color}} class="fas fa-spinner fa-spin"></i>
                            :
                            <Icon color={design.grid_view_button_text_color} size={design.custom_font.card_button_size} name={'plus'} />

                        }
                    </View>
                </div>
{/* 
                {
                    this.props.removeItemModal ?
                        <TouchableOpacity disabled={this.props.cartButtonLoading} onPress={this.props.increaseItemCount}>
                            <View style={{alignItems:'center',justifyContent:'center',width:20}}>
                                <Icon color={design.grid_view_button_text_color} size={design.custom_font.card_button_size} name={'plus'} />
                            </View>
                        </TouchableOpacity>
                        :
                        <TouchableOpacity disabled={this.props.cartButtonLoading} onPress={() => this.props.item.price.length>1 || this.props.item.modifiers.length>0 ? this.props.onCartClick(this.props.item) : this.props._addToCart(this.props.item)}>
                            <View style={{alignItems:'center',justifyContent:'center',width:20}}>
                                <Icon color={design.grid_view_button_text_color} size={design.custom_font.card_button_size} name={'plus'} />
                            </View>
                        </TouchableOpacity>
                } */}



            </View>
        )
    }
}


export default CartButton